import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ChevronLeftIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m15 18-6-6 6-6" />
    </SvgIcon>
  );
}
