import config from 'config';
import { ICompany, Role } from 'types/company';
import { TinyCommunity } from 'types/company/community';

const baseUrl = config.COMPANIES_API_URL;

export interface CompanyPendingInCommunity {
  type: 'company-pending-in-community';
  community: TinyCommunity;
  company: ICompany;
  communityHasApplication: boolean;
}
export type DashboardMessage =
  | { type: 'you-are-new-in-community'; community: ICompany; role: Role; joinedAsCompany?: ICompany }
  | { type: 'you-are-pending-in-community'; community: TinyCommunity }
  | { type: 'thanks-for-signing-up-as-investor' }
  | { type: 'thanks-for-signing-up-as-other' }
  | { type: 'mentor-with-no-mentees' }
  | { type: 'mentor-with-one-mentee'; company: ICompany }
  | { type: 'mentor-with-several-mentees'; communitySlug: string; count: number; companies: ICompany[] }
  | { type: 'community-admin-pending-requests'; communitySlug: string; userCount: number; companyCount: number }
  | CompanyPendingInCommunity
  | { type: 'welcome-to-company-profile-as-new-company-admin'; company: ICompany }
  | { type: 'should-update-company-profile-as-company-admin'; company: ICompany; lastUpdated: string }
  | { type: 'should-update-investors-as-company-admin'; company: ICompany; lastUpdated: string }
  | { type: 'pending-community-application-as-company-admin'; community: ICompany }
  | { type: 'dnb-nxt-webinar' }
  | { type: 'is-founder-in-100-pitches'; communitySlug: string };

export interface DashboardData {
  messages: DashboardMessage[];
}

export type PulseItemDTO =
  | { type: 'CreatedEvent'; timestamp: DateString; eventId: UUID; title: string }
  | {
      type: 'JoinedCommunity';
      timestamp: DateString;
      communityName: string;
      communitySlug: string;
      communityId: number;
      company: ICompany;
    }
  | { type: 'StartedFundingRound'; timestamp: DateString; company: ICompany }
  | { type: 'ClosedFundingRound'; timestamp: DateString; company: ICompany }
  | { type: 'UpdatedProfile'; timestamp: DateString; company: ICompany }
  | { type: 'UpdatedKpis'; timestamp: DateString; company: ICompany }
  | { type: 'UpdatedPitchDeck'; timestamp: DateString; company: ICompany }
  | { type: 'UpdatedTeam'; timestamp: DateString; company: ICompany }
  | { type: 'PostedUpdate'; timestamp: DateString; company: ICompany; contentId: UUID; audienceCommunityIds: number[] };

export type PulseSelection = 'InvestmentPreferences' | 'PlatformWide' | 'Collections' | 'Network' | 'Mixed';

export interface PulseDTO {
  values: PulseItemDTO[];
  selection: PulseSelection;
}

export default {
  getUserDashboardData: () => `${baseUrl}/dashboard/user`,
  getCompanyDashboardData: (companyId: number) => `${baseUrl}/dashboard/companies/${companyId}`,
  getCommunityDashboardData: (communityId: number) => `${baseUrl}/dashboard/communities/${communityId}`,
  pulse: {
    platformWide: (limit?: number) => `${baseUrl}/dashboard/pulse/platform-wide${limit ? '?' + 'limit=' + limit : ''}`,
    suggestions: () => `${baseUrl}/dashboard/pulse/suggestions`,
    myNetwork: () => `${baseUrl}/dashboard/pulse/my-network`,
    myCollections: () => `${baseUrl}/dashboard/pulse/my-collections`,
  },
};
