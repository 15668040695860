import Table from 'ui/elements/tables/Table/Table';
import React, { useMemo, useState } from 'react';
import { Organization, OrganizationInviteDTO } from 'types/organization';
import TextFilter from 'domain/shared/Filters/TextFilter';
import useTableWithSort from 'ui/elements/tables/Table/useTableWithSort';
import { asShortDate } from 'util/dateUtils';
import PopMenu from 'ui/modules/PopMenu';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import WithdrawInvitationDialog from 'pages/Organization/Settings/ConnectionSettings/WithdrawInvitationDialog';
import useResource from 'util/resource/useResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import { getOrElse } from 'util/resource';
import { PaginatedResult } from 'types';
import Pagination from 'ui/modules/Pagination/Pagination';
import { PaginationOptions } from 'types/api';
import { emptyPaginatedResult } from 'util/paginationUtils';

interface InvitedTableProps {
  organization: Organization;
}

export default function InvitedTable(props: InvitedTableProps) {
  const [emailFilter, setEmailFilter] = useState('');
  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>();

  const { onSort, sortOptions } = useTableWithSort({
    sortBy: 'invitedAt',
    sortOrder: 'desc',
  });

  const url = useMemo(() => {
    return OrganizationAPI.listInvitesUrl(props.organization.id, paginationOptions, {
      emailFilter: emailFilter !== '' ? emailFilter : null,
      sortOrder: sortOptions?.sortOrder === 'desc' ? 'desc' : sortOptions?.sortOrder === 'asc' ? 'asc' : null,
      sortBy: sortOptions?.sortBy === 'invitedAt' ? 'createdAt' : sortOptions?.sortBy === 'email' ? 'email' : null,
    });
  }, [props.organization.id, paginationOptions, emailFilter, sortOptions]);

  const { resource } = useResource<PaginatedResult<OrganizationInviteDTO>>(url);

  const data = getOrElse<PaginatedResult<OrganizationInviteDTO>>(
    resource,
    emptyPaginatedResult<OrganizationInviteDTO>(),
  );

  const [showWithdrawDialog, setShowWithdrawDialog] = useState<OrganizationInviteDTO | null>(null);

  return (
    <>
      <Table
        style={{ tableLayout: 'auto' }}
        isLoading={resource.state === 'fetching'}
        onSort={onSort}
        sortOptions={sortOptions}
        infoMessages={{
          noResults: 'No users invited',
          noResultsAfterFilter: "We couldn't find any invitations with these filters.",
        }}
      >
        <Table.Header>
          <Table.HeaderCell
            style={{ width: '80%' }}
            isSortable
            sortBy="email"
            filter={<TextFilter setValue={setEmailFilter} value={emailFilter} />}
          >
            Email
          </Table.HeaderCell>
          <Table.HeaderCell isSortable sortBy="invitedAt">
            Invited
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Header>
        <Table.Body>
          {data.values.map((invite, index) => (
            <Table.Row key={index}>
              <Table.DataCell key={index} style={{ wordBreak: 'break-word' }}>
                <span>{invite.email}</span>
              </Table.DataCell>
              <Table.DataCell style={{ whiteSpace: 'nowrap' }}>{asShortDate(invite.createdAt)}</Table.DataCell>
              <Table.DataCell>
                <PopMenu
                  items={[
                    {
                      onClick: () => setShowWithdrawDialog(invite),
                      icon: <TrashIcon />,
                      text: 'Withdraw invitation',
                    },
                  ]}
                />
              </Table.DataCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination setPaginationOptions={setPaginationOptions} resource={data} />
      {showWithdrawDialog && (
        <WithdrawInvitationDialog onClose={() => setShowWithdrawDialog(null)} invite={showWithdrawDialog} />
      )}
    </>
  );
}
