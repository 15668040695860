import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from 'ui/elements/icons/ErrorIcon';
import cx from 'classnames';
import React from 'react';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import styles from './styles.scss';
import { UploadStatus } from 'domain/shared/Files/Upload/useFileUpload';

interface Props {
  document: File;
  uploadErrorMessage?: string;
  status: UploadStatus;
}

export default function UploadedDocument(props: Props) {
  const { document, status, uploadErrorMessage } = props;

  const FileStatus = () => {
    switch (status) {
      case 'UPLOADING':
        return <CircularProgress style={{ verticalAlign: 'middle' }} size={24} />;
      case 'UPLOAD_ERROR':
        return <ErrorIcon style={{ verticalAlign: 'middle' }} color="red" />;
      default:
        return <CheckmarkIcon className="u-color-green" />;
    }
  };

  return (
    <div className={styles.documentUpload}>
      <div>
        <FileStatus />
      </div>
      <div className={styles.documentUploadInfo}>
        <div className={styles.nameAndIcon}>
          <span className={cx('u-ellipsis', styles.name, { [styles.errorText]: status === 'UPLOAD_ERROR' })}>
            {document.name}
          </span>
          {uploadErrorMessage && <span className="text-metadata u-error-text">{uploadErrorMessage}</span>}
          <div className={styles.progressText}>
            {status === 'UPLOAD_ERROR' ? null : `${(document.size / 1000000).toFixed(1)} MB`}
          </div>
        </div>
      </div>
    </div>
  );
}
