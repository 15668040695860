import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { fontSizeLarge, spacingUnits } from 'ui/theme/themeBluePlanet';

export const Body = styled.div`
  margin-top: calc(-140px - 3vw);
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  @media screen and (max-width: 1040px) {
    max-width: 920px;
    top: -2rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  ${bluePlanetTheme.breakpoints.down('lg')} {
    margin: calc(-120px - 3vw) auto 0 auto;
  }

  ${bluePlanetTheme.breakpoints.down('md')} {
    margin: calc(-70px - 4vw) ${spacingUnits.half} 0 ${spacingUnits.half};
  }

  ${bluePlanetTheme.breakpoints.down('sm')} {
    width: auto;
    margin: calc(-40px - 4vw) ${spacingUnits.half} 0 ${spacingUnits.half};
  }

  @media (max-width: 360px) {
    margin: -20px ${spacingUnits.half} 0 ${spacingUnits.half};
  }
`;

export const CompanyCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingUnits.content};
  ${bluePlanetTheme.breakpoints.up('sm')} {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

export const ActionCardBody = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  font-size: ${fontSizeLarge};
  ${bluePlanetTheme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const ActionCardButtons = styled.div`
  ${bluePlanetTheme.breakpoints.down('md')} {
    margin-top: ${spacingUnits.content};
  }
`;
