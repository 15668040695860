import { fetched as fetchedCompany, ActionTypes as CompanyActionTypes } from '../company/details/actions';
import { CommunityMembershipDTO } from 'types/company/community';
import { FetchedAction } from 'globalstate/common/genericResource';
import { SelfUserProfile } from 'types/user';
import { update } from 'util/intercom/intercom';
import { Role } from 'types/company';

export interface IntercomStore {
  user?: SelfUserProfile;
  company?: {
    name: string;
    id: number;
    isCommunity: boolean;
  };
  roles?: Role[];
  hasBooted: boolean;
}

const initialState: IntercomStore = { hasBooted: false };

export type SelfUserFetchedAction = {
  type: 'self user fetched';
  payload: SelfUserProfile;
};

type IntercomAction = fetchedCompany | FetchedAction<CommunityMembershipDTO[]> | FetchedAction<SelfUserProfile>;

export default (state: IntercomStore = initialState, action: IntercomAction): IntercomStore => {
  switch (action.type) {
    case 'self user fetched': {
      const userProfile = (action as FetchedAction<SelfUserProfile>).payload;
      const nextState = { ...state, user: userProfile };

      const result = update(nextState);
      return { ...nextState, hasBooted: nextState.hasBooted || result === 'boot' };
    }
    case CompanyActionTypes.FETCHED: {
      const payload = (action as fetchedCompany).payload;
      const company = payload.company;
      const roles = payload.access.roles;
      const nextState = {
        ...state,
        company,
        roles,
      };
      const result = update(nextState);
      return { ...nextState, hasBooted: nextState.hasBooted || result === 'boot' };
    }
    case CompanyActionTypes.COMPANY_CHANGED:
      return {
        ...state,
        company: undefined,
        roles: undefined,
      };
    default:
      return state;
  }
};
