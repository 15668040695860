import useSWR from 'hooks/useSWR';
import { CompanyProfile } from 'types/company';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

export const companyProfileKey = (slug: string) => companiesAPIUrls.companyProfile.get(slug);
export const companyProfileByIdKey = (id: number) => companiesAPIUrls.companyProfile.get(id.toString());

// Fetch the company profile from `window.preloaded` if it is there, to speed up rendering
// We would probably need a better setup if we were to preload other resources, but this works when
// it's only one type we support.
function getPreloadedCompanyProfile(slug: string): CompanyProfile {
  const hasBeenPrefetched =
    window.preloaded && window.preloaded.companyProfile && window.preloaded.companyProfile.slug === slug;
  return hasBeenPrefetched ? window.preloaded?.companyProfile : undefined;
}

export function useCompanyProfile(slug: string) {
  const swrResult = useSWR<CompanyProfile>(companyProfileKey(slug), getPreloadedCompanyProfile(slug));

  return useSWRResource(swrResult);
}

export function useCompanyProfileById(companyId?: number) {
  const swrResult = useSWR<CompanyProfile>(companyId ? companyProfileByIdKey(companyId) : undefined);

  return useSWRResource(swrResult);
}
