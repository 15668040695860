import CompanyShortlistPicker from 'domain/Shortlists/CompanyShortlistPicker';
import { trackConversationOpened } from 'domain/conversations/actions';
import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import CalendarIcon from 'ui/elements/icons/CalendarIcon';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import Resource from 'util/resource/Resource';
import { getOrElse } from 'util/resource';
import BookmarkIcon from 'ui/elements/icons/BookmarkIcon';
import { useShortlists } from 'apis/CompanyAPI/users/useShortlists';
import MenuButton, { ButtonMenu } from 'ui/elements/buttons/MenuButton';
import { ShortlistButtonText } from 'domain/Shortlists/ShortlistButtonText';
import CompanyConversationDialogAsUser from 'domain/conversations/CompanyConversationDialogAsUser';
import Calendly from 'ui/domain/Calendly';
import ShareCompany from './ShareCompany';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import useJourneyLogger from 'hooks/useJourneyLogger';
import useUrlParams from 'hooks/useUrlParams';

type Props = {
  viewAs: CompanyViewAs;
  company: ICompany & {
    calendlyUrl?: string;
  };
};

export default function SidebarActions({ company, viewAs }: Props) {
  const [log] = useJourneyLogger();
  const match = useRouteMatch<{ communitySlug?: string }>();
  const { values } = useUrlParams(['contact'], undefined, { isRemoveAfterRead: true });
  const [isCompanyConversationDialogOpen, setIsCompanyConversationOpen] = useState(values.contact[0] === '1');

  const { resource: shortlistResource, mutate: setShortlistResource } = useShortlists();
  const shortlists = getOrElse(shortlistResource, { values: [] });
  const shortlistsWithCompany = shortlists.values.filter(sl => sl.companies.map(c => c.id).includes(company.id));

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className="u-content-spacing-bottom" data-intercom-target="company-profile-sidebar-actions">
      <ButtonMenu className="u-content-spacing-bottom">
        <Resource
          resource={shortlistResource}
          renderLoading={() => (
            <MenuButton>
              <BookmarkIcon className="u-half-spacing-right" />
            </MenuButton>
          )}
        >
          {shortlists => (
            <CompanyShortlistPicker
              company={company}
              shortlists={shortlists.values}
              dropdownWidth={containerRef.current?.clientWidth ? `${containerRef.current?.clientWidth}px` : undefined}
              setShortlists={setShortlistResource}
              menuItemHeight={'60px'}
              dropdownButton={onClick => (
                <MenuButton onClick={onClick}>
                  <ShortlistButtonText shortlists={shortlistsWithCompany} />
                </MenuButton>
              )}
            />
          )}
        </Resource>
        {company.calendlyUrl && (
          <Calendly calendlyUrl={company.calendlyUrl}>
            {onOpen => (
              <MenuButton onClick={onOpen}>
                <CalendarIcon className="u-half-spacing-right" />
                Book a meeting
              </MenuButton>
            )}
          </Calendly>
        )}
        <ShareCompany company={company} canEditCompany={viewAs === 'Admin'} />
        <MenuButton
          onClick={() => {
            log(viewAs === 'Visitor' ? 'opened contact company' : 'open give feedback to company');
            setIsCompanyConversationOpen(true);
            trackConversationOpened({
              withCompanyId: company.id,
              communitySlug: match.params.communitySlug,
              key: 'company-profile-give-feedback',
              path: match.path,
            });
          }}
        >
          <ChatBubblesIcon className="u-half-spacing-right" />
          {viewAs === 'Visitor' ? 'Contact' : 'Give feedback to the company'}
        </MenuButton>
      </ButtonMenu>
      {isCompanyConversationDialogOpen && (
        <CompanyConversationDialogAsUser
          isOpen={isCompanyConversationDialogOpen}
          onClose={() => setIsCompanyConversationOpen(false)}
          company={company}
        />
      )}
    </div>
  );
}
