import ErrorIcon from 'ui/elements/icons/ErrorIcon';
import React, { useState } from 'react';
import styles from './styles.scss';
import FileIcon from 'domain/shared/Files/File/FileIcon';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import { CSSProperties } from 'react';
import Tooltip from 'ui/elements/Tooltip';
import IconButton from '@mui/material/IconButton';
import cx from 'classnames';
import Loading from 'ui/elements/Loading';
import { UploadStatus } from '../Upload/useFileUpload';

interface Props {
  name: string;
  status: UploadStatus;
  onClose: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  style?: CSSProperties;
  onDownload?: () => void;
}

export default function UploadedFile(props: Props) {
  const { name, status } = props;
  const [isDownloading, setIsDownloading] = useState(false);

  const onClick = props.onDownload
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        if (props.onDownload) {
          setIsDownloading(true);
          props.onDownload();
          setIsDownloading(false);
        }
      }
    : undefined;

  return (
    <div
      className={cx(styles.container, styles.border, styles.whiteBackground, props.className, {
        [styles.downloadable]: !!props.onDownload,
      })}
      style={props.style}
      onClick={props.onDownload ? onClick : undefined}
    >
      <div className={styles.status}>
        {status === 'UPLOADING' ? (
          <Loading style={{ verticalAlign: 'middle', padding: '2px' }} size={20} />
        ) : !isDownloading ? (
          <IconButton size="small" onClick={props.onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        ) : null}
      </div>
      <Tooltip title={name}>
        <div className={styles.nameAndIcon}>
          {status === 'UPLOAD_ERROR' ? (
            <ErrorIcon className="u-half-spacing-right" color="red" />
          ) : isDownloading ? (
            <Loading className="u-half-spacing-right" style={{ height: '24px' }} size={24} />
          ) : (
            <FileIcon className="u-half-spacing-right" name={name} />
          )}
          {name}
        </div>
      </Tooltip>
    </div>
  );
}
