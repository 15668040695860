import ApiBase from 'apis/ApiBase';
import { withPagination } from 'apis/utils';
import { CloudinaryUploadSettings, PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { ReplyContentDTO, TopicDTO, TopicReplyDTO } from 'types/company/community';
import { ContentStats, UpdateStats } from 'types/content/update';
import { CompanyId } from 'urls';

export interface IContentAPI {
  community: {
    discussions: {
      get: (
        communityId: CompanyId,
        communityCompanyId: CompanyId,
        paginationOptions?: PaginationOptions,
      ) => Promise<PaginatedResult<TopicDTO>>;
      single: (communityId: CompanyId, communityCompanyId: CompanyId, discussionId: string) => Promise<TopicDTO>;
      createTopic: (
        communityId: CompanyId,
        communityCompanyId: CompanyId,
        title: string,
        description: string,
      ) => Promise<TopicDTO>;
      editTopic: (
        communityId: CompanyId,
        communityCompanyId: CompanyId,
        topicId: TopicDTO['id'],
        title: string,
        description: string,
      ) => Promise<TopicDTO>;
      reply: (
        communityId: CompanyId,
        communityCompanyId: CompanyId,
        discussionId: TopicDTO['id'],
        reply: ReplyContentDTO,
      ) => Promise<TopicReplyDTO>;
      replies: (
        communityId: CompanyId,
        communityCompanyId: CompanyId,
        discussionId: TopicDTO['id'],
        paginationOptions?: PaginationOptions,
      ) => Promise<PaginatedResult<TopicReplyDTO>>;
      editReply: (
        communityId: CompanyId,
        communityCompanyId: CompanyId,
        discussionId: TopicDTO['id'],
        replyId: TopicReplyDTO['id'],
        replyEdits: ReplyContentDTO,
      ) => Promise<TopicReplyDTO>;
    };
  };
  images: {
    getCompanyUploadUrl: (companyId: number | string, filename: string) => Promise<CloudinaryUploadSettings>;
    getUserUploadUrl: (userId: number, filename: string) => Promise<CloudinaryUploadSettings>;
    getOrganizationUploadUrl: (organizationId: string, filename: string) => Promise<CloudinaryUploadSettings>;
  };
  stats: {
    globalStats: () => Promise<ContentStats>;
    updateStats: (updateId: UUID) => Promise<UpdateStats>;
  };
}

const api = ApiBase();

export const ContentAPI = (baseUrl: string): IContentAPI => {
  return {
    community: {
      discussions: {
        get: (communityId, communityCompanyId, paginationOptions) =>
          api.get<PaginatedResult<TopicDTO>>(
            withPagination(
              `${baseUrl}/community/${communityId}/companies/${communityCompanyId}/discussions`,
              paginationOptions,
            ),
          ),
        single: (communityId, communityCompanyId, discussionId) =>
          api.get<TopicDTO>(
            `${baseUrl}/community/${communityId}/companies/${communityCompanyId}/discussions/${discussionId}`,
          ),
        createTopic: (communityId, communityCompanyId, title, description) =>
          api.post(`${baseUrl}/community/${communityId}/companies/${communityCompanyId}/discussions`, {
            title,
            content: {
              htmlContent: description,
              structuredContent: description,
            },
          }),
        editTopic: (communityId, communityCompanyId, topicId, title, description) =>
          api.patch(`${baseUrl}/community/${communityId}/companies/${communityCompanyId}/discussions/${topicId}`, {
            title,
            content: {
              htmlContent: description,
              structuredContent: description,
            },
          }),
        reply: (communityId, communityCompanyId, discussionId, reply) =>
          api.post(
            `${baseUrl}/community/${communityId}/companies/${communityCompanyId}/discussions/${discussionId}`,
            reply,
          ),
        replies: (communityId, communityCompanyId, discussionId, paginationOptions) =>
          api.get(
            withPagination(
              `${baseUrl}/community/${communityId}/companies/${communityCompanyId}/discussions/${discussionId}/replies`,
              paginationOptions,
            ),
          ),
        editReply: (communityId, communityCompanyId, discussionId, replyId, replyEdits) => {
          const discussionUrl = (communityId: CompanyId, communityCompanyId: CompanyId, discussionId: TopicDTO['id']) =>
            `${baseUrl}/community/${communityId}/companies/${communityCompanyId}/discussions/${discussionId}`;
          return api.patch(
            `${discussionUrl(communityId, communityCompanyId, discussionId)}/reply/${replyId}`,
            replyEdits,
          );
        },
      },
    },
    images: {
      getCompanyUploadUrl: (companyId: number | string, filename: string) =>
        api.post<CloudinaryUploadSettings>(`${baseUrl}/images?companyId=${companyId}&filename=${filename}`, {}),
      getUserUploadUrl: (userId: number, filename: string) =>
        api.post<CloudinaryUploadSettings>(`${baseUrl}/users/${userId}/profile/images?filename=${filename}`, {}),
      getOrganizationUploadUrl: (organizationId: string, filename: string) =>
        api.post<CloudinaryUploadSettings>(
          `${baseUrl}/organizations/${organizationId}/images?filename=${filename}`,
          {},
        ),
    },
    stats: {
      globalStats: () => api.get(`${baseUrl}/stats`),
      updateStats: (updateId: UUID) => api.get(`${baseUrl}/stats/updates/${updateId}`),
    },
  };
};
