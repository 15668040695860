import React, { CSSProperties } from 'react';
import cx from 'classnames';
import styles from './styles.scss';
import { ButtonColors } from './Button';
import { Link } from 'react-router-dom';
import { LocationDescriptor, LocationState, Location } from 'history';

interface Props {
  url: LocationDescriptor<LocationState> | ((location: Location<LocationState>) => LocationDescriptor<LocationState>);
  children: React.ReactNode;
  color?: ButtonColors;
  kind?: 'primary' | 'secondary' | 'tertiary';
  shape?: 'square' | 'rounded';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  target?: string;
  style?: CSSProperties;
  rel?: string;
  disabled?: boolean;
  hasSubtleHoverStyle?: boolean;
  isActive?: boolean;
  intercomTarget?: string;
}

export default function LinkAsButton({
  color = 'blue',
  size = 'medium',
  kind = 'primary',
  shape = 'square',
  url,
  className,
  children,
  disabled,
  hasSubtleHoverStyle,
  isActive,
  intercomTarget,
  ...rest
}: Props) {
  return (
    <Link
      to={url}
      className={cx(
        className,
        styles[shape],
        styles.button,
        styles[color],
        styles[kind],
        styles[size],
        disabled ? styles.disabled : undefined,
        {
          [styles.subtleHoverStyle]: hasSubtleHoverStyle,
          [styles.isActive]: isActive,
        },
      )}
      data-intercom-target={intercomTarget}
      {...rest}
    >
      {children}
    </Link>
  );
}
