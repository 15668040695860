import React from 'react';
import LoadableResource from 'util/resource/Resource';
import { NotificationElement } from 'domain/notify/components/NotificationElement';
import InfoCard from 'ui/views/cards/InfoCard';
import { NotificationList as NotificationListDTO } from 'types/notify';
import { IResource } from 'util/resource';
import styles from './styles.scss';
import Loading from 'ui/elements/Loading';

export interface NotificationListProps {
  resource: IResource<NotificationListDTO>;
  onClick: () => void;
}

export default function NotificationList(props: NotificationListProps) {
  return (
    <LoadableResource
      resource={props.resource}
      renderLoading={() => (
        <div className="u-flex-center u-section-spacing-top">
          <Loading size={25} />
        </div>
      )}
    >
      {notifications =>
        notifications.values.length === 0 ? (
          <InfoCard className={styles.empty}>You have no notifications</InfoCard>
        ) : (
          <div className="u-content-padding-left">
            <h6 className="text-subheading-2 u-flex-align-center" style={{ minHeight: 80 }}>
              Notifications
            </h6>
            {notifications.values.map((notification, idx) => (
              <NotificationElement
                key={`notification-element-${idx}`}
                notification={notification}
                onClick={props.onClick}
              />
            ))}
          </div>
        )
      }
    </LoadableResource>
  );
}
