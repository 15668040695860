import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function PlusIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 4v16m8-8H4" />
    </SvgIcon>
  );
}
