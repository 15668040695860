import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CheckMarkCircleIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M22 12a10 10 0 1 0-20 0 10 10 0 0 0 20 0Z" />
      <path d="m8 12.5 2.5 2.5L16 9" />
    </SvgIcon>
  );
}
