import React from 'react';
import { lighten, useTheme } from '@mui/material';
import { PieChart as RechartsPieChart, Pie, Cell, Tooltip, TooltipProps } from 'recharts';
import Pill from 'ui/elements/Pill';
import useWindowWidth from 'hooks/useWindowWidth';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import styles from './styles.scss';
import { contentSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import styled from '@emotion/styled';
import themeBluePlanet, { fontSizeTiny } from 'ui/theme/themeBluePlanet';

interface Props {
  chartData: { value: number; name: string }[];
  colors?: string[];
  radius?: string | number;
  cx?: string | number;
  cy?: string | number;
  width?: number;
  height?: number;
  unit?: string;
}

const CustomTooltip = ({ active, payload, unit }: TooltipProps<ValueType, NameType> & { unit?: string }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.chartLabel}>
        <p>{`${payload[0].name} : ${payload[0].value}${unit ?? ''}`}</p>
      </div>
    );
  }

  return null;
};

const Label = styled.div`
  color: ${themeBluePlanet.bluePlanetPalette.indigo.light};
  font-size: ${fontSizeTiny};
`;

export default function PieChart(props: Props) {
  const width = useWindowWidth();
  const theme = useTheme();
  const mobile = width < theme.breakpoints.values.sm;

  const COLORS = props.colors ?? [
    theme.bluePlanetPalette.purple.medium,
    lighten(theme.bluePlanetPalette.violet.dark ?? '', 0.1),
    theme.bluePlanetPalette.violet.main,
    theme.bluePlanetPalette.violet.medium,
  ];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: mobile ? 'column' : 'row',
        gap: mobile ? contentSpacing : sectionSpacing,
      }}
    >
      <div>
        <RechartsPieChart width={props.width ? props.width : 100} height={props.width ? props.width : 200}>
          <Pie
            data={props.chartData}
            cx={`${props.cx} ?? 0`}
            cy={`${props.cy} ?? 0`}
            labelLine={false}
            outerRadius={props.radius ? props.radius : '100%'}
            dataKey="value"
            blendStroke
          >
            {props.chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip offset={40} content={<CustomTooltip unit={props.unit} />} />
        </RechartsPieChart>
      </div>

      <div className="u-flex u-flex--column u-flex--gap-1">
        {props.chartData.map((item, index) => (
          <div className="u-flex" key={index}>
            <Pill style={{ height: '28px', backgroundColor: COLORS[index % COLORS.length], marginTop: '2px' }} />
            <div>
              <Label>{item.value}%</Label>
              <Label>{item.name}</Label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
