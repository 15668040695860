import { CalendarEvent, google, ics, office365, outlook, yahoo } from 'calendar-link';
import React, { useRef, useState } from 'react';
import DropDown from '../DropDown/DropDown';
import Button from './Button';
import cx from 'classnames';

interface Props {
  event: CalendarEvent;
  className?: string;
  trackingKey?: string;
}

export default function CalendarButton({ event, className, trackingKey }: Props) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const googleUrl = google(event); // https://calendar.google.com/calendar/render...
  const outlookUrl = outlook(event); // https://outlook.live.com/owa/...
  const officeUrl = office365(event); // https://outlook.office.com/owa/...
  const yahooUrl = yahoo(event); // https://calendar.yahoo.com/?v=60&title=...
  const iCal = ics(event); // standard ICS file based on https://icalendar.org

  const items = [
    { label: 'Google', value: googleUrl, href: googleUrl },
    { label: 'Outlook', value: outlookUrl, href: outlookUrl },
    { label: 'Office 365', value: officeUrl, href: officeUrl },
    { label: 'Yahoo', value: yahooUrl, href: yahooUrl },
    { label: 'iCal', value: iCal, href: iCal },
  ];

  return (
    <div className={className}>
      <span ref={buttonRef}>
        <Button
          kind="primary"
          className={cx('u-ellipsis', trackingKey ? `data-track-${trackingKey}` : undefined)}
          onClick={() => setOpen(!open)}
        >
          Add to calendar
        </Button>
      </span>
      <DropDown values={items} anchorEl={buttonRef.current} setOpen={setOpen} open={open} onSelect={() => null} />
    </div>
  );
}
