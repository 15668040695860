import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function PlusBoxIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 8v8m4-4H8" />
      <path d="M2.5 12c0-4.5 0-6.7 1.4-8.1C5.3 2.5 7.5 2.5 12 2.5s6.7 0 8.1 1.4c1.4 1.4 1.4 3.6 1.4 8.1s0 6.7-1.4 8.1c-1.4 1.4-3.6 1.4-8.1 1.4s-6.7 0-8.1-1.4c-1.4-1.4-1.4-3.6-1.4-8.1Z" />
    </SvgIcon>
  );
}
