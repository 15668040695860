import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ChatBubblesIcon(props: IconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 23 22">
      <path d="M20 9a9 9 0 0 0-9-7c-5 0-9 3.9-9 8.6 0 2.3 1 4.4 2.5 6 .3.3.5.7.4 1.2-.1.8-.4 1.5-1 2.1a6 6 0 0 0 3.9-.6l.8-.3H9" />
      <path d="M11 16.3c0 2.9 2.5 5.2 5.5 5.2h1l.5-.1.5.2c.7.4 1.5.5 2.3.3a3 3 0 0 1-.6-1.3c0-.2.1-.5.3-.7 1-1 1.5-2.2 1.5-3.6 0-3-2.5-5.3-5.5-5.3S11 13.4 11 16.3Z" />
    </SvgIcon>
  );
}
