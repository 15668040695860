import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';

interface Props {
  imageUrls: string[];
}

export default function PreviewImages(props: Props) {
  const images = props.imageUrls.slice(0, 3);
  return (
    <div
      className={cx(styles.imagePreviewGrid, {
        [styles.imagePreviewGridSingle]: images.length === 1,
        [styles.imagePreviewGridDouble]: images.length === 2,
        [styles.imagePreviewGridTriple]: images.length === 3,
      })}
    >
      {images.map((url, index) => (
        <img
          key={index}
          src={url}
          alt={`img-${index}`}
          className={cx(styles.imagePreviewImg, {
            [styles.verticalImage]: index === 0 && images.length === 3,
          })}
        />
      ))}
    </div>
  );
}
