import React from 'react';
import ChevronUpIcon from 'ui/elements/icons/ChevronUpIcon';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import { bluePlanetTheme } from 'ui/theme';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import { darken } from '@mui/material';

export function PeriodOverPeriod({ value, previousValue }: { value: number; previousValue?: number }) {
  const periodOverPeriod = previousValue ? Math.round(((value - previousValue) / previousValue) * 100) : undefined;
  return (
    periodOverPeriod !== undefined && (
      <span
        className="text-weight-medium u-flex-align-center"
        style={{
          color:
            periodOverPeriod > 0
              ? darken(bluePlanetTheme.bluePlanetPalette.green.main, 0.2)
              : periodOverPeriod === 0
              ? bluePlanetTheme.bluePlanetPalette.blue.medium
              : bluePlanetTheme.bluePlanetPalette.red.medium,
        }}
      >
        {periodOverPeriod > 0 ? <ChevronUpIcon /> : periodOverPeriod < 0 ? <ChevronDownIcon /> : <ChevronRightIcon />}
        {isFinite(periodOverPeriod) && <>{Math.abs(periodOverPeriod)}%</>}
      </span>
    )
  );
}
