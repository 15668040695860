import React, { useEffect } from 'react';
import OnYourAgenda from './sections/onYourAgenda/OnYourAgenda';
import { DashboardData } from 'apis/DashboardAPI';
import Pulse, { ActivityType, activityTypes } from './sections/pulse/Pulse';
import { scrollToTop } from 'util/scroll';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import Resource from 'util/resource/Resource';
import { sectionSpacing } from 'ui/theme/themeConstants';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import FeaturedCompanyMatchesForCurrentUser from 'pages/Discover/FeaturedCompanyMatchesForCurrentUser';
import { getOrUndefined } from 'util/resource';
import { SelfUserProfile } from 'types/user';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 800px 600px;
  grid-template-areas:
    'messages messages'
    'top sidebar';
  column-gap: ${sectionSpacing};

  ${bluePlanetTheme.breakpoints.down(1700)} {
    grid-template-columns: 800px 3fr;
  }

  ${bluePlanetTheme.breakpoints.down(1600)} {
    column-gap: 2rem;
  }

  ${bluePlanetTheme.breakpoints.down(1500)} {
    grid-template-columns: 4fr 3fr;
    column-gap: 1rem;
  }

  ${bluePlanetTheme.breakpoints.down('lg')} {
    grid-template-columns: 100%;
    grid-template-areas: 'messages' 'sidebar' 'top';
    gap: 0;
  }
`;

function getActivityTypes(user: SelfUserProfile): ActivityType[] {
  if (user.canBrowsePlatform) {
    return activityTypes.filter(t => user.isInvestor || t !== 'Suggestions');
  }
  return activityTypes.filter(t => t !== 'Suggestions' && t !== 'All');
}

export function Home({
  hundredPitchesMessage,
  dashboardData,
}: {
  hundredPitchesMessage: { type: 'is-founder-in-100-pitches'; communitySlug: string } | undefined;
  dashboardData: DashboardData;
}): React.ReactNode {
  const messages = hundredPitchesMessage
    ? [hundredPitchesMessage, ...(dashboardData.messages ?? [])]
    : dashboardData.messages;

  const { resource: userProfileResource } = useSelfUserProfile();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <GridContainer>
      {messages.length > 0 && (
        <div style={{ gridArea: 'messages' }}>
          <OnYourAgenda messages={messages} />
        </div>
      )}
      <div style={{ gridArea: 'sidebar' }}>
        {getOrUndefined(userProfileResource)?.canBrowsePlatform && (
          <FeaturedCompanyMatchesForCurrentUser className="u-section-spacing-bottom" />
        )}
      </div>
      <div style={{ gridArea: 'top' }}>
        <Resource resource={userProfileResource}>{user => <Pulse activityTypes={getActivityTypes(user)} />}</Resource>
      </div>
    </GridContainer>
  );
}
