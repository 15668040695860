import React from 'react';
import { FolderDTO } from 'types/documents';
import { UserProfile } from 'types/user';
import DocumentListing from './DocumentListing';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import { TrustLevel } from 'types/company';

interface Props {
  company: ICompany;
  isCommunity: boolean;
  trustLevels: TrustLevel[];
  folderId?: FolderDTO['id'];
  userProfiles: UserProfile[];
  folders: FolderDTO[];
  canUploadDocuments: boolean;
  viewAs: CompanyViewAs;
  folderNavigation: string | ((folderId: string) => void);
}

export default function FolderContents({
  folderId,
  company,
  isCommunity,
  folders,
  trustLevels,
  userProfiles,
  canUploadDocuments,
  viewAs,
  folderNavigation,
}: Props) {
  const canViewDocuments = (trustLevels: TrustLevel[]) => {
    return (
      (viewAs === 'Follower' &&
        trustLevels.some(trustLevel => trustLevel === 'semi_trusted' || trustLevel === 'public')) ||
      viewAs === 'Admin' ||
      viewAs === 'Shareholder' ||
      viewAs === 'Board member'
    );
  };

  const foldersToShow = folderId
    ? folders.filter(f => f.parentId === folderId && canViewDocuments(f.accesses.map(it => it.trustLevel)))
    : folders.filter(f => f.parentId === null && canViewDocuments(f.accesses.map(it => it.trustLevel)));

  return (
    <DocumentListing
      trustLevels={trustLevels}
      folderId={folderId}
      folders={folders}
      foldersToShow={foldersToShow}
      company={company}
      isCommunity={isCommunity}
      userProfiles={userProfiles}
      canUploadDocuments={canUploadDocuments}
      folderNavigation={folderNavigation}
    />
  );
}
