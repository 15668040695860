import { IframelyResponse, IframelyApi } from 'apis/iframelyApi';
import React, { useState, useEffect } from 'react';
import LinkPreview from 'ui/elements/LinkPreview';

export default function IframelyLinkPreview({ link }: { link: string }) {
  const [meta, setMeta] = useState<IframelyResponse>();
  useEffect(() => {
    if (link) {
      IframelyApi()
        .get(link)
        .then(result => {
          setMeta(result);
        });
    }
  }, [link]);

  if (!meta) {
    return null;
  }

  return <LinkPreview meta={meta} />;
}
