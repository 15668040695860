import React, { useEffect, useState } from 'react';
import { OpenAiTextCompletion } from './completionsAPI';
import { concat } from './AiMenu';
import { useInterval } from 'hooks/useInterval';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const blink = keyframes`
  50% {
    opacity: 0;
  }
  `;

const Blinker = styled.div`
  animation: ${blink} 1.7s ease infinite;
`;

// This displays a stream of text as it comes in from the server, with an added delay
// to make the text flow better. The text comes in chunks, and will appear laggy if we
// display them as they come in. Instead we queue them up and display one word at a time,
// so it looks like the text is being typed out. When we've recieved all the text, we show the whole thing.
export default function StreamingText({ lines }: { lines: OpenAiTextCompletion[]; isComplete: boolean }) {
  const [visibleText, setVisibleText] = useState<OpenAiTextCompletion[]>([]);
  const [index, setIndex] = useState(0);
  const [actualLines, setActualLines] = useState<OpenAiTextCompletion[]>([]);

  const simulateTypeEffect = () => {
    const nextValue = actualLines[index];
    if (nextValue) {
      setIndex(prev => prev + 1);
      // for some reason the first character is always a newline
      if (nextValue.choices[0].text === '\n' && visibleText.length === 0) {
        return;
      }
      setVisibleText(prev => [...prev, nextValue]);
    }
  };

  useEffect(() => {
    setActualLines(lines);
  }, [lines.length]);

  useInterval(() => {
    simulateTypeEffect();
  }, 25);

  if (lines.length === 0) return <Blinker>&hellip;</Blinker>;

  return <>{concat(visibleText)}</>;
}
