import { Organization, UpdateOrganizationDTO } from 'types/organization';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import OrganizationAPI from 'apis/OrganizationAPI/index';
import { invalidate } from 'hooks/useSWR';

export const useUpdateOrganization = (
  organization: Organization,
  options?: { onSuccess?: () => void; onFailure?: () => void },
) => {
  const notify = useNotify();

  return useLazyResource(
    (update: Partial<UpdateOrganizationDTO>) =>
      OrganizationAPI.update(organization.id, {
        name: organization.name,
        description: organization.description,
        website: organization.website,
        location: organization.location,
        established: organization.established?.toString(),
        logoUrl: organization.logoUrl,
        bannerUrl: organization.bannerUrl,
        ...update,
      }),
    {
      onSuccess: (result: Organization) => {
        options?.onSuccess && options.onSuccess();
        invalidate(OrganizationAPI.getBySlug(organization.slug), result);
        invalidate(OrganizationAPI.listAllUsersUrl(organization.id));
      },
      onFailure: () => {
        options?.onFailure && options.onFailure();
        notify('error', `Something unexpected happened when trying to update organization information.`);
      },
    },
  );
};
