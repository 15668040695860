import React from 'react';
import { ICompany } from 'types/company';
import Logo from 'ui/domain/Logo';

interface Props {
  company?: ICompany;
  size?: number;
  className?: string;
}

export default function CompanyLogo(props: Props) {
  return <Logo url={props.company?.logoURL} type="company" size={props.size || 50} className={props.className} />;
}
