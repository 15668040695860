import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function DollarSignIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18.4 8.1C18.4 6 15.5 4 12 4 8.5 4 5.6 5.9 5.6 8.1c0 2.3 1.7 3.6 6.4 3.6s7 1.2 7 4.2-3.1 4.1-7 4.1-7-1.9-7-4.1" />
      <path d="M12 2v20" />
    </SvgIcon>
  );
}
