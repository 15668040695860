import { ActionTypes as CompanyActionTypes } from 'globalstate/company/details/actions';
import { Middleware } from 'redux';

const extractCompanyIdFromPath = (path: string) => {
  const regex = /\/(companies|communities)\/(\d+).*/;
  const matchNew = regex.exec(path);
  return matchNew && matchNew[2];
};

export interface LocationChangeAction {
  type: string;
  pathname: string;
  previousPathname?: string;
}

export const LOCATION_CHANGED_ACTION = 'location changed';

const companyChangedMiddleware: Middleware = api => next => (action: LocationChangeAction) => {
  if (action.type === LOCATION_CHANGED_ACTION) {
    const loc = action.previousPathname;
    if (loc) {
      const prevId = extractCompanyIdFromPath(loc);
      const newId = extractCompanyIdFromPath(action.pathname);

      if (prevId !== newId) {
        const result = next(action);
        api.dispatch({
          type: CompanyActionTypes.COMPANY_CHANGED,
          payload: { id: newId ? parseInt(newId, 10) : null },
        });
        return result;
      }
    }
  }
  return next(action);
};

export default companyChangedMiddleware;
