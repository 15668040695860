import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import useDialogHandler from 'hooks/useDialogHandler';
import React from 'react';
import ChatWithUserIcon from 'ui/elements/icons/ChatBubblesIcon';
import cx from 'classnames';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { getOrUndefined } from 'util/resource';

interface Props {
  className?: string;
  user: { cwUserId: UUID };
  hasIcon?: boolean;
  link?: 'dark' | 'normal';
}
export default function ConversationLink({ user, className, hasIcon, link = 'dark' }: Props) {
  const dialogHandler = useDialogHandler();
  const { resource: userProfileResource } = useSelfUserProfile();
  const isSelf = user.cwUserId === getOrUndefined(userProfileResource)?.cwUserId;

  return isSelf ? null : (
    <>
      <button
        className={cx(className, 'u-flex-align-center text-link-small', { ['text-link-dark']: link === 'dark' })}
        onClick={dialogHandler.open}
      >
        {hasIcon && <ChatWithUserIcon className="u-quarter-spacing-right" />}
        Chat
      </button>
      {dialogHandler.isOpen && (
        <UserConversationDialog
          conversation={{ type: 'withUsers', withCwUserIds: [user.cwUserId] }}
          closeConversation={dialogHandler.close}
        />
      )}
    </>
  );
}
