import without from 'ramda/src/without';
import { ResourceIdDTO } from 'types/notify';
import { communityUrls, companyUrls, userUrls } from 'urls';

export interface ResourceIdentifier {
  resourceParent: ResourceIdDTO[];
  resourceId: ResourceIdDTO;
}

function hackFindType(notification: ResourceIdentifier): ResourceIdDTO {
  //assumes max two parents. We need to fix resource identifier logic in backend, way to complicated at the moment.
  // issue seems to be that the sort order of parents are random, but we need to find the parent that identifies what type of resource it is.

  if (
    ['feedback', 'update', 'task', 'mention-in-update', 'company-mention-in-update'].includes(
      notification.resourceParent[0].type,
    )
  ) {
    return notification.resourceParent[0];
  }
  return notification.resourceParent[1];
}

function moveUp(closestParent: ResourceIdDTO, r: ResourceIdentifier) {
  const rest = without([closestParent], r.resourceParent);
  return { resourceParent: rest, resourceId: closestParent };
}

function companyPart(companyId: string | undefined) {
  if (!companyId) {
    return undefined;
  }
  return `/ctx/${companyId}`;
}

function idPart(type: string, parents: { type: string; id: string }[]) {
  const parent = parents.find(resource => resource.type === type);
  if (parent == null) {
    return undefined;
  }
  return parent.id;
}

function userPart(parents: { type: string; id: string }[]) {
  const user = parents.find(resource => resource.type === 'user');
  if (user == null) {
    return undefined;
  }
  return user.id;
}

function linkUpdate(notification: ResourceIdentifier): NotifyLink {
  const companyId = idPart('company', notification.resourceParent);
  if (!companyId) {
    return directUrl('#');
  }
  return {
    type: 'company',
    companyId,
    url: (slug: string) => `${companyPart(slug)}/updates/${notification.resourceId.id}`,
  };
}

function linkRoomUpdate(notification: ResourceIdentifier): NotifyLink {
  const roomId = idPart('room', notification.resourceParent);
  const companyId = idPart('company', notification.resourceParent);
  if (roomId === undefined || companyId === undefined) {
    return directUrl('#');
  }

  return {
    type: 'company',
    companyId,
    url: slug => companyUrls.rooms.viewUpdate(slug, roomId, notification.resourceId.id),
  };
}

function linkRoom(notification: ResourceIdentifier): NotifyLink {
  const roomId = idPart('room', notification.resourceParent);
  const companyId = idPart('company', notification.resourceParent);
  if (roomId === undefined || companyId === undefined) {
    return directUrl('#');
  }

  return {
    type: 'company',
    companyId,
    url: slug => companyUrls.rooms.view(slug, roomId),
  };
}

function linkContactUs(notification: ResourceIdentifier): NotifyLink {
  const companyId = idPart('company', notification.resourceParent);
  if (!companyId) {
    return directUrl('#');
  }
  return {
    type: 'company',
    companyId,
    url: slug => `${slug}/inbox/${notification.resourceId.id}`,
  };
}

function linkConversation(notification: ResourceIdentifier): NotifyLink {
  // User-user conversation
  if (notification.resourceParent.filter(r => r.type == 'user').length == 2) {
    return directUrl(userUrls.conversations.viewUserUserConversation(notification.resourceId.id));
  }
  return linkCompanyConversation(notification);
}

function linkUserConversation(notification: ResourceIdentifier) {
  return directUrl(`/conversation/${idPart('conversation', notification.resourceParent)}`);
}

function linkCompanyConversation(notification: ResourceIdentifier): NotifyLink {
  const companyId = idPart('company', notification.resourceParent);
  if (!companyId) {
    return directUrl('#');
  }
  return {
    type: 'company',
    companyId,
    url: slug => `${companyPart(slug)}/conversation/${userPart(notification.resourceParent)}`,
  };
}

function linkDiscussions(notification: ResourceIdentifier) {
  const communityId = idPart('communityId', notification.resourceParent);
  const companyId = idPart('companyId', notification.resourceParent);
  if (communityId && companyId) {
    return directUrl(communityUrls.discussion.view(communityId, companyId));
  }
  return directUrl('#');
}

function linkComment(notification: ResourceIdentifier): NotifyLink {
  const closestParent = hackFindType(notification);
  if (closestParent == null) {
    return directUrl('#');
  }
  switch (closestParent.type) {
    case 'room-update':
      return linkRoomUpdate(moveUp(closestParent, notification));
    case 'feedback':
      return linkContactUs(moveUp(closestParent, notification));
    case 'update':
      return linkUpdate(moveUp(closestParent, notification));
    default:
      return directUrl('#');
  }
}

function linkFollowerRequestSent(notification: ResourceIdentifier): NotifyLink {
  const companyId = idPart('company', notification.resourceParent);
  if (!companyId) {
    return directUrl('#');
  }
  return {
    type: 'company',
    companyId,
    url: slug => companyUrls.settings.users(slug, 'requests'),
  };
}

function linkFollowerRequestAccepted(notification: ResourceIdentifier): NotifyLink {
  const companyId = idPart('company', notification.resourceParent);
  if (!companyId) {
    return directUrl('#');
  }
  return {
    type: 'company',
    companyId,
    url: slug => companyUrls.overview(slug, 'updates'),
  };
}

function directUrl(url: string): NotifyLink {
  return { type: 'direct', url };
}

// Notify uses company id to identify companies
// However, our links expect the company slug. This structure
// lets the user of the link resolve the company slug from the company id where needed
type NotifyLink =
  | {
      type: 'company';
      companyId: string;
      url: (companySlug: string) => string;
    }
  | {
      type: 'direct';
      url: string;
    };

export default function notifyLink(notification: ResourceIdentifier | null): NotifyLink {
  if (notification == null) {
    return directUrl('#');
  }

  switch (notification.resourceId.type) {
    case 'update':
    case 'mention-in-update':
    case 'company-mention-in-update':
      return linkUpdate(notification);
    case 'room-update':
    case 'mention-in-room-update':
      return linkRoomUpdate(notification);
    case 'room-user-added':
      return linkRoom(notification);
    case 'feedback':
      return linkContactUs(notification);
    case 'comment':
    case 'mention-in-comment':
    case 'company-mention-in-comment':
      return linkComment(notification);
    case 'conversation':
      return linkConversation(notification);
    case 'user-conversation':
      return linkUserConversation(notification);
    case 'company-conversation':
      return linkCompanyConversation(notification);
    case 'discussion':
      return linkDiscussions(notification);
    case 'follower-request-sent':
      return linkFollowerRequestSent(notification);
    case 'follower-request-accepted':
      return linkFollowerRequestAccepted(notification);
    default:
      return directUrl('#');
  }
}
