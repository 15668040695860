import config from 'config';
import { userUrls } from 'urls';

type CookieCategory = 'necessary' | 'analytics';

interface Cookie {
  categories: CookieCategory[];
}

const pluginConfig = {
  current_lang: 'en',
  autoclear_cookies: true,
  page_scripts: true,
  delay: 500, // if user isn't logged in, we don't want this to pop up right before they are redirected to log in

  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences: any, cookie: Cookie) {},

  onAccept: function (cookie: Cookie) {
    if (cookie.categories.includes('analytics')) {
      const _Intercom: Intercom_.IntercomStatic = (window as any).Intercom;
      _Intercom('boot', {
        app_id: config.INTERCOM_APP_ID,
      });
    }
  },

  onChange: function (cookie: any, changed_preferences: any) {},

  languages: {
    en: {
      consent_modal: {
        title: 'We use cookies!',
        description:
          'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Reject all',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie Settings',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }, { col4: 'Description' }],
        blocks: [
          {
            title: 'Cookie usage 📢',
            description: `We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href=${userUrls.legal.privacyPolicy()} class="cc-link">privacy policy</a>.`,
          },
          {
            title: 'Strictly necessary cookies',
            description:
              'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
            cookie_table: [
              {
                col1: '^_legacy_auth0',
                col2: 'auth0.com',
                col3: '1 day',
                col4: 'Authentication - used for a better user experience in keeping users logged in',
                is_regex: true,
              },
              {
                col1: '^auth0',
                col2: 'auth0.com',
                col3: '1 day',
                col4: 'Authentication - used for a better user experience in keeping users logged in',
                is_regex: true,
              },
              {
                col1: 'cc_cookie',
                col2: 'app.crowdworks.it',
                col3: '182 days',
                col4: "Stores the user's cookie consent state for the current domain",
              },
            ],
          },
          {
            title: 'Performance and Analytics cookies',
            description: 'These cookies allow the website to remember the choices you have made in the past',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: '^_hp2_id', // match all cookies starting with "_ga"
                col2: '.crowdworks.it',
                col3: '13 months',
                col4: 'User cookie (stores user_id, identity, other IDs)',
                is_regex: true,
              },
              {
                col1: '^_hp2_ses_props',
                col2: '.crowdworks.it',
                col3: '30 minutes',
                col4: 'Session properties cookie (stores timestamp and cookie domain/path)',
                is_regex: true,
              },
              {
                col1: '^_hp2_props',
                col2: '.crowdworks.it',
                col3: '13 months',
                col4: 'Event properties cookie (stores properties set by the addEventProperties API)',
                is_regex: true,
              },
              {
                col1: '^intercom-id',
                col2: '.crowdworks.it',
                col3: '9 months',
                col4: 'Anonymous visiter identifier cookie.',
                is_regex: true,
              },
              {
                col1: '^intercom-session',
                col2: '.crowdworks.it',
                col3: '1 week',
                col4: 'Identifier for each unique session. The user can access their conversations and have data communicated on logged out pages for 1 week',
                is_regex: true,
              },
              {
                col1: '^intercom-device-id',
                col2: '.crowdworks.it',
                col3: '9 months',
                col4: 'Allows users to access their intercom conversations and have data communicated on logged out pages for 1 week.',
                is_regex: true,
              },
            ],
          },
          {
            title: 'More information',
            description:
              'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
          },
        ],
      },
    },
  },
};

export default pluginConfig;
