import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CodeIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m17 8 1.8 1.9c.8.7 1.2 1.1 1.2 1.6s-.4.9-1.2 1.6L17 15M7 8 5.2 9.9C4.4 10.6 4 11 4 11.5s.4.9 1.2 1.6L7 15M14.5 4l-5 16" />
    </SvgIcon>
  );
}
