import React from 'react';
import Avatar from 'ui/elements/avatars/Avatar';
import styles from 'ui/elements/avatars/styles.scss';

type User = {
  imageUrl?: string;
  name: string;
};

interface Props {
  users: User[];
}

const srcImageSize = 100;
const rootstyle = { height: 50, width: 50, flexShrink: 0 };

export default function Avatars(props: Props) {
  const { users } = props;

  if (users.length === 1) {
    return (
      <Avatar
        userName={users[0].name ?? ''}
        imageUrl={users[0].imageUrl ?? ''}
        resize={srcImageSize}
        style={rootstyle}
      />
    );
  } else if (users.length > 1) {
    return (
      <div className={styles.avatars} style={rootstyle}>
        {users.slice(0, 3).map((user, idx) => {
          return (
            <Avatar
              key={`${user.name} - ${idx}`}
              size={32 - idx * 6}
              style={{
                position: 'absolute',
                left: idx === 0 ? 0 : idx === 1 ? 24 : 12,
                top: idx === 0 ? 0 : idx === 1 ? 12 : 24,
                border: '2px solid white',
              }}
              userName={user.name}
              imageUrl={user.imageUrl}
              resize={srcImageSize}
            />
          );
        })}
      </div>
    );
  } else {
    return null;
  }
}
