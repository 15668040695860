import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ChevronUpIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m6 15 6-6 6 6" />
    </SvgIcon>
  );
}
