import styled from '@emotion/styled';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import cx from 'classnames';
import { contentSpacing } from 'ui/theme/themeConstants';
import { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import { css } from '@emotion/react';

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: Children;
  className?: string;
}

const styles = css`
  color: ${bluePlanetTheme.bluePlanetPalette.indigo.main};
  background-color: ${bluePlanetTheme.bluePlanetPalette.blue.veryLight};
  padding: 0 ${contentSpacing};
  font-size: ${fontSizeSmall};
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  border-radius: ${bluePlanetTheme.shape.borderRadius}px;
  display: flex;
  align-items: center;
  min-height: 60px;
  width: 100%;
`;

const StyledButton = styled.button`
  ${styles}
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background-color: ${bluePlanetTheme.bluePlanetPalette.blue.light};
  }
  &:focus:not(:active) {
    z-index: 10; // Make sure outline is visible
    outline-color: ${bluePlanetTheme.bluePlanetPalette.blue.medium};
    outline-width: 2px;
    outline-style: solid;
    -moz-outline-radius: 8px;
  }
`;

const StyledDiv = styled.div`
  ${styles}
`;

export default function MenuButton({ onClick, className, children }: Props) {
  return onClick ? (
    <StyledButton className={className} onClick={onClick}>
      {children}
    </StyledButton>
  ) : (
    <StyledDiv>{children}</StyledDiv>
  );
}

export function ButtonMenu({ className, children, ...rest }: { className?: string; children: Children }) {
  return (
    <div {...rest} className={cx(className, 'u-flex u-flex--column u-flex--gap-quarter')}>
      {children}
    </div>
  );
}
