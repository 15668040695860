import { LinearProgress as MUILinearProgress } from '@mui/material';
import React, { CSSProperties } from 'react';
import { bluePlanetTheme } from 'ui/theme';

type Color = 'green' | 'yellow' | 'orange' | 'red' | 'blue';

interface Props {
  color?: Color | 'auto';
  className?: string;
  percentage: number;
  style?: CSSProperties;
}

function getColor(color: Color | 'auto', percentage: number) {
  const palette = bluePlanetTheme.bluePlanetPalette;

  switch (color) {
    case 'auto': {
      if (percentage >= 75) return palette.green.main;
      if (percentage >= 40) return palette.orange.medium;
      return palette.red.main;
    }
    case 'green':
      return palette.green.main;
    case 'orange':
      return palette.orange.medium;
    case 'red':
      return palette.red.main;
    case 'yellow':
      return palette.yellow.main;
    default:
      return palette.blue.main;
  }
}

export default function ProgressBar({ color = 'auto', percentage, ...rest }: Props) {
  return (
    <MUILinearProgress
      sx={{
        backgroundColor: bluePlanetTheme.bluePlanetPalette.grey.medium,
        '& .MuiLinearProgress-bar': {
          backgroundColor: getColor(color, percentage),
        },
        width: '100%',
        borderRadius: '8px',
      }}
      variant="determinate"
      value={percentage}
      {...rest}
    />
  );
}
