import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import Fuse from 'fuse.js';
import uniqBy from 'ramda/src/uniqBy';
import Label from 'ui/elements/form/Label';
import MultiSelect from 'ui/elements/form/Select/MultiSelect';
import Chip from 'ui/elements/Chip';

const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${bluePlanetTheme.spacing(1)};

  > * {
    width: calc(50% - ${bluePlanetTheme.spacing(1)});
    flex-grow: 1;
  }

  ${bluePlanetTheme.breakpoints.down('sm')} {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
`;

function getFuse<T>(items: T[]) {
  return new Fuse(items, {
    keys: [
      { name: 'name', weight: 2 },
      { name: 'tags', weight: 1 },
    ],
    threshold: 0.3,
  });
}

interface Props<T> {
  name: string;
  placeholder: string;
  label?: string;
  selectedItems: T[];
  setItems: (t: T[]) => void;
  errorMessage?: string | ReactNode;
  optionsValues: T[];
  autoFocus?: boolean;
  hideSelectedValues?: boolean;
  optionLabel: (t: T) => string;
  optionalOnAdd?: (input: string, optionsLength: number, selectedItem?: T) => void;
  style?: React.CSSProperties;
  className?: string;
  onLabelClick?: () => void;
}

interface PickedChipProps {
  label: string;
  className?: string;
  onDelete?: () => void;
  onClick?: () => void;
}

const PickedChip = (props: PickedChipProps) => {
  return <Chip color="blue-light" onClick={props.onClick} {...props} />;
};

export default function Picker<T>({
  name,
  placeholder,
  label,
  selectedItems,
  setItems,
  optionsValues,
  errorMessage,
  autoFocus,
  optionLabel,
  hideSelectedValues,
  optionalOnAdd,
  style,
  className,
  onLabelClick,
}: Props<T>) {
  const [input, setInput] = useState('');

  const [options, setOptions] = useState<T[]>(optionsValues);
  const fuse = getFuse<T>(optionsValues);

  const onAdd = (items: T[], selectedItem?: T) => {
    optionalOnAdd?.(input, options.length, selectedItem);
    setItems(uniqBy(it => it, [...selectedItems, ...items]));
  };
  const onRemove = (item: T) => setItems(selectedItems.filter(v => v !== item));

  const onInputChange = (value: string) => {
    setInput(value);
    if (!value) {
      setOptions(optionsValues);
      return;
    }

    const result = fuse.search(value, { limit: 10 });
    setOptions(result.map(r => r.item));
  };

  const renderValue = !hideSelectedValues ? PickedChip : () => <></>;

  return (
    <div style={style} className={className}>
      {label && (
        <div onClick={onLabelClick}>
          <Label>{label}</Label>
        </div>
      )}
      <SelectWrapper>
        <MultiSelect
          name={name}
          placeholder={placeholder}
          onChange={onAdd}
          onRemove={onRemove}
          getOptionLabel={optionLabel}
          onInputChange={onInputChange}
          options={options}
          error={errorMessage}
          helperText={errorMessage?.toString()}
          autoFocus={autoFocus}
          values={selectedItems}
          renderValue={renderValue}
          disableFilter
        />
      </SelectWrapper>
    </div>
  );
}
