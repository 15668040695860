import SvgIcon, { IconProps } from '../SvgIcon';
import React from 'react';

export default function DocxIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3.5 13v-.8c0-2.97 0-4.45.47-5.64a7.24 7.24 0 0 1 4.37-4.12C9.6 2 11.17 2 14.32 2c1.8 0 2.7 0 3.42.25a4.14 4.14 0 0 1 2.5 2.36c.26.67.26 1.52.26 3.22V13" />
      <path d="M3.5 12c0-1.84 1.5-3.33 3.33-3.33.67 0 1.45.11 2.1-.06.58-.15 1.03-.6 1.18-1.18.17-.65.06-1.43.06-2.1C10.17 3.5 11.66 2 13.5 2" />
      <path d="M5.52 21.44c1.56 0 2.82-1.34 2.82-3 0-1.65-1.26-3-2.82-3-.35 0-.53 0-.66.09-.32.19-.28.58-.28.91v4c0 .34-.04.73.28.92.13.08.3.08.66.08ZM19.42 16.44c0-.33.04-.72-.28-.91-.13-.09-.3-.09-.66-.09-1.56 0-2.82 1.35-2.82 3 0 1.66 1.26 3 2.82 3 .35 0 .53 0 .66-.08.32-.19.28-.58.28-.92" />
      <ellipse cx="12.11" cy="18.44" rx="1.6" ry="3" />
    </SvgIcon>
  );
}
