import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function GridIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2 18c0-1.5 0-2.3.3-2.9l.8-.8C3.7 14 4.5 14 6 14s2.3 0 2.9.3l.8.8c.3.6.3 1.4.3 2.9s0 2.3-.3 2.9l-.8.8c-.6.3-1.4.3-2.9.3s-2.3 0-2.9-.3l-.8-.8C2 20.3 2 19.5 2 18ZM14 18c0-1.5 0-2.3.3-2.9l.8-.8c.6-.3 1.4-.3 2.9-.3s2.3 0 2.9.3l.8.8c.3.6.3 1.4.3 2.9s0 2.3-.3 2.9l-.8.8c-.6.3-1.4.3-2.9.3s-2.3 0-2.9-.3l-.8-.8c-.3-.6-.3-1.4-.3-2.9ZM2 6c0-1.5 0-2.3.3-2.9l.8-.8C3.7 2 4.5 2 6 2s2.3 0 2.9.3l.8.8c.3.6.3 1.4.3 2.9s0 2.3-.3 2.9l-.8.8c-.6.3-1.4.3-2.9.3s-2.3 0-2.9-.3l-.8-.8C2 8.3 2 7.5 2 6ZM14 6c0-1.5 0-2.3.3-2.9l.8-.8C15.7 2 16.5 2 18 2s2.3 0 2.9.3l.8.8c.3.6.3 1.4.3 2.9s0 2.3-.3 2.9l-.8.8c-.6.3-1.4.3-2.9.3s-2.3 0-2.9-.3l-.8-.8C14 8.3 14 7.5 14 6Z" />
    </SvgIcon>
  );
}
