import { KeyedMutator, SWRResponse } from 'swr';
import { fetched, fetchError, idle, isFetching, IResource } from '.';

export const useSWRResource = <T>(
  response: SWRResponse<T, any>,
): { resource: IResource<T>; mutate: KeyedMutator<T> } => {
  if (response.error) {
    return {
      resource: fetchError(404),
      mutate: response.mutate,
    };
  }

  if (response.data) {
    return {
      resource: fetched(response.data, response.isValidating),
      mutate: response.mutate,
    };
  }
  if (response.isLoading || response.isValidating) {
    return {
      resource: isFetching(),
      mutate: response.mutate,
    };
  }

  return {
    resource: idle,
    mutate: response.mutate,
  };
};
