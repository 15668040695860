import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CopyIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M9 15c0-2.8 0-4.2.9-5.1.9-.9 2.3-.9 5.1-.9h1c2.8 0 4.2 0 5.1.9.9.9.9 2.3.9 5.1v1c0 2.8 0 4.2-.9 5.1-.9.9-2.3.9-5.1.9h-1c-2.8 0-4.2 0-5.1-.9C9 20.2 9 18.8 9 16v-1Z" />
      <path d="M17 9c0-3 0-4.5-1-5.5a4 4 0 0 0-.5-.6c-1-.9-2.7-.9-6-.9s-5 0-6 1a4 4 0 0 0-.6.5c-.9 1-.9 2.7-.9 6s0 5 1 6c0 .2.3.4.5.6 1 .9 2.5.9 5.5.9" />
    </SvgIcon>
  );
}
