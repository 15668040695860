import { uploadToCloudinary } from 'apis/cloudinary';
import { CloudinaryUploadResponse, CloudinaryUploadSettings } from 'types';

export default async function uploadImage(
  getUploadUrl: (fileName: string) => Promise<CloudinaryUploadSettings>,
  canvas: HTMLCanvasElement,
  image?: File,
  onUploadStart?: () => void,
  onSuccess?: (image: CloudinaryUploadResponse) => void,
  onFailure?: (error: any) => void,
) {
  onUploadStart && onUploadStart();

  try {
    const fileType = image && image.type;
    const fileName = image && image.name;
    const result = await getUploadUrl(fileName || 'filename');

    canvas.toBlob(async (image: Blob) => {
      try {
        const cloudinary = await uploadToCloudinary(result, image);
        onSuccess && onSuccess(cloudinary);
      } catch (e) {
        onFailure && onFailure(e);
      }
    }, fileType || undefined);
  } catch (e) {
    onFailure && onFailure(e);
  }
}
