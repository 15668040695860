import React, { AllHTMLAttributes, PropsWithChildren } from 'react';
import styles from './styles.scss';
import cx from 'classnames';
import { CompactLabelDTO } from 'types';
import { formatShortLocation } from 'util/locationUtils';
import { Location } from 'apis/MapboxAPI';

type DetailProps = {
  label: string;
  children?: PropsWithChildren<AllHTMLAttributes<HTMLLIElement>['children']>;
};

function CompanyDetail({ label, children }: DetailProps) {
  return (
    <li style={{ overflow: 'hidden' }}>
      <div className="text-font-text text-tiny text-weight-medium" style={{ whiteSpace: 'nowrap' }}>
        {children || '-'}
      </div>
      <div className="text-metadata text-nowrap">{label}</div>
    </li>
  );
}

type DetailsProps = {
  location?: Location;
  established?: string;
  fundingStage?: CompactLabelDTO;
  className?: AllHTMLAttributes<HTMLUListElement>['className'];
};

export default function CompanyDetails({ location, established, fundingStage, className }: DetailsProps) {
  return (
    <ul className={cx(styles.companyDetails, className, 'u-half-spacing-y')} style={{ listStyle: 'none' }}>
      <CompanyDetail label="Est.">{established}</CompanyDetail>
      <CompanyDetail label="Location">{formatShortLocation(location)}</CompanyDetail>
      <CompanyDetail label="Funding stage">{fundingStage?.name}</CompanyDetail>
    </ul>
  );
}
