import { useRef, useEffect } from 'react';

export default function useDebouncedIntersectionObserver(
  pageRefs: React.RefObject<any>[],
  onPageChange: (num: number) => void,
  debounceDelay: number,
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && pageRefs.length > 0) {
            const index = pageRefs.findIndex(ref => ref.current === entry.target);
            if (index !== -1) {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
              timeoutRef.current = setTimeout(() => {
                onPageChange(index + 1);
              }, debounceDelay);
            }
          }
        });
      },
      { threshold: 0.5 },
    );

    if (pageRefs.length > 0) {
      pageRefs.forEach(ref => {
        if (ref.current) {
          intersectionObserver.observe(ref.current);
        }
      });
    }

    return () => {
      intersectionObserver.disconnect();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [pageRefs, onPageChange, debounceDelay]);
}
