import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function PitchIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3 17.8h18v-9c0-2.8 0-4.2-.9-5.1-.9-.9-2.3-.9-5.1-.9H9c-2.8 0-4.2 0-5.1.9C3 4.6 3 6 3 8.8v9Z" />
      <path d="M2 17.8h20M7 21.8l5-3 5 3M12 18.1V22" />
    </SvgIcon>
  );
}
