import React, { useState } from 'react';
import { FeedbackContext } from 'types/user';
import LinkButton from 'ui/elements/buttons/LinkButton';
import Card from 'ui/views/cards/Card';
import FeedbackDialog from './FeedbackDialog';

interface Props {
  message: string;
  feedbackContext: FeedbackContext;
}

export default function FeedbackCard({ message, feedbackContext }: Props) {
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  return (
    <>
      <Card color="transparent" type="solid">
        <div className="u-quarter-spacing-bottom">
          <b>Do you have feedback or questions?</b>
        </div>
        <p className="u-quarter-spacing-bottom">{message}</p>
        <LinkButton
          onClick={() => {
            setIsFeedbackDialogOpen(true);
          }}
        >
          Get in touch
        </LinkButton>
      </Card>
      <FeedbackDialog
        feedbackContext={feedbackContext}
        isOpen={isFeedbackDialogOpen}
        onClose={() => setIsFeedbackDialogOpen(false)}
      />
    </>
  );
}
