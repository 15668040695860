import React from 'react';
import { staticFileLocations } from 'urls';
import { resize } from 'util/cloudinary';
import { BANNER_IMAGE_WIDTH } from 'util/constants';
import classes from './styles.scss';

interface Props {
  imageURL?: string;
}

export default function Banner(props: Props) {
  const backgroundImage = resize(props.imageURL || staticFileLocations.defaultCompanyImage, {
    width: BANNER_IMAGE_WIDTH,
  });
  return (
    <div className={classes.banner}>
      <div className={classes.bannerWrapper} style={{ backgroundImage: `url(${backgroundImage})` }} />
    </div>
  );
}
