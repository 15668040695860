import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import IconButton from 'ui/elements/icons/IconButton';
import SettingsIcon from 'ui/elements/icons/SettingsIcon';
import styles from './styles.scss';

interface Props {
  onClick: () => void;
  text: string;
}

export default function ConversationSettingsButton({ onClick, text }: Props) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  return isMdUp ? (
    <div className={styles.settingsButton} onClick={onClick}>
      <SettingsIcon fontSize="small" />
      <span className="u-quarter-spacing-left">{text}</span>
    </div>
  ) : (
    <div className="u-half-spacing-x" style={{ alignSelf: 'center' }} onClick={onClick}>
      <IconButton color="indigo">
        <SettingsIcon fontSize="small" />
      </IconButton>
    </div>
  );
}
