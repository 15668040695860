import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function LinkIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M13.5 17H17a5 5 0 0 0 0-10h-3.5m-3 10H7A5 5 0 0 1 7 7h3.5M9 12h6" />
    </SvgIcon>
  );
}
