import styled from '@emotion/styled';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import { fontSizeSmall, shape } from 'ui/theme/themeBluePlanet';
import { contentSpacing } from 'ui/theme/themeConstants';

const ToggleButtonStyled = styled.button(
  (props: { isSelected?: boolean }) => `
    border-radius: ${shape.borderRadius}px;
    border: 1px solid ${bluePlanetTheme.bluePlanetPalette.grey.medium};
    padding: ${contentSpacing};
    background-color: ${props.isSelected ? bluePlanetTheme.bluePlanetPalette.blue.main : 'white'};
    color: ${props.isSelected ? bluePlanetTheme.bluePlanetPalette.white : 'inherit'};
    transition: all 0.2s;
    
    &:hover {
      filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.07));
    }
  `,
);

const Description = styled.p`
  margin-top: ${contentSpacing};
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  font-size: ${fontSizeSmall};
`;

export const ToggleButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  ${bluePlanetTheme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;

export default function ToggleButton({
  isSelected,
  title,
  description,
  onClick,
}: {
  isSelected?: boolean;
  title: string;
  description: string;
  onClick?: () => void;
}) {
  return (
    <ToggleButtonStyled isSelected={isSelected} onClick={onClick}>
      <h4 className="text-h4">{title}</h4>
      <Description>{description}</Description>
    </ToggleButtonStyled>
  );
}
