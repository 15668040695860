import React from 'react';
import UserConversationDialog from './UserConversationDialog';
import conversationApi from 'apis/ContentAPI/conversationApi';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import Resource from 'util/resource/Resource';
import { userUrls } from 'urls';
import useRoute from 'hooks/useRoute';

export default function UserUserConversationDialog(props: { conversationId: string }) {
  const { push } = useRoute();

  const [conversationResource] = useResourceLegacy(
    () => conversationApi.user.get(props.conversationId),
    [props.conversationId],
  );

  return (
    <Resource resource={conversationResource} renderError="Nothing" renderLoading="Nothing">
      {conversation => (
        <UserConversationDialog
          conversation={{ type: 'existing', conversation }}
          closeConversation={() => push(userUrls.dashboard.home())}
        />
      )}
    </Resource>
  );
}
