import { Organization } from 'types/organization';
import React, { useState } from 'react';
import Radio from 'ui/elements/form/choice/Radio';
import Button from 'ui/elements/buttons/Button';
import useLazyResource from 'util/resource/useLazyResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import useNotify from 'hooks/useNotify';
import { invalidate } from 'hooks/useSWR';

interface VisibilitySettingsProps {
  organization: Organization;
}

export function VisibilitySettings(props: VisibilitySettingsProps) {
  const options = [
    {
      id: 'public',
      title: 'PUBLIC',
      description:
        'All CrowdWorks members can see your full profile, while a limited view is accessible for people outside of CrowdWorks.',
    },
    {
      id: 'restricted',
      title: 'LIMITED',
      description: 'Only CrowdWorks members can view your profile.',
    },
  ];

  const [selectedOptionId, setSelectedOptionId] = useState<string>(
    props.organization.isInternetPublic ? 'public' : 'restricted',
  );

  const notify = useNotify();
  const [updateVisibility, isUpdatingVisibility] = useLazyResource(
    () => OrganizationAPI.updateOrganizationVisibility(props.organization.id, selectedOptionId === 'public'),
    {
      onSuccess: () => {
        notify('success', 'Organization visibility updated');
        invalidate(OrganizationAPI.getBySlug(props.organization.slug));
      },
      onFailure: () => {
        notify('error', 'Failed to update organization visibility');
      },
    },
  );

  return (
    <div className={'u-flex u-flex--column u-flex--align-items-start u-content-spacing-x'}>
      <h4 className="text-h4 u-content-spacing-y">Organization visibility</h4>
      <div className={'u-flex u-flex--column u-flex--gap-1'}>
        {options.map(option => (
          <div
            key={option.id}
            className={'u-flex u-flex--gap-half u-flex-align-center'}
            onClick={() => setSelectedOptionId(option.id)}
          >
            <Radio checked={option.id === selectedOptionId} />
            <div className={'u-flex u-flex--column u-flex--gap-quarter'}>
              <span className={'text-subheading'}>{option.title}</span>
              <p className={'text-body'}>{option.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={'u-content-spacing-top'}>
        <Button onClick={updateVisibility} isLoading={isUpdatingVisibility} kind="primary">
          Save
        </Button>
      </div>
    </div>
  );
}
