import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function PhoneIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m4.7 14.4 2-1.4c.5-.4 1.1-.7 1.7-.8.8-.2 1.1-.6 1.1-1.5 0-2.2 5-2.4 5 0 0 .9.3 1.3 1 1.5.7.1 1.3.4 1.8.8l2 1.4c.8.6 1.3.9 1.5 1.4.2.4.2 1 .2 2 0 2 0 2.9-.5 3.5-.7.8-2.4.7-3.4.7H6.9c-1 0-2.7.1-3.4-.7-.5-.6-.5-1.6-.5-3.5 0-1 0-1.6.2-2 .2-.5.7-.8 1.5-1.4Z" />
      <path d="M14 17a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
      <path d="M7 3.7a11 11 0 0 0-3.2 1.4c-1.3.9-2 2.5-1.8 4 0 .7.6 1 1.2.9l1.4-.4c1.4-.4 1.7-1 1.9-2.3L7 3.7Zm0 0c3.2-1 6.8-1 10 0m0 0c1.4.4 2.3.8 3.2 1.4 1.3.9 2 2.5 1.8 4 0 .7-.6 1-1.2.9l-1.4-.4c-1.4-.4-1.7-1-1.9-2.3L17 3.7Z" />
    </SvgIcon>
  );
}
