import useDialogHandler, { DialogHandlerProps } from 'hooks/useDialogHandler';
import React, { useEffect, useState } from 'react';
import { Goal } from 'types/goals';
import ArchiveGoalDialog from '../EditGoal/ArchiveGoalDialog';
import CompleteGoalDialog from '../EditGoal/CompleteGoalDialog';
import DeleteGoalDialog from '../EditGoal/DeleteGoalDialog';
import UnarchiveGoalDialog from '../EditGoal/UnarchiveGoalDialog';
import GoalCard, { GoalCardAction } from './GoalCard';
import { companyUrls } from 'urls';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';

interface Props {
  company: ICompany;
  goals: Goal[];
  viewAs: CompanyViewAs;
  refresh: () => void;
}

interface ActiveGoalAction {
  goal: Goal;
  action: GoalCardAction;
}

interface GoalActionDialogProps {
  goal: Goal;
  dialogHandler: DialogHandlerProps;
}

const mapActionDialog: Record<GoalCardAction, React.ComponentType<GoalActionDialogProps>> = {
  archive: ArchiveGoalDialog,
  unarchive: UnarchiveGoalDialog,
  delete: DeleteGoalDialog,
  complete: CompleteGoalDialog,
};

export default function GoalList({ company, goals, viewAs, refresh }: Props) {
  const dialogHandler = useDialogHandler(false);
  const [activeDialog, setActiveDialog] = useState<React.ReactNode>();
  const [activeAction, setActiveAction] = useState<ActiveGoalAction>();

  const onFinishGoalAction = () => {
    setActiveAction(undefined);
    dialogHandler.close();
    refresh();
  };

  useEffect(() => {
    if (!!activeAction) {
      const { goal, action } = activeAction;
      const dialogProps = { company, goal, dialogHandler, onFinishGoalAction };
      setActiveDialog(React.createElement(mapActionDialog[action], dialogProps));
    } else {
      setActiveDialog(null);
    }
  }, [activeAction, company, dialogHandler.isOpen]);

  const onPromptGoalAction = (goal: Goal, action: GoalCardAction) => {
    setActiveAction({ goal, action });
    dialogHandler.open();
  };

  return (
    <div>
      {goals.map(goal => {
        return (
          <div className="u-content-spacing-bottom" key={`goal-${goal.id}`}>
            <GoalCard
              editHref={companyUrls.goals.edit(company.slug, goal.id)}
              viewAs={viewAs}
              goal={goal}
              onPromptGoalAction={onPromptGoalAction}
            />
          </div>
        );
      })}
      {activeDialog}
    </div>
  );
}
