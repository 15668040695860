import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import { SnackbarProps } from '@mui/material/Snackbar';
import CheckCircleIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import ErrorIcon from 'ui/elements/icons/WarningIcon';
import cx from 'classnames';
import classes from './styles.scss';
import React from 'react';
import { SnackbarVariant, SnackbarAction } from 'types/snackbar';
import Button from 'ui/elements/buttons/Button';

interface Props {
  message: string;
  variant?: SnackbarVariant;
  onClose?: SnackbarProps['onClose'];
  action?: SnackbarAction;
}

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

export default function CustomSnackbarContent(props: Props) {
  const { variant, message } = props;
  const Icon = variant && variantIcon[variant];

  return (
    <SnackbarContent
      className={cx({ [classes[variant ?? 'success']]: variant })}
      message={
        <span id="client-snackbar" className="u-flex-align-center">
          {Icon && <Icon className="u-half-spacing-right" />}
          {message}
        </span>
      }
      action={
        <>
          {props.action && (
            <span className="u-half-spacing-left">
              <Button
                size="small"
                kind="tertiary"
                color={props.action.color}
                onClick={event => {
                  props.action && props.action.onClick();
                  props.onClose && props.onClose(event, 'closebuttonclick' as any);
                }}
              >
                {props.action.text}
              </Button>
            </span>
          )}
          {props.variant && (
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              size="small"
              onClick={event => props.onClose && props.onClose(event, 'closebuttonclick' as any)} // MUI has changed this typing from string to union, but the legal values are inappropriate and borks the snackbar
            >
              <CloseIcon />
            </IconButton>
          )}
        </>
      }
    />
  );
}
