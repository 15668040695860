import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function BoardIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M10.7 15.1c0 1.6-1.3 2.9-2.9 2.9A2.9 2.9 0 0 1 5 15a2.9 2.9 0 0 1 5.7 0Z" />
      <path d="m10.4 12.6 2.5-2.5m0 0 1.8-1.7C15.1 8 16 7.2 17 8l2 2m-6 0 2 2" />
    </SvgIcon>
  );
}
