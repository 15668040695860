import cx from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  size?: 'normal' | 'small';
  style?: object;
  onClick: () => void;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  'data-intercom-target'?: string;
}
export default function LinkButton(props: Props) {
  return (
    <button
      style={{ outline: 'none', ...props.style }}
      className={cx(props.className, 'u-flex-align-center', {
        'text-link': props.size !== 'small',
        'text-link-small': props.size === 'small',
      })}
      onClick={props.onClick}
      type={props.type}
      data-intercom-target={props['data-intercom-target']}
    >
      {props.children}
    </button>
  );
}
