import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ChatAddIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.5 2.4H12c-5.5 0-10 4.2-10 9.5 0 2.6 1 4.9 2.7 6.6.4.4.6.9.5 1.4-.1.9-.5 1.7-1 2.3 1.4.3 2.9 0 4.2-.6l.8-.4.9.1 1.9.2a9.8 9.8 0 0 0 10-10.1M13.8 6.8h7m-3.5-3.5v7" />
    </SvgIcon>
  );
}
