import React, { useState } from 'react';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import TextField from 'ui/elements/form/TextField';
import Button from 'ui/elements/buttons/Button';
import i18n from 'util/i18n';
import CardStack from 'ui/views/cards/CardStack';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import ClickableCard from 'ui/views/cards/ClickableCard';
import { HELP_CENTER_URL } from 'util/constants';
import { Report } from 'apis/AntifraudAPI';

interface ReportDialogProps {
  title: string;
  onClose: () => void;
  postReport: (report: Report) => Promise<unknown>;
  isOpen: boolean;
  reasons: string[];
}

export default function ReportDialog(props: ReportDialogProps) {
  return (
    <Dialog open={props.isOpen} onClose={props.onClose} backdropColor="medium">
      <ReportDialogContent {...props} />
    </Dialog>
  );
}

function ReportDialogContent({ title, onClose, postReport: reportConversation, reasons }: ReportDialogProps) {
  const [reason, setReason] = useState<string>();
  const [elaboration, setElaboration] = useState<string>();

  const notify = useNotify();

  const [report, isSaving] = useLazyResource(
    () =>
      reportConversation({
        reason: reason ? reason : '',
        elaboration: elaboration,
      }),
    {
      onSuccess: () => {
        notify('success', `Reported submitted!`);
        onClose();
      },
      onFailure: () => notify('error', i18n('en').errors.save('report')),
    },
  );

  const subheading = (
    <>
      {reason ? (
        <p className="text-body">Do you have anything you want to add to the report (optional)?</p>
      ) : (
        <>
          <p className="text-body">
            Choose the option that fits the case. Your report will remain anonymous and the user you reported won&apos;t
            see who reported them.
          </p>
        </>
      )}
      <a
        className="text-link u-flex u-content-spacing-top"
        target="_blank"
        href={`${HELP_CENTER_URL}/articles/6215654-crowdworks-safety-guidelines`}
        rel="noreferrer"
      >
        Read more about our guidelines here
      </a>
    </>
  );

  return (
    <>
      <Title onClose={onClose} subheading={subheading}>
        {title}
      </Title>
      <Content>
        {!reason ? (
          <>
            <CardStack className="u-content-spacing-bottom">
              {reasons.map(r => (
                <ClickableCard key={r} title="" onClick={() => setReason(r)}>
                  {r}
                </ClickableCard>
              ))}
            </CardStack>
          </>
        ) : (
          <>
            <TextField
              autoFocus
              name="title"
              placeholder="This user doesn't represent a real person"
              onChange={e => setElaboration(e.target.value)}
              value={elaboration}
              multiline
              minRows={4}
            />
          </>
        )}
      </Content>
      <DialogActions>
        {reason && (
          <Button isLoading={isSaving} onClick={report} kind="primary">
            Send
          </Button>
        )}
        <Button onClick={onClose} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}
