import when from 'ramda/src/when';
import uniq from 'ramda/src/uniq';

export function addOrUpdateInList<T>(resource: T[], selector: (item: T) => boolean, transform: (i?: T) => T): T[] {
  const item = resource.find(selector);
  if (!item) {
    return [...resource, transform()];
  }
  return resource.map(item => when(selector, (t: T) => transform(t), item));
}
export function updateInList<T>(resource: T[], selector: (item: T) => boolean, transform: (i: T) => T): T[] {
  return resource.map(item => when(selector, (t: T) => transform(t), item));
}
export function toggleItemInList<T>(array: T[], item: T) {
  return array.includes(item) ? array.filter(a => a !== item) : [...array, item];
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

// move a item in a list down one level
export function moveDown<T, U>(list: T[], item: T, idSelector: (a: T) => U) {
  const index = list.findIndex(i => idSelector(i) === idSelector(item));
  if (index < 0 || index >= list.length) return list;

  const itemsBefore = list.slice(0, index);
  const selectedItem = list[index];
  const swappedItem = list[index + 1];
  const itemsAfter = list.slice(index + 2, list.length);
  return [...itemsBefore, swappedItem, selectedItem, ...itemsAfter].filter(notEmpty);
}

// move a item in a list up one level
export function moveUp<T, U>(list: T[], item: T, idSelector: (item: T) => U) {
  const index = list.findIndex(i => idSelector(i) === idSelector(item));
  if (index < 1 || index >= list.length || list.length === 1) return list;

  const selectedItem = list[index];
  const swappedItem = list[index - 1];
  const itemsBefore = list
    .slice(0, Math.max(1, index - 2))
    // ensure that the two items swapping places doesn't appear twice in the list
    .filter(i => idSelector(i) !== idSelector(swappedItem) && idSelector(i) !== idSelector(selectedItem));
  const itemsAfter = list.slice(index + 1, list.length);

  return [...itemsBefore, selectedItem, swappedItem, ...itemsAfter].filter(notEmpty);
}

// performs a shallow comparison to check if two arrays contain the same elements regardless of their order
export function haveEqualElements<T>(array1: T[], array2: T[]): boolean {
  function countOccurrences<T>(array: T[]): Map<T, number> {
    const occurrences = new Map<T, number>();
    for (const item of array) {
      occurrences.set(item, (occurrences.get(item) || 0) + 1);
    }
    return occurrences;
  }
  const occurrences1 = countOccurrences(array1);
  const occurrences2 = countOccurrences(array2);

  if (occurrences1.size !== occurrences2.size) {
    return false;
  }

  for (const [item, count1] of occurrences1) {
    const count2 = occurrences2.get(item);
    if (count2 !== count1) {
      return false;
    }
  }

  return true;
}

// generates an array of numbers from start to end
export function range(start: number, end: number) {
  return Array.from({ length: end + 1 - start }, (_, k) => k + start);
}

export const distinct = uniq;

export function chunkArray<T>(num: number, inputArray: T[]) {
  const size = Math.ceil(inputArray.length / num);
  return Array.from({ length: num }, (_, i) => inputArray.slice(i * size, i * size + size));
}
