import React from 'react';
import CompanyLogo from 'ui/elements/CompanyLogo';
import Timestamp from 'ui/elements/Timestamp';
import styles from './messages.scss';
import Indicator from 'ui/elements/Indicator/NumberIndicator';
import emailIllustration from 'ui/illustrations/envelope.svg';
import SeeMore from 'ui/modules/Pagination/SeeMore';
import { Conversation } from 'apis/ContentAPI/conversationTypes';
import { getConversationName } from '../utils';
import Avatars from 'domain/conversations/Avatars';
import { ICompany } from 'types/company';
import { UserProfile } from 'types/user';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';

interface Props {
  conversations: PaginatedResult<Conversation>;
  cwUserId: string;
  companyProfiles: ICompany[];
  userProfiles: UserProfile[];
  setActiveChat: (conversation: Conversation) => void;
  fetchConversations: (paginationOptions?: PaginationOptions | undefined) => void;
  type: 'messages' | 'requests';
}

export default function MessageList({
  conversations,
  cwUserId,
  companyProfiles,
  userProfiles,
  setActiveChat,
  fetchConversations,
  type,
}: Props) {
  return (
    <div>
      {conversations.values.map((conversation, i) => {
        const conversationWithCompany = companyProfiles.find(c =>
          conversation.type === 'user-company' ? c.id === conversation.companyConversation.companyId : undefined,
        );
        const conversationWithUsers =
          conversation.type === 'user-user'
            ? userProfiles.filter(u =>
                conversation.userConversation.activeParticipants
                  .concat(conversation.userConversation.inactiveParticipants)
                  .filter(u => u !== cwUserId)
                  .some(id => id === u.cwUserId),
              )
            : [];

        const text =
          conversation.latestReply.content.length > 0
            ? conversation.latestReply.content
            : conversation.latestReply.attachments.length > 0
            ? `Sent ${conversation.latestReply.attachments.length} files`
            : '';
        const content = text.length > 60 ? <span>{text.slice(0, 60)}&hellip;</span> : text;

        const user =
          conversation.latestReply.creatorCwUserId === cwUserId
            ? 'You'
            : userProfiles.find(u => u.cwUserId === conversation.latestReply.creatorCwUserId)?.name ??
              (conversation.type === 'user-company' ? 'Company' : 'Someone');

        const conversationTitle = conversation.type === 'user-user' ? conversation.name : undefined;
        return (
          <div onClick={() => setActiveChat(conversation)} className={styles.message} key={i}>
            {conversation.type === 'user-company' ? (
              <CompanyLogo company={conversationWithCompany} />
            ) : (
              <Avatars users={conversationWithUsers} />
            )}
            {conversation.hasUnreadMessages && (
              <span style={{ position: 'absolute', marginLeft: '1.5rem', marginTop: '2.25rem' }}>
                <Indicator className="u-half-spacing-left" value={1} />
              </span>
            )}
            <div className="u-half-spacing-left u-flex u-flex--column u-flex-grow">
              <span className="text-weight-medium text-medium">
                {conversation.type === 'user-company'
                  ? conversationWithCompany?.name ?? 'Company'
                  : conversationTitle || getConversationName(conversationWithUsers)}
              </span>
              <span>
                {user}: {content}
                <Timestamp className={styles.timestamp} timestamp={conversation.latestReply.createdAt} />
              </span>
            </div>
          </div>
        );
      })}
      {conversations.values.length === 0 && (
        <div className="u-content-spacing-top u-align-center">
          <img style={{ maxWidth: '15rem' }} src={emailIllustration} />
          <p className="text-body u-section-spacing-top"></p>
          {type === 'messages' ? 'Your inbox is empty.' : 'You have no message requests.'} <br />
          {type === 'messages'
            ? 'When someone sends you a message, the conversation will appear here.'
            : 'When someone wants to connect with you, the request will appear here.'}
        </div>
      )}
      <SeeMore
        className="u-content-spacing-top"
        limit={conversations.limit}
        resource={conversations}
        loadResource={fetchConversations}
      />
    </div>
  );
}
