import { differenceInDays, differenceInHours, differenceInMinutes, max, startOfDay } from 'date-fns';
import useSWR from 'swr';
import { getSessionStorageItem, setSessionStorageItem } from 'hooks/useSessionStorage';
import React from 'react';
import ColorDot from 'ui/modules/ColorDot';
import { bluePlanetTheme } from 'ui/theme';
import cx from 'classnames';
import { capitalize } from '@mui/material';

function timeSince(date: Date) {
  if (!date) return 'over a month ago';

  const startDate = new Date(date);

  if (isNaN(startDate.getTime())) return 'over a month ago';

  const now = new Date();
  const days = differenceInDays(startOfDay(now), startOfDay(startDate));
  const hours = differenceInHours(now, startDate);
  const minutes = differenceInMinutes(now, startDate);

  const postfix = ' ago';

  if (minutes < 20) return 'a moment ago';
  if (hours === 0) return 'less than 1 hour' + postfix;
  if (hours < 2) return hours + ' hour' + postfix;
  if (hours <= 12) return hours + ' hours' + postfix;
  if (hours <= 24) return ' less than a day' + postfix;
  if (days < 2) return 'yesterday';
  if (days <= 5) return days + ' days' + postfix;
  if (days <= 7) return 'less than 1 week' + postfix;
  if (days <= 14) return 'less than 2 weeks' + postfix;
  if (days <= 30) return 'less than 1 month' + postfix;
  else return 'over a month ago';
}

// When a company admin updates their company, we want to show a green dot next to the company name immediately.
// However, because the backend is using pub sub events to update the last updated field, we need a way to update the UI immediately.
// This function should only be used after a company has been updated, if the backend triggers a pub sub event.
// (It's not ideal, I know.)
export function useCompanyLastUpdated(companyId: number) {
  const response = useSWR('/localstorage/last-updated', () =>
    getSessionStorageItem<DateString | undefined>(`last-updated-${companyId}`, undefined),
  );

  const setLastUpdated = (date: Date) => {
    setSessionStorageItem(`last-updated-${companyId}`, date.toISOString());
    response.mutate(date.toISOString(), { revalidate: false });
  };

  return { lastUpdated: response.data, setLastUpdated };
}

function getLatestDate(lastUpdated: DateString | undefined, sessionStorageDateString: DateString | undefined) {
  const sessionStorageDate = sessionStorageDateString ? new Date(sessionStorageDateString) : undefined;

  if (!lastUpdated) return sessionStorageDate;

  const lastUpdatedDate = new Date(lastUpdated);
  if (!sessionStorageDate) return lastUpdatedDate;
  return max([sessionStorageDate, lastUpdatedDate]);
}

export default function LastUpdated({
  companyId,
  lastUpdated,
  className,
  hideInactive,
  size = 'tiny',
  compact,
}: {
  companyId: number;
  lastUpdated: DateString;
  className?: string;
  hideInactive?: boolean;
  size?: string;
  compact?: boolean;
}) {
  const { lastUpdated: sessionStorageDateString } = useCompanyLastUpdated(companyId);
  const date = getLatestDate(lastUpdated, sessionStorageDateString);
  const prefix = compact ? '' : 'Updated ';

  const textClassName = 'text-metadata ' + (size === 'micro' ? 'text-micro' : 'text-tiny');

  return date && differenceInDays(startOfDay(new Date()), startOfDay(date)) <= 30 ? (
    <span className={cx(className, 'u-flex u-flex-align-center')}>
      <ColorDot className="u-quarter-spacing-right" size="tiny" color={bluePlanetTheme.bluePlanetPalette.green.main} />
      <span className={textClassName}>
        {prefix}
        {compact ? capitalize(timeSince(date)) : timeSince(date)}
      </span>
    </span>
  ) : !hideInactive ? (
    <span className={cx(className, 'u-flex u-flex-align-center')}>
      <ColorDot className="u-quarter-spacing-right" size="tiny" color={bluePlanetTheme.bluePlanetPalette.grey.medium} />
      <span className={textClassName}>{compact ? 'Over a month ago' : 'Updated over a month ago'}</span>
    </span>
  ) : null;
}
