import { RefObject, useEffect } from 'react';

export function useClickOutside(ref: RefObject<HTMLElement>, onClickOutside: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any): any {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
}
