import Table from 'ui/elements/tables/Table/Table';
import { asShortDate } from 'util/dateUtils';
import useResource from 'util/resource/useResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import Avatar from 'ui/elements/avatars/Avatar';
import TextFilter from 'domain/shared/Filters/TextFilter';
import useTableWithSort from 'ui/elements/tables/Table/useTableWithSort';
import fuzzysearch from 'fuzzysearch';
import { compareAsc } from 'date-fns';
import Chip from 'ui/elements/Chip';
import EditIcon from 'ui/elements/icons/EditIcon';
import DisplayAsDialog from 'pages/Organization/Settings/ConnectionSettings/DisplayAsDialog';
import { useMediaQuery, useTheme } from '@mui/material';
import PopMenu from 'ui/modules/PopMenu';
import AdminIcon from 'ui/elements/icons/AdminIcon';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import RemoveUserDialog from 'pages/Organization/Settings/ConnectionSettings/RemoveUserDialog';
import PermissionDialog from 'pages/Organization/Settings/ConnectionSettings/PermissionDialog';
import DropDownMultiSelectHeader from 'ui/elements/tables/Table/Filters/DropDownMultiSelectHeader';
import { AllOrganizationUsers, Organization, OrganizationUser } from 'types/organization';
import React, { useMemo, useState } from 'react';
import IconButton from 'ui/elements/icons/IconButton';

interface ConnectionTableProps {
  organization: Organization;
}

const displayOptions = [
  {
    id: 1,
    name: 'Contact person',
    key: 'public_contact_person',
  },
  {
    id: 2,
    name: 'Team member',
    key: 'public_team_member',
  },
];

export function ConnectionTable(props: ConnectionTableProps) {
  const { resource } = useResource<AllOrganizationUsers>(OrganizationAPI.listAllUsersUrl(props.organization.id));

  const [nameFilter, setNameFilter] = useState('');
  const { onSort, sortOptions } = useTableWithSort({
    sortBy: 'joined',
    sortOrder: 'desc',
  });

  function compareDates(a: Date | string, b: Date | string): number {
    return compareAsc(new Date(a), new Date(b));
  }

  function comparePermission(a: OrganizationUser, b: OrganizationUser): number {
    if (a.isAdmin && !b.isAdmin) {
      return -1;
    } else if (!a.isAdmin && b.isAdmin) {
      return 1;
    } else {
      return 0;
    }
  }

  function filterByDisplay(user: OrganizationUser, displays: string[]) {
    return displays.every(display => user.displayAs.includes(display));
  }

  function displayLabel(name: string): string {
    if (name.includes('contact')) {
      return 'Contact person';
    } else if (name.includes('team')) {
      return 'Team member';
    }
    return '';
  }

  const [displayOptionsSelected, setDisplayOptionsSelected] = useState<
    {
      id: string | number;
      name: string;
      key: string;
    }[]
  >([]);

  const bodyData = useMemo(() => {
    if (resource.state === 'fetched') {
      return resource.resource.users
        .filter(it => fuzzysearch(nameFilter.toLowerCase(), it.user.name.toLowerCase()))
        .filter(it =>
          filterByDisplay(
            it,
            displayOptionsSelected.map(d => d.key),
          ),
        )
        .sort((a, b) => {
          let sortOrder = 1;
          if (sortOptions?.sortBy === 'name') {
            sortOrder = a.user.name.localeCompare(b.user.name);
          } else if (sortOptions?.sortBy === 'joined') {
            sortOrder = compareDates(a.createdAt, b.createdAt);
          } else if (sortOptions?.sortBy === 'permission') {
            sortOrder = comparePermission(a, b);
          }
          return sortOptions?.sortOrder === 'desc' ? -1 * sortOrder : sortOrder;
        });
    } else {
      return [];
    }
  }, [resource, nameFilter, sortOptions, displayOptionsSelected]);

  const [displayHovered, setDisplayHovered] = useState<number | null>(null);
  const [permissionHovered, setPermissionHovered] = useState<number | null>(null);
  const [showDisplayAsDialog, setShowDisplayAsDialog] = useState<OrganizationUser | null>(null);
  const [showPermissionDialog, setShowPermissionDialog] = useState<OrganizationUser | null>(null);
  const [showRemoveUserDialog, setShowRemoveUserDialog] = useState<OrganizationUser | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Table
        isLoading={resource.state !== 'fetched'}
        onSort={onSort}
        sortOptions={sortOptions}
        infoMessages={{
          noResults: 'No users found',
          noResultsAfterFilter: "We couldn't find any user with these filters.",
        }}
      >
        <Table.Header>
          <Table.HeaderCell
            isSortable
            sortBy="name"
            filter={<TextFilter setValue={setNameFilter} value={nameFilter} />}
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell isSortable sortBy="joined">
            Joined
          </Table.HeaderCell>
          {!isMobile && (
            <>
              <Table.HeaderCell
                filter={
                  <DropDownMultiSelectHeader
                    filter={{
                      options: displayOptions,
                      currentValue: displayOptionsSelected,
                      setValue: values => {
                        const options = displayOptions.filter(option => values.includes(option.id.toString()));
                        setDisplayOptionsSelected(options);
                      },
                    }}
                  />
                }
              >
                Display
              </Table.HeaderCell>
              <Table.HeaderCell isSortable sortBy="permission">
                Permission
              </Table.HeaderCell>
            </>
          )}
          <Table.HeaderCell />
        </Table.Header>
        <Table.Body>
          {bodyData.map((user, index) => (
            <Table.Row key={index}>
              <Table.DataCell>
                <div className={'u-flex u-flex-align-center u-flex--gap-half'}>
                  <Avatar key={index} imageUrl={user.user.imageUrl} borderColor="grey" />
                  <span>{user.user.name}</span>
                </div>
              </Table.DataCell>
              <Table.DataCell>{asShortDate(user.createdAt)}</Table.DataCell>
              {!isMobile && (
                <>
                  <Table.DataCell
                    style={{ width: '25%' }}
                    onMouseEnter={() => setDisplayHovered(index)}
                    onMouseLeave={() => setDisplayHovered(null)}
                  >
                    <div className={'u-flex u-flex-align-center u-flex--gap-half'}>
                      <div className={'u-flex u-flex--wrap u-flex--gap-half'}>
                        {user.displayAs.map(display => (
                          <Chip key={index + display} color={'blue-light'} label={displayLabel(display)} />
                        ))}
                      </div>
                      {displayHovered === index && (
                        <IconButton onClick={() => setShowDisplayAsDialog(user)}>
                          <EditIcon color={'blue'} />
                        </IconButton>
                      )}
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    onMouseEnter={() => setPermissionHovered(index)}
                    onMouseLeave={() => setPermissionHovered(null)}
                  >
                    <div className={'u-flex u-flex-align-center u-flex--gap-half'}>
                      <span>{user.isAdmin ? 'Admin' : 'None'}</span>
                      {permissionHovered === index && (
                        <IconButton onClick={() => setShowPermissionDialog(user)}>
                          <EditIcon color={'blue'} />
                        </IconButton>
                      )}
                    </div>
                  </Table.DataCell>
                </>
              )}
              <Table.DataCell>
                <PopMenu
                  items={[
                    {
                      onClick: () => setShowDisplayAsDialog(user),
                      text: 'Edit user display settings',
                      icon: <EyeIcon />,
                    },
                    {
                      onClick: () => setShowPermissionDialog(user),
                      text: 'Edit user permissions',
                      icon: <AdminIcon />,
                    },
                    {
                      onClick: () => setShowRemoveUserDialog(user),
                      text: 'Remove user',
                      icon: <TrashIcon />,
                    },
                  ]}
                />
              </Table.DataCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {showDisplayAsDialog && resource.state === 'fetched' && (
        <DisplayAsDialog
          initialUser={showDisplayAsDialog}
          organization={props.organization}
          organizationUsers={resource.resource.users}
          onClose={() => setShowDisplayAsDialog(null)}
        />
      )}
      {showPermissionDialog && resource.state === 'fetched' && (
        <PermissionDialog
          organization={props.organization}
          initialUser={showPermissionDialog}
          organizationUsers={resource.resource.users}
          onClose={() => setShowPermissionDialog(null)}
        />
      )}
      {showRemoveUserDialog && resource.state === 'fetched' && (
        <RemoveUserDialog
          onClose={() => setShowRemoveUserDialog(null)}
          userToRemove={showRemoveUserDialog}
          organizationUsers={resource.resource.users}
          organization={props.organization}
        />
      )}
    </>
  );
}
