export type GoalId = string;

export type GoalState = 'draft' | 'active' | 'completed' | 'archived';
export type GoalConfidenceLevel = 'low' | 'medium' | 'high';

export type UnbornGoal = {
  state: GoalState;
  title: string;
  description?: string;
  deadline: string;
  confidence: GoalConfidenceLevel;
  comment?: string;
};

export type Goal = {
  id: GoalId;
  createdAt: string;
  archivedAt?: string;
  completedAt?: string;
} & UnbornGoal;

export type GoalFilter = {
  state?: GoalState;
  sortBy?: 'createdAt' | 'deadline' | 'archivedAt' | 'completedAt';
  sortOrder?: 'asc' | 'desc';
};

export const toUnbornGoalDTO = (goal: Goal): UnbornGoal => ({
  state: goal.state,
  title: goal.title,
  description: goal.description,
  deadline: goal.deadline,
  confidence: goal.confidence,
  comment: goal.comment,
});
