import usePreviousProps from 'hooks/usePreviousProps';
import React, { useEffect, useState } from 'react';
import Button from 'ui/elements/buttons/Button';

export function OptionSelected<T>({
  onRemove,
  isRemoving,
  option,
  label,
  autoFocus,
}: {
  onRemove?: (value: T) => void;
  isRemoving?: boolean;
  option: T;
  label: string;
  autoFocus?: boolean;
}) {
  const [isRemovingCurrent, setIsRemovingCurrent] = useState(false);

  const onSelectionRemoved = () => {
    // If onRemove triggers loading, we want the button to show a spinner until removal is complete
    if (isRemoving != null) {
      setIsRemovingCurrent(true);
    }
  };

  const previousIsRemoving = usePreviousProps(isRemoving);
  useEffect(() => {
    if (isRemoving == null) {
      return;
    }

    // Stop loading when isRemoving change from true to false
    if (previousIsRemoving === true && isRemoving === false) {
      setIsRemovingCurrent(false);
    }
  }, [isRemoving, previousIsRemoving]);

  return (
    <Button
      shape="rounded"
      kind="primary"
      color="blue"
      className="u-quarter-spacing-right u-quarter-spacing-bottom"
      isLoading={isRemovingCurrent}
      onClick={
        onRemove
          ? () => {
              onRemove(option);
              onSelectionRemoved();
            }
          : undefined
      }
      autoFocus={autoFocus}
      hasSubtleHoverStyle
    >
      {label}
    </Button>
  );
}
