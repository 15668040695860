import { Organization } from 'types/organization';
import React, { useState } from 'react';
import Tabs from 'ui/modules/tabs/Tabs';
import Tab from 'ui/modules/tabs/Tab';
import useTabState from 'hooks/useTabState';
import { ConnectionTable } from 'pages/Organization/Settings/ConnectionSettings/ConnectionTable';
import InvitedTable from 'pages/Organization/Settings/ConnectionSettings/InvitedTable';
import Button from 'ui/elements/buttons/Button';
import AddConnectionDialog from 'pages/Organization/Settings/ConnectionSettings/AddConnectionDialog';
import InviteUserIcon from 'ui/elements/icons/InviteUserIcon';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';

interface ConnectionSettingsProps {
  organization: Organization;
}

enum TabStates {
  Connections = 'connections',
  Invitations = 'invitations',
}

export function ConnectionSettings(props: ConnectionSettingsProps) {
  const [active, onChange] = useTabState<TabStates>(TabStates.Connections);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showInviteConnectionDialog, setShowInviteConnectionDialog] = useState(false);

  return (
    <>
      <div className="u-flex-space-between u-flex-align-center u-half-spacing-bottom ">
        <Tabs activeTab={active} onChange={onChange}>
          <Tab value={TabStates.Connections} label={'Connections'} />
          <Tab value={TabStates.Invitations} label={'Invited'} />
        </Tabs>
        <Button kind={'primary'} onClick={() => setShowInviteConnectionDialog(true)}>
          <div className={'u-flex-align-center u-flex--gap-half'}>
            <InviteUserIcon />
            {!isMobile && <span>Invite your connections</span>}
          </div>
        </Button>
      </div>

      {active === TabStates.Invitations && <InvitedTable organization={props.organization} />}
      {active === TabStates.Connections && <ConnectionTable organization={props.organization} />}
      {showInviteConnectionDialog && (
        <AddConnectionDialog organization={props.organization} onClose={() => setShowInviteConnectionDialog(false)} />
      )}
    </>
  );
}
