import SvgIcon, { IconProps } from '../SvgIcon';
import React from 'react';

export default function TxtIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3.5 13v-.8c0-3 0-4.5.5-5.6.7-2 2.3-3.4 4.3-4.2 1.3-.4 2.9-.4 6-.4 1.8 0 2.7 0 3.4.3 1.2.4 2.1 1.2 2.5 2.3.3.7.3 1.5.3 3.2V13" />
      <path d="M3.5 12c0-1.8 1.5-3.3 3.3-3.3H9c.6-.2 1-.7 1.2-1.3.2-.6 0-1.4 0-2 0-2 1.6-3.4 3.4-3.4" />
      <path d="M6.1 22V16" />
      <path d="M8 16H4.2" />
      <path d="M18 22V16" />
      <path d="M19.8 16H16M10 22l4-6M10 16l4 6" />
    </SvgIcon>
  );
}
