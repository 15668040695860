import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function HelpIcon(props: IconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12v8.002C2 21.107 2.894 22 3.999 22H12Z" />
      <path d="M12.018 13.897c0-1.558 1.228-1.819 1.65-2.24a2.337 2.337 0 1 0-3.857-2.425" />
      <path d="M12.081 16.183v.139" />
    </SvgIcon>
  );
}
