import { IndicatorType, KpiDetails, KpiValue } from 'domain/kpis/types';

import config from 'config';
import { CompanyId } from 'urls';

import ApiBase from '../ApiBase';

const KPI_API_PATH = config.REPORTING_API_URL + '/companies';

const api = ApiBase();

interface CreateKpi {
  name: string;
  description?: string;
  unitOfMeasure?: string;
  trendIndicator?: IndicatorType;
}

interface EditKpi {
  name?: string;
  description?: string;
  unitOfMeasure?: string;
  trendIndicator?: IndicatorType;
}

interface CreateKpiValue {
  create: {
    kpiId: string;
    value: number;
    timestamp: string;
  };
}
interface UpdateKpiValue {
  update: {
    kpiId: string;
    valueId: string;
    value: number;
    timestamp: string;
  };
}
interface DeleteKpiValue {
  delete: {
    kpiId: string;
    valueId: string;
  };
}

export type KpiId = string;
export type CreateOrUpdateKpiValue = CreateKpiValue | UpdateKpiValue | DeleteKpiValue;

export default {
  kpi: {
    archive: (companyId: CompanyId, kpiId: string) =>
      api.patch(`${KPI_API_PATH}/${companyId}/kpis/${kpiId}`, { isArchived: true }),
    unArchive: (companyId: CompanyId, kpiId: string) =>
      api.patch(`${KPI_API_PATH}/${companyId}/kpis/${kpiId}`, { isArchived: false }),
    new: (companyId: CompanyId, kpi: CreateKpi) => api.post<KpiDetails>(`${KPI_API_PATH}/${companyId}/kpis`, kpi),
    edit: (companyId: CompanyId, kpiId: string, kpi: EditKpi) =>
      api.patch<KpiDetails>(`${KPI_API_PATH}/${companyId}/kpis/${kpiId}`, kpi),
    list: (companyId: CompanyId) => `${KPI_API_PATH}/${companyId}/kpis`,
    updateValues: (companyId: CompanyId, updates: CreateOrUpdateKpiValue[]) =>
      api.post<[KpiId, KpiValue[]][]>(`${KPI_API_PATH}/${companyId}/kpis/updatevalues`, updates),
  },
};
