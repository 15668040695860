import React from 'react';
import { CommunityType } from 'types/company/community';
import RocketIcon from 'ui/elements/icons/RocketIcon';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import WizardPreview from 'ui/modules/wizards/WizardPreview';
import UsersIcon from 'ui/elements/icons/UsersIcon';

interface Props {
  communityType: CommunityType;
}

export default function OnboardingWalkthrough(props: Props) {
  switch (props.communityType) {
    case 'mentor_master_network':
      return (
        <div className="u-fullWidth">
          <div className="u-section-spacing-y u-content-spacing-x">
            <p className="text-body u-content-spacing-bottom u-align-center text-h3">Oversikt over mentorprogrammet</p>
            <WizardPreview
              steps={[
                {
                  name: 'Fyll ut selskapsinformasjon og ønsket mentorkompetanse',
                  icon: <RocketIcon />,
                },
                {
                  name: 'Du blir koblet med en mentor',
                  icon: <UsersIcon />,
                },
                {
                  name: 'Mentorsamarbeid',
                  icon: <ChatBubblesIcon />,
                },
              ]}
            />
          </div>
        </div>
      );

    default:
      return <></>;
  }
}
