import cx from 'classnames';
import React from 'react';

import LockIcon from 'ui/elements/icons/LockIcon';

import classes from './styles.scss';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import classNames from 'classnames';

export interface Props {
  className?: string;
  title: string;
  children: React.ReactNode;
  action?: React.ReactNode;
}

export default function RestrictedCard(props: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(bluePlanetTheme.breakpoints.down('md'));
  return (
    <div className={cx(classes.box, props.className)}>
      <div className={classes.title}>
        <div className={classes.icon}>{<LockIcon color="blue" fontSize={isMobile ? 'medium' : 'large'} />}</div>
        <div>
          <div className="u-flex-space-between u-flex-align-center">
            <span
              className={classNames({
                'text-small': isMobile,
                'text-medium': isSmallScreen && !isMobile,
                'text-h4': !isMobile && !isSmallScreen,
              })}
            >
              {props.title}
            </span>
          </div>
          <span
            className={classNames({
              'text-small': isMobile,
              'text-medium': isSmallScreen && !isMobile,
              'text-body': !isMobile && !isSmallScreen,
            })}
          >
            {props.children}
          </span>
        </div>
      </div>
      {props.action && <div className="u-half-spacing-top">{props.action}</div>}
    </div>
  );
}
