import { format } from 'date-fns';
import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';

interface Props {
  date: string;
  background?: 'white' | 'indigo';
}

export default function CalendarDate({ date, background }: Props) {
  return (
    <div
      className={cx(styles.calendar, {
        [styles.indigo]: background === 'indigo',
      })}
    >
      <div
        className={cx(styles.calendarHeading, {
          [styles.indigo]: background === 'indigo',
        })}
      >
        {format(new Date(date), 'EE')}
      </div>
      <div className={styles.calendarDate}>{format(new Date(date), 'dd')}</div>
    </div>
  );
}
