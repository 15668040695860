const parsed = window.config;

export default {
  COMPANIES_API_URL: parsed.COMPANIES_API_URL,
  AUTH0_NAMESPACE: parsed.AUTH0_NAMESPACE,
  AUTH0_API_IDENTIFIER: parsed.AUTH0_API_IDENTIFIER,
  AUTH0_CLIENT_ID: parsed.AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: parsed.AUTH0_DOMAIN,
  AUTH0_CONNECTION: parsed.AUTH0_CONNECTION,
  CONTENT_API_URL: parsed.CONTENT_API_URL,
  BUILD_ENVIRONMENT: parsed.BUILD_ENVIRONMENT,
  DOCUMENTS_API_URL: parsed.DOCUMENTS_API_URL,
  NOTIFY_API_URL: parsed.NOTIFY_API_URL,
  REPORTING_API_URL: parsed.REPORTING_API_URL,
  SLACK_INTEGRATION_CLIENT_ID: parsed.SLACK_INTEGRATION_CLIENT_ID,
  ANTIFRAUD_API_URL: parsed.ANTIFRAUD_API_URL,
  PORTAL_URL: parsed.PORTAL_URL,
  MAPBOX_API_TOKEN: parsed.MAPBOX_API_TOKEN,
  INTERCOM_APP_ID: parsed.INTERCOM_APP_ID,
};
