import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { ThemeProvider } from '@mui/material/styles';
import { init, withProfiler } from '@sentry/react';
import axios from 'axios';
import Snackbar from 'ui/modules/Snackbar';
import { bluePlanetTheme } from 'ui/theme';
import config from 'config';
import React from 'react';
import { Provider } from 'react-redux';
import isDev from 'util/isDev';
import configureStore from './configureStore';
import Routes from './routes';
import Auth0ProviderWithHistory from 'auth/Auth0ProviderWithHistory';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { logError } from 'util/logging';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import CookieConsent from './CookieConsent';
import { KeyboardNavigationProvider } from 'contexts/KeyboardNavigationContext';

if (!isDev()) {
  init({
    dsn: 'https://ac16f6aacccd4e72ba2eaf6f84597a16@sentry.io/272997',
    environment: config.BUILD_ENVIRONMENT,
    ignoreErrors: ['ChunkLoadError', 'Loading CSS chunk'],
    denyUrls: [
      // fetched from https://gist.github.com/impressiver/5092952
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}

const store = configureStore();

axios.interceptors.response.use(
  response => response,
  (error: any) => {
    if (error.response && error.response.status >= 500) {
      logError(error);
    }

    return Promise.reject(error);
  },
);

const container = document.getElementById('app');
const root = createRoot(container!);

function App() {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={bluePlanetTheme}>
          <Snackbar />
          <BrowserRouter>
            <Auth0ProviderWithHistory>
              <KeyboardNavigationProvider>
                <Routes />
              </KeyboardNavigationProvider>
            </Auth0ProviderWithHistory>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
      <CookieConsent />
    </Provider>
  );
}

const Profiled = withProfiler(App);

root.render(<Profiled />);
