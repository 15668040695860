import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { logInfo } from 'util/logging';

export const swrOptions = {
  focusThrottleInterval: 120000, // Two minute cooldown on revalidation caused by changing tabs/windows/devtools
  dedupingInterval: 180000, // 3 minutes - This is effectivly the cache duration
  revalidateOnFocus: false, // We don't want to revalidate on focus as it will likely cause requests to run before we have been able to fetch the auth token
};

export interface IApiBase {
  post: <T>(url: string, payload: object, headers?: object) => Promise<T>;
  postMultiform: <T>(url: string, payload: FormData, headers?: object) => Promise<T>;
  put: <T>(url: string, payload: object) => Promise<T>;
  patch: <T>(url: string, payload: object) => Promise<T>;
  get: <T>(
    url: string,
    options?: {
      requestParams?: object;
      headers?: object;
      responseType?: 'blob';
    },
  ) => Promise<T>;
  deleteRequest: <T>(
    url: string,
    payload?: object,
    options?: {
      requestParams?: object;
    },
  ) => Promise<T>;
}

const requestWithPayload = async <T>(
  method: 'POST' | 'PUT' | 'PATCH' | 'GET' | 'DELETE',
  url: string,
  payload?: object,
  requestParams?: object,
  headers?: object,
  formData?: FormData,
  responseType?: 'blob',
) => {
  try {
    const res = await axios.request<T>({
      url,
      method,
      params: requestParams,
      paramsSerializer: {
        indexes: null,
      },
      responseType,
      data: payload ? JSON.stringify(payload) : formData ? formData : undefined,
      headers: {
        'Content-Type': 'application/json',
        ['X-API-Id']: 'portal',
        ['X-Correlation-Id']: uuidv4(),
        ...(headers || {}),
      },
    });
    return Promise.resolve(res.data);
  } catch (e) {
    if (e.response) {
      return Promise.reject(e.response);
    }
    if (e.request) {
      // The request was made but no response was received
      return Promise.reject(e.request);
    }
    logInfo(e.message);
    logInfo('How does this happen?');
    return Promise.reject(e.message);
  }
};

const headers = {};

export const post = <T>(url: string, payload: object, headerz?: object) =>
  requestWithPayload<T>('POST', url, payload, undefined, { ...headers, ...headerz });
export const postMultiform = <T>(url: string, formData: FormData, headerz?: object) =>
  requestWithPayload<T>(
    'POST',
    url,
    undefined,
    undefined,
    {
      'Content-Type': 'multipart/form-data',
      ...headerz,
    },
    formData,
  );
export const put = <T>(url: string, payload: object) => requestWithPayload<T>('PUT', url, payload, undefined, headers);
export const patch = <T>(url: string, payload: object) =>
  requestWithPayload<T>('PATCH', url, payload, undefined, headers);
export const get = <T>(
  url: string,
  options?: {
    requestParams?: object;
    headers?: object;
    responseType?: 'blob';
  },
) =>
  requestWithPayload<T>(
    'GET',
    url,
    undefined,
    options?.requestParams,
    { ...headers, ...(options?.headers ?? {}) },
    undefined,
    options?.responseType,
  );
export const deleteRequest = <T>(
  url: string,
  payload?: object,
  options?: {
    requestParams?: object;
  },
) => requestWithPayload<T>('DELETE', url, payload, options?.requestParams);

export default (): IApiBase => {
  return {
    post,
    postMultiform,
    put,
    patch,
    get,
    deleteRequest,
  };
};
