import { useState } from 'react';

export type SortDirection = 'asc' | 'desc';
export type SortDirectionOrder = 'asc-desc' | 'desc-asc';

export interface SortOptions {
  sortBy: string;
  sortOrder: SortDirection;
}

// Cycle through sorting
// With sortOrderShuffleDirection set to 'asc-desc' (default): Asc -> Desc -> None
// With sortOrderShuffleDirection set to 'desc-asc': Desc -> Asc -> None
function getNextState(
  sortBy: string,
  sortOrderShuffleDirection: 'asc-desc' | 'desc-asc',
  prevState?: SortOptions,
): SortOptions | undefined {
  const firstOrder = sortOrderShuffleDirection === 'desc-asc' ? 'desc' : 'asc';
  const secondOrder = sortOrderShuffleDirection === 'desc-asc' ? 'asc' : 'desc';
  if (prevState?.sortBy !== sortBy) {
    return {
      sortBy,
      sortOrder: firstOrder,
    };
  } else return prevState.sortOrder === firstOrder ? { sortBy, sortOrder: secondOrder } : undefined;
}

export default function useTableWithSort(initialSortOptions?: SortOptions) {
  const [sortOptions, setSortOptions] = useState<SortOptions | undefined>(initialSortOptions);

  function onSort(sortBy: string, sortOrderShuffleDirection: SortDirectionOrder = 'asc-desc') {
    setSortOptions(prevState => getNextState(sortBy, sortOrderShuffleDirection, prevState));
  }

  return { sortOptions, onSort };
}
