import SvgIcon, { IconProps } from '../SvgIcon';
import React from 'react';

export default function PdfIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3.5 13v-.8c0-3 0-4.5.5-5.6.7-2 2.3-3.4 4.3-4.2 1.3-.4 2.9-.4 6-.4 1.8 0 2.7 0 3.4.3 1.2.4 2.1 1.2 2.5 2.3.3.7.3 1.5.3 3.2V13" />
      <path d="M3.5 12c0-1.8 1.5-3.3 3.3-3.3H9c.6-.2 1-.7 1.2-1.3.2-.6 0-1.4 0-2 0-2 1.6-3.4 3.4-3.4" />
      <path d="M11.5 22c1.6 0 2.9-1.3 2.9-3 0-1.6-1.3-3-2.9-3-.3 0-.5 0-.6.2-.3.1-.3.5-.3.9v4c0 .3 0 .7.3.9h.6ZM4.3 22v-2.3m0 0v-2.4c0-.9 0-1.2 1-1.2h1.4c.9 0 1.5.8 1.5 1.8s-.6 1.8-1.5 1.8H4.3ZM16.7 22v-2.3m0 0v-2.4c0-.9.1-1.2 1-1.2h2m-3 3.6h2.5" />
    </SvgIcon>
  );
}
