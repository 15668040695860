import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BackofficeCompanyActivitySummaryDTO, BackofficeCompanyCommunityRelationDTO } from 'types/backoffice';
import Dictionary from 'ui/elements/dictionary/Dictionary';
import DictionaryItem from 'ui/elements/dictionary/DictionaryItem';
import SectionHeading from 'ui/elements/SectionHeading';
import { bluePlanetTheme } from 'ui/theme';
import Card from 'ui/views/cards/Card';
import { backofficeUrls, communityUrls, CompanyId, companyUrls } from 'urls';
import { asDate } from '../Matchmaking/utils';
import LoadableResource from 'util/resource/Resource';
import PopMenu, { PopMenuItem } from 'ui/modules/PopMenu';
import UserIcon from 'ui/elements/icons/UserIcon';
import TrendingUpIcon from 'ui/elements/icons/TrendingUpIcon';
import useBackoffice from 'hooks/useBackoffice';
import { AdminRights } from 'auth/util';
import { daysAgo, norwegianDateWithTimestamp } from 'util/dateUtils';
import CompassIcon from 'ui/elements/icons/CompassIcon';
import BackofficeAPI from 'apis/BackofficeAPI';
import MinusIcon from 'ui/elements/icons/MinusIcon';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import { useBackofficeCompany } from '../useBackofficeCompany';

interface Props {
  companyId: CompanyId;
}

export default function BackofficeCompanyDetails({ companyId }: Props) {
  const backoffice = useBackoffice();

  return backoffice.showBackofficeInfoOnProfiles ? (
    <Inner companyId={companyId} adminRights={backoffice.adminRights} />
  ) : null;
}

function Inner({ companyId, adminRights }: Props & { adminRights: AdminRights[] }) {
  const { resource, mutate } = useBackofficeCompany(companyId);

  const history = useHistory();

  return (
    <Card
      className="u-section-spacing-top"
      style={{ backgroundColor: bluePlanetTheme.bluePlanetPalette.yellow.medium }}
    >
      <LoadableResource resource={resource}>
        {details => (
          <>
            <SectionHeading heading="Backoffice">
              <PopMenu
                items={
                  [
                    adminRights.includes('write-everything')
                      ? {
                          icon: details.isPlatformApproved ? <MinusIcon /> : <CheckmarkIcon />,
                          text: (
                            <>{details.isPlatformApproved ? 'Remove platform approval' : 'Set platform approved'}</>
                          ),
                          onClick: async () => {
                            await BackofficeAPI.companies.patch(details.company.id, {
                              isPlatformApproved: !details.isPlatformApproved,
                            });
                            mutate();
                          },
                        }
                      : null,

                    adminRights.includes('write-everything')
                      ? {
                          icon: <UserIcon />,
                          text: <>View connections</>,
                          onClick: () => {
                            history.push(companyUrls.settings.users(details.company.slug));
                          },
                        }
                      : null,
                    {
                      icon: <TrendingUpIcon />,
                      text: <>View insights</>,
                      onClick: () => {
                        history.push(companyUrls.insights(details.company.slug));
                      },
                    },
                    {
                      icon: <CompassIcon />,
                      text: <>Matchmaking</>,
                      onClick: () => {
                        history.push(backofficeUrls.matchmaking.company(details.company.id));
                      },
                    },
                  ].filter(i => i !== null) as PopMenuItem[]
                }
              />
            </SectionHeading>
            <div>
              <Dictionary>
                <DictionaryItem label="Company id">{details.company.id}</DictionaryItem>
                <DictionaryItem label="Registered">
                  {daysAgo(details.createdAt)} - {norwegianDateWithTimestamp(details.createdAt)}
                </DictionaryItem>
                <DictionaryItem label="Public profile?">{details.hasPublicProfile ? 'Yes' : 'No'}</DictionaryItem>
                <DictionaryItem label="Platform approved?">{details.isPlatformApproved ? 'Yes' : 'No'}</DictionaryItem>
              </Dictionary>
              <ActivitySection activity={details.activity} />
              <CommunityRelations relations={details.communityRelations} />
            </div>
          </>
        )}
      </LoadableResource>
    </Card>
  );
}

function ActivitySection({ activity }: { activity: BackofficeCompanyActivitySummaryDTO | null }) {
  function getEntries(activity: BackofficeCompanyActivitySummaryDTO) {
    return [
      { label: 'Last updated', value: asDate(activity.lastUpdatedAt) },
      { label: 'Profile update', value: asDate(activity.profileLastUpdatedAt) },
      { label: 'Goal update', value: asDate(activity.goalLastUpdatedAt) },
      { label: 'KPI update', value: asDate(activity.kpiLastUpdatedAt) },
      { label: 'Document center', value: asDate(activity.documentCenterDocumentLastCreated) },
      { label: 'Answer FR', value: asDate(activity.followerRequestLastAnswered) },
      { label: 'Pitch deck updated', value: asDate(activity.pitchDeckLastUpdated) },
      { label: 'Team updated', value: asDate(activity.teamLastUpdated) },
      { label: 'Funding round started', value: asDate(activity.fundingRoundLastStarted) },
      { label: 'Funding round closed', value: asDate(activity.fundingRoundLastClosed) },
    ];
  }
  return (
    <>
      <h3 className="u-section-spacing-top u-half-spacing-bottom">Activity</h3>
      {!activity && <div>None</div>}
      {activity && (
        <Dictionary>
          {getEntries(activity).map(a => (
            <DictionaryItem key={a.label} label={a.label}>
              {a.value}
            </DictionaryItem>
          ))}
        </Dictionary>
      )}
    </>
  );
}

function CommunityRelations({ relations }: { relations: BackofficeCompanyCommunityRelationDTO[] }) {
  return (
    <>
      <h3 className="u-section-spacing-top u-half-spacing-bottom">Communities</h3>
      {relations.length > 0 && (
        <>
          {relations.map(r => (
            <Card key={r.communitySlug} className="u-half-spacing-bottom">
              <Link to={communityUrls.overview(r.communitySlug)} target="blank" rel="noopener noreferrer">
                <div>{r.communityName}</div>

                <div className="text-metadata">
                  {asDate(r.createdAt)} &rarr; {r.visibility}
                </div>
              </Link>
            </Card>
          ))}
        </>
      )}
      {relations.length === 0 && <div>None</div>}
    </>
  );
}
