import { v4 as uuidv4 } from 'uuid';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import format from 'date-fns/format';
import useSessionStorage from './useSessionStorage';
import CompanyAPI from 'apis/CompanyAPI';
import isDev from 'util/isDev';
import { useMediaQuery, useTheme } from '@mui/material';

export interface JourneyLogEntry {
  message: string;
}

const startTime = new Date();
const startTimeString = format(startTime, 'yyMMddHHmmss');
let index = 0;
let enabled = false;

function getScrollPercent() {
  const doc = document.documentElement,
    body = document.body,
    scrollTop = doc['scrollTop'] || body['scrollTop'],
    scrollHeight = doc['scrollHeight'] || body['scrollHeight'];

  const res = Math.round((scrollTop / (scrollHeight - doc.clientHeight)) * 100);
  return isNaN(res) ? 0 : res;
}

export default function useJourneyLogger() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const uuidParts = uuidv4().split('-');
  const [sessionId] = useSessionStorage('sessionId', uuidParts[4]);

  const setEnabled = (isEnabled: boolean) => {
    enabled = isEnabled;
  };

  const log = (message: string) => {
    if (enabled) {
      const logEntry = `${sessionId}/${startTimeString}/${index++} | ${new Date().toISOString()} | ${differenceInSeconds(
        new Date(),
        startTime,
      )}s ${getScrollPercent()}% ${isMobile ? 'm' : 'd'} | ${message}`;

      if (isDev()) {
        console.log(logEntry);
      }

      CompanyAPI.log.journey({ message: logEntry });
    }
  };

  type ReturnType = [(message: string) => void, typeof setEnabled];
  return [log, setEnabled] as ReturnType;
}
