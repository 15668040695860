import LoadableResource from 'util/resource/Resource';
import { norwegianDateWithTimestamp } from 'util/dateUtils';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import React from 'react';

import Table from 'ui/elements/tables/Table/Table';

import NotifyAPI from 'apis/NotifyAPI';
import { default as Chip, Props as ChipProps } from 'ui/elements/Chip';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';

interface Props {
  userId: number;
  onClose: () => void;
}

export function getEmailEventLabelColor(event: string): ChipProps['color'] {
  switch (event) {
    case 'click':
    case 'open':
      return 'green';
    case 'spamreport':
    case 'bounce':
    case 'dropped':
      return 'red';
    case 'deferred':
      return 'yellow';
    default:
      return 'grey';
  }
}

export default function EmailEvents({ userId, onClose }: Props) {
  const [mailResource] = useResourceLegacy(() => NotifyAPI.backoffice.getMailEvents(userId), []);

  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg">
      <Title onClose={onClose}>Latest mails</Title>

      <Content>
        <LoadableResource resource={mailResource}>
          {mails => (
            <Table isLoading={false} infoMessages={{ noResults: 'None' }}>
              <Table.Header>
                <Table.HeaderCell>Subject</Table.HeaderCell>
                <Table.HeaderCell>Events</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {mails.map(mail => (
                  <Table.Row key={mail.id}>
                    <Table.DataCell>
                      <div>{mail.subject}</div>
                      <div className="u-text-small u-color-grey">Type: {mail.type}</div>
                    </Table.DataCell>
                    <Table.DataCell>
                      <div className="u-align-left">
                        {mail.events.length == 0 && (
                          <EventChipWithTime color="grey" label="Created" time={mail.createdAt} />
                        )}
                        {mail.events
                          .filter(e => e.event != 'processed')
                          .map((e, i) => (
                            <>
                              <EventChipWithTime
                                key={i}
                                color={getEmailEventLabelColor(e.event)}
                                label={e.event}
                                time={e.createdAt}
                              />
                            </>
                          ))}
                      </div>
                    </Table.DataCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </LoadableResource>
      </Content>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

export function EventChipWithTime(props: { color: ChipProps['color']; label: string; time: DateString }) {
  return (
    <span className="u-half-spacing-right u-flex u-align-center" style={{ display: 'inline-block' }}>
      <Chip color={props.color} label={props.label} /> <span>{norwegianDateWithTimestamp(props.time)}</span>
    </span>
  );
}
