import addDays from 'date-fns/addDays';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

export const STATS_MAX_DAYS_RANGE = 365;
export const STATS_DATE_RANGES = [
  {
    id: '-7d',
    label: 'Past 7 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -6)),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: '-30d',
    label: 'Past 30 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -29)),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: '-60d',
    label: 'Past 60 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -59)),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    id: '-90d',
    label: 'Past 90 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -89)),
      endDate: endOfDay(new Date()),
    }),
  },
];
