import useSWR from 'hooks/useSWR';
import { PaginatedResult } from 'types';
import { CompanyListItem } from 'types/company';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

export const companiesKey = companiesAPIUrls.list();

export const useCompanies = () => {
  const swrResult = useSWR<PaginatedResult<CompanyListItem>>(companiesKey);
  return useSWRResource(swrResult);
};
