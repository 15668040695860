import React, { useEffect } from 'react';
import Card from 'ui/views/cards/Card';
import styles from './styles.scss';
import cx from 'classnames';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';

import Resource from 'util/resource/Resource';
import CompanyLogo from 'ui/elements/CompanyLogo';
import { useCompanyProfileById } from 'apis/CompanyAPI/companies/useCompanyProfile';
import { useHistory } from 'react-router';
import { companyUrls } from 'urls';

interface Props {
  companyId: number;
  className?: string;
  shouldNavigateToCompanyProfile: boolean;
  onNavigate?: () => void;
  onContentLoaded?: () => void;
}

const DEFAULT_IMAGE_URL =
  'https://res.cloudinary.com/crowdworks/image/upload/f_auto/w_585,q_95,c_fit/v1599053507/production/static/default-company-banner-dark-faded.png';

export default function ShareCompanyCard({
  companyId,
  className,
  shouldNavigateToCompanyProfile,
  onNavigate,
  onContentLoaded,
}: Props) {
  const { resource: companyLimitedResource } = useCompanyProfileById(companyId);
  const history = useHistory();

  const handleClick = shouldNavigateToCompanyProfile
    ? () => {
        onNavigate && onNavigate();
        history.push(companyUrls.overview(companyId));
      }
    : undefined;

  useEffect(() => {
    if (companyLimitedResource.state === 'fetched' && onContentLoaded) onContentLoaded();
  }, [companyLimitedResource.state]);

  return (
    <Card className={cx(styles.shareCard, className)} elevation={2} padding="none" onClick={handleClick}>
      <Resource resource={companyLimitedResource}>
        {company => (
          <>
            <div className="u-flex u-flex-space-between u-flex-align-center u-half-padding-x u-half-padding-y">
              <div className="u-flex u-flex-align-center">
                <CompanyLogo company={company} size={40} />
                <span className="text-metadata u-half-spacing-left">{company.name}</span>
              </div>
              <ChevronRightIcon color="dark-grey" fontSize="small" strokeWidth={2} />
            </div>
            <div
              style={{ backgroundImage: `url(${company.imageURL || DEFAULT_IMAGE_URL})` }}
              className={styles.shareCardImage}
            />
            <div className="u-half-padding">
              <div className={styles.mission}>{company.mission}</div>
            </div>
          </>
        )}
      </Resource>
    </Card>
  );
}
