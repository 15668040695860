import { PaginationOptions } from 'types/api';
import ApiBase from './ApiBase';

const api = ApiBase();

export const postFetcher = <T>([url, body]: [string, object]) => api.post<T>(url, body);
export const getFetcher = ([url]: [string]) => api.get(url);
export const externalUrlFetcher = async (url: string) => {
  const response = await fetch(url, {
    referrerPolicy: 'origin',
  });
  if (response.ok) {
    return await response.json();
  }

  throw Error(`Error in fetch, response status: ${response.status}`);
};

export const withPagination = (
  url: string,
  paginationOptions?: PaginationOptions,
  extraParams?: { [key: string]: any },
) => {
  const p = new URLSearchParams();
  if (paginationOptions) {
    p.append('page', (paginationOptions.page || 1).toString());
    if (paginationOptions.limit) {
      p.append('limit', (paginationOptions.limit || '').toString());
    }
  }

  if (extraParams) {
    Object.keys(extraParams)
      .filter(param => extraParams[param] !== undefined && extraParams[param] !== null)
      .forEach(param => {
        const value = extraParams[param];
        if (Array.isArray(value)) {
          value.forEach(v => p.append(param, v));
        } else {
          p.append(param, value);
        }
      });
  }

  const queryString = p.toString();
  return `${url}${queryString.length > 0 ? '?' : ''}${queryString}`;
};
