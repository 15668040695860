import { DialogContentText } from '@mui/material';
import React, { useState } from 'react';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import { ProgressHandler } from 'hooks/useProgressHandler';
import { companyUrls } from 'urls';
import { Goal, toUnbornGoalDTO, UnbornGoal } from 'types/goals';
import ProgressConfirmDialog from 'ui/modules/dialogs/ProgressConfirmDialog';
import TextField from 'ui/elements/form/TextField';
import useNotify from 'hooks/useNotify';
import useRoute from 'hooks/useRoute';
import { ICompany } from 'types/company';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import GoalsAPI from 'apis/GoalsAPI';
import useLazyResource from 'util/resource/useLazyResource';

interface Props {
  company: ICompany;
  goal: Goal;
  dialogHandler: DialogHandlerProps;
  onFinishGoalAction: () => void;
}

export default function ArchiveGoalDialog(props: Props) {
  const [comment, setComment] = useState('');
  const { push } = useRoute();
  const notify = useNotify();

  const { setLastUpdated } = useCompanyLastUpdated(props.company.id);
  const goalsApi = GoalsAPI.goal(props.company.id);
  const [editGoal] = useLazyResource((dto: UnbornGoal) => goalsApi.edit(props.goal.id, dto), {
    onSuccess: () => {
      notify('success', 'Updated goal');
      setLastUpdated(new Date());
      push(companyUrls.overview(props.company.slug, 'goals'));
    },
    onFailure: error => notify('error', error || 'Could not update goal'),
  });

  const onSubmit = async (progressHandler: ProgressHandler) => {
    const dto: UnbornGoal = { ...toUnbornGoalDTO(props.goal), comment, state: 'archived' };
    progressHandler.onProgress();
    await editGoal(dto);
    progressHandler.onCleanup('success');
    props.onFinishGoalAction();
  };

  return (
    <ProgressConfirmDialog
      maxWidth="sm"
      title="Archive goal"
      dismissLabel="Cancel"
      confirmLabel="Archive"
      confirmColor="red"
      dialogHandler={props.dialogHandler}
      onClose={props.onFinishGoalAction}
      onConfirm={progressHandler => onSubmit(progressHandler)}
    >
      <DialogContentText>
        You are able to provide a comment when archiving this goal in the text field below. Note that archived goals can
        be unarchived.
      </DialogContentText>
      <div className="u-content-spacing-top">
        <TextField
          name="comment"
          label="Comment"
          value={comment}
          onChange={e => setComment(e.target.value)}
          fullWidth
        />
      </div>
    </ProgressConfirmDialog>
  );
}
