import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';

interface Props {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

export default function ChipList(props: Props) {
  return <div className={cx(props.className, styles.chipList)}>{props.children}</div>;
}
