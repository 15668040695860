import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function NetworkIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M4.4 12.6V12a7.7 7.7 0 0 1 3.2-5.5m9.5.4c-1-.7 0 .1.2.2a7.6 7.6 0 0 1 2.4 5m-11 7.5a7.6 7.6 0 0 0 6.9 0" />
      <circle cx="12.1" cy="5" r="2.4" />
      <circle cx="5.2" cy="16.8" r="2.4" />
      <circle cx="18.8" cy="16.5" r="2.4" />
    </SvgIcon>
  );
}
