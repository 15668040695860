import { IconButton, lighten, useTheme } from '@mui/material';
import React from 'react';
import CloseIcon from '../icons/CloseIcon';
import SearchIcon from '../icons/SearchIcon';
import TextField from './TextField';

interface Props {
  onChange: (value: string) => void;
  className?: string;
  value: string;
  color?: 'lightBlue';
}

export default function SearchInput(props: Props) {
  const theme = useTheme();

  return (
    <TextField
      sx={{
        '.MuiInput-root': {
          height: '50px',
          borderRadius: '29px',
          backgroundColor: props.color === 'lightBlue' ? lighten(theme.bluePlanetPalette.indigo.light, 0.2) : undefined,
          color: props.color === 'lightBlue' ? lighten(theme.bluePlanetPalette.indigo.light, 0.6) : undefined,
        },

        '.MuiInput-root.Mui-focused': {
          color: props.color === 'lightBlue' ? lighten(theme.bluePlanetPalette.indigo.light, 0.6) : undefined,
        },
        '.MuiInputAdornment-root': {
          marginLeft: '10px',
        },
      }}
      name="search"
      onChange={e => props.onChange(e.target.value)}
      value={props.value}
      prefix={
        <SearchIcon
          style={{
            color: props.color === 'lightBlue' ? lighten(theme.bluePlanetPalette.indigo.light, 0.6) : undefined,
          }}
        />
      }
      suffix={
        props.value.length > 0 ? (
          <IconButton onClick={() => props.onChange('')}>
            <CloseIcon
              style={{
                color: props.color === 'lightBlue' ? lighten(theme.bluePlanetPalette.indigo.light, 0.6) : undefined,
              }}
            />
          </IconButton>
        ) : undefined
      }
    />
  );
}
