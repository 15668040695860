import React, { useRef } from 'react';
import FormikTextField, { FormikTextFieldProps } from 'ui/elements/form/formik/FormikTextField';
import TextField, { Props as TextFieldProps } from 'ui/elements/form/TextField';
import ExternalLinkIcon from 'ui/elements/icons/ExternalLink';
import * as Yup from 'yup';

type SetFieldValueType = { setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void };

interface Props<T> {
  formikProps: FormikTextFieldProps<T>['formikProps'] & SetFieldValueType;
}

export const linkedInFieldValidator = Yup.string()
  .matches(/^((?!(\/)).)*$/, 'Please remove all / characters')
  .matches(
    /^((?!(https:|www\.|http:|\/)).)*$/,
    'Your username is the part of the LinkedIn URL after /in/. E.G elon-musk (https://www.linkedin.com/in/elon-musk/)',
  )
  .nullable();

export default function LinkedInFormikField<T extends { linkedInUrl?: string }>(props: Props<T>) {
  const inputRef = useRef<HTMLInputElement>();
  const updateSelectionStart = () => inputRef.current && inputRef.current.focus();
  return (
    <FormikTextField
      inputRef={inputRef}
      formikProps={props.formikProps}
      className="u-quarter-spacing-bottom"
      type="uri"
      name="linkedInUrl"
      helperText="Your LinkedIn username will be visible on your profile"
      label="LinkedIn username"
      placeholder="jane-doe-162"
      prefix={
        <span className="text-metadata" onClick={updateSelectionStart}>
          linkedin.com/in/
        </span>
      }
      onPaste={event => {
        const value = (event.clipboardData && event.clipboardData.getData('text')) ?? '';
        const parts = value.split('/').filter(Boolean);
        if (value && value.indexOf('/in/') >= 0 && parts.length > 0) {
          props.formikProps.setFieldValue('linkedInUrl', parts[parts.length - 1]);
          event.preventDefault();
        }
      }}
      suffix={
        props.formikProps.values.linkedInUrl ? (
          <a
            href={`https://www.linkedin.com/in/${props.formikProps.values.linkedInUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ExternalLinkIcon color="dark-grey" />
          </a>
        ) : undefined
      }
    />
  );
}

export function LinkedInField({
  onChange,
  value,
  ...rest
}: {
  onChange: (val: string | undefined) => void;
} & Omit<TextFieldProps, 'onChange'>) {
  const inputRef = useRef<HTMLInputElement>();
  const updateSelectionStart = () => inputRef.current && inputRef.current.focus();

  return (
    <TextField
      inputRef={inputRef}
      type="uri"
      prefix={
        <span className="text-metadata" onClick={updateSelectionStart}>
          linkedin.com/in/
        </span>
      }
      onPaste={event => {
        const value = (event.clipboardData && event.clipboardData.getData('text')) ?? '';
        const parts = value.split('/').filter(Boolean);
        if (value && value.indexOf('/in/') >= 0 && parts.length > 0) {
          onChange(parts[parts.length - 1]);
          event.preventDefault();
        }
      }}
      onChange={e => onChange(e.target.value)}
      suffix={
        value ? (
          <a href={`https://www.linkedin.com/in/${value}`} target="_blank" rel="noopener noreferrer">
            <ExternalLinkIcon color="dark-grey" />
          </a>
        ) : undefined
      }
      {...rest}
    />
  );
}
