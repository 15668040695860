import { useState } from 'react';

export default function useAccordionHandler(initialExpanded?: boolean) {
  const [isExpanded, setExpanded] = useState(initialExpanded || false);

  function expand() {
    setExpanded(true);
  }
  function collapse() {
    setExpanded(false);
  }

  return {
    isExpanded,
    expand,
    collapse,
  };
}
