import { communitiesKey, useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { companiesKey, useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { companyProfileByIdKey, companyProfileKey } from 'apis/CompanyAPI/companies/useCompanyProfile';
import { ContentAPI } from 'apis/ContentAPI';
import config from 'config';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import { LogoHeader } from 'domain/companies/profile/header';
import { BANNER_IMAGE_ASPECT_RATIO } from 'domain/shared/Branding/BrandingForm';
import LocationInput from 'domain/shared/Location/LocationInput';
import useNotify from 'hooks/useNotify';
import { invalidate } from 'hooks/useSWR';
import useWindowWidth from 'hooks/useWindowWidth';
import React, { useRef, useState } from 'react';
import { PaginatedResult } from 'types';
import { CompanyListItem, CompanyProfile } from 'types/company';
import { MyCommunity } from 'types/company/community';
import AiMenu from 'ui/domain/AiMenu/AiMenu';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import StageChip from 'ui/domain/Chips/StageChip';
import ChipList from 'ui/elements/Chip/ChipList';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import CtaButton from 'ui/elements/buttons/CtaButton';
import HorizontalDictionary from 'ui/elements/dictionary/HorizontalDictionary';
import InlineEditableField from 'ui/elements/form/InlineEditableField';
import TextField from 'ui/elements/form/TextField';
import ImageUpload from 'ui/modules/ImageUpload';
import { bluePlanetTheme } from 'ui/theme';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { resize } from 'util/cloudinary';
import TruncatedList from 'util/components/TruncatedList';
import { MISSION_MAX_LENGTH } from 'util/constants';
import { formatShortLocation } from 'util/locationUtils';
import Resources from 'util/resource/Resources';
import useLazyResource from 'util/resource/useLazyResource';

export default function BasicCompanyProfileForm({
  onComplete,
  onBack,
  onClose,
  companyProfile,
  setCompanyProfile,
}: {
  onComplete: () => void;
  onBack: () => void;
  onClose: () => void;
  companyProfile: CompanyProfile;
  setCompanyProfile: (companyProfile: CompanyProfile) => void;
}) {
  const notify = useNotify();
  const { setLastUpdated } = useCompanyLastUpdated(companyProfile.id);
  const contentAPI = ContentAPI(`${config.CONTENT_API_URL}/companies/${companyProfile.id}`);

  const companies = useCompanies();
  const communities = useCommunities();

  const [updatedProfile, setUpdatedProfile] = useState<CompanyProfile>(companyProfile);

  const [updateCompany, isSaving] = useLazyResource(
    () =>
      companiesApi.update(updatedProfile.id, {
        location: updatedProfile.location,
        established: updatedProfile.established,
        employees: updatedProfile.employees,
        website: updatedProfile.website,
        mission: updatedProfile.mission,
      }),
    {
      onSuccess: () => {
        setCompanyProfile(updatedProfile);
        onComplete();
      },
      onFailure: () => notify('error', 'Failed to save company profile'),
    },
  );

  const width = useWindowWidth();
  const dictionaryColumnCount =
    width < bluePlanetTheme.breakpoints.values.sm ? 1 : width < bluePlanetTheme.breakpoints.values.md ? 2 : 4;

  const dictInputWidth = width < bluePlanetTheme.breakpoints.values.md ? '100%' : '260px';
  const aiRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Content className="u-flex u-flex--column u-flex-align-center">
        <div style={{ width: '100%' }}>
          <Resources resources={[companies.resource, communities.resource]}>
            {([companyList, communityList]) => (
              <>
                <ImageUpload
                  width={0}
                  imageUrl={updatedProfile.imageURL}
                  aspectRatio={BANNER_IMAGE_ASPECT_RATIO}
                  onUploadSuccess={async image => {
                    setUpdatedProfile({ ...updatedProfile, imageURL: image.secure_url });
                    await companiesApi.admin.updateBannerImage(companyProfile.id, image.secure_url);
                    const updatedCompany = { ...updatedProfile, imageURL: image.secure_url };
                    invalidate(companyProfileKey(companyProfile.slug), updatedCompany);
                    invalidate(companyProfileByIdKey(companyProfile.id), updatedCompany);
                    setLastUpdated(new Date());
                  }}
                  onUploadFailure={e => {
                    notify('error', 'Upload failed. Only jpeg & png images are allowed.');
                  }}
                  getUploadUrl={filename => contentAPI.images.getCompanyUploadUrl(companyProfile.id, filename)}
                  placeholder={(openDropzone, height) => (
                    <CtaButton
                      onClick={openDropzone}
                      style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                      height={height}
                    >
                      Add a banner image
                    </CtaButton>
                  )}
                />
                <LogoHeader className="u-flex u-content-spacing-top text-h4">
                  <ImageUpload
                    width={80}
                    aspectRatio={1}
                    onUploadSuccess={async image => {
                      setUpdatedProfile({ ...updatedProfile, logoURL: image.secure_url });
                      await companiesApi.admin.updateLogo(companyProfile.id, image.secure_url);
                      const updatedCompany = { ...updatedProfile, logoURL: image.secure_url };
                      invalidate(companyProfileKey(companyProfile.slug), updatedCompany);
                      invalidate(companyProfileByIdKey(companyProfile.id), updatedCompany);
                      invalidate<PaginatedResult<CompanyListItem>>(companiesKey, {
                        ...companyList,
                        values: companyList.values.map(v =>
                          v.id === updatedProfile.id ? { ...v, logoURL: image.secure_url } : v,
                        ),
                      });
                      invalidate<{
                        values: MyCommunity[];
                      }>(communitiesKey, {
                        ...communityList,
                        values: communityList.values.map(v => ({
                          ...v,
                          myCompanyMemberships: v.myCompanyMemberships.map(c =>
                            c.company.id === updatedProfile.id
                              ? { visibility: c.visibility, company: { ...c.company, logoURL: image.secure_url } }
                              : c,
                          ),
                        })),
                      });
                      setLastUpdated(new Date());
                    }}
                    onUploadFailure={e => {
                      notify('error', 'Upload failed. Only jpeg & png images are allowed.');
                    }}
                    getUploadUrl={filename => contentAPI.images.getCompanyUploadUrl(companyProfile.id, filename)}
                    imageUrl={resize(updatedProfile.logoURL, { width: 500, height: 500 })}
                    shape="round"
                    placeholder={(openDropzone, height) => (
                      <CtaButton onClick={openDropzone} height={height} shape="circle">
                        Add logo
                      </CtaButton>
                    )}
                  />
                  {companyProfile.name}
                </LogoHeader>
              </>
            )}
          </Resources>
          <HorizontalDictionary columnCount={dictionaryColumnCount} className="u-content-spacing-top">
            {[
              {
                key: 'Est',
                value: (
                  <InlineEditableField
                    label="Add established"
                    defaultValue={updatedProfile.established ?? ''}
                    onConfirm={value => setUpdatedProfile({ ...updatedProfile, established: value })}
                    renderInputComponent={(onChange, value) => (
                      <TextField
                        autoFocus
                        placeholder='e.g. "2015"'
                        type="number"
                        value={value}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                      />
                    )}
                    valueComponent={<>{updatedProfile.established}</>}
                  />
                ),
              },
              {
                key: 'Location',
                value: (
                  <InlineEditableField
                    label="Add location"
                    defaultValue={updatedProfile.location}
                    onConfirm={value => setUpdatedProfile({ ...updatedProfile, location: value })}
                    inputWrapperStyle={{ width: dictInputWidth }}
                    renderInputComponent={onChange => (
                      <LocationInput
                        style={{ width: '100%' }}
                        name="location"
                        searchArea="places"
                        placeholder="Search for location"
                        autoComplete="address-level2"
                        autoFocus
                        onChange={v => onChange(v || undefined)}
                      />
                    )}
                    valueComponent={<>{updatedProfile.location && formatShortLocation(updatedProfile.location)}</>}
                  />
                ),
              },
              {
                key: 'Funding stage',
                value: companyProfile.fundingStage?.name ?? '-',
              },
              {
                key: 'Employees',
                value: (
                  <InlineEditableField
                    label="Add employees"
                    defaultValue={updatedProfile.employees ? `${updatedProfile.employees}` : ''}
                    buttonStyle={{ height: 25 }}
                    onConfirm={value => {
                      const employees = parseInt(value, 10);
                      if (!isNaN(employees)) {
                        setUpdatedProfile({ ...updatedProfile, employees });
                      } else {
                        setUpdatedProfile({ ...updatedProfile, employees: undefined });
                      }
                    }}
                    renderInputComponent={(onChange, value) => (
                      <TextField
                        autoFocus
                        type="number"
                        value={value}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                      />
                    )}
                    valueComponent={<>{updatedProfile.employees}</>}
                  />
                ),
              },
            ]}
          </HorizontalDictionary>
          <div className="u-content-spacing-top u-flex u-flex-space-between u-flex-align-center">
            <ChipList>
              <TruncatedList
                values={companyProfile.industries.values}
                limit={2}
                renderItem={label => <IndustryChip label={label.name} />}
                renderExpandButton={(label, onClick) => <IndustryChip onClick={onClick} label={label.name} />}
              />
              {companyProfile.stages.values.map(stage => (
                <StageChip key={stage.name} label={stage.name} />
              ))}
            </ChipList>
          </div>
          <div className="u-content-spacing-top">
            <InlineEditableField
              label="Add your mission statement"
              defaultValue={updatedProfile.mission || ''}
              buttonStyle={{ height: '100px' }}
              onConfirm={value => setUpdatedProfile({ ...updatedProfile, mission: value })}
              disableEnterKey
              renderInputComponent={(onChange, value) => (
                <div className="u-flex u-flex--column u-flex-grow">
                  <TextField
                    inputProps={{ style: { lineHeight: '25px' } }}
                    autoFocus
                    maxLength={MISSION_MAX_LENGTH}
                    multiline
                    minRows={4}
                    maxRows={20}
                    suffixPosition="start-bottom"
                    value={value}
                    suffix={
                      <AiMenu
                        companyId={companyProfile.id}
                        organizationId={undefined}
                        input={value || ''}
                        maxLength={700}
                        onReplace={v => {
                          onChange(v);
                        }}
                        aiRef={aiRef}
                      />
                    }
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                  />
                  <div ref={aiRef} />
                </div>
              )}
              valueComponent={
                <div style={{ minHeight: '100px' }} className="text-body">
                  {updatedProfile.mission}
                </div>
              }
            />
          </div>
        </div>
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button isLoading={isSaving} onClick={updateCompany} kind="primary">
            Next
          </Button>
          <Button onClick={onBack} kind="primary" color="greyLight">
            Back
          </Button>
        </ButtonList>
        <Button onClick={onClose} kind="tertiary" color="grey">
          Pick it up later
        </Button>
      </DialogActions>
    </>
  );
}
