import cx from 'classnames';
import React from 'react';

import styles from './styles.scss';

interface Props {
  shape?: 'square' | 'round';
  children: string | JSX.Element;
}

export default function Placeholder(props: Props) {
  return <div className={cx(styles.placeholder, { [styles.round]: props.shape === 'round' })}>{props.children}</div>;
}
