import cx from 'classnames';
import React from 'react';
import styles from './styles.scss';

interface Props {
  color: string;
  size?: 'small' | 'large' | 'tiny';
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
}

export default function ColorDot(props: Props) {
  return props.onClick ? (
    <button
      onClick={props.onClick}
      style={{ backgroundColor: props.color }}
      className={cx(props.className, {
        [styles.colorDot]: !props.size || props.size === 'small',
        [styles.colorDotTiny]: props.size === 'tiny',
        [styles.colorDotLarge]: props.size === 'large',
      })}
    >
      {props.children}
    </button>
  ) : (
    <div
      style={{ backgroundColor: props.color }}
      className={cx(props.className, {
        [styles.colorDot]: !props.size || props.size === 'small',
        [styles.colorDotTiny]: props.size === 'tiny',
        [styles.colorDotLarge]: props.size === 'large',
      })}
    />
  );
}
