import React from 'react';
import ErrorPage from './ErrorPage';
import NotFound from './NotFoundPage';

export type HttpErrorType = 'NotFound' | 'Unauthorized' | 'Error';

export function fromStatusCode(statusCode?: number) {
  if (statusCode == 404) return 'NotFound';
  if (statusCode == 403) return 'Unauthorized';
  return 'Error';
}

export function mapResponse(errorType: HttpErrorType | undefined, ok: JSX.Element) {
  switch (errorType) {
    case 'NotFound':
    case 'Unauthorized':
      return <NotFound />;
    case 'Error':
      return <ErrorPage />;
    default:
      return ok;
  }
}
