import CompanyConversationDialogAsUser from 'domain/conversations/CompanyConversationDialogAsUser';
import React, { useState } from 'react';
import { ICompany } from 'types/company';
import Button from 'ui/elements/buttons/Button';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import CardInfoCard from 'ui/views/cards/CardInfoCard';
import { getFundingRoundClosingDateText } from './FundingRound';

interface Props {
  company: ICompany;
  closingDate?: string;
  className?: string;
}

export default function FundingRoundLimitedView(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <CardInfoCard
        className="u-half-spacing-bottom"
        title="Active funding round"
        subTitle={getFundingRoundClosingDateText(props.closingDate)}
      >
        <Button kind={'secondary'} color="white" onClick={() => setIsOpen(true)}>
          <ChatBubblesIcon className="u-half-spacing-right" /> I&apos;m interested
        </Button>
      </CardInfoCard>
      {isOpen && (
        <CompanyConversationDialogAsUser isOpen={isOpen} onClose={() => setIsOpen(false)} company={props.company} />
      )}
    </>
  );
}
