import useSWR from 'hooks/useSWR';
import { List } from 'types/api';
import { MentorMentorship } from './types';
import { mentorUrls } from './mentorApi';
import { useSWRResource } from 'util/resource/useSWRResource';

export function useMentorMentorships(companySlug: string) {
  const swrResult = useSWR<List<MentorMentorship>>(mentorUrls.listForMentor(companySlug));
  return useSWRResource(swrResult);
}
