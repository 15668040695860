import cx from 'classnames';
import React, { CSSProperties } from 'react';

import classes from './styles.scss';

interface ButtonListProps {
  children: React.ReactNode | React.ReactNode[];
  axis?: 'horizontal' | 'vertical';
  align?: 'left' | 'center' | 'right';
  backgroundColor?: 'white';
  className?: string;
  style?: CSSProperties;
}

export default function ButtonList(props: ButtonListProps) {
  const { align, axis, style } = props;

  const classNames = cx({
    [classes.actionsLeft]: !align || align === 'left',
    [classes.actionsRight]: align === 'right',
    [classes.actionsCenter]: align === 'center',
    [classes.vertical]: axis === 'vertical',
  });
  return (
    <div
      style={style}
      className={cx(
        classNames,
        classes.buttonList,
        props.className,
        props.backgroundColor ? classes[props.backgroundColor] : undefined,
      )}
    >
      {props.children}
    </div>
  );
}
