import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function LocationIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M13.6 21.4a2.4 2.4 0 0 1-3.2 0c-4-3.8-9.3-8-6.7-14A9 9 0 0 1 12 2a9 9 0 0 1 8.3 5.4c2.6 6-2.7 10.2-6.7 14Z" />
      <path d="M14.7 11a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Z" />
    </SvgIcon>
  );
}
