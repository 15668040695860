import React from 'react';
import { splitFilenameExtension } from 'util/stringUtils';
import { IconProps } from 'ui/elements/icons/SvgIcon';
import DocxIcon from 'ui/elements/icons/FileIcons/DocxIcon';
import GifIcon from 'ui/elements/icons/FileIcons/GifIcon';
import JpgIcon from 'ui/elements/icons/FileIcons/JpgIcon';
import PdfIcon from 'ui/elements/icons/FileIcons/PdfIcon';
import PngIcon from 'ui/elements/icons/FileIcons/PngIcon';
import PptIcon from 'ui/elements/icons/FileIcons/PptIcon';
import TxtIcon from 'ui/elements/icons/FileIcons/TxtIcon';
import RarIcon from 'ui/elements/icons/FileIcons/RarIcon';
import XlsIcon from 'ui/elements/icons/FileIcons/XlsIcon';
import ZipIcon from 'ui/elements/icons/FileIcons/ZipIcon';
import DocumentIcon from 'ui/elements/icons/FileIcons/DocumentIcon';

interface Props extends IconProps {
  name: string;
}

export default function FileIcon(props: Props) {
  const { extension } = splitFilenameExtension(props.name);

  switch (extension?.toLocaleLowerCase()) {
    case 'doc':
    case 'docx':
      return <DocxIcon {...props} />;
    case 'pdf':
      return <PdfIcon {...props} />;
    case 'png':
      return <PngIcon {...props} />;
    case 'jpg':
    case 'jpeg':
      return <JpgIcon {...props} />;
    case 'gif':
      return <GifIcon {...props} />;
    case 'ppt':
    case 'pptx':
      return <PptIcon {...props} />;
    case 'txt':
      return <TxtIcon {...props} />;
    case 'rar':
      return <RarIcon {...props} />;
    case 'zip':
      return <ZipIcon {...props} />;
    case 'xls':
    case 'xlsx':
    case 'xlsm':
      return <XlsIcon {...props} />;
    default:
      return <DocumentIcon {...props} />;
  }
}
