import { bluePlanetTheme } from 'ui/theme';
import MUIChip from '@mui/material/Chip';
import ChipList from 'ui/elements/Chip/ChipList';
import TruncatedList from 'util/components/TruncatedList';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import React from 'react';
import { HtmlTooltip } from 'pages/Dashboard/network/common/CompanyRoles';

interface Props {
  isAdmin: boolean;
  displayAs: string[];
}

function getColor(label: string) {
  const palette = bluePlanetTheme.bluePlanetPalette;
  switch (label) {
    case 'admin':
      return { backgroundColor: palette.yellow.medium, color: palette.indigo.main };
    case 'public_contact_person':
      return { backgroundColor: palette.green.light, color: palette.indigo.main };
    case 'public_team_member':
      return { backgroundColor: palette.red.light, color: palette.indigo.main };
    default:
      return { backgroundColor: palette.yellow.medium, color: palette.indigo.main };
  }
}

function getLabelName(label: string) {
  switch (label) {
    case 'admin':
      return 'admin';
    case 'public_contact_person':
      return 'contact person';
    case 'public_team_member':
      return 'team member';
    default:
      return 'member';
  }
}

const RoleLabel = ({ label }: { label: string }) => {
  return (
    <MUIChip
      sx={{
        ...getColor(label),
        padding: 0,
        '.MuiChip-label': {
          padding: '0 0.40625rem',
        },
      }}
      label={getLabelName(label)}
    />
  );
};

export function OrganizationRoles({ isAdmin, displayAs }: Props) {
  const roles = [...(isAdmin ? ['admin'] : []), ...displayAs].map(r => ({ name: r }));

  return (
    <div className="u-flex u-flex--gap-quarter">
      {roles.length === 0 && <RoleLabel label={'member'} key={'member'} />}
      {roles.length > 0 && (
        <ChipList>
          <TruncatedList
            limit={1}
            values={roles}
            renderItem={role => <RoleLabel label={role.name} key={role.name} />}
            renderExpandButton={(role, hand) => (
              <HtmlTooltip
                title={roles.slice(1).map(role => (
                  <RoleLabel label={role.name} key={role.name} />
                ))}
              >
                <span>
                  <IndustryChip onClick={hand} label={role.name} />
                </span>
              </HtmlTooltip>
            )}
          />
        </ChipList>
      )}
    </div>
  );
}
