import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

type ReturnType<V> = { [K in keyof V]: string[] };

export default function useUrlParams<V>(
  paramList: (keyof V & string)[],
  sortByParam?: string,
  opts?: {
    isRemoveAfterRead?: boolean;
  },
): {
  values: ReturnType<V>;
  setValue: (param: keyof V & string, value: string | undefined | (number | string)[]) => void;
  sortBy?: string | undefined;
  setSortBy: (value: string | undefined) => void;
  numActive: number;
  reset: () => void;
} {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const valuesFromUrl = paramList.reduce(
    (obj, param) => ({
      ...obj,
      [param]: urlParams.getAll(param),
    }),
    {},
  ) as ReturnType<V>;

  const [values, setValues] = useState(valuesFromUrl);

  const setValue = (param: (keyof V & string) | 'sortBy', value: string | undefined | (number | string)[]) => {
    urlParams.delete(param);

    if (Array.isArray(value)) {
      value.forEach(v => urlParams.append(param, v.toString()));
    } else {
      if (value) urlParams.set(param, value);
    }

    history.replace({
      pathname: history.location.pathname,
      search: urlParams.toString(),
    });

    setValues(prev => ({
      ...prev,
      [param]: Array.isArray(value) ? value : [value],
    }));
  };

  const [sortBy, setSortByState] = useState((sortByParam && urlParams.get(sortByParam)) ?? undefined);
  const setSortBy = (value: string | undefined) => {
    setSortByState(value);
    setValue('sortBy', value);
  };

  const reset = () => {
    if (urlParams.size > 0) {
      paramList.forEach(paramList => urlParams.delete(paramList));
      const empty = paramList.reduce(
        (obj, param) => ({
          ...obj,
          [param]: [],
        }),
        {},
      ) as ReturnType<V>;

      history.replace({
        pathname: history.location.pathname,
        search: urlParams.toString(),
      });
      setValues(empty);
    }
  };

  useEffect(() => {
    if (opts?.isRemoveAfterRead) {
      reset();
    }
  }, []);

  const keys = getAllKeys(urlParams);
  const numActive = keys.reduce((acc, key: keyof V & string) => (paramList.includes(key) ? acc + 1 : acc), 0);

  return {
    values,
    setValue,
    numActive,
    reset,
    sortBy,
    setSortBy,
  };
}

function getAllKeys(params: URLSearchParams) {
  const keys: string[] = [];
  params.forEach((_, key) => {
    if (!keys.includes(key)) {
      keys.push(key);
    }
  });
  return keys;
}
