import React, { useEffect } from 'react';

export default function useKeyboard(
  keyMap: { [key: string]: Function },
  shouldDisable?: () => boolean,
  deps?: React.DependencyList | undefined,
) {
  function downHandler(e: KeyboardEvent) {
    if (shouldDisable && shouldDisable()) {
      return;
    }

    if (keyMap[e.key]) {
      keyMap[e.key]();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, deps);
}
