import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import { ProgressHandler } from 'hooks/useProgressHandler';
import { companyUrls } from 'urls';
import { Goal, toUnbornGoalDTO, UnbornGoal } from 'types/goals';
import ProgressConfirmDialog from 'ui/modules/dialogs/ProgressConfirmDialog';
import useNotify from 'hooks/useNotify';
import useRoute from 'hooks/useRoute';
import { ICompany } from 'types/company';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import useLazyResource from 'util/resource/useLazyResource';
import GoalsAPI from 'apis/GoalsAPI';

interface Props {
  company: ICompany;
  goal: Goal;
  dialogHandler: DialogHandlerProps;
  onFinishGoalAction: () => void;
}

export default function UnarchiveGoalDialog(props: Props) {
  const { push } = useRoute();
  const notify = useNotify();
  const { setLastUpdated } = useCompanyLastUpdated(props.company.id);
  const goalsApi = GoalsAPI.goal(props.company.id);
  const [editGoal] = useLazyResource((dto: UnbornGoal) => goalsApi.edit(props.goal.id, dto), {
    onSuccess: () => {
      notify('success', 'Updated goal');
      setLastUpdated(new Date());
      push(companyUrls.overview(props.company.slug, 'goals'));
    },
    onFailure: error => notify('error', error || 'Could not update goal'),
  });

  const onConfirm = async (progressHandler: ProgressHandler) => {
    const dto: UnbornGoal = { ...toUnbornGoalDTO(props.goal), state: 'active' };
    progressHandler.onProgress();
    await editGoal(dto);
    progressHandler.onCleanup('success');
    props.onFinishGoalAction();
  };

  return (
    <ProgressConfirmDialog
      maxWidth="sm"
      title="Unarchive goal"
      dismissLabel="Cancel"
      confirmLabel="Unarchive"
      confirmColor="blue"
      dialogHandler={props.dialogHandler}
      onClose={props.onFinishGoalAction}
      onConfirm={onConfirm}
    >
      <DialogContentText>Are you sure you want to unarchive this goal?</DialogContentText>
    </ProgressConfirmDialog>
  );
}
