import React from 'react';
import StatsCard from 'ui/views/cards/StatsCard';
import styled from '@emotion/styled';
import { contentSpacing } from 'ui/theme/themeConstants';
import { bluePlanetTheme } from 'ui/theme';
import { PitchDeckStats } from 'apis/CompanyAPI/insights/types';
import ProgressPie from 'ui/elements/Progress/ProgressPie';
import { useTheme } from '@mui/material';

type Props = { pitchDeckViews: PitchDeckStats };

export const timeSpentText = (durationInSeconds: number) => {
  const date = new Date(0);
  date.setSeconds(durationInSeconds);
  const timeString = date.toISOString().substring(14, 19);
  return timeString;
};

const StatsGrid = styled.div`
  display: grid;
  gap: ${contentSpacing};
  grid-template-columns: repeat(3, minmax(min-content, 300px));

  ${bluePlanetTheme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
  }
`;

export default function PitchDeckViews({ pitchDeckViews }: Props) {
  const theme = useTheme();

  const topCards = [
    {
      key: 'medianTimeSpent',
      value: `${timeSpentText(pitchDeckViews.medianTimeSpent)}`,
      title: 'Median Time Spent',
      tooltip: 'The median time visitors spent exploring your pitch deck (minutes:seconds)',
    },
    {
      key: 'medianViewedSlides',
      icon: (
        <ProgressPie
          percentage={
            pitchDeckViews.totalSlideCount
              ? (pitchDeckViews.medianViewedSlides / pitchDeckViews.totalSlideCount) * 100
              : 0
          }
          width={40}
          thickness={4}
          hidePercentage
          colors={[theme.bluePlanetPalette.purple.light, theme.bluePlanetPalette.purple.main]}
        />
      ),
      value: `${pitchDeckViews.medianViewedSlides}/${pitchDeckViews.totalSlideCount}`,
      title: 'Median viewed slides',
      tooltip: 'The median number of slides viewed by visitors of your pitch deck',
    },
    {
      key: 'visitsTotal',
      value: pitchDeckViews.viewCount,
      title: 'Visits total',
      tooltip: "Total number of people who've browsed your pitch deck",
    },
  ];

  return (
    <>
      <StatsGrid>
        {topCards.map(total => (
          <StatsCard
            key={total.title}
            title={total.title}
            icon={total.icon}
            value={total.value}
            tooltip={total.tooltip}
          />
        ))}
      </StatsGrid>
    </>
  );
}
