import { calculateCompletedPercentage } from 'domain/onboarding/utils';
import React from 'react';
import { ICompany } from 'types/company';
import ProgressPie from 'ui/elements/Progress/ProgressPie';
import { companyUrls } from 'urls';
import { useCompanyOnboarding } from 'apis/OnboardingAPI';
import { Todo } from './Todo';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import PitchIcon from 'ui/elements/icons/PitchIcon';

interface Props {
  company: ICompany;
}

export default function FinishCompanyProfile({ company }: Props) {
  const { checklistSteps } = useCompanyOnboarding(company.slug);
  const percentage = calculateCompletedPercentage(checklistSteps);

  return (
    <Todo
      color="indigo"
      href={companyUrls.overview(company.slug, 'profile', { expandOnboardingChecklist: true })}
      action={<span className="text-link-light">Complete now</span>}
    >
      <div>
        {isNaN(percentage) ? (
          <IconAvatar color="white" size={{ width: '40px', height: '40px' }}>
            <PitchIcon></PitchIcon>
          </IconAvatar>
        ) : (
          <ProgressPie width={40} thickness={2} fontSize="small" percentage={percentage} />
        )}
      </div>
      Finish your company profile to start getting matches
    </Todo>
  );
}
