import React, { useState } from 'react';
import UserListDialog from 'domain/users/UserListDialog';
import { Update } from 'types/content';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import ChatIcon from 'ui/elements/icons/ChatIcon';
import LikeIcon from 'ui/elements/icons/LikeIcon';
import { pluralize } from 'util/stringUtils';
import Tooltip from 'ui/elements/Tooltip';
import { contentSpacing } from 'ui/theme/themeConstants';
import GraphIcon from 'ui/elements/icons/GraphIcon';
import { UpdateAPI } from 'apis/ContentAPI/UpdateAPI';

const ActionButton = styled.button`
  &:hover {
    color: ${bluePlanetTheme.bluePlanetPalette.blue.main};
  }
`;

export default function UpdateStats({
  update,
  numLikes,
  numComments,
  canEditUpdate,
  onCommentClick,
  api,
}: {
  update: Update;
  numLikes: number;
  numComments: number;
  canEditUpdate: boolean;
  onCommentClick: () => void;
  api: UpdateAPI;
}) {
  const [showLikes, setShowLikes] = useState(false);

  return (
    <>
      {numLikes > 0 || numComments > 0 || canEditUpdate ? (
        <div
          className="u-content-spacing-top u-flex"
          style={{ color: bluePlanetTheme.bluePlanetPalette.grey.dark, gap: contentSpacing }}
        >
          {numLikes > 0 && (
            <ActionButton onClick={() => setShowLikes(true)} className="u-flex u-flex-align-center text-small">
              <LikeIcon style={{ fontSize: '18px' }} className="u-quarter-spacing-right" />
              {numLikes}
            </ActionButton>
          )}
          {numComments > 0 && (
            <ActionButton onClick={onCommentClick} className="u-flex u-flex-align-center text-small">
              <ChatIcon style={{ fontSize: '18px' }} className="u-quarter-spacing-right" />
              {numComments} {pluralize(numComments, 'comment', 'comments')}
            </ActionButton>
          )}
          {/* Note: the view count will be visible in the payload for all users.
              It's actually a PITA today to figure out in the backend if a user can edit a post or not, when listing out updates in the user feed. 
              We should try to fix this if we make any changes to how audiences works in the future 🙏
          */}
          {canEditUpdate && (
            <Tooltip title={`${update.views} ${pluralize(update.views, 'person has', 'people have')} read this post`}>
              <span className="u-flex u-flex-align-center text-small">
                <GraphIcon style={{ fontSize: '18px' }} className="u-quarter-spacing-right" />
                {update.views} {pluralize(update.views, 'view', 'views')}
              </span>
            </Tooltip>
          )}
        </div>
      ) : null}

      <UserListDialog
        isOpen={showLikes}
        closeDialog={() => setShowLikes(false)}
        fetchResource={opts => api.likes.list(update.id, opts)}
        title="Liked by"
      />
    </>
  );
}
