import React from 'react';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import useNotify from 'hooks/useNotify';
import { PatchShortlist, Shortlist } from 'types/user';
import useLazyResource from 'util/resource/useLazyResource';
import { insertLinksForUrls } from 'util/stringUtils';
import HtmlContent from 'ui/elements/HtmlContent';

export const useUpdateShortlist = (options?: {
  onSuccess?: (updatedShortlist: Shortlist) => void;
  onFailure?: (e?: string) => void;
}) => {
  const notify = useNotify();

  return useLazyResource(
    (data: { id: UUID; payload: PatchShortlist }) => usersApi.shortlists.update(data.id, data.payload),
    {
      onSuccess: updatedShortlist => {
        options?.onSuccess && options.onSuccess(updatedShortlist);
      },
      onFailure: e =>
        options?.onFailure
          ? options.onFailure(e)
          : () => notify('error', `Something unexpected happened when trying to update the list.`),
    },
  );
};

export function ShortlistDescription({ description }: { description: string | undefined }) {
  if (!description) return null;

  const textWithUrls = insertLinksForUrls(description);
  return <HtmlContent>{textWithUrls}</HtmlContent>;
}
