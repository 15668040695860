import { useEffect, useState } from 'react';

export function parsePersistedValue<T>(persistedValue: string, initialValue: T): T {
  try {
    return JSON.parse(persistedValue) as T;
  } catch (e) {
    // Local storage usage should best effort. If we fail to parse the stored value, use the default value
    return initialValue;
  }
}

export function getLocalStorageItem<T>(key: string, initialValue: T) {
  const persistedValueString = window?.localStorage?.getItem(key);
  const persistedValue = persistedValueString ? parsePersistedValue(persistedValueString, initialValue) : initialValue;

  return persistedValue;
}

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
  opts: { isRemoveAfterRead: boolean } | undefined = undefined,
) {
  const persistedValueString = window?.localStorage?.getItem(key);

  const persistedValue = persistedValueString ? parsePersistedValue(persistedValueString, initialValue) : initialValue;

  const [value, setLocalState] = useState<T | undefined>(persistedValue);
  const setState = (value: T) => {
    setLocalState(value);
    window?.localStorage?.setItem(key, JSON.stringify(value));
  };

  const removeItem = () => {
    setLocalState(initialValue);
    window?.localStorage?.removeItem(key);
  };

  useEffect(() => {
    if (opts && opts.isRemoveAfterRead) {
      removeItem();
    }
  }, []);

  type ReturnType = [T, typeof setState, typeof removeItem];
  return [value, setState, removeItem] as ReturnType;
}
