import React from 'react';

import { NotificationListItem } from 'domain/notify/components/NotificationListItem';

import { timeSince } from 'util/dateUtils';
import { NotificationDTO } from 'types/notify';

import notifyLink from '../utils/notifyLink';
import { NotificationIcon } from '../utils/notificationUtils';
import { useCompanyProfiles } from 'apis/CompanyAPI/companies/useCompanyProfiles';
import { getOrUndefined } from 'util/resource';

export interface NotificationElementProps {
  notification: NotificationDTO;
  onClick?: () => void;
}

function CompanyNotification({
  notification,
  companyId,
  url,
  onClick,
}: {
  companyId: string;
  url: (slug: string) => string;
} & NotificationElementProps) {
  const time = timeSince(notification.createdAt);
  const { resource } = useCompanyProfiles([parseInt(companyId, 10)]);
  const company = getOrUndefined(resource)?.values[0];

  return (
    <NotificationListItem
      to={company ? url(company.slug) : '#'}
      icon={<NotificationIcon type={notification.resourceId.type} />}
      primary={notification.text}
      secondary={time}
      onClick={onClick}
      seen={notification.seen}
    />
  );
}

export function NotificationElement(props: NotificationElementProps) {
  const time = timeSince(props.notification.createdAt);
  if (props.notification.path) {
    return (
      <NotificationListItem
        to={props.notification.path}
        icon={<NotificationIcon type={props.notification.resourceId.type} />}
        primary={props.notification.text}
        secondary={time}
        onClick={props.onClick}
        seen={props.notification.seen}
      />
    );
  }

  const link = notifyLink(props.notification);
  if (link.type === 'company') {
    return (
      <CompanyNotification
        notification={props.notification}
        onClick={props.onClick}
        companyId={link.companyId}
        url={link.url}
      />
    );
  }

  return (
    <NotificationListItem
      to={link.url}
      icon={<NotificationIcon type={props.notification.resourceId.type} />}
      primary={props.notification.text}
      secondary={time}
      onClick={props.onClick}
      seen={props.notification.seen}
    />
  );
}
