import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ErrorIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z" />
      <path strokeWidth="2" d="M12 15h0" />
      <path d="M12 12V8" />
    </SvgIcon>
  );
}
