import { Dialog, DialogActions, DialogContent, IconButton, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import styles from './styles.scss';
import cx from 'classnames';
import bluePlanetTheme, { BackdropColor, getBackdropColor } from 'ui/theme/themeBluePlanet';

export { default as DialogBanner } from './Banner';

const borderRadius = '8px';

interface Props {
  children?: React.ReactNode;
  dialogActions?: React.ReactNode;
  onClose: () => void;
  open: boolean;
  backdropColor?: BackdropColor;
  disableEnforceFocus?: boolean;
  modalId?: string;
  width?: 'default' | 'wide';
  overflowY?: 'scroll' | 'auto';
}

export default function CenteredModalDialog(props: Props) {
  const { width = 'default' } = props;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={props.open}
      onClose={props.onClose}
      scroll="paper"
      sx={{
        fontFamily: bluePlanetTheme.typography.fontFamily,
        marginLeft: 0,
        marginTop: 0,
        alignItems: 'center',
        justifyContent: 'center',
        '.MuiDialog-paper': {
          backgroundColor: 'transparent',
          height: '100%',
          boxShadow: 'none',
        },
      }}
      BackdropProps={{
        style: {
          left: 0,
          backgroundColor: getBackdropColor(props.backdropColor ?? 'medium'),
        },
      }}
      PaperProps={{
        style: {
          maxHeight: smallScreen ? 'calc(100% - 10px)' : undefined,
          width: smallScreen ? 'calc(100% - 10px)' : undefined,
          maxWidth: width === 'wide' ? '1600px' : undefined,
        },
      }}
      disableEnforceFocus={props.disableEnforceFocus}
      id={props.modalId}
    >
      <DialogContent
        sx={{
          // Makes actionbar (prev/next) button stay fixed at bottom and close button fixed at top
          overflowY: props.overflowY || 'auto',
          backgroundColor: bluePlanetTheme.bluePlanetPalette.grey.light,
          borderRadius: borderRadius,
          padding: bluePlanetTheme.spacing(2),
          // Override root style
          [bluePlanetTheme.breakpoints.down('lg')]: {
            padding: bluePlanetTheme.spacing(2),
          },
        }}
        tabIndex={0}
      >
        <div className={cx('u-flex-space-between', 'u-flex-align-center', styles.floatingHeader)}>
          <IconButton
            className={cx('u-half-spacing', styles.closeButtonWrapper)}
            color="inherit"
            onClick={props.onClose}
            aria-label="Close"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {props.children}
      </DialogContent>
      {props.dialogActions ? (
        <DialogActions
          sx={{
            marginTop: theme.spacing(1),
            padding: {
              xs: '0',
            },
            borderRadius: borderRadius,
            backgroundColor: theme.bluePlanetPalette.grey.light,
            overflow: 'hidden',
          }}
        >
          {props.dialogActions}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
