import useSWR from 'hooks/useSWR';
import { CommunityMembershipDTO } from 'types/company/community';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

export const communityMembershipsKey = (companySlug: string) => companiesAPIUrls.communityMemberships.list(companySlug);

export const useCommunityMemberships = (companySlug: string) => {
  const swrResult = useSWR<{ values: CommunityMembershipDTO[] }>(communityMembershipsKey(companySlug));
  return useSWRResource(swrResult);
};
