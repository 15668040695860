import useResource from 'util/resource/useResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import { Organization } from 'types/organization';
import React from 'react';
import Resource from 'util/resource/Resource';
import { useContextualNavigation } from 'domain/shared/Navigation';
import { OrganizationLogo } from 'domain/shared/Navigation/Desktop/OrganizationLogo';
import { organizationUrls } from 'urls';
import SettingsIcon from 'ui/elements/icons/SettingsIcon';
import { HttpErrorType } from 'pages/common/error/helpers';
import ErrorPage from 'pages/common/error/ErrorPage';
import { OrganizationNotFound } from 'pages/OrganizationNotFound';

interface OrganizationContainerProps {
  slug: string;
  children: (organization: Organization) => React.ReactElement;
}

function OrganizationErrorPage({
  children,
  errorType,
}: {
  errorType: HttpErrorType | undefined;
  children: React.ReactElement;
}): JSX.Element {
  switch (errorType) {
    case 'NotFound':
    case 'Unauthorized':
      return <OrganizationNotFound />;
    case 'Error':
      return <ErrorPage />;
    default:
      return children;
  }
}

export function OrganizationContainer({ slug, children }: OrganizationContainerProps) {
  const { resource } = useResource<Organization>(OrganizationAPI.getBySlug(slug));

  const navItems =
    resource.state === 'fetched'
      ? [
          {
            href: organizationUrls.settings.settings(resource.resource.slug),
            label: 'Settings',
            identifier: 'Settings',
            isVisible: resource.resource.isAdmin,
            icon: <SettingsIcon fontSize="small" />,
          },
        ]
      : undefined;

  const navLogo =
    resource.state === 'fetched' ? (
      <OrganizationLogo slug={resource.resource.slug} logoUrl={resource.resource.logoUrl} />
    ) : undefined;

  useContextualNavigation({ navLinks: navItems ?? [], navLogo: navLogo }, [resource.state, slug]);

  return (
    <OrganizationErrorPage errorType={resource.state === 'error' ? resource.errorType : undefined}>
      <Resource resource={resource}>{organization => children(organization)}</Resource>
    </OrganizationErrorPage>
  );
}
