import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import NotifyAPI from 'apis/NotifyAPI';
import Dictionary from 'ui/elements/dictionary/Dictionary';
import DictionaryItem from 'ui/elements/dictionary/DictionaryItem';
import { ContentAPI } from 'apis/ContentAPI';
import config from 'config';
import Resources from 'util/resource/Resources';
import React from 'react';
import Button from 'ui/elements/buttons/Button';
import { backofficeUrls } from 'urls';
import { getOrUndefined } from 'util/resource';
import ExternalLinkIcon from 'ui/elements/icons/ExternalLink';

export default function UpdateStatsDialog({ updateId, onClose }: { onClose: () => void; updateId: UUID }) {
  const [notifyStatsResource] = useResourceLegacy(() => NotifyAPI.backoffice.getUpdateStats(updateId), [updateId]);
  const [contentStatsResource] = useResourceLegacy(
    () => ContentAPI(config.CONTENT_API_URL).stats.updateStats(updateId),
    [updateId],
  );

  const eventId = getOrUndefined(notifyStatsResource)?.eventId;

  return (
    <Dialog open onClose={onClose}>
      <Title>
        <div className="u-flex u-flex-space-between">
          <span>Update stats</span>
        </div>
      </Title>
      <Content>
        <Resources resources={[notifyStatsResource, contentStatsResource]}>
          {([notifyStats, contentStats]) => (
            <>
              <h5 className="text-metadata-medium u-content-spacing-bottom">Email stats</h5>
              <Dictionary>
                <DictionaryItem label="Delivered">{notifyStats.numDelivers}</DictionaryItem>
                <DictionaryItem label="Open">{notifyStats.numOpens}</DictionaryItem>
                <DictionaryItem label="Clicked">{notifyStats.numClicks}</DictionaryItem>
                <DictionaryItem label="Bounced">{notifyStats.numBounces}</DictionaryItem>
                <DictionaryItem label="Dropped">{notifyStats.numDrops}</DictionaryItem>
              </Dictionary>
              {eventId && (
                <div className="u-content-spacing-top ">
                  <a target="_blank" className="text-link" href={backofficeUrls.emails.view(eventId)} rel="noreferrer">
                    <ExternalLinkIcon className="u-quarter-spacing-right" /> View detailed stats in backoffice
                  </a>
                </div>
              )}
              <h5 className="text-metadata-medium u-content-spacing-bottom u-section-spacing-top">
                Where have users viewed the update?
              </h5>
              <Dictionary>
                <DictionaryItem label="In email">{contentStats.numSeenInMail}</DictionaryItem>
                <DictionaryItem label="In app">{contentStats.numSeenInApp}</DictionaryItem>
              </Dictionary>
            </>
          )}
        </Resources>
      </Content>
      <DialogActions>
        <Button kind="secondary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
