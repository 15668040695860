import SvgIcon, { IconProps } from '../SvgIcon';
import React from 'react';

export default function JpgIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3.5 13v-.8c0-2.97 0-4.45.47-5.64a7.24 7.24 0 0 1 4.37-4.12C9.6 2 11.17 2 14.32 2c1.8 0 2.7 0 3.42.25a4.14 4.14 0 0 1 2.5 2.36c.26.67.26 1.52.26 3.22V13" />
      <path d="M3.5 12c0-1.84 1.5-3.33 3.33-3.33.67 0 1.45.11 2.1-.06.58-.15 1.03-.6 1.18-1.18.17-.65.06-1.43.06-2.1C10.17 3.5 11.66 2 13.5 2M19.39 15.43c-.25-.13-1.03-.23-1.44-.13-1.56.37-2.12 1.49-2.12 2.9 0 1.98.48 3.03 3.56 2.86v-2.17h-1.36" />
      <path d="M9.9 21.18v-2.4m0 0v-2.4c0-.88.07-1.2.98-1.2h1.48c.82 0 1.49.8 1.49 1.8 0 .99-.67 1.8-1.49 1.8H9.9ZM5.97 15.18v4.8c0 .88-.07 1.2-.99 1.2H3.5" />
    </SvgIcon>
  );
}
