import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { halfSpacing } from 'ui/theme/themeConstants';

const Sticky = styled.div(
  ({ top = 0 }: { top?: number }) => `
    position: sticky;
    top:${top}px;
    z-index: 20;
    background-color: ${bluePlanetTheme.bluePlanetPalette.grey.light};
    padding: ${halfSpacing} 0;
}`,
);

export default Sticky;
