import useSWR from 'hooks/useSWR';
import { CompanyAccessDTO, Role, Scope } from 'types/company';
import { fetched, fetchError, isFetching } from 'util/resource';
import { companiesAPIUrls } from './companiesApi';

function mapScopes(scopes: string[]): Scope[] {
  return scopes.map(scope => {
    const [action, resource] = scope.split(':');
    return {
      action,
      resource,
    } as Scope;
  });
}

interface Access {
  roles: Role[];
  scopes: Scope[];
}

export const companyAccessKey = (slug: string) => companiesAPIUrls.getMe(slug);

export const useCompanyAccess = (slug: string) => {
  const response = useSWR<CompanyAccessDTO>(companyAccessKey(slug));

  const data: Access = { roles: response.data?.roles ?? [], scopes: mapScopes(response.data?.scopes ?? []) };

  if (response.error) {
    return {
      resource: fetchError(404),
      mutate: response.mutate,
    };
  }

  if (response.data) {
    return {
      resource: fetched(data),
      mutate: response.mutate,
    };
  }

  return {
    resource: isFetching(),
    mutate: response.mutate,
  };
};
