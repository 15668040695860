import Hidden from '@mui/material/Hidden';
import React from 'react';
import Logo from 'ui/domain/Logo';
import { ICompany } from 'types/company/company';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';

interface Props {
  company: ICompany;
}

const Div = styled.div`
  padding: ${contentSpacing} ${halfSpacing};
  background-color: ${bluePlanetTheme.bluePlanetPalette.white};
  display: flex;
  align-items: center;
`;

export default function CompanyHeaderMobile({ company }: Props) {
  return (
    <Hidden mdUp>
      <Div>
        <Logo className="u-half-spacing-right" type="company" url={company.logoURL} size={44} />
        {company.name && <h3 className="text-h3">{company.name}</h3>}
      </Div>
    </Hidden>
  );
}
