import useSWR from 'hooks/useSWR';
import { CommunityMemberSummary } from 'types/company/community';
import { useSWRResource } from 'util/resource/useSWRResource';
import { communityAPIUrls } from './communitiesApi';

export const communityKey = (communitySlug: string) => communityAPIUrls.getMemberSummary(communitySlug);

export const useCommunityMemberSummary = (communitySlug: string) => {
  const response = useSWR<CommunityMemberSummary>(communityKey(communitySlug));
  return useSWRResource(response);
};
