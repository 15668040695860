/* eslint-disable react/display-name */
import cx from 'classnames';
import React from 'react';

import styles from './cards.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  backgroundColor?: 'default' | 'white';
}
const CardStack = React.forwardRef<HTMLDivElement, Props>(({ className, children, backgroundColor }, ref) => {
  return (
    <div
      ref={ref}
      className={cx(styles.cardStack, className, {
        [styles.white]: backgroundColor === 'white',
      })}
    >
      {children}
    </div>
  );
});

export default CardStack;
