import React, { useState } from 'react';
import BaseLayout from 'ui/views/layouts/BaseLayout';
import Network from './network';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import Resources from 'util/resource/Resources';
import { DashboardData, DashboardMessage } from 'apis/DashboardAPI';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { CompanyListItem, ICompany } from 'types/company';
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';
import { userUrls } from 'urls';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import featureToggle from 'featureToggle';
import { LoadingScreen } from 'auth/AuthenticatedRoutes';
import { SelfUserProfile } from 'types/user';
import Tabs from 'ui/modules/tabs/Tabs';
import Tab from 'ui/modules/tabs/Tab';
import useRoute from 'hooks/useRoute';
import NotFound from 'pages/common/error/NotFound';
import UserUserConversationDialog from 'domain/conversations/UserUserConversationDialog';
import { Home } from './Home';
import { Manage } from './manage/Manage';
import { companiesCommunitiesByRole } from 'domain/companies/companyUtils';
import { useUserDashboardData } from './useUserDashboardData';
import { useFeaturedMatches } from 'apis/CompanyAPI/users/useFeaturedMatches';
import Shortlists from 'pages/Shortlists';
import { MyCommunity } from 'types/company/community';
import AddNewMenu from './sections/AddNewMenu';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { OrganizationListItemDTO, TinyOrganizationDTO } from 'types/organization';
import NetworkIcon from 'ui/elements/icons/NetworkIcon';
import BookmarkActiveIcon from 'ui/elements/icons/BookmarkActiveIcon';
import SettingsIcon from 'ui/elements/icons/SettingsIcon';
import { notEmpty } from 'util/arrayUtils';
import Sticky from 'ui/views/containers/Sticky';
import DashboardIcon from 'ui/elements/icons/DashboardIcon';
import { useOrganizations } from 'apis/OrganizationAPI/organizations/useOrganizations';

export default function Dashboard() {
  const companiesResource = useCompanies();
  const communitiesResource = useCommunities();
  const organizationsResource = useOrganizations();
  const { resource: userResource } = useSelfUserProfile();
  const { resource: matchesResource } = useFeaturedMatches();
  const { resource: dashboardDataResource } = useUserDashboardData();

  return (
    <Resources
      resources={[
        companiesResource.resource,
        communitiesResource.resource,
        organizationsResource.resource,
        dashboardDataResource,
        userResource,
        matchesResource, // Eager load for a smoother experience 😎
      ]}
      renderLoading={() => (
        <div className="u-flex u-flex-center u-flex-grow">
          <LoadingScreen />
        </div>
      )}
    >
      {([companies, communities, organizations, dashboardData, userProfile]) => (
        <DashboardContent
          communities={communities.values}
          companies={companies.values}
          organizations={organizations}
          dashboardData={dashboardData}
          userProfile={userProfile}
        />
      )}
    </Resources>
  );
}

export type WizardType =
  | { type: 'company'; company: ICompany }
  | { type: 'organization'; organization: TinyOrganizationDTO }
  | { type: 'user' }
  | { type: 'none' };

function getGreeting() {
  const hour = new Date().getHours();
  return hour > 18 || hour < 5 ? 'Good evening' : hour < 12 ? 'Good morning' : 'Good afternoon';
}

const tabConstants = ['home', 'manage', 'network', 'collections'] as const;

// This requires special attention because home is at the root path
export function isHomeTab(currentPath: string) {
  return currentPath === userUrls.dashboard.home();
}

function DashboardContent({
  companies,
  communities,
  organizations,
  dashboardData,
  userProfile,
}: {
  communities: MyCommunity[];
  companies: CompanyListItem[];
  organizations: OrganizationListItemDTO[];
  dashboardData: DashboardData;
  userProfile: SelfUserProfile;
}) {
  const [profilePreviewVisible, setProfilePreviewVisible] = useState(false);

  const toggleProfilePreview = () => {
    setProfilePreviewVisible(!profilePreviewVisible);
  };

  const organizationsIManage = organizations.filter(org => org.isAdmin);
  const { companiesIManage, communitiesIManage } = companiesCommunitiesByRole(companies, communities);
  const hasProfiles = companiesIManage.length + communitiesIManage.length + organizationsIManage.length > 0;

  const isFounderIn100PitchesCommunitySlug = communities.find(
    c => featureToggle.communityIs100Pitches(c.id) && c.roles.includes('company_admin_in_community'),
  )?.slug;
  const hundredPitchesMessage: DashboardMessage | undefined = isFounderIn100PitchesCommunitySlug
    ? { type: 'is-founder-in-100-pitches', communitySlug: isFounderIn100PitchesCommunitySlug }
    : undefined;

  const { push } = useRoute();

  const location = useLocation();
  const activeTab = isHomeTab(location.pathname)
    ? 'home'
    : tabConstants.filter(tab => location.pathname.includes(tab))[0];
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const tabs = [
    {
      title: 'Home',
      value: 'home',
      onClick: () => push(userUrls.dashboard.home()),
      icon: <DashboardIcon />,
    },
    hasProfiles
      ? {
          title: 'Manage',
          value: 'manage',
          onClick: () => push(userUrls.dashboard.manage()),
          icon: <SettingsIcon />,
        }
      : undefined,
    {
      title: 'Network',
      value: 'network',
      onClick: () => push(userUrls.dashboard.network.overview()),
      icon: <NetworkIcon />,
    },
    {
      title: 'Collections',
      value: 'collections',
      onClick: () => push(userUrls.dashboard.collections()),
      icon: <BookmarkActiveIcon />,
    },
  ].filter(notEmpty);

  return (
    <BaseLayout
      title="Home"
      marginTop="none"
      heading={
        isMobile ? undefined : (
          <div className="u-flex-grow">
            <h2 className="text-h3" style={{ marginBottom: 0 }}>
              {getGreeting()}
              {userProfile && (
                <>
                  , <button onClick={toggleProfilePreview}>{userProfile?.name ?? ''}</button>
                </>
              )}
            </h2>
            <div
              className="u-flex u-flex-align-center u-flex-space-between u-flex-grow u-content-spacing-top"
              style={{ position: 'relative' }}
            >
              <Tabs activeTab={activeTab}>
                {tabs.map(tab => (
                  <Tab
                    data-intercom-target={`dashboard-${tab.value}`}
                    key={tab.value}
                    onClick={tab.onClick}
                    value={tab.value}
                    label={tab.title}
                  />
                ))}
              </Tabs>
              <AddNewMenu />
            </div>
          </div>
        )
      }
    >
      {isMobile && (
        <Sticky>
          <Tabs activeTab={activeTab} variant="fullWidth">
            {tabs.map(tab => (
              <Tab
                data-intercom-target={`dashboard-${tab.value}`}
                key={tab.value}
                onClick={tab.onClick}
                value={tab.value}
                icon={tab.icon}
                label={tab.title}
              />
            ))}
          </Tabs>
        </Sticky>
      )}
      {isMobile && <AddNewMenu />}
      <Switch>
        <Route
          path="/network"
          render={() => <Network communities={communities} companies={companies} organizations={organizations} />}
        />
        {hasProfiles && (
          <Route
            exact
            path="/manage"
            render={() => <Manage companies={companies} communities={communities} organizations={organizations} />}
          />
        )}

        <Route path="/collections" component={Shortlists} />
        <Route
          exact
          path="/"
          render={() => <Home hundredPitchesMessage={hundredPitchesMessage} dashboardData={dashboardData} />}
        />
        <Route
          exact
          path="/conversation/:conversationId"
          render={(props: RouteComponentProps<{ conversationId: UUID }>) => (
            <UserUserConversationDialog conversationId={props.match.params.conversationId} />
          )}
        ></Route>
        <Route component={NotFound} />
      </Switch>
      <UserProfileDialog
        isOpen={profilePreviewVisible}
        cwUserId={userProfile.cwUserId}
        onClose={toggleProfilePreview}
      />
    </BaseLayout>
  );
}
