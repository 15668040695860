import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function UploadIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M17.5 9s0 0 0 0a4.5 4.5 0 0 1 .5 9m-.5-9v-.5a5.5 5.5 0 0 0-11-.5m11 1a5.5 5.5 0 0 1-1.3 3M6.5 8A5 5 0 0 0 6 18m.5-10A5 5 0 0 1 10 9M12 13v8m0-8c.7 0 2 2 2.5 2.5M12 13c-.7 0-2 2-2.5 2.5" />
    </SvgIcon>
  );
}
