import React from 'react';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import AnchorButton from 'ui/elements/buttons/AnchorButton';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import { isValidUrl } from 'util/stringUtils';

export default function RedirectDialog({
  externalRedirectHref,
  setExternalRedirectHref,
  checkboxState,
  setCheckboxState,
  setHasOptedOutOfExternalLinkInterception,
}: {
  externalRedirectHref: string;
  setExternalRedirectHref: (href?: string) => void;
  checkboxState: boolean;
  setCheckboxState: (state: boolean) => void;
  setHasOptedOutOfExternalLinkInterception: (hasOpted: boolean) => void;
}) {
  return (
    <Dialog open={!!externalRedirectHref} onClose={() => setExternalRedirectHref(undefined)}>
      <Title onClose={() => setExternalRedirectHref(undefined)}>Leaving CrowdWorks</Title>
      {isValidUrl(externalRedirectHref) ? (
        <>
          <Content>
            <p className="text-body u-section-spacing-bottom">
              You have clicked a user generated link on CrowdWorks that leads to <b>{externalRedirectHref}</b>
            </p>
            <AnchorButton
              kind="secondary"
              size="large"
              href={externalRedirectHref}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setExternalRedirectHref(undefined);
                setHasOptedOutOfExternalLinkInterception(checkboxState);
              }}
            >
              Follow link
            </AnchorButton>
          </Content>
          <DialogActions>
            <div>
              <p className="text-body u-content-spacing-bottom">
                Be sure you only follow links from sources you trust.
              </p>
              <Checkbox
                checked={checkboxState}
                onChange={() => setCheckboxState(!checkboxState)}
                color="primary"
                label="Don't show this again"
              />
            </div>
          </DialogActions>
        </>
      ) : (
        <DialogActions>
          <p className="text-body">
            You have clicked a user generated link on CrowdWorks that contains an invalid address (
            <b>{externalRedirectHref}</b>).
          </p>
        </DialogActions>
      )}
    </Dialog>
  );
}
