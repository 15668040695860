import cx from 'classnames';
import React from 'react';
import AreaChart, { LineSeriesPoint } from 'ui/elements/charts/AreaChart';
import { Kpi, KpiValue } from 'domain/kpis/types';
import { asUnixMillis } from 'util/dateUtils';
import { formatAsKpi, getTrend, sortKpiValuesDesc } from './utils';

export interface GraphMetric {
  x: number;
  y: number | string;
}

const getGraphData = (value: KpiValue[]) =>
  value
    .map(
      (metric: KpiValue): LineSeriesPoint => ({
        x: asUnixMillis(new Date(metric.timestamp)),
        y: metric.value,
      }),
    )
    .sort((a: GraphMetric, b: GraphMetric) => a.x - b.x);

export default function KPIGraph(props: { kpi?: Kpi }) {
  const kpiValues = props.kpi?.values ?? [];
  const metrics = getGraphData(kpiValues);
  const values = sortKpiValuesDesc(kpiValues).map(m => m.value);
  const direction = props.kpi ? getTrend(props.kpi) : 'neutral';
  const value = values[0] || 0;
  return (
    <>
      <h5 className="text-h4">{props.kpi?.details.name}</h5>
      <div
        className={cx(
          {
            'u-color-green': direction === 'positive',
            'u-color-red': direction === 'negative',
          },
          'text-h2',
          'u-align-right',
        )}
      >
        {formatAsKpi(value)}
        {props.kpi?.details.unitOfMeasure && (
          <span className="u-quarter-spacing-left text-metadata">{props.kpi?.details.unitOfMeasure}</span>
        )}
      </div>
      <AreaChart
        name=""
        main={{
          data: metrics,
        }}
        unit={props.kpi?.details.unitOfMeasure}
      />
    </>
  );
}
