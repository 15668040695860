import React from 'react';
import UserElement from 'ui/domain/Users/UserElement';
import UserConversationButton from 'domain/conversations/UserConversationButton';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import { getOrUndefined } from 'util/resource';
import { userUrls } from 'urls';
import { UserProfile } from 'types/user';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';

interface Props {
  user: UserProfile;
  className?: string;
  onClick?: () => void;
}
export default function User(props: Props) {
  const { user } = props;
  const { resource: userProfileResource } = useSelfUserProfile();
  const loggedInUser = getOrUndefined(userProfileResource);
  return (
    <UserElement
      className={props.className}
      href={props.onClick ? undefined : userUrls.show(user.cwUserId)}
      onClick={props.onClick}
      user={user}
      actions={
        user.cwUserId !== loggedInUser?.cwUserId && (
          <UserConversationButton className="text-medium" conversationWith={[user.cwUserId]}>
            <ChatBubblesIcon fontSize="small" />
          </UserConversationButton>
        )
      }
    />
  );
}
