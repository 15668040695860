import ApiBase from 'apis/ApiBase';
import { MentorNetworkMentorshiplist, MentorshipStatus } from 'apis/MentorAPI/types';
import { withPagination } from 'apis/utils';
import config from 'config';
import { Room, RoomWithUsers } from 'domain/rooms/types';
import { List, PaginatedResult, PaginationOptions, SortByOptions, SortOptions } from 'types/api';
import {
  CommunityCompanyCardDTO,
  CommunityInviteAs,
  CommunityInviteDetails,
  CommunityPitchViewStatsDTO,
  CommunitySummary,
  CommunityVisibility,
  CompaniesListFilters,
  MentorNetworkCompanyDTO,
  MentorNetworkFilters,
  MentorNetworkUserSummary,
  PatchCommunity,
  TinyCommunity,
} from 'types/company/community';
import {
  CompanyCommunityAccess,
  CompanyListItem,
  ICompany,
  ILabel,
  LabelDTO,
  Role,
  UpdateLabelDTO,
} from 'types/company/company';
import { InvestorSearchFilter, PendingUserFilter, UserFilter, UsersSearchFilter } from 'types/company/companyAPI';
import { TransferStatusDTO } from 'types/company/mentor';
import { AggregatedFollowRequestStatsDTO } from 'types/company/stats';
import { DocumentDownloadDTO, DocumentList } from 'types/documents';
import {
  CompactOrganization,
  OrganizationFilters,
  OrganizationRequestFilters,
  TinyOrganizationDTO,
} from 'types/organization';
import { InvestorProfile, InviteTokenDTO, UserProfile } from 'types/user';
import { EventDTO, NewEventDTO, UpdateEventDTO } from 'ui/domain/Event/types';
import { CompanyId, dateRangeQueryParam } from 'urls';
import { CommunityCompanySortOptions } from './types/types';

const api = ApiBase();
const baseUrl = config.COMPANIES_API_URL;

export const companiesListFiltersToQueryString = (
  filters?: CompaniesListFilters,
  paginationOptions?: PaginationOptions,
  orderBy?: CommunityCompanySortOptions,
) => {
  const params = new URLSearchParams();
  if (filters) {
    filters.label && filters.label.map(f => params.append('label', f.toString()));
    filters.stages && filters.stages.map(f => params.append('stage', f.toString()));
    filters.name && params.append('name', String(filters.name));
    filters.industry && filters.industry.map(f => params.append('industry', f.toString()));
    filters.fundingStage && filters.fundingStage.map(f => params.append('fundingStage', f.toString()));
    filters.fundingRound === 'ACTIVE' && params.append('fundingRound', 'ACTIVE');
    filters.seen && params.append('seen', filters.seen);
  }
  if (paginationOptions && paginationOptions.page) {
    params.append('page', String(paginationOptions.page));
  }
  if (paginationOptions && paginationOptions.limit) {
    params.append('limit', String(paginationOptions.limit));
  }
  if (orderBy) {
    params.append('orderBy', orderBy);
  }

  return params ? `?${params.toString()}` : '';
};

const getCommunityUrl = (communitySlug: CompanyId) => `${baseUrl}/communities/${communitySlug}`;

const getCommunityCompaniesUrl = (
  communitySlug: CompanyId,
  filters?: CompaniesListFilters,
  paginationOptions?: PaginationOptions,
  orderBy?: CommunityCompanySortOptions,
) => {
  return `${getCommunityUrl(communitySlug)}/companies${companiesListFiltersToQueryString(
    filters,
    paginationOptions,
    orderBy,
  )}`;
};

const getMentorNetworkMembersUrl = (
  communitySlug: string,
  orderBy: 'default' | 'random',
  filters?: MentorNetworkFilters,
) => {
  const params = new URLSearchParams();
  if (filters) {
    filters.label && filters.label.map(f => params.append('label', f.toString()));
    filters.dealleads && filters.dealleads.map(f => params.append('dealLeadUserId', f.toString()));
    filters.status && params.append('status', filters.status);
    filters.name && params.append('name', filters.name);
    filters.industry && filters.industry.map(f => params.append('industry', f.toString()));
  }
  if (orderBy) {
    params.append('orderBy', orderBy);
  }
  return `${getCommunityUrl(communitySlug)}/mentor-network/companies${params ? `?${params.toString()}` : ''}`;
};

const getDealLeadsUrl = (communitySlug: string, companyId: number) =>
  `${getCommunityUrl(communitySlug)}/companies/${companyId}/deal-leads`;

export const communityUrl = (communityId: number) => `${config.CONTENT_API_URL}/communities/${communityId}`;

export const communityAPIUrls = {
  getMemberSummary: (communitySlug: string) => `${baseUrl}/communities/${communitySlug}/member-summary`,
  getMe: (communitySlug: string) => `${baseUrl}/communities/${communitySlug}/users/me`,
  getSummary: (communityId: string | number, communityInviteCode?: string) =>
    `${baseUrl}/communities/${communityId}/summary${communityInviteCode ? `?communityCode=${communityInviteCode}` : ''}`,
  getPreview: (communityId: string | number) => `${baseUrl}/communities/${communityId}/preview`,
  list: () => `${baseUrl}/communities`,
  listFeatured: () => `${baseUrl}/communities/featured`,
  applications: {
    list: (communitySlug: string) => `${baseUrl}/communities/${communitySlug}/applications`,
    listForCompany: (communitySlug: string, companyId: number) =>
      `${baseUrl}/communities/${communitySlug}/applications/${companyId}`,
    answerForCompany: (communitySlug: string, companyId: number) =>
      `${baseUrl}/communities/${communitySlug}/applications/${companyId}`,
  },
  events: {
    list: (communitySlug: string) => `${getCommunityUrl(communitySlug)}/events?page=1&limit=100`,
    listAttachments: (communityId: number, eventId: UUID) =>
      `${getCommunityUrl(communityId)}/events/${eventId}/documents`,
  },
  industries: {
    list: (communitySlug: string) => `${baseUrl}/labels/industry/communities/${communitySlug}`,
  },
  stages: {
    list: (communitySlug: string) => `${baseUrl}/labels/stage/communities/${communitySlug}`,
  },
  accessRequest: {
    get: (communitySlug: string) => `${baseUrl}/communities/${communitySlug}/access-request`,
  },
  users: {
    listPending: (communitySlug: string) =>
      `${getCommunityUrl(communitySlug)}/admin/pending-users?status=pending&limit=1`,
    list: (communityId: number, paginationOptions?: PaginationOptions, filter?: UserFilter) =>
      withPagination(`${baseUrl}/communities/${communityId}/users`, paginationOptions, {
        q: filter && filter.name,
        sortBy: filter && filter.sortBy,
        sortOrder: filter && filter.sortOrder,
        audience: filter && filter.audience,
      }),
  },
  invitations: {
    get: (inviteCode: string) => `${baseUrl}/companyProfiles/from-invite-code/${inviteCode}`,
    getInviteToken: (communitySlug: string, role: CommunityInviteAs) =>
      `${getCommunityUrl(communitySlug)}/invitations/invite-token?role=${role}`,
  },
  investors: {
    list: (
      communitySlug: string,
      orderBy: SortByOptions,
      filter: InvestorSearchFilter,
      paginationOptions: PaginationOptions,
    ) => {
      const params = new URLSearchParams();
      params.append('orderBy', orderBy);
      filter.sortBy && params.append('sortBy', filter.sortBy);
      filter.industryPreference &&
        filter.industryPreference.map(f => params.append('industryPreference', f.toString()));
      filter.investorType && params.append('investorType', filter.investorType);
      filter.query && params.append('query', filter.query);
      paginationOptions && paginationOptions.page && params.append('page', String(paginationOptions.page));
      paginationOptions && paginationOptions.limit && params.append('limit', String(paginationOptions.limit));

      return `${getCommunityUrl(communitySlug)}/investors?${params ? params.toString() : ''}`;
    },
  },
  companies: {
    list: (
      communitySlug: CompanyId,
      orderBy: CommunityCompanySortOptions,
      paginationOptions?: PaginationOptions,
      filters?: CompaniesListFilters,
    ) => getCommunityCompaniesUrl(communitySlug, filters, paginationOptions, orderBy),
    listPending: (communitySlug: string) =>
      `${getCommunityUrl(communitySlug)}/admin/companies?visibilityFilter=Pending&limit=1`,
    listActivity: (communitySlug: string) => `${getCommunityUrl(communitySlug)}/companies/activity`,
  },
  organizations: {
    list: (communitySlug: string, orderBy: 'random' | 'alphabetically', paginationOptions?: PaginationOptions) => {
      const params = new URLSearchParams();
      params.append('orderBy', orderBy);
      paginationOptions && paginationOptions.page && params.append('page', String(paginationOptions.page));
      paginationOptions && paginationOptions.limit && params.append('limit', String(paginationOptions.limit));

      return `${getCommunityUrl(communitySlug)}/organizations?${params ? params.toString() : ''}`;
    },
  },
  labels: {
    list: (communitySlug: string, onlySelfServiceable = false) =>
      `${getCommunityUrl(communitySlug)}/labels?limit=1337${onlySelfServiceable ? '&onlySelfServiceable=true' : ''}`,
  },
};

export type CompanyResult = ICompany & { type: 'company' };
export type OrganizationResult = TinyOrganizationDTO & { type: 'organization' };

export type UserType = 'Founder' | 'Advisor' | 'Investor' | 'Employee' | 'Admin' | 'CommunityTeam';

export type UserResult = {
  cwUserId: UUID;
  name: string;
  logoURL?: string;
  userType: UserType;
  type: 'user';
};

export type JoinCommunityAs = { type: 'Advisor' } | { type: 'Investor' } | { type: 'Company'; companyId: number };

export type MemberSearchResult = CompanyResult | UserResult | OrganizationResult;

export const communitiesApi = {
  patch: (communitySlug: string, body: PatchCommunity) =>
    api.patch<PatchCommunity>(`${getCommunityUrl(communitySlug)}`, body),
  list: () => api.get<CompanyListItem[]>(communityAPIUrls.list()),
  listTeam: (communitySlug: string, params?: PaginationOptions) =>
    api.get<PaginatedResult<UserProfile>>(`${baseUrl}/communities/${communitySlug}/team`, {
      requestParams: params,
    }),
  joinAsExistingUser: (accessToken: string | undefined, code: string) =>
    api.post<TinyCommunity>(`${baseUrl}/communities/join/${code}`, {}, { Authorization: `Bearer ${accessToken}` }),
  getSummary: (communitySlug: string) => api.get<CommunitySummary>(communityAPIUrls.getSummary(communitySlug)),
  search: (communitySlug: string, query: string) => `${baseUrl}/communities/${communitySlug}/search?query=${query}`,

  accessRequest: {
    delete: (communitySlug: string) => api.deleteRequest(`${baseUrl}/communities/${communitySlug}/access-request`),
    requestToJoin: (communitySlug: string, body: { inviteCode?: string; joinAs: JoinCommunityAs }) =>
      api.post<CommunitySummary>(`${baseUrl}/communities/${communitySlug}/access-request`, body),
  },
  events: {
    create: (communityId: number, payload: NewEventDTO) =>
      api.post<EventDTO>(`${getCommunityUrl(communityId)}/events`, payload),
    delete: (communityId: number, eventId: UUID) =>
      api.deleteRequest<EventDTO>(`${getCommunityUrl(communityId)}/events/${eventId}`),
    update: (communityId: number, eventId: UUID, payload: UpdateEventDTO) =>
      api.put<UpdateEventDTO>(`${getCommunityUrl(communityId)}/events/${eventId}`, payload),

    listAttachments: (communityId: number, eventId: UUID) =>
      api.get<DocumentList>(communityAPIUrls.events.listAttachments(communityId, eventId)),
    download: (communityId: number, eventId: UUID, documentId: UUID) =>
      api.get<DocumentDownloadDTO>(
        `${getCommunityUrl(communityId)}/events/${eventId}/documents/download/${documentId}`,
      ),
  },
  admin: {
    linkedCommunities: {
      get: (communitySlug: string) =>
        api.get<List<TinyCommunity>>(`${getCommunityUrl(communitySlug)}/admin/linked-communities`),
      getRelatedCommunityCompanies: (communitySlug: string, companyIds: number[], toCommunity: number) =>
        api.post<CompanyCommunityAccess>(
          `${getCommunityUrl(communitySlug)}/admin/linked-communities/${toCommunity}/companies`,
          {
            companyIds,
          },
        ),
      transfer: (communitySlug: string, companyIds: number[], toCommunity: number) =>
        api.post<void>(`${getCommunityUrl(communitySlug)}/admin/linked-communities/${toCommunity}`, {
          companyIds,
        }),
    },
    getStats: (communitySlug: string) => `${getCommunityUrl(communitySlug)}/admin/stats`,
    listCompaniesInCommunity: (
      communitySlug: string,
      filters?: { name?: string; visibility?: CommunityVisibility[]; label?: ILabel['id'][]; location?: string },
      sortOptions?: SortOptions,
      paginationOptions?: PaginationOptions,
    ) => {
      return withPagination(`${getCommunityUrl(communitySlug)}/admin/companies`, paginationOptions, {
        sortBy: sortOptions?.sortBy,
        sortOrder: sortOptions?.sortOrder,
        nameFilter: filters?.name,
        label: filters?.label,
        visibilityFilter: filters?.visibility,
        location: filters?.location,
      });
    },
    listOrganizationsInCommunity: (
      communitySlug: string,
      filters?: { name?: string; visibility?: CommunityVisibility[] },
      sortOptions?: SortOptions,
      paginationOptions?: PaginationOptions,
    ) => {
      return withPagination(`${getCommunityUrl(communitySlug)}/admin/organizations`, paginationOptions, {
        sortBy: sortOptions?.sortBy,
        sortOrder: sortOptions?.sortOrder,
        nameFilter: filters?.name,
        visibilityFilter: filters?.visibility,
      });
    },
    exportCompaniesInCommunity: (
      communitySlug: string,
      filters?: { name?: string; visibility?: CommunityVisibility[]; label?: ILabel['id'][]; location?: string },
      sortOptions?: SortOptions,
      columns?: string[],
    ) => {
      const requestParams = {
        sortBy: sortOptions?.sortBy,
        sortOrder: sortOptions?.sortOrder,
        nameFilter: filters?.name,
        label: filters?.label,
        visibilityFilter: filters?.visibility,
        location: filters?.location,
        columns,
      };
      return api.get<Blob>(`${getCommunityUrl(communitySlug)}/admin/companies/export`, {
        requestParams,
        responseType: 'blob',
      });
    },
    hideCompanyFromCommunity: (communitySlug: string, companyId: number) =>
      api.post<void>(`${getCommunityUrl(communitySlug)}/admin/companies/${companyId}/hide`, {}),
    showCompanyInCommunity: (communitySlug: string, companyId: number) =>
      api.post<void>(`${getCommunityUrl(communitySlug)}/admin/companies/${companyId}/show`, {}),
    approveCompanyAccess: (communitySlug: string, companyId: number) =>
      api.post<void>(`${getCommunityUrl(communitySlug)}/admin/companies/${companyId}/approve`, {}),
    hideOrganizationFromCommunity: (communitySlug: string, organizationId: UUID) =>
      api.post<void>(`${getCommunityUrl(communitySlug)}/admin/organizations/${organizationId}/hide`, {}),
    showOrganizationInCommunity: (communitySlug: string, organizationId: UUID) =>
      api.post<void>(`${getCommunityUrl(communitySlug)}/admin/organizations/${organizationId}/show`, {}),
    removeCompany: (communitySlug: string, companyId: number) =>
      api.deleteRequest(`${getCommunityUrl(communitySlug)}/admin/companies/${companyId}`),
    removeOrganization: (communitySlug: string, organizationId: UUID) =>
      api.deleteRequest(`${getCommunityUrl(communitySlug)}/admin/organizations/${organizationId}`),
    listPendingUsers: (communitySlug: string, paginationOptions: PaginationOptions, filters: PendingUserFilter) =>
      withPagination(`${getCommunityUrl(communitySlug)}/admin/pending-users`, paginationOptions, filters),
    accept: (communitySlug: string, userId: number) =>
      api.post(`${getCommunityUrl(communitySlug)}/admin/pending-users/${userId}/accept`, {}),
    reject: (communitySlug: string, userId: number) =>
      api.deleteRequest(`${getCommunityUrl(communitySlug)}/admin/pending-users/${userId}`),
  },
  stats: {
    followRequests: (communitySlug: string, fromDate: Date, toDate: Date) =>
      api.get<AggregatedFollowRequestStatsDTO>(
        `${getCommunityUrl(communitySlug)}/stats/follow-requests?${dateRangeQueryParam(fromDate, toDate)}`,
      ),
    pitchViews: (communitySlug: string, fromDate: Date, toDate: Date) =>
      api.get<CommunityPitchViewStatsDTO>(
        `${getCommunityUrl(communitySlug)}/stats/pitch-views?${dateRangeQueryParam(fromDate, toDate)}`,
      ),
  },
  getReusableToken: (communitySlug: string, role: CommunityInviteAs) =>
    api.get<CommunityInviteDetails>(`${getCommunityUrl(communitySlug)}/invitations/invite-token?role=${role}`),
  invites: {
    getInviteToken: (communitySlug: string, role: Role) =>
      api.get<InviteTokenDTO>(`${baseUrl}/communities/${communitySlug}/invitations/users/invite-token?role=${role}`),
    invite: (communitySlug: string, emails: string[], invitedAs: CommunityInviteAs) => {
      return api.post<void>(`${getCommunityUrl(communitySlug)}/invitations/bulk-invite`, { emails, invitedAs });
    },
    list: (communitySlug: string, filter?: CommunityInviteAs, paginationOptions?: PaginationOptions) =>
      withPagination(`${getCommunityUrl(communitySlug)}/invitations`, paginationOptions, { invitedAs: filter }),
    retractInvitation: (communitySlug: string, inviteCode: string) =>
      api.deleteRequest(`${getCommunityUrl(communitySlug)}/invitations/${inviteCode}`),
  },
  mentorNetwork: {
    getMentorNetworkMembers: (
      communitySlug: string,
      orderBy: 'default' | 'random',
      paginationOptions: PaginationOptions,
      filters: MentorNetworkFilters,
    ) =>
      api.get<PaginatedResult<MentorNetworkCompanyDTO>>(getMentorNetworkMembersUrl(communitySlug, orderBy, filters), {
        requestParams: paginationOptions,
      }),
    getReport: (communitySlug: string) =>
      api.get<
        {
          companyName: string;
          acceptedDate: string;
          acceptedMonth?: string;
          mentor?: string;
          status: MentorshipStatus;
          ul: string;
          payment: string;
        }[]
      >(`${getCommunityUrl(communitySlug)}/mentor-network/companies/report`),
    getUserSummary: (communitySlug: string) =>
      api.get<MentorNetworkUserSummary>(`${getCommunityUrl(communitySlug)}/mentor-network/user-summary`),
    transfer: {
      status: (communitySlug: string, companyId: number) =>
        api.get<TransferStatusDTO>(`${getCommunityUrl(communitySlug)}/mentor-network/transfer/${companyId}/status`),
      transfer: (communitySlug: string, companyId: number, transferToCommunity: number) =>
        api.post<void>(
          `${getCommunityUrl(communitySlug)}/mentor-network/transfer/${companyId}/${transferToCommunity}`,
          {},
        ),
    },
    mentorships: {
      list: (communitySlug: string, companyId: number) =>
        api.get<MentorNetworkMentorshiplist>(`${getCommunityUrl(communitySlug)}/companies/${companyId}/mentorships`),
      listAsMentor: (communitySlug: string) =>
        api.get<List<ICompany>>(`${getCommunityUrl(communitySlug)}/mentor-network/mentor`),

      downloadFinalReport: (communitySlug: string, companyId: number, mentorshipId: UUID) =>
        api.get<DocumentDownloadDTO>(
          `${getCommunityUrl(communitySlug)}/companies/${companyId}/mentorships/${mentorshipId}/final-report-file`,
        ),
      downloadMentorAgreement: (communitySlug: string, companyId: number, mentorshipId: UUID) =>
        api.get<DocumentDownloadDTO>(
          `${getCommunityUrl(communitySlug)}/companies/${companyId}/mentorships/${mentorshipId}/mentor-agreement`,
        ),
      mentorAssignments: {
        add: (communitySlug: string, companyId: CompanyId, mentorshipId: UUID, userId: number) =>
          api.post(
            `${getCommunityUrl(communitySlug)}/companies/${companyId}/mentorships/${mentorshipId}/mentor-assignments`,
            { userId },
          ),
        remove: (communitySlug: string, companyId: CompanyId, mentorshipId: UUID) =>
          api.deleteRequest(
            `${getCommunityUrl(communitySlug)}/companies/${companyId}/mentorships/${mentorshipId}/mentor-assignments`,
          ),
      },
    },
  },
  getCompanies: (
    communitySlug: string,
    orderBy: CommunityCompanySortOptions,
    paginationOptions?: PaginationOptions,
    filters?: CompaniesListFilters,
  ) =>
    api.get<PaginatedResult<CommunityCompanyCardDTO>>(
      getCommunityCompaniesUrl(communitySlug, filters, paginationOptions, orderBy),
    ),
  getCompany: (communityId: number, companyId: string) =>
    api.get<ICompany>(`${baseUrl}/communities/${communityId}/companies/${companyId}`),
  communityLabels: {
    add: (communitySlug: string, label: UpdateLabelDTO) =>
      api.post<LabelDTO>(`${getCommunityUrl(communitySlug)}/labels`, label),
    update: (communitySlug: string, labelId: string, label: UpdateLabelDTO) =>
      api.put<LabelDTO>(`${getCommunityUrl(communitySlug)}/labels/${labelId}`, label),
    remove: (communitySlug: string, labelId: string, forceDelete?: boolean) =>
      api.deleteRequest<void>(`${getCommunityUrl(communitySlug)}/labels/${labelId}`, undefined, {
        requestParams: { force: forceDelete },
      }),
  },
  communityMemberLabels: {
    get: (communitySlug: string, companyId: number, paginationOptions?: PaginationOptions) =>
      api.get<PaginatedResult<LabelDTO>>(`${baseUrl}/communities/${communitySlug}/companies/${companyId}/labels`, {
        requestParams: paginationOptions,
      }),
    remove: (communitySlug: string, companyId: number, labelId: LabelDTO['id']) =>
      api.deleteRequest<void>(`${getCommunityUrl(communitySlug)}/companies/${companyId}/labels/${labelId}`),
    attach: (communitySlug: string, companyId: number, labelId: LabelDTO['id']) =>
      api.post<void>(`${getCommunityUrl(communitySlug)}/companies/${companyId}/labels`, { labelId }),
  },

  dealLeads: {
    list: (communitySlug: string, communityCompanyId: number) =>
      api.get<List<UserProfile>>(getDealLeadsUrl(communitySlug, communityCompanyId)),
    add: (communitySlug: string, communityCompanyId: number, userId: number) =>
      api.post(getDealLeadsUrl(communitySlug, communityCompanyId), { userId }),
    remove: (communitySlug: string, communityCompanyId: number, userId: number) =>
      api.deleteRequest(`${getDealLeadsUrl(communitySlug, communityCompanyId)}/${userId}`),
  },
  listInvestors: (
    communitySlug: string,
    orderBy: SortByOptions,
    filter: InvestorSearchFilter,
    paginationOptions: PaginationOptions,
  ) =>
    api.get<PaginatedResult<InvestorProfile>>(`${getCommunityUrl(communitySlug)}/investors`, {
      requestParams: { ...filter, ...paginationOptions, orderBy },
    }),
  organizations: {
    list: (
      communitySlug: string,
      orderBy?: 'random' | 'alphabetically',
      paginationOptions?: PaginationOptions,
      filters?: OrganizationRequestFilters,
    ) =>
      api.get<PaginatedResult<CompactOrganization>>(`${getCommunityUrl(communitySlug)}/organizations`, {
        requestParams: { ...filters, ...paginationOptions, orderBy },
      }),
    getFilters: (communitySlug: string) =>
      api.get<OrganizationFilters>(`${getCommunityUrl(communitySlug)}/organizations/filters`),
  },

  rooms: {
    getUserList: (communitySlug: string, filter: UsersSearchFilter) =>
      api.get<PaginatedResult<UserProfile>>(`${baseUrl}/communities/${communitySlug}/rooms/user-list`, {
        requestParams: filter,
      }),
    create: (communitySlug: string, companyId: number, name: string, users: UUID[], admins: UUID[]) =>
      api.post<RoomWithUsers>(`${baseUrl}/communities/${communitySlug}/companies/${companyId}/rooms`, {
        name,
        users,
        admins,
      }),
    get: (communityId: number, companyId: number, roomId: UUID) =>
      api.get<RoomWithUsers>(`${baseUrl}/communities/${communityId}/companies/${companyId}/rooms/${roomId}`),
    list: (communityId: number, companyId: number) =>
      api.get<List<Room>>(`${baseUrl}/communities/${communityId}/companies/${companyId}/rooms`),

    patch: (communitySlug: string, companyId: number, roomId: UUID, users: UUID[], admins: UUID[]) =>
      api.patch<RoomWithUsers>(`${baseUrl}/communities/${communitySlug}/companies/${companyId}/rooms/${roomId}`, {
        users,
        admins,
      }),
  },
  users: {
    search: (communitySlug: string, params: UsersSearchFilter, orderBy: SortByOptions) =>
      api.get<PaginatedResult<UserProfile>>(`${getCommunityUrl(communitySlug)}/users`, {
        requestParams: { ...params, orderBy },
      }),
  },
};
