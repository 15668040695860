import React from 'react';
import {
  roleArticle as companyRoleArticle,
  roleDisplayName as companyRoleDisplayName,
} from 'domain/companies/roleUtils';
import SmallAccordion from 'ui/modules/Accordion/SmallAccordion';
import { Role } from 'types/company';
import { unCapitalize } from 'util/stringUtils';
import { Link } from 'react-router-dom';
import { communityUrls, companyUrls, organizationUrls } from 'urls';
import { DetailedUserProfile, EntityInCommon } from 'types/user';
import { notEmpty } from 'util/arrayUtils';
import groupBy from 'ramda/src/groupBy';
import sort from 'ramda/src/sort';

interface Props {
  user: DetailedUserProfile;
  linkClassName?: string;
}

function joinToElements(list: EntityInCommon[], transform: (entity: EntityInCommon) => JSX.Element | string) {
  return list.map((item, index) => (
    <span key={`item-${item.entity.id}`}>
      {transform(item)}
      {index < list.length - 2 && ', '}
      {index == list.length - 2 && ' and '}
    </span>
  ));
}

const companyPlural = (num: number) => (num === 1 ? 'company' : 'companies');
const organizationPlural = (num: number) => (num === 1 ? 'organization' : 'organizations');

function roleSortValue(role: Role) {
  const roles = [
    'prospective_investor',
    'mentor',
    'advisor',
    'investor',
    'employee',
    'board',
    'company_admin_in_community',
    'company_master',
  ];
  return roles.indexOf(role);
}

function sortRoles(role: Role, other: Role) {
  return roleSortValue(other) - roleSortValue(role);
}

// Displays all companies/organizations user manages that is also part
// of a community you are a part of
function companyAdminInCommunityText(user: DetailedUserProfile, linkClassName?: string) {
  const allCommunitiesAsEntityAdmin = joinToElements(
    user.entitiesInCommon.filter(
      item =>
        item.type === 'community' &&
        (item.role === 'company_admin_in_community' || item.role === 'organization_admin_in_community'),
    ),
    item => (
      <Link className={linkClassName} to={communityUrls.overview(item.entity.slug)}>
        {item.entity.name}
      </Link>
    ),
  );

  const companiesUserManagesInCommunities = joinToElements(
    user.entitiesInCommon.filter(item => item.type === 'company' && item.isCompanyInCommunity),
    item => (
      <a className={linkClassName} href={companyUrls.overview(item.entity.slug, 'profile')}>
        {item.entity.name}
      </a>
    ),
  );

  const organizationsUserManagesInCommunities = joinToElements(
    user.entitiesInCommon.filter(item => item.type === 'organization' && item.isOrganizationInCommunity),
    item => (
      <a className={linkClassName} href={organizationUrls.view(item.entity.slug)}>
        {item.entity.name}
      </a>
    ),
  );

  // User isn't company/organization admin in any communities that I am a part of
  if (
    allCommunitiesAsEntityAdmin.length === 0 &&
    companiesUserManagesInCommunities.length === 0 &&
    organizationsUserManagesInCommunities.length === 0
  )
    return null;

  // User is company/organization admin in any communities that I am a part of, and the companies/organizations are visible in the community
  const entitiesJSX: JSX.Element[] = [];

  const organizationText = organizationsUserManagesInCommunities.length > 0 && (
    <>
      <span>{user.name}</span> is managing the {organizationPlural(organizationsUserManagesInCommunities.length)}{' '}
      <span>{organizationsUserManagesInCommunities}</span>
    </>
  );

  const companyText = companiesUserManagesInCommunities.length > 0 && (
    <>
      {organizationsUserManagesInCommunities.length > 0 ? (
        ', the '
      ) : (
        <>
          <span>{user.name}</span> is managing the{' '}
        </>
      )}
      {companyPlural(companiesUserManagesInCommunities.length)} <span>{companiesUserManagesInCommunities}</span>
    </>
  );

  const communityText = (organizationsUserManagesInCommunities.length > 0 ||
    companiesUserManagesInCommunities.length > 0) && (
    <>
      {' and is a part of '}
      {allCommunitiesAsEntityAdmin.length > 0 ? allCommunitiesAsEntityAdmin : ' a community you are a part of'}
    </>
  );

  if (organizationText) entitiesJSX.push(organizationText);
  if (companyText) entitiesJSX.push(companyText);
  if (communityText) entitiesJSX.push(communityText);

  if (entitiesJSX.length > 0) {
    return (
      <>
        {entitiesJSX.map((entityText, i) => (
          <span key={`entity-${i}`}>{entityText}</span>
        ))}
      </>
    );
  }

  // User is a company/organization admin in any communities that I am a part of, but the companies/organizations aren't visible
  return (
    <>
      <span>{user.name}</span> is a part of{' '}
      {allCommunitiesAsEntityAdmin.length > 0 ? allCommunitiesAsEntityAdmin : ' a community you are a part of'}
    </>
  );
}

// displays all roles user has in communities in common with you
// except the 'company admin in community' and the 'organization admin in community' roles
function communityTexts(user: DetailedUserProfile, linkClassName?: string) {
  const entitiesInCommon = user.entitiesInCommon;
  const allCommunityRolesExceptCompanyAndOrganizationAdmin = groupBy(
    item => item.role,
    entitiesInCommon.filter(
      item =>
        item.role !== 'company_admin_in_community' &&
        item.role != 'organization_admin_in_community' &&
        item.type === 'community',
    ),
  );

  return Object.keys(allCommunityRolesExceptCompanyAndOrganizationAdmin).map((role: Role) => {
    const companyNames = joinToElements(allCommunityRolesExceptCompanyAndOrganizationAdmin[role], item => (
      <Link className={linkClassName} to={communityUrls.overview(item.entity.slug)}>
        {item.entity.name}
      </Link>
    ));
    const displayText = `is a part of `;
    return (
      <div key={`insight-${role}`}>
        <span>{user.name}</span> {displayText} <span>{companyNames}</span>
      </div>
    );
  });
}

// displays all roles user has in companies/organizations in common with you
function companyTexts(user: DetailedUserProfile, linkClassName?: string) {
  const allCompanies = groupBy(
    item => (item.type === 'company' || item.type === 'organization' ? item.role : ''),
    user.entitiesInCommon.filter(
      item =>
        item.type !== 'community' &&
        (item.type === 'company' ? !item.isCompanyInCommunity : !item.isOrganizationInCommunity),
    ),
  );

  const sortedRoles = sort(sortRoles, Object.keys(allCompanies));

  return sortedRoles.map((role: Role) => {
    const companyNames = joinToElements(allCompanies[role], item =>
      item.type === 'company' || item.type === 'organization' ? (
        <a
          className={linkClassName}
          href={
            item.type === 'company'
              ? companyUrls.overview(item.entity.slug, 'profile')
              : organizationUrls.view(item.entity.slug)
          }
        >
          {item.type === 'company' ? item.entity.name : item.entity.name}
        </a>
      ) : (
        <></>
      ),
    );
    const displayText =
      role === 'company_master'
        ? `is managing the ${companyPlural(companyNames.length)}`
        : role === 'organization_master'
          ? `is managing the ${organizationPlural(companyNames.length)}`
          : `is ${companyRoleArticle(role)} ${unCapitalize(companyRoleDisplayName(role))} in`;
    return (
      <div key={`insight-${role}`}>
        <span>{user.name}</span> {displayText} <span>{companyNames}</span>
      </div>
    );
  });
}

export default function InsightsContent(props: Props) {
  const allItems = [
    companyAdminInCommunityText(props.user, props.linkClassName),
    ...companyTexts(props.user, props.linkClassName),
    ...communityTexts(props.user, props.linkClassName),
  ].filter(notEmpty);

  return (
    <div>
      {allItems.length === 1 && <div>{allItems[0]}</div>}

      {allItems.length > 1 && (
        <SmallAccordion
          title={allItems[0]}
          viewMoreText={`Show ${allItems.length - 1} more`}
          viewLessText="Show less"
          align="right"
        >
          {allItems.slice(1).map((common, i) => (
            <div key={`shared-connection-${i}`}>{common}</div>
          ))}
        </SmallAccordion>
      )}
    </div>
  );
}
