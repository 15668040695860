import { captureException, captureMessage as sentryCaptureMessage, SeverityLevel } from '@sentry/browser';
import isDev from 'util/isDev';

export function log(message: string, severity: SeverityLevel) {
  if (sentryCaptureMessage) {
    sentryCaptureMessage(message, severity);
  }
  if (isDev()) {
    console.error(message);
  }
}

function isError(error: any): error is Error {
  return error instanceof Error;
}

export function logError(error: Error | any, message?: string, additionalData?: Record<string, unknown>) {
  const data = additionalData ?? {};

  if (captureException && isError(error)) {
    captureException(error, {
      contexts: {
        message: { value: message },
        additionalData: data,
      },
    });
  } else if (sentryCaptureMessage) {
    const msg =
      error?.message ??
      error?.data?.message ??
      error?.data?.developerMessage ??
      (typeof message === 'string' ? message : 'An unnamed error has been logged');

    sentryCaptureMessage(msg, {
      level: 'error',
      contexts: {
        message: { value: message },
        additionalData: data,
        error: error,
      },
    });
  }
  if (isDev()) {
    console.error(error);
  }
}

export function logWarning(message: string) {
  if (sentryCaptureMessage) {
    sentryCaptureMessage(message, 'warning');
  }
  if (isDev()) {
    console.warn(message);
  }
}

export function logInfo(message: string) {
  if (sentryCaptureMessage) {
    sentryCaptureMessage(message, 'info');
  }
  if (isDev()) {
    console.error(message);
  }
}
