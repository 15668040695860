import { useCloseableMessage } from 'apis/CompanyAPI/users/useUiState';
import React, { useEffect, useState } from 'react';
import AnchorButton from 'ui/elements/buttons/AnchorButton';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';

export default function useDownloadWarning(fileName: string, onDownload?: () => any) {
  const [warningDialogIsOpen, setWarningDialogIsOpen] = useState(false);

  const {
    isClosed: hasOptedOutOfDownloadWarning,
    closeMessage: setHasOptedOutOfDownloadWarning,
    isLoading,
  } = useCloseableMessage(`has-opted-out-of-file-download-warning`);

  const [checkboxState, setCheckboxState] = useState<{ isTouched: boolean; value?: boolean }>({
    isTouched: false,
    value: hasOptedOutOfDownloadWarning,
  });

  useEffect(() => {
    setCheckboxState({ isTouched: checkboxState.isTouched, value: hasOptedOutOfDownloadWarning });
  }, [isLoading]);

  const onClick = onDownload
    ? () => {
        if (hasOptedOutOfDownloadWarning || warningDialogIsOpen) {
          onDownload();
          setWarningDialogIsOpen(false);
          checkboxState.isTouched && checkboxState.value === true && setHasOptedOutOfDownloadWarning();
        } else {
          setWarningDialogIsOpen(true);
        }
      }
    : undefined;

  const WarningDialog = (
    <Dialog open={warningDialogIsOpen} onClose={() => setWarningDialogIsOpen(false)}>
      <Title onClose={() => setWarningDialogIsOpen(false)}>Downloading file</Title>
      <>
        <Content>
          <p className="text-body u-section-spacing-bottom">
            You are downloading a user-uploaded file from CrowdWorks: <b>{fileName}</b>
          </p>
          <AnchorButton onClick={onClick} kind="secondary" size="large">
            Download file
          </AnchorButton>
        </Content>
        <DialogActions>
          <div>
            <p className="text-body u-content-spacing-bottom">
              Be sure you only download files from sources you trust.
            </p>
            <Checkbox
              checked={checkboxState.value ?? false}
              onChange={() => setCheckboxState({ isTouched: true, value: !checkboxState.value })}
              color="primary"
              label="Don't show this again"
            />
          </div>
        </DialogActions>
      </>
    </Dialog>
  );

  return { onClick, WarningDialog };
}
