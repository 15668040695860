import React, { useState } from 'react';
import { PitchDeckStats } from 'apis/CompanyAPI/insights/types';
import ProgressPie from 'ui/elements/Progress/ProgressPie';
import { useTheme } from '@mui/material';
import PitchDeckViews, { timeSpentText } from './PitchDeckViews';
import { Link } from 'react-router-dom';
import { companyUrls } from 'urls';
import DateRangePresetsPicker from 'ui/elements/DateRangePresetsPicker';
import { STATS_DATE_RANGES, STATS_MAX_DAYS_RANGE } from 'domain/Stats/StatsDateRanges';
import useResource from 'util/resource/useResource';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import { ICompany } from 'types/company';
import Resource from 'util/resource/Resource';
import SectionHeading from 'ui/elements/SectionHeading';
import Table from 'ui/elements/tables/Table/Table';
import { isValidating } from 'util/resource';
import { investorTypes } from 'types/user';
import { formatShortLocation } from 'util/locationUtils';
import { timeSince } from 'util/dateUtils';

interface Props {
  company: ICompany;
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  shouldDisplayInsightsLink?: boolean;
}

export default function PitchDeckInsights({ company, dateRange, shouldDisplayInsightsLink }: Props) {
  const theme = useTheme();

  const [range, setRange] = useState(dateRange);

  const { resource: pitchDeckViews } = useResource<PitchDeckStats>(
    companiesAPIUrls.insights.pitchDeckViews(company.id, range.startDate, range.endDate),
    { keepPreviousData: true },
  );

  function getInvestorType(investorType?: string) {
    if (investorType && investorType != 'other') {
      return investorTypes[investorType].name;
    } else {
      return 'Investor';
    }
  }

  return (
    <div>
      <div className="u-flex u-flex--gap-1 u-flex--wrap u-flex-align-center">
        <h4 className="text-h2">Pitch Deck Insights</h4>
        {shouldDisplayInsightsLink && (
          <Link className="text-link" to={companyUrls.insights(company.slug)}>
            See all company profile insights &gt;
          </Link>
        )}
      </div>
      <div className="u-content-spacing-top">
        <DateRangePresetsPicker
          presets={STATS_DATE_RANGES}
          maxDaysRange={STATS_MAX_DAYS_RANGE}
          initialRange={range}
          onChange={newRange => {
            setRange(newRange);
          }}
        />
      </div>
      <Resource resource={pitchDeckViews}>
        {views => {
          return (
            <div>
              <div className="u-flex u-flex--align-center u-flex--wrap u-content-spacing-top u-half-spacing-bottom">
                <SectionHeading
                  margin="none"
                  heading="Pitch deck views"
                  className="text-nowrap u-content-spacing-right"
                  addMobileMargin
                />
                <span className="u-content-spacing-x u-quarter-spacing-y">
                  Showing aggregated data from the latest uploaded pitch deck
                </span>
              </div>
              <PitchDeckViews pitchDeckViews={views} />
              <SectionHeading heading="Activity" className="u-section-spacing-top" addMobileMargin />
              <Table
                isLoading={isValidating(pitchDeckViews)}
                infoMessages={{
                  noResults: 'There are no pitch deck viewers yet.',
                }}
              >
                <Table.Header>
                  <Table.HeaderCell>Visitor</Table.HeaderCell>
                  <Table.HeaderCell>Duration</Table.HeaderCell>
                  <Table.HeaderCell>Viewed slides</Table.HeaderCell>
                  <Table.HeaderCell>Location</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {views.views.map((visit, idx) => (
                    <Table.Row key={idx}>
                      <Table.DataCell>
                        <div className="u-flex u-flex--column">
                          <span>{getInvestorType(visit.investorType)}</span>
                          <span className="text-metadata">{timeSince(visit.viewedAt)}</span>
                        </div>
                      </Table.DataCell>
                      <Table.DataCell>{timeSpentText(visit.durationInSeconds)}</Table.DataCell>
                      <Table.DataCell>
                        <div className="u-flex u-flex--gap-half">
                          <ProgressPie
                            percentage={(visit.viewedSlideCount / views.totalSlideCount) * 100}
                            width={25}
                            thickness={2}
                            hidePercentage
                            colors={[theme.bluePlanetPalette.purple.light, theme.bluePlanetPalette.purple.main]}
                          />
                          {visit.viewedSlideCount} / {views.totalSlideCount}
                        </div>
                      </Table.DataCell>
                      <Table.DataCell style={{ textAlign: 'left' }}>
                        {visit.location ? (
                          formatShortLocation(visit.location)
                        ) : (
                          <span className="text-grey">Unknown</span>
                        )}
                      </Table.DataCell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          );
        }}
      </Resource>
    </div>
  );
}
