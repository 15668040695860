import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';
import { ImpactGoal } from 'types';
import ImpactGoalContainer from './ImpactGoalContainer';

interface Props {
  activeImpactGoals: ImpactGoal[];
  allImpactGoals: ImpactGoal[];
  setActiveImpactGoals: (func: (prevState: ImpactGoal[]) => ImpactGoal[]) => void;
}

export default function ImpactGoals({ activeImpactGoals, allImpactGoals, setActiveImpactGoals }: Props) {
  const activeGoalIds = activeImpactGoals.map(g => g.id);

  function onSelectImpactGoal(goal: ImpactGoal) {
    if (activeGoalIds.includes(goal.id)) {
      setActiveImpactGoals(prevState => [...prevState.filter(g => g.id !== goal.id)]);
    } else {
      setActiveImpactGoals(prevState => [...prevState, goal]);
    }
  }

  return (
    <ImpactGoalContainer>
      {allImpactGoals.map(goal => (
        <img
          key={goal.id}
          className={cx(styles.impactGoal, {
            [styles.activeGoal]: activeGoalIds.includes(goal.id),
          })}
          draggable={false}
          src={goal.imageUrl}
          title={goal.name}
          onClick={() => onSelectImpactGoal(goal)}
        />
      ))}
    </ImpactGoalContainer>
  );
}
