import { Hidden, useMediaQuery } from '@mui/material';
import { Author, AuthorImage } from 'domain/shared/author';
import { FormikProps, withFormik } from 'formik';
import React, { lazy } from 'react';
import Button from 'ui/elements/buttons/Button';
import usePrompt from 'hooks/usePrompt';
import { DocumentList } from 'types/documents';
import Loading from 'ui/elements/Loading';
import i18n from 'util/i18n';

import { CreateRoomUpdateDTO } from '../types';
import { emptyQuillContent, updateValidationSchema } from 'domain/Updates/shared/utils';
import { uploadFile } from 'domain/shared/Files/actions';
import roomUpdateAPI from '../RoomUpdateAPI';
import useFileUpload from 'domain/shared/Files/Upload/useFileUpload';
import Dropzone from 'domain/shared/Files/Upload/Dropzone';
import Dialog, { Content, Title } from 'ui/views/dialogs/Dialog';
import { bluePlanetTheme } from 'ui/theme';
import { roomMentionSearchFunction } from 'ui/elements/form/RichTextEditor/utils';
import AttachmentsCarousel from 'domain/Updates/shared/UpdateForm/AttachmentsCarousel';

const RichTextEditor = lazy(() => import('ui/elements/form/RichTextEditor'));

interface OwnProps {
  roomId: string;
  titleDraft?: string;
  contentDraft?: string;
  bannerImage?: string;
  onSave: (dto: CreateRoomUpdateDTO) => void;
  isSaving: boolean;
  author: Author;
  onClose: () => void;
  dialogTitle: string;
  attachments?: DocumentList;
  saveButtonText?: string;
  contentId?: string;
}

type Props = FormikProps<CreateRoomUpdateDTO> & OwnProps;

function RoomUpdateForm(props: Props) {
  const getImageUploadUrl = (filename: string) => roomUpdateAPI(props.roomId).images.uploadUrl(filename);

  usePrompt(props.dirty, props.isSubmitting);

  const onAttachmentUploaded = (files: { documentId: string; name: string }[]) => {
    const newDocumentIds = files.map(f => f.documentId);
    props.setFieldValue('attachments', [...props.values.attachments, ...newDocumentIds]);
  };

  const onAttachmentRemoved = (documentId: UUID): Promise<void> => {
    return Promise.resolve(
      props.setFieldValue(
        'attachments',
        props.values.attachments.filter(doc => doc !== documentId),
      ),
    ).then(() => {});
  };

  const [newAttachments, onUploadFile, removeFile] = useFileUpload(file => uploadFile('room-updates', file), {
    onSuccess: onAttachmentUploaded,
    deleteFile: onAttachmentRemoved,
  });

  const onClose = () => {
    if (props.dirty && !props.isSubmitting) {
      const confirmed = confirm(i18n('en').common.unsavedChangesPrompt);
      if (confirmed) {
        props.onClose();
      }
    } else {
      props.onClose();
    }
  };

  const api = roomUpdateAPI(props.roomId);

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const mentionSearchFunction = async (searchQuery: string) => roomMentionSearchFunction(searchQuery, props.roomId);

  return (
    <Dialog maxWidth="md" fullScreen={isMobile} verticalAlign="top" open onClose={onClose}>
      <>
        <Title onClose={onClose}>
          <div className="u-flex u-flex-space-between u-flex-align-center" style={{ width: '100%' }}>
            <div className="u-flex u-flex-align-center">
              <AuthorImage className="u-half-spacing-right" author={props.author} />
              {props.dialogTitle}
            </div>
            <Hidden smDown>
              <Button
                onClick={() => props.handleSubmit()}
                kind="primary"
                type="submit"
                data-intercom-target="publish"
                isLoading={props.isSaving}
                className="u-half-spacing-right"
              >
                {props.saveButtonText || 'Publish'}
              </Button>
            </Hidden>
          </div>
        </Title>
        <Content className="u-section-spacing-bottom">
          <React.Suspense fallback={<Loading spacing="content-spacing" position="center" />}>
            <Dropzone disableOnClick onDrop={onUploadFile}>
              <RichTextEditor
                name="content"
                deltaName="structuredContent"
                content={props.values.content}
                delta={props.values.structuredContent || {}}
                setFieldValue={props.setFieldValue}
                setFieldTouched={props.setFieldTouched}
                validateField={props.validateField}
                handleChange={props.handleChange}
                toolbarType="none"
                error={props.touched.content ? props.errors.content : undefined}
                getImageUploadUrl={getImageUploadUrl}
                mentionSearchFunction={mentionSearchFunction}
                onUploadFile={onUploadFile}
                submitButton={
                  <Button
                    onClick={() => props.handleSubmit()}
                    kind="primary"
                    type="submit"
                    data-intercom-target="publish"
                    isLoading={props.isSaving}
                  >
                    {props.saveButtonText || 'Publish'}
                  </Button>
                }
                attachmentSection={
                  <AttachmentsCarousel
                    attachmentIdList={props.values.attachments}
                    existingAttachments={props.attachments?.documents ?? []}
                    newAttachments={newAttachments}
                    downloadAttachment={updateId => api.attachments.download(props.contentId || '', updateId)}
                    removeExistingAttachment={onAttachmentRemoved}
                    removeNewAttachment={removeFile}
                  />
                }
                autoFocus
              />
            </Dropzone>
          </React.Suspense>
        </Content>
      </>
    </Dialog>
  );
}

export default withFormik<OwnProps, CreateRoomUpdateDTO>({
  mapPropsToValues: props => ({
    title: props.titleDraft ?? '',
    bannerImage: props.bannerImage || '',
    content: props.contentDraft ?? emptyQuillContent,
    structuredContent: {} as string,
    attachments: props.attachments?.documents.map(doc => doc.id) || [],
  }),
  handleSubmit: (values, { props }) => {
    const data: CreateRoomUpdateDTO = {
      attachments: values.attachments,
      title: values.title,
      bannerImage: values.bannerImage,
      content: values.content,
      structuredContent: JSON.stringify(values.structuredContent) || '{}',
      linkPreview: values.linkPreview,
    };
    props.onSave(data);
  },
  validationSchema: updateValidationSchema,
  validateOnChange: false,
  validateOnBlur: false,
})(RoomUpdateForm);
