import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useDelayedExecution } from 'hooks/useDelayedExecution';
import { GET_NEW_TOKEN_BEFORE_EXPIRATION_MINUTES } from '.';
import { jwtDecode } from 'jwt-decode';
import { addMinutes, isBefore } from 'date-fns';

// Since we are setting the token in the global axios configuration, we have to set it and renew it explicitly here
// This hook should only be used at one level in the component tree, as multiple instantiating will result in redunant calls to get the token
// Should it be necessary to use it in multiple places, it could be refactored to use a context or similar
export function useConfigureAccessToken() {
  const { getAccessTokenSilently } = useAuth0();
  const [isAccessTokenReady, setIsAccessTokenReady] = useState(false);
  const [getNewTokenAt, setGetNewTokenAt] = useState<Date | undefined>(undefined);
  const [accessTokenLastRefreshed, setAccessTokenLastRefreshed] = useState<Date | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const getNewTokenAt = calculateWhenToGetNewToken(token, GET_NEW_TOKEN_BEFORE_EXPIRATION_MINUTES);
      setGetNewTokenAt(getNewTokenAt);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAccessTokenReady(true);
    })();
  }, []);

  // Refresh the token X minutes before it expires
  useDelayedExecution(() => {
    (async () => {
      // If we refreshed the token less than X minutes ago, could be in an endless loop of getting new tokens, so we return early
      if (
        accessTokenLastRefreshed !== undefined &&
        isBefore(new Date(), addMinutes(accessTokenLastRefreshed, GET_NEW_TOKEN_BEFORE_EXPIRATION_MINUTES))
      ) {
        setGetNewTokenAt(addMinutes(accessTokenLastRefreshed, GET_NEW_TOKEN_BEFORE_EXPIRATION_MINUTES));
        return;
      }

      const token = await getAccessTokenSilently();
      const getNewTokenAt = calculateWhenToGetNewToken(token, GET_NEW_TOKEN_BEFORE_EXPIRATION_MINUTES);
      setAccessTokenLastRefreshed(new Date());
      setGetNewTokenAt(getNewTokenAt);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    })();
  }, getNewTokenAt);

  return { isAccessTokenReady };
}

// Determine when we need to refetch the token
export function calculateWhenToGetNewToken(accessToken: string, bufferMinutes: number) {
  const decodedToken = jwtDecode(accessToken);
  const expirationTimeInEpoch = decodedToken.exp;
  const expirationTime = !isNaN(Number(decodedToken.exp)) ? new Date(Number(expirationTimeInEpoch) * 1000) : undefined;
  const getNewTokenAt = expirationTime ? addMinutes(expirationTime, -bufferMinutes) : undefined;

  // If we didn't get a token (which shouldn't happen) we can't really determine when to get a new one
  if (!getNewTokenAt) return undefined;

  return getNewTokenAt;
}
