import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './styles.scss';

const cache: Record<string, string> = {};

interface Props {
  url: string;

  onClick?: () => void;
  className?: string;
}

export default function Preview(props: Props) {
  const [image, setImage] = useState<string | undefined>(undefined);

  const fetchImage = (url: string) => {
    if (cache[url]) {
      setImage(cache[url]);
      return;
    }
    setImage(undefined);

    return window
      .fetch(`https://noembed.com/embed?url=${url}`)
      .then(response => response.json())
      .then(data => {
        if (data.thumbnail_url) {
          const image = data.thumbnail_url.replace('height=100', 'height=480');
          setImage(image);
          cache[url] = image;
        }
      });
  };

  useEffect(() => {
    fetchImage(props.url);
  }, [props.url]);

  return (
    <div className={classNames(props.className, styles.preview)}>
      <img alt=" " onClick={props.onClick} tabIndex={0} src={image} width="101.33px" height="57px" />
    </div>
  );
}
