import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function RotateLeftIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M5 17v-2c0-5 3.8-9 8.5-9s8.5 4 8.5 9" />
      <path d="m8 15-3 3-3-3" />
    </SvgIcon>
  );
}
