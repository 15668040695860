import config from 'config';

import {
  CommunityNotificationPreferences,
  CommunityUnsubscribeResponse,
  CompanyNotificationPreferences,
  CompanyUnsubscribeResponse,
  NotificationPreferences,
  UnsubscribeResponse,
  UpdateNotificationPreferences,
} from 'apis/NotifyAPI/types';
import { NotificationList } from 'types/notify';

import ApiBase from '../ApiBase';
import { BackofficeMailDTO, BackofficeUpdateStats } from 'types/backoffice';

const API_PATH = config.NOTIFY_API_URL;
const api = ApiBase();

export default {
  backoffice: {
    getMailEvents: (userId: number) =>
      api.get<BackofficeMailDTO[]>(`${API_PATH}/backoffice/mail-events/user/${userId}`),
    deleteUser: (cwUserId: UUID, userId: number, email: string) =>
      api.deleteRequest<void>(`${API_PATH}/backoffice/users/${cwUserId}?userId=${userId}&email=${email}`),
    getUpdateStats: (updateId: UUID) =>
      api.get<BackofficeUpdateStats>(`${API_PATH}/backoffice/update-stats/${updateId}`),
  },
  notifications: {
    get: () => api.get<NotificationList>(`${API_PATH}/notifications`),
    markAllAsSeen: () => api.post(`${API_PATH}/notifications/mark-all-as-seen`, {}),
  },
  preferences: {
    get: () => api.get<NotificationPreferences>(`${API_PATH}/preferences`),
    update: (preferences: UpdateNotificationPreferences) =>
      api.patch<NotificationPreferences>(`${API_PATH}/preferences`, preferences),

    getCompanyPreferences: (companyId: number) =>
      api.get<CompanyNotificationPreferences>(`${API_PATH}/preferences/companies/${companyId}`),
    updateCompanyPreferences: (companyId: number, preferences: CompanyNotificationPreferences) =>
      api.patch<CompanyNotificationPreferences>(`${API_PATH}/preferences/companies/${companyId}`, preferences),
    getCommunityPreferences: (companyId: number) =>
      api.get<CommunityNotificationPreferences>(`${API_PATH}/preferences/communities/${companyId}`),
    updateCommunityPreferences: (companyId: number, preferences: CommunityNotificationPreferences) =>
      api.patch<CommunityNotificationPreferences>(`${API_PATH}/preferences/communities/${companyId}`, preferences),
  },
  emails: {
    preferences: {
      get: (unsubscribeKey: string) => api.get<UnsubscribeResponse>(`${API_PATH}/unsubscribe/${unsubscribeKey}`),
      save: (unsubscribeKey: string, preferences: UpdateNotificationPreferences) =>
        api.post<UnsubscribeResponse>(`${API_PATH}/unsubscribe/${unsubscribeKey}`, { preferences }),

      getCompanyPreferences: (unsubscribeToken: string) =>
        api.get<CompanyUnsubscribeResponse>(`${API_PATH}/unsubscribe/companies/${unsubscribeToken}`),
      updateCompanyPreferences: (unsubscribeToken: string, preferences: CompanyNotificationPreferences) =>
        api.patch<CompanyNotificationPreferences>(`${API_PATH}/unsubscribe/companies/${unsubscribeToken}`, preferences),
      getCommunityPreferences: (unsubscribeToken: string) =>
        api.get<CommunityUnsubscribeResponse>(`${API_PATH}/unsubscribe/communities/${unsubscribeToken}`),
      updateCommunityPreferences: (unsubscribeToken: string, preferences: CommunityNotificationPreferences) =>
        api.patch<CommunityNotificationPreferences>(
          `${API_PATH}/unsubscribe/communities/${unsubscribeToken}`,
          preferences,
        ),
    },
  },
};
