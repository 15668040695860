import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { PaginatedResult, PaginationOptions } from 'types/api';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import { bluePlanetTheme } from 'ui/theme';
import { fontSizeSmall, shape } from 'ui/theme/themeBluePlanet';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import { alpha } from '@mui/material/styles';

interface PaginationProps<T> {
  color?: 'grey' | 'white';
  resource: PaginatedResult<T>;
  setPaginationOptions: (paginationOptions: PaginationOptions) => void;
}

const PaginationContainer = styled.div`
  background-color: ${(props: { color: 'white' | 'grey' }) =>
    props.color === 'grey'
      ? alpha(bluePlanetTheme.bluePlanetPalette.grey.light, 0.8)
      : bluePlanetTheme.bluePlanetPalette.white};
  height: 3.75rem;
  margin-top: ${halfSpacing};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSizeSmall};
  border-top-left-radius: ${shape.borderRadius}px;
  border-bottom-left-radius: ${shape.borderRadius}px;
  border-top-right-radius: ${shape.borderRadius}px;
  border-bottom-right-radius: ${shape.borderRadius}px;
`;

const PaginationButton = styled.button`
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  color: ${alpha(bluePlanetTheme.bluePlanetPalette.indigo.main, 0.6)};
  display: flex;
  align-items: center;
  align-self: stretch;
  text-transform: uppercase;

  :first-of-type {
    padding-left: ${halfSpacing};
    padding-right: ${contentSpacing};
    border-right: 2px solid ${bluePlanetTheme.bluePlanetPalette.grey.light};
  }

  :last-of-type {
    padding-right: ${halfSpacing};
    padding-left: ${contentSpacing};
    border-left: 2px solid ${bluePlanetTheme.bluePlanetPalette.grey.light};
  }

  &:disabled {
    color: ${alpha(bluePlanetTheme.bluePlanetPalette.indigo.main, 0.2)};
  }

  svg {
    height: 15px;
  }
`;

export default function Pagination<T>({ resource, setPaginationOptions, color = 'white' }: PaginationProps<T>) {
  // If a filter has been applied when we are on a page > 1, we want to reset the starting page
  // to 1, to make sure we are not stuck on a higher page than we have values to fill.
  useEffect(() => {
    if (resource.page > resource.pages) {
      setPaginationOptions({ limit: resource.limit, page: 1 });
    }
  }, [resource.page, resource.pages]);

  return (
    <PaginationContainer color={color}>
      <PaginationButton
        disabled={resource.page === 1}
        onClick={() => setPaginationOptions({ limit: resource.limit, page: resource.page - 1 })}
      >
        <ChevronLeftIcon strokeWidth={2.5} />
        Prev
      </PaginationButton>
      <span>
        Page {resource.page} of {resource.pages}
      </span>
      <PaginationButton
        disabled={resource.page >= resource.pages} // Page will be greater than pages when there are no values. Page = 1 and pages = 0.
        onClick={() => setPaginationOptions({ limit: resource.limit, page: resource.page + 1 })}
      >
        Next
        <ChevronRightIcon strokeWidth={2.5} />
      </PaginationButton>
    </PaginationContainer>
  );
}
