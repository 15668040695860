import useSWR from 'hooks/useSWR';
import { PitchDTO } from 'types/company';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

export const pitchKey = (companyId: number) => companiesAPIUrls.pitch.get(companyId);
export function usePitch(companyId: number) {
  const swrResult = useSWR<PitchDTO>(pitchKey(companyId));
  return useSWRResource(swrResult);
}
