import React from 'react';
import { ICompany } from 'types/company';
import useResource from 'util/resource/useResource';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import Card from 'ui/views/cards/Card';
import { PeriodOverPeriod } from './PeriodOverPeriod';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import styled from '@emotion/styled';
import Resources from 'util/resource/Resources';
import { formatThousands } from 'util/numbers';
import { companyUrls } from 'urls';
import { Link } from 'react-router-dom';
import { getDateRange } from 'util/dateUtils';
import { NUMBER_OF_DAYS_IN_INSIGHTS_PERIOD } from './Manage';
import { ProfileEngagement, ProfileInsights } from 'apis/CompanyAPI/insights/types';

interface Props {
  company: ICompany;
}

const Grid = styled.div`
  display: grid;
  gap: ${halfSpacing};
  grid-template-columns: 3fr 1fr 1fr;
  grid-template-areas: 'main sidebar';
  align-items: center;
  margin-bottom: ${contentSpacing};
`;

export default function CompanyInsightsSidebar({ company }: Props) {
  const [startDate, endDate] = getDateRange(new Date(), NUMBER_OF_DAYS_IN_INSIGHTS_PERIOD);

  const { resource: profileVisits } = useResource<ProfileInsights>(
    companiesAPIUrls.insights.profileVisits(company.id, startDate, endDate),
  );

  const { resource: profileEngagement } = useResource<ProfileEngagement>(
    companiesAPIUrls.insights.profileEngagement(company.id, startDate, endDate),
  );

  return (
    <Resources resources={[profileVisits, profileEngagement]}>
      {([profileVisits, profileEngagement]) => (
        <>
          <SectionHeadingLarge heading="Insights" />
          <Card>
            <div className="u-flex u-flex-space-between u-content-spacing-bottom">
              <h4 className="text-medium text-weight-medium text-font-text">Engagement</h4>
              <p className="text-metadata">Last 30 days</p>
            </div>
            <Grid>
              <KeyInsight
                title="Profile visitors"
                value={profileVisits.visitors.value}
                previousValue={profileVisits.visitors.previousValue}
              />
              <KeyInsight
                title="Profile views"
                value={profileVisits.visits.value}
                previousValue={profileVisits.visits.previousValue}
              />
              <KeyInsight
                title="New followers"
                value={profileEngagement.newFollowers.value}
                previousValue={profileEngagement.newFollowers.previousValue}
              />
              <KeyInsight
                title="Pitch deck downloads"
                value={profileEngagement.pitchDownloads.value}
                previousValue={profileEngagement.pitchDownloads.previousValue}
              />
              <KeyInsight
                title="Post engagement"
                value={profileEngagement.postEngagement.value}
                previousValue={profileEngagement.postEngagement.previousValue}
              />
            </Grid>
            <Link className="text-link text-small" to={companyUrls.insights(company.slug)}>
              See all insights
            </Link>
          </Card>
        </>
      )}
    </Resources>
  );
}

function KeyInsight({ title, value, previousValue }: { title: string; value: number; previousValue?: number }) {
  return (
    <>
      <span className="text-font-text text-medium">{title}</span>
      <span className="text-font-text text-medium text-weight-medium text-align-right">{formatThousands(value)}</span>
      <span className="text-tiny">
        <PeriodOverPeriod value={value} previousValue={previousValue} />
      </span>
    </>
  );
}
