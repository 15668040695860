import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function LockIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M17 10.2H7a2 2 0 0 0-2 2v6c0 1 .9 2 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2ZM12 3.8a4 4 0 0 1 4 4v2.4H8V7.8a4 4 0 0 1 4-4Z" />
    </SvgIcon>
  );
}
