import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { invalidateStartsWith } from 'hooks/useSWR';
import { CompanyConversation, conversationApiUrls } from 'apis/ContentAPI/conversationApi';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { bindActionCreators } from 'redux';
import { getUserProfiles } from 'actions/userProfiles';
import { useSWRResource } from 'util/resource/useSWRResource';
import { notEmpty } from 'util/arrayUtils';

export const reloadCompanyInbox = (companyId: number) => {
  invalidateStartsWith([conversationApiUrls.company.list(companyId)]);
};

export function useCompanyInbox(
  companyId: number | undefined,
  paginationOptions: PaginationOptions,
  canReadCompanyInbox: boolean,
) {
  const dispatch = useDispatch();
  const swrResult = useSWR<PaginatedResult<CompanyConversation>>(
    canReadCompanyInbox && companyId ? conversationApiUrls.company.list(companyId, paginationOptions) : undefined,
  );
  const actions = bindActionCreators(
    {
      getUserProfiles,
    },
    dispatch,
  );

  const swrResource = useSWRResource(swrResult);

  useEffect(() => {
    if (swrResource.resource.state === 'fetched') {
      actions.getUserProfiles(
        swrResource.resource.resource.values.map(c => c.companyConversation?.cwUserId).filter(notEmpty),
      );
    }
  }, [swrResource.resource.state, swrResource.resource.state === 'fetched' && swrResource.resource.resource.values]);
  return swrResource;
}
