import styled from '@emotion/styled';
import React, { ReactElement, ReactNode, useContext, useMemo } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import FontRedHat from 'ui/theme/FontRedHat';
import { fontSizeMedium, shape } from 'ui/theme/themeBluePlanet';
import SortButton from './SortButton';
import { SortDirectionOrder } from './useTableWithSort';
import { TableContext } from './Table';
import { alpha } from '@mui/material/styles';
import { contentSpacing, halfSpacing, quarterSpacing } from 'ui/theme/themeConstants';
import classNames from 'classnames';

interface HeaderProps extends React.HTMLAttributes<HTMLTableSectionElement> {
  menu?: JSX.Element;
  height?: number;
}

const StyledHeaderCell = styled.th(
  (props: { tableColor?: 'white' | 'grey' }) => `
  white-space: nowrap;
  background-color: ${
    props.tableColor === 'grey'
      ? alpha(bluePlanetTheme.bluePlanetPalette.grey.light, 0.8)
      : bluePlanetTheme.bluePlanetPalette.white
  };
  font-family: ${FontRedHat.display};
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  font-size: ${fontSizeMedium};
  color: ${alpha(bluePlanetTheme.bluePlanetPalette.indigo.main, 0.6)};
  padding: ${quarterSpacing} ${halfSpacing};
  border-bottom: 4px solid ${
    props.tableColor === 'grey' ? bluePlanetTheme.bluePlanetPalette.white : bluePlanetTheme.bluePlanetPalette.grey.light
  };

  &:first-of-type {
    border-top-left-radius: ${shape.borderRadius}px;
    border-bottom-left-radius: ${shape.borderRadius}px;
    padding-left: ${contentSpacing};

    ${bluePlanetTheme.breakpoints.down('md')} {
      padding-left: ${halfSpacing};
    }
  }

  &:last-of-type {
    border-top-right-radius: ${shape.borderRadius}px;
    border-bottom-right-radius: ${shape.borderRadius}px;
  }
`,
);

export const Header = ({ height, menu, children, ...rest }: HeaderProps) => {
  const context = useContext(TableContext);

  if (!context) {
    throw Error('Table.Header: Component must be wrapped in <Table> to access TableContext.');
  }

  const { hasMenu, hasClickableRows, columnClassNames } = context;

  const memoizedChildren = useMemo(() => {
    if (columnClassNames != null) {
      return React.Children.map(children, (child: ReactElement | null, index) =>
        child != null
          ? React.cloneElement(child, {
              ...child.props,
              className: classNames(child.props.className, columnClassNames[index]),
            })
          : null,
      );
    }
    return children;
  }, [children, columnClassNames]);

  return (
    <thead {...rest}>
      <tr style={{ height: height ?? 40 }}>
        {memoizedChildren}
        {hasMenu && <StyledHeaderCell as="th">{!hasClickableRows && menu}</StyledHeaderCell>}
        {hasClickableRows && <StyledHeaderCell as="th">{menu}</StyledHeaderCell>}
      </tr>
    </thead>
  );
};

type HeaderCellProps = {
  isSortable?: boolean;
  sortBy?: string;
  sortOrder?: SortDirectionOrder;
  filter?: ReactNode;
} & React.ThHTMLAttributes<HTMLTableCellElement>;

export const HeaderCell = ({ isSortable, sortBy, sortOrder, filter, children, ...rest }: HeaderCellProps) => {
  const context = useContext(TableContext);

  if (!context) {
    throw Error('Table.HeaderCell: Component must be wrapped in <Table> to access TableContext.');
  }

  if (isSortable) {
    if (!sortBy) {
      throw Error(
        'Table.HeaderCell: HeaderCell.isSortable is set to true, but sortBy is not defined in Table Context.',
      );
    }
    if (!context.onSort) {
      throw Error(
        'Table.HeaderCell: HeaderCell.isSortable is set to true, but onSort is not defined in Table Context.',
      );
    }
  }

  const { sortOptions, onSort } = context;

  return (
    <StyledHeaderCell tableColor={context.tableColor} {...rest}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {isSortable ? (
          <SortButton
            sortDirection={sortOptions?.sortBy === sortBy ? sortOptions?.sortOrder : undefined}
            onClick={() => onSort && onSort(sortBy!, sortOrder)}
          >
            {children}
          </SortButton>
        ) : (
          children
        )}
        {filter && <span className="u-half-spacing-left">{filter}</span>}
      </span>
    </StyledHeaderCell>
  );
};
