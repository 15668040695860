import { RootStore } from 'globalstate/rootStore';
import partial from 'ramda/src/partial';
import { useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { IResource } from 'util/resource';

type UseSharedPaginatedResource<T> = [
  IResource<PaginatedResult<T>>,
  (paginationOptions?: PaginationOptions) => void,
  (paginationOptions?: PaginationOptions) => void,
  () => void,
];

export default function useSharedPaginatedResource<T>(
  selectResource: (store: RootStore) => IResource<PaginatedResult<T>>,
  fetch: (type: 'initial' | 'page', paginationOptions?: PaginationOptions) => (dispatch: Dispatch) => void,
  deps: any[],
): UseSharedPaginatedResource<T> {
  const resource = useSelector(selectResource);
  const dispatch = useDispatch();
  const store = useStore();
  const fetchResource = (type: 'initial' | 'page', paginationOptions?: PaginationOptions) => {
    fetch(type, paginationOptions)(dispatch);
  };

  useEffect(() => {
    /*
    Note: reselecting here to retreive correct loading state. This prevents different components
    from calling the same endpoint on load if it isn't fetched already, since they all would see
    resource.state as .Idle otherwise. Also we can't use useSelector inside useEffect, so we have to 
    use the store directly and reselect.
    */

    const resource = selectResource(store.getState());
    if (resource.state !== 'fetching') {
      fetchResource('initial');
    }
  }, deps);

  const reload = () => {
    if (resource.state === 'fetched') {
      fetch('initial', { page: 1, limit: resource.resource.limit })(dispatch);
      // her må vi ha noe logikk for å unngå at page og limit blir oppdatert i reduceren.
      // fetch(false, { page: 1, limit: resource.resource.values.length })(dispatch);
    }
  };

  return [resource, partial(fetchResource, ['initial']), partial(fetchResource, ['page']), reload];
}
