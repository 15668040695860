import ApiBase from 'apis';
import config from 'config';
import { EventDTO } from 'ui/domain/Event/types';

const baseUrl = config.COMPANIES_API_URL;
const api = ApiBase();

export default {
  get: (id: UUID) => api.get<EventDTO>(`${baseUrl}/events/${id}`),
  getUrl: (id: UUID) => `${baseUrl}/events/${id}`,
};
