import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function UpdateIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18 15V9c0-2.8 0-4.2-.9-5.1C16.2 3 14.8 3 12 3H8c-2.8 0-4.2 0-5.1.9C2 4.8 2 6.2 2 9v6c0 2.8 0 4.2.9 5.1.9.9 2.3.9 5.1.9h12M6 8h8M6 12h8M6 16h4" />
      <path d="M18 8h1c1.4 0 2.1 0 2.6.4.4.5.4 1.2.4 2.6v8a2 2 0 1 1-4 0V8Z" />
    </SvgIcon>
  );
}
