import { CompactOrganization } from 'types/organization';
import Banner from 'domain/shared/Banner';
import React from 'react';
import CardStack from 'ui/views/cards/CardStack';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import Logo from 'ui/domain/Logo';
import Card from 'ui/views/cards/Card';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import ChipList from 'ui/elements/Chip/ChipList';
import TruncatedList from 'util/components/TruncatedList';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import HorizontalDictionary from 'ui/elements/dictionary/HorizontalDictionary';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import LockIcon from 'ui/elements/icons/LockIcon';
import useNotify from 'hooks/useNotify';
import { organizationUrls } from 'urls';
import PopMenu, { PopMenuItem } from 'ui/modules/PopMenu';
import LinkedInIcon from 'ui/elements/icons/LinkedInIcon';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import ShareIcon from 'ui/elements/icons/ShareIcon';
import { notEmpty } from 'util/arrayUtils';
import { ActionCardBody, Body } from 'ui/layout/PublicPage';
import { useLoginWithRedirect } from 'auth/useLoginWithRedirect';

function ShareButton({ organization }: { organization: CompactOrganization; disabled?: boolean }) {
  const notify = useNotify();
  const href = `${location.origin}${organizationUrls.view(organization.slug)}`;
  const onCopy = () => {
    if (typeof window !== 'undefined') {
      window.navigator.clipboard.writeText(`${href}`);
      notify('success', 'Link copied');
    }
  };

  const linkedinShare = () => {
    window.open(
      `http://www.linkedin.com/shareArticle?mini=true&url=${href}?utm_source=linkedin&utm_medium=share-link&utm_campaign=company&utm_term=public-profile&title=${organization.name} - CrowdWorks`,
      '',
      '_blank, width=500, height=600, resizable=yes, scrollbars=yes',
    );
    return false;
  };
  const menuItems: PopMenuItem[] = [
    {
      icon: <LinkedInIcon />,
      text: <>Share on LinkedIn</>,
      onClick: linkedinShare,
    },
    {
      icon: <LinkIcon />,
      text: <>Copy link to profile</>,
      onClick: onCopy,
    },
    navigator.share
      ? {
          icon: <ShareIcon />,
          text: <>More options</>,
          onClick: () => {
            navigator.share({
              title: organization.name,
              text: `Check out ${organization.name} on CrowdWorks!`,
              url: `${href}?utm_source=direct&utm_medium=native-share&utm_campaign=company&utm_term=public-profile`,
            });
          },
        }
      : undefined,
  ].filter(notEmpty);

  return (
    <>
      <PopMenu
        items={menuItems}
        renderAnchor={onClick => (
          <Button onClick={onClick} kind="tertiary" color="grey">
            <ShareIcon className="u-quarter-spacing-right" />
            Share
          </Button>
        )}
      />
    </>
  );
}

export const PublicOrganization = ({ organization }: { organization: CompactOrganization }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { loginWithRedirect } = useLoginWithRedirect();

  return (
    <div>
      <Banner imageURL={organization.bannerUrl} />
      <div style={{ position: 'relative' }}>
        <Body>
          <CardStack>
            <Card padding={isSmallScreen ? 'default' : 'double'} elevation={1}>
              <div className={'u-flex u-flex-space-between'}>
                <div className="u-flex u-flex-align-center">
                  <Logo size={45} className="u-content-spacing-right" type="company" url={organization.logoUrl} />
                  <div>
                    <h1 className="text-h4" style={{ lineHeight: '1.1' }}>
                      {organization.name}
                    </h1>
                  </div>
                </div>
                <ButtonList>
                  <ShareButton organization={organization} />
                </ButtonList>
              </div>
              <div className="u-content-spacing-top" style={{ minHeight: 29 }}>
                <ChipList>
                  <TruncatedList
                    values={organization.industries.map(i => ({ name: i }))}
                    limit={isSmallScreen ? 2 : 5}
                    renderItem={industry => <IndustryChip label={industry.name} />}
                    renderExpandButton={(industry, onClick) => <IndustryChip label={industry.name} onClick={onClick} />}
                  />
                </ChipList>
              </div>
              <HorizontalDictionary columnCount={3} className="u-content-spacing-top">
                {[
                  {
                    key: 'Type',
                    value: organization.types.join(', ') || '-',
                  },
                  {
                    key: 'Established',
                    value: organization.established || '-',
                  },
                  {
                    key: 'Location',
                    value: organization.location ? organization.location : '-',
                  },
                ]}
              </HorizontalDictionary>
            </Card>
          </CardStack>
          <Card padding={isSmallScreen ? 'default' : 'double'} className="u-half-spacing-top" color="indigo">
            <ActionCardBody>
              <div className="u-flex">
                <IconAvatar color="white" className="u-content-spacing-right">
                  <LockIcon />
                </IconAvatar>
                <p>
                  Create an account to see more details and get updates on events, programs and insights shared by{' '}
                  {organization.name}
                </p>
              </div>
              <ButtonList>
                <Button
                  onClick={() => {
                    return loginWithRedirect('login', { organization: organization.slug });
                  }}
                  kind="tertiary"
                  color="greyLight"
                >
                  Log in
                </Button>
                <Button
                  onClick={() => {
                    return loginWithRedirect('signup', { organization: organization.slug });
                  }}
                  style={{ whiteSpace: 'nowrap' }}
                  kind="primary"
                >
                  Sign up
                </Button>
              </ButtonList>
            </ActionCardBody>
          </Card>
        </Body>
      </div>
    </div>
  );
};
