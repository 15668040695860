import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function HamburgerIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M4 6h16M4 12h16M4 18h16" />
    </SvgIcon>
  );
}
