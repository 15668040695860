import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function SettingsIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m21.3 7.1-.5-.8c-.3-.7-.5-1-.9-1.1-.3-.1-.6 0-1.3.2l-1.3.3c-.4.1-1 0-1.3-.2l-.4-.2a2 2 0 0 1-.8-1l-.3-1c-.2-.6-.3-1-.6-1.1-.2-.2-.6-.2-1.3-.2h-1c-.8 0-1.1 0-1.4.2-.3.2-.4.5-.6 1.2l-.3 1a2 2 0 0 1-.8 1l-.3.1a2 2 0 0 1-1.4.2l-1.2-.3c-.7-.2-1.1-.3-1.4-.2-.3.1-.5.4-.9 1l-.5 1c-.3.5-.5.9-.5 1.2 0 .3.3.6.8 1l1 1.2a2.4 2.4 0 0 1 0 2.8l-1 1.1-.8 1.1c0 .4.2.7.5 1.3l.5.8c.4.7.6 1 .9 1.1.3.2.7 0 1.4-.1l1.2-.4c.5 0 1 0 1.4.2l.3.2c.3.2.6.5.8 1l.3 1c.2.6.3 1 .6 1.1.3.2.6.2 1.3.2h1.1c.7 0 1 0 1.3-.2.3-.2.4-.5.6-1.2l.3-1 .8-1 .4-.1a2 2 0 0 1 1.3-.2l1.3.4c.7.2 1 .3 1.3.1.4-.1.6-.4 1-1l.4-1c.4-.5.5-.8.5-1.2 0-.3-.3-.5-.7-1l-1-1.2a2.4 2.4 0 0 1 0-2.8l1-1.1c.4-.5.7-.8.7-1.1 0-.3-.1-.7-.5-1.3Z" />
      <path d="M15.5 12a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" />
    </SvgIcon>
  );
}
