import useSWR from 'hooks/useSWR';
import { SelfUserProfile } from 'types/user';
import { useSWRResource } from 'util/resource/useSWRResource';
import { userAPIUrls } from './usersApi';

export const selfDetailedKey = userAPIUrls.self.detailed;

export const useSelfUserProfile = () => {
  const swrResult = useSWR<SelfUserProfile>(selfDetailedKey);

  return useSWRResource(swrResult);
};
