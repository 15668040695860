import React, { Fragment, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Title } from 'ui/views/dialogs/Dialog';
import { SelfUserProfile, UserProfile } from 'types/user';
import { IResource } from 'util/resource';
import useDialogHandler from 'hooks/useDialogHandler';
import SeeMore from 'ui/modules/Pagination/SeeMore';
import ScrollIntoView from 'util/components/ScrollIntoView';
import MessageCard from 'ui/domain/MessageCard/MessageCard';
import LoadableResource from 'util/resource/Resource';
import { useMediaQuery, useTheme } from '@mui/material';
import styles from './styles.scss';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { Message } from './types';
import EventCard from './EventCard';
import { messageListToBlocks } from '../utils';
import conversationApi, { Message as MessageDTO } from 'apis/ContentAPI/conversationApi';
import { ConversationSidebar } from './ConversationSidebar';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import { downloadAttachment } from '../actions';
import { AttachmentListDTO } from 'apis/ContentAPI/conversationTypes';
import useWindowHeight from 'hooks/useWindowHeight';
import ChatDialog from './ChatDialog';
import isSameDay from 'date-fns/isSameDay';
import MessageRequest, { MessageRequestStrip } from './MessageRequest';

interface Props {
  conversationId?: UUID;
  conversationName?: string;
  introduction?: React.ReactNode;
  enableConversationNameChange?: boolean;
  setConversationName?: (name: string) => void;
  header: JSX.Element;
  showSidebar?: boolean;
  setShowSidebar?: (v: boolean) => any;
  activeMembers?: UserProfile[];
  pendingMembers?: UserProfile[];
  conversation: IResource<PaginatedResult<Message>>;
  attachments?: IResource<AttachmentListDTO>;
  userProfiles: UserProfile[];
  onClose: () => void;
  onNavigate?: () => void;
  seeMore: (paginationOptions: PaginationOptions) => Promise<void>;
  user: SelfUserProfile;
  addMessage: (message: MessageDTO) => void;
  children: JSX.Element;
  setEditingMemberList?: () => void;
  showApproval?: Boolean;
  onApproveRequest?: () => Promise<void>;
  onRejectRequest?: () => Promise<void>;
}

export default function Messages(props: Props) {
  const theme = useTheme();
  const windowHeight = useWindowHeight();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md')) || windowHeight < 840;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { header, conversation, conversationId, seeMore, children } = props;
  const editNameDialogHandler = useDialogHandler();

  const [userDialogId, setUserDialogId] = useState('');
  const onClickAuthor = (authorId: string) => setUserDialogId(authorId);
  const onClickAttachment = (fileId: string) => downloadAttachment(fileId);
  const contentRef = useRef<HTMLDivElement>(null);
  const messageRequestRef = useRef<HTMLDivElement>(null);
  const [isMessageRequestOutOfBounds, setMessageRequestOutOfBounds] = useState(false);

  const onEditName = conversationId
    ? async (name?: string) => {
        const result = await conversationApi.user.renameConversation(conversationId, name);
        editNameDialogHandler.close();
        props.addMessage(result);
      }
    : () => Promise.resolve();

  const closeSidebar = () => {
    props.setShowSidebar && props.setShowSidebar(false);
  };

  function renderMessages(messages: Message[], page: number, handleScrollToBottom: () => void) {
    if (messages.length) {
      const messageBlockList = messageListToBlocks(props.showApproval ? [...messages.slice(1)] : [...messages]);
      const messageCards = messageBlockList.map((messageBlock, i) => {
        const isSelf = messageBlock[0].creatorCwUserId === props.user.cwUserId;
        const author = isSelf
          ? props.user
          : props.userProfiles.find(cu => cu.cwUserId === messageBlock[0].creatorCwUserId);
        return (
          <Fragment key={`message-${i}`}>
            {messageBlock[0].type === 'message' ? (
              <MessageCard
                onAuthorClick={onClickAuthor}
                onAttachmentClick={onClickAttachment}
                padding="end"
                messages={messageBlock}
                author={author}
                isSelfAuthored={isSelf}
                handleScrollToBottom={handleScrollToBottom}
                onClose={props.onClose}
                onNavigate={props.onNavigate}
              />
            ) : (
              <EventCard user={props.user} message={messageBlock[0]} author={author} />
            )}
            {/* Scroll to the bottom of the chat if there is no approval shown */}
            {i === messageBlockList.length - 1 && page === 1 && !props.showApproval && <ScrollIntoView />}
          </Fragment>
        );
      });
      const todayIndex = messageBlockList.findIndex(messageBlock =>
        isSameDay(new Date(messageBlock[0].createdAt), new Date()),
      );
      if (todayIndex > 0) {
        messageCards.splice(
          todayIndex,
          0,
          <div key={'divider'} className="u-flex">
            <span className={styles.todayDividerLine} />
            <span className={styles.todayDividerText}>today</span>
            <span className={styles.todayDividerLine} />
          </div>,
        );
      }
      return messageCards;
    } else {
      return null;
    }
  }

  function isLastPageVisible(conversation: PaginatedResult<Message>) {
    return Math.floor((conversation.total - 1) / conversation.pageSize) < conversation.page;
  }

  useEffect(() => {
    // On scroll, check if the user has scrolled past the message request box (if it exists)
    function shouldRenderRequestStrip() {
      if (messageRequestRef.current && contentRef.current) {
        const requestElem = messageRequestRef.current.getBoundingClientRect();
        const contentElem = contentRef.current.getBoundingClientRect();
        if (requestElem.bottom <= contentElem.top) {
          setMessageRequestOutOfBounds(true);
        } else if (requestElem.bottom > contentElem.top + 60) {
          setMessageRequestOutOfBounds(false);
        }
      } else {
        setMessageRequestOutOfBounds(false);
      }
    }
    contentRef.current?.addEventListener('scroll', shouldRenderRequestStrip);
    return () => {
      contentRef.current?.removeEventListener('scroll', shouldRenderRequestStrip);
    };
  }, [messageRequestRef.current, contentRef.current]);

  return (
    <div>
      <ChatDialog isOpen={true} onClose={props.onClose} isFullScreen={fullScreen}>
        {close => (
          <>
            <div
              className={cx(styles.messagesContainer, {
                [styles.containerWithSidebar]: props.showSidebar,
                [styles.large]: windowHeight < 1080 && !isMobile,
                [styles.fullScreen]: fullScreen && !isMobile,
              })}
            >
              <Title className={styles.dialogTitle} onClose={!props.showSidebar ? close : undefined}>
                {header}
              </Title>
              <div ref={contentRef} id="conversation-message-list" className={styles.content}>
                <LoadableResource resource={conversation}>
                  {conversation => {
                    return (
                      <>
                        {props.showApproval &&
                          conversation.values.length > 1 &&
                          (isMessageRequestOutOfBounds || !isLastPageVisible(conversation)) && (
                            <MessageRequestStrip
                              onAccept={props.onApproveRequest ? props.onApproveRequest : () => Promise.resolve()}
                              onDecline={props.onRejectRequest ? props.onRejectRequest : () => Promise.resolve()}
                            />
                          )}
                        <div
                          className={cx(styles.messages, {
                            [styles.hideMessages]: isMobile && props.showSidebar,
                          })}
                        >
                          <SeeMore
                            className="u-flex-center"
                            resource={conversation}
                            loadResource={seeMore}
                            limit={10}
                          />
                          {props.showApproval && conversation.values.length && isLastPageVisible(conversation) && (
                            <div ref={messageRequestRef}>
                              <MessageRequest
                                message={conversation.values[0]}
                                onAccept={props.onApproveRequest ? props.onApproveRequest : () => Promise.resolve()}
                                onDecline={props.onRejectRequest ? props.onRejectRequest : () => Promise.resolve()}
                                onAuthorClick={(authorId: string) => setUserDialogId(authorId)}
                                author={props.userProfiles.find(
                                  cu => cu.cwUserId === conversation.values[0].creatorCwUserId,
                                )}
                              />
                            </div>
                          )}
                          {renderMessages(conversation.values, conversation.page, () => {
                            if (contentRef?.current) {
                              contentRef.current.scrollTop = contentRef.current.scrollHeight;
                            }
                          })}
                          {conversation.values.length === 0 && props.introduction}
                        </div>
                      </>
                    );
                  }}
                </LoadableResource>
              </div>
              <div className={!isMobile ? 'u-content-padding' : ''}>
                <div className={styles.textField}>{children}</div>
              </div>
            </div>
            <div
              className={cx(styles.sidebarContainer, {
                [styles.sidebarContainerOpen]: props.showSidebar,
              })}
            >
              <ConversationSidebar
                conversationId={props.conversationId}
                onClose={close}
                dialogHandler={editNameDialogHandler}
                onEdit={onEditName}
                conversationName={props.conversationName}
                setConversationName={props.setConversationName}
                activeMembers={props.activeMembers}
                pendingMembers={props.pendingMembers}
                fullScreen={fullScreen}
                showSidebar={props.showSidebar || false}
                closeSidebar={closeSidebar}
                attachments={!props.showApproval ? props.attachments : undefined}
                enableConversationNameChange={props.enableConversationNameChange}
                setEditingMemberList={!props.showApproval ? props.setEditingMemberList : undefined}
              />
            </div>
          </>
        )}
      </ChatDialog>
      {userDialogId && <UserProfileDialog cwUserId={userDialogId} onClose={() => setUserDialogId('')} />}
    </div>
  );
}
