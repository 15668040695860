import React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import cx from 'classnames';
import styles from './styles.scss';

import { DOCUMENT_UPLOAD_LIMIT, SUPPORTED_DOCUMENT_FILE_TYPES_BY_MIME_TYPE } from 'util/constants';

interface Props {
  disableOnClick?: boolean;
  children: React.ReactElement;
  onDrop: (accepted: File[], rejected: FileRejection[]) => void;
  tabIndex?: number;
  style?: React.CSSProperties;
}

export default function DocumentDropzone(props: Props) {
  const { children, onDrop } = props;
  return (
    <Dropzone
      noClick={props.disableOnClick}
      multiple
      accept={SUPPORTED_DOCUMENT_FILE_TYPES_BY_MIME_TYPE}
      maxSize={DOCUMENT_UPLOAD_LIMIT.bytes}
      onDrop={onDrop}
      useFsAccessApi={false} // this is a hack to chrome to upload the correct file types. See https://github.com/react-dropzone/react-dropzone/issues/1191
    >
      {state => (
        <div
          className={cx(styles.container, {
            [styles.clickable]: !props.disableOnClick,
          })}
          style={{ backgroundImage: '', ...props.style }}
          {...state.getRootProps()}
          tabIndex={props.tabIndex}
        >
          <input {...state.getInputProps()} />
          {children}
        </div>
      )}
    </Dropzone>
  );
}
