import SvgIcon, { IconProps } from '../SvgIcon';
import React from 'react';

export default function PptIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3.5 13v-.8c0-3 0-4.5.5-5.6.7-2 2.3-3.4 4.3-4.2 1.3-.4 2.9-.4 6-.4 1.8 0 2.7 0 3.4.3 1.2.4 2.1 1.2 2.5 2.3.3.7.3 1.5.3 3.2V13" />
      <path d="M3.5 12c0-1.8 1.5-3.3 3.3-3.3H9c.6-.2 1-.7 1.2-1.3.2-.6 0-1.4 0-2 0-2 1.6-3.4 3.4-3.4" />
      <path d="M4.3 21.6v-2.4m0 0v-2.4c0-1 0-1.2 1-1.2h1.5c.8 0 1.5.8 1.5 1.8s-.7 1.8-1.5 1.8H4.3ZM10.8 21.6v-2.4m0 0v-2.4c0-1 0-1.2 1-1.2h1.4c.8 0 1.5.8 1.5 1.8s-.7 1.8-1.5 1.8h-2.4ZM18.6 21.7v-5.8" />
      <path d="M20.5 15.7h-3.8" />
    </SvgIcon>
  );
}
