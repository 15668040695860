import Dialog, { DialogActions, Title } from 'ui/views/dialogs/Dialog';
import React from 'react';
import { Organization, OrganizationUser } from 'types/organization';
import Button from 'ui/elements/buttons/Button';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';
import useNotify from 'hooks/useNotify';

interface RemoveUserDialogProps {
  onClose: () => void;
  userToRemove: OrganizationUser;
  organizationUsers: OrganizationUser[];
  organization: Organization;
}

export default function RemoveUserDialog(props: RemoveUserDialogProps) {
  const notify = useNotify();
  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(props.organization, {
    onSuccess: () => {
      notify('success', 'User removed');
    },
  });

  return (
    <Dialog open={true} onClose={props.onClose}>
      <Title style={{ paddingBottom: 0 }} onClose={props.onClose}>
        Remove {props.userToRemove.user.name} from {props.organization.name}?
      </Title>
      <DialogActions>
        <Button
          kind={'primary'}
          color={'red'}
          isLoading={isUpdatingOrganization}
          disabled={isUpdatingOrganization}
          onClick={() => {
            const updatedUsers = props.organizationUsers.filter(
              user => user.user.cwUserId !== props.userToRemove.user.cwUserId,
            );

            if (!updatedUsers.some(user => user.isAdmin)) {
              notify('error', 'At least one user must be admin');
              return;
            }

            updateOrganization({
              users: updatedUsers.map(user => {
                return {
                  id: user.user.cwUserId,
                  isAdmin: user.isAdmin,
                  displayAs: user.displayAs,
                };
              }),
            });

            props.onClose();
          }}
        >
          Remove
        </Button>
        <Button kind={'tertiary'} onClick={props.onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
