import React from 'react';
import styles from './styles.scss';
import FileIcon from 'domain/shared/Files/File/FileIcon';
import { CSSProperties } from 'react';
import Loading from 'ui/elements/Loading';
import cx from 'classnames';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { splitFilenameExtension } from 'util/stringUtils';
import DownloadIcon from 'ui/elements/icons/DownloadIcon';
import useDownloadWarning from 'domain/documents/useDownloadWarning';

interface Props {
  name: string;
  onDownload: () => Promise<void>;
  className?: string;
  style?: CSSProperties;
  hasBorder?: boolean;
  backgroundColor?: 'white' | 'transparent';
  fullWidth?: boolean;
}

export default function DownloadableFile(props: Props) {
  const { name } = props;
  const { extension } = splitFilenameExtension(name);

  const notify = useNotify();
  const [onDownload, isDownloading] = useLazyResource(props.onDownload, {
    onFailure: message => notify('error', `Something went wrong while trying to download ${name}: ${message}`),
  });

  const { onClick, WarningDialog } = useDownloadWarning(name, () => onDownload(''));

  return (
    <>
      <button
        className={cx(
          styles.container,
          styles.nameAndIcon,
          styles.downloadable,
          {
            [styles.whiteBackground]: props.backgroundColor === 'white',
            [styles.fullWidth]: props.fullWidth,
            [styles.border]: props.hasBorder,
          },
          props.className,
        )}
        style={props.style}
        onClick={onClick}
      >
        <span className={`${styles.icon} u-half-spacing-right`}>{<FileIcon name={name} />}</span>
        <div className={styles.nameAndExtension}>
          <span className="u-ellipsis text-weight-medium">{name}</span>
          <span className="text-metadata">{extension?.toUpperCase()}</span>
        </div>
        <span className={`${styles.icon} u-margin-left-auto`}>
          {isDownloading ? <Loading size={20}></Loading> : <DownloadIcon />}
        </span>
      </button>

      {WarningDialog}
    </>
  );
}
