import React, { CSSProperties } from 'react';
import cx from 'classnames';
import styles from './styles.scss';

type PillColor = 'blue' | 'purple' | 'yellow' | 'green' | 'red';

interface Props {
  color?: PillColor;
  style?: CSSProperties;
}

export default function Pill(props: Props) {
  return (
    <span
      className={cx(styles.pill, {
        [styles.blue]: !props.color || props.color === 'blue',
        [styles.yellow]: props.color === 'yellow',
        [styles.green]: props.color === 'green',
        [styles.purple]: props.color === 'purple',
        [styles.red]: props.color === 'red',
      })}
      style={props.style ?? undefined}
    />
  );
}
