import useContainerWidth from 'hooks/useContainerWidth';
import React, { useRef } from 'react';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import Card from 'ui/views/cards/Card';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { fontSizeMedium, typography } from 'ui/theme/themeBluePlanet';

interface Props {
  color?: 'white' | 'indigo' | { background: string; text?: string };
  topElement: React.ReactNode;
  children: React.ReactNode;
}

function getHexValues(color: 'white' | 'indigo' | { background: string; text?: string } | undefined) {
  if (typeof color === 'object' && 'background' in color) {
    return { background: color.background, text: color.text };
  } else {
    return { background: undefined, color: undefined };
  }
}

const Container = styled.div(
  ({ layout }: { layout: 'row' | 'column' }) => `
      height: 100%;
      display: flex;
      flex: 1;
      flex-direction: ${layout};
      align-items: center;
      ${layout === 'column' && 'justify-content: space-between;'}
      gap: ${layout === 'column' ? halfSpacing : contentSpacing};
`,
);

const Content = styled.div(
  ({ layout, textColor }: { layout: 'row' | 'column'; textColor?: string }) => css`
    display: flex;
    flex-direction: column;
    gap: ${halfSpacing};
    justify-content: space-evenly;
    align-items: ${layout === 'column' ? 'center' : 'flex-start'};
    height: 100%;
    font-size: ${fontSizeMedium};
    color: ${textColor};

    & > * {
      text-align: ${layout === 'column' ? 'center' : 'left'};
    }
  `,
);

const TopElement = styled.div(
  ({ layout }: { layout: 'row' | 'column' }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.125rem;
    font-weight: ${typography.fontWeightBold};
    ${layout === 'row' &&
    css`
      margin-right: ${contentSpacing};
      width: 60px;
      height: 60px;
    `}
    ${layout === 'column' &&
    css`
      min-height: 100px;
    `}
  `,
);

export default function DashboardMessage({ topElement, children, color }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const width = useContainerWidth(containerRef, []) ?? 0;

  const layout = width > 290 ? 'row' : 'column';

  const { background, text } = getHexValues(color);
  const typedColor = color === 'indigo' ? color : color === 'white' ? color : undefined;

  return (
    <Card className="u-flex-align-center" color={typedColor} style={{ width: '100%', backgroundColor: background }}>
      <Container layout={layout} ref={containerRef}>
        <TopElement layout={layout}>{topElement}</TopElement>
        <Content textColor={text} layout={layout}>
          {children}
        </Content>
      </Container>
    </Card>
  );
}
