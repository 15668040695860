import { Quill } from 'react-quill';
const BlockBlot = Quill.import('blots/block');

// overrides internal block blot
// and adds a class to every parapgrah.
// Legacy richtext has a content-spacing below each paragraph.
// We have removed it for new paragraphs, with the custom classname (to prevent breaking old richtext content)

class CustomBlockBlot extends BlockBlot {
  static create(value: any) {
    const node = super.create(value);
    node.setAttribute('class', 'ql-custom-paragraph');
    return node;
  }
}
Quill.register('formats/block', CustomBlockBlot);
