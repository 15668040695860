import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function BookmarkIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M11 2C7.2 2 5.3 2 4.2 3.1 3 4.3 3 6.1 3 9.7V18c0 2.3 0 3.4.8 3.9 1.5.8 4.3-2 5.6-2.7.8-.5 1.2-.7 1.6-.7.4 0 .8.2 1.6.7 1.3.8 4.1 3.5 5.6 2.7.8-.5.8-1.6.8-4V13" />
      <path d="M17 10V2m-4 4h8" />
    </SvgIcon>
  );
}
