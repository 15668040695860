import React from 'react';

import { Goal } from 'types/goals';
import ProgressBar from 'ui/elements/Progress/ProgressBar';
import { getDifferenceInDays } from 'util/dateUtils';

interface GoalProgressBarProps {
  goal: Goal;
}

export default function GoalProgressBar(props: GoalProgressBarProps) {
  const { state, confidence, createdAt, deadline } = props.goal;

  function getColor() {
    switch (confidence) {
      case 'high':
        return 'green';
      case 'low':
        return 'red';
      default:
        return 'yellow';
    }
  }

  function calculateValue() {
    const totalDays = getDifferenceInDays(new Date(createdAt), new Date(deadline));
    const elapsedDays = getDifferenceInDays(new Date(createdAt), new Date());
    return Math.min(100, Math.round((elapsedDays * 100) / totalDays));
  }

  const value = state === 'completed' ? 100 : calculateValue();

  return <ProgressBar percentage={value} color={getColor()} />;
}
