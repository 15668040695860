import { CompanyListItem, CompanyReachDTO } from 'types/company';
import { useCompanyOrCommunityDashboardData } from 'pages/Dashboard/useDashboardData';
import { Hidden, useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { useCompanyUserSummary } from 'apis/CompanyAPI/companies/useCompanyUserSummary';
import { getOrUndefined } from 'util/resource';
import useResource from 'util/resource/useResource';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import Resource from 'util/resource/Resource';
import Card from 'ui/views/cards/Card';
import { getReachText } from 'pages/Company/Insights';
import { Link } from 'react-router-dom';
import { companyUrls } from 'urls';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import AddIcon from 'ui/elements/icons/AddIcon';
import InviteUserIcon from 'ui/elements/icons/InviteUserIcon';
import GraphIcon from 'ui/elements/icons/GraphIcon';
import TurnRightIcon from 'ui/elements/icons/TurnRightIcon';
import TrendingUpIcon from 'ui/elements/icons/TrendingUpIcon';
import OnYourCompanyAgenda from 'pages/Dashboard/manage/OnYourCompanyAgenda';
import CompanyInsights from 'pages/Dashboard/manage/CompanyInsightsCards';
import Todos from 'pages/Dashboard/manage/todos/Todos';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import FounderPulse from 'pages/Dashboard/manage/FounderPulse';
import CompanyInsightsSidebar from 'pages/Dashboard/manage/CompanyInsightsSidebar';
import React from 'react';
import styled from '@emotion/styled';
import { sectionSpacing } from 'ui/theme/themeConstants';
import { ActionLinks } from 'pages/Dashboard/manage/ManageCommunity';

const Grid = styled.div`
  display: grid;
  column-gap: ${sectionSpacing};
  grid-template-columns: 1fr 300px;
  grid-template-areas: 'main sidebar';

  ${bluePlanetTheme.breakpoints.down('lg')} {
    display: flex;
    gap: ${sectionSpacing};
  }
`;

export default function ManageCompany({ company }: { company: CompanyListItem }) {
  const { resource: dashboardData } = useCompanyOrCommunityDashboardData(company);
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const companyUserSummary = useCompanyUserSummary(company?.id);
  const companyUsers = getOrUndefined(companyUserSummary.resource);

  const { resource: reachResource } = useResource<CompanyReachDTO>(companiesAPIUrls.insights.reach(company.id));

  const reach = getOrUndefined(reachResource);

  return (
    <Resource resource={dashboardData}>
      {dashboardData => (
        <>
          <div>
            <Card className="u-section-spacing-bottom">
              {reach && (
                <div className="text-metadata u-flex-align-center u-flex--gap-1 u-content-spacing-bottom u-flex--wrap">
                  <span>
                    Company connections <strong className="text-weight-medium">{reach.connectionCount}</strong>
                  </span>
                  <span>
                    Potential reach{' '}
                    <strong>
                      {reach.potentialReach ? (
                        <strong className="text-weight-medium">{getReachText(reach.potentialReach)}</strong>
                      ) : (
                        <strong className="text-weight-medium">{reach.connectionCount}</strong>
                      )}
                    </strong>
                  </span>
                  <span>
                    <Link className="text-link-small" to={companyUrls.settings.inviteUser(company.slug)}>
                      Invite shareholders and followers
                    </Link>{' '}
                    or{' '}
                    <Link className="text-link-small" to={companyUrls.settings.share(company.slug)}>
                      share your profile
                    </Link>
                  </span>
                </div>
              )}
              <ActionLinks>
                <LinkAsButton
                  url={companyUrls.overview(company.slug, 'updates', {
                    isPostingUpdate: true,
                  })}
                >
                  <AddIcon className="u-half-spacing-right" fontSize="small"></AddIcon>
                  New post
                </LinkAsButton>
                <LinkAsButton color="greyLight" url={companyUrls.settings.inviteUser(company.slug)}>
                  <InviteUserIcon className="u-half-spacing-right" fontSize="small" /> Invite connections
                </LinkAsButton>
                <LinkAsButton color="greyLight" url={companyUrls.kpi.manage(company.slug)}>
                  <GraphIcon className="u-half-spacing-right" fontSize="small" />
                  Update metrics
                </LinkAsButton>
                <LinkAsButton color="greyLight" url={companyUrls.overview(company.slug, 'profile')}>
                  <TurnRightIcon className="u-half-spacing-right" fontSize="small" />
                  Go to company profile
                </LinkAsButton>
                <LinkAsButton color="greyLight" url={companyUrls.settings.fundingRound(company.slug)}>
                  <TrendingUpIcon className="u-half-spacing-right" fontSize="small" />
                  Start funding round
                </LinkAsButton>
              </ActionLinks>
            </Card>
          </div>
          <div>
            <OnYourCompanyAgenda
              messages={dashboardData.messages.filter(
                it =>
                  ![
                    'welcome-to-company-profile-as-new-company-admin',
                    'should-update-investors-as-company-admin',
                    'should-update-company-profile-as-company-admin',
                  ].includes(it.type),
              )} // We show these in to-do's instead
            />
          </div>
          <CompanyInsights company={company} />
          {isMobile ? (
            <>
              <div style={{ gridArea: 'main' }}>
                {companyUsers && (
                  <Todos messages={dashboardData.messages} userSummary={companyUsers} company={company} />
                )}
                <MobileMargin>
                  <FounderPulse company={company} />
                </MobileMargin>
              </div>
              <Hidden lgDown>
                <div style={{ gridArea: 'sidebar' }}>
                  <CompanyInsightsSidebar company={company} />
                </div>
              </Hidden>
            </>
          ) : (
            <Grid>
              <div style={{ gridArea: 'main' }}>
                {companyUsers && (
                  <Todos messages={dashboardData.messages} userSummary={companyUsers} company={company} />
                )}
                <FounderPulse company={company} />
              </div>
              <Hidden lgDown>
                <div style={{ gridArea: 'sidebar' }}>
                  <CompanyInsightsSidebar company={company} />
                </div>
              </Hidden>
            </Grid>
          )}
        </>
      )}
    </Resource>
  );
}
