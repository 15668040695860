import { useCallback, useEffect, useState } from 'react';

export default function useElementSize<T extends HTMLElement | null>() {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [windowWidth, setWindowWidth] = useState<number>();

  const ref = useCallback(
    (node: T) => {
      if (node !== null) {
        const { height, width } = node.getBoundingClientRect();
        setWidth(width);
        setHeight(height);
      }
    },
    [windowWidth],
  );

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ref, width, height };
}
