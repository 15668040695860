import { fromStatusCode } from 'pages/common/error/helpers';
import { useState } from 'react';
import { IResource } from '.';

interface Props<A, T> {
  onSuccess?: (result: T, args: A) => void;
  onFailure?: (e: string, args: A) => void;
}

export default function useLazyResource<A, T>(
  fetchResource: (args: A) => Promise<T>,
  { onSuccess, onFailure }: Props<A, T> = {},
) {
  const [resource, setResource] = useState<IResource<T>>({ state: 'idle' });

  const perform = async (args: A) => {
    setResource({ state: 'fetching' });
    try {
      const resource = await fetchResource(args);
      setResource({ resource, state: 'fetched', isValidating: false });
      onSuccess && onSuccess(resource, args);
    } catch (e) {
      setResource({ state: 'error', errorType: fromStatusCode(e?.status) });

      const errorMessage = e?.data?.message ?? e;

      onFailure && onFailure(typeof errorMessage === 'string' ? errorMessage : '', args);
    }
    return resource;
  };

  const result: [(args: A) => Promise<IResource<T>>, boolean, IResource<T>] = [
    perform,
    resource.state === 'fetching',
    resource,
  ];
  return result;
}
