import styled from '@emotion/styled';
import { SwipeableDrawer } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import bluePlanetTheme, { fontSizeSmall, shape } from 'ui/theme/themeBluePlanet';
import { contentSpacing, halfSpacing, quarterSpacing } from 'ui/theme/themeConstants';

const DrawerList = styled.div`
  padding: ${halfSpacing} 0;
  max-height: 90vh;
`;

const DrawerItemButton = styled.button<{ itemSpacing?: 'compact' | 'default'; isClickable: boolean }>`
  text-align: left;
  min-height: ${({ itemSpacing }) => (itemSpacing === 'compact' ? '40px' : '60px')};
  font-size: ${fontSizeSmall};
  font-weight: 500;
  padding: 0 ${contentSpacing};
  width: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: ${({ isClickable }) => (isClickable ? bluePlanetTheme.bluePlanetPalette.grey.light : undefined)};
  }
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

const DrawerItemText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const HelperText = styled.div`
  font-size: ${fontSizeSmall};
  font-weight: 400;
  margin-top: ${quarterSpacing};
`;

const Puller = styled.div`
  width: 60px;
  height: 6px;
  background-color: ${bluePlanetTheme.bluePlanetPalette.grey.medium};
  border-radius: ${shape.borderRadius}px;
  position: absolute;
  top: 8px;
  left: calc(50% - 30px);
`;

export function DrawerItem({
  children,
  onClick,
  className,
  innerClassName,
  prefix,
  disabled,
  helperText,
  suffix,
  color,
  backgroundColor,
  itemSpacing,
}: {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  innerClassName?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  disabled?: boolean;
  title?: string;
  helperText?: string;
  color?: string;
  backgroundColor?: string;
  itemSpacing?: 'default' | 'compact';
}) {
  return (
    <DrawerItemButton
      onClick={onClick}
      disabled={disabled}
      className={classNames(className, 'u-flex-center')}
      style={{ backgroundColor: backgroundColor }}
      itemSpacing={itemSpacing}
      isClickable={!!onClick}
    >
      <div style={{ color: color }} className={classNames('u-flex u-flex-align-center', innerClassName)}>
        {prefix && <span className="u-half-spacing-right">{prefix}</span>}
        <DrawerItemText>{children}</DrawerItemText>
        {suffix && <span className="u-half-spacing-left">{suffix}</span>}
      </div>
      {helperText && <HelperText>{helperText}</HelperText>}
    </DrawerItemButton>
  );
}

export default function DrawerBottom({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <SwipeableDrawer
      sx={{ zIndex: 1400 }} // zIndex must be higher than dialog zIndex
      disableSwipeToOpen
      onOpen={() => {}}
      onClose={onClose}
      anchor="bottom"
      open={isOpen}
    >
      <Puller />
      <DrawerList>{children}</DrawerList>
    </SwipeableDrawer>
  );
}
