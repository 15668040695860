import cx from 'classnames';
import React, { CSSProperties } from 'react';

export default function FormRow({
  className,
  children,
  ...rest
}: {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <div className={cx('u-half-spacing-top', className)} {...rest}>
      {children}
    </div>
  );
}
