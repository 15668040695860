import React from 'react';
import ProfileDialogContainer from 'domain/companies/ProfileDialog/ProfileDialogContainer';
import NextPreviousBar from 'ui/modules/NextPreviousBar/NextPreviousBar';
import { AnalyticsContext } from 'hooks/usePitchViewLogger';
import { CompanyOverviewSection } from 'urls';
import { useKeyboardNavigation } from 'contexts/KeyboardNavigationContext';

interface Props {
  analyticsCommunityId: number | 'none'; // We use this for tracking pitch views - important that this is set when applicable
  analyticsContext: AnalyticsContext;
  companyId: number;
  initialTab?: CompanyOverviewSection;

  actionBar?: {
    hasNext: boolean;
    hasPrevious: boolean;
    onNext: () => void;
    onPrevious: () => void;
  };
  onClose: string | (() => void);
  subRoutingIsEnabled?: boolean;
}

const MODAL_ID = 'company-profile-dialog';
export default function CompanyProfileDialog({
  analyticsCommunityId,
  companyId,
  actionBar,
  onClose,
  analyticsContext,
  subRoutingIsEnabled,
  initialTab,
}: Props) {
  const { navigationContext } = useKeyboardNavigation();
  return (
    <ProfileDialogContainer
      analyticsCommunityId={analyticsCommunityId === 'none' ? undefined : analyticsCommunityId}
      onClose={onClose}
      companyId={companyId}
      analyticsContext={analyticsContext}
      modalId={MODAL_ID}
      subRoutingIsEnabled={subRoutingIsEnabled}
      initialTab={initialTab}
      renderActionBar={
        actionBar ? (
          <NextPreviousBar
            {...actionBar}
            shouldDisableKeyboardNavigation={() =>
              // Prevent keyboard navigation when this modal is covered by another modal
              // or the navigation context is taken by another component
              !!document.querySelector(`#${MODAL_ID}[aria-hidden]`) || !!navigationContext
            }
          />
        ) : undefined
      }
    />
  );
}
