import React, { useRef, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import { CompanyProfile } from 'types/company';
import i18n from 'util/i18n';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import { FundingStage, Industry, Stage } from 'types';
import { List } from 'types/api';
import CompanyIndustryAndStagePicker, {
  IndustryAndStageField,
} from 'domain/labels/industries/CompanyIndustryAndStagePicker';
import LocationInput from 'domain/shared/Location/LocationInput';
import Label from 'ui/elements/form/Label';
import Select from 'ui/elements/form/Select/Select';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { validWebsiteRegex } from 'util/validation';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import { MISSION_MAX_LENGTH } from 'util/constants';
import { useUpdateCompany } from 'apis/CompanyAPI/companies/useUpdateCompany';
import { useFundingStages } from 'apis/CompanyAPI/labels/useFundingStages';
import { getOrUndefined } from 'util/resource';
import AiMenu from 'ui/domain/AiMenu/AiMenu';

export type Field = 'product-stage' | 'industry' | 'mission' | 'website' | 'established' | 'location' | 'funding-stage';

interface Props {
  company: CompanyProfile;
  industries: List<Industry>;
  productStages: List<Stage>;
  onClose: () => void;
  onEdit: () => void;
  visibleField?: Field;
}

export default function DetailsUpdateDialog({
  company,
  industries,
  productStages,
  onClose,
  onEdit,
  visibleField,
}: Props) {
  const [updateCompany, isUpdatingCompany] = useUpdateCompany(company.id, company.slug, {
    onSuccess: () => {
      onClose();
      onEdit();
    },
  });

  const [updatedIndustries, setUpdatedIndustries] = useState(industries.values);
  const [updatedProductStages, setUpdatedProductStages] = useState(productStages.values[0]);
  const [updatedFundingStage, setFundingStage] = useState(company.fundingStage);
  const { resource: fundingStageOptionsResource } = useFundingStages();
  const fundingStageOptions =
    getOrUndefined(fundingStageOptionsResource)?.values?.map(s => ({ ...s, value: s.id })) ?? [];

  function updateFundingStage(fundingStage: FundingStage) {
    setFundingStage(state => ({ ...state, id: fundingStage.id, name: fundingStage.name }));
  }

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      website: company.website,
      mission: company.mission,
      established: company.established,
      location: company.location,
    },
    validationSchema: Yup.object().shape({
      website: Yup.string()
        .notRequired()
        .matches(validWebsiteRegex, 'Website should be a valid url, e.g. https://example.com')
        .nullable(),
      mission: Yup.string()
        .notRequired()
        .nullable()
        .max(
          MISSION_MAX_LENGTH,
          `Keep the mission statement short and sweet - ${MISSION_MAX_LENGTH} characters at the most`,
        ),
      established: Yup.number().notRequired().nullable(),
      location: Yup.object().notRequired().nullable(),
    }),
    onSubmit: values => {
      updateCompany({
        company: values,
        labels: {
          industries: updatedIndustries,
          productStage: updatedProductStages,
          fundingStage: updatedFundingStage,
        },
      });
    },
  });

  const shouldShowField = (field: Field) => visibleField == null || visibleField === field;

  const aiRef = useRef<HTMLDivElement>(null);
  return (
    <Dialog open={true} onClose={onClose} maxWidth={!visibleField ? 'md' : 'sm'} mobileLayout="fullscreen">
      {!visibleField && <Title onClose={onClose}>Edit company details</Title>}
      <form
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Content paddingY={visibleField ? 'top' : 'none'}>
          {(shouldShowField('product-stage') || shouldShowField('industry')) && (
            <div className={visibleField ? '' : 'u-content-spacing-bottom'}>
              <CompanyIndustryAndStagePicker
                companyId={company.id}
                autoFocusField={visibleField as IndustryAndStageField}
                industries={updatedIndustries}
                productStage={updatedProductStages}
                onIndustryChange={setUpdatedIndustries}
                onStageChange={setUpdatedProductStages}
              />
            </div>
          )}
          {shouldShowField('mission') && (
            <FormikTextField
              autoFocus={visibleField === 'mission'}
              name="mission"
              formikProps={formik}
              label="Company mission"
              placeholder="A brief summary of the company. Shouldn't be longer than two paragraphs."
              multiline
              minRows={5}
              maxRows={12}
              suffixPosition="start-bottom"
              helperText={
                (formik.values.mission?.length ?? 0 > 1000)
                  ? 'Your mission statement exceeds 1000 characters. For clarity and impact, consider keeping it shorter.'
                  : undefined
              }
              suffix={
                <AiMenu
                  companyId={company.id}
                  organizationId={undefined}
                  input={formik.values.mission || ''}
                  maxLength={700}
                  onReplace={value => {
                    formik.setFieldValue('mission', value);
                  }}
                  aiRef={aiRef}
                />
              }
            />
          )}
          <div ref={aiRef} />
          {shouldShowField('website') && (
            <FormikTextField
              autoFocus={visibleField === 'website'}
              name="website"
              formikProps={formik}
              label="Company website"
              placeholder={i18n('en').placeholders.website}
            />
          )}
          {shouldShowField('established') && (
            <FormikTextField
              autoFocus={visibleField === 'established'}
              name="established"
              formikProps={formik}
              label="Year established"
              type="number"
            />
          )}
          {shouldShowField('location') && (
            <LocationInput
              autoFocus={visibleField === 'location'}
              name="location"
              label="Location"
              searchArea="places"
              placeholder={'Search for location'}
              autoComplete="address-level2"
              onChange={v => formik.setFieldValue('location', v)}
              value={formik.values.location}
            />
          )}
          {shouldShowField('funding-stage') && (
            <>
              <Label className="u-content-spacing-top">Funding stage</Label>
              <Select
                autoFocus={visibleField === 'funding-stage'}
                name="fundingStage"
                value={updatedFundingStage}
                placeholder="Select current funding stage"
                getOptionLabel={i => i.name}
                options={fundingStageOptions}
                onChange={updateFundingStage}
              />
            </>
          )}
        </Content>
        <DialogActions>
          <Button kind="primary" isLoading={isUpdatingCompany} type="submit">
            Update
          </Button>
          <Button kind="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
