import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function TimerIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M11 13.2 8 7l5.4 4.3c.8.6.8 1.7 0 2.4-.7.6-1.9.3-2.3-.5Z" />
      <path d="M5 4.8a10 10 0 1 0 9-2.6c-.8-.2-1.3-.3-1.6 0-.4.3-.4.8-.4 1.8v1" />
    </SvgIcon>
  );
}
