import SeeMore from 'ui/modules/Pagination/SeeMore';
import React from 'react';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { ICwUserId, UserProfile } from 'types/user';
import Avatar from 'ui/elements/avatars/Avatar';
import InfoCard from 'ui/views/cards/InfoCard';
import styled from '@emotion/styled';
import { contentSpacing } from 'ui/theme/themeConstants';

interface Props {
  emptyMessage?: string;
  resource: PaginatedResult<ICwUserId>;
  userProfiles: UserProfile[];
  seeMore: (paginationOptions: PaginationOptions) => Promise<PaginatedResult<ICwUserId>>;
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${contentSpacing};
`;

export default function UserList(props: Props) {
  const emptyMessage = props.emptyMessage || 'Nothing here';
  return (
    <List>
      {props.resource.values.length === 0 ? (
        <div className="u-padding-content-x">
          <InfoCard>{emptyMessage}</InfoCard>
        </div>
      ) : (
        props.resource.values.map(value => {
          const user = props.userProfiles.find(user => user.cwUserId === value.cwUserId);
          return user ? (
            <div key={user.cwUserId} className="u-flex u-flex-align-center">
              <Avatar className="u-half-spacing-right" imageUrl={user.imageUrl} />
              {user.name}
            </div>
          ) : null;
        })
      )}
      <SeeMore className="u-content-spacing-top" loadResource={props.seeMore} resource={props.resource} />
    </List>
  );
}
