import { IconButton, Tooltip } from '@mui/material';
import CheckIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import EditIcon from 'ui/elements/icons/EditIcon';
import React from 'react';
import { Link } from 'react-router-dom';
import { Goal } from 'types/goals';
import ArchiveIcon from 'ui/elements/icons/ArchiveIcon';
import UnarchiveIcon from 'ui/elements/icons/UnarchiveIcon';
import Card from 'ui/views/cards/Card';
import CardStack from 'ui/views/cards/CardStack';
import { yearMonthDate } from 'util/dateUtils';
import { isValidGoalStateTransition } from '../utils';
import GoalProgressBar from './GoalProgressBar';
import { capitalize } from 'util/stringUtils';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

interface Props {
  goal: Goal;
  editHref: string;
  viewAs: CompanyViewAs;
  onPromptGoalAction: (goal: Goal, action: GoalCardAction) => void;
}

export type GoalCardAction = 'archive' | 'unarchive' | 'delete' | 'complete';

export default function GoalCard({ goal, onPromptGoalAction, viewAs, editHref }: Props) {
  const isCompanyAdmin = viewAs === 'Admin';

  if (!isCompanyAdmin && goal.state === 'draft') {
    return null;
  }

  const canArchive = isCompanyAdmin && isValidGoalStateTransition(goal.state, 'archived');
  const canPublish = isCompanyAdmin && goal.state === 'archived' && isValidGoalStateTransition(goal.state, 'active');
  const canComplete = isCompanyAdmin && isValidGoalStateTransition(goal.state, 'completed');
  const canDelete = isCompanyAdmin && (goal.state === 'archived' || goal.state === 'completed');

  return (
    <CardStack>
      <Card>
        <div className="u-flex u-flex-space-between">
          <span>
            <h5 className="text-h4">{goal.title}</h5>
            <span className="text-metadata">
              Deadline {formatDistanceToNow(new Date(goal.deadline), { includeSeconds: false, addSuffix: true })}
            </span>
          </span>
          <div className="u-flex-space-between u-flex--align-items-center">
            {isCompanyAdmin && (
              <Tooltip title="Edit">
                <Link to={editHref}>
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {canArchive && (
              <Tooltip title="Archive">
                <IconButton onClick={() => onPromptGoalAction(goal, 'archive')}>
                  <ArchiveIcon />
                </IconButton>
              </Tooltip>
            )}
            {canPublish && (
              <Tooltip title="Reactivate">
                <IconButton onClick={() => onPromptGoalAction(goal, 'unarchive')}>
                  <UnarchiveIcon />
                </IconButton>
              </Tooltip>
            )}
            {canComplete && (
              <Tooltip title="Complete">
                <IconButton onClick={() => onPromptGoalAction(goal, 'complete')}>
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title="Delete">
                <IconButton onClick={() => onPromptGoalAction(goal, 'delete')}>
                  <TrashIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {!!goal.description && <p className="text-body u-content-spacing-top">{goal.description}</p>}
      </Card>
      <Card>
        <p className="u-half-spacing-bottom text-h4">{capitalize(goal.confidence)} confidence</p>
        <GoalProgressBar goal={goal} />
      </Card>
      {goal.state === 'archived' && !!goal.archivedAt && (
        <Card>
          <p className="text-metadata">Archived {yearMonthDate(new Date(goal.archivedAt))}</p>
          {!!goal.comment && <p className="text-body text-italic u-half-spacing-top">{goal.comment}</p>}
        </Card>
      )}
      {goal.state === 'completed' && !!goal.completedAt && (
        <Card>
          <div className="u-content-spacing-top text-metadata">
            Completed {yearMonthDate(new Date(goal.completedAt))}
          </div>
        </Card>
      )}
    </CardStack>
  );
}
