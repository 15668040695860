import React from 'react';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { Update } from 'types/content';
import { ICwUserId, UserProfile } from 'types/user';
import ChatAddIcon from 'ui/elements/icons/ChatAddIcon';
import LikeIcon from 'ui/elements/icons/LikeIcon';
import Button from 'ui/elements/buttons/Button';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import useDialogHandler from 'hooks/useDialogHandler';
import styles from './styles.scss';
import { bluePlanetTheme } from 'ui/theme';
import Tooltip from 'ui/elements/Tooltip';
import Card from 'ui/views/cards/Card';
import { quarterSpacing } from 'ui/theme/themeConstants';

interface Props {
  content: Update;
  onCommentClick: () => void;
  onLike: () => void;
  hasLiked: boolean;
  getLikes: (contentId: string, paginationOptions?: PaginationOptions) => Promise<PaginatedResult<ICwUserId>>;
  creator?: UserProfile;
}

export default function UpdateActions(props: Props) {
  const { hasLiked } = props;

  const contactUserDialogHandler = useDialogHandler();

  return (
    <>
      <Card
        style={{ paddingTop: quarterSpacing, paddingBottom: quarterSpacing }}
        padding="half"
        className={'u-flex u-flex--gap-quarter'}
      >
        <Button
          className={'u-flex-1 u-content-padding-y'}
          style={{
            color: hasLiked ? bluePlanetTheme.bluePlanetPalette.blue.main : bluePlanetTheme.bluePlanetPalette.black,
          }}
          onClick={props.onLike}
          kind="tertiary"
        >
          <Tooltip title="Like">
            <>
              <LikeIcon className="u-quarter-spacing-right" />
              <span className={styles.actionText}>Like</span>
            </>
          </Tooltip>
        </Button>
        <Button
          className={'u-flex-1 u-content-padding-y'}
          style={{ color: bluePlanetTheme.bluePlanetPalette.black }}
          kind="tertiary"
          onClick={props.onCommentClick}
        >
          <span className={`u-flex u-flex-align-center ${styles.actionText}`}>
            <ChatAddIcon className="u-quarter-spacing-right" fontSize="small" />
            Comment
          </span>
        </Button>
        {props.creator && (
          <Button
            className={'u-flex-1 u-content-padding-y'}
            style={{ color: bluePlanetTheme.bluePlanetPalette.black }}
            kind="tertiary"
            onClick={contactUserDialogHandler.open}
          >
            <ChatBubblesIcon className="u-quarter-spacing-right" fontSize="small" />
            <span className={styles.actionText}>Message</span>
          </Button>
        )}
      </Card>
      {props.creator && contactUserDialogHandler.isOpen && (
        <UserConversationDialog
          conversation={{ type: 'withUsers', withCwUserIds: [props.creator.cwUserId] }}
          closeConversation={contactUserDialogHandler.close}
        />
      )}
    </>
  );
}
