import Tooltip from '@mui/material/Tooltip';
import React, { CSSProperties } from 'react';
import EyeCrossedIcon from 'ui/elements/icons/EyeCrossedIcon';

interface Props {
  title?: string;
  style?: CSSProperties;
}

export default function CompanyMastersOnlyTooltip(props: Props) {
  return (
    <Tooltip title={props.title || 'Only visible for company admins'} style={props.style}>
      <span>
        <EyeCrossedIcon color="dark-grey" />
      </span>
    </Tooltip>
  );
}
