import { Checkbox as MUICheckbox, FormHelperText } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import styles from './checkbox.scss';

interface Props {
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  name?: string;
  checked: boolean;
  indeterminate?: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  color?: 'primary' | 'secondary' | 'default';
  required?: CheckboxProps['required'];
  fontSize?: 'small' | 'medium' | 'large';
  error?: string;
}

function Label(props: Props & { children: JSX.Element }) {
  const fontSize = props.fontSize === 'large' ? '1rem' : props.fontSize == 'small' ? '0.75rem' : '0.875rem';
  if (props.label)
    return (
      <FormControlLabel
        label={props.label}
        name={props.name}
        onChange={props.onChange}
        className={styles.checkboxLabel}
        value={props.value}
        control={props.children}
        sx={{
          '.MuiFormControlLabel-label': { fontSize },
        }}
      />
    );
  return props.children;
}

export default function Checkbox(props: Props) {
  const helperText = props.helperText || props.error;
  return (
    <>
      <Label {...props}>
        <MUICheckbox
          onChange={props.onChange}
          name={props.name}
          checked={props.checked}
          color={props.color}
          required={props.required}
          disabled={props.disabled}
          indeterminate={props.indeterminate}
        />
      </Label>
      {helperText && (
        <FormHelperText error={!!props.error} sx={{ paddingLeft: 0, marginLeft: 0 }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}
