import React from 'react';
import { Link } from 'react-router-dom';
import { ICompany } from 'types/company';

export type HrefOrOnClick =
  | { type: 'href'; handler: (company: ICompany) => string }
  | { type: 'click'; handler: (companyId: number) => void };

export default function HrefOrOnClickWrapper({
  company,
  action,
  trackingKey,
  children,
}: {
  company: ICompany;
  action: HrefOrOnClick;
  trackingKey: string;
  children: React.ReactNode;
}) {
  return (
    <>
      {action.type === 'href' && (
        <Link to={action.handler(company)} className={trackingKey}>
          {children}
        </Link>
      )}
      {action.type === 'click' && (
        <button
          onClick={() => action.handler(company.id)}
          className={trackingKey}
          style={{ cursor: 'pointer', textAlign: 'left' }}
        >
          {children}
        </button>
      )}
    </>
  );
}
