import React from 'react';
import { communityUrls, companyUrls } from 'urls';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import { DashboardMessage as DashboardMessageDTO } from 'apis/DashboardAPI';
import CommunityRequests from 'pages/Community/Overview/CommunityRequests';
import Card from 'ui/views/cards/Card';
import { yearsMonthsDaysSince } from 'util/dateUtils';
import PlusIcon from 'ui/elements/icons/PlusIcon';
import StatsCard from 'ui/views/cards/StatsCard';
import { differenceInDays, startOfDay } from 'date-fns';
import CardHeading from 'ui/views/cards/CardHeading';
import HundredPitchesOnYourAgenda from 'domain/communities/applications/100pitchesOnYourAgenda';
import FinishCompanyProfile from '../sections/messages/FinishCompanyProfile';
import PendingCompanyMessage from '../sections/messages/PendingCompanyMessage';
import useRoute from 'hooks/useRoute';
import { AgendaCardContainer, UpdateButton } from './OnYourCompanyAgenda';

function isCompanyMessage(type: DashboardMessageDTO['type']) {
  return [
    'community-admin-pending-requests',
    'welcome-to-company-profile-as-new-company-admin',
    'should-update-company-profile-as-company-admin',
    'should-update-investors-as-company-admin',
    'pending-community-application-as-company-admin',
    'dnb-nxt-webinar',
    'is-founder-in-100-pitches',
    'company-pending-in-community',
  ].includes(type);
}

export function CompanyMessages({ messages }: { messages: DashboardMessageDTO[] }) {
  const { push } = useRoute();

  const restOfMessages = messages?.filter(it => isCompanyMessage(it.type)) ?? [];

  return restOfMessages.map(message => {
    switch (message.type) {
      case 'is-founder-in-100-pitches':
        return <HundredPitchesOnYourAgenda key="is-founder-in-100-pitches" communitySlug={message.communitySlug} />;
      case 'company-pending-in-community':
        return <PendingCompanyMessage message={message} key={message.type + message.community.name} />;
      case 'community-admin-pending-requests':
        return (
          <CommunityRequests
            key={`${message.communitySlug}-message-community-requests`}
            communitySlug={message.communitySlug}
            users={message.userCount}
            companies={message.companyCount}
          />
        );
      case 'should-update-investors-as-company-admin':
        const updateUrl = companyUrls.overview(message.company.slug, 'updates', {
          isPostingUpdate: true,
          audience: 'investors',
        });
        if (!message.lastUpdated) {
          return (
            <Card key="message-update-investors">
              <AgendaCardContainer>
                <CardHeading heading="Your investors are waiting" />
                <p className="text-body u-content-spacing-bottom">
                  Your shareholders have invested in {message.company.name} because they believe in your vision. Keep
                  them updated and show them the progress you&apos;re making.
                </p>
                <div style={{ marginTop: 'auto' }}>
                  <LinkAsButton
                    className="data-track-dashboard-cta-update-company-profile"
                    kind={'primary'}
                    url={updateUrl}
                  >
                    Update your shareholders
                  </LinkAsButton>
                </div>
              </AgendaCardContainer>
            </Card>
          );
        }

        const timeSinceUpdate = differenceInDays(startOfDay(new Date()), startOfDay(new Date(message.lastUpdated)));
        const color = timeSinceUpdate < 25 ? 'blue' : timeSinceUpdate < 35 ? 'orange' : 'red';
        return (
          <div key="message-update-investors" className="u-flex" style={{ gap: '10px' }}>
            <Card
              onClick={() => push(updateUrl)}
              hover="highlight"
              color="blue"
              className="u-flex-grow"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <div className="u-flex u-flex--column u-flex-align-center">
                <UpdateButton href={updateUrl}>
                  {/* there is some whitespace to the left that doesn't align the icon in the center */}
                  <PlusIcon style={{ marginLeft: '-1px' }} color="blue" fontSize="small" />
                </UpdateButton>
                <span className="u-half-spacing-top text-align-center">Update your shareholders</span>
              </div>
            </Card>
            <StatsCard
              padding="half"
              title="days since last update"
              className="u-flex-grow"
              value={timeSinceUpdate}
              color={color}
            />
          </div>
        );
      case 'welcome-to-company-profile-as-new-company-admin':
        return <FinishCompanyProfile key="welcome-to-company-profile-as-new-company-admin" company={message.company} />;
      case 'should-update-company-profile-as-company-admin':
        return (
          <Card key={`${message.company.id}-update-company-profile`}>
            <AgendaCardContainer>
              <CardHeading heading="Make sure your company information is up to date!" />
              <p className="text-body u-content-spacing-bottom">
                It has been {yearsMonthsDaysSince(message.lastUpdated)} since you last updated your company information,
                perhaps some of it is outdated? Review your profile and make sure it&apos;s up to date.
              </p>
              <div style={{ marginTop: 'auto' }}>
                <LinkAsButton
                  className="data-track-dashboard-cta-update-company-profile"
                  kind={'primary'}
                  url={companyUrls.overview(message.company.slug, 'profile')}
                >
                  Go to company profile
                </LinkAsButton>
              </div>
            </AgendaCardContainer>
          </Card>
        );
      case 'pending-community-application-as-company-admin':
        return (
          <Card key="pending-community-application">
            <AgendaCardContainer>
              <CardHeading heading="Application in progress" />
              <p className="text-body u-content-spacing-bottom">
                Your application to {message.community.name} is currently being evaluated
              </p>
              <div style={{ marginTop: 'auto' }}>
                <LinkAsButton
                  className="data-track-dashboard-cta-pending-application"
                  kind={'primary'}
                  url={communityUrls.overview(message.community.slug)}
                >
                  Go to community profile
                </LinkAsButton>
              </div>
            </AgendaCardContainer>
          </Card>
        );
      default:
        return null;
    }
  });
}
