import React from 'react';
import Button from 'ui/elements/buttons/Button';
import Dialog, { Title, Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { useUpdateCompany } from 'apis/CompanyAPI/companies/useUpdateCompany';
import { useFormik } from 'formik';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';

interface Props {
  calendlyUrl?: string;
  companyId: number;
  companySlug: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function CalendarSetupDialog({ companyId, companySlug, isOpen, onClose, calendlyUrl }: Props) {
  const [saveCompany, isSavingCompany] = useUpdateCompany(companyId, companySlug, {
    onSuccess: () => {
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: {
      calendlyUrl: calendlyUrl,
    },
    onSubmit: values => {
      saveCompany({ company: { calendlyUrl: values.calendlyUrl } });
    },
  });

  return (
    <Dialog open={isOpen} onClose={onClose} mobileLayout="drawer">
      <Title onClose={onClose}>Set up company calendar</Title>
      <form
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Content>
          Add your{' '}
          <a href="https://calendly.com/" target="_blank" rel="noreferrer" className="text-link">
            Calendly account
          </a>{' '}
          to allow others to book a meeting with you. This is particularly useful to potential and current investors
          that want to know more about your company and its progress more in-depth.
          <FormikTextField
            formikProps={formik}
            name="calendlyUrl"
            className="u-content-spacing-top"
            autoFocus
            prefix="https://calendly.com/"
            placeholder="acme-inc/15min"
          />
        </Content>
        <DialogActions>
          <Button type="submit" kind="primary" isLoading={isSavingCompany}>
            Update
          </Button>
          <Button kind="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
