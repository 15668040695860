import useSWR from 'hooks/useSWR';
import { List } from 'types/api';
import { Shortlist } from 'types/user';
import { useSWRResource } from 'util/resource/useSWRResource';
import { userAPIUrls } from './usersApi';

export const useShortlists = () => {
  const swrResult = useSWR<List<Shortlist>>(userAPIUrls.shortlists.list());

  return useSWRResource(swrResult);
};

export function updateShortlistInList(list: List<Shortlist> | undefined, updatedItem: Shortlist): List<Shortlist> {
  if (!list) {
    return { values: [] };
  }

  return { values: list.values.map(sl => (sl.id === updatedItem.id ? updatedItem : sl)) };
}
