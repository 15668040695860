import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function EditIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m15.2 6 1.4-1.4a2 2 0 0 1 2.8 2.8L18 8.8M15.2 6 7 14.2c-1 1-1.6 1.6-2 2.2A19 19 0 0 0 4 20c1.4-.3 3-.7 3.6-1a11 11 0 0 0 2.2-2L18 8.8M15.2 6 18 8.8" />
      <path d="M11 20h6" />
    </SvgIcon>
  );
}
