import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import { uploadDocument } from 'domain/companies/Documents/actions';
import DocumentUploader from 'domain/companies/Documents/Upload/DocumentUploader';
import useDialogHandler from 'hooks/useDialogHandler';
import React from 'react';
import { FolderDTO } from 'types/documents';
import Button from 'ui/elements/buttons/Button';
import UploadIcon from 'ui/elements/icons/UploadIcon';
import { DialogActions } from 'ui/views/dialogs/Dialog';

interface Props {
  companyId: number;
  folderId?: FolderDTO['id'];
  uploadDocument: typeof uploadDocument;
  onClose: (successfulUploads: number) => void;
}

export default function UploadDialog(props: Props) {
  const dialogHandler = useDialogHandler();

  function handleClose(successCount: number) {
    dialogHandler.close();
    props.onClose(successCount);
  }

  const { folderId } = props;

  return !folderId ? (
    <>
      <Tooltip title="Upload documents to this folder" placement="top" disableFocusListener>
        <IconButton color="primary" onClick={dialogHandler.open}>
          <UploadIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        dismissLabel="Close"
        title="Please select a folder"
        isOpen={dialogHandler.isOpen}
        onClose={dialogHandler.close}
      >
        To upload files you must first select a folder. A folder can be selected by closing this dialog and clicking on
        the folder. If no folders exist you can create one by clicking the &quot;add folder&quot; icon in the top right
        of this section.
      </ConfirmDialog>
    </>
  ) : (
    <>
      <DocumentUploader uploadDocument={props.uploadDocument(props.companyId, folderId)}>
        {({ dropzone, uploadedDocuments, documentsBeingUploaded, numberOfSuccessfullyUploadedDocuments, reset }) => (
          <>
            <Tooltip title="Upload documents to this folder" placement="top" disableFocusListener>
              <IconButton color="primary" onClick={dialogHandler.open}>
                <UploadIcon />
              </IconButton>
            </Tooltip>
            <Dialog
              open={dialogHandler.isOpen}
              onClose={() => {
                handleClose(numberOfSuccessfullyUploadedDocuments);
                reset();
              }}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>Upload documents</DialogTitle>
              <DialogContent>
                {dropzone}
                {uploadedDocuments}
              </DialogContent>
              <DialogActions>
                <Button
                  kind="secondary"
                  onClick={() => {
                    handleClose(numberOfSuccessfullyUploadedDocuments);
                    reset();
                  }}
                  disabled={documentsBeingUploaded.length > 0}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </DocumentUploader>
    </>
  );
}
