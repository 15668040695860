import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function UserIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M4 20c0-3 4-3 6-5 1-1-2-1-2-6 0-3.3 1.3-5 4-5s4 1.7 4 5c0 5-3 5-2 6 2 2 6 2 6 5" />
    </SvgIcon>
  );
}
