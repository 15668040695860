import featureToggle from 'featureToggle';
import { ResourceAction, ResourceName, Role, VisibleRole } from 'types/company/access';
import { CommunityType } from 'types/company/community';

export const roleArticle = (role: Role) => {
  switch (role) {
    case 'company_master':
    case 'organization_master':
    case 'organization_admin_in_community':
    case 'employee':
    case 'advisor':
      return 'an';
    case 'board':
    case 'prospective_investor':
    case 'mentor':
    case 'company_admin_in_community':
      return 'a';
    case 'investor':
    case 'organization_member':
      return 'a';
  }
};

export const roleDisplayName = (role: Role) => {
  switch (role) {
    case 'company_master':
    case 'organization_master':
      return 'Admin';
    case 'board':
      return 'Board member';
    case 'investor':
      return 'Shareholder';
    case 'prospective_investor':
      return 'Follower';
    // these are not applicable for companies, but we leave them here to avoid any undefined errors
    case 'employee':
      return 'Employee';
    case 'advisor':
      return 'Advisor';
    case 'mentor':
      return 'Mentor';
    case 'company_admin_in_community':
      return 'Company';
    case 'organization_admin_in_community':
      return 'Organization';
    case 'organization_member':
      return 'Member';
  }
};

export function roleFromQueryParam(param: string | null | undefined): VisibleRole | undefined {
  switch (param) {
    case 'founder':
      return 'company_master';
    case 'shareholder':
      return 'investor';
    case 'follower':
      return 'prospective_investor';
    case 'board':
      return 'board';
    case 'employee':
      return 'employee';
    case 'advisor':
      return 'advisor';
    case 'mentor':
      return 'mentor';
    default:
      return undefined;
  }
}

export const invisibleRoles: Role[] = ['company_admin_in_community'];

export const roleDisplayNamePlural = (role: Role) => {
  switch (role) {
    case 'company_master':
      return 'Company admins';
    case 'organization_master':
      return 'Organization admins';
    case 'board':
      return 'Board members';
    case 'investor':
      return 'Shareholders';
    case 'employee':
      return 'Employees';
    case 'prospective_investor':
      return 'Followers';
    case 'advisor':
      return 'Advisors';
    case 'mentor':
      return 'Mentors';
    case 'company_admin_in_community':
      return 'Companies';
    case 'organization_admin_in_community':
      return 'Organizations';
    case 'organization_member':
      return 'Members';
  }
};

export const communityRoles = (communityType: CommunityType | undefined, communityId: number): VisibleRole[] => {
  if (communityType === 'investor_arena' && featureToggle.communityHasBoardRoom(communityId))
    return ['company_master', 'advisor', 'board', 'employee', 'investor', 'mentor'];
  if (communityType === 'investor_arena' && !featureToggle.communityHasBoardRoom(communityId))
    return ['company_master', 'advisor', 'employee', 'investor', 'mentor'];
  if (communityType === 'mentor_network') return ['company_master', 'mentor'];
  if (communityType === 'mentor_master_network') return ['company_master'];
  else return ['company_master', 'advisor', 'board', 'investor', 'employee'];
};

export const companyViewAsOptions = ['Admin', 'Shareholder', 'Board member', 'Follower', 'Visitor'] as const;
export type CompanyViewAs = (typeof companyViewAsOptions)[number];

export const getViewAs = (
  roles: Role[],
  hasScope: (action: ResourceAction, resource: ResourceName) => boolean,
): CompanyViewAs => {
  if (hasScope('Write', 'Company')) {
    return 'Admin';
  }
  if (roles.includes('investor')) {
    return 'Shareholder';
  }
  if (roles.includes('board')) {
    return 'Board member';
  }
  if (roles.includes('prospective_investor') || hasScope('Read', 'Company')) {
    return 'Follower';
  }

  return 'Visitor';
};
