import React from 'react';
import Card from 'ui/views/cards/Card';
import Logo from 'ui/domain/Logo';
import { formatShortLocation } from 'util/locationUtils';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Banner from 'domain/shared/Banner';
import useLocalStorage from 'hooks/useLocalStorage';
import { CompanyProfile } from 'types/company';
import { bluePlanetTheme } from 'ui/theme';
import LockIcon from 'ui/elements/icons/LockIcon';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import HorizontalDictionary from 'ui/elements/dictionary/HorizontalDictionary';
import useWindowWidth from 'hooks/useWindowWidth';
import StageChip from 'ui/domain/Chips/StageChip';
import ChipList from 'ui/elements/Chip/ChipList';
import TruncatedList from 'util/components/TruncatedList';
import ShareIcon from 'ui/elements/icons/ShareIcon';
import BookmarkIcon from 'ui/elements/icons/BookmarkIcon';
import { Hidden } from '@mui/material';
import CardStack from 'ui/views/cards/CardStack';
import { companyUrls } from 'urls';
import PopMenu, { PopMenuItem } from 'ui/modules/PopMenu';
import LinkedInIcon from 'ui/elements/icons/LinkedInIcon';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import useNotify from 'hooks/useNotify';
import { ICompany } from 'types/company';
import { useLoginWithRedirect } from 'auth/useLoginWithRedirect';
import useFundingRound from '../Overview/useFundingRound';
import Resource from 'util/resource/Resource';
import CardInfoCard from 'ui/views/cards/CardInfoCard';
import { getFundingRoundClosingDateText } from 'domain/companies/FundingRoundWidget/FundingRound';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import ClampedText from 'ui/elements/text/ClampedText';
import { notEmpty } from 'util/arrayUtils';
import { ActionCardBody, ActionCardButtons, Body, CompanyCardHeader } from 'ui/layout/PublicPage';
import styled from '@emotion/styled';
import { spacingUnits } from 'ui/theme/themeBluePlanet';

const CtaText = styled.p`
  ${bluePlanetTheme.breakpoints.down('sm')} {
    margin-top: ${spacingUnits.half};
  }
`;

function HeaderActions({ company, disabled }: { company: ICompany; disabled?: boolean }) {
  const notify = useNotify();
  const [, setAutoShortlistCompany] = useLocalStorage<string | undefined>('auto-shortlist-company', undefined);
  const { loginWithRedirect } = useLoginWithRedirect();
  const href = `${location.origin}${companyUrls.overview(company.slug, 'profile')}`;
  const onCopy = () => {
    if (typeof window !== 'undefined') {
      window.navigator.clipboard.writeText(
        `${href}?utm_source=direct&utm_medium=copy-link&utm_campaign=company&utm_term=public-profile`,
      );
      notify('success', 'Link copied');
    }
  };

  const linkedinShare = () => {
    window.open(
      `http://www.linkedin.com/shareArticle?mini=true&url=${href}?utm_source=linkedin&utm_medium=share-link&utm_campaign=company&utm_term=public-profile&title=${company.name} - CrowdWorks`,
      '',
      '_blank, width=500, height=600, resizable=yes, scrollbars=yes',
    );
    return false;
  };
  const menuItems: PopMenuItem[] = [
    {
      icon: <LinkedInIcon />,
      text: <>Share on LinkedIn</>,
      onClick: disabled ? () => {} : linkedinShare,
    },
    {
      icon: <LinkIcon />,
      text: <>Copy link to profile</>,
      onClick: disabled ? () => {} : onCopy,
    },
    navigator.share
      ? {
          icon: <ShareIcon />,
          text: <>More options</>,
          onClick: () => {
            navigator.share({
              title: company.name,
              text: `Check out ${company.name} on CrowdWorks!`,
              url: `${href}?utm_source=direct&utm_medium=native-share&utm_campaign=company&utm_term=public-profile`,
            });
          },
        }
      : undefined,
  ].filter(notEmpty);

  return (
    <>
      <PopMenu
        items={menuItems}
        renderAnchor={onClick => (
          <Button onClick={onClick} kind="tertiary" color="grey">
            <ShareIcon className="u-quarter-spacing-right" />
            Share
          </Button>
        )}
      />
      <Button
        kind="tertiary"
        color="grey"
        onClick={() => {
          if (disabled) return;
          setAutoShortlistCompany(company.slug);
          loginWithRedirect('login');
        }}
      >
        <BookmarkIcon className="u-quarter-spacing-right" /> Add to list
      </Button>
    </>
  );
}

export default function PublicProfile({ company, disabled = false }: { company: CompanyProfile; disabled?: boolean }) {
  const width = useWindowWidth();
  const isSmallScreen = width < bluePlanetTheme.breakpoints.values.md;
  const { loginWithRedirect } = useLoginWithRedirect();
  const [, setAutoFollowCompany] = useLocalStorage<string | undefined>('auto-follow-company', undefined);
  const { resource: fundingRoundResource } = useFundingRound(company.slug);

  return (
    <div>
      <Banner imageURL={company.imageURL} />
      <div style={{ position: 'relative' }}>
        <Body>
          <CardStack>
            <Card padding={isSmallScreen ? 'default' : 'double'} elevation={1}>
              <CompanyCardHeader>
                <div className="u-flex u-flex-align-center">
                  <Logo size={45} className="u-content-spacing-right" type="company" url={company.logoURL} />
                  <div>
                    {/* This needs to be a H1 for SEO reasons */}
                    <h1 className="text-h4" style={{ lineHeight: '1.1' }}>
                      {company.name}
                    </h1>
                  </div>
                </div>
                <ButtonList>
                  <Hidden mdDown>
                    <HeaderActions company={company} disabled={disabled} />
                  </Hidden>
                  <Button
                    kind="primary"
                    onClick={() => {
                      if (disabled) return;
                      setAutoFollowCompany(company.slug);
                      loginWithRedirect('signup');
                    }}
                  >
                    <>
                      <span
                        className="u-quarter-spacing-right"
                        style={{ fontSize: '1.25rem', marginTop: '-8px', marginBottom: '-8px' }}
                      >
                        +
                      </span>
                      Follow
                    </>
                  </Button>
                </ButtonList>
              </CompanyCardHeader>
              <div className="u-content-spacing-top" style={{ minHeight: 29 }}>
                <ChipList>
                  <TruncatedList
                    values={company.industries.values}
                    limit={isSmallScreen ? 2 : 5}
                    renderItem={industry => <IndustryChip label={industry.name} />}
                    renderExpandButton={(industry, onClick) => <IndustryChip label={industry.name} onClick={onClick} />}
                  />
                  {company.stages.values.map(stage => (
                    <StageChip key={stage.name} label={stage.name} />
                  ))}
                </ChipList>
              </div>
              <ClampedText numLines={isSmallScreen ? 3 : 5} className="text-body u-half-spacing-y">
                {company.mission ?? ''}
              </ClampedText>

              <HorizontalDictionary columnCount={isSmallScreen ? 2 : 4} className="u-content-spacing-top">
                {[
                  {
                    key: 'Established',
                    value: company.established || '-',
                  },
                  {
                    key: 'Location',
                    value: company.location ? formatShortLocation(company.location) : '-',
                  },
                  {
                    key: 'Funding stage',
                    value: company.fundingStage?.name ?? '-',
                  },
                  {
                    key: 'Connections',
                    value: company.connectionCount,
                  },
                ]}
              </HorizontalDictionary>
            </Card>
            <Hidden mdUp>
              <Card>
                <ButtonList>
                  <HeaderActions company={company} disabled={disabled} />
                </ButtonList>
              </Card>
            </Hidden>
          </CardStack>
          <Resource resource={fundingRoundResource} renderError="Nothing" renderLoading="Nothing">
            {fundingRound =>
              fundingRound.visibility === 'restricted' &&
              fundingRound.isActive && (
                <CardInfoCard
                  className="u-half-spacing-top"
                  title="Active funding round"
                  subTitle={getFundingRoundClosingDateText(fundingRound.plannedClosingDate)}
                >
                  <Button kind={'secondary'} color="white" onClick={() => loginWithRedirect('signup')}>
                    <ChatBubblesIcon className="u-half-spacing-right" /> I&apos;m interested
                  </Button>
                </CardInfoCard>
              )
            }
          </Resource>
          <Card padding={isSmallScreen ? 'default' : 'double'} className="u-half-spacing-top" color="indigo">
            <ActionCardBody>
              <div className="u-flex">
                <IconAvatar color="white" className="u-content-spacing-right">
                  <LockIcon />
                </IconAvatar>
                <div>
                  <p className="text-weight-bold">Unlock more company insights</p>
                  <CtaText>
                    Create an account to get access to their profile, pitch, live updates, insights and more.
                  </CtaText>
                </div>
              </div>
              <ActionCardButtons>
                <ButtonList>
                  <Button
                    onClick={() => {
                      if (disabled) return;
                      return loginWithRedirect('login');
                    }}
                    kind="tertiary"
                    color="greyLight"
                  >
                    Log in
                  </Button>
                  <Button
                    onClick={() => {
                      if (disabled) return;
                      return loginWithRedirect('signup');
                    }}
                    kind="primary"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Sign up
                  </Button>
                </ButtonList>
              </ActionCardButtons>
            </ActionCardBody>
          </Card>
        </Body>
      </div>
    </div>
  );
}
