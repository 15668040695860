import React from 'react';
import Card, { PaddingSizes } from './Card';
import bluePlanetTheme, { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import Tooltip from 'ui/elements/Tooltip';
import QuestionIcon from 'ui/elements/icons/QuestionIcon';
import { formatThousands } from 'util/numbers';
import { PeriodOverPeriod } from 'pages/Dashboard/manage/PeriodOverPeriod';

interface Props {
  title: string;
  tooltip?: string;
  icon?: React.ReactNode;
  value: string | number;
  previousValue?: number;
  color?: 'default' | 'blue' | 'orange' | 'red';
  className?: string;
  padding?: PaddingSizes;
  children?: React.ReactNode;
}

export default function StatsCard({
  className,
  icon,
  value,
  previousValue,
  title,
  tooltip,
  color,
  padding,
  children,
}: Props) {
  const valueColor =
    color === 'blue'
      ? bluePlanetTheme.bluePlanetPalette.blue.main
      : color === 'orange'
      ? bluePlanetTheme.bluePlanetPalette.orange.medium
      : color === 'red'
      ? bluePlanetTheme.bluePlanetPalette.red.medium
      : 'inherit';

  return (
    <Card className={className} elevation={1} padding={padding}>
      <div className="u-flex u-flex--column u-flex-space-between" style={{ height: '100%' }}>
        <div className="u-flex-align-center text-medium text-font-text text-align-left">
          {title}
          {tooltip && (
            <Tooltip
              className="u-quarter-spacing-left u-flex-align-center"
              color="light"
              title={
                <p className="text-body" style={{ fontWeight: '400' }}>
                  {tooltip}
                </p>
              }
            >
              <div>
                <QuestionIcon style={{ fontSize: fontSizeSmall }}></QuestionIcon>
              </div>
            </Tooltip>
          )}
        </div>
        <div className="u-flex-align-center">
          {icon && <div className="u-half-spacing-right">{icon}</div>}
          <p className="text-jumbo text-weight-medium u-half-spacing-right">
            <span
              style={{
                color: valueColor,
                fontSize: '2rem',
              }}
              className="text-weight-bold text-align-left"
            >
              {typeof value === 'number' ? formatThousands(value) : value}
            </span>
          </p>
          {typeof value === 'number' && previousValue !== undefined && (
            <PeriodOverPeriod value={value} previousValue={previousValue} />
          )}
        </div>
        {children}
      </div>
    </Card>
  );
}
