import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ShareIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21 6.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM9 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM21 17.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8.7 10.7l6.5-3m-6.5 5.6 6.5 3" />
    </SvgIcon>
  );
}
