import React, { useEffect, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import { OptionPredefined } from './OptionPredefined';
import { OptionSelected } from './OptionSelected';
import TextField from 'ui/elements/form/TextField';
import classNames from 'classnames';
import usePreviousProps from 'hooks/usePreviousProps';
import TooltipTrigger from 'ui/elements/Tooltip/TooltipTrigger';
import InlineTooltip from 'ui/elements//Tooltip/InlineTooltip';

interface IOption {
  name: string;
  tooltip?: string;
}

export function toOption(value: string) {
  return { name: value };
}

interface Props<T extends IOption> {
  options: T[];
  values: T[];
  onSelect: (value: T) => void;
  isSelecting?: boolean;
  onRemove?: (value: T) => void;
  isRemoving?: boolean;
  onCustomInputSelect?: (value: string) => void;
  customInputPlaceholder?: string;
  customInputErrorMessage?: string;
  customInputComponent?: React.ReactNode;
  customInputButton?: React.ReactNode;
  align?: 'left' | 'center';
  className?: string;
  autoFocus?: boolean;
}

export default function SelectCloud<T extends IOption>({
  options,
  values,
  onSelect,
  isSelecting,
  onRemove,
  isRemoving,
  onCustomInputSelect,
  customInputPlaceholder,
  customInputErrorMessage,
  customInputComponent,
  customInputButton,
  align = 'left',
  className,
  autoFocus,
}: Props<T>) {
  const [isCustomInputVisible, setIsCustomInputVisible] = useState(false);
  const [customInput, setCustomInput] = useState<string>('');
  const [isSelectingCustomInput, setIsSelectingCustomInput] = useState(false);
  const [hasCustomInputError, setHasCustomInputError] = useState(false);

  const isEqual = (a: T, b: T) => a.name === b.name;

  // We show the selected options within the selected options group, to get the same order of options, even when they have selected
  // the ones that are predefined. Therefore, we need to filter out the selected options that are predefined.
  const restOfSelectedOptions = values.filter(value => !options.some(option => isEqual(option, value)));

  const previousIsSelecting = usePreviousProps(isSelecting);
  const onCustomInputAdded = () => {
    // If onSelection triggers loading, we make the add button of custom selection load after it has been added
    // but do not hide the custom input field
    if (isSelecting != null) {
      setIsSelectingCustomInput(true);
    } else {
      setCustomInput('');
      setIsCustomInputVisible(false);
    }
  };

  useEffect(() => {
    if (isSelecting == null) {
      return;
    }
    // reset custom input field if there was loading of selection after the loading has finished
    if (customInput && !hasCustomInputError && previousIsSelecting === true && isSelecting === false) {
      setCustomInput('');
      setIsCustomInputVisible(false);
      setIsSelectingCustomInput(false);
    }
  }, [isSelecting, previousIsSelecting, customInput, hasCustomInputError]);

  const [tooltipText, setTooltipText] = useState<React.ReactNode | undefined>();

  return (
    <div className={className}>
      <div className={classNames('u-flex--wrap', { 'u-flex-center': align === 'center' })}>
        {options.map((option, i) => {
          const value = values.find(value => isEqual(value, option));
          return value ? (
            <TooltipTrigger
              key={i}
              setText={setTooltipText}
              text={value.tooltip ? <InlineTooltip title={value.name + ':'}>{value.tooltip}</InlineTooltip> : <></>}
            >
              <OptionSelected
                autoFocus={i === 0 && autoFocus}
                key={value.name}
                label={value.name}
                onRemove={onRemove}
                isRemoving={isRemoving}
                option={value}
              />
            </TooltipTrigger>
          ) : (
            <TooltipTrigger
              key={i}
              setText={setTooltipText}
              text={option.tooltip ? <InlineTooltip title={option.name + ':'}>{option.tooltip}</InlineTooltip> : <></>}
            >
              <OptionPredefined
                autoFocus={i === 0 && autoFocus}
                key={option.name}
                label={option.name}
                onSelect={onSelect}
                isSelecting={isSelecting}
                option={option}
              />
            </TooltipTrigger>
          );
        })}
        {restOfSelectedOptions.map(option => (
          <TooltipTrigger
            key={option.name}
            setText={setTooltipText}
            text={option.tooltip ? <InlineTooltip title={option.name + ':'}>{option.tooltip}</InlineTooltip> : <></>}
          >
            <OptionSelected label={option.name} onRemove={onRemove} isRemoving={isRemoving} option={option} />
          </TooltipTrigger>
        ))}
        {customInputButton && customInputButton}
        {(onCustomInputSelect || customInputComponent) && !isCustomInputVisible && (
          <Button kind="tertiary" color="indigo" shape="rounded" onClick={() => setIsCustomInputVisible(true)}>
            Add other
          </Button>
        )}
      </div>
      {isCustomInputVisible && customInputComponent}
      {isCustomInputVisible && !customInputComponent && (
        <div className="u-flex u-flex--align-items-start u-half-spacing-top">
          <TextField
            className="u-quarter-spacing-right"
            placeholder={customInputPlaceholder}
            value={customInput}
            error={hasCustomInputError}
            helperText={hasCustomInputError ? customInputErrorMessage : ' '}
            onChange={e => setCustomInput(e.target.value)}
            autoFocus
          />
          <Button
            kind="secondary"
            isLoading={isSelectingCustomInput}
            onClick={() => {
              if (customInput && onCustomInputSelect) {
                onCustomInputSelect(customInput);
                onCustomInputAdded();
              } else {
                setHasCustomInputError(true);
              }
            }}
          >
            Add
          </Button>
        </div>
      )}
      {options.some(o => o.tooltip) && (
        <div className="u-section-spacing-top " style={{ minHeight: '6rem' }}>
          {tooltipText ? (
            tooltipText
          ) : (
            <InlineTooltip title="Category help:">Hover or hold category to learn more</InlineTooltip>
          )}
        </div>
      )}
    </div>
  );
}
