import React from 'react';

import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import CrowdWorksLogo from 'ui/elements/icons/CrowdWorksLogo';
import { Hidden } from '@mui/material';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { spacingUnits } from 'ui/theme/themeBluePlanet';
import { useLoginWithRedirect } from 'auth/useLoginWithRedirect';

const HeaderWrapper = styled.header(
  ({ color }: { color: 'white' | 'indigo' }) => `
  background-color: ${
    color === 'white' ? bluePlanetTheme.bluePlanetPalette.white : bluePlanetTheme.bluePlanetPalette.indigo.main
  };
  color: ${color === 'white' ? bluePlanetTheme.bluePlanetPalette.indigo.main : bluePlanetTheme.bluePlanetPalette.white};
  padding: ${spacingUnits.content};
`,
);

export default function Header({ color }: { color: 'white' | 'indigo' }) {
  const { loginWithRedirect } = useLoginWithRedirect();

  return (
    <HeaderWrapper color={color}>
      <div className="u-flex u-flex-space-between u-flex-align-center">
        <span>
          <CrowdWorksLogo style={{ height: '50px', width: '50px' }} />
          <Hidden xsDown>
            <span style={{ fontSize: '18px' }} className="u-half-spacing-left text-font-text text-weight-medium">
              CrowdWorks
            </span>
          </Hidden>
          <Hidden smDown>
            <span className="u-content-spacing-x">|</span>
            <a className={color === 'white' ? 'text-link' : 'text-link-white'} href="https://crowdworks.it">
              Learn more &rarr;
            </a>
          </Hidden>
        </span>
        <ButtonList>
          <Button
            color={color === 'white' ? 'indigo' : 'white'}
            kind="tertiary"
            onClick={() => loginWithRedirect('signup')}
          >
            Sign up
          </Button>
          <Button
            color={color === 'white' ? 'blue' : 'white'}
            kind="secondary"
            onClick={() => loginWithRedirect('login')}
          >
            Log in
          </Button>
        </ButtonList>
      </div>
    </HeaderWrapper>
  );
}
