import React, { FocusEventHandler } from 'react';
import currencyMap, { getCurrencySymbol } from 'util/currency';
import Label from '../Label';
import Select from 'ui/elements/form/Select/Select';
import DecimalInput from './DecimalInput';
import classNames from 'classnames';
import styles from './styles.scss';

interface Props {
  className?: string;
  label?: string;
  currency: string;
  onCurrencyChange: (value: string) => void;

  from: {
    onChange: (value: string) => void;
    onBlur?: FocusEventHandler;
    onFocus?: FocusEventHandler;
    name: string;
    value?: string | number;
    placeholder: string;
    error?: string;
    touched?: boolean;
  };

  to: {
    onChange: (value: string) => void;
    onBlur?: FocusEventHandler;
    onFocus?: FocusEventHandler;
    name: string;
    value?: string | number;
    placeholder: string;
    error?: string;
    touched?: boolean;
  };
}

export default function CurrencyRangeInput(props: Props) {
  const currencyOptions: { value: string; label: string }[] = Object.keys(currencyMap).map(v => ({
    value: v,
    label: v,
  }));

  return (
    <div className={classNames(styles.currencyRangeGrid, props.className)}>
      {props.label && (
        <Label htmlFor={props.from.name} style={{ gridArea: 'inputLabel' }} id="currencyRangeInputLabel">
          {props.label}
        </Label>
      )}

      <Select
        id={`${props.from.name}-currency`}
        style={{ gridArea: 'currency' }}
        name={`${props.from.name}-currency`}
        placeholder="Select currency"
        onChange={v => props.onCurrencyChange(v?.value ?? '')}
        value={{ value: props.currency, label: getCurrencySymbol(props.currency) }}
        options={currencyOptions}
        getOptionLabel={option => option.label ?? ''}
        className={styles.currencySelect}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
      <DecimalInput
        style={{ gridArea: 'inputFrom' }}
        ariaLabelledBy="currencyRangeInputLabel"
        name={props.from.name}
        value={props.from.value}
        onChange={props.from.onChange}
        onBlur={props.from.onBlur}
        onFocus={props.from.onFocus}
        error={!!props.from.error}
        placeholder={props.from.placeholder}
        helperText={props.from.error}
        className={styles.textInput}
      />
      <span
        className="u-half-spacing-x u-flex-align-center"
        style={{ minHeight: 43, gridArea: 'dash', justifySelf: 'center', marginBottom: 'auto' }}
      >
        -
      </span>
      <DecimalInput
        style={{ gridArea: 'inputTo' }}
        ariaLabelledBy="currencyRangeInputLabel"
        name={props.to.name}
        value={props.to.value}
        onChange={props.to.onChange}
        onBlur={props.to.onBlur}
        onFocus={props.from.onFocus}
        error={!!props.to.error}
        placeholder={props.to.placeholder}
        helperText={props.to.error}
      />
    </div>
  );
}
