import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function StatsIcon(props: IconProps) {
  return (
    <SvgIcon viewBox="0 0 21 21" {...props}>
      <path d="M3.4165 16.7744H16.7498" />
      <path d="M5.08301 16.7744L5.08301 7.60775H8.41634V16.7744" />
      <path d="M8.4165 16.7744V4.27441H11.7498V16.7744" />
      <path d="M11.7495 16.7744V10.1077H15.0828V16.7744" />
    </SvgIcon>
  );
}
