import { swrOptions } from 'apis/ApiBase';
import { postFetcher } from 'apis/utils';
import useSWR from 'swr';
import { List } from 'types/api';
import { ICompany } from 'types/company';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

// This hook should only fetch profiles it doesn't already have and always have previously fetched profiles available
export const useCompanyProfiles = (companyIds: number[]) => {
  const swrResult = useSWR<List<ICompany>>([companiesAPIUrls.companyProfiles.list(), { companyIds }], postFetcher, {
    ...swrOptions,
    keepPreviousData: true, // This doesn't make that much sense, but the UX is slightly better.
  });

  return useSWRResource(swrResult);
};
