import React from 'react';
import styles from './styles.scss';

import cx from 'classnames';

interface Props {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export default function ActionBarButton({ className, disabled, onClick, style, children }: Props) {
  return (
    <button className={cx(styles.actionBarButton, className)} disabled={disabled} onClick={onClick} style={style}>
      {children}
    </button>
  );
}
