import styled from '@emotion/styled';
import { alpha } from '@mui/material';
import { IframelyResponse } from 'apis/iframelyApi';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import CloseIcon from '../icons/CloseIcon';
import IconButton from '../icons/IconButton';
import styles from './styles.scss';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import { isInternalUrl, isReadableText } from 'util/stringUtils';
import { useHistory } from 'react-router-dom';
import RedirectDialog from 'ui/modules/dialogs/RedirectInterceptDialog';
import { useCloseableMessage } from 'apis/CompanyAPI/users/useUiState';

// should return the sitename and domain from a url
// e.g:
// https://news.ycombinator.com/item?id=35166874 -> news.ycombinator.com
// https://www.vg.no/rampelys/musikk/i/ve9qAl/durek-verrett-har-startet-boyband -> vg.no

function getSiteAndDomain(url: string) {
  const r = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/;
  const match = url.match(r);
  if (match?.[1]) return match?.[1];
  if (match?.[0]) return match?.[0];

  return url;
}

const CloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <div
      className="u-half-padding-right u-flex-align-center"
      style={{ position: 'absolute', right: '0px', height: '100%' }}
    >
      <IconButton
        size="small"
        onClick={e => {
          e.preventDefault();
          onClose();
        }}
        color="white"
        hoverColor="white"
        sx={{
          backgroundColor: alpha(bluePlanetTheme.bluePlanetPalette.indigo.main, 0.3),
          '&:hover': {
            backgroundColor: alpha(bluePlanetTheme.bluePlanetPalette.indigo.main, 0.6),
          },
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

const Image = styled.img`
  display: block;
  object-fit: cover;
  object-position: center center;
`;

const VerticalImage = styled(Image)`
  max-width: 35%;
  max-height: 160px;
  border-top-left-radius: ${bluePlanetTheme.shape.borderRadius}px;
  border-bottom-left-radius: ${bluePlanetTheme.shape.borderRadius}px;
`;

const HorizontalImage = styled(Image)`
  max-height: 100px;
  width: 100%;
  border-top-left-radius: ${bluePlanetTheme.shape.borderRadius}px;
  border-top-right-radius: ${bluePlanetTheme.shape.borderRadius}px;
`;

const Description = styled.div`
  background-color: ${bluePlanetTheme.bluePlanetPalette.grey.light};
  padding: ${halfSpacing} ${contentSpacing};
  flex-grow: 1;
  width: 65%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-top-right-radius: ${bluePlanetTheme.shape.borderRadius}px;
  border-bottom-right-radius: ${bluePlanetTheme.shape.borderRadius}px;

  ${bluePlanetTheme.breakpoints.down('sm')} {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-left-radius: ${bluePlanetTheme.shape.borderRadius}px;
  }
`;

const LinkWrapper = styled.button`
  display: flex;
  width: 100%;
  text-align: left;
  align-items: stretch;

  ${bluePlanetTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export default function LinkPreview({ meta, onClose }: { meta: IframelyResponse; onClose?: () => void }) {
  const history = useHistory();
  const thumbnail = meta.links.thumbnail?.[0];
  const favico = meta.links.icon?.[0];
  const [externalRedirectHref, setExternalRedirectHref] = useState<string | undefined>();

  const {
    isClosed: hasOptedOutOfExternalLinkInterception,
    closeMessage: setHasOptedOutOfExternalLinkInterception,
    isLoading,
  } = useCloseableMessage(`has-opted-out-of-external-link-interception`);

  const [checkboxState, setCheckboxState] = useState<{ isTouched: boolean; value?: boolean }>({
    isTouched: false,
    value: hasOptedOutOfExternalLinkInterception,
  });

  useEffect(() => {
    setCheckboxState({ isTouched: checkboxState.isTouched, value: hasOptedOutOfExternalLinkInterception });
  }, [isLoading]);

  const onClickLink = () => {
    if (!isInternalUrl(window.location.host, meta.meta.canonical) && !hasOptedOutOfExternalLinkInterception) {
      setExternalRedirectHref(meta.meta.canonical);
    } else if (!isInternalUrl(window.location.host, meta.meta.canonical)) {
      window.open(meta.meta.canonical, '_blank');
    } else {
      const url = new URL(meta.meta.canonical).pathname;
      history.push(url);
    }
  };

  // Preview with image
  if (thumbnail) {
    return (
      <>
        <div style={{ position: 'relative' }}>
          {onClose && <CloseButton onClose={onClose} />}
          <LinkWrapper onClick={onClickLink}>
            <HorizontalImage
              className="u-mobile-down-only"
              src={thumbnail.href}
              // Hide image if it fails to load
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.style.display = 'none';
              }}
            />
            <VerticalImage
              className="u-mobile-up-only"
              src={thumbnail.href}
              // Hide image if it fails to load
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.style.display = 'none';
              }}
            />
            <Description>
              <div>
                <h5 className={classNames(styles.previewHeader, 'text-h5')}>{meta.meta.title}</h5>
                {meta.meta.description && isReadableText(meta.meta.description) && (
                  <p className={classNames(styles.previewDescription, 'text-small u-quarter-spacing-top')}>
                    {meta.meta.description}
                  </p>
                )}
                <div className="text-link-small u-quarter-spacing-top">{getSiteAndDomain(meta.meta.canonical)}</div>
              </div>
            </Description>
          </LinkWrapper>
        </div>
        {externalRedirectHref && (
          <RedirectDialog
            externalRedirectHref={externalRedirectHref}
            setExternalRedirectHref={setExternalRedirectHref}
            checkboxState={checkboxState.value ?? false}
            setCheckboxState={value => setCheckboxState({ isTouched: true, value })}
            setHasOptedOutOfExternalLinkInterception={() => {
              if (checkboxState.isTouched && checkboxState.value === true) setHasOptedOutOfExternalLinkInterception();
            }}
          />
        )}
      </>
    );
  }

  // Preview without image
  return (
    <>
      <div style={{ position: 'relative' }}>
        {onClose && <CloseButton onClose={onClose} />}
        <LinkWrapper onClick={onClickLink}>
          <Description style={{ width: '100%' }}>
            <div className="u-flex u-flex-align-center">
              {favico && <img width={18} height={18} className="u-half-spacing-right" src={favico.href} />}
              <h5 className={(classNames(styles.previewHeader), 'text-h5')}>{meta.meta.title}</h5>
            </div>
            {meta.meta.description && isReadableText(meta.meta.description) && (
              <p className={classNames(styles.previewDescription, 'text-small u-quarter-spacing-top')}>
                {meta.meta.description}
              </p>
            )}
            <div className="text-link-small u-quarter-spacing-top">{getSiteAndDomain(meta.meta.canonical)}</div>
          </Description>
        </LinkWrapper>
      </div>
      {externalRedirectHref && (
        <RedirectDialog
          externalRedirectHref={externalRedirectHref}
          setExternalRedirectHref={setExternalRedirectHref}
          checkboxState={checkboxState.value ?? false}
          setCheckboxState={value => setCheckboxState({ isTouched: true, value })}
          setHasOptedOutOfExternalLinkInterception={() => {
            if (checkboxState.isTouched && checkboxState.value === true) setHasOptedOutOfExternalLinkInterception();
          }}
        />
      )}
    </>
  );
}
