import cx from 'classnames';
import React, { CSSProperties } from 'react';
interface Props {
  icon: JSX.Element;
  className?: string;
  children: React.ReactNode;
  style?: CSSProperties;
}

export default function Action({ className, icon, children, ...rest }: Props) {
  return (
    <span
      style={{ whiteSpace: 'nowrap' }}
      {...rest}
      className={cx('text-link-small u-flex u-flex--align-items-center', className)}
    >
      {icon}
      <span className="u-quarter-spacing-left">{children}</span>
    </span>
  );
}
