import { IFRAMELY_API_KEY } from 'util/constants';

import ApiBase from './ApiBase';

export interface IframelyResponse {
  html: string;
  meta: { description?: string; title: string; medium?: string; canonical: string; site?: string };
  links: {
    thumbnail?: { href: string; type: string; media: { width: number; height: number } }[];
    icon?: { href: string }[];
  };
}

export const IframelyApi = () => {
  const api = ApiBase();

  return {
    get: (url: string) =>
      api.get<IframelyResponse>(
        `https://iframe.ly/api/iframely?url=${encodeURI(
          url,
        )}&api_key=${IFRAMELY_API_KEY}&iframe=1&omit_css=1&omit_script=1`,
      ),
  };
};
