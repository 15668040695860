import React from 'react';
import { DashboardMessage as DashboardMessageDTO } from 'apis/DashboardAPI';
import { CardGridWithLimit } from 'ui/views/cards/CardGrid';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';

import { CompanyMessages } from './CompanyMessages';

export const AgendaCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const UpdateButton = styled.a`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  &:hover {
    background-color: ${bluePlanetTheme.bluePlanetPalette.grey.light};
  }
`;

interface Props {
  messages: DashboardMessageDTO[];
}

export default function OnYourCompanyAgenda({ messages }: Props) {
  return (
    <div className={messages.length > 0 ? 'u-section-spacing-bottom' : undefined}>
      <CardGridWithLimit
        mobile={{ visibleCardsCount: 2, columnCount: 1 }}
        tablet={{ visibleCardsCount: 2, columnCount: 2 }}
        desktop={{ visibleCardsCount: 3, columnCount: 3 }}
        xl={{ visibleCardsCount: 3, columnCount: 3 }}
        xxl={{ visibleCardsCount: 3, columnCount: 3 }}
      >
        <CompanyMessages messages={messages} />
      </CardGridWithLimit>
    </div>
  );
}
