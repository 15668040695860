import React from 'react';
import { Involvement } from 'types/user';
import { spacingUnits } from 'ui/theme/themeBluePlanet';
import styled from '@emotion/styled';
import { involvementLabels } from '../UserInvolvementForm';

const List = styled.ul`
  padding-left: ${spacingUnits.half};
  & > li {
    padding: ${spacingUnits.half} ${spacingUnits.half} ${spacingUnits.quarter} 0;
  }
`;

interface Props {
  involvement: Involvement;
}

export default function InvolvementList({ involvement }: Props) {
  if (!involvement || !Object.values(involvement).includes(true)) {
    return null;
  }

  return (
    <>
      <div className="u-half-spacing-top-reset">
        <List>
          {involvement.provideGeneralFeedback && (
            <li className="text-body">{involvementLabels.provideGeneralFeedback}</li>
          )}
          {involvement.openForBoardMemberPosition && (
            <li className="text-body">{involvementLabels.openForBoardMemberPosition}</li>
          )}
          {involvement.openForAdvisoryBoardPosition && (
            <li className="text-body">{involvementLabels.openForAdvisoryBoardPosition}</li>
          )}
          {involvement.openForMentorPosition && (
            <li className="text-body">{involvementLabels.openForMentorPosition}</li>
          )}
          {involvement.openForSweatEquityProjects && (
            <li className="text-body">{involvementLabels.openForSweatEquityProjects}</li>
          )}
        </List>
      </div>
    </>
  );
}
