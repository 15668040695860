import SvgIcon, { IconProps } from '../SvgIcon';
import React from 'react';

export default function GifIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3.5 13.39v-.8c0-2.98 0-4.46.47-5.64a7.24 7.24 0 0 1 4.37-4.12c1.26-.44 2.83-.44 5.98-.44 1.8 0 2.7 0 3.42.25a4.14 4.14 0 0 1 2.5 2.35c.26.68.26 1.53.26 3.22V13.4" />
      <path d="M3.5 12.39c0-1.84 1.5-3.34 3.33-3.34.67 0 1.45.12 2.1-.05.58-.16 1.03-.6 1.18-1.18.17-.65.06-1.43.06-2.1 0-1.84 1.49-3.33 3.33-3.33" />
      <path d="M16.03 22.24v-2.4m0 0v-2.4c0-.88.07-1.2.98-1.2h2m-2.98 3.6h2.47" />
      <path d="M12.4 16.24v6M8.5 16.42a2.81 2.81 0 0 0-1.43-.14c-1.57.37-2.13 1.5-2.13 2.9 0 1.99.49 3.03 3.56 2.86v-2.16H7.15" />
    </SvgIcon>
  );
}
