import React, { useRef, useState } from 'react';
import useNotify from 'hooks/useNotify';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Button from 'ui/elements/buttons/Button';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { Organization } from 'types/organization';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';
import ImageUpload from 'ui/modules/ImageUpload';
import { BANNER_IMAGE_ASPECT_RATIO } from 'domain/shared/Branding/BrandingForm';
import { ContentAPI } from 'apis/ContentAPI';
import config from 'config';
import CtaButton from 'ui/elements/buttons/CtaButton';
import { LogoHeader } from 'domain/companies/profile/header';
import { resize } from 'util/cloudinary';
import InlineEditableField from 'ui/elements/form/InlineEditableField';
import TextField from 'ui/elements/form/TextField';
import { MISSION_MAX_LENGTH } from 'util/constants';
import HorizontalDictionary from 'ui/elements/dictionary/HorizontalDictionary';
import useWindowWidth from 'hooks/useWindowWidth';
import { bluePlanetTheme } from 'ui/theme';
import LocationInput from 'domain/shared/Location/LocationInput';
import { formatShortLocation } from 'util/locationUtils';
import ChipList from 'ui/elements/Chip/ChipList';
import TruncatedList from 'util/components/TruncatedList';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import AiMenu from 'ui/domain/AiMenu/AiMenu';
import SkillChip from 'ui/domain/Chips/SkillChip';

export default function OrganizationProfileForm({
  organization,
  onComplete,
  onClose,
  onBack,
}: {
  organization: Organization;
  onComplete: () => void;
  onClose: () => void;
  onBack: () => void;
}) {
  const notify = useNotify();

  const api = ContentAPI(`${config.CONTENT_API_URL}`);

  const aiRef = useRef<HTMLDivElement>(null);

  const [updatedProfile, setUpdatedProfile] = useState<Organization>(organization);

  const width = useWindowWidth();
  const dictionaryColumnCount =
    width < bluePlanetTheme.breakpoints.values.sm ? 1 : width < bluePlanetTheme.breakpoints.values.md ? 2 : 4;
  const dictInputWidth = width < bluePlanetTheme.breakpoints.values.md ? '100%' : '260px';

  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(organization, {
    onSuccess: () => {
      onComplete();
    },
    onFailure() {
      notify('error', 'Failed to update organization profile');
    },
  });

  return (
    <>
      <Content className="u-flex u-flex--column u-flex-align-center">
        <div style={{ width: '100%' }}>
          <ImageUpload
            width={0}
            imageUrl={updatedProfile.bannerUrl}
            aspectRatio={BANNER_IMAGE_ASPECT_RATIO}
            onUploadSuccess={async image => {
              setUpdatedProfile({ ...updatedProfile, bannerUrl: image.secure_url });
            }}
            onUploadFailure={() => {
              notify('error', 'Upload failed. Only jpeg & png images are allowed.');
            }}
            getUploadUrl={filename => api.images.getOrganizationUploadUrl(organization.id, filename)}
            placeholder={(openDropzone, height) => (
              <CtaButton
                onClick={openDropzone}
                style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                height={height}
              >
                Add a banner image
              </CtaButton>
            )}
          />
          <LogoHeader className="u-flex u-content-spacing-top text-h4">
            <ImageUpload
              width={80}
              aspectRatio={1}
              onUploadSuccess={async image => {
                setUpdatedProfile({ ...updatedProfile, logoUrl: image.secure_url });
              }}
              onUploadFailure={() => {
                notify('error', 'Upload failed. Only jpeg & png images are allowed.');
              }}
              getUploadUrl={filename => api.images.getOrganizationUploadUrl(organization.id, filename)}
              imageUrl={resize(updatedProfile.logoUrl, { width: 500, height: 500 })}
              shape="round"
              placeholder={(openDropzone, height) => (
                <CtaButton onClick={openDropzone} height={height} shape="circle">
                  Add logo
                </CtaButton>
              )}
            />
            {organization.name}
          </LogoHeader>
          <HorizontalDictionary columnCount={dictionaryColumnCount} className="u-content-spacing-top">
            {[
              {
                key: 'Est',
                value: (
                  <InlineEditableField
                    label="Add established"
                    defaultValue={updatedProfile.established ?? ''}
                    onConfirm={value => setUpdatedProfile({ ...updatedProfile, established: value })}
                    renderInputComponent={(onChange, value) => (
                      <TextField
                        autoFocus
                        placeholder='e.g. "2015"'
                        type="number"
                        value={value}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                      />
                    )}
                    valueComponent={<>{updatedProfile.established}</>}
                  />
                ),
              },
              {
                key: 'Location',
                value: (
                  <InlineEditableField
                    label="Add location"
                    defaultValue={updatedProfile.location}
                    onConfirm={value => setUpdatedProfile({ ...updatedProfile, location: value })}
                    inputWrapperStyle={{ width: dictInputWidth }}
                    renderInputComponent={onChange => (
                      <LocationInput
                        style={{ width: '100%' }}
                        name="location"
                        placeholder="Search for location"
                        autoComplete="address-level2"
                        searchArea="places"
                        autoFocus
                        onChange={v => onChange(v ? formatShortLocation(v) : undefined)}
                      />
                    )}
                    valueComponent={<>{updatedProfile.location}</>}
                  />
                ),
              },
              {
                key: 'Organization type',
                value: organization.types.join(', '),
              },
              {
                key: 'Website',
                value: (
                  <InlineEditableField
                    label="Website"
                    defaultValue={updatedProfile.website ? `${updatedProfile.website}` : ''}
                    onConfirm={value => setUpdatedProfile({ ...updatedProfile, website: value })}
                    renderInputComponent={(onChange, value) => (
                      <TextField
                        autoFocus
                        type="url"
                        value={value}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                      />
                    )}
                    valueComponent={<>{updatedProfile.website}</>}
                  />
                ),
              },
            ]}
          </HorizontalDictionary>
          <HorizontalDictionary columnCount={2} className="u-content-spacing-top">
            {[
              {
                key: 'Services',
                value: (
                  <ChipList>
                    <TruncatedList
                      values={organization.services}
                      limit={2}
                      renderItem={label => <SkillChip label={label.name} />}
                      renderExpandButton={(label, onClick) => <SkillChip onClick={onClick} label={label.name} />}
                    />
                  </ChipList>
                ),
              },
              {
                key: 'Industries',
                value: (
                  <ChipList>
                    <TruncatedList
                      values={organization.industries.map(i => ({ name: i })) || []}
                      limit={2}
                      renderItem={label => <IndustryChip label={label.name} />}
                      renderExpandButton={(label, onClick) => <IndustryChip onClick={onClick} label={label.name} />}
                    />
                  </ChipList>
                ),
              },
            ]}
          </HorizontalDictionary>
          <div className="u-content-spacing-top">
            <InlineEditableField
              label="Add a short presentation"
              defaultValue={updatedProfile.description || ''}
              buttonStyle={{ height: '100px' }}
              onConfirm={value => setUpdatedProfile({ ...updatedProfile, description: value })}
              disableEnterKey
              renderInputComponent={(onChange, value) => (
                <div className="u-flex u-flex--column u-flex-grow">
                  <TextField
                    inputProps={{ style: { lineHeight: '25px' } }}
                    autoFocus
                    maxLength={MISSION_MAX_LENGTH}
                    multiline
                    minRows={4}
                    maxRows={20}
                    suffixPosition="start-bottom"
                    value={value}
                    suffix={
                      <AiMenu
                        companyId={undefined}
                        organizationId={organization.id}
                        input={value || ''}
                        maxLength={700}
                        onReplace={v => {
                          onChange(v);
                        }}
                        aiRef={aiRef}
                      />
                    }
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                  />
                  <div ref={aiRef} />
                </div>
              )}
              valueComponent={
                <div style={{ minHeight: '100px' }} className="text-body">
                  {updatedProfile.description}
                </div>
              }
            />
          </div>
        </div>
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button
            isLoading={isUpdatingOrganization}
            onClick={() =>
              updateOrganization({
                bannerUrl: updatedProfile.bannerUrl,
                logoUrl: updatedProfile.logoUrl,
                description: updatedProfile.description,
                established: updatedProfile.established,
                location: updatedProfile.location,
                website: updatedProfile.website,
              })
            }
            kind="primary"
          >
            Finish
          </Button>
          <Button onClick={onBack} kind="primary" color="greyLight">
            Back
          </Button>
        </ButtonList>
        <Button onClick={onClose} kind="tertiary" color="grey">
          Pick it up later
        </Button>
      </DialogActions>
    </>
  );
}
