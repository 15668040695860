import useAccordionHandler from 'hooks/useAccordionHandler';
import React from 'react';
import InfoCard from 'ui/views/cards/InfoCard';

export function Info() {
  const accordionProps = useAccordionHandler(false);
  return (
    <InfoCard className="u-content-spacing-top">
      Please select who can view the folder and its contents.
      {accordionProps.isExpanded ? (
        <div>
          <p>
            Folders inherit audiences from their parent. You may add new audiences, but you cannot remove audiences from
            the parent folder. If you need to do so, create a new folder in a parent folder with the desired access
            rights.
          </p>
          <a
            href=""
            className="text-link"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              accordionProps.collapse();
            }}
          >
            Close information
          </a>
        </div>
      ) : (
        <div>
          <a
            href=""
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              accordionProps.expand();
            }}
            className="text-link"
          >
            Read more
          </a>
        </div>
      )}
    </InfoCard>
  );
}
