import React from 'react';
import Chip from 'ui/elements/Chip';

interface Props {
  label: string;
  className?: string;
  onDelete?: () => void;
  onClick?: () => void;
}

export default function BusinessDomainChip(props: Props) {
  return <Chip color="grey-medium" {...props} />;
}
