import ChevronLeftRounded from 'ui/elements/icons/ChevronLeftIcon';
import React from 'react';
import HeadTags from 'ui/views/layouts/BaseLayout/PageTitle';
import internalServerError from 'ui/illustrations/mission_impossible.svg';
import { ContactUsIfProblem } from 'util/i18n';
import ScrollableBody from 'ui/views/layouts/BaseLayout/ScrollableBody';

export default function ErrorPage() {
  return (
    <>
      <HeadTags title="Oops, 500 internal server error" />
      <ScrollableBody accountForMobileHeader>
        <div className="u-margin-x-auto u-align-center u-section-spacing-y">
          <div className="u-section-spacing-y"></div>
          <h1 className="text-h2">Oops, this is unfortunate.</h1>
          <div className="u-content-spacing-top">
            <h2 className="text-h3">Looks like something went wrong.</h2>
          </div>
          <p className="text-body u-content-spacing-top">
            The error has been reported and we will be working hard to fix it.
          </p>
          <p className="text-body">
            <ContactUsIfProblem />
          </p>
          <p className="u-section-spacing-top u-content-spacing-bottom u-flex-center">
            <a href="/" className="text-link" style={{ display: 'flex', alignItems: 'center' }}>
              <ChevronLeftRounded className="u-half-spacing-right" />
              Take me back home
            </a>
          </p>
          <img
            style={{ marginTop: '5rem', height: 'auto', maxWidth: 240, width: '100%', display: 'block' }}
            src={internalServerError}
            alt="Oops, we encountered an error on our end"
            className="u-margin-x-auto u-section-spacing-y"
          />
        </div>
      </ScrollableBody>
    </>
  );
}
