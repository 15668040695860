import React, { useState } from 'react';
import { emailIsValid } from 'util/stringUtils';
import TextField from 'ui/elements/form/TextField';
import TruncatedList from 'util/components/TruncatedList';
import Chip from 'ui/elements/Chip';

export function EmailInput({
  autoFocus,
  validEmails,
  setValidEmails,
}: {
  autoFocus?: boolean;
  validEmails: string[];
  setValidEmails: (emails: string[]) => void;
}) {
  const [inputValue, setInputValue] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  const onUserEmailPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const list = e.clipboardData
      .getData('text')
      .replace(/[\r\n;\s]+/g, ',')
      .split(',')
      .filter(email => !validEmails.find(inv => inv === email) && emailIsValid(email));
    setValidEmails([...validEmails, ...list]);
    e.preventDefault();
  };
  return (
    <div className="u-flex  u-flex--column" style={{ width: '100%' }}>
      <TextField
        autoFocus={autoFocus}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        placeholder="Add or paste emails, seperate with comma"
        error={isInvalidEmail}
        helperText={isInvalidEmail ? 'Please enter a valid email address' : ''}
        onPaste={onUserEmailPaste}
        onBlur={() => {
          if (emailIsValid(inputValue)) {
            setValidEmails([...validEmails, inputValue]);
            setInputValue('');
          } else if (inputValue.trim() !== '') {
            setIsInvalidEmail(true);
          }
        }}
        onKeyDown={e => {
          setIsInvalidEmail(false);
          const validKeys = ['Enter', ','];
          if (!validKeys.includes(e.key)) {
            return;
          }
          e.preventDefault();
          if (emailIsValid(inputValue)) {
            setValidEmails([...validEmails, inputValue]);
            setInputValue('');
          } else {
            setIsInvalidEmail(true);
          }
        }}
      />
      {validEmails.length > 0 && (
        <div className="u-half-spacing-top" style={{ display: 'flex', flexWrap: 'wrap', gap: '0.625rem' }}>
          <TruncatedList
            renderExpandButton={(value, onClick) => <Chip color="blue-light" label={value.name} onClick={onClick} />}
            limit={10}
            renderItem={item => (
              <Chip
                color="blue-light"
                label={item.name}
                onDelete={() => setValidEmails(validEmails.filter(e => e !== item.name))}
              />
            )}
            values={validEmails.map(email => ({ name: email }))}
          />
        </div>
      )}
    </div>
  );
}
