import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'ui/views/cards/Card';
import CardHeading from 'ui/views/cards/CardHeading';
import { communityUrls } from 'urls';
import { pluralize } from 'util/stringUtils';
import Chip from 'ui/elements/Chip';
import styled from '@emotion/styled';
import CompanyMastersOnlyTooltip from 'ui/domain/tooltips/CompanyMastersOnlyTooltip';

interface Props {
  communitySlug: string;
  users: number;
  companies: number;
  showToolTip?: boolean;
  className?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

function getLink(pendingCompanies: number, communitySlug: string) {
  if (pendingCompanies > 0) return communityUrls.manageMembers.companies(communitySlug, 'Pending');
  else return communityUrls.manageMembers.users(communitySlug, 'requests');
}

export default function CommunityRequests({ communitySlug, users, companies, showToolTip = false, className }: Props) {
  if (users === 0 && companies === 0) {
    return null;
  }

  return (
    <Card className={className}>
      <Container>
        <CardHeading
          heading={`Pending ${pluralize(users + companies, 'request')}`}
          tooltip={showToolTip && <CompanyMastersOnlyTooltip title="Only visible for community admins" />}
        />
        <div className="text-body u-content-spacing-bottom">
          {'You have '}
          {users > 0 && (
            <>
              <Chip
                style={{ verticalAlign: '2px' }}
                isRounded
                label={`${users} ${pluralize(users, 'user')}`}
                color="orange"
              />
            </>
          )}
          {companies > 0 && users > 0 && ' and '}
          {companies > 0 && (
            <>
              <Chip
                style={{ verticalAlign: '2px' }}
                isRounded
                label={`${companies} ${pluralize(companies, 'company', 'companies')}`}
                color="yellow"
              />
            </>
          )}
          {' requesting to join your community - they are waiting for your review.'}
        </div>
        <Link className="text-link" to={getLink(companies, communitySlug)}>
          Go to requests
        </Link>
      </Container>
    </Card>
  );
}
