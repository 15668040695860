import useSWR from 'hooks/useSWR';
import { UserProfilesSummaryDTO } from 'types/company';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

export const useCompanyUserSummary = (companyId?: number) => {
  const swrResult = useSWR<UserProfilesSummaryDTO>(
    companyId != null ? companiesAPIUrls.getUserSummary(companyId) : undefined,
  );

  return useSWRResource(swrResult);
};
