import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';

type Props = {
  title: NonNullable<React.ReactNode>;
  color?: 'dark' | 'light';
  placement?: TooltipProps['placement'];
  PopperProps?: TooltipProps['PopperProps'];
  arrow?: boolean;
  disableTouchListener?: TooltipProps['disableTouchListener'];
  disableFocusListener?: TooltipProps['disableFocusListener'];
  children: TooltipProps['children'];
} & TooltipProps;

export default function Tooltip(props: Props) {
  return props.color === 'light' ? (
    <MuiTooltip
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: bluePlanetTheme.bluePlanetPalette.grey.light,
            color: bluePlanetTheme.bluePlanetPalette.indigo.main,
          },
        },
      }}
    />
  ) : (
    <MuiTooltip {...props} />
  );
}
