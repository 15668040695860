import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import { userUrls } from 'urls';

export default function ConsentsDialog({
  open,
  onClose,
  onSuccess,
  isSaving,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  isSaving: boolean;
}) {
  return (
    <Dialog open={open}>
      <img
        style={{ width: '100%', height: 'auto' }}
        src="https://res.cloudinary.com/crowdworks/image/upload/v1698240274/news/Ai-2x.png"
      />
      <Title paddingY="half">Introducing CrowdWorks AI</Title>
      <Content>
        CrowdWorks AI is a generative assistant which uses natural language processing to get you from a blank input
        field to a great draft in a blink. By using CrowdWorks AI, you agree to the{' '}
        <Link className="text-link" target="_blank" to={userUrls.legal.privacyPolicy('artificial-intelligence')}>
          Content Generation Terms
        </Link>{' '}
        and that you are responsible for any text you use.
      </Content>
      <DialogActions>
        <ButtonList>
          <Button kind="primary" onClick={onSuccess} isLoading={isSaving}>
            OK
          </Button>
          <Button kind="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonList>
      </DialogActions>
    </Dialog>
  );
}
