import React from 'react';
import Chip from 'ui/elements/Chip';

interface Props {
  label: string;
  className?: string;
  onDelete?: () => void;
}

export default function UserChip(props: Props) {
  return <Chip color="blue-light" {...props} />;
}
