import React, { useState } from 'react';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import TextField from 'ui/elements/form/TextField';
import Button from 'ui/elements/buttons/Button';
import i18n from 'util/i18n';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import Checkbox from 'ui/elements/form/choice/Checkbox';

import { FeedbackContext } from 'types/user';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  feedbackContext: FeedbackContext;
}

export default function FeedbackDialog({ onClose, isOpen, feedbackContext }: Props) {
  const notify = useNotify();

  const [feedback, setFeedback] = useState<string>('');
  const [isAnonymous, setIsAnonymous] = useState(false);

  const [sendFeedback, isSaving] = useLazyResource(
    () =>
      usersApi.feedback({
        feedback: feedback,
        anonymous: isAnonymous,
        feedbackContext: feedbackContext,
      }),
    {
      onSuccess: () => {
        notify('success', 'Feedback sent!');
        onClose();
      },
      onFailure: () => notify('error', i18n('en').errors.save('feedback')),
    },
  );

  const close = () => {
    setFeedback('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={close} backdropColor="light">
      <>
        <Title
          onClose={close}
          subheading={
            'Please give a description of what you want to share. All feedback is relevant to us, and we value all comments, so do not hesitate to elaborate.'
          }
        >
          Do you have feedback or questions?
        </Title>
        <Content>
          <TextField
            className="u-content-spacing-bottom"
            autoFocus
            name="title"
            placeholder="Write your feedback"
            onChange={e => setFeedback(e.target.value)}
            value={feedback}
            multiline
            minRows={4}
          />
          <Checkbox
            checked={isAnonymous}
            onChange={() => setIsAnonymous(!isAnonymous)}
            fontSize="small"
            label="Make my feedback anonymous. If you want our response to your feedback, please leave this checkmark open."
          />
        </Content>
        <DialogActions>
          <Button isLoading={isSaving} onClick={sendFeedback} kind="primary">
            Send
          </Button>

          <Button onClick={close} kind="tertiary">
            Cancel
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
