import DocumentAPI from 'apis/DocumentAPI';
import { uploadFileToGCP } from 'domain/shared/Files/actions';

import { DocumentType, FolderDTO } from 'types/documents';

export function uploadDocument(companyId: number, folderId: FolderDTO['id'], documentType?: DocumentType) {
  return (document: File) =>
    uploadFileToGCP(
      document,
      () =>
        DocumentAPI.upload(companyId, folderId, {
          filename: document.name,
          documentType,
        }),
      (documentId: UUID) => DocumentAPI.deleteDocument(companyId, documentId),
    );
}
