import { FormikProps } from 'formik';
import React, { useRef } from 'react';
import { CompanyProfile } from 'types/company';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import Label from 'ui/elements/form/Label';
import CurrencyInput from 'ui/elements/form/numbers/CurrencyInput';
import SelectCloud, { toOption } from 'ui/elements/form/SelectCloud/SelectCloud';
import { notEmpty } from 'util/arrayUtils';
import { MarketPotentialFormInput } from './utils';
import AiMenu from 'ui/domain/AiMenu/AiMenu';

export type MarketPotentialField =
  | 'geographical-potential'
  | 'current-focus'
  | 'estimated-available'
  | 'estimated-serviceable'
  | 'description';

type FieldsProps = {
  company: CompanyProfile;
  handleChangeParent?: (e: React.ChangeEvent<any>) => void;
  setFieldValueParent?: (field: string, value: any) => void;
  autoFocusField?: MarketPotentialField;
} & FormikProps<MarketPotentialFormInput>;

export function MarketPotentialFields({
  company,
  autoFocusField,
  values,
  setFieldValue,
  setFieldValueParent,
  touched,
  errors,
  handleChange,
  handleChangeParent,
  handleBlur,
}: FieldsProps) {
  const geographicalPotentialSuggestions = ['Global', 'US', 'APAC', 'EU'];
  const currentFocusSuggestions = [company?.location?.country, 'US', 'UK'].filter(it => !!it);

  const setValue = (field: string, value: any) => {
    setFieldValue(field, value);
    setFieldValueParent && setFieldValueParent(field, value);
  };
  const aiRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Label>Geographical market potential</Label>
      <SelectCloud
        autoFocus={autoFocusField === 'geographical-potential'}
        className="u-content-spacing-bottom"
        options={geographicalPotentialSuggestions.map(toOption)}
        values={values.geographicalMarketPotential.map(toOption)}
        onSelect={v => {
          setValue('geographicalMarketPotential', [...values.geographicalMarketPotential, v.name]);
        }}
        onCustomInputSelect={v => {
          setValue('geographicalMarketPotential', [...values.geographicalMarketPotential, v]);
        }}
        onRemove={v =>
          setValue(
            'geographicalMarketPotential',
            values.geographicalMarketPotential.filter(it => it !== v.name),
          )
        }
        customInputErrorMessage="You need to write something to be able to add it as geographical potential"
        customInputPlaceholder="Which markets are potential growth areas?"
      />
      <Label>Current market focus</Label>
      <SelectCloud
        autoFocus={autoFocusField === 'current-focus'}
        className="u-content-spacing-bottom"
        options={currentFocusSuggestions.filter(notEmpty).map(toOption)}
        values={values.currentMarketFocus.map(toOption) ?? []}
        onSelect={v => setValue('currentMarketFocus', [...values.currentMarketFocus, v.name])}
        onCustomInputSelect={v => setValue('currentMarketFocus', [...values.currentMarketFocus, v])}
        onRemove={v =>
          setValue(
            'currentMarketFocus',
            values.currentMarketFocus.filter(it => it !== v.name),
          )
        }
        customInputErrorMessage="You need to write something to be able to add it as current focus"
        customInputPlaceholder="Which countries or areas are you targeting now?"
      />
      <div className="u-content-spacing-bottom">
        <CurrencyInput
          autoFocus={autoFocusField === 'estimated-available'}
          name="estimatedAvailableMarketValue"
          value={values.estimatedAvailableMarketValue}
          touched={touched.estimatedAvailableMarketValue}
          error={errors.estimatedAvailableMarketValue}
          onChange={v => setValue('estimatedAvailableMarketValue', v)}
          label="Estimated available market per year"
          placeholder="1 000 000"
          currency={values.estimatedMarketCurrency}
          onCurrencyChange={v => setValue('estimatedMarketCurrency', v)}
          nullable
        />
      </div>
      <div className="u-content-spacing-bottom">
        <CurrencyInput
          autoFocus={autoFocusField === 'estimated-serviceable'}
          name="estimatedServiceableMarketValue"
          value={values.estimatedServiceableMarketValue}
          touched={touched.estimatedServiceableMarketValue}
          error={errors.estimatedServiceableMarketValue}
          onChange={v => setValue('estimatedServiceableMarketValue', v)}
          label="Estimated serviceable market per year"
          placeholder="1 000 000"
          currency={values.estimatedMarketCurrency}
          onCurrencyChange={v => setValue('estimatedMarketCurrency', v)}
          nullable
        />
      </div>
      <FormikTextField
        formikProps={{
          values,
          touched,
          errors,
          handleChange: (e: React.ChangeEvent<any>) => {
            handleChange(e);
            handleChangeParent && handleChangeParent(e);
          },
          handleBlur,
        }}
        autoFocus={autoFocusField === 'description'}
        name="description"
        label="Description"
        multiline
        minRows={4}
        maxRows={20}
        inputProps={{ maxLength: 725 }}
        helperText="Understanding your market potential is important to investors, because it helps them estimate what the future market or potential for your product or service would look like."
        suffixPosition="start-bottom"
        suffix={
          <AiMenu
            companyId={company.id}
            organizationId={undefined}
            input={values.description || ''}
            onReplace={value => {
              setFieldValue('description', value);
            }}
            aiRef={aiRef}
          />
        }
      />
      <div ref={aiRef} />
    </>
  );
}
