import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';

interface Step {
  number: number;
  isActive: boolean;
}
interface Props {
  steps: Step[];
}

function ProgressStep({ step, currentStepNumber }: { step: Step; currentStepNumber: number }) {
  return (
    <div
      className={cx(styles.progressStep, {
        [styles.isActive]: step.isActive,
        [styles.isComplete]: step.number < currentStepNumber,
      })}
    ></div>
  );
}

export default function ProgressSteps(props: Props) {
  const { steps } = props;

  const currentStepNumber = steps.find(s => s.isActive)?.number ?? 0;

  return (
    <div>
      <div className={styles.progressSteps}>
        {steps.map(step => (
          <ProgressStep key={step.number} step={step} currentStepNumber={currentStepNumber} />
        ))}
      </div>
    </div>
  );
}
