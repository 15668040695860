import React from 'react';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import { Goal } from 'types/goals';
import ProgressConfirmDialog from 'ui/modules/dialogs/ProgressConfirmDialog';
import GoalsAPI from 'apis/GoalsAPI';
import useNotify from 'hooks/useNotify';
import useIO from 'util/resource/useLazyResource';
import { ProgressHandler } from 'hooks/useProgressHandler';

interface Props {
  companyId: number;
  goal: Goal;
  dialogHandler: DialogHandlerProps;
  onFinishGoalAction: () => void;
}

export default function DeleteGoalDialog(props: Props) {
  const { companyId, dialogHandler, goal, onFinishGoalAction } = props;

  const notify = useNotify();

  const [onSubmit] = useIO(
    async (progressHandler: ProgressHandler) => {
      progressHandler.onProgress();
      await GoalsAPI.goal(companyId).delete(goal.id);
      progressHandler.onCleanup('success');
    },
    {
      onSuccess: () => {
        notify('success', 'Goal deleted');
        onFinishGoalAction();
      },
      onFailure: error => notify('error', error || 'Something went wrong when trying to delete goal.'),
    },
  );

  return (
    <ProgressConfirmDialog
      maxWidth="sm"
      title="Delete goal"
      dismissLabel="Cancel"
      confirmLabel="Delete"
      confirmColor="red"
      dialogHandler={dialogHandler}
      onConfirm={onSubmit}
    >
      You are about to delete this goal. Once deleted, the goal may not be restored. If circumstances have lead to this
      goal no longer being relevant, consider archiving the goal instead.
    </ProgressConfirmDialog>
  );
}
