import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

type State = { state: 'fetching' } | { state: 'unauthenticated' } | { state: 'authenticated'; accessToken: string };

// use this hook if you want to do authenticated api calls
// outside of <AuthenticatedRoutes />

export default function useAccessToken() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const [accessToken, setToken] = useState<State>({ state: 'fetching' });
  useEffect(() => {
    (async () => {
      if (isLoading) setToken({ state: 'fetching' });
      if (!isAuthenticated) setToken({ state: 'unauthenticated' });
      const token = await getAccessTokenSilently();
      setToken({ state: 'authenticated', accessToken: token });
    })();
  }, [isAuthenticated, isLoading]);

  return [accessToken];
}

export function getAccessTokenOrUndefined(accessToken: State) {
  return accessToken.state === 'authenticated' ? accessToken.accessToken : undefined;
}
