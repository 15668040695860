import { swrOptions } from 'apis/ApiBase';
import useSwr, { SWRConfiguration, mutate } from 'swr';

export default function useSWR<T>(url?: string, fallbackData?: T, options?: SWRConfiguration) {
  return useSwr<T>(url ? [url] : undefined, {
    ...swrOptions,
    ...options,
    fallbackData: fallbackData,
  });
}

export function invalidate<T>(key: string, payload?: T) {
  if (payload) {
    // Repopulate directly with the provided payload
    mutate([key], payload, { revalidate: false });
  } else {
    // Refetches the data in the background and repopulates without ceremony
    mutate([key]);
  }
}

// use this to invalidate all keys that start with a certain string
// e.g. resources that have different query params
export const invalidateStartsWith = (keys: string[]) => {
  keys.forEach(_key => {
    mutate(key => {
      return Array.isArray(key) && typeof key[0] === 'string' && key[0].startsWith(_key);
    });
  });
};
