import { combineReducers, Reducer } from 'redux';
import { SnackStore } from 'types/snackbar';
import { ICompany } from 'types/company';
import { UserProfile } from 'types/user';
import snackbar from './reducers/snackbarReducer';
import companyProfiles from './reducers/companyProfiles';
import intercom from './reducers/intercom';
import userProfiles from './reducers/userProfiles';
import { user, UserStore } from './user';

export interface RootStore {
  snackbar: SnackStore;
  userProfiles: {
    items: UserProfile[];
  };
  companyProfiles: {
    items: ICompany[];
  };
  user: UserStore;
}

const rootReducer: Reducer<RootStore> = combineReducers({
  intercom,
  snackbar,
  userProfiles,
  companyProfiles,
  user,
});

export default rootReducer;
