import { Room } from 'domain/rooms/types';
import { List } from 'types/api';
import roomsApi, { roomAPIUrls } from './roomsApi';
import useSWR from 'hooks/useSWR';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { useSWRResource } from 'util/resource/useSWRResource';

export const useRooms = (roomIds?: string[]) => {
  return useResourceLegacy<List<Room>>(
    () => (roomIds && roomIds.length > 0 ? roomsApi.list(roomIds) : Promise.resolve({ values: [] })),
    [],
  );
};

export function useCompanyRooms(companySlug: string) {
  const swrResult = useSWR<List<Room>>(roomAPIUrls.listForCompany(companySlug));
  return useSWRResource(swrResult);
}
