import React from 'react';
import { DetailedUserProfile } from 'types/user';
import { ICompany } from 'types/company';
import Card from 'ui/views/cards/Card';
import InviteUserIcon from 'ui/elements/icons/InviteUserIcon';
import Button from 'ui/elements/buttons/Button';
import useLazyResource from 'util/resource/useLazyResource';
import CompanyAPI from 'apis/CompanyAPI';
import { notify } from 'actions/snackbar';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import { halfSpacing } from 'ui/theme/themeConstants';
import useDialogHandler from 'hooks/useDialogHandler';
import ProgressConfirmDialog from 'ui/modules/dialogs/ProgressConfirmDialog';

interface Props {
  company: ICompany;
  user: DetailedUserProfile;
  onUpdate: () => void;
}

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  ${bluePlanetTheme.breakpoints.down('sm')} {
    flex-direction: column;
    & > :last-child {
      margin-top: ${halfSpacing};
    }
  }
`;

export default function UserFollowRequestCard({ company, user, onUpdate }: Props) {
  const [acceptUser, isAccepting] = useLazyResource(() => CompanyAPI.follower.company.accept(company.id, user.id), {
    onSuccess: () => {
      notify('success', 'User accepted!');
      onUpdate();
    },

    onFailure: () => notify('error', 'Something went wrong when trying to accept user'),
  });

  const [rejectUser, isRejecting] = useLazyResource(() => CompanyAPI.follower.company.reject(company.id, user.id), {
    onSuccess: () => {
      notify('success', 'Follow request rejected');
      onUpdate();
    },

    onFailure: () => notify('error', 'Something went wrong when trying to reject user request'),
  });

  const dialogHandler = useDialogHandler(false);
  return (
    <Card
      style={{
        backgroundColor: bluePlanetTheme.bluePlanetPalette.blue.veryLight,
      }}
      className="u-content-spacing-bottom"
    >
      <CardContainer>
        <div className="u-flex-align-center">
          <InviteUserIcon fontSize="large" color="blue" className="u-half-spacing-right" />
          {user.name} wants to follow your company {company.name}
        </div>
        <div className="u-margin-left-auto u-flex-align-center u-half-padding-x">
          <Button kind="primary" isLoading={isAccepting} onClick={acceptUser} className="u-half-spacing-right">
            Confirm
          </Button>
          <Button kind="tertiary" color="grey" isLoading={isRejecting} onClick={dialogHandler.open}>
            Reject
          </Button>
          <ProgressConfirmDialog
            dialogHandler={dialogHandler}
            confirmLabel="Reject request"
            onConfirm={rejectUser}
            dismissLabel="Cancel"
            title="Reject request"
          >
            Reject {user.name} as a follower.
          </ProgressConfirmDialog>
        </div>
      </CardContainer>
    </Card>
  );
}
