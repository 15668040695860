import { calculateCompletedPercentage } from 'domain/onboarding/utils';
import React from 'react';
import { Link } from 'react-router-dom';
import { ICompany } from 'types/company';
import ProgressPie from 'ui/elements/Progress/ProgressPie';
import { companyUrls } from 'urls';
import DashboardMessage from './DashboardMessage';
import { useCompanyOnboarding } from 'apis/OnboardingAPI';

interface Props {
  company: ICompany;
}

export default function FinishCompanyProfile({ company }: Props) {
  const { checklistSteps } = useCompanyOnboarding(company.slug);
  const percentage = calculateCompletedPercentage(checklistSteps);

  return (
    <DashboardMessage
      key="company-profile-onboarding"
      color="indigo"
      topElement={
        isNaN(percentage) ? (
          <div style={{ height: 70, width: 70 }}></div>
        ) : (
          <ProgressPie width={70} thickness={2} fontSize="normal" percentage={percentage} />
        )
      }
    >
      <p>Complete your company profile, and improve your chances of attracting the right audience</p>
      <Link
        className="data-track-dashboard-cta-go-to-company-profile text-link-light"
        to={companyUrls.overview(company.slug, 'profile', { expandOnboardingChecklist: true })}
      >
        Complete now
      </Link>
    </DashboardMessage>
  );
}
