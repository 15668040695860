import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function RocketIcon(props: IconProps) {
  return (
    <SvgIcon {...props} strokeWidth={1.655}>
      <path d="M11.8 6.5 13.3 5a9 9 0 0 1 6.2-2.5c.9 0 1.3 0 1.7.3.3.4.3.8.3 1.7a9 9 0 0 1-2.5 6.2l-1.5 1.5c-1.2 1.2-1.6 1.6-1.3 2.9.2 1 .5 2-.2 2.7-1 1-1.8 1-2.6 0l-7.2-7.2c-1-.8-1-1.7 0-2.6.7-.7 1.7-.4 2.7-.2 1.3.3 1.7 0 2.9-1.3Z" />
      <path strokeWidth="2" d="M17 7h0" />
      <path d="m2.5 21.5 5-5M8.5 21.5l2-2M2.5 15.5l2-2" />
    </SvgIcon>
  );
}
