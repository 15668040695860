import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function PiggybankIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M13 5c5 0 9 3.4 9 7.5 0 2-1 4-2.6 5.3a1 1 0 0 0-.4.8V21h-2l-.8-1.1a.5.5 0 0 0-.5-.2c-1.8.4-3.6.4-5.4 0-.2 0-.4 0-.5.2L9 21H7v-2.4a1 1 0 0 0-.4-.8C5.5 17 2 15.6 2 14v-1.6c0-.6.4-1 1-1s1.1-.3 1.3-1C5.3 7.5 8.8 5 13 5Z" />
      <path d="M14.5 8a4.3 4.3 0 0 0-4 0" />
      <path strokeWidth="2" d="M7.5 11h0" />
      <path d="M21 8.5c.5-.5 1-1.4 1-2.7C22 4.3 20.7 3 19 3c-.4 0-.7 0-1 .2" />
    </SvgIcon>
  );
}
