import React, { useEffect, useRef } from 'react';
import styles from './styles.scss';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { Backdrop } from '@mui/material';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isFullScreen?: boolean;
  children: (close: () => void) => React.ReactNode;
}

export const CHAT_DIALOG_ROLE = 'chat-dialog';

export function createPortalWrapper(role: string) {
  // append a new element with a given role to the document body, and return it so e.g. portals can be attached to it
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('role', role);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

export default function ChatDialog({ isOpen, onClose, isFullScreen, children }: Props) {
  const portalContainer = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isOpen) {
      if (!portalContainer.current) {
        portalContainer.current = createPortalWrapper(CHAT_DIALOG_ROLE);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      if (portalContainer.current) {
        document.body.removeChild(portalContainer.current);
        portalContainer.current = undefined;
      }
    };
  }, []);

  return (
    <>
      {isOpen &&
        portalContainer.current &&
        createPortal(
          <>
            <Backdrop className={styles.backdrop} open={isOpen} onClick={onClose} />
            <div
              className={cx(styles.chatDialog, {
                [styles.fullScreen]: isFullScreen,
              })}
            >
              {children(onClose)}
            </div>
          </>,
          portalContainer.current,
        )}
    </>
  );
}
