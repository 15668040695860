import useSWR from 'hooks/useSWR';
import { DocumentList } from 'types/documents';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';

export default function usePitchDocuments(companyId: number) {
  const swrResult = useSWR<DocumentList>(companiesAPIUrls.pitchDocuments.list(companyId));

  return useSWRResource(swrResult);
}
