import React from 'react';
import Button from 'ui/elements/buttons/Button';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import Dialog, { DialogActions, Title } from 'ui/views/dialogs/Dialog';
import { Content } from 'ui/views/dialogs/FullscreenDialog';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import { bluePlanetTheme } from 'ui/theme';

interface Props {
  title: string;
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export default function ConfirmationDialog(props: Props) {
  return (
    <Dialog open={props.isOpen} onClose={props.onClose} backdropColor="light">
      <Title
        style={{
          backgroundColor: bluePlanetTheme.bluePlanetPalette.green.light,
          borderRadius: '8px 8px 0 0',
          color: bluePlanetTheme.bluePlanetPalette.green.dark,
        }}
        onClose={props.onClose}
        className="u-content-spacing-bottom"
      >
        <IconAvatar className="u-half-spacing-right" color="success">
          <CheckmarkIcon fontSize="large" />
        </IconAvatar>
        {props.title}
      </Title>
      <Content>{props.children}</Content>
      <DialogActions>
        <Button onClick={() => props.onClose()} kind="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
