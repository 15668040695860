import { conversationApiUrls, ConversationMeDTO } from 'apis/ContentAPI/conversationApi';
import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';

export const conversationMeKey = conversationApiUrls.user.me();
export const useConversationMe = () => {
  const swrResult = useSWR<ConversationMeDTO>(conversationMeKey);

  return useSWRResource(swrResult);
};
