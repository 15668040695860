import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notify } from 'actions/snackbar';

export default function useNotify() {
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      notify,
    },
    dispatch,
  );

  return actions.notify;
}
