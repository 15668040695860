import { useState } from 'react';

export interface DialogHandlerProps {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export default function useDialogHandler(initialOpen?: boolean) {
  const [isOpen, setOpen] = useState(initialOpen || false);

  function open() {
    setOpen(true);
  }

  function close() {
    setOpen(false);
  }

  return {
    isOpen,
    open,
    close,
  } as DialogHandlerProps;
}
