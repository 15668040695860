import { DialogProps } from '@mui/material/Dialog';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import { ProgressHandler } from 'hooks/useProgressHandler';
import React from 'react';
import Button from 'ui/elements/buttons/Button';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import i18n from 'util/i18n';

interface Props {
  children: React.ReactNode | React.ReactNode[] | string;
  title: React.ReactNode;
  dialogHandler: DialogHandlerProps;
  onConfirm: (handleProgress: ProgressHandler) => void;
  isConfirming?: boolean;
  confirmButtonClassName?: string;
  confirmColor?: 'blue' | 'red';
  dismissLabel?: string;
  confirmLabel?: string;
  maxWidth?: DialogProps['maxWidth'];
  noDismissButton?: boolean;
  onClose?: () => void;
  disableConfirmButton?: boolean;
  onDismiss?: () => void;
  scroll?: 'paper' | 'body';
  // Needed when the content overflows and the scroll prop doesn't solve it.
  contentOverflow?: 'visible' | 'auto';
}

export default function ProgressConfirmDialog(props: Props) {
  const [isLoading, setLoading] = React.useState(false);

  const onClose = () => {
    if (!isLoading) {
      props.onClose && props.onClose();
      props.dialogHandler.close();
    }
  };

  return (
    <Dialog
      open={props.dialogHandler.isOpen}
      onClose={onClose}
      scroll={props.scroll}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={props.maxWidth || 'xs'}
    >
      <Title id="alert-dialog-title" onClose={onClose}>
        {props.title}
      </Title>
      <Content overflowY={props.contentOverflow}>{props.children}</Content>
      <DialogActions>
        <Button
          kind="primary"
          onClick={async () => {
            await props.onConfirm({
              onProgress: () => setLoading(true),
              onCleanup: result => {
                setLoading(false);
                if (result === 'success') {
                  props.dialogHandler.close();
                }
              },
            });
          }}
          color={props.confirmColor}
          isLoading={props.isConfirming ?? isLoading}
          disabled={props.disableConfirmButton}
          className={props.confirmButtonClassName}
        >
          {props.confirmLabel || i18n('en').common.confirm}
        </Button>
        {!props.noDismissButton && (
          <Button
            kind="tertiary"
            onClick={e => {
              e.stopPropagation();
              props.onDismiss && props.onDismiss();
              props.dialogHandler.close();
            }}
          >
            {props.dismissLabel || i18n('en').common.noCancel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
