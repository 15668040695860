import React from 'react';
import StatsCard from 'ui/views/cards/StatsCard';
import PitchViewsGraph from 'domain/Stats/PitchViewsGraph';
import styled from '@emotion/styled';
import { contentSpacing } from 'ui/theme/themeConstants';
import { bluePlanetTheme } from 'ui/theme';
import { ProfileInsights, ProfileInsightsBreakdown } from 'apis/CompanyAPI/insights/types';
import Card from 'ui/views/cards/Card';
import PieChart from 'ui/elements/charts/PieChart';
import { DEFAULT_CURRENCY } from 'util/currency';
import { investorTypes } from 'types/user';

type Props = { profileVisits: ProfileInsights; profileVisitsBreakdown: ProfileInsightsBreakdown };

const avgDurationText = (durationInSeconds: number) => {
  const date = new Date(0);
  date.setSeconds(durationInSeconds);
  const timeString = date.toISOString().substring(14, 19);
  return timeString;
};

const StatsGrid = styled.div`
  display: grid;
  gap: ${contentSpacing};
  grid-template-columns: repeat(3, minmax(min-content, 300px));

  ${bluePlanetTheme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const InvestmentPowerGrid = styled.div`
  display: grid;
  gap: ${contentSpacing};
  grid-template-columns: 1fr 1fr;
  ${bluePlanetTheme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

export default function ProfileVisits({ profileVisits, profileVisitsBreakdown }: Props) {
  const topCards = [
    {
      key: 'impressions',
      value: profileVisits.visits.value,
      previousValue: profileVisits.visits.previousValue,
      title: 'Visits',
      tooltip: 'Total visits to your company profile',
    },
    {
      key: 'uniqueUsers',
      value: profileVisits.visitors.value,
      previousValue: profileVisits.visitors.previousValue,
      title: 'Visitors',
      tooltip: "Total number of people who've visited your company profile",
    },
    {
      key: 'avgDuration',
      value: `${avgDurationText(profileVisits.avgDurationInSeconds)}`,
      title: 'Average view duration',
      tooltip: 'How long visitors spent exploring your profile on average (minutes:seconds)',
    },
  ];

  return (
    <>
      <StatsGrid>
        {topCards.map(total => (
          <StatsCard
            key={total.title}
            title={total.title}
            value={total.value}
            previousValue={total.previousValue}
            tooltip={total.tooltip}
          />
        ))}
      </StatsGrid>
      <div className="u-content-spacing-top">
        <PitchViewsGraph visitors={profileVisitsBreakdown.visitors} visits={profileVisitsBreakdown.visits} />
      </div>
      <InvestmentPowerGrid className="u-content-spacing-top">
        <Card>
          <p className="u-content-spacing-bottom text-font-text text-medium">Investors</p>
          <PieChart
            width={160}
            height={160}
            chartData={profileVisitsBreakdown.investorType.values.map(it => ({
              name: investorTypes[it.label].name,
              value: it.percentage,
            }))}
            unit="%"
          ></PieChart>
        </Card>
        <Card>
          <p className="u-content-spacing-bottom text-font-text text-medium">Investment power ({DEFAULT_CURRENCY})</p>
          <PieChart
            width={160}
            height={160}
            chartData={profileVisitsBreakdown.investmentPower.values.map(it => ({
              name: it.label,
              value: it.percentage,
            }))}
            unit="%"
          ></PieChart>
        </Card>
      </InvestmentPowerGrid>
    </>
  );
}
