import React, { createContext } from 'react';

import { Header, HeaderCell } from './Header';
import { Body } from './Body';
import styled from '@emotion/styled';
import Loading from 'ui/elements/Loading';
import { Row } from './Row';
import { SortDirectionOrder, SortOptions } from './useTableWithSort';
import { DataCell } from './DataCell';
import { bluePlanetTheme } from 'ui/theme';

const LoadingOverlay = styled.div(
  ({ isLoading }: { isLoading?: boolean }) => `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  visibility: ${isLoading ? 'visible' : 'hidden'};
`,
);

export interface TableContextProps {
  sortOptions?: SortOptions;
  onSort?: (sortBy: string, sortOrder?: SortDirectionOrder) => void;
  hasMenu?: boolean;
  hasClickableRows?: boolean;
  isLoading?: boolean;
  isFilterActive?: boolean;
  infoMessages?: {
    noResults: string;
    noResultsAfterFilter?: string;
  };
  columnClassNames?: Array<string | undefined>;
  tableColor?: 'white' | 'grey';
}

// We need this so that overflowing tables don't cause horizontal scrollbars on the page
const TableContainer = styled.div`
  overflow-x: auto;
  overflow-y: clip;
  width: auto; // Needed to make negative margins work
  min-width: 100%;
  scrollbar-width: thin;
  box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.06);

  ${bluePlanetTheme.breakpoints.down('sm')} {
    margin: 0; // Need to reset margin and padding on mobile units, to avoid a sideways scroll
    padding: 0;
  }
`;

type TableProps = React.TableHTMLAttributes<HTMLTableElement> & TableContextProps;

export const TableContext = createContext<TableContextProps | null>(null);

export const Table = ({
  children,
  isLoading,
  sortOptions,
  onSort,
  hasMenu,
  hasClickableRows,
  isFilterActive,
  infoMessages,
  columnClassNames,
  tableColor: color = 'white',
  style,
  ...rest
}: TableProps) => {
  return (
    <TableContext.Provider
      value={{
        isLoading,
        sortOptions,
        onSort,
        hasMenu,
        hasClickableRows,
        isFilterActive,
        infoMessages,
        columnClassNames,
        tableColor: color,
      }}
    >
      <TableContainer>
        <table
          style={{
            ...style,
            width: '100%',
            textAlign: 'left',
            borderCollapse: 'separate',
            position: 'relative',
            borderSpacing: '0 2px',
          }}
          {...rest}
        >
          {children}
          <LoadingOverlay isLoading={isLoading}>
            <Loading style={{ marginTop: 65 }} />
          </LoadingOverlay>
        </table>
      </TableContainer>
    </TableContext.Provider>
  );
};

Table.Header = Header;
Table.HeaderCell = HeaderCell;
Table.Body = Body;
Table.Row = Row;
Table.DataCell = DataCell;

export default Table;
