import React, { useEffect } from 'react';
import { Autocomplete, AutocompleteRenderOptionState, CircularProgress, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

interface AutocompleteResourceOption {
  id: any;
  name: string;
}

interface AutocompleteResourceProps<T> {
  name?: string;
  label: string;
  placeholder: string;
  preload?: boolean;
  minCharactersTreshold: number;
  onSelect: (item: T) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onQueryChange: (query: string) => Promise<T[]>;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
}

export default function AutocompleteResource<T extends AutocompleteResourceOption>(
  props: AutocompleteResourceProps<T>,
) {
  const [options, setOptions] = React.useState<readonly T[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const getOptionsDelayed = useDebouncedCallback(async (query: string) => {
    setLoading(true);
    setOptions(await props.onQueryChange(query));
    setLoading(false);
  }, 300);

  useEffect(() => {
    if (props.preload && options.length === 0) {
      getOptionsDelayed(inputValue);
    } else if (inputValue.length >= props.minCharactersTreshold) {
      getOptionsDelayed(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue, getOptionsDelayed, props.preload]);

  return (
    <Autocomplete
      className={props.className}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => `${option.name} (${option.id})`}
      options={options}
      loading={loading}
      disabled={props.disabled}
      filterOptions={u => u}
      onBlur={props.onBlur}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(e, value) => {
        if (value) {
          props.onSelect(value);
        }
      }}
      clearOnBlur={true}
      blurOnSelect={true}
      noOptionsText={
        inputValue.length >= props.minCharactersTreshold
          ? 'No results'
          : `Type at least ${props.minCharactersTreshold} characters`
      }
      renderOption={props.renderOption}
      value={null}
      renderInput={params => (
        <TextField
          {...params}
          name={props.name}
          error={props.error}
          helperText={props.helperText}
          label={props.label}
          placeholder={props.placeholder}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          InputLabelProps={{
            hidden: true,
            disableAnimation: true,
            shrink: true,
            style: { position: 'relative', transform: 'none' },
          }}
        />
      )}
    />
  );
}
