import React, { CSSProperties } from 'react';
import { staticFileLocations } from 'urls';
import { resize } from 'util/cloudinary';
import classes from './styles.scss';

interface Props {
  companySlug?: string;
  imageURL?: string;
  style?: CSSProperties;
  width?: number | 'auto';
}

export default function Banner({ imageURL, style }: Props) {
  const backgroundImage = resize(imageURL || staticFileLocations.defaultCompanyImage, { width: 'auto' });
  return (
    <div className={classes.banner}>
      <div
        className={classes.bannerWrapper}
        style={{
          ...style,
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>
    </div>
  );
}
