import React from 'react';
import CompanyAPI from 'apis/CompanyAPI';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import Resource from 'util/resource/Resource';
import { DetailedUserProfile } from 'types/user';
import UserFollowRequestCard from './UserFollowRequestCard';

interface Props {
  user: DetailedUserProfile;
}

export default function UserFollowRequests({ user }: Props) {
  const [userFollowRequests, , , reload] = useResourceLegacy(
    () => CompanyAPI.follower.user.listUserRequestsForMyCompanies(user.id),
    [user],
  );

  return (
    <Resource resource={userFollowRequests}>
      {requests => {
        if (requests.values.length === 0) {
          return null;
        }
        return (
          <>
            {requests.values.map(r => (
              <UserFollowRequestCard key={r.company.id} user={user} company={r.company} onUpdate={reload} />
            ))}
          </>
        );
      }}
    </Resource>
  );
}
