import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function GraphIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M7 18v-2m5 2v-3m5 3v-5M2.5 12c0-4.5 0-6.7 1.4-8.1C5.3 2.5 7.5 2.5 12 2.5s6.7 0 8.1 1.4c1.4 1.4 1.4 3.6 1.4 8.1s0 6.7-1.4 8.1c-1.4 1.4-3.6 1.4-8.1 1.4s-6.7 0-8.1-1.4c-1.4-1.4-1.4-3.6-1.4-8.1Z" />
      <path d="M6 11.5c2.1 0 7-.3 9.8-4.7M14 6.3l1.9-.3c.2 0 .5.1.6.4L17 8" />
    </SvgIcon>
  );
}
