import React from 'react';
import { Currency } from 'types/company';
import Tooltip from 'ui/elements/Tooltip';
import { getCurrencySymbol } from 'util/currency';
import { formatThousands } from 'util/numbers';

const CurrencyTooltip = ({ value }: { value: Currency }) => {
  const formattedValue = formatThousands(value.value);
  return value.currency ? (
    <Tooltip title={`${value.currency} ${formattedValue}`}>
      <span>
        {getCurrencySymbol(value.currency)} {formattedValue}
      </span>
    </Tooltip>
  ) : (
    <Tooltip title={formattedValue}>
      <span>{formattedValue}</span>
    </Tooltip>
  );
};

export default CurrencyTooltip;
