import { useFormik } from 'formik';
import React, { useRef } from 'react';
import { ExecutiveSummaryDTO, ICompany } from 'types/company/company';
import Button from 'ui/elements/buttons/Button';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import Dialog, { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import * as Yup from 'yup';
import useSaveExecutiveSummary from 'domain/companies/ExecutiveSummary/useSaveExecutiveSummary';
import AiMenu from 'ui/domain/AiMenu/AiMenu';

interface Props {
  company: ICompany;
  executiveSummary: ExecutiveSummaryDTO;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}
export default function WhyInvestDialog({ company, executiveSummary, isOpen, onClose, onSuccess }: Props) {
  const [onSave, isSaving] = useSaveExecutiveSummary(company, {
    onComplete: () => {
      onSuccess && onSuccess();
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: {
      unfairAdvantage: executiveSummary.unfairAdvantage,
    },
    onSubmit: values => {
      onSave(values);
    },
    validationSchema: Yup.object().shape({
      unfairAdvantage: Yup.string()
        .trim()
        .notRequired()
        .max(2000, 'Why invest in us should be at most 2000 characters long'),
    }),
  });
  const aiRef = useRef<HTMLDivElement>(null);
  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="md">
      <form onSubmit={formik.handleSubmit}>
        <Content paddingY="top">
          <FormikTextField
            formikProps={formik}
            autoFocus={true}
            name="unfairAdvantage"
            label="Competitive advantage - why invest in us?"
            multiline
            minRows={4}
            maxRows={20}
            inputProps={{ maxLength: 725 }}
            suffixPosition="start-bottom"
            helperText={
              formik.values.unfairAdvantage?.length || 0 > 1000
                ? 'Why invest in us exceeds 1000 characters. For clarity and impact, consider keeping it shorter.'
                : undefined
            }
            suffix={
              <AiMenu
                companyId={company.id}
                organizationId={undefined}
                input={formik.values.unfairAdvantage || ''}
                maxLength={700}
                onReplace={value => {
                  formik.setFieldValue('unfairAdvantage', value);
                }}
                aiRef={aiRef}
              />
            }
          />
          <div ref={aiRef} />
        </Content>
        <DialogActions>
          <Button kind="primary" onClick={() => formik.handleSubmit()} isLoading={isSaving}>
            Update
          </Button>
          <Button kind="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
