import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function EyeCrossedIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21.5 11c.3.5.5.7.5 1 0 .3-.2.5-.5 1-1.3 1.9-4.8 6-9.5 6s-8.2-4.1-9.5-6c-.3-.5-.5-.7-.5-1 0-.3.2-.5.5-1C3.8 9.1 7.3 5 12 5s8.2 4.1 9.5 6Z" />
      <path d="M15 12a3 3 0 0 1-2.2 2.9m.5-5.6a3 3 0 0 0-3.3 5M17.7 2.7 6.1 20.5" />
    </SvgIcon>
  );
}
