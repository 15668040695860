import React from 'react';
import { Update } from 'types/content';
import MaxWidth from 'ui/views/containers/MaxWidth';
import styles from './styles.scss';

interface Props {
  content: Update;
}

export default function UpdateHeader(props: Props) {
  const { content } = props;

  return (
    <div className={styles.titleWrapper}>
      <MaxWidth width="sm">
        <h2 className="text-h3 u-half-spacing-bottom u-break-words">{content.title}</h2>
      </MaxWidth>
    </div>
  );
}
