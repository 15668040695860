import React from 'react';
import { PostUpdateTo } from 'types/content';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import Button from 'ui/elements/buttons/Button';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import { Title, Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { FormControl, FormControlLabel, Hidden, List, RadioGroup, useMediaQuery } from '@mui/material';
import Radio from 'ui/elements/form/choice/Radio';
import { CommunityMembershipDTO } from 'types/company/community';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';

const visibilityDisplayString = (postUpdateTo: PostUpdateTo) => {
  if (postUpdateTo.type === 'company' && postUpdateTo.visibility === 'Private') {
    return 'Shareholders and board members';
  } else if (postUpdateTo.type === 'company' && postUpdateTo.visibility === 'Public') {
    return 'Anyone';
  } else if (postUpdateTo.type === 'community') {
    return `Everyone in ${postUpdateTo.community.name}`;
  } else {
    return '';
  }
};

const PostTo = styled.span`
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  font-size: ${fontSizeSmall};
  color: ${bluePlanetTheme.bluePlanetPalette.grey.main};
  text-align: left;
`;

const Heading = styled.div`
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  color: ${bluePlanetTheme.bluePlanetPalette.grey.main};
`;

export function VisibilitySelector({
  setIsVisibilitySelectorOpen,
  postUpdateTo,
  setPostUpdateTo,
  communityMemberships,
  settingsDialogHandler,
  canSelectCommunities,
}: {
  setIsVisibilitySelectorOpen: (value: boolean) => void;
  postUpdateTo: PostUpdateTo;
  setPostUpdateTo: (value: PostUpdateTo) => void;
  communityMemberships: CommunityMembershipDTO[];
  canSelectCommunities: boolean;
  settingsDialogHandler: DialogHandlerProps;
}) {
  const communitiesICanPostTo = canSelectCommunities
    ? communityMemberships.filter(
        community =>
          community.communityVisibility === 'Visible' && community.community.communityType === 'investor_arena',
      )
    : [];

  const value = postUpdateTo.type === 'company' ? postUpdateTo.visibility : postUpdateTo.community.id;

  return (
    <>
      <Title onClose={() => setIsVisibilitySelectorOpen(false)}>Who are you posting to?</Title>
      <Content>
        <List>
          <FormControl>
            <RadioGroup value={value}>
              <FormControlLabel
                className="u-half-spacing-bottom"
                style={{ alignItems: 'flex-start' }}
                control={<Radio />}
                value="Public"
                onChange={() => setPostUpdateTo({ type: 'company', visibility: 'Public' })}
                label={
                  <div>
                    <div className="u-flex u-flex--align-items-center" style={{ gap: '0.4rem' }}>
                      Anyone
                    </div>
                    <div style={{ fontWeight: 'normal' }}>Anyone on CrowdWorks</div>
                  </div>
                }
              />
              <FormControlLabel
                className="u-half-spacing-bottom"
                style={{ alignItems: 'flex-start' }}
                control={<Radio />}
                value="Private"
                onChange={() => setPostUpdateTo({ type: 'company', visibility: 'Private' })}
                label={
                  <div>
                    <div className="u-flex u-flex--align-items-center" style={{ gap: '0.4rem' }}>
                      Shareholders & board members
                    </div>
                    <div style={{ fontWeight: 'normal' }}>Approved members of the company</div>
                  </div>
                }
              />
              {communitiesICanPostTo.length > 0 && (
                <>
                  <Heading className="u-content-spacing-y">Communities you are part of:</Heading>
                  {communitiesICanPostTo.map(community => (
                    <FormControlLabel
                      className="u-half-spacing-bottom"
                      style={{ alignItems: 'flex-start' }}
                      key={community.community.id}
                      control={<Radio />}
                      onChange={() =>
                        setPostUpdateTo({
                          type: 'community',
                          community: community.community,
                          visibility: 'CommunityMembers',
                        })
                      }
                      value={community.community.id}
                      label={
                        <div>
                          <div className="u-flex u-flex--align-items-center" style={{ gap: '0.4rem' }}>
                            {community.community.name}
                          </div>
                          <div style={{ fontWeight: 'normal' }}>Everyone in the community</div>
                        </div>
                      }
                    />
                  ))}
                </>
              )}
            </RadioGroup>
          </FormControl>
        </List>
      </Content>
      <DialogActions align="space-between">
        <Button kind="primary" onClick={() => setIsVisibilitySelectorOpen(false)}>
          Done
        </Button>
        <Button kind="tertiary" onClick={settingsDialogHandler.open}>
          More options <ChevronRightIcon className="u-half-spacing-left" />
        </Button>
      </DialogActions>
    </>
  );
}

export default function Visibility({
  openVisibilityPicker,
  postUpdateTo,
}: {
  openVisibilityPicker: () => void;
  postUpdateTo: PostUpdateTo;
}) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  return (
    <>
      <Button kind="primary" color="greyLight" onClick={openVisibilityPicker}>
        <div
          className="u-flex u-flex-align-center u-flex-space-between"
          style={{ minWidth: isMobile ? undefined : '200px' }}
        >
          <div className="u-flex u-flex-align-center">
            <Hidden smDown>
              <EyeIcon className="u-half-spacing-right" />
            </Hidden>
            <div className="u-flex u-flex--column text-align-left">
              <PostTo>Post to:</PostTo>
              <span>{visibilityDisplayString(postUpdateTo)}</span>
            </div>
          </div>
          <ChevronDownIcon className="u-half-spacing-left" />
        </div>
      </Button>
    </>
  );
}
