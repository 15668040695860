import SvgIcon, { IconProps } from '../SvgIcon';
import React from 'react';

export default function DocumentIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M9 2.89v1.5C9 5.8 9 6.5 8.56 6.95c-.44.44-1.15.44-2.56.44H4.5" />
      <path d="M20 16.39v-8c0-2.83 0-4.25-.88-5.13-.88-.87-2.3-.87-5.12-.87H9.83c-.41 0-.61 0-.8.07-.18.08-.33.22-.62.51L4.6 6.8c-.3.29-.44.43-.51.62C4 7.6 4 7.8 4 8.22v8.17c0 2.82 0 4.24.88 5.12.88.88 2.3.88 5.12.88h4c2.83 0 4.24 0 5.12-.88.88-.88.88-2.3.88-5.12Z" />
    </SvgIcon>
  );
}
