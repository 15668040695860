import React, { ReactNode } from 'react';
import Card from 'ui/views/cards/Card';
import { Link } from 'react-router-dom';

function Lnk({ to, children }: { to?: string; children: ReactNode }) {
  return to ? <Link to={to}>{children}</Link> : children;
}

export function Todo({
  href,
  action,
  children,
  color,
}: {
  href?: string;
  action?: ReactNode;
  children: ReactNode;
  color?: 'indigo';
}) {
  return (
    <Lnk to={href}>
      <Card color={color} padding="half" className="u-flex-space-between u-flex-align-center">
        <div className="u-flex-align-center u-flex--gap-1">{children}</div>
        {action}
      </Card>
    </Lnk>
  );
}
