import { Props as ChipProps } from 'ui/elements/Chip';
import { BackofficeMatchmakingBaseMatch } from 'types/backoffice';
import useLocalStorage from 'hooks/useLocalStorage';
import { norwegianDateWithTimestamp } from 'util/dateUtils';
import { investorTypes } from 'types/user';

export const stateToColor: { [key in BackofficeMatchmakingBaseMatch['state']]: ChipProps['color'] } = {
  Staged: 'yellow',
  Yay: 'green',
  Seen: 'grey',
  Notified: 'grey',
  New: 'green-medium',
};

export function asDate(date: string | null | undefined): string {
  return date ? norwegianDateWithTimestamp(new Date(date)) : '-';
}

export function asInvestorType(key: string) {
  const entry = investorTypes[key];
  return entry ? entry.name : '-';
}

/* 
Note: this is not meant as a strict security check. It's just a temporary good-enough solution
to prevent untrained backoffice users from doing backoffice mutation actions.
*/
export const useMatchmakingFeatureToggle = () => {
  const [enabled] = useLocalStorage('backoffice-matchmaking', false);

  const guard = (callback: Function) => {
    if (enabled) {
      callback();
    } else {
      alert(
        'Not enabled. This feature is in testing, and early access is only given to trained users. Ask the team for instructions if you think you should be one of them :)',
      );
    }
  };
  return guard;
};
