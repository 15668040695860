import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function InfoIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M22 12a10 10 0 1 0-20 0 10 10 0 0 0 20 0Z" />
      <path d="M12.2 17v-5l-.1-.9-.9-.1" />
      <path strokeWidth="2" d="M12 8h0" />
    </SvgIcon>
  );
}
