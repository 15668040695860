import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function RotateRightIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M19 17v-2c0-5-3.8-9-8.5-9S2 10 2 15" />
      <path d="m16 15 3 3 3-3" />
    </SvgIcon>
  );
}
