import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function CompanyIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M15 2H9c-3.3 0-4 .7-4 4v16h14V6c0-3.3-.7-4-4-4Z" />
      <path d="M3 22h18" />
      <path d="M15 22v-3c0-1.7-.3-2-2-2h-2c-1.7 0-2 .3-2 2v3" />
      <path d="M13.5 6h-3m3 3.5h-3m3 3.5h-3" />
    </SvgIcon>
  );
}
