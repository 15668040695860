import useSWR from 'hooks/useSWR';
import { CommunityInviteDetails } from 'types/company/community';
import { useSWRResource } from 'util/resource/useSWRResource';
import { communityAPIUrls } from './communitiesApi';

export const communityInviteKey = (communityCode: string) => communityAPIUrls.invitations.get(communityCode);

export const useCommunityInvite = (communityCode?: string) => {
  const swrResult = useSWR<CommunityInviteDetails>(communityCode ? communityInviteKey(communityCode) : undefined);
  return useSWRResource(swrResult);
};
