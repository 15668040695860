import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ChevronDownIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m18 9-6 6-6-6" />
    </SvgIcon>
  );
}
