import { Quill } from 'react-quill';
import { Mention } from '..';

const MentionBlot = Quill.import('blots/mention');

class StyledMentionBlot extends MentionBlot {
  static render(data: Mention) {
    const element = document.createElement('span');
    element.className = 'ql-styled-mention';
    element.innerText = data.value;
    return element;
  }
}
StyledMentionBlot.blotName = 'styled-mention';
StyledMentionBlot.tagName = 'span';

Quill.register(StyledMentionBlot);
