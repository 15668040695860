import React, { useEffect, useState } from 'react';

import ReactPlayer from 'react-player/lazy';
import Preview from './Preview';
import PlusIcon from 'ui/elements/icons/PlusIcon';
import styles from './styles.scss';
import { VideoListDTO } from 'types/company';
import videoPlayerIllustration from 'ui/illustrations/video_player.svg';
import useNotify from 'hooks/useNotify';
import useJourneyLogger from 'hooks/useJourneyLogger';
import LinkButton from 'ui/elements/buttons/LinkButton';

interface Props {
  companyId: number;
  canEditCompany: boolean;
  videos: VideoListDTO;
  playerMaxWidth?: string;
  openEditDialog: () => void;
}

export default function Videos(props: Props) {
  const [log] = useJourneyLogger();
  const [activeVideo, setActiveVideo] = useState<string>();
  const [playing, setPlaying] = useState(false);
  const changeVideo = (url: string) => {
    log('changed video');
    setActiveVideo(url);
    setPlaying(true);
  };

  const notify = useNotify();

  useEffect(() => {
    setActiveVideo(props.videos.values[0]?.url);
    setPlaying(false);
  }, [props.companyId, props.videos]);

  if (props.videos.values.length === 0 && !props.canEditCompany) {
    return null;
  }

  return (
    <>
      {props.canEditCompany && props.videos.values.length === 0 && (
        <div className="u-flex u-flex--center u-flex-align-center">
          <img width="72" style={{ maxWidth: '15rem' }} src={videoPlayerIllustration} />
          <div className="u-flex u-flex--column u-section-spacing-left">
            <p className="text-body">Share videos to introduce your company, visions or showcase your product.</p>
            <LinkButton onClick={props.openEditDialog}>Add videos</LinkButton>
          </div>
        </div>
      )}
      <div>
        {activeVideo && (
          <div className={styles.player} style={{ maxWidth: props.playerMaxWidth }}>
            <div style={{ paddingBottom: '56.25%', position: 'relative' }}>
              <ReactPlayer
                onError={() => notify('error', "Sorry, we're having trouble playing this video.")}
                controls
                url={activeVideo}
                width="100%"
                playing={playing}
                height="100%"
                onStart={() => log('begin video')}
                onPause={() => log('paused video')}
                onPlay={() => log('play video')}
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
              />
            </div>
          </div>
        )}
        {(props.videos.values.length > 1 || props.canEditCompany) && (
          <div className="u-flex u-half-spacing-top">
            {props.videos.values.map(video => (
              <Preview
                className="u-quarter-spacing-right"
                key={video.id}
                url={video.url}
                onClick={() => changeVideo(video.url)}
              />
            ))}
            {props.canEditCompany && props.videos.values.length > 0 && (
              <div className={styles.placeholder} onClick={props.openEditDialog}>
                <PlusIcon />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
