import React, { useMemo } from 'react';
import UploadedFile from 'domain/shared/Files/File/UploadedFile';
import { DocumentDTO, DocumentDownloadDTO } from 'types/documents';
import { FileBeingUploaded, UploadStatus } from 'domain/shared/Files/Upload/useFileUpload';
import useLazyResource from 'util/resource/useLazyResource';
import useNotify from 'hooks/useNotify';
import { downloadBlob } from 'domain/shared/Files/actions';
import Carousel from 'ui/elements/Carousel';

interface Props {
  existingAttachments: DocumentDTO[];
  newAttachments: FileBeingUploaded[];
  attachmentIdList: string[];
  removeExistingAttachment: (documentId: UUID) => Promise<void>;
  removeNewAttachment: (temporaryId: string) => void;
  downloadAttachment: (documentId: string) => Promise<DocumentDownloadDTO>;
}

type AttachmentToRender = {
  id: string;
  name: string;
  status: UploadStatus;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDownload?: () => void;
};

export default function AttachmentsCarousel(props: Props) {
  const notify = useNotify();
  const [downloadAttachment] = useLazyResource(
    (documentId: string) => props.downloadAttachment(documentId).then(response => downloadBlob(response)),
    {
      onFailure: () => notify('error', 'Could not download the document.'),
    },
  );
  const attachmentsToRender = useMemo(
    () =>
      (
        props.existingAttachments
          .filter(doc => props.attachmentIdList.includes(doc.id))
          .map(doc => {
            const res: AttachmentToRender = {
              id: doc.id,
              name: doc.name,
              status: 'COMPLETED' as UploadStatus,
              onClose: e => {
                e.stopPropagation();
                props.removeExistingAttachment(doc.id);
              },
              onDownload: () => downloadAttachment(doc.id),
            };
            return res;
          }) ?? []
      ).concat(
        props.newAttachments.map(att => ({
          id: att.tempId,
          name: att.file.name,
          status: att.status,
          onClose: e => {
            e.stopPropagation();
            props.removeNewAttachment(att.tempId);
          },
        })),
      ),
    [props.newAttachments, props.attachmentIdList],
  );

  return (
    <>
      {attachmentsToRender.length > 0 && (
        <Carousel>
          {attachmentsToRender.map(att => (
            <span key={att.id}>
              <UploadedFile
                key={att.id}
                onClose={att.onClose}
                name={att.name}
                status={att.status}
                onDownload={att.onDownload}
              />
            </span>
          ))}
        </Carousel>
      )}
    </>
  );
}
