import { swrOptions } from 'apis/ApiBase';
import { postFetcher } from 'apis/utils';
import useSWR from 'swr';
import { PaginatedResult } from 'types/api';
import { UserProfile } from 'types/user';
import { useSWRResource } from 'util/resource/useSWRResource';
import { userAPIUrls } from './usersApi';

// This hook should only fetch profiles it doesn't already have and always have previously fetched profiles available
export const useUserProfiles = (cwUserIds: string[]) => {
  const swrResult = useSWR<PaginatedResult<UserProfile>>(
    [userAPIUrls.userProfiles.list(), { cwUserIds }],
    postFetcher,
    { ...swrOptions, keepPreviousData: true }, // This doesn't make that much sense, but the UX is slightly better.
  );

  return useSWRResource(swrResult);
};
