import { TextFieldProps } from '@mui/material/TextField';
import { FormikProps, getIn } from 'formik';
import React from 'react';
import TextField, { TextFieldProps as UITextFieldProps } from '../TextField';

export type FormikTextFieldProps<T> = {
  name: string;
  formikProps: {
    values: FormikProps<T>['values'];
    touched: FormikProps<T>['touched'];
    errors: FormikProps<T>['errors'];
    handleChange: FormikProps<T>['handleChange'];
    handleBlur: FormikProps<T>['handleBlur'];
  };
  'data-intercom-target'?: string;
} & UITextFieldProps;

type Props<T> = FormikTextFieldProps<T> &
  Omit<TextFieldProps, 'prefix'> & {
    variant?: 'standard' | 'filled';
  };

export default class FormikTextField<T> extends React.Component<Props<T>> {
  shouldComponentUpdate(nextProps: Props<T>) {
    const { values, touched, errors } = this.props.formikProps;
    const hasValueChanged = getIn(values, this.props.name) !== getIn(nextProps.formikProps.values, nextProps.name);
    const hasTouchedChanged = getIn(touched, this.props.name) !== getIn(nextProps.formikProps.touched, nextProps.name);
    const hasErrorChanged = getIn(errors, this.props.name) !== getIn(nextProps.formikProps.errors, nextProps.name);
    const hasDisabledChanged = this.props.disabled !== nextProps.disabled;
    const hasPrefixChanged = this.props.prefix !== nextProps.prefix;
    return hasValueChanged || hasTouchedChanged || hasErrorChanged || hasDisabledChanged || hasPrefixChanged;
  }

  render() {
    const { formikProps, name } = this.props;

    const fieldId = `${name}-input`;
    const error = !!getIn(formikProps.errors, name) && !!getIn(formikProps.touched, name);
    const helperText = !!error ? getIn(formikProps.errors, name) : this.props.helperText || ' ';

    const { formikProps: _, ...textFieldProps } = this.props; // Delete formikProps from the props that are passed on so that React doesn't complain about unkown props

    return (
      <TextField
        {...textFieldProps}
        name={name}
        id={fieldId}
        value={getIn(formikProps.values, name, '') ?? ''}
        error={error}
        onChange={formikProps.handleChange}
        onBlur={e => {
          formikProps.handleBlur(e);
          this.props.onBlur && this.props.onBlur(e);
        }}
        helperText={helperText}
      />
    );
  }
}
