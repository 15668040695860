import React from 'react';
import { UserProfile } from 'types/user';
import Avatar from 'ui/elements/avatars/Avatar';
import CompanyLogo from 'ui/elements/CompanyLogo';
import { ICompany } from 'types/company';
import { Link } from 'react-router-dom';

interface CompanyAuthor {
  type: 'company';
  user?: UserProfile;
  company?: ICompany;
  companyhref?: string;
  icon?: string;
}

type BaseCompanyAuthor = Omit<CompanyAuthor, 'type'>;

type BoardRoomAuthor = BaseCompanyAuthor & {
  type: 'boardroom';
  roomHref?: string;
  onClick?: () => void;
};

type RoomAuthor = BaseCompanyAuthor & {
  type: 'room';
  roomName?: string;
  roomHref?: string;
  onClick?: () => void;
};

type UserAuthor = UserProfile & {
  type: 'user';
};

export type Author = CompanyAuthor | UserAuthor | BoardRoomAuthor | RoomAuthor;

export function asCompanyAuthor(user?: UserProfile, company?: ICompany, companyHref?: string): CompanyAuthor {
  return {
    type: 'company',
    company: company,
    user: user,
    companyhref: companyHref,
  };
}

export function asBoardRoomAuthor(
  user?: UserProfile,
  company?: ICompany,
  companyHref?: string,
  roomHref?: string,
): BoardRoomAuthor {
  return {
    type: 'boardroom',
    company: company,
    user: user,
    companyhref: companyHref,
    roomHref: roomHref,
  };
}

export function asRoomAuthor(
  user?: UserProfile,
  company?: ICompany,
  companyHref?: string,
  roomName?: string,
  roomHref?: string,
): RoomAuthor {
  return {
    type: 'room',
    company: company,
    user: user,
    companyhref: companyHref,
    roomName: roomName,
    roomHref: roomHref,
  };
}

export function asUserAuthor(user?: UserProfile): UserAuthor {
  return {
    id: user?.id ?? -1,
    cwUserId: user?.cwUserId || '',
    type: 'user',
    name: user?.name ?? '',
    imageUrl: user?.imageUrl ?? '',
    position: user?.position,
    employer: user?.employer,
  };
}

export function nameOf(author?: Author) {
  if (!author) return '';
  return author?.type === 'user' ? author.name : author.user?.name;
}

export function AuthorImage({
  author,
  companyHref,
  size,
  className,
  onClick,
}: {
  author?: Author;
  onClick?: () => void;
  companyHref?: string;
  className?: string;
  size?: number;
}) {
  if (author?.type === 'user') {
    return (
      <button onClick={onClick} title={author?.name} className={className}>
        <Avatar size={size} imageUrl={author?.imageUrl} />
      </button>
    );
  } else if (companyHref) {
    return (
      <Link to={companyHref} className={className}>
        <CompanyLogo company={author?.company} size={size ?? 40} />
      </Link>
    );
  } else if (author?.type === 'company') {
    return <CompanyLogo className={className} company={author?.company} size={size ?? 40} />;
  }
  return <Avatar className={className} />;
}
