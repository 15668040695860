import useSWR from 'hooks/useSWR';
import { useSWRResource } from './useSWRResource';

export default function useResource<T>(
  url: string | undefined,
  options?: { keepPreviousData?: boolean; cacheDuractionMs?: number },
) {
  const result = useSWR<T>(url, undefined, {
    ...(options && {
      keepPreviousData: options.keepPreviousData,
      dedupingInterval: options.cacheDuractionMs,
    }),
  });
  return useSWRResource<T>(result);
}
