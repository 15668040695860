import styled from '@emotion/styled';
import cx from 'classnames';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import { fontSizeSmall } from 'ui/theme/themeBluePlanet';

interface Props {
  className?: string;
  label: string;
  children?: React.ReactNode;
}

const Key = styled.dt`
  color: ${bluePlanetTheme.bluePlanetPalette.indigo.light};
  font-weight: ${bluePlanetTheme.typography.fontWeightRegular};
  font-size: ${fontSizeSmall};
`;

const Value = styled.dl`
  color: ${bluePlanetTheme.bluePlanetPalette.indigo.light};
  font-weight: ${bluePlanetTheme.typography.fontWeightBold};
  font-size: ${fontSizeSmall};
  a {
    font-size: inherit;
  }
`;

export default function DictionaryItem({ className, children, label }: Props) {
  return (
    <>
      <Key className={cx('u-flex-align-center', className)}>{label}</Key>
      <Value className={cx('u-flex-align-center u-flex--wrap', className)}>{children}</Value>
    </>
  );
}
