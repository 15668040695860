import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import OrganizationAPI from 'apis/OrganizationAPI/index';
import { OrganizationListItemDTO } from 'types/organization';

export const organizationKey = OrganizationAPI.listUrl();

export const useOrganizations = () => {
  const swrResult = useSWR<OrganizationListItemDTO[]>(organizationKey);
  return useSWRResource(swrResult);
};
