import useSWR from 'hooks/useSWR';
import { ImpactGoal } from 'types';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

export const impactGoalsKey = (companyId: number) => companiesAPIUrls.impactGoals.list(companyId);

export const useImpactGoals = (companyId: number) => {
  const swrResult = useSWR<ImpactGoal[]>(impactGoalsKey(companyId));

  return useSWRResource(swrResult);
};
