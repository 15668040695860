import React from 'react';
import HeadTags from 'ui/views/layouts/BaseLayout/PageTitle';
import ScrollableBody from 'ui/views/layouts/BaseLayout/ScrollableBody';
import protectedIllustration from 'ui/illustrations/protected.svg';

export function CompanyNotFound() {
  return (
    <>
      <HeadTags title="" />
      <ScrollableBody accountForMobileHeader>
        <div className="u-align-center u-section-padding-y">
          <h1 className="text-h2 u-content-spacing-bottom">Private profile</h1>
          <p className="text-body text-large u-content-spacing-bottom">
            This company profile is private - it is only visible for connections. Contact the founders to request an
            invitation.
          </p>
          <div style={{ maxWidth: '18rem', margin: '5rem auto' }}>
            <img src={protectedIllustration} />
          </div>
        </div>
      </ScrollableBody>
    </>
  );
}
