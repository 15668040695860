import Hidden from '@mui/material/Hidden';
import React from 'react';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { keyframes } from '@emotion/react';
import { contentSpacing, halfSpacing, quarterSpacing, transtionEase } from 'ui/theme/themeConstants';
import NotificationEntry from 'domain/notify/NotificationEntry';
import UserInbox from 'domain/conversations/UserInbox';
import { userUrls } from 'urls';
import { Link } from 'react-router-dom';
import SettingsIcon from 'ui/elements/icons/SettingsIcon';

const border = `2px solid ${bluePlanetTheme.bluePlanetPalette.grey.medium}`;
const siebarWidth = '80px';

const isFirefox = navigator?.userAgent?.includes('Firefox');

const slideUp = keyframes`
    0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: $nav-link-opacity;
    transform: translateY(0);
  }

`;

const Sidebar = styled.div`
  flex: 0 0 auto;
  width: ${siebarWidth};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: ${isFirefox ? border : undefined}; // This is replaced by the scrollbar in webkit browsers
  height: 100vh;
`;

const Section = styled.div`
  animation: ${slideUp} 300ms ${transtionEase};
  text-align: center;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: ${border};
  padding: calc(${contentSpacing} - ${quarterSpacing}) 0; // Subtract the quarter spacing that the items have extra
  gap: ${halfSpacing};
  p {
    margin-top: 4px;
  }
`;

const Item = styled.div(
  (props: { color?: 'default' | 'light' }) => `
      cursor: pointer;
      opacity: 0.8;
      transition: opacity 0.1s ${transtionEase};
      &.selected,     
      &:hover {
        opacity: 1;
      }

      &:focus {
        outline: 0;
      }

      & > * {
        padding: ${quarterSpacing}; // Make the clickable area bigger
        display: block;
        color: ${props.color === 'light' ? bluePlanetTheme.bluePlanetPalette.grey.dark : 'inherit'};
      }
    `,
);

export default function RightHandSidebar() {
  return (
    <Hidden mdDown>
      <Sidebar>
        <div>
          <Section>
            <Item>
              <NotificationEntry />
            </Item>
          </Section>
          <Section>
            <Item>
              <UserInbox />
            </Item>
            <Item color="light">
              <Link to={userUrls.profile.edit}>
                <SettingsIcon fontSize="small" />
              </Link>
            </Item>
          </Section>
        </div>
      </Sidebar>
    </Hidden>
  );
}
