import { Role, Scope } from 'types/company/company';

export enum ActionTypes {
  FETCHED = 'company/FETCHED',
  COMPANY_CHANGED = 'company/CHANGED_ID',
}

export type fetched = {
  type: ActionTypes.FETCHED;
  payload: {
    company: {
      name: string;
      id: number;
      isCommunity: boolean;
    };
    access: {
      scopes: Scope[];
      roles: Role[];
    };
  };
};

export type CompanyChanged = { type: ActionTypes.COMPANY_CHANGED; payload: { id?: number } };
