import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { useEffect, useRef } from 'react';

const usePitchDeckViewLogger = (
  companyId: number,
  pitchDeckId: string,
  isAdmin: boolean,
): ((pageNumber: number) => void) => {
  const lastPageNumberRef = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const logView = async (duration: number, pageNumber: number) => {
    await companiesApi.profile.pitchDocuments.views.logView(companyId, {
      pitchDeckId,
      pageNumber,
      duration,
    });
  };

  useEffect(() => {
    return () => {
      if (lastPageNumberRef.current === null || startTimeRef.current === null) return;
      const duration = Math.floor((Date.now() - startTimeRef.current) / 1000);
      logView(duration, lastPageNumberRef.current);
    };
  }, [companyId, pitchDeckId]);

  if (isAdmin) return () => {};

  return (pageNumber: number) => {
    if (lastPageNumberRef.current !== null && startTimeRef.current !== null) {
      const duration = Math.floor((Date.now() - startTimeRef.current) / 1000);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (duration < 1) {
        timeoutRef.current = setTimeout(() => {
          logView(duration, lastPageNumberRef.current!);
        }, 600);
      } else {
        logView(duration, lastPageNumberRef.current);
      }
    }

    startTimeRef.current = Date.now();
    lastPageNumberRef.current = pageNumber;
  };
};

export default usePitchDeckViewLogger;
