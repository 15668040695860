import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function AddFolderIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18 13.5v8m4-4h-8M7 6.5h9.75c2.1 0 3.16 0 3.92.5.32.22.6.5.82.83.47.7.5 1.85.5 3.67M12 6.5l-.63-1.27c-.53-1.05-1-2.1-2.17-2.54-.51-.19-1.1-.19-2.26-.19-1.81 0-2.72 0-3.4.38a3 3 0 0 0-1.16 1.16C2 4.72 2 5.63 2 7.44v3.06c0 4.71 0 7.07 1.46 8.54 1.36 1.35 3.48 1.45 7.54 1.46" />
    </SvgIcon>
  );
}
