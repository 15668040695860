import React from 'react';
import { FullFundingRound, FundingRoundListDTO } from 'types/company';
import currencyMap, { DEFAULT_CURRENCY } from 'util/currency';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import FormRow from 'ui/elements/form/FormRow';
import CurrencyInput from 'ui/elements/form/numbers/CurrencyInput';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import Button from 'ui/elements/buttons/Button';
import * as Yup from 'yup';
import styles from '../styles.scss';
import { useFormik } from 'formik';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import { companyUrls } from 'urls';
import { useHistory, useRouteMatch } from 'react-router';
import { useUpdateArchivedRound } from './actions';

interface Props {
  companyId: number;
  companySlug: string;
  archivedFundingRounds: FullFundingRound[];
  setArchiveResource: (func: (prevState: FundingRoundListDTO) => FundingRoundListDTO) => void;
}

const schema = Yup.object().shape({
  isValuationUpdateChecked: Yup.boolean(),
  capitalRaised: Yup.number()
    .nullable()
    .notRequired()
    .lessThan(1000000000000000, 'Capital raised must be less than 1 000 000 000 000 000'),
  capitalRaisedCurrency: Yup.string().oneOf(Object.keys(currencyMap)),
  postMoneyValuation: Yup.number()
    .nullable()
    .lessThan(1000000000000000, 'Post money valuation must be less than 1 000 000 000 000 000')
    .when('isValuationUpdateChecked', ([isValuationUpdateChecked], schema) =>
      isValuationUpdateChecked ? schema.nullable().required('Required when valuation update is checked') : schema,
    ),
  postMoneyValuationCurrency: Yup.string().oneOf(Object.keys(currencyMap)),
});

export default function UpdateArchivedFundingRoundDialog({
  companyId,
  companySlug,
  archivedFundingRounds,
  setArchiveResource,
}: Props) {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();

  const fundingRoundId = match.params.id;

  const fundingRound = archivedFundingRounds.find(r => r.id === fundingRoundId);

  const [onUpdateFundingRound, isSavingUpdateFundingRound] = useUpdateArchivedRound(companyId, fundingRoundId, {
    onSuccess: () => {
      history.push(companyUrls.settings.fundingRound(companySlug));
    },
    onFailure: () => {},
  });

  const formik = useFormik({
    initialValues: {
      capitalRaised: fundingRound?.capitalRaised?.value,
      capitalRaisedCurrency: fundingRound?.capitalRaised?.currency ?? DEFAULT_CURRENCY,
      postMoneyValuation: fundingRound?.postMoneyValuation?.value,
      postMoneyValuationCurrency: fundingRound?.postMoneyValuation?.currency ?? DEFAULT_CURRENCY,
      isValuationUpdateChecked: false,
    },
    onSubmit: values => {
      onUpdateFundingRound({
        fundingRound: {
          capitalRaised: values.capitalRaised,
          capitalRaisedCurrency: values.capitalRaisedCurrency,
          postMoneyValuation: values.postMoneyValuation,
          postMoneyValuationCurrency: values.postMoneyValuationCurrency,
        },
        shouldUpdateValuation: values.isValuationUpdateChecked,
      }).then(() => {
        setArchiveResource(prev => {
          const idx = prev.fundingRounds.findIndex(r => r.id === fundingRound?.id);
          const newArchive = [...prev.fundingRounds];
          if (idx !== -1) {
            newArchive[idx].capitalRaised = {
              value: values.capitalRaised,
              currency: values.capitalRaisedCurrency,
            };
            newArchive[idx].postMoneyValuation = {
              value: values.postMoneyValuation,
              currency: values.postMoneyValuationCurrency,
            };
          }
          return {
            fundingRounds: newArchive,
          };
        });
      });
    },
    validationSchema: schema,
    enableReinitialize: false,
  });

  return (
    <Dialog onClose={() => history.push(companyUrls.settings.fundingRound(companySlug))} open={true} maxWidth="sm">
      <Title onClose={() => history.push(companyUrls.settings.fundingRound(companySlug))}>Update outcome details</Title>
      <form onSubmit={formik.handleSubmit}>
        <Content>
          <p className="u-content-spacing-bottom">How much capital did you raise?</p>

          <FormRow className={styles.formRow}>
            <div className="u-fullWidth">
              <CurrencyInput
                name="capitalRaised"
                value={formik.values.capitalRaised}
                error={formik.touched.capitalRaised ? formik.errors.capitalRaised : undefined}
                onChange={v => formik.setFieldValue('capitalRaised', v)}
                label="Capital raised"
                placeholder="10 000 000"
                currency={formik.values.capitalRaisedCurrency}
                onCurrencyChange={v => formik.setFieldValue('capitalRaisedCurrency', v)}
                touched={formik.touched.capitalRaised}
              />
            </div>
          </FormRow>
          <FormRow className={styles.formRow}>
            <div className="u-fullWidth">
              <CurrencyInput
                name="postMoneyValuation"
                value={formik.values.postMoneyValuation}
                error={formik.touched.postMoneyValuation ? formik.errors.postMoneyValuation : undefined}
                onChange={v => formik.setFieldValue('postMoneyValuation', v)}
                label="Post-money valuation"
                placeholder="10 000 000"
                currency={formik.values.postMoneyValuationCurrency}
                onCurrencyChange={v => formik.setFieldValue('postMoneyValuationCurrency', v)}
                touched={formik.touched.postMoneyValuation}
              />
            </div>
          </FormRow>
          <div className="u-content-spacing-top">
            <Checkbox
              color="primary"
              helperText="Valuation is only visible to company admins, board members and shareholders"
              checked={formik.values.isValuationUpdateChecked}
              label="Update company profile with new valuation"
              onChange={() => {
                formik.setFieldValue('isValuationUpdateChecked', !formik.values.isValuationUpdateChecked);
              }}
            />
          </div>
        </Content>
        <DialogActions>
          <Button className="u-section-padding-x" type="submit" kind="primary" isLoading={isSavingUpdateFundingRound}>
            Close & confirm
          </Button>
          <LinkAsButton url={companyUrls.settings.fundingRound(companySlug)} kind="tertiary">
            Cancel
          </LinkAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
