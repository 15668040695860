import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import { userAPIUrls } from './usersApi';
import { CompanyCardDTO } from 'types/company';

export const featuredMatchesKey = userAPIUrls.matches.featured();
export const useFeaturedMatches = () => {
  const swrResult = useSWR<CompanyCardDTO[]>(featuredMatchesKey);
  return useSWRResource(swrResult);
};
