import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function UnarchiveIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M8.7 6c-2.3 0-3.6.3-4.5 1.2C3 8.4 3 9.8 3 11.4V14c0 3.8 0 5.7 1.2 6.8C5.3 22 7.2 22 11 22h2c3.8 0 5.7 0 6.8-1.2 1.2-1.1 1.2-3 1.2-6.8v-2.6c0-1.6 0-3-1.2-4.2-.8-.8-2.5-1-4.4-1.2M21 12H3M15 17H9" />
      <path d="M12 1.4v6.9m0-7c-.7 0-2 2-2.5 2.6M12 1.4c.7 0 2 2 2.5 2.5" />
    </SvgIcon>
  );
}
