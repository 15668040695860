import React, { useEffect, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import { InvestorType, investorTypes, SelfUserProfile, PatchUserPayload } from 'types/user';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';
import useNotify from 'hooks/useNotify';
import { saveUser } from 'actions/userProfiles';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { USER_WIZARD_CONTENT_MIN_HEIGHT } from '../UserOnboardingWizard';

interface Props {
  onStart?: () => void;
  onComplete: () => void;
  userProfile: SelfUserProfile;
  closeWizard?: () => void;
}

export default function InvestorTypeForm({ userProfile, onComplete, closeWizard, onStart }: Props) {
  const notify = useNotify();
  const [updateUser, isSaving] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onSuccess: () => {
      onComplete();
    },
    onFailure: () => notify('error', `Unable to save changes`),
  });

  const [investorType, setInvestorType] = useState<InvestorType | null>(userProfile.investorType);

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  const options = [
    ...Object.keys(investorTypes).map(v => ({
      type: v,
      name: investorTypes[v].name,
      tooltip: investorTypes[v].description,
    })),
    { type: 'not-investing', name: `I'm not investing` },
  ];

  return (
    <>
      <Content style={{ minHeight: USER_WIZARD_CONTENT_MIN_HEIGHT }}>
        <SelectCloud
          options={options}
          onSelect={v => setInvestorType(v.type)}
          onRemove={() => setInvestorType(null)}
          values={options.filter(o => o.type === investorType)}
        ></SelectCloud>
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button
            onClick={() => {
              const isInvestor =
                investorType === 'not-investing' ? false : investorType !== null ? !!investorType : undefined;
              updateUser({
                investorType: investorType === 'not-investing ' || investorType == null ? undefined : investorType,
                isInvestor: isInvestor,
              });
            }}
            kind="primary"
            isLoading={isSaving}
            className="track-investor-onboarding-type-continue track-wizard-step-continue"
          >
            {closeWizard ? 'Next' : 'Update'}
          </Button>
        </ButtonList>
        {closeWizard && (
          <Button onClick={closeWizard} kind="tertiary" color="grey">
            Pick it up later
          </Button>
        )}
      </DialogActions>
    </>
  );
}
