import { companyUrls } from 'urls';

export type CompanySettingsSections =
  | 'description'
  | 'general-information'
  | 'labels'
  | 'executive-summary'
  | 'market-potential'
  | 'images'
  | 'update-frequency'
  | 'website'
  | 'impact-goals'
  | undefined;

function getIframeHost() {
  return location.origin === 'http://localhost:8080' ? 'http://localhost:1337' : location.origin;
}

export const getIframeCode = (companySlug: string, kind: 'detailed' | 'minimal' = 'minimal') => {
  const height = kind === 'detailed' ? 480 : 320;
  const url =
    kind === 'detailed'
      ? companyUrls.embed.detailedCompanyProfile(companySlug)
      : companyUrls.embed.companyProfile(companySlug);
  return `<iframe src="${getIframeHost()}${url}" title="Embedded company profile widget" width="${EMBED_IFRAME_WIDTH}" height="${height}" style="max-width: 100%; display: block;" frameborder="0"></iframe>`;
};

export const SUCCESS_WIDTH = 95;
export const EMBED_IFRAME_WIDTH = 504;
