import React, { useState } from 'react';
import useDialogHandler from 'hooks/useDialogHandler';
import { useSelector } from 'react-redux';
import { RootStore } from 'globalstate/rootStore';
import CompanyConversationDialogAsUser from 'domain/conversations/CompanyConversationDialogAsUser';
import Drawer from '@mui/material/Drawer';
import styles from './messages.scss';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import { Conversation } from 'apis/ContentAPI/conversationApi';
import UserConversationDialog from '../UserConversationDialog';
import { useConversationMe } from 'apis/CompanyAPI/users/useConversationMe';
import UserInboxContent from './UserInboxContent';
import { OrangeBadge } from 'ui/elements/OrangeBadge';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export const CONVERSATION_DRAWER_ID = 'conversation-drawer';

export default function UserInbox(props: Props) {
  const dialogHandler = useDialogHandler();

  const state = useSelector((state: RootStore) => ({
    companyProfiles: state.companyProfiles.items,
    userProfiles: state.userProfiles.items,
  }));

  const [activeChat, setActiveChat] = useState<Conversation | undefined>();
  const { resource: meResource } = useConversationMe();
  const numUnread = meResource.state === 'fetched' ? meResource.resource.numUnread : 0;

  const company = state.companyProfiles.find(c =>
    activeChat?.type === 'user-company' ? c.id === activeChat.companyConversation.companyId : undefined,
  );

  return (
    <>
      <button
        style={{ ...props.style }}
        data-intercom-target="user-inbox"
        className={props.className}
        onClick={dialogHandler.open}
      >
        <OrangeBadge badgeContent={numUnread > 0 ? numUnread : undefined}>
          <ChatBubblesIcon />
        </OrangeBadge>
      </button>
      {company && <CompanyConversationDialogAsUser isOpen onClose={() => setActiveChat(undefined)} company={company} />}
      {activeChat?.type === 'user-user' && (
        <UserConversationDialog
          conversation={{ type: 'existing', conversation: activeChat }}
          closeConversation={() => setActiveChat(undefined)}
          closeInbox={dialogHandler.close}
        />
      )}
      <Drawer
        id={CONVERSATION_DRAWER_ID}
        anchor="right"
        open={dialogHandler.isOpen}
        onClose={dialogHandler.close}
        PaperProps={{ className: styles.messageList }}
        disableEnforceFocus // input fields that are not handled by mui dialog are unselectable if this is removed
      >
        <UserInboxContent dialogHandler={dialogHandler} setActiveChat={setActiveChat} />
      </Drawer>
    </>
  );
}
