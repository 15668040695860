import { communitiesApi, communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import EventAPI from 'apis/EventAPI';
import { useFormik } from 'formik';
import useNotify from 'hooks/useNotify';
import { invalidate } from 'hooks/useSWR';
import React from 'react';
import { TinyCommunity } from 'types/company/community';
import { DocumentList } from 'types/documents';
import { EventDTO, UpdateEventDTO } from 'ui/domain/Event/types';
import { fetched } from 'util/resource';
import LoadableResource from 'util/resource/Resource';
import useLazyResource from 'util/resource/useLazyResource';
import useResource from 'util/resource/useResource';
import EventDialog, { eventSchema } from './EventDialog';

type Props = {
  event: EventDTO;
  community: TinyCommunity;
  onClose: () => void;
};

export default function EditEventDialog({ event, community, onClose }: Props) {
  const eventsApi = communitiesApi.events;
  const eventsAPIUrls = communityAPIUrls.events;
  const notify = useNotify();

  const [updateEvent, isSaving] = useLazyResource(
    (payload: UpdateEventDTO) => eventsApi.update(community.id, event.id, payload),
    {
      onSuccess: result => {
        notify('success', `Event ${result.title} updated!`);
        invalidate(EventAPI.getUrl(event.id));
        invalidate(communityAPIUrls.events.list(community.slug));
        onClose();
      },
      onFailure: error => notify('error', error || 'Failed to update event'),
    },
  );

  const hasAttachments = event.attachments.length > 0;
  const { resource } = useResource<DocumentList>(
    hasAttachments ? eventsAPIUrls.listAttachments(community.id, event.id) : undefined,
  );
  const attachmentsResource = hasAttachments ? resource : fetched<DocumentList>({ documents: [] });

  const formik = useFormik({
    initialValues: {
      title: event?.title ?? '',
      location: event?.location ?? '',
      locationUrl: event?.locationUrl,
      content: event?.content ?? '',
      calendarContent: event?.calendarContent,
      startsAt: event?.startsAt ?? new Date().toISOString(),
      endsAt: event?.endsAt,
      attachments: event?.attachments ?? [],
      organizations: event.organizations ?? [],
    },
    onSubmit: values => {
      updateEvent({
        ...values,
        organizations: values.organizations.map(o => ({
          organizationId: o.organization.id,
          communityIdList: o.communityList.map(c => c.id),
        })),
      });
    },
    validationSchema: eventSchema,
    enableReinitialize: false,
    validateOnBlur: false,
  });

  return (
    <LoadableResource resource={attachmentsResource}>
      {attachments => (
        <EventDialog
          onClose={onClose}
          formik={formik}
          isSaving={isSaving}
          communityId={community.id}
          attachments={attachments}
          newEvent={false}
        />
      )}
    </LoadableResource>
  );
}
