import { OnboardingPart, OnboardingStep, OrganizationOnboardingStep } from 'apis/OnboardingAPI';
import OrganizationAPI from 'apis/OrganizationAPI';
import { invalidate } from 'hooks/useSWR';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import React, { useEffect } from 'react';
import { Organization, OrganizationType, Service } from 'types/organization';
import Wizard, { OnboardingStage } from 'ui/modules/wizards/Wizard';
import { track } from 'util/analytics';
import { getOrElse } from 'util/resource';
import Resource from 'util/resource/Resource';
import useResource from 'util/resource/useResource';
import OrganizationTypeForm from './OrganizationTypeForm';
import OrganizationServicesForm from './OrganizationServicesForm';
import OrganizationProfileForm from './OrganizationProfileForm';
import OrganizationIndustriesForm from './OrganizationIndustriesForm';
import { IndustryResource } from 'pages/Organization/shared/EditableIndustries';

export default function OrganizationOnboardingWizard({
  organizationSlug,
  wizard,
  setStepCompleted,
  onClose,
}: {
  organizationSlug: string;
  wizard: OnboardingPart;
  setStepCompleted: (step: OnboardingStep) => void;
  onClose: (status: 'Skipped' | 'Completed') => void;
}) {
  const { resource: organizationResource } = useResource<Organization>(OrganizationAPI.getBySlug(organizationSlug));

  return (
    <Resource resource={organizationResource} renderLoading="Nothing">
      {organization => (
        <OrganizationWizard
          organization={organization}
          onboarding={wizard}
          setStepCompleted={setStepCompleted}
          onClose={onClose}
        />
      )}
    </Resource>
  );
}

function OrganizationWizard({
  organization,
  onboarding,
  setStepCompleted,
  onClose,
}: {
  organization: Organization;
  onboarding: OnboardingPart;
  setStepCompleted: (step: OnboardingStep) => void;
  onClose: (status: 'Skipped' | 'Completed') => void;
}) {
  const { resource: organizationTypesResource } = useResource<OrganizationType[]>(OrganizationAPI.listAllTypesUrl);
  const { resource: servicesResource } = useResource<Service[]>(OrganizationAPI.listAllServicesUrl);
  const industryResource = IndustryResource();

  const organizationTypes = getOrElse(organizationTypesResource, []);
  const services = getOrElse(servicesResource, []);
  const industries = getOrElse(industryResource, []);

  useEffect(() => {
    track('organization-onboarding-started');
  }, [onboarding]);

  const completedStages = onboarding.steps.filter(step => step.isCompleted).map(step => step.step);

  function onCloseWizard(status: 'Skipped' | 'Completed', reload = true) {
    onClose(status);
    if (reload) {
      invalidate(userDashboardKey);
    }
    track('organization-onboarding-completed');
  }

  const stages: OnboardingStage<OrganizationOnboardingStep>[] = [
    {
      name: 'OrganizationType',
      title: 'What type of organization are you?',
      subtitle: 'Add the options that best describe your organization',
      content: ({ onComplete }) => (
        <OrganizationTypeForm
          organizationTypes={organizationTypes}
          organization={organization}
          onComplete={() => {
            setStepCompleted('OrganizationType');
            onComplete();
          }}
          onClose={() => onCloseWizard('Skipped')}
        />
      ),
    },
    {
      name: 'OrganizationIndustries',
      title: 'What is your industry and business focus?',
      subtitle: 'Add as many areas as you want, choose at least one category',
      content: ({ onComplete, onBack }) => (
        <OrganizationIndustriesForm
          industries={industries}
          organization={organization}
          onComplete={() => {
            setStepCompleted('OrganizationType');
            onComplete();
          }}
          onClose={() => onCloseWizard('Skipped')}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'Services',
      title: 'What services does your organization offer?',
      subtitle: 'Add the options that best describe your organization',
      content: ({ onComplete, onBack }) => (
        <OrganizationServicesForm
          services={services}
          organization={organization}
          onComplete={() => {
            setStepCompleted('Services');
            onComplete();
          }}
          onClose={() => onCloseWizard('Skipped')}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'OrganizationProfile',
      title: 'Build your profile',
      subtitle:
        'Nice! Preview and prepare your profile to connect with investors, early stage companies and corporates within your industries',
      content: ({ onComplete, onBack }) => (
        <OrganizationProfileForm
          organization={organization}
          onComplete={() => {
            setStepCompleted('OrganizationProfile');
            onComplete();
            invalidate(userDashboardKey);
            onCloseWizard('Completed');
          }}
          onClose={() => onCloseWizard('Skipped')}
          onBack={onBack}
        />
      ),
    },
  ];

  return <Wizard completedStages={completedStages} stages={stages} />;
}
