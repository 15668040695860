import { OrganizationListItemDTO } from 'types/organization';
import Card from 'ui/views/cards/Card';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import { organizationUrls } from 'urls';
import TurnRightIcon from 'ui/elements/icons/TurnRightIcon';
import UserIcon from 'ui/elements/icons/UserIcon';
import React from 'react';
import { ActionLinks } from 'pages/Dashboard/manage/ManageCommunity';

export default function ManageOrganization({ organization }: { organization: OrganizationListItemDTO }) {
  return (
    <div>
      <Card className="u-section-spacing-bottom">
        <ActionLinks>
          <LinkAsButton url={organizationUrls.view(organization.slug)}>
            <TurnRightIcon className="u-half-spacing-right" fontSize="small" />
            Go to organization
          </LinkAsButton>
          <LinkAsButton color="greyLight" url={organizationUrls.settings.connections(organization.slug)}>
            <UserIcon className="u-half-spacing-right" fontSize="small" />
            Invite members
          </LinkAsButton>
        </ActionLinks>
      </Card>
    </div>
  );
}
