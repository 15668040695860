import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';

interface Props {
  className?: string;
  style?: CSSProperties;
  width: MaxWidthSizes;
  children: React.ReactNode;
}

export type MaxWidthSizes = 'large' | 'xl' | 'md' | 'sm' | 'xs';

export const maxWidths = {
  xl: '1805px',
  large: '1440px',
  md: '1140px',
  sm: '740px',
  xs: '600px',
};

const Content = styled.div(
  ({ width }: { width: MaxWidthSizes }) => `
    width: 100%;
    max-width: ${maxWidths[width]};
`,
);
export default function MaxWidth(props: Props) {
  return (
    <Content width={props.width} className={props.className} style={props.style}>
      {props.children}
    </Content>
  );
}
