import React, { useState } from 'react';
import CompanyAPI from 'apis/CompanyAPI';
import IndustryPicker from 'domain/labels/industries/IndustryPicker';
import useNotify from 'hooks/useNotify';
import { Industry } from 'types';
import { CompanyProfile } from 'types/company';
import ButtonList from 'ui/elements/buttons/ButtonList';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import { notEmpty } from 'util/arrayUtils';
import useLazyResource from 'util/resource/useLazyResource';
import Button from 'ui/elements/buttons/Button';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import MaxWidth from 'ui/views/containers/MaxWidth';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import RocketIcon from 'ui/elements/icons/RocketIcon';
import featureToggle from 'featureToggle';

export const predefinedIndustryNames = [
  'software',
  'ai',
  'fintech',
  'renewable energy',
  'education',
  'health',
  'b2b',
  'b2c',
  'saas',
  'technology',
  'sustainability',
  'consumer',
];

export default function IndustryForm({
  industries,
  companyProfile,
  onComplete,
  setUseAiMode,
  onClose,
}: {
  industries: Industry[];
  companyProfile: CompanyProfile;
  onComplete: (industries: Industry[]) => void;
  setUseAiMode: (value: boolean) => void;
  onClose: () => void;
}) {
  const predefinedIndustries = predefinedIndustryNames
    .map(ind => industries.find(industry => industry.name.toLowerCase() === ind))
    .filter(notEmpty);

  const notify = useNotify();
  const { setLastUpdated } = useCompanyLastUpdated(companyProfile.id);

  const [selectedIndustries, setSelectedIndustries] = useState<Industry[]>(
    companyProfile.industries.values.length > 0 ? companyProfile.industries.values : [],
  );
  const [saveIndustries, isSaving] = useLazyResource(
    () => CompanyAPI.industries.update(companyProfile.id, selectedIndustries),
    {
      onSuccess: () => {
        onComplete(selectedIndustries);
        setLastUpdated(new Date());
      },
      onFailure: () => notify('error', 'Failed to update industries'),
    },
  );

  return (
    <>
      <Content>
        <SelectCloud
          options={predefinedIndustries}
          values={selectedIndustries}
          onSelect={industry => setSelectedIndustries([...selectedIndustries, industry])}
          onRemove={industry => setSelectedIndustries(selectedIndustries.filter(ind => ind.id !== industry.id))}
          customInputComponent={
            <MaxWidth width="xs">
              <IndustryPicker
                className="u-half-spacing-top"
                autoFocus
                selectedIndustries={selectedIndustries}
                setIndustries={industries => setSelectedIndustries(industries)}
                hideSelectedValues
              />
            </MaxWidth>
          }
        />
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button isLoading={isSaving} onClick={saveIndustries} kind="primary">
            Next
          </Button>
          {featureToggle.isAiOnboardingEnabled() && (
            <Button color="purpleLight" kind="primary" onClick={() => setUseAiMode(true)}>
              <RocketIcon className="u-quarter-spacing-right" />
              Playful mode? Try our Beta AI helper
            </Button>
          )}
        </ButtonList>
        <Button onClick={onClose} kind="tertiary" color="grey">
          Pick it up later
        </Button>
      </DialogActions>
    </>
  );
}
