import styled from '@emotion/styled';
import { darken, lighten } from '@mui/material';
import { MarketPotential } from 'apis/CompanyAPI/companies/useMarketPotential';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { CompanyProfile } from 'types/company';
import SectionHeading from 'ui/elements/SectionHeading';
import EditAction from 'ui/elements/actions/EditAction';
import CtaButton from 'ui/elements/buttons/CtaButton';
import ColorDot from 'ui/modules/ColorDot';
import { bluePlanetTheme } from 'ui/theme';
import FontRedHat from 'ui/theme/FontRedHat';
import { fontSizeTiny } from 'ui/theme/themeBluePlanet';
import { contentSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import Card from 'ui/views/cards/Card';
import { BODY_ID } from 'ui/views/layouts/BaseLayout/ScrollableBody';
import { useDebouncedCallback } from 'use-debounce';
import { formatCurrency } from 'util/currency';
import { IResource, getOrUndefined } from 'util/resource';
import MarketPotentialDialog from './MarketPotentialDialog';

interface Props {
  company: CompanyProfile;
  marketPotentialResource?: IResource<MarketPotential>;
  reloadMarketPotential: () => void;
  canEditCompany: boolean;
}

export const hasMarketPotential = (marketPotential?: MarketPotential) =>
  marketPotential?.currentMarketFocus ||
  marketPotential?.geographicalMarketPotential ||
  marketPotential?.description ||
  marketPotential?.estimatedAvailableMarket?.value ||
  marketPotential?.estimatedServiceableMarket?.value;

const AttributesGrid = styled.div(
  (props: { hasMarketGraph: boolean }) => `
  display: grid;
  grid-template-columns: ${props.hasMarketGraph ? '155px 1fr 1fr' : 'repeat(2, 1fr)'};
  grid-row-gap: ${contentSpacing};
  grid-column-gap: ${sectionSpacing};

  ${bluePlanetTheme.breakpoints.down('md')} {
    grid-column-gap: ${contentSpacing};
  }

  ${bluePlanetTheme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`,
);

const MarketGraph = styled.div(
  (props: { width: number }) => `
  position: relative;
  width: ${props.width}px;
  height: ${props.width}px;
  grid-row: 1/4; // To make the graph span all the rows of the rest of the props

  ${bluePlanetTheme.breakpoints.down('sm')} {
    display: none;
  }
`,
);

const GridCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AdminHelpInfo = styled.p`
  grid-column: 2/4;

  ${bluePlanetTheme.breakpoints.down('sm')} {
    grid-column: unset;
  }
`;

const EstimatedAvailableMarketCircle = styled.button(
  (props: { largeCircleWidth: number; hasValue: boolean }) => `
  background-color: ${
    props.hasValue
      ? bluePlanetTheme.bluePlanetPalette.violet.dark
      : lighten(bluePlanetTheme.bluePlanetPalette.grey.main, 0.6)
  };
  position: absolute;
  width: ${props.largeCircleWidth}px;
  height: ${props.largeCircleWidth}px;
  border-radius: 50%;
  font-size: ${fontSizeTiny};
  font-family: ${FontRedHat.text};
  color: ${bluePlanetTheme.bluePlanetPalette.blue.main};
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  transition:  ${bluePlanetTheme.transitions.create(['background-color', 'outline', 'color'], {
    duration: bluePlanetTheme.transitions.duration.standard,
    easing: bluePlanetTheme.transitions.easing.easeOut,
  })};

  /* We need to use a before or after-element to get the dark circular outline, as safari does not support circular outlines */
  &:before {
    content: "";
    position: absolute;
    top: -3px; 
    right: -3px; 
    bottom: -3px; 
    left: -3px; 
    border: 1px solid ${bluePlanetTheme.bluePlanetPalette.grey.main};
    border-radius: 50%; 
  }

 ${
   !props.hasValue
     ? `&:hover,
        &:focus {
          outline: none;
          color: ${darken(bluePlanetTheme.bluePlanetPalette.blue.main, 0.3)};
          background-color: ${lighten(bluePlanetTheme.bluePlanetPalette.grey.main, 0.8)};
        }`
     : ''
 }
`,
);

const EstimatedServiceableMarketCircle = styled.button(
  (props: { largeCircleWidth: number; smallCircleWidth: number; hasValue: boolean }) => `
    background-color: ${
      props.hasValue
        ? bluePlanetTheme.bluePlanetPalette.violet.medium
        : lighten(bluePlanetTheme.bluePlanetPalette.grey.main, 0.35)
    };
    position: absolute;
    width: ${props.smallCircleWidth}px;
    height: ${props.smallCircleWidth}px;
    border-radius: 50%;
    bottom: 8px;
    left: calc((${props.largeCircleWidth}px - ${props.smallCircleWidth}px) / 2);
    font-size: ${fontSizeTiny};
    font-family: ${FontRedHat.text};
    color: ${bluePlanetTheme.bluePlanetPalette.blue.main};
    font-weight: ${bluePlanetTheme.typography.fontWeightMedium};

    transition:  ${bluePlanetTheme.transitions.create(['background-color', 'color'], {
      duration: bluePlanetTheme.transitions.duration.standard,
      easing: bluePlanetTheme.transitions.easing.easeOut,
    })};
    
    ${
      !props.hasValue
        ? `
          &:hover,
          &:focus {
            color: ${darken(bluePlanetTheme.bluePlanetPalette.blue.main, 0.7)};
            outline: none;
            background-color: ${lighten(bluePlanetTheme.bluePlanetPalette.grey.main, 0.1)};
          }`
        : ''
    }
`,
);

type Field =
  | 'geographical-potential'
  | 'current-focus'
  | 'estimated-available'
  | 'estimated-serviceable'
  | 'description';

export default function MarketPotentialSection({
  company,
  marketPotentialResource,
  reloadMarketPotential,
  canEditCompany,
}: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  // Decide which fields should be visible when dialog is triggered from the inline elements
  const [dialogTriggerField, setDialogTriggerField] = useState<Field>();

  // We need to add a delayed reset when we close the dialog.
  // Otherwise, we will se the full form in a flash before the dialog is closed when we only
  // have shown one visible field.
  const delayedResetField = useDebouncedCallback(() => setDialogTriggerField(undefined), 100);
  const marketPotential = getOrUndefined(marketPotentialResource);

  if (!canEditCompany && !hasMarketPotential(marketPotential)) {
    return null;
  }

  const openDialog = (field: Field) => {
    setDialogTriggerField(field);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    delayedResetField();

    // Scroll to section if dialog has caused scrolling and the section getting out of view
    const sectionTop = sectionRef.current?.offsetTop;
    const body = document.getElementById(BODY_ID);
    body?.scrollTo({ top: sectionTop && sectionTop < window.scrollY ? sectionTop - 20 : window.scrollY });
  };

  // Get the diameter of the small circle based on the formula for area (π r^2)
  const calculateDiameterOfSmallerCircle = (a: number, b: number, largeDiameter: number) =>
    Math.sqrt(a / b) * largeDiameter;

  const largeCircleWidth = 155;
  const smallCircleWidth =
    marketPotential?.estimatedAvailableMarket?.value != null &&
    marketPotential.estimatedServiceableMarket?.value != null
      ? calculateDiameterOfSmallerCircle(
          marketPotential.estimatedServiceableMarket.value,
          marketPotential.estimatedAvailableMarket.value,
          largeCircleWidth,
        ) * 0.9 // Offset slightly because the inner circle has a distance to the outer. Makes it not 100% correct, but looks better
      : 70;

  const optionalAsterisc = canEditCompany ? '*' : '';

  const hasMarketGraph =
    canEditCompany ||
    (!!marketPotential?.estimatedAvailableMarket?.value && !!marketPotential.estimatedServiceableMarket?.value);
  const hasAnyTopGridAttributes =
    canEditCompany ||
    !!marketPotential?.estimatedAvailableMarket?.value ||
    !!marketPotential?.estimatedServiceableMarket?.value ||
    (marketPotential?.currentMarketFocus ?? []).length > 0 ||
    (marketPotential?.geographicalMarketPotential ?? []).length > 0;

  return (
    <div ref={sectionRef}>
      <SectionHeading
        heading="Market potential"
        data-intercom-target={canEditCompany ? 'company-overview-market-potential-heading' : undefined}
        addMobileMargin
      >
        {canEditCompany && (
          <button onClick={() => setIsDialogOpen(true)} aria-label="Edit section">
            <EditAction tooltip="Edit section" />
          </button>
        )}
      </SectionHeading>
      <Card data-intercom-target={canEditCompany ? 'company-overview-market-potential-card' : undefined}>
        {hasAnyTopGridAttributes && (
          <AttributesGrid hasMarketGraph={hasMarketGraph}>
            {hasMarketGraph && (
              <MarketGraph width={largeCircleWidth}>
                <EstimatedAvailableMarketCircle
                  disabled={!!marketPotential?.estimatedAvailableMarket?.value}
                  onClick={() => openDialog('estimated-available')}
                  hasValue={!!marketPotential?.estimatedAvailableMarket?.value}
                  largeCircleWidth={largeCircleWidth}
                >
                  {!marketPotential?.estimatedAvailableMarket?.value ? (
                    <span
                      // Position the text at the top of the large circle
                      style={{
                        position: 'absolute',
                        top: 20,
                        left: 0,
                        width: '100%',
                      }}
                    >
                      Add
                    </span>
                  ) : (
                    ''
                  )}
                </EstimatedAvailableMarketCircle>
                <EstimatedServiceableMarketCircle
                  onClick={() => openDialog('estimated-serviceable')}
                  disabled={!!marketPotential?.estimatedServiceableMarket?.value}
                  hasValue={!!marketPotential?.estimatedServiceableMarket?.value}
                  largeCircleWidth={largeCircleWidth}
                  smallCircleWidth={smallCircleWidth >= largeCircleWidth ? largeCircleWidth - 15 : smallCircleWidth}
                >
                  {!marketPotential?.estimatedServiceableMarket?.value ? 'Add' : ''}
                </EstimatedServiceableMarketCircle>
              </MarketGraph>
            )}
            {(canEditCompany || marketPotential?.geographicalMarketPotential) && (
              <GridCell>
                <h3
                  className="text-subheading-2"
                  data-intercom-target={canEditCompany ? 'company-overview-geographical-market-potential' : undefined}
                >
                  Geographical market potential{optionalAsterisc}
                </h3>
                {canEditCompany && !marketPotential?.geographicalMarketPotential ? (
                  <CtaButton onClick={() => openDialog('geographical-potential')}>Add</CtaButton>
                ) : (
                  <span className="text-body">{marketPotential?.geographicalMarketPotential?.join(', ')}</span>
                )}
              </GridCell>
            )}
            {(canEditCompany || marketPotential?.currentMarketFocus) && (
              <GridCell>
                <h3
                  className="text-subheading-2"
                  data-intercom-target={canEditCompany ? 'company-overview-current-market-focus' : undefined}
                >
                  Current market focus{optionalAsterisc}
                </h3>
                {canEditCompany && !marketPotential?.currentMarketFocus ? (
                  <CtaButton onClick={() => openDialog('current-focus')}>Add</CtaButton>
                ) : (
                  <span className="text-body">{marketPotential?.currentMarketFocus?.join(', ')}</span>
                )}
              </GridCell>
            )}
            {(canEditCompany || (marketPotential?.estimatedAvailableMarket?.value ?? 0) > 0) && (
              <GridCell>
                <h3
                  className="text-subheading-2"
                  data-intercom-target={canEditCompany ? 'company-overview-available-market' : undefined}
                >
                  Est. available market{optionalAsterisc}
                </h3>
                {canEditCompany && !marketPotential?.estimatedAvailableMarket?.value ? (
                  <CtaButton onClick={() => openDialog('estimated-available')}>Add</CtaButton>
                ) : (
                  <span className="u-flex-align-center text-body">
                    <ColorDot className="u-half-spacing-right" color={bluePlanetTheme.bluePlanetPalette.violet.dark!} />
                    {formatCurrency(false, marketPotential?.estimatedAvailableMarket)} / year
                  </span>
                )}
              </GridCell>
            )}
            {(canEditCompany || (marketPotential?.estimatedServiceableMarket?.value ?? 0) > 0) && (
              <GridCell>
                <h3
                  className="text-subheading-2"
                  data-intercom-target={canEditCompany ? 'company-overview-serviceable-market' : undefined}
                >
                  Est. serviceable market{optionalAsterisc}
                </h3>
                {canEditCompany && !marketPotential?.estimatedServiceableMarket?.value ? (
                  <CtaButton onClick={() => openDialog('estimated-serviceable')}>Add</CtaButton>
                ) : (
                  <span className="u-flex-align-center text-body">
                    <ColorDot
                      className="u-half-spacing-right"
                      color={bluePlanetTheme.bluePlanetPalette.violet.medium}
                    />
                    {formatCurrency(false, marketPotential?.estimatedServiceableMarket)} / year
                  </span>
                )}
              </GridCell>
            )}
            {canEditCompany && (
              <AdminHelpInfo className="text-metadata-regular text-small">
                *Fields are optional and will not be displayed if empty.
              </AdminHelpInfo>
            )}
          </AttributesGrid>
        )}
        {canEditCompany && !marketPotential?.description ? (
          <CtaButton
            className="u-section-spacing-top"
            height={100}
            onClick={() => openDialog('description')}
            explanation="Investors use signals of market potential to estimate the future market or potential for your product or service. Explain the short keywords and numbers above to clarify your ambitions and focus."
          >
            Add description
          </CtaButton>
        ) : (
          <p className={classNames('text-body', { 'u-half-spacing-top': hasAnyTopGridAttributes })}>
            {marketPotential?.description}
          </p>
        )}
      </Card>
      <MarketPotentialDialog
        company={company}
        marketPotential={marketPotential}
        isOpen={isDialogOpen}
        onClose={closeDialog}
        autoFocusField={dialogTriggerField}
        onComplete={() => reloadMarketPotential()}
      />
    </div>
  );
}
