import React, { useState } from 'react';
import { TinyCommunity } from 'types/company/community';
import Button from 'ui/elements/buttons/Button';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import bluePlanetTheme, { fontSizeTiny } from 'ui/theme/themeBluePlanet';
import Card from 'ui/views/cards/Card';

type PostOption = 'greet' | 'share' | 'milestone' | 'team' | 'monthly-summary' | 'event' | 'quarterly-highlights';

function getHelpText(option: PostOption) {
  switch (option) {
    case 'greet':
      return {
        title: 'New opportunities',
        content:
          'Give a warm welcome to your connections and inform them that they can easily follow your traction on CrowdWorks!',
      };
    case 'share':
      return {
        title: 'Grow your network',
        content:
          'Why do you need assistance from your connections in sharing your profile? Who are you hoping will discover it?',
      };

    case 'milestone':
      return {
        title: 'Latest traction signals',
        content: 'What was your most recent milestone, and why is it significant?',
      };
    case 'team':
      return {
        title: 'Latest updates on your organization',
        content:
          'What type of candidate have you recently recruited, and which of their skills do you find most valuable?',
      };

    case 'monthly-summary':
      return {
        title: 'Monthly activity',
        content:
          'What were the notable highlights or challenges last month? Are there any special events or expectations for the coming month?',
      };
    case 'quarterly-highlights':
      return {
        title: 'Quarterly highlights',
        content: 'What are the significant events and metrics from the last quarter that should be shared?',
      };
    case 'event':
      return {
        title: 'Exciting new opportunity',
        content:
          'What excites you about participating in this program or event, and how do you believe it will contribute to your further advancement?',
      };
  }
}

export const getHelpOptions = (community?: TinyCommunity): { name: string; value: PostOption }[] => [
  {
    name: 'Greet connections',
    value: 'greet',
  },
  { name: 'Share our profile', value: 'share' },
  {
    name: 'Monthly summary',
    value: 'monthly-summary',
  },
  { name: 'Latest milestone', value: 'milestone' },
  {
    name: community ? `Joining ${community.name}` : 'Joining an event',
    value: 'event',
  },
  { name: 'Quarterly highlights', value: 'quarterly-highlights' },
  {
    name: 'New team member',
    value: 'team',
  },
];

function UpdateIdeasCard({ helpText }: { helpText: { title: string; content: string } }) {
  return (
    <Card color="grey-medium" className="u-half-spacing-bottom">
      <div
        className="u-half-spacing-bottom"
        style={{
          fontSize: fontSizeTiny,
          textTransform: 'uppercase',
          color: bluePlanetTheme.bluePlanetPalette.grey.dark,
        }}
      >
        {helpText.title}
      </div>
      <div className="text-weight-medium">{helpText.content}</div>
    </Card>
  );
}

export default function UpdateIdeas({
  helpOptions,
  showCustomInput,
  onSelect,
}: {
  helpOptions: { name: string; value: PostOption }[];
  showCustomInput?: boolean;
  onSelect?: () => void;
}) {
  const [selectedHelpOption, setSelectedHelpOption] = useState<{ name: string; value: PostOption } | undefined>();
  const helpText = selectedHelpOption ? getHelpText(selectedHelpOption.value) : undefined;

  return (
    <>
      <SelectCloud
        className="u-section-spacing-bottom u-half-spacing-top"
        options={helpOptions}
        onSelect={item => {
          setSelectedHelpOption(item);
          onSelect && onSelect();
        }}
        values={selectedHelpOption ? [selectedHelpOption] : []}
        onRemove={() => setSelectedHelpOption(undefined)}
        customInputButton={
          showCustomInput && (
            <Button
              onClick={() => {
                setSelectedHelpOption(undefined);
                onSelect && onSelect();
              }}
              kind="tertiary"
              color="indigo"
            >
              Create your own...
            </Button>
          )
        }
      />
      {helpText && <UpdateIdeasCard helpText={helpText} />}
    </>
  );
}
