import ProgressConfirmDialog from 'ui/modules/dialogs/ProgressConfirmDialog';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import React from 'react';
import cx from 'classnames';
import { IResource } from 'util/resource';

interface Props {
  companyName: string;
  onUnfollow: () => Promise<IResource<void>>;
  isUnfollowing: boolean;
  dialogHandler: DialogHandlerProps;
  requestIsPending: boolean;
}

export default function UnfollowDialog(props: Props) {
  const { companyName, requestIsPending } = props;

  return (
    <ProgressConfirmDialog
      maxWidth="sm"
      title={requestIsPending ? `Withdraw request` : `Stop following ${companyName}`}
      dialogHandler={props.dialogHandler}
      onConfirm={() => {
        props.onUnfollow();
        props.dialogHandler.close();
      }}
      confirmLabel={requestIsPending ? 'Withdraw' : 'Unfollow'}
      isConfirming={props.isUnfollowing}
      dismissLabel="Cancel"
      confirmButtonClassName={cx('data-track-unfollow-follow')}
    >
      {requestIsPending
        ? `Are you sure you want to withdraw your request to follow ${companyName}?`
        : `Unfollowing ${companyName} means you will lose access to see their updates, goals and more. If you regret this decision in the future, you can always follow them again`}
    </ProgressConfirmDialog>
  );
}
