import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { uploadDocument } from 'domain/companies/Documents/actions';
import UploadDialog from 'domain/companies/Documents/DocumentCenter/UploadDialog';
import useDialogHandler from 'hooks/useDialogHandler';
import React from 'react';

import { TrustLevel } from 'types/company/company';
import { FolderDTO } from 'types/documents';
import AddFolderIcon from 'ui/elements/icons/AddFolderIcon';
import CreateFolderDialog from './Actions/CreateFolderDialog';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import { invalidate } from 'hooks/useSWR';
import useLazyResource from 'util/resource/useLazyResource';
import DocumentAPI from 'apis/DocumentAPI';
import useNotify from 'hooks/useNotify';
import { documentCenterDocumentsKey, documentCenterFoldersKey } from 'apis/DocumentAPI/useDocuments';

interface Props {
  folderId: FolderDTO['id'] | undefined;
  companyId: number;
  trustLevels: TrustLevel[];
  currentFolder: FolderDTO | undefined;
}

export default function Tools(props: Props) {
  const dialogHandler = useDialogHandler();
  const notify = useNotify();
  const { folderId, companyId, currentFolder } = props;
  const { setLastUpdated } = useCompanyLastUpdated(props.companyId);

  const [createFolder, isCreatingFolder] = useLazyResource(
    (payload: { trustLevels: TrustLevel[]; name: string; parentId?: string }) =>
      DocumentAPI.createFolder(companyId, payload),
    {
      onSuccess: (_, args) => {
        dialogHandler.close();
        invalidate(documentCenterFoldersKey(companyId));
        notify('success', `"${args.name}" folder created`);
      },

      onFailure: message => notify('error', message || 'Could not create folder'),
    },
  );

  return (
    <div className="u-flex-align-center u-flex--wrap">
      <div className="u-flex-align-center">
        <div className="u-half-spacing-right">
          <UploadDialog
            companyId={companyId}
            folderId={folderId}
            uploadDocument={uploadDocument}
            onClose={successfulUploads => {
              if (successfulUploads > 0 && folderId) {
                invalidate(documentCenterDocumentsKey(companyId, folderId));
                setLastUpdated(new Date());
              }
            }}
          />
        </div>
        <>
          <CreateFolderDialog
            companyId={companyId}
            dialogHandler={dialogHandler}
            key={`parent-${currentFolder && currentFolder.id}`}
            parentAccesses={currentFolder && currentFolder.accesses}
            existingAccesses={currentFolder && currentFolder.accesses}
            onSubmit={(name: string, trustLevels: TrustLevel[]) =>
              createFolder({ name, trustLevels, parentId: currentFolder && currentFolder.id })
            }
            loading={isCreatingFolder}
            type="create"
            trustLevels={props.trustLevels}
          />
          <Tooltip title="Add folder" placement="top" disableFocusListener>
            <IconButton color="primary" onClick={dialogHandler.open}>
              <AddFolderIcon />
            </IconButton>
          </Tooltip>
        </>
      </div>
    </div>
  );
}
