import React from 'react';
import { Link } from 'react-router-dom';
import { userUrls } from 'urls';
import { currentYear } from 'util/dateUtils';

export default function PageFooter() {
  return (
    <footer className="u-align-center text-body">
      <p className="u-section-spacing-y" style={{ fontSize: '0.8125rem', lineBreak: 'anywhere' }}>
        <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>
          CrowdWorks © {currentYear()} <span className="u-quarter-spacing-x">•</span>
        </span>
        <span style={{ display: 'inline-block' }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-link-small text-nowrap"
            href="https://crowdworks.it"
          >
            crowdworks.it
          </a>
          <span className="u-quarter-spacing-x">•</span>
          <Link to={userUrls.legal.termsOfUse()} rel="noopener noreferrer" className="text-link-small text-nowrap">
            User terms
          </Link>
          <span className="u-quarter-spacing-x">•</span>
          <Link to={userUrls.legal.privacyPolicy()} className="text-link-small text-nowrap">
            Privacy policy
          </Link>
        </span>
      </p>
    </footer>
  );
}
