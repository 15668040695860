import React from 'react';
import useDialogHandler from 'hooks/useDialogHandler';
import UserConversationDialog from './UserConversationDialog';
import IconButton from 'ui/elements/icons/IconButton';

interface Props {
  conversationWith: UUID[];
  className?: string;
  groupName?: string;
  children: Children;
}

export default function UserConversationButton(props: Props) {
  const { className, conversationWith, children } = props;
  const dialogHandler = useDialogHandler();

  return (
    <>
      <IconButton color="indigo" hoverColor="blue" className={className} onClick={dialogHandler.open}>
        {children}
      </IconButton>
      {dialogHandler.isOpen && (
        <UserConversationDialog
          conversation={{ type: 'withUsers', withCwUserIds: conversationWith }}
          closeConversation={dialogHandler.close}
          groupName={props.groupName}
        />
      )}
    </>
  );
}
