import Snackbar from '@mui/material/Snackbar';
import { closeSnackbar } from 'actions/snackbar';
import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import SnackbarContent from './SnackbarContent';
import { bindActionCreators } from 'redux';
import useSelector from 'hooks/useSelector';

export default function CustomSnackbar() {
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      closeSnackbar,
    },
    dispatch,
  );

  const handleClose = (_event: SyntheticEvent<any>, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    actions.closeSnackbar();
  };

  const snackbar = useSelector(store => store.snackbar);

  return (
    <Snackbar
      key={snackbar.message}
      open={snackbar.open}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={handleClose}
      autoHideDuration={snackbar.durationInMs}
    >
      <div>
        {/* Function component cannot be passed as children to Snackbar
        https://material-ui.com/guides/composition/#caveat-with-refs */}
        <SnackbarContent
          variant={snackbar.variant}
          message={snackbar.message}
          onClose={handleClose}
          action={snackbar.action}
        />
      </div>
    </Snackbar>
  );
}
