import Tooltip from '@mui/material/Tooltip';
import cx from 'classnames';
import React from 'react';

import EditIcon from 'ui/elements/icons/EditIcon';
import { IconProps } from '../icons/SvgIcon';

interface Props {
  className?: string;
  tooltip: string;
  fontSize?: IconProps['fontSize'];
  color?: 'white' | 'blue';
}
export default function EditAction({ className, tooltip, fontSize, color = 'blue' }: Props) {
  return (
    <Tooltip title={tooltip} placement="top">
      <span className={cx('text-link-small u-flex-center u-flex--align-items-center', className)}>
        <EditIcon color={color} fontSize={fontSize ?? 'small'} />
      </span>
    </Tooltip>
  );
}
