import ApiBase from 'apis/ApiBase';
import { withPagination } from 'apis/utils';
import config from 'config';

import { PaginatedResult, PaginationOptions } from 'types/api';
import { Goal, GoalFilter, GoalId, UnbornGoal } from 'types/goals';

const baseUrl = config.REPORTING_API_URL;

const api = ApiBase();

export default {
  goal: (companyId: number) => ({
    list: (filter?: GoalFilter, paginationOptions?: PaginationOptions): Promise<PaginatedResult<Goal>> => {
      return api.get<PaginatedResult<Goal>>(
        withPagination(`${baseUrl}/companies/${companyId}/goals`, paginationOptions, filter),
      );
    },
    get: (goalId: GoalId): Promise<Goal> => api.get<Goal>(`${baseUrl}/companies/${companyId}/goals/${goalId}`),
    create: (dto: UnbornGoal): Promise<Goal> => api.post<Goal>(`${baseUrl}/companies/${companyId}/goals`, dto),
    edit: (goalId: GoalId, dto: UnbornGoal): Promise<Goal> =>
      api.patch<Goal>(`${baseUrl}/companies/${companyId}/goals/${goalId}`, dto),
    delete: (goalId: GoalId): Promise<GoalId> => api.deleteRequest(`${baseUrl}/companies/${companyId}/goals/${goalId}`),
  }),
};
