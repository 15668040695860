import React, { Children, useContext, useEffect, useState } from 'react';
import { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import { contentSpacing } from 'ui/theme/themeConstants';
import { StyledDataCell } from './DataCell';
import { TableContext } from './Table';

type BodyProps = React.HTMLAttributes<HTMLTableSectionElement>;

const EmptyBody = ({ children, ...rest }: BodyProps) => {
  return (
    <tbody {...rest}>
      <tr>
        <StyledDataCell
          colSpan={100}
          style={{
            height: 58,
            padding: contentSpacing,
            fontSize: fontSizeSmall,
          }}
        >
          {children}
        </StyledDataCell>
      </tr>
    </tbody>
  );
};

export const Body = ({ children, ...rest }: BodyProps) => {
  const childrenCount = Children.count(children);
  const context = useContext(TableContext);

  if (!context) {
    throw Error('Table.Body: Component must be wrapped in <Table> to access TableContext.');
  }
  const { isLoading, isFilterActive, infoMessages } = context;

  if (isFilterActive && !infoMessages?.noResultsAfterFilter) {
    throw Error(
      'Table.Body: Table.isFilterActive is set to true, but infoMessages.noResultsAfterFilter is not defined in Table Context.',
    );
  }

  // Keep previous result while loading
  const [previousChildren, setPreviousRows] = useState(children);
  useEffect(() => {
    isLoading === false && setPreviousRows(children);
  }, [isLoading, childrenCount]);

  const childrenToRender = isLoading ? previousChildren : children;
  // Show initial empty row when loading and no results have been fetched previously
  if (childrenCount === 0 && isLoading) {
    return <EmptyBody />;
  }

  // Show message if no results after filtering
  if (childrenCount === 0 && isFilterActive) {
    return <EmptyBody>{infoMessages?.noResultsAfterFilter}</EmptyBody>;
  }

  // Show empty message if no results after loading
  if (childrenCount === 0 && !isLoading) {
    return <EmptyBody>{infoMessages?.noResults}</EmptyBody>;
  }

  return <tbody {...rest}>{childrenToRender}</tbody>;
};
