import ApiBase from 'apis/ApiBase';
import { withPagination } from 'apis/utils';
import config from 'config';
import { CloudinaryUploadSettings, PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { CommentId, Comment, Like } from 'types/content';
import { DocumentDownloadDTO, DocumentList } from 'types/documents';
import { UpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import { BoardroomUpdateDTO, CreateBoardroomUpdateDTO } from './types';

const api = ApiBase();

interface BoardroomUpdateAPI extends UpdateAPI {
  create: (dto: CreateBoardroomUpdateDTO) => Promise<BoardroomUpdateDTO>;
  editUpdate: (updateId: string, dto: CreateBoardroomUpdateDTO) => Promise<BoardroomUpdateDTO>;

  list: (paginationOptions?: PaginationOptions) => Promise<PaginatedResult<BoardroomUpdateDTO>>;
  get: (updateId: string) => Promise<BoardroomUpdateDTO>;

  images: {
    uploadUrl: (filename: string) => Promise<CloudinaryUploadSettings>;
  };
}

const boardroomUpdateAPI = (companyId: number): BoardroomUpdateAPI => {
  const baseUrl = `${config.CONTENT_API_URL}/companies/${companyId}/boardroom-updates`;

  return {
    create: (contentCreateDto: CreateBoardroomUpdateDTO) =>
      api.post<BoardroomUpdateDTO>(`${baseUrl}`, contentCreateDto),
    list: (paginationOptions?: PaginationOptions) =>
      api.get<PaginatedResult<BoardroomUpdateDTO>>(withPagination(`${baseUrl}`, paginationOptions)),
    get: (updateId: string) => api.get<BoardroomUpdateDTO>(`${baseUrl}/${updateId}`),
    editUpdate: (updateId: string, editUpdateDto: CreateBoardroomUpdateDTO) =>
      api.patch<BoardroomUpdateDTO>(`${baseUrl}/${updateId}`, editUpdateDto),
    delete: (updateId: string) => api.deleteRequest(`${baseUrl}/${updateId}`),
    archive: (updateId: string) => api.deleteRequest<void>(`${baseUrl}/${updateId}/archive`, {}),
    unArchive: (updateId: string) => api.patch(`${baseUrl}/${updateId}`, { archived: false }),
    togglePin: (updateId: string, isPinned: boolean) => api.post<void>(`${baseUrl}/${updateId}`, { isPinned }),
    markAsRead: (updateId: string) => api.post<void>(`${baseUrl}/${updateId}/read`, {}),
    attachments: {
      list: (updateId: string) => api.get<DocumentList>(`${baseUrl}/${updateId}/attachments`),
      download: (updateId: string, fileId: string) =>
        api.get<DocumentDownloadDTO>(`${baseUrl}/${updateId}/attachments/${fileId}`),
    },
    likes: {
      list: (updateId: string, paginationOptions?: PaginationOptions) =>
        api.get<PaginatedResult<Like>>(withPagination(`${baseUrl}/${updateId}/likes`, paginationOptions)),
      like: (updateId: string) => api.post<void>(`${baseUrl}/${updateId}/likes`, {}),
      unLike: (updateId: string) => api.deleteRequest(`${baseUrl}/${updateId}/likes`),
    },
    comments: {
      list: (contentId: string, paginationOptions?: PaginationOptions) =>
        api.get<PaginatedResult<Comment>>(withPagination(`${baseUrl}/${contentId}/comments`, paginationOptions)),
      post: (contentId: string, comment: string) =>
        api.post<Comment>(`${baseUrl}/${contentId}/comments`, {
          content: comment,
        }),
      replyToComment: (contentId: string, commentId: string, comment: string) =>
        api.post<Comment>(`${baseUrl}/${contentId}/comments`, {
          content: comment,
          responseTo: commentId,
        }),
      delete: (contentId: string, commentId: CommentId) =>
        api.deleteRequest(`${baseUrl}/${contentId}/comments/${commentId}`),
      getReplies: (contentId: string, commentId: string, paginationOptions?: PaginationOptions) =>
        api.get<PaginatedResult<Comment>>(
          withPagination(`${baseUrl}/${contentId}/comments/${commentId}/replies`, paginationOptions),
        ),
    },
    images: {
      uploadUrl: (filename: string) => {
        return api.post<CloudinaryUploadSettings>(`${baseUrl}/images?filename=${filename}`, {});
      },
    },
  };
};

export default boardroomUpdateAPI;
