import React, { useEffect, useState } from 'react';
import reverse from 'ramda/src/reverse';
import sortBy from 'ramda/src/sortBy';
import { fetched, getOrUndefined, map } from 'util/resource';
import { getUserProfiles } from 'actions/userProfiles';
import { notify } from 'actions/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import MessageField from 'domain/conversations/Message/MessageField';
import Resource from 'util/resource/Resource';
import Messages from './Message/Messages';
import conversationApi, { Message, PostConversationMessage, UserConversation } from 'apis/ContentAPI/conversationApi';
import { fetchConversationByUsers, listAttachments, listMessages } from './actions';
import { RootStore } from 'globalstate/rootStore';
import {
  conversationActions as conversationListActions,
  userConversationAdded,
  userConversationRequestRemoved,
} from 'globalstate/user/conversationList';
import { markConversationAsRead } from 'globalstate/user/conversationList';
import { UserProfile } from 'types/user';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { addItem, emptyPaginatedResult } from 'util/paginationUtils';
import usePaginatedResourceLegacy from 'util/resource/usePaginatedResourceLegacy';
import { notEmpty } from 'util/arrayUtils';
import InfoIcon from 'ui/elements/icons/InfoIcon';
import ConversationHeader from './Message/ConversationHeader';
import { getConversationName } from './utils';
import Avatars from 'domain/conversations/Avatars';
import UserSearch from 'domain/users/UserSearch';
import cx from 'classnames';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import ConversationSettingsButton from './Message/ConversationSettingsButton';
import { SharedContent } from './Message/types';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { uniqBy } from 'ramda';
import UserConversationIntroduction from './UserConversationIntroduction';

type ConversationType =
  | {
      type: 'new';
    }
  | {
      type: 'withUsers';
      withCwUserIds: UUID[];
    }
  | {
      type: 'existing';
      conversation: UserConversation;
    };

interface Props {
  conversation: ConversationType;
  closeConversation: () => void;
  closeInbox?: () => void;
  groupName?: string;
  sharedContent?: SharedContent;
  initialMessage?: string;
}

function isConversation(value: UserConversation | Message | { withCwUserIds: UUID[] }): value is UserConversation {
  return value.hasOwnProperty('userConversation');
}

function getConversation(conversation: ConversationType, withCwUserIds: UUID[], getProfiles: typeof getUserProfiles) {
  if (conversation.type === 'new') return fetchConversationByUsers(withCwUserIds, getProfiles);
  else if (conversation.type === 'existing') {
    getProfiles(
      conversation.conversation.userConversation.activeParticipants.concat(
        conversation.conversation.userConversation.inactiveParticipants,
      ),
    );
    return Promise.resolve(conversation.conversation);
  } else return fetchConversationByUsers(conversation.withCwUserIds, getProfiles);
}

function getDeps(conversation: ConversationType, withCwUserIds: UUID[]) {
  if (conversation.type === 'new') return [withCwUserIds.length];
  else if (conversation.type === 'existing') return [conversation.conversation.id];
  else return conversation.withCwUserIds;
}

function getConversationMembers(conversation: ConversationType, user?: UserProfile) {
  if (conversation.type === 'new') return [];
  else if (conversation.type === 'existing')
    return conversation.conversation.userConversation.activeParticipants
      .concat(conversation.conversation.userConversation.inactiveParticipants)
      .filter(u => u !== user?.cwUserId);
  else return conversation.withCwUserIds;
}

function getUserProfilesByIds(cwUserIds: string[], userProfiles: UserProfile[]) {
  return cwUserIds.map(u => userProfiles.find(up => up.cwUserId === u)).filter(notEmpty);
}

export default function UserConversationDialog(props: Props) {
  const [sharedContent, setSharedContent] = useState(props.sharedContent);
  const dispatch = useDispatch();
  const boundActions = bindActionCreators(
    {
      getUserProfiles,
      notify,
      userConversationAdded,
      userConversationRequestRemoved,
      markConversationAsRead,
    },
    dispatch,
  );

  const [currentConversation, setCurrentConversation] = useState(props.conversation);

  const [hasConversationStarted, setHasConversationStarted] = useState(currentConversation.type !== 'new');

  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);

  const [showSidebar, setShowSidebar] = useState(false);
  const userProfiles = useSelector((state: RootStore) => state.userProfiles.items);

  const [withCwUserIds, setWithCwUserIds] = useState<UUID[]>(getConversationMembers(currentConversation, userProfile));
  const [originalMembers, setOriginalMembers] = useState<UserProfile[]>(
    getUserProfilesByIds(getConversationMembers(currentConversation, userProfile), userProfiles),
  );

  const withUserProfiles = getUserProfilesByIds(withCwUserIds, userProfiles);

  const [notConnectedUsers, setNotConnectedUsers] = useState<UserProfile[]>([]);

  function getConversationActiveMembers(user: UserProfile, activeMembers: UserProfile[]): UserProfile[] {
    return activeMembers.length > 0
      ? activeMembers.map(m => m.cwUserId).includes(user.cwUserId)
        ? uniqBy(
            (user: UserProfile) => user.cwUserId,
            [user, ...activeMembers.filter(m => m.cwUserId !== user.cwUserId)],
          )
        : uniqBy(
            (user: UserProfile) => user.cwUserId,
            activeMembers.filter(m => m.cwUserId !== user.cwUserId),
          )
      : [user];
  }

  function getConversationPendingMembers(user: UserProfile, pendingMembers: UserProfile[]) {
    if (pendingMembers.length > 0) {
      if (pendingMembers.map(m => m.cwUserId).includes(user.cwUserId)) {
        return uniqBy(
          (user: UserProfile) => user.cwUserId,
          [user, ...pendingMembers.filter(m => m.cwUserId !== user.cwUserId)],
        );
      } else {
        return uniqBy(
          (user: UserProfile) => user.cwUserId,
          pendingMembers.filter(m => m.cwUserId !== user.cwUserId),
        );
      }
    } else {
      return [];
    }
  }

  const [conversationName, setConversationName] = useState(
    currentConversation.type === 'existing' ? currentConversation.conversation.name : props.groupName,
  );

  const [userDialogProfile, setUserDialogProfile] = useState<UserProfile>();

  const [conversationResource, , setConversationResource] = useResourceLegacy(
    () => getConversation(currentConversation, withCwUserIds, boundActions.getUserProfiles),
    getDeps(currentConversation, withCwUserIds),
    conversation => setConversationName(conversation.name),
  );

  useEffect(() => {
    if (currentConversation.type === 'withUsers') {
      boundActions.getUserProfiles(currentConversation.withCwUserIds);
    }
  }, [currentConversation.type === 'withUsers' && currentConversation.withCwUserIds]);

  useEffect(() => {
    setOriginalMembers(getUserProfilesByIds(getConversationMembers(currentConversation, userProfile), userProfiles));
  }, [hasConversationStarted]);

  const conversation = getOrUndefined(conversationResource); // The conversation might not exist yet, but we should still be able to create it by posting the

  const withActiveUserProfiles = conversation?.userConversation.activeParticipants
    ? getUserProfilesByIds(conversation?.userConversation.activeParticipants, userProfiles)
    : [];
  const withPendingUserProfiles = conversation?.userConversation.inactiveParticipants
    ? getUserProfilesByIds(conversation?.userConversation.inactiveParticipants, userProfiles)
    : [];

  const [messagesResource, setMessages, , seeMore] = usePaginatedResourceLegacy(
    conversation
      ? paginationOptions => listMessages(conversation.id, paginationOptions || { limit: 10 })
      : () => Promise.resolve(emptyPaginatedResult<Message>({ limit: 10 })),
    [conversation?.id],
    result => {
      if (conversation && result.page === 1) {
        boundActions.markConversationAsRead(conversation);
      }
    },
  );

  const [attachmentResource, setAttachmentState] = useResourceLegacy(
    () => (conversation ? listAttachments(conversation.id) : Promise.resolve({ values: [] })),
    [conversation],
  );

  const updateConversationName = (name?: string) => {
    setConversationName(name);
    if (conversation) {
      dispatch(
        conversationListActions.updated(conversation.id, prev => ({
          ...prev,
          name,
        })),
      );
    }
  };

  const onClickUser = () => setUserDialogProfile(withUserProfiles[0]);

  const title = conversationName ? (
    <span className="u-wrap-text">{conversationName}</span>
  ) : withUserProfiles.length === 1 ? (
    <div className="u-wrap-text">
      <span onClick={onClickUser} style={{ cursor: 'pointer' }}>
        {getConversationName(withUserProfiles)}
      </span>
    </div>
  ) : (
    <span className="u-wrap-text">{getConversationName(withUserProfiles)}</span>
  );

  const addMessage = (message: Message) => setMessages(prevState => addItem(prevState, message));

  const onReply = async (message: PostConversationMessage) => {
    const result = conversation
      ? await conversationApi.user.postMessage(conversation.id, message)
      : await conversationApi.user.createConversation(withCwUserIds, message, props.groupName);

    const latestReply = isConversation(result) ? result.latestReply : result;

    if (isConversation(result)) {
      // This means it is the first message to this conversation
      setConversationResource(() => fetched(result));
      boundActions.userConversationAdded(result);
      setCurrentConversation({ type: 'existing', conversation: result });
    } else if (conversation && conversation.userConversation.rejectedAt) {
      // This means that the user has rejected the conversation request before, but is now contacting the requester
      const approvedConversation = {
        ...conversation,
        latestReply,
        userConversation: {
          ...conversation.userConversation,
          rejectedAt: undefined,
          activeParticipants: [...conversation.userConversation.activeParticipants, userProfile?.cwUserId ?? ''],
          inactiveParticipants: conversation.userConversation.inactiveParticipants.filter(
            p => p !== userProfile?.cwUserId,
          ),
        },
      };
      boundActions.userConversationAdded(approvedConversation);
      setCurrentConversation({
        type: 'existing',
        conversation: approvedConversation,
      });
      setConversationResource(() => fetched(approvedConversation));
    } else if (conversation) {
      dispatch(
        conversationListActions.updated(conversation.id, prev => ({
          ...prev,
          latestReply,
        })),
      );
      setCurrentConversation({ type: 'existing', conversation: conversation });
    }

    addMessage(latestReply);
    setSharedContent(undefined);
    if (!hasConversationStarted) {
      setHasConversationStarted(true);
    }

    setAttachmentState(prevState => ({ values: [...prevState.values, ...latestReply.attachments] }));
    return latestReply;
  };

  const onApproveRequest = async () => {
    if (conversation?.id) {
      try {
        const result = await conversationApi.user.approveConversation(conversation.id);
        setConversationResource(() => fetched(result));
        setCurrentConversation({ type: 'existing', conversation: result });
        boundActions.userConversationAdded(result);
        boundActions.userConversationRequestRemoved(result.id);
      } catch {
        boundActions.notify('error', 'Failed to accept the conversation request');
      }
    }
  };

  const onRejectRequest = async () => {
    if (conversation?.id) {
      try {
        await conversationApi.user.rejectConversation(conversation.id);
        boundActions.userConversationRequestRemoved(conversation.id);
        props.closeConversation();
      } catch {
        boundActions.notify('error', 'Failed to decline the conversation request');
      }
    }
  };

  const messages = map(messagesResource, r => ({
    ...r,
    values: sortBy(
      a => new Date(a.createdAt),
      reverse(r.values).map(c => ({
        ...c,
        align: userProfile?.cwUserId === c.creatorCwUserId ? ('right' as const) : ('left' as const),
      })),
    ),
  }));

  const handleEditingMemberList = () => {
    setHasConversationStarted(false);
    setConversationName('');
  };

  async function checkConnectionToUser(cwUserIds: string[], allUserProfiles: UserProfile[]) {
    if (cwUserIds.length) {
      const isConnectedRequests = cwUserIds.map(async id => ({
        id,
        isConnected: await usersApi.isConnected(id),
      }));
      const isConnectedResponses = await Promise.all(isConnectedRequests);

      setNotConnectedUsers(
        getUserProfilesByIds(
          isConnectedResponses.filter(r => !r.isConnected).map(r => r.id),
          allUserProfiles,
        ),
      );
    } else {
      setNotConnectedUsers([]);
    }
  }

  useEffect(() => {
    if (userProfiles.length > 0) {
      checkConnectionToUser(withCwUserIds, userProfiles);
    }
  }, [withCwUserIds, userProfiles]);

  const onChangeConversationMembers = async (userIds: string[]) => {
    setCurrentConversation({ type: 'new' });
    setWithCwUserIds(userIds);
  };

  const conversationIsWithSelf =
    currentConversation.type === 'withUsers' &&
    currentConversation.withCwUserIds.every(id => id === userProfile?.cwUserId);

  const isConversationPending =
    conversation &&
    userProfile &&
    ((conversation.userConversation.inactiveParticipants.includes(userProfile.cwUserId) &&
      !conversation.userConversation.rejectedAt) ||
      (conversation.userConversation.activeParticipants.includes(userProfile.cwUserId) &&
        conversation.userConversation.activeParticipants.length < 2));

  return (
    <>
      <Resource resource={userProfileResource}>
        {userProfile => (
          <Messages
            attachments={attachmentResource}
            addMessage={addMessage}
            introduction={
              <UserConversationIntroduction members={withUserProfiles} notConnectedMembers={notConnectedUsers} />
            }
            conversationId={conversation?.id}
            conversationName={conversationName}
            setConversationName={updateConversationName}
            setEditingMemberList={handleEditingMemberList}
            user={userProfile}
            activeMembers={getConversationActiveMembers(userProfile, withActiveUserProfiles)}
            pendingMembers={getConversationPendingMembers(userProfile, withPendingUserProfiles)}
            userProfiles={userProfiles}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            onClose={props.closeConversation}
            onNavigate={props.closeInbox}
            enableConversationNameChange={
              withCwUserIds.length > 1 &&
              conversation?.userConversation.activeParticipants.includes(userProfile.cwUserId)
            }
            showApproval={
              conversation?.userConversation.inactiveParticipants.includes(userProfile.cwUserId) &&
              !conversation.userConversation.rejectedAt
            }
            onApproveRequest={onApproveRequest}
            onRejectRequest={onRejectRequest}
            header={
              !hasConversationStarted ? (
                <>
                  <span className="u-half-spacing-right text-weight-medium">To:</span>
                  <UserSearch
                    initialUsers={originalMembers}
                    sharedCompanyId={sharedContent?.type === 'company' ? sharedContent.companyId : undefined}
                    onChange={onChangeConversationMembers}
                    noResultsText={
                      props.sharedContent?.type === 'company'
                        ? 'No results. Companies can only be shared with community members.'
                        : 'No results'
                    }
                  />
                </>
              ) : (
                <ConversationHeader
                  title={title}
                  logo={<Avatars users={withUserProfiles} />}
                  suffix={
                    <ConversationSettingsButton
                      onClick={() => setShowSidebar(!showSidebar)}
                      text={showSidebar ? 'Close Settings' : 'Show Settings'}
                    />
                  }
                  onClickUser={!conversationName && withUserProfiles.length === 1 ? onClickUser : undefined}
                />
              )
            }
            conversation={messages}
            seeMore={seeMore}
          >
            <>
              {conversationIsWithSelf ? (
                <p className="u-align-center u-content-padding">
                  <InfoIcon className="u-half-spacing-right"></InfoIcon>You&apos;re not allowed to write to yourself
                </p>
              ) : (
                <MessageField
                  placeholder={
                    isConversationPending
                      ? 'Conversation is pending. The recipient has to accept the request before you can send more messages.'
                      : 'Enter your message...'
                  }
                  handleSubmit={onReply}
                  autoFocus={currentConversation.type !== 'new'}
                  submitClassName={cx(
                    'data-track-conversation-send-message',
                    'data-track-conversation-send-message-as-user',
                  )}
                  disabled={withCwUserIds.length < 1 || isConversationPending}
                  isConversationPending={isConversationPending}
                  padding="none"
                  sharedContent={sharedContent}
                  characterLimit={conversation?.id || notConnectedUsers.length === 0 ? undefined : 300}
                  initialMessage={props.initialMessage}
                />
              )}
            </>
          </Messages>
        )}
      </Resource>
      {userDialogProfile && (
        <UserProfileDialog cwUserId={userDialogProfile.cwUserId} onClose={() => setUserDialogProfile(undefined)} />
      )}
    </>
  );
}
