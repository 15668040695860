import { CompanyListItem } from 'types/company';
import { MyCommunity } from 'types/company/community';

export const communitiesByRole = (communities: MyCommunity[]) => {
  const communitiesIManage = communities.filter(c => c.roles.includes('company_master'));
  const myCommunities = communities.filter(c => !c.roles.includes('company_master'));

  return { communitiesIManage, myCommunities }
}

export const companiesCommunitiesByRole = (companies: CompanyListItem[], communities: MyCommunity[]) => {
  const companiesIManage = companies.filter(c => c.roles.includes('company_master'));
  const myInvestments = companies.filter(c => c.roles.includes('investor'));  
  const myBoardPositions = companies.filter(c => c.roles.includes('board'));
  const companiesIFollow = companies.filter(c => c.roles.includes('prospective_investor'));

  return {
    ...communitiesByRole(communities),
    companiesIManage,
    myInvestments,
    myBoardPositions,
    companiesIFollow,
  };
};