import companyChangedMiddleware from 'middlewares/companyChangedMiddleware';
import { applyMiddleware, compose, createStore } from 'redux';
import immutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import rootReducer from './globalstate/rootStore';

const finalCreateStore = () => {
  if (__DEV_TOOLS__) {
    return compose(
      applyMiddleware(thunk, immutableStateInvariant(), companyChangedMiddleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f: Function) => f,
    )(createStore);
  }
  return compose(applyMiddleware(thunk, companyChangedMiddleware))(createStore);
};

export default function configureStore() {
  const store = finalCreateStore()(rootReducer);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./globalstate/rootStore', () => {
      const nextRootReducer = require('./globalstate/rootStore'); // eslint-disable-line @typescript-eslint/no-var-requires
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
