import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function LikeIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2 12.5c0-1.1.9-2 2-2a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3 2 2 0 0 1-2-2v-6Z" />
      <path d="m15.5 7.8-.3.9c-.2.7-.3 1-.2 1.3 0 .2.2.4.4.5.2.2.6.2 1.4.2h.4c2.6 0 3.8 0 4.4.8l.2.2c.5.9 0 2-1.1 4.3-1 2.1-1.5 3.2-2.4 3.8l-.2.2c-1 .5-2.2.5-4.5.5H13c-2.9 0-4.3 0-5.2-.9-.9-.8-.9-2.2-.9-5v-1c0-1.4 0-2.1.3-2.8.2-.7.7-1.2 1.7-2.3L13 4l.3-.2a1 1 0 0 1 1.4 0l.2.3.3.4a3.8 3.8 0 0 1 .3 3.3Z" />
    </SvgIcon>
  );
}
