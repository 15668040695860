import usePreviousProps from 'hooks/usePreviousProps';
import React, { useEffect, useState } from 'react';
import Button from 'ui/elements/buttons/Button';

export function OptionPredefined<T>({
  option,
  label,
  onSelect,
  isSelecting,
  autoFocus,
}: {
  option: T;
  label: string;
  onSelect: (value: T) => void;
  isSelecting?: boolean;
  autoFocus?: boolean;
}) {
  const [isSelectingCurrent, setIsSelectingCurrent] = useState(false);

  const onCurrentSelected = () => {
    // If onSelect triggers loading, we want the button to show a spinner until removal is complete
    if (isSelecting != null) {
      setIsSelectingCurrent(true);
    }
  };

  const previousIsSelecting = usePreviousProps(isSelecting);
  useEffect(() => {
    if (isSelecting == null) {
      return;
    }

    // Stop loading when isSelecting change from true to false
    if (previousIsSelecting === true && isSelecting === false) {
      setIsSelectingCurrent(false);
    }
  }, [isSelecting, previousIsSelecting]);

  return (
    <Button
      shape="rounded"
      kind="secondary"
      color="blue"
      className="u-quarter-spacing-right u-quarter-spacing-bottom"
      onClick={() => {
        onSelect(option);
        onCurrentSelected();
      }}
      isLoading={isSelectingCurrent}
      autoFocus={autoFocus}
      hasSubtleHoverStyle
    >
      {label}
    </Button>
  );
}
