import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ReplyIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M14.2 2.6c4.2.2 7.5 3.6 7.8 7.9V13a8.4 8.4 0 0 1-7.8 7.9H9.8l-1.5-.3c-.5-.3-.7-.4-.9-.3l-.7.4c-.6.5-1.5.8-2.7.8-.6 0-1 0-1-.3-.2-.2 0-.5.3-1.2.5-1 .8-2 .3-2.8A8.7 8.7 0 0 1 2 13v-2.5" />
      <path d="M12.8 9.7c-1.7-4-4.2-5.7-9.4-5.4M4 6 2.7 4.7c-.2-.2-.2-.6-.1-.8l1-1.3" />
    </SvgIcon>
  );
}
