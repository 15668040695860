import React from 'react';
import { CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { asShortDateWithoutYear } from 'util/dateUtils';
import { bluePlanetTheme } from 'ui/theme';
import DateHeadingTooltip from './DateHeadingTooltip';
import StatsChartContainer from './StatsChartContainer';
import { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import { useMediaQuery } from '@mui/material';
import { TimeSeriesPoint } from 'apis/CompanyAPI/insights/types';

type Props = {
  visits: TimeSeriesPoint[];
  visitors: TimeSeriesPoint[];
};

const textColor = bluePlanetTheme.bluePlanetPalette.indigo.main;

const blackLegendTextFormatter = (value: string) => {
  return <span style={{ color: textColor, paddingRight: '1rem' }}>{value}</span>;
};

export default function PitchViewGraph({ visits, visitors }: Props) {
  const theme = bluePlanetTheme;
  const isDesktopDown = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <StatsChartContainer>
      <ComposedChart
        data={visits.map((entry, index) => ({
          date: entry.x,
          visits: entry.y,
          visitors: visitors[index].y,
        }))}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis
          dy={10}
          dataKey="date"
          style={{ fill: textColor, fontWeight: 500, fontSize: fontSizeSmall }}
          tickFormatter={value => asShortDateWithoutYear(value)}
          tickLine={false}
          interval={isDesktopDown ? 8 : 4}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          style={{ fill: textColor, fontWeight: 500, fontSize: fontSizeSmall }}
          allowDecimals={false}
        />
        <Legend wrapperStyle={{ paddingTop: '2rem' }} formatter={blackLegendTextFormatter} />
        <CartesianGrid vertical={false} />
        <Tooltip content={<DateHeadingTooltip emptyLabel="No pitch views" />} />
        <Line
          type="monotone"
          dataKey="visits"
          name="Visits"
          stroke={theme.bluePlanetPalette.blue.main}
          strokeWidth={3}
          dot={false}
          legendType="circle"
        />
        <Line
          type="monotone"
          dataKey="visitors"
          name="Unique visitors"
          stroke={theme.bluePlanetPalette.purple.main}
          strokeWidth={3}
          dot={false}
          legendType="circle"
        />
      </ComposedChart>
    </StatsChartContainer>
  );
}
