import ApiBase from 'apis/ApiBase';
import { withPagination } from 'apis/utils';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import {
  AttachmentListDTO,
  CompanyConversation,
  Conversation,
  Message,
  PostConversationMessage,
  SingleConversationMeDTO,
  UserConversation,
} from './conversationTypes';
import { DocumentDownloadDTO } from 'types/documents';
import config from 'config';
export * from './conversationTypes';

const api = ApiBase();
const baseUrl = config.CONTENT_API_URL;

export const conversationApiUrls = {
  user: {
    me: () => `${baseUrl}/conversations/me`,
  },
  company: {
    me: (companyId: number) => `${baseUrl}/companies/${companyId}/conversations/me`,
    list: (companyId: number, paginationOptions?: PaginationOptions) =>
      withPagination(`${baseUrl}/companies/${companyId}/conversations/list`, paginationOptions),
  },
};

export default {
  user: {
    listConversations: (paginationOptions?: PaginationOptions) =>
      api.get<PaginatedResult<Conversation>>(withPagination(`${baseUrl}/conversations`, paginationOptions)),
    listConversationRequests: (paginationOptions?: PaginationOptions) =>
      api.get<PaginatedResult<UserConversation>>(
        withPagination(`${baseUrl}/conversations/requests`, paginationOptions),
      ),
    get: (conversationId: UUID) => api.get<UserConversation>(`${baseUrl}/conversations/${conversationId}`),
    single: {
      get: (companyId: number, paginationOptions: PaginationOptions) =>
        api.get<PaginatedResult<Message>>(
          withPagination(`${baseUrl}/companies/${companyId}/conversations`, paginationOptions),
        ),
      reply: (companyId: number, message: PostConversationMessage) =>
        api.post<Message>(`${baseUrl}/companies/${companyId}/conversations`, message),
    },
    getByUsers: (cwUserIds: UUID[]) =>
      api.get<UserConversation>(`${baseUrl}/conversations/users?ids=${cwUserIds.join('&ids=')}`),
    listMessages: (conversationId: UUID, paginationOptions: PaginationOptions) =>
      api.get<PaginatedResult<Message>>(
        withPagination(`${baseUrl}/conversations/${conversationId}/messages`, paginationOptions),
      ),
    postMessage: (conversationId: UUID, message: PostConversationMessage) =>
      api.post<Message>(`${baseUrl}/conversations/${conversationId}/messages`, message),
    createConversation: (withUsers: UUID[], message: PostConversationMessage, name?: string) =>
      api.post<UserConversation>(`${baseUrl}/conversations`, { users: withUsers, message, name }),
    renameConversation: (conversationId: UUID, name?: string) =>
      api.patch<Message>(`${baseUrl}/conversations/${conversationId}/name`, { name }),
    approveConversation: (conversationId: UUID) =>
      api.post<UserConversation>(`${baseUrl}/conversations/${conversationId}/accept`, {}),
    rejectConversation: (conversationId: UUID) =>
      api.post<void>(`${baseUrl}/conversations/${conversationId}/reject`, {}),
  },
  company: {
    list: (companyId: number, paginationOptions: PaginationOptions) =>
      api.get<PaginatedResult<CompanyConversation>>(
        withPagination(`${baseUrl}/companies/${companyId}/conversations/list`, paginationOptions),
      ),
    get: (companyId: number, cwUserId: string, paginationOptions: PaginationOptions) =>
      api.get<PaginatedResult<Message>>(
        withPagination(`${baseUrl}/companies/${companyId}/conversations/u/${cwUserId}`, paginationOptions),
      ),
    me: (companyId: number, cwUserId: string) =>
      api.get<SingleConversationMeDTO>(`${baseUrl}/companies/${companyId}/conversations/u/${cwUserId}/me`),
    reply: (companyId: number, cwUserId: string, message: PostConversationMessage) =>
      api.post<Message>(`${baseUrl}/companies/${companyId}/conversations/u/${cwUserId}`, message),
  },
  listAttachments: (conversationId: UUID) =>
    api.get<AttachmentListDTO>(`${baseUrl}/conversations/${conversationId}/attachments`),
  downloadAttachment: (fileId: UUID) => api.get<DocumentDownloadDTO>(`${baseUrl}/conversations/attachments/${fileId}`),
  trackConversationOpened: (context: {
    key: string;
    withCompanyId: number;
    path: string;
    openedFromCommunitySlug?: string;
  }) => api.post(`${baseUrl}/conversations/track`, { ...context, eventType: 'open' }),
};
