import React, { useState } from 'react';
import { FundingRoundListDTO } from 'types/company';
import HorizontalDictionary from 'ui/elements/dictionary/HorizontalDictionary';
import Accordion from 'ui/modules/Accordion';
import { getCurrencySymbol } from 'util/currency';
import { asDayMonthNameYear } from 'util/dateUtils';
import { formatThousands } from 'util/numbers';
import FundingStageChip from 'ui/domain/Chips/FundingStageChip';
import { capitalize } from 'util/stringUtils';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import { Hidden } from '@mui/material';
import { Link, useRouteMatch } from 'react-router-dom';

interface Props {
  fundingRounds: FundingRoundListDTO;
}

export default function FundingRoundArchive({ fundingRounds }: Props) {
  const [expandedAccordionIds, setExpandedAccordionIds] = useState<UUID[]>([]);
  const [userDialogId, setUserDialogId] = useState<UUID | undefined>(undefined);
  const match = useRouteMatch();

  const handleExpand = (id: UUID) => {
    if (expandedAccordionIds.includes(id)) {
      setExpandedAccordionIds(expandedAccordionIds.filter(i => i !== id));
    } else {
      setExpandedAccordionIds([...expandedAccordionIds, id]);
    }
  };

  return (
    <>
      {fundingRounds.fundingRounds.map((round, idx) => (
        <Accordion
          key={idx}
          title={
            <div className="u-fullWidth u-flex u-flex-align-center">
              <div style={{ width: '28%' }}>{format(new Date(round.openingDate), 'LLLL yyyy', { locale: enUS })}</div>
              <Hidden smDown smUp={expandedAccordionIds.includes(round.id)}>
                <div className="text-metadata" style={{ width: '48%' }}>
                  From {asDayMonthNameYear(round.openingDate)} to{' '}
                  {asDayMonthNameYear(round.actualClosingDate ?? round.plannedClosingDate)}
                </div>
              </Hidden>
              <Hidden mdDown mdUp={expandedAccordionIds.includes(round.id)}>
                <div className="text-metadata">
                  Raised:{' '}
                  {round.capitalRaised?.value
                    ? `${getCurrencySymbol(round.capitalRaised?.currency)} ${formatThousands(
                        round.capitalRaised?.value,
                      )}`
                    : 'N/A'}
                </div>
              </Hidden>
            </div>
          }
          isExpanded={expandedAccordionIds.includes(round.id)}
          onChange={() => handleExpand(round.id)}
          className="u-quarter-spacing-top"
          border="none"
        >
          <HorizontalDictionary columnCount={2} columnWidth={168}>
            {[
              {
                key: 'Capital seeking',
                value: `${getCurrencySymbol(round.capitalSeeking.currency)} ${formatThousands(
                  round.capitalSeeking.value,
                )}`,
              },
              {
                key: 'Capital raised',
                value: round.capitalRaised?.value ? (
                  `${getCurrencySymbol(round.capitalRaised?.currency)} ${formatThousands(round.capitalRaised?.value)}`
                ) : (
                  <div>
                    <span className="u-content-spacing-right">N/A</span>
                    <Link to={`${match.url}/edit/${round.id}`}>
                      <span className="text-link text-weight-regular text-small">Update</span>
                    </Link>
                  </div>
                ),
              },
              {
                key: 'Pre-money valuation',
                value: `${getCurrencySymbol(round.preMoneyValuation.currency)} ${formatThousands(
                  round.preMoneyValuation.value,
                )}`,
              },
              {
                key: 'Funding stage',
                value: round.fundingStage ? <FundingStageChip label={round.fundingStage?.name} /> : 'N/A',
              },
              {
                key: 'Previous valuation',
                value: `${getCurrencySymbol(round.previousValuation.currency)} ${formatThousands(
                  round.previousValuation.value,
                )}`,
              },
              { key: 'Type', value: capitalize(round.type) },
              { key: 'Opening date', value: asDayMonthNameYear(round.openingDate) },
              {
                key: 'Planned closing date',
                value: asDayMonthNameYear(round.plannedClosingDate),
              },
              {
                key: 'Closing date',
                value: round.actualClosingDate ? asDayMonthNameYear(round.actualClosingDate) : 'N/A',
              },
              {
                key: 'Lead investor',
                value: round.leadInvestor ? (
                  <div>
                    <span className="u-content-spacing-right">{round.leadInvestor?.name}</span>
                    <button
                      className="text-link text-weight-regular text-small"
                      onClick={() => setUserDialogId(round.leadInvestor?.cwUserId)}
                    >
                      View profile
                    </button>
                  </div>
                ) : (
                  'N/A'
                ),
              },
            ]}
          </HorizontalDictionary>
          {userDialogId && <UserProfileDialog cwUserId={userDialogId} onClose={() => setUserDialogId(undefined)} />}
        </Accordion>
      ))}
      <div style={{ height: '90vh' }} />
    </>
  );
}
