import React from 'react';
import { TooltipProps } from 'recharts';
import { asShortDate } from 'util/dateUtils';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import Card from 'ui/views/cards/Card';

export default function DateHeadingTooltip({
  active,
  payload,
  label,
  emptyLabel,
}: TooltipProps<ValueType, NameType> & { emptyLabel: string }) {
  if (active && payload && payload.length) {
    if (payload.every(entry => entry.value === 0)) {
      return (
        <Card elevation={2}>
          <p>
            <strong>{asShortDate(label)}</strong>
          </p>
          <p>{emptyLabel}</p>
        </Card>
      );
    } else {
      return (
        <Card elevation={2}>
          <p>
            <strong>{asShortDate(label)}</strong>
          </p>
          {payload.map(p => (
            <p key={p.dataKey}>
              <strong>{p.value}</strong> {(p.name?.toString() || '').toLowerCase()}
            </p>
          ))}
        </Card>
      );
    }
  }

  return null;
}
