import React from 'react';
import PhoneIcon from 'ui/elements/icons/PhoneIcon';
import cx from 'classnames';

interface Props {
  className?: string;
  phone: string;
  linkIsDisabled?: boolean;
}

export default function Phone({ className, phone, linkIsDisabled = false }: Props) {
  return linkIsDisabled ? (
    <span
      className={cx(className, 'u-flex-align-center text-link-small text-link-dark')}
      style={{ whiteSpace: 'nowrap' }}
    >
      <PhoneIcon fontSize="small" className="u-quarter-spacing-right" />
      <span title={phone}>{phone}</span>
    </span>
  ) : (
    <a
      href={`tel:${phone}`}
      className={cx(className, 'u-flex-align-center text-link-small text-link-dark')}
      style={{ whiteSpace: 'nowrap' }}
    >
      <PhoneIcon fontSize="small" className="u-quarter-spacing-right" />
      <span title={phone}>{phone}</span>
    </a>
  );
}
