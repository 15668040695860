import {
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DialogTitleProps } from '@mui/material/DialogTitle';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import React from 'react';
import MaxWidth from 'ui/views/containers/MaxWidth';
import { DialogContext } from '../Dialog';

interface Props {
  children?: React.ReactNode;
  onClose: () => void;
  open: boolean;
  disableEnforceFocus?: boolean;
}

type TitleProps = DialogTitleProps & { onClose?: () => void };

export const Title = (props: TitleProps) => (
  <DialogTitle>
    <div className="u-flex-space-between u-flex-align-center">
      {props.children || <span />}
      {props.onClose && (
        <IconButton color="inherit" onClick={props.onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      )}
    </div>
  </DialogTitle>
);

export function Content(props: DialogContentProps & { padding?: 'none' | 'default' }) {
  return (
    <DialogContent
      style={{ ...props.style, padding: props.padding === 'none' ? 0 : undefined }}
      {...props}
      sx={{
        ...props.sx,
        overflowY: 'visible',
      }}
    />
  );
}

export default function MuiFullscreenDialog(props: Props) {
  const leftMenuWidth = '80px';
  const headerHeight = '78px';
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <DialogContext.Provider value={{ onClose: props.onClose }}>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose}
        scroll="body"
        sx={{
          fontFamily: theme.typography.fontFamily,
          marginLeft: smallScreen ? 0 : leftMenuWidth,
          marginTop: smallScreen ? 0 : headerHeight,
          '.MuiDialog-paperFullScreen': {
            backgroundColor: theme.bluePlanetPalette.grey.light,
          },
        }}
        BackdropProps={{ style: { left: smallScreen ? 0 : leftMenuWidth } }}
        disableEnforceFocus={props.disableEnforceFocus}
      >
        <MaxWidth width="xl">{props.children}</MaxWidth>
      </Dialog>
    </DialogContext.Provider>
  );
}
