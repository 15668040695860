import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function PreferencesIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M4 11v10M19 13v8M19 3v4M11.5 3v10M4 3v2M11.5 19v2M2 9.5v-.9l.6-.5.9-.1h1.9l.5.6.1.9v.9a1 1 0 0 1-.6.5l-.9.1H2.6a1 1 0 0 1-.5-.6L2 9.5ZM17 11.5v-.9l.6-.5.9-.1h1.9l.5.6.1.9v.9a1 1 0 0 1-.6.5l-.9.1h-1.9a1 1 0 0 1-.5-.6l-.1-.9ZM9.5 14.5v-.9l.6-.5.9-.1h1.9l.5.6.1.9v.9a1 1 0 0 1-.6.5l-.9.1h-1.9a1 1 0 0 1-.5-.6l-.1-.9Z" />
    </SvgIcon>
  );
}
