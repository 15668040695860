import { Badge, BadgeProps } from '@mui/material';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';

export function OrangeBadge(props: BadgeProps) {
  return (
    <Badge
      {...props}
      sx={{
        '.MuiBadge-badge': {
          color: bluePlanetTheme.bluePlanetPalette.white,
          backgroundColor: bluePlanetTheme.bluePlanetPalette.orange.main,
          fontWeight: 500,
          fontSize: '0.75rem',
          height: bluePlanetTheme.spacing(2),
          minWidth: bluePlanetTheme.spacing(2),
          padding: 0,
        },
      }}
    />
  );
}
