import Resources from 'util/resource/Resources';
import Select from 'ui/elements/form/Select/Select';
import React from 'react';
import { Industry, Stage } from 'types';
import Label from 'ui/elements/form/Label';
import { useStages } from 'apis/CompanyAPI/labels/useStages';

interface Props {
  label?: string;
  productStage?: Stage;
  industries: Industry[];
  onChange: (stage?: Stage) => void;
  autoFocus?: boolean;
  style?: React.CSSProperties;
}
export default function StagePicker(props: Props) {
  const { productStage, label, industries, onChange } = props;

  const { resource: allStages } = useStages();

  return (
    <Resources resources={[allStages]}>
      {([allStages]) => {
        const parentIndustries = industries.filter(ind =>
          allStages.industryDependent.some(c => c.parents.some(p => p.id === ind.id)),
        );
        const children = allStages.industryDependent.filter(stage =>
          stage.parents.some(parentIndustry => parentIndustries.some(ind => ind.id === parentIndustry.id)),
        );

        const options = [...allStages.values, ...children];

        return (
          <div style={props.style}>
            {label && <Label>{label} </Label>}
            <Select
              autoComplete="off"
              name="stages"
              placeholder="Select product stage"
              onChange={onChange}
              value={productStage}
              getOptionLabel={stage => stage?.name || ''}
              options={options}
              autoFocus={props.autoFocus}
            />
          </div>
        );
      }}
    </Resources>
  );
}
