import useAccordionHandler from 'hooks/useAccordionHandler';
import React from 'react';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import cx from 'classnames';
import styles from './accordion.scss';

interface Props {
  children: React.ReactNode;
  title?: React.ReactNode;
  viewMoreText?: string;
  viewLessText?: string;
  align?: 'right' | 'bottom';
  className?: string;
}

export default function SmallAccordion(props: Props) {
  const accordionProps = useAccordionHandler(false);

  return (
    <div
      className={cx(
        'u-flex u-flex-align-baseline',
        {
          ['u-flex-space-between']: props.align === 'right',
          ['u-flex--column']: props.align === 'bottom',
        },
        props.className,
      )}
    >
      <div
        className={cx({
          ['u-ellipsis']: !accordionProps.isExpanded && !!props.title,
          ['u-half-spacing-right']: props.align === 'right',
          ['u-flex-column-1']: props.align === 'bottom',
        })}
      >
        {props.title && <span className={cx(styles.title, '')}>{props.title}</span>}
        <div
          className={cx(styles.content, {
            [styles.open]: accordionProps.isExpanded,
          })}
        >
          {props.children}
        </div>
      </div>
      <div
        className={cx(styles.linkContainer, {
          ['u-flex--aSelf-center']: props.align === 'bottom',
        })}
      >
        <button
          className={cx('text-link-small', styles.viewMore)}
          onClick={accordionProps.isExpanded ? accordionProps.collapse : accordionProps.expand}
        >
          <span>
            {!accordionProps.isExpanded ? `${props.viewMoreText || 'View more'}` : `${props.viewLessText || 'Close'}`}
          </span>
          <ChevronDownIcon
            className={cx('u-quarter-spacing-left', styles.viewMoreIcon, {
              [styles.iconOpen]: accordionProps.isExpanded,
            })}
            strokeWidth={2}
            fontSize="small"
          ></ChevronDownIcon>
        </button>
      </div>
    </div>
  );
}
