import React from 'react';
import { Todo } from './Todo';
import Button from 'ui/elements/buttons/Button';
import { Network } from 'ui/illustrations/Illustrations.stories';
import ContactMeButton from 'domain/users/ContactMeButton';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { useCloseableMessage } from 'apis/CompanyAPI/users/useUiState';

export default function PromoteInvestorMatching({ companyId }: { companyId: number }) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const { isClosed, closeMessage, isLoading } = useCloseableMessage(`promote-investor-matching-${companyId}`);
  const contactMeKey = `wants-investor-matching-${companyId}`;
  const { isClosed: dismissButtonIsHidden } = useCloseableMessage(contactMeKey);

  return (
    !isLoading &&
    !isClosed && (
      <Todo>
        <Network />
        <div className={`u-flex ${isMobile ? 'u-flex--column u-flex--gap-half' : 'u-flex--gap-2'}`}>
          <span style={{ margin: 'auto 0' }}>
            <b>Investor matching</b> will get you hot leads from our network and increase your chances of building
            relevant connections. Prices from €500.
          </span>
          <div className="u-flex-align-center u-flex--gap-1">
            <ContactMeButton
              tag={contactMeKey}
              feedback="InterestedInInvestorMatchingDashboard"
              companyId={companyId}
            />
            {!dismissButtonIsHidden && (
              <Button kind="tertiary" color="grey" onClick={closeMessage}>
                Dismiss
              </Button>
            )}
          </div>
        </div>
      </Todo>
    )
  );
}
