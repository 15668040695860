import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';

interface GridProps {
  columnCount: number;
}

export const CardGrid = styled.div(
  (props: { mobile: GridProps; tablet: GridProps; desktop: GridProps; xl: GridProps; xxl: GridProps }) => `
    display: grid;
    grid-template-columns: repeat(${props.mobile.columnCount}, 1fr);
    gap: 1rem 1rem;
    ${bluePlanetTheme.breakpoints.up('sm')} {
      grid-template-columns: repeat(${props.tablet.columnCount}, 1fr);
    }
  
    ${bluePlanetTheme.breakpoints.up('md')} {
      grid-template-columns: repeat(${props.desktop.columnCount}, 1fr);
    }

    ${bluePlanetTheme.breakpoints.up('lg')} {
        grid-template-columns: repeat(${props.xl.columnCount}, 1fr);
      }
  
    ${bluePlanetTheme.breakpoints.up('xl')} {
      grid-template-columns: repeat(${props.xxl.columnCount}, 1fr);
    }
  `,
);

interface GridWithVisibileCardLimitProps {
  visibleCardsCount: number;
  columnCount: number;
}

export const CardGridWithLimit = styled(CardGrid)(
  (props: {
    mobile: GridWithVisibileCardLimitProps;
    tablet: GridWithVisibileCardLimitProps;
    desktop: GridWithVisibileCardLimitProps;
    xl: GridWithVisibileCardLimitProps;
    xxl: GridWithVisibileCardLimitProps;
  }) => `
  
    > * {
      ${bluePlanetTheme.breakpoints.down('sm')} {
        &:nth-of-type(n + ${props.mobile.visibleCardsCount + 1}) {
          display: none;
        }
      }
  
      ${bluePlanetTheme.breakpoints.between('sm', 'md')} {
        &:nth-of-type(n + ${props.tablet.visibleCardsCount + 1}) {
          display: none;
        }
      }
  
      ${bluePlanetTheme.breakpoints.between('md', 'lg')} {
        &:nth-of-type(n + ${props.desktop.visibleCardsCount + 1}) {
          display: none;
        }
      }

      ${bluePlanetTheme.breakpoints.between('lg', 'xl')} {
        &:nth-of-type(n + ${props.xl.visibleCardsCount + 1}) {
          display: none;
        }
      }
  
      ${bluePlanetTheme.breakpoints.up('xl')} {
        &:nth-of-type(n + ${props.xxl.visibleCardsCount + 1}) {
          display: none;
        }
      }
    }
  `,
);
