import { post } from 'apis/ApiBase';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import { SelfUserProfile } from 'types/user';

declare global {
  interface Window {
    heap?: any;
  }
}

/*
This method should be used sparingly, as heap autocaptures all pageviews and button clicks and allows
us to retroactivly define events based on URLs and CSS selectors. However, it isn't able to track
everything and that's where this method comes in.

Heap's cheat-sheet for using custom event tracking:

* Events that are not autocaptured. Example: pop-ups that haven’t been explicitly triggered by a 
  pageview or some type of user interaction.
* Events with a lot of custom metadata. For example, for a ‘Purchase’ event, you may want 
  to also bring in ‘Subtotal’, ‘Quantity’, ‘Color’, ‘Shipping Type’ as properties of that event. 
  You can also use Snapshots to capture this data; however, using the Track API makes it 
  less susceptible to changes on your website. 
* Events that are mission-critical. This is typically any key conversion event, 
  e.g. ‘Membership Started’, where you need the data in Heap to match your backend exactly. 
  In this case, you should use the server-side API. 

Note: newly tracked custom events will appear in heap after a couple of hours, 
and is defined as described in https://help.heap.io/define-analyze/data-management/creating-and-managing-events/#block-9850bdee-31ae-4f0e-9351-478d7da72564
*/
export function track(event: string, properties?: Object) {
  if (window.heap) {
    window.heap.track(event, properties);
  }
}
export function setUserProperties(properties: Object) {
  if (window.heap) {
    window.heap.addUserProperties(properties);
  }
}

export function updateUserSegment(user: Pick<SelfUserProfile, 'isInvestor' | 'isMentor' | 'investorType'>) {
  setUserProperties({ isInvestor: user.isInvestor, investorType: user.investorType, isMentor: user.isMentor });
}

interface TrackDto {
  path: string;
  queryParams: string;
  anonymousId?: UUID;
}

const EXCLUDED_PATHS = ['/auth0/callback'];

export function trackPageView({
  path,
  queryParams,
  anonymousId,
}: {
  path: string;
  queryParams: URLSearchParams;
  anonymousId?: UUID;
}) {
  const paramsObj = Array.from(queryParams.entries()).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  const params = JSON.stringify(paramsObj);

  if (EXCLUDED_PATHS.includes(path)) return;
  const payload: TrackDto = { path, queryParams: params, anonymousId };
  post(companiesAPIUrls.track(), payload);
}
