import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function LogoutIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m11 3-.7.2c-2.5 1-3.8 1.4-4.6 2.4C5 6.6 5 8 5 10.8v2.4c0 2.8 0 4.1.7 5.2.8 1 2 1.5 4.6 2.4l.7.2" />
      <path d="M21 12H11m10 0c0-.7-2-2-2.5-2.5M21 12c0 .7-2 2-2.5 2.5" />
    </SvgIcon>
  );
}
