import React from 'react';
import useDialogHandler from 'hooks/useDialogHandler';
import IconButton from '@mui/material/IconButton';
import WriteIcon from 'ui/elements/icons/WriteIcon';
import UserConversationDialog from './UserConversationDialog';

interface Props {
  className?: string;
}

export default function NewConversationButton(props: Props) {
  const { className } = props;
  const dialogHandler = useDialogHandler();

  return (
    <>
      <IconButton className={className} onClick={dialogHandler.open}>
        <WriteIcon />
      </IconButton>
      {dialogHandler.isOpen && (
        <UserConversationDialog conversation={{ type: 'new' }} closeConversation={dialogHandler.close} />
      )}
    </>
  );
}
