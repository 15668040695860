import CircularProgress from '@mui/material/CircularProgress';
import React, { CSSProperties } from 'react';
import cx from 'classnames';

interface Props {
  position?: 'center';
  spacing?: 'none' | 'content-spacing';
  size?: number;
  style?: CSSProperties;
  className?: string;
  color?: string;
}

export default function Loading(props: Props) {
  return (
    <div
      style={props.style}
      className={cx(props.className, {
        ['u-align-center']: props.position === 'center',
        ['u-content-spacing']: props.spacing === 'content-spacing',
      })}
    >
      <CircularProgress style={{ color: props.color }} variant="indeterminate" size={props.size ?? 40} />
    </div>
  );
}
