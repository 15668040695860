import React, { useState } from 'react';
import Dialog, { Content, Title, DialogActions } from 'ui/views/dialogs/Dialog';
import { UserProfile } from 'types/user';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import UserElement from 'ui/domain/Users/UserElement';
import TextField from 'ui/elements/form/TextField';
import Button from 'ui/elements/buttons/Button';
import EditIcon from 'ui/elements/icons/EditIcon';
import useIO from 'util/resource/useLazyResource';
import useNotify from 'hooks/useNotify';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import Accordion from 'ui/modules/Accordion';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import IconButton from 'ui/elements/icons/IconButton';
import styles from './styles.scss';
import { AttachmentListDTO } from 'apis/ContentAPI/conversationTypes';
import { IResource } from 'util/resource';
import LoadableResource from 'util/resource/Resource';
import DownloadableFile from 'domain/shared/Files/File/DownloadableFile';
import { downloadAttachment } from 'domain/conversations/actions';
import LinkButton from 'ui/elements/buttons/LinkButton';
import ReportDialog from 'domain/antifraud/ReportDialog';
import cx from 'classnames';
import useWindowHeight from 'hooks/useWindowHeight';
import FlagIcon from 'ui/elements/icons/FlagIcon';
import AntifraudAPI, { Report } from 'apis/AntifraudAPI';

interface EditProps {
  name?: string;
  dialogHandler: DialogHandlerProps;
  onSave: (name?: string) => Promise<void>;
  onSuccess?: (name?: string) => void;
}

function EditNameDialog(props: EditProps) {
  const { onSuccess } = props;
  const [value, setValue] = useState(props.name || '');

  const notify = useNotify();
  const [onSave, isSaving] = useIO(props.onSave, {
    onSuccess: onSuccess ? () => onSuccess(value) : undefined,
    onFailure: message => notify('error', message || `Unable to change conversation name, please try again later.`),
  });

  return (
    <Dialog open={props.dialogHandler.isOpen} onClose={props.dialogHandler.close}>
      <Title onClose={props.dialogHandler.close}>Change conversation name</Title>
      <Content>
        <TextField autoFocus value={value} onChange={e => setValue(e.currentTarget.value)} />
      </Content>
      <DialogActions>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          Cancel
        </Button>
        <Button isLoading={isSaving} onClick={() => onSave(value)} kind="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  conversationId?: UUID;
  showSidebar: boolean;
  attachments?: IResource<AttachmentListDTO>;
  closeSidebar?: () => any;
  dialogHandler: DialogHandlerProps;
  conversationName?: string;
  setConversationName?: (name: string) => void;
  activeMembers?: UserProfile[];
  pendingMembers?: UserProfile[];
  onEdit: (name?: string) => Promise<void>;
  fullScreen: boolean;
  onClose?: () => void;
  enableConversationNameChange?: boolean;
  setEditingMemberList?: () => void;
}

export function ConversationSidebar(props: Props) {
  const [userDialogProfile, setUserDialogProfile] = useState<UserProfile>();
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const windowHeight = useWindowHeight();
  const { conversationId } = props;

  const handleEditMemberList = () => {
    if (props.setEditingMemberList && props.closeSidebar) {
      props.setEditingMemberList();
      props.closeSidebar();
    }
  };

  return (
    <>
      <div className={styles.sidebar}>
        <Title className={styles.header} onClose={props.onClose} closeIconIndent="double">
          <div className="u-flex-align-center">
            {props.fullScreen && props.closeSidebar && (
              <IconButton color="indigo" onClick={() => props.closeSidebar && props.closeSidebar()}>
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
            )}
            <span>Settings</span>
          </div>
        </Title>
        <div
          className={cx(styles.sidebarContent, {
            [styles.fullScreen]: props.fullScreen,
            [styles.large]: windowHeight < 1080,
          })}
        >
          <Accordion
            title="Members"
            isExpanded={true}
            paddingHeader="double"
            paddingContent="none"
            summaryBackgroundColor="grey-light"
            border="bottom"
          >
            {props.activeMembers &&
              props.activeMembers.map(user => (
                <UserElement
                  className={styles.clickableCard}
                  onClick={() => setUserDialogProfile(user)}
                  user={user}
                  key={`sidebar-${user.id}`}
                  textOverflow="hide"
                />
              ))}
            {props.setEditingMemberList && props.closeSidebar && (
              <div className={styles.addPerson}>
                <LinkButton onClick={handleEditMemberList}>Add person</LinkButton>
              </div>
            )}
          </Accordion>
          {props.pendingMembers && props.pendingMembers.length > 0 && (
            <Accordion
              title="Pending"
              isExpanded={true}
              paddingHeader="double"
              paddingContent="none"
              summaryBackgroundColor="grey-light"
              border="bottom"
            >
              {props.pendingMembers.map(user => (
                <UserElement
                  className={styles.clickableCard}
                  onClick={() => setUserDialogProfile(user)}
                  user={user}
                  key={`sidebar-${user.id}`}
                  textOverflow="hide"
                />
              ))}
            </Accordion>
          )}
          {props.attachments && (
            <LoadableResource resource={props.attachments}>
              {attachments => {
                return attachments.values.length ? (
                  <Accordion
                    title="Shared files"
                    isExpanded={false}
                    paddingHeader="double"
                    paddingContent="none"
                    summaryBackgroundColor="grey-light"
                    border="bottom"
                  >
                    {attachments.values.map(a => (
                      <DownloadableFile
                        key={a.fileId}
                        onDownload={() => downloadAttachment(a.fileId)}
                        name={a.filename}
                        fullWidth
                      />
                    ))}
                  </Accordion>
                ) : null;
              }}
            </LoadableResource>
          )}
          {props.enableConversationNameChange && (
            <div className={styles.renameChat} onClick={props.dialogHandler.open}>
              <span>Change chat name</span>
              <EditIcon />
            </div>
          )}
          <button className={styles.clickableReportCard} onClick={() => setIsReportDialogOpen(true)}>
            <FlagIcon color="grey" className="u-half-spacing" />
            Report user
          </button>
          <EditNameDialog
            onSave={props.onEdit}
            name={props.conversationName}
            dialogHandler={props.dialogHandler}
            onSuccess={
              props.setConversationName
                ? (name: string) => props.setConversationName && props.setConversationName(name)
                : undefined
            }
          />
        </div>
      </div>
      {conversationId && (
        <ReportDialog
          title="Report"
          postReport={(report: Report) => AntifraudAPI.report.conversation(conversationId, report)}
          isOpen={isReportDialogOpen}
          onClose={() => setIsReportDialogOpen(false)}
          reasons={['Spam', 'Scams or fraud', 'Other']}
        />
      )}

      {userDialogProfile && (
        <UserProfileDialog cwUserId={userDialogProfile.cwUserId} onClose={() => setUserDialogProfile(undefined)} />
      )}
    </>
  );
}
