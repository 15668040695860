import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles.scss';
import Logo from 'ui/domain/Logo';
import { Enterprise } from 'apis/CompanyAPI/enterprisesApi';

export const EnterpriseLogo = ({ enterprise }: { enterprise: Enterprise }) => {
  return (
    <Link className={classnames('u-flex u-flex', styles.link)} to={`/e/${enterprise.slug}`}>
      <div className={styles.companyLogoWrapper}>
        <Logo url={enterprise.logoURL} type={'organization'} size={59} />
      </div>
      <p>{enterprise.name}</p>
    </Link>
  );
};
