import MUIIconButton, { IconButtonProps } from '@mui/material/IconButton/IconButton';
import React, { useMemo } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { getColor, IconColor } from './SvgIcon';

type Props = Omit<IconButtonProps, 'color'> & {
  color?: IconColor;
  hoverColor?: IconColor;
  sx?: SxProps<Theme>;
};

export default function IconButton(props: Props) {
  const { hoverColor, color, sx, ...rest } = props;
  const colorValue = getColor(color || 'blue');
  const defaultSx = useMemo(
    () => ({
      '.MuiSvgIcon-root': {
        color: colorValue,
      },
      '&:hover .MuiSvgIcon-root': {
        color: getColor(hoverColor) ?? colorValue,
      },
    }),
    [props.color],
  );

  return (
    <MUIIconButton sx={{ ...defaultSx, ...sx }} {...rest}>
      {props.children}
    </MUIIconButton>
  );
}
