export const texts = {
  management: {
    title: 'Core team',
    label: 'Details about the core team. How many are there? What backgrounds do they have?',
    placeholder:
      'Our management team is a capable group of 3. Our founders take part in developing the company. With over 10 years of experience we can change the field.',
  },
  problem: {
    title: 'Problem',
    label: 'Describe the problem you want to solve',
    placeholder: 'Electrical cars are difficult to manoeuvre in a city environment.',
  },
  solution: {
    title: 'Solution',
    label: 'How does your product solve your customer problem?',
    placeholder: 'We developed a scooter that you can park anywhere in the city.',
  },
  unfairAdvantage: {
    title: 'Why invest in us?',
    label: 'What makes you different and better than your competition? What makes your team and company stand out?',
    placeholder: 'Our scooters can go farther and faster than our competitors.',
  },
};
