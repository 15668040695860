import React from 'react';
import { resize } from 'util/cloudinary';
import { UPDATE_BANNER_IMAGE_WIDTH } from 'util/constants';
import styles from './styles.scss';

interface Props {
  imageUrl?: string;
}

export default function BannerImage(props: Props) {
  return props.imageUrl ? (
    <div
      className={styles.bannerImage}
      style={{ backgroundImage: `url(${resize(props.imageUrl, { width: UPDATE_BANNER_IMAGE_WIDTH })})` }}
    />
  ) : null;
}
