import { externalAPI } from 'apis';
import { CLOUDINARY_API_KEY } from 'util/constants';

import { CloudinaryUploadResponse, CloudinaryUploadSettings } from 'types';

export const uploadToCloudinary = async (result: CloudinaryUploadSettings, file: Blob) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('api_key', CLOUDINARY_API_KEY);
  formData.append('public_id', result.metadata.public_id);
  formData.append('signature', result.metadata.signature);
  formData.append('timestamp', result.metadata.timestamp);

  const response = await externalAPI.post<CloudinaryUploadResponse>(result.uploadUrl, formData);
  return response.data;
};
