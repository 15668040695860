import { IntercomStore } from 'globalstate/reducers/intercom';
import { Role } from 'types/company';

interface IntercomCompany extends Intercom_.IntercomCompany {
  name: string;
  id: number;
  isCommunity?: boolean;
}

interface IntercomAttributes extends Intercom_.IntercomSettings {
  user_id: string;
  name?: string;
  email?: string;
  user_hash?: string;
  role?: string;
  company?: IntercomCompany;
}

function getIntercomRole(roles: Role[] = [], isCommunity: boolean) {
  if (roles.includes('company_master')) return isCommunity ? 'community admin' : 'company admin';
  if (roles.includes('company_admin_in_community')) return 'company admin in community';
  if (roles.includes('mentor')) return 'mentor';
  if (roles.includes('investor')) return 'investor';
  if (roles.length > 0) {
    return roles[0];
  }
  return undefined;
}

function createPayload(data: IntercomStore): IntercomAttributes {
  const { user, company, roles } = data;

  return {
    email: user?.email,
    user_id: user?.id?.toString() || '',
    name: user?.name,
    user_hash: user?.userHash,
    role: company ? getIntercomRole(roles, !!company.isCommunity) : undefined,
    company: company
      ? {
          name: company.name,
          id: company.id,
          isCommunity: company.isCommunity,
        }
      : undefined,
  };
}

export function update(data: IntercomStore): 'boot' | 'update' | undefined {
  const _Intercom: Intercom_.IntercomStatic = (window as any).Intercom;

  if (!_Intercom) return undefined;

  const hasFetchedAllNecessaryData = !!data.user;
  const shouldBoot = hasFetchedAllNecessaryData && data.hasBooted === false;
  const shouldUpdate = hasFetchedAllNecessaryData;
  const payload = createPayload(data);

  if (shouldBoot) {
    _Intercom('boot', payload);
    return 'boot';
  } else if (shouldUpdate) {
    _Intercom('update', payload);
    return 'update';
  } else return undefined;
}

export function startProductTour(tourId: number) {
  const _Intercom: Intercom_.IntercomStatic = (window as any).Intercom;
  _Intercom != null && _Intercom('startTour', tourId);
}

export function updateRoute() {
  const _Intercom: Intercom_.IntercomStatic = (window as any).Intercom;
  _Intercom && _Intercom('update');
}

export function show() {
  const _Intercom: Intercom_.IntercomStatic = (window as any).Intercom;
  _Intercom && _Intercom('show');
}
