import { Grid, GridSize, Tooltip } from '@mui/material';
import LoadableResource from 'util/resource/Resource';
import TeamMember from './TeamMember';
import React, { useContext, useState } from 'react';
import { companiesApi, HighlightedSection } from 'apis/CompanyAPI/companies/companiesApi';
import Card from 'ui/views/cards/Card';
import Button from 'ui/elements/buttons/Button';
import { ICompany } from 'types/company';
import useLazyResource from 'util/resource/useLazyResource';
import SectionHeading from 'ui/elements/SectionHeading';
import EditAction from 'ui/elements/actions/EditAction';

import IconButton from 'ui/elements/icons/IconButton';
import ChevronUpIcon from 'ui/elements/icons/ChevronUpIcon';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import { moveDown, moveUp } from 'util/arrayUtils';

import meetTheTeamIllustration from 'ui/illustrations/meet_the_team.svg';
import HighlightedUsersDialog, { EditSectionMode } from './HighlightedUsersDialog';
import { useCompanyLastUpdated } from '../LastUpdated';
import useHighlightedUsers from './useHighlightedUsers';

interface Props {
  company: ICompany;
  onOpenTeamMember: (cwUserId: UUID) => void;
  canEditCompany?: boolean;
}

interface GridProps {
  xl?: boolean | GridSize;
}

export const TeamGridContext = React.createContext<GridProps>({});

export default function TeamMembers(props: Props) {
  const { resource: highlightedUsersResource, mutate } = useHighlightedUsers(props.company.slug);
  const { setLastUpdated } = useCompanyLastUpdated(props.company.id);

  const [editMode, setEditMode] = useState<EditSectionMode | undefined>(undefined);

  const teamGridContext = useContext(TeamGridContext);

  const [patchSectionOrder, isSaving] = useLazyResource(
    (ordered: HighlightedSection[]) => {
      const promise = companiesApi.profile.highlightedUsers.patchOrder(
        props.company.slug,
        ordered.map(section => section.id),
      );
      mutate(promise, { optimisticData: { sections: ordered } });
      return promise;
    },
    {
      onSuccess: () => {
        setLastUpdated(new Date());
      },
    },
  );

  return (
    <>
      <LoadableResource resource={highlightedUsersResource}>
        {result => {
          return (
            <div>
              {result.sections.map((section, i) => (
                <div key={section.id} data-intercom-target={`highlighted-users-${section.sectionName}`}>
                  <SectionHeading heading={section.sectionName} addMobileMargin>
                    {props.canEditCompany && (
                      <>
                        {result.sections.length > 0 && i > 0 && (
                          <Tooltip title="Move section up">
                            {/* tooltip doesn't work with IconButton as a child */}
                            <span>
                              <IconButton
                                disabled={isSaving}
                                size="small"
                                onClick={() => patchSectionOrder(moveUp(result.sections, section, s => s.id))}
                              >
                                <ChevronUpIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        {result.sections.length > 0 && i < result.sections.length - 1 && (
                          <Tooltip title="Move section down">
                            {/* tooltip doesn't work with IconButton as a child */}
                            <span>
                              <IconButton
                                disabled={isSaving}
                                size="small"
                                onClick={() => patchSectionOrder(moveDown(result.sections, section, s => s.id))}
                              >
                                <ChevronDownIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        <button onClick={() => setEditMode({ type: 'edit', section })}>
                          <EditAction tooltip="Edit" />
                        </button>
                      </>
                    )}
                  </SectionHeading>

                  <Grid container spacing={1} alignContent="stretch" className="u-section-spacing-bottom">
                    {section.users.map(user => (
                      <Grid key={user.id} item xs={12} sm={6} lg={4} xl={teamGridContext.xl ?? 3}>
                        <TeamMember key={user.id} user={user} onClick={props.onOpenTeamMember} />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ))}

              {props.canEditCompany && result.sections.length === 0 && (
                <Card
                  onClick={() => setEditMode({ type: 'new' })}
                  hover="highlight"
                  type="dashed"
                  className="u-content-spacing-y text-body u-flex u-flex-space-around"
                  data-intercom-target="highlighted-users-cta"
                >
                  <div className="u-flex u-flex--column u-flex-align-center">
                    <img style={{ maxWidth: '15rem' }} src={meetTheTeamIllustration} />
                    <p className="u-content-spacing-top">
                      Showcase the key stakeholders in your company, such as board members, key employees and lead
                      investors.
                    </p>
                    <Button kind="tertiary">Add stakeholders</Button>
                  </div>
                </Card>
              )}

              {props.canEditCompany && result.sections.length > 0 && (
                <Card
                  onClick={() => setEditMode({ type: 'new' })}
                  hover="highlight"
                  type="dashed"
                  className="u-content-spacing-y text-body u-flex u-flex-space-around"
                  data-intercom-target="highlighted-users-add-section"
                >
                  <Button kind="tertiary">Add another section</Button>
                </Card>
              )}
              {editMode && (
                <HighlightedUsersDialog
                  editMode={editMode}
                  onClose={() => setEditMode(undefined)}
                  onSuccess={response => mutate(response)}
                  company={props.company}
                />
              )}
            </div>
          );
        }}
      </LoadableResource>
    </>
  );
}
