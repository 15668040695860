import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function InsightsIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M8.5 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM14.5 17a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM18.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
      <path d="M15.4 14.3 18 9m-8.4 2.6 3.6 2.7M3 19l4.6-7.1" />
      <path d="M20 21H9c-3.3 0-5 0-6-1s-1-2.7-1-6V3" />
    </SvgIcon>
  );
}
