import useSWR from 'hooks/useSWR';
import { ExecutiveSummaryDTO } from 'types/company';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

export const getExecutiveSummaryKey = (companyId: number) => companiesAPIUrls.executiveSummary.get(companyId);

export function useExecutiveSummary(companyId: number) {
  const swrResult = useSWR<ExecutiveSummaryDTO>(getExecutiveSummaryKey(companyId));
  return useSWRResource(swrResult);
}
