import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CloseIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m7.2 7.2 9.6 9.6m0-9.6-9.6 9.6" />
    </SvgIcon>
  );
}
