import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { PieChart as RechartsPieChart, Pie, Cell } from 'recharts';
import cx from 'classnames';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import styles from './styles.scss';

interface Props {
  percentage: number;
  colors?: string[];
  width: number;
  thickness: number;
  fontSize?: 'small' | 'normal';
  fontColor?: string;
  backgroundColor?: string;
  hidePercentage?: true;
}

export default function ProgressPie({
  percentage,
  colors,
  width,
  thickness,
  fontSize = 'normal',
  fontColor,
  backgroundColor,
  hidePercentage,
}: Props) {
  const theme = useTheme();

  const COLORS = colors ?? [theme.bluePlanetPalette.grey.main, theme.bluePlanetPalette.green.main];
  const [data, setData] = useState([
    { value: 100 - percentage, name: 'progress-left' },
    { value: percentage, name: 'progress' },
  ]);

  useEffect(() => {
    // this check prevents additional renders, so the pie chart can animate on load
    if (data[1].value !== percentage) {
      setData([
        { value: 100 - percentage, name: 'progress-left' },
        { value: percentage, name: 'progress' },
      ]);
    }
  }, [percentage]);

  const outerRadius = width / 2 - 1;
  const innerRadius = outerRadius - thickness;

  return (
    <div
      style={{
        position: 'relative',
        width: width,
        height: width,
        borderRadius: '100%',
      }}
    >
      <RechartsPieChart width={width} height={width} style={{ backgroundColor: backgroundColor, borderRadius: '100%' }}>
        <Pie
          data={data}
          labelLine={false}
          startAngle={90}
          endAngle={450}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          dataKey="value"
          blendStroke
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </RechartsPieChart>
      <div
        className={cx(styles.percentage, {
          [styles.textSmall]: fontSize === 'small',
        })}
        style={fontColor ? { color: fontColor } : undefined}
      >
        {percentage === 100 && !hidePercentage ? (
          <CheckmarkIcon style={{ fontSize: (innerRadius ?? 40) * 1.2 }} strokeWidth={2.5} />
        ) : hidePercentage ? (
          <></>
        ) : (
          <p>{percentage}%</p>
        )}
      </div>
    </div>
  );
}
