import React from 'react';

import { ICompany } from 'types/company';
import ProfileDialog from './ProfileDialog';
import useProfileViewLogger, { AnalyticsContext } from 'hooks/usePitchViewLogger';
import useRoute from 'hooks/useRoute';
import { useCompanyProfileById } from 'apis/CompanyAPI/companies/useCompanyProfile';
import { CompanyOverviewSection } from 'urls';

interface Props {
  onClose: string | (() => void);
  companyId: number;
  analyticsCommunityId?: number;
  analyticsContext: AnalyticsContext;

  renderSidebar?: (company: ICompany) => React.ReactNode;
  renderActionBar?: React.ReactNode;
  modalId?: string;
  subRoutingIsEnabled?: boolean;
  initialTab?: CompanyOverviewSection;
}

export default function PublicProfileDialogContainer(props: Props) {
  useProfileViewLogger(props.companyId, props.analyticsContext, props.analyticsCommunityId);

  const { resource: companyProfileResource } = useCompanyProfileById(props.companyId);

  const { push } = useRoute();

  const onClose = () => {
    if (typeof props.onClose === 'string') {
      push(props.onClose);
    }
    if (typeof props.onClose === 'function') {
      props.onClose();
    }
  };

  return (
    <ProfileDialog
      companyProfile={companyProfileResource}
      companyId={props.companyId}
      onClose={onClose}
      renderSidebar={props.renderSidebar}
      renderActionBar={props.renderActionBar}
      modalId={props.modalId}
      subRoutingIsEnabled={props.subRoutingIsEnabled}
      initialTab={props.initialTab}
    />
  );
}
