import styled from '@emotion/styled';
import { lighten } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import { halfSpacing } from 'ui/theme/themeConstants';

export const BottomMobileNavigation = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${bluePlanetTheme.bluePlanetPalette.blue.veryLight};
  z-index: 1000;
  filter: drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.12));
  min-height: 60px;
`;

export const NavigationRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NavigationItem = styled.button(
  ({ color }: { color?: 'indigo' | 'blueVeryLight' }) => `
  padding: ${halfSpacing};
  width: 100%;
  display: flex;
  font-size: ${fontSizeSmall};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color:  ${
      color === 'indigo'
        ? lighten(bluePlanetTheme.bluePlanetPalette.indigo.light, 0.1)
        : bluePlanetTheme.bluePlanetPalette.blue.light
    };)}};
  }
`,
);
