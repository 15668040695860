import InputLabel from '@mui/material/InputLabel';
import React from 'react';

interface Props {
  id?: string;
  className?: string;
  htmlFor?: string;
  error?: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export default function Label(props: Props) {
  return <InputLabel {...props} />;
}
