import styled from '@emotion/styled';
import { DialogActions, useMediaQuery, useTheme } from '@mui/material';
import { ContentAPI } from 'apis/ContentAPI';
import { Location } from 'apis/MapboxAPI';
import config from 'config';
import LocationInput from 'domain/shared/Location/LocationInput';
import { FormikProps } from 'formik';
import React, { lazy, useEffect, useState } from 'react';
import { DocumentDownloadDTO, DocumentList } from 'types/documents';
import { OrganizationWithCommunities } from 'types/organization';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import DatePicker from 'ui/elements/form/DatePicker';
import Label from 'ui/elements/form/Label';
import { communityMentionSearchFunction } from 'ui/elements/form/RichTextEditor/utils';
import TextField from 'ui/elements/form/TextField';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import { bluePlanetTheme } from 'ui/theme';
import { contentSpacing } from 'ui/theme/themeConstants';
import Dialog, { Content, Title } from 'ui/views/dialogs/Dialog';
import * as Yup from 'yup';
import { useEventAttachments } from './useEventAttachments';
import AttachmentsCarousel from 'domain/Updates/shared/UpdateForm/AttachmentsCarousel';

export const eventSchema = Yup.object().shape({
  title: Yup.string().required('Your event must have a title'),
  location: Yup.string().required('Please add a location to your event'),
  locationUrl: Yup.string().notRequired().nullable(),
  content: Yup.string().required('Please give your event a description'),
  calendarContent: Yup.string().notRequired().nullable(),
  startsAt: Yup.date().required(),
  endsAt: Yup.date().notRequired().nullable(),
  communityId: Yup.number().notRequired(),
  attachments: Yup.array().of(Yup.string()).required(),
});

const Container = styled(Content)`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${contentSpacing};

  ${bluePlanetTheme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;

    & > div:nth-of-type(1) {
      order: 2;
    }
  }
`;

const DatesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${contentSpacing};

  ${bluePlanetTheme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`;

export type EventFormValues = {
  title: string;
  location: string;
  locationUrl?: string;
  content: string;
  calendarContent?: string;
  startsAt: string;
  endsAt?: string;
  communityId?: number;
  attachments: string[];
  organizations?: OrganizationWithCommunities[];
};

type Props = {
  onClose: () => void;
  formik: FormikProps<EventFormValues>;
  isSaving: boolean;
  communityId: number;
  attachments?: DocumentList;
  newEvent: boolean;
};

const RichTextEditor = lazy(() => import('ui/elements/form/RichTextEditor'));

export default function EventDialog({ onClose, formik, isSaving, communityId, attachments, newEvent }: Props) {
  const [startDate, setSelectedStartDate] = useState(new Date(formik.values.endsAt ?? new Date()));
  const [endDate, setSelectedEndDate] = useState(new Date(formik.values.endsAt ?? new Date()));

  const [selectedLocation, setSelectedLocation] = useState<Location | undefined>(() => {
    const savedLocation = localStorage.getItem(`location_${formik.values.location || ''}`);
    return savedLocation ? JSON.parse(savedLocation) : undefined;
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const { newAttachments, onAttachmentRemoved, onUploadFile, removeFile, onSubmit } = useEventAttachments({
    formik,
    attachments,
  });

  const getImageUploadUrl = (filename: string) =>
    ContentAPI(`${config.CONTENT_API_URL}/companies/${communityId}`).images.getCompanyUploadUrl(communityId, filename);

  const mentionSearchFunction = async (searchQuery: string) =>
    communityMentionSearchFunction(searchQuery, 'CommunityMembers', communityId);

  useEffect(() => {
    if (selectedLocation) {
      localStorage.setItem(`location_${formik.values.location}`, JSON.stringify(selectedLocation));
    } else {
      localStorage.removeItem(`location_${formik.values.location || ''}`);
    }
  }, [selectedLocation]);

  const handleDateChange = (
    currentDate: Date,
    newDate: Date,
    setDate: React.Dispatch<React.SetStateAction<Date>>,
    field: 'startsAt' | 'endsAt',
  ) => {
    const isToday = (date: Date) =>
      date.getDate() === new Date().getDate() &&
      date.getMonth() === new Date().getMonth() &&
      date.getFullYear() === new Date().getFullYear();

    const isSameTime = (date1: Date, date2: Date) =>
      date1.getHours() === date2.getHours() && date1.getMinutes() === date2.getMinutes();

    // The calendar component reverts to today's date when the time is changed, so
    // if only the time is changed, we manually keep the currently applied date
    // On the first end date change the times are never equal (for some reason), so we add an extra check
    const updatedDate =
      isSameTime(currentDate, newDate) || !isToday(newDate)
        ? newDate
        : new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            newDate.getHours(),
            newDate.getMinutes(),
            newDate.getSeconds(),
          );

    setDate(updatedDate);
    formik.setFieldValue(field, updatedDate.toISOString());
  };

  return (
    <Dialog open fullScreen={isMobile} maxWidth="lg" onClose={onClose}>
      <Title onClose={onClose}>{newEvent ? 'Create event' : 'Update event'}</Title>
      <form style={{ borderRadius: 'inherit' }}>
        <Container className="u-content-padding-bottom">
          <div>
            <FormikTextField
              autoFocus
              formikProps={formik}
              inputProps={{ fontSize: '1.5rem' }}
              name="title"
              label="event title"
              type="string"
              placeholder="Add title here.."
            />
            <DatesContainer>
              <div className="u-fullWidth">
                <Label>Start time</Label>
                <DatePicker
                  name="startsAt"
                  showTimeInput
                  shouldCloseOnSelect={false}
                  dateFormat={'dd/MM/yyyy HH:mm'}
                  timeFormat={'hh:mm'}
                  selected={formik.values.startsAt ? new Date(formik.values.startsAt) : null}
                  onChange={date => handleDateChange(startDate, date!, setSelectedStartDate, 'startsAt')}
                  onBlur={formik.handleBlur}
                  customInput={
                    <TextField
                      error={formik.touched.startsAt && !!formik.errors.startsAt}
                      helperText={formik.touched.startsAt && formik.errors.startsAt ? 'Invalid date' : undefined}
                    />
                  }
                  minDate={new Date()}
                  maxDate={formik.values.endsAt ? new Date(formik.values.endsAt) : null}
                />
              </div>
              <div className="u-fullWidth">
                <Label>End time</Label>
                <DatePicker
                  name="endsAt"
                  showTimeInput
                  shouldCloseOnSelect={false}
                  dateFormat={'dd/MM/yyyy HH:mm'}
                  selected={formik.values.endsAt ? new Date(formik.values.endsAt) : null}
                  onChange={date => handleDateChange(endDate, date!, setSelectedEndDate, 'endsAt')}
                  onBlur={formik.handleBlur}
                  customInput={
                    <TextField
                      error={formik.touched.endsAt && !!formik.errors.endsAt}
                      helperText={formik.touched.endsAt && formik.errors.endsAt ? 'Invalid date' : undefined}
                    />
                  }
                  minDate={new Date(formik.values.startsAt)}
                />
              </div>
            </DatesContainer>
          </div>
        </Container>

        <div style={{ background: theme.bluePlanetPalette.grey.light, borderRadius: 'inherit' }}>
          <Container className="u-content-padding-top">
            <div>
              <Label>Description</Label>
              <RichTextEditor
                toolbarType="none"
                name="content"
                deltaName="eventContent"
                content={formik.values.content}
                delta={{}}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                validateField={formik.validateField}
                handleChange={formik.handleChange}
                error={formik.touched.content ? formik.errors.content : undefined}
                getImageUploadUrl={getImageUploadUrl}
                mentionSearchFunction={mentionSearchFunction}
                onUploadFile={onUploadFile}
                submitButton={
                  <Button style={{ whiteSpace: 'nowrap' }} onClick={onSubmit} kind="primary" type="submit">
                    {newEvent ? 'Publish' : 'Save'}
                  </Button>
                }
                attachmentSection={
                  <AttachmentsCarousel
                    attachmentIdList={formik.values.attachments}
                    existingAttachments={attachments?.documents ?? []}
                    newAttachments={newAttachments}
                    // It is not possible to download documents here, but they are uploaded locally by the user anyway
                    downloadAttachment={() => Promise.resolve({} as DocumentDownloadDTO)}
                    removeExistingAttachment={onAttachmentRemoved}
                    removeNewAttachment={removeFile}
                  />
                }
              />
            </div>

            <div>
              <LocationInput
                name="location"
                label="event location"
                searchArea="addresses"
                autoComplete="address-level2"
                error={formik.touched.location ? formik.errors.location : ''}
                onChange={location => {
                  if (location) {
                    setSelectedLocation(location);
                    formik.setFieldValue('location', location.description);
                  } else {
                    setSelectedLocation(undefined);
                    formik.setFieldValue('location', '');
                  }
                }}
                value={selectedLocation}
              />
            </div>
          </Container>

          {!isMobile && (
            <DialogActions sx={{ justifyContent: 'flex-start' }}>
              <ButtonList>
                <Button kind="primary" onClick={() => formik.handleSubmit()} disabled={isSaving}>
                  {newEvent ? 'Publish' : 'Save'}
                </Button>
                <Button kind="tertiary" onClick={onClose}>
                  Cancel
                </Button>
              </ButtonList>
            </DialogActions>
          )}
        </div>
      </form>
    </Dialog>
  );
}
