import styled from '@emotion/styled';
import { alpha } from '@mui/material';
import useRoute from 'hooks/useRoute';
import React, { useContext } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import { fontSizeMedium, shape } from 'ui/theme/themeBluePlanet';
import { contentSpacing, halfSpacing, quarterSpacing } from 'ui/theme/themeConstants';
import { RowContext } from './Row';

type DataCellProps = {
  isLinkDisabled?: boolean;
  align?: 'left' | 'center' | 'right';
} & React.ThHTMLAttributes<HTMLTableCellElement>;

const hoverStyles = `
  cursor: pointer;
  transition: background-color 200ms ease-out;
  background-color: ${alpha(bluePlanetTheme.bluePlanetPalette.white, 0.3)};
`;

export const StyledDataCell = styled.td(
  (props: DataCellProps) => `
  background-color: ${bluePlanetTheme.bluePlanetPalette.white};
  font-weight: ${bluePlanetTheme.typography.fontWeightRegular};
  font-size: ${fontSizeMedium};
  padding: ${quarterSpacing} ${halfSpacing};
  outline: none;

  &:first-of-type {
    padding-left: ${contentSpacing};
    border-top-left-radius: ${shape.borderRadius}px;
    border-bottom-left-radius: ${shape.borderRadius}px;

    ${bluePlanetTheme.breakpoints.down('md')} {
      padding-left: ${halfSpacing};
    }
  }
  &:hover {
    ${props.onClick ? hoverStyles : ''}
  }

  &:last-of-type {
    border-top-right-radius: ${shape.borderRadius}px;
    border-bottom-right-radius: ${shape.borderRadius}px;
    text-align: ${props.align || 'center'};
  }
  &:focus {
    outline: 2px solid ${bluePlanetTheme.bluePlanetPalette.blue.medium};
    border-radius: ${bluePlanetTheme.shape.borderRadius}px;
  }
`,
);

export const DataCell = ({ isLinkDisabled = false, onClick, style, children, ...rest }: DataCellProps) => {
  const context = useContext(RowContext);
  const { push } = useRoute();

  if (!context) {
    throw Error('Table.DataCell: Component must be wrapped in <Row> to access RowContext.');
  }

  const { href } = context;
  const hasLink = href && !isLinkDisabled;

  return (
    <StyledDataCell
      style={{ ...style, cursor: hasLink || onClick ? 'pointer' : 'inherit' }}
      onKeyDown={e => {
        if (e.key === 'Enter' && hasLink) {
          push(href);
        }
      }}
      onClick={
        hasLink
          ? () => {
              push(href);
            }
          : onClick
      }
      {...rest}
    >
      {children}
    </StyledDataCell>
  );
};
