import React from 'react';
import styles from './styles.scss';

interface Props {
  title: JSX.Element;
  logo: JSX.Element;
  suffix?: JSX.Element;
  onClickUser?: () => void;
}

export default function ConversationHeader(props: Props) {
  const { logo } = props;

  return (
    <div className="u-flex u-flex-align-center u-flex-space-between u-flex-1">
      <span className="u-flex u-flex-align-center">
        <span
          onClick={props.onClickUser}
          style={props.onClickUser ? { cursor: 'pointer' } : undefined}
          className="u-half-spacing-right"
        >
          {logo}
        </span>
        <span className={styles.conversationHeader}>{props.title}</span>
      </span>

      {props.suffix}
    </div>
  );
}
