import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ArchiveIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M13 2h-2C7.2 2 5.3 2 4.2 3.2 3 4.3 3 6.2 3 10v4c0 3.8 0 5.7 1.2 6.8C5.3 22 7.2 22 11 22h2c3.8 0 5.7 0 6.8-1.2 1.2-1.1 1.2-3 1.2-6.8v-4c0-3.8 0-5.7-1.2-6.8C18.7 2 16.8 2 13 2ZM21 12H3M15 7H9M15 17H9" />
    </SvgIcon>
  );
}
