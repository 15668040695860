import useSWR from 'hooks/useSWR';
import { Currency } from 'types/company';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

export interface MarketPotential {
  estimatedAvailableMarket?: Currency;
  estimatedServiceableMarket?: Currency;
  geographicalMarketPotential?: string[];
  currentMarketFocus?: string[];
  description?: string;
}

export interface PatchMarketPotential {
  geographicalMarketPotential?: string[] | null;
  currentMarketFocus?: string[] | null;
  estimatedAvailableMarketValue?: number | null;
  estimatedAvailableMarketCurrency?: string | null;
  estimatedServiceableMarketValue?: number | null;
  estimatedServiceableMarketCurrency?: string | null;
  description?: string | null;
}

export const useMarketPotential = (companyId: number) => {
  const swrResult = useSWR<MarketPotential>(companiesAPIUrls.marketPotential.get(companyId));

  return useSWRResource(swrResult);
};
