import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function DashboardIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2 6c0-1.9 0-2.8.6-3.4C3.2 2 4 2 6 2s2.8 0 3.4.6c.6.6.6 1.5.6 3.4v2c0 1.9 0 2.8-.6 3.4-.6.6-1.5.6-3.4.6s-2.8 0-3.4-.6C2 10.8 2 10 2 8V6ZM2 19c0-1 0-1.4.2-1.8a2 2 0 0 1 1-1c.4-.2.9-.2 1.8-.2h2c1 0 1.4 0 1.8.2.5.2.8.5 1 1 .2.4.2.9.2 1.8 0 1 0 1.4-.2 1.8a2 2 0 0 1-1 1c-.4.2-.9.2-1.8.2H5c-1 0-1.4 0-1.8-.2a2 2 0 0 1-1-1C2 20.4 2 19.9 2 19ZM14 16c0-1.9 0-2.8.6-3.4.6-.6 1.5-.6 3.4-.6s2.8 0 3.4.6c.6.6.6 1.5.6 3.4v2c0 1.9 0 2.8-.6 3.4-.6.6-1.5.6-3.4.6s-2.8 0-3.4-.6C14 20.8 14 20 14 18v-2ZM14 5c0-1 0-1.4.2-1.8a2 2 0 0 1 1-1c.4-.2.9-.2 1.8-.2h2c1 0 1.4 0 1.8.2.5.2.8.5 1 1 .2.4.2.9.2 1.8 0 1 0 1.4-.2 1.8a2 2 0 0 1-1 1c-.4.2-.9.2-1.8.2h-2c-1 0-1.4 0-1.8-.2a2 2 0 0 1-1-1C14 6.4 14 5.9 14 5Z" />
    </SvgIcon>
  );
}
