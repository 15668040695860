import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function FlagIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M4 7v14M11.76 3.9c-3.3-1.68-5.9-.69-7.2.32-.24.18-.36.27-.46.48-.1.2-.1.4-.1.79v9.24c.97-1.1 3.88-2.8 7.76-.82 3.46 1.76 6.41 1.03 7.81.27.2-.1.29-.16.36-.28.07-.12.07-.24.07-.5V5.87c0-.82 0-1.24-.2-1.39-.2-.15-.66-.02-1.58.24a8.89 8.89 0 0 1-6.46-.81Z" />
    </SvgIcon>
  );
}
