import React from 'react';
import { NotificationType } from 'types/notifications';
import ChatIcon from 'ui/elements/icons/ChatIcon';
import HornIcon from 'ui/elements/icons/HornIcon';
import LikeIcon from 'ui/elements/icons/LikeIcon';
import ReplyIcon from 'ui/elements/icons/ReplyIcon';
import UpdateIcon from 'ui/elements/icons/UpdateIcon';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import UsersIcon from 'ui/elements/icons/UsersIcon';

export const NotificationIcon = ({ type }: { type: NotificationType }) => {
  switch (type) {
    case 'comment':
      return <ChatIcon />;
    case 'like':
      return <LikeIcon />;
    case 'reply':
      return <ReplyIcon />;
    case 'feedback':
      return <HornIcon />;
    case 'conversation':
      return <ChatBubblesIcon />;
    case 'room-user-added':
    case 'follower-request-sent':
    case 'follower-request-accepted':
      return <UsersIcon />;
    default:
      return <UpdateIcon />;
  }
};
