import Tabz from '@mui/material/Tabs';
import React, { CSSProperties } from 'react';

interface Props<T> {
  className?: string;
  activeTab: T;
  onChange?: (activeTab: T, e: React.ChangeEvent<{}>) => void;
  children: React.ReactNode;
  hideTabIndicator?: boolean;
  align?: 'left' | 'right';
  style?: CSSProperties;
  variant?: 'fullWidth' | 'scrollable';
}

export default function Tabs<T>(props: Props<T>) {
  return (
    <Tabz
      variant={props.variant ?? 'scrollable'}
      value={props.activeTab}
      className={props.className}
      textColor="primary"
      indicatorColor="primary"
      allowScrollButtonsMobile
      onChange={(e, activeTab) => props.onChange && props.onChange(activeTab, e)}
      TabIndicatorProps={{ hidden: props.hideTabIndicator }}
      scrollButtons="auto"
      style={props.style}
      sx={{
        maxWidth: '100%',
        '.MuiTabs-flexContainer': {
          maxWidth: '100%',

          justifyContent: props.align === 'right' ? 'flex-end' : undefined,
        },
      }}
    >
      {props.children}
    </Tabz>
  );
}
