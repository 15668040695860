import useSWR from 'hooks/useSWR';
import { AllIndustries } from 'types';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from '../companies/companiesApi';

export const industriesKey = companiesAPIUrls.industries.listAll();

export const useIndustries = () => {
  const swrResult = useSWR<AllIndustries>(industriesKey);
  return useSWRResource(swrResult);
};
