import config from 'config';

const companyOverviewProductTourForAdmins = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return 394444;
    case 'beta':
    case 'production':
      return 400394;
    default:
      return undefined;
  }
};

export default {
  companyOverviewProductTourForAdmins,
};
