import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { CSSProperties } from 'react';
import { asDayMonthNameYear, dateWithTimestamp, asTime, timeSince } from 'util/dateUtils';

interface Props {
  timestamp: Date | string;
  className?: string;
  style?: CSSProperties;
  showFullTimestamp?: boolean;
  tooltipPlacement?: 'top' | 'bottom';
}

export default function Timestamp({ timestamp, className, style, showFullTimestamp, tooltipPlacement = 'top' }: Props) {
  return (
    <Tooltip title={`${asDayMonthNameYear(timestamp)} ${asTime(timestamp)}`} placement={tooltipPlacement} arrow={true}>
      <span style={style} className={className}>
        {showFullTimestamp ? dateWithTimestamp(timestamp) : timeSince(timestamp)}
      </span>
    </Tooltip>
  );
}
