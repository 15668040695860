import React from 'react';
import Resources from 'util/resource/Resources';
import { useShortlists } from 'apis/CompanyAPI/users/useShortlists';
import CompanyProfileCard from 'domain/companies/CompanyCard/CompanyCard';
import { useFeaturedMatches } from 'apis/CompanyAPI/users/useFeaturedMatches';
import { distinct } from 'util/arrayUtils';
import styled from '@emotion/styled';
import { useDebouncedCallback } from 'use-debounce';
import { css } from '@emotion/react';
import CompanyProfileDialog from 'domain/companies/ProfileDialog/CompanyProfileDialog';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import Button from 'ui/elements/buttons/Button';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { asObjectWithKeys, useUiState } from 'apis/CompanyAPI/users/useUiState';
import Carousel from 'ui/elements/Carousel';
import { defaultBleedAmounts, MobileMargin } from 'ui/views/layouts/BaseLayout';

interface Props {
  className?: string;
  useCarouselOnLargeScreen?: boolean;
}

const Container = styled.div(
  ({ isDismissed, useCarouselOnLargeScreen }: { isDismissed: boolean; useCarouselOnLargeScreen?: boolean }) => css`
    // For transition
    ${useCarouselOnLargeScreen &&
    css`
      max-height: 1000px;
    `}
    transition: all 0.3s ease-out;
    ${isDismissed &&
    css`
      overflow: hidden;
      margin-bottom: 0 !important;
      max-height: 0;
    `}
  `,
);

export default function FeaturedCompanyMatchesForCurrentUser(props: Props) {
  const { resource: matchesResource } = useFeaturedMatches();
  const { resource: shortlistResource, mutate: setShortlistResource } = useShortlists();

  const { uiState, saveUiState } = useUiState();

  const setDismissedMatches = (companyIds: number[]) => {
    saveUiState({
      dismissedMatches: asObjectWithKeys(companyIds),
    });
  };

  // For cool animation 😎
  const [isDismissed, setIsDismissed] = React.useState(false);
  const dismissMatches = useDebouncedCallback((companyIds: number[]) => setDismissedMatches(distinct(companyIds)), 310);
  const [featuredCompanyInDialog, setFeaturedCompanyInDialog] = React.useState<number | undefined>();

  const isDesktopUp = useMediaQuery(bluePlanetTheme.breakpoints.up('lg'));

  const useCarousel = (isDesktopUp && props.useCarouselOnLargeScreen) || !isDesktopUp;

  return (
    <Resources resources={[matchesResource, shortlistResource, uiState]} renderLoading="Nothing">
      {([matches, shortlists, uiState]) => {
        const companies = matches.filter(it => !(uiState.dismissedMatches ?? {})[it.details.id]);
        const findNextMatch = (companyId: number) =>
          companies.find((_, index) => companies[index - 1]?.details.id === companyId)?.details.id;
        const findPreviousMatch = (companyId: number) =>
          companies.find((_, index) => companies[index + 1]?.details.id === companyId)?.details.id;

        return (
          companies.length > 0 && (
            <div>
              <MobileMargin>
                <Container
                  className={props.className}
                  isDismissed={isDismissed}
                  useCarouselOnLargeScreen={props.useCarouselOnLargeScreen}
                >
                  <SectionHeadingLarge heading="Companies you might find interesting">
                    <Button
                      kind="tertiary"
                      disabled={isDismissed}
                      color="grey"
                      onClick={() => {
                        setIsDismissed(true);
                        dismissMatches(companies.map(c => c.details.id));
                      }}
                    >
                      Dismiss
                    </Button>
                  </SectionHeadingLarge>
                  {useCarousel ? (
                    <Carousel
                      slidesWidth={{ sm: '80%', md: '40%', lg: '28%' }}
                      bleed={defaultBleedAmounts}
                      options={{ dragFree: 'mobile' }}
                    >
                      {companies.map(company => (
                        <CompanyProfileCard
                          key={company.details.id}
                          company={company}
                          action={{
                            type: 'click',
                            handler: companyId => setFeaturedCompanyInDialog(companyId),
                          }}
                          shortlists={shortlists.values}
                          setShortlists={setShortlistResource}
                        />
                      ))}
                    </Carousel>
                  ) : (
                    <div className="u-flex u-flex--column" style={{ gap: '1rem' }}>
                      {companies.map(company => (
                        <CompanyProfileCard
                          key={company.details.id}
                          company={company}
                          action={{
                            type: 'click',
                            handler: companyId => setFeaturedCompanyInDialog(companyId),
                          }}
                          shortlists={shortlists.values}
                          setShortlists={setShortlistResource}
                        />
                      ))}
                    </div>
                  )}
                </Container>
              </MobileMargin>

              {featuredCompanyInDialog && (
                <CompanyProfileDialog
                  analyticsContext="featured-matches"
                  companyId={featuredCompanyInDialog}
                  actionBar={{
                    onNext: () => setFeaturedCompanyInDialog(findNextMatch(featuredCompanyInDialog)),
                    onPrevious: () => setFeaturedCompanyInDialog(findPreviousMatch(featuredCompanyInDialog)),
                    hasNext: !!findNextMatch(featuredCompanyInDialog),
                    hasPrevious: !!findPreviousMatch(featuredCompanyInDialog),
                  }}
                  analyticsCommunityId="none"
                  onClose={() => setFeaturedCompanyInDialog(undefined)}
                />
              )}
            </div>
          )
        );
      }}
    </Resources>
  );
}
