import React, { useRef, useState } from 'react';
import Accordion from '.';
import styled from '@emotion/styled';

interface Props {
  title: React.ReactNode;
  buttons: ActionButton[];
  children: React.ReactNode;
  className?: string;
  isExpanded?: boolean;
  paddingHeader?: 'none' | 'default' | 'double';
  paddingContent?: 'none' | 'default';
}

interface ActionButton {
  element: JSX.Element;
  onClick: () => void;
}

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;

const ContentLeft = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ContentRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default function AccordionWithButtons({
  title,
  buttons,
  paddingHeader,
  paddingContent,
  className,
  isExpanded,
  children,
}: Props) {
  const [expanded, setExpanded] = useState(isExpanded);
  const refs = useRef<{ element: HTMLDivElement | null; onClick: () => void }[]>([]);

  function onChange(e: any) {
    const res = refs.current.find(ref => ref.element && ref.element.contains(e.target));
    if (res) {
      res.onClick();
    } else {
      setExpanded(!expanded);
    }
  }

  return (
    <Accordion
      title={
        <ContentContainer>
          <ContentLeft>{title}</ContentLeft>
          <ContentRight>
            {buttons.map((button, idx) => (
              <div
                key={idx}
                ref={element => (refs.current[idx] = { element, onClick: button.onClick })}
                className="u-half-spacing-right"
              >
                {button.element}
              </div>
            ))}
          </ContentRight>
        </ContentContainer>
      }
      isExpanded={expanded}
      onChange={onChange}
      showPill={false}
      paddingHeader={paddingHeader}
      paddingContent={paddingContent}
      className={className}
      accordionBackgroundColor="transparent"
      summaryBackgroundColor="default"
      detailsBackgroundColor="grey-light"
    >
      <div className="u-content-spacing-left">{children}</div>
    </Accordion>
  );
}
