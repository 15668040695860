import { SnackbarVariant, SnackStore, SnackbarAction } from 'types/snackbar';

const initalState: SnackStore = {
  open: false,
  message: '',
  durationInMs: 0,
};

export enum SnackbarActionType {
  InflateSnackbar = 'INFLATE_SNACKBAR',
  CloseSnackbar = 'CLOSE_SNACKBAR',
}

interface InflateSnackbar extends SnackStore {
  type: SnackbarActionType.InflateSnackbar;
}

interface CloseSnackbar {
  type: SnackbarActionType.CloseSnackbar;
}

const snackReducer = (store: SnackStore = initalState, action: InflateSnackbar | CloseSnackbar): SnackStore => {
  switch (action.type) {
    case SnackbarActionType.InflateSnackbar:
      return {
        ...store,
        open: action.open,
        message: action.message,
        timestamp: action.timestamp,
        variant: action.variant,
        durationInMs: action.durationInMs,
        action: action.action,
      };
    case SnackbarActionType.CloseSnackbar:
      return {
        ...store,
        open: false,
      };
    default:
      return store;
  }
};

export default snackReducer;

export const inflateSnackbarAction = (
  timestamp: string,
  message: string,
  durationInMs?: number,
  variant?: SnackbarVariant,
  action?: SnackbarAction,
): InflateSnackbar => {
  return {
    timestamp,
    message,
    variant,
    durationInMs,
    action,
    open: true,
    type: SnackbarActionType.InflateSnackbar,
  };
};
