import ApiBase from 'apis/ApiBase';
import config from 'config';
import {
  MentorAsk,
  Mentorshiplist,
  UpdateMenteeReport,
  UpdateMentorAsk,
  UpdateMentorReport,
  UpdateMentorshipScope,
} from './types';
import { DocumentDownloadDTO, UploadDocumentResponse } from 'types/documents';
import { CompanyId } from 'urls';

const baseUrl = config.COMPANIES_API_URL;

export const mentorUrls = {
  listForMentor: (slug: string) => `${baseUrl}/companies/${slug}/mentorships/mentor`,
};

const api = ApiBase();

export const mentorAPI = {
  mentorships: {
    list: (companyId: number) => api.get<Mentorshiplist>(`${baseUrl}/companies/${companyId}/mentorships`),

    mentorAsk: {
      get: (companyId: number, mentorshipId: UUID) =>
        api.get<MentorAsk>(`${baseUrl}/companies/${companyId}/mentorships/${mentorshipId}/mentor-ask`),
      update: (companyId: number, mentorshipId: UUID, payload: UpdateMentorAsk) =>
        api.patch<MentorAsk>(`${baseUrl}/companies/${companyId}/mentorships/${mentorshipId}/mentor-ask`, payload),
    },
    mentorAgreement: {
      upload: (companyId: number, mentorshipId: UUID, payload: { filename: string }) =>
        api.post<UploadDocumentResponse>(
          `${baseUrl}/companies/${companyId}/mentorships/${mentorshipId}/mentor-agreement`,
          payload,
        ),
      download: (companyId: number, mentorshipId: UUID) =>
        api.get<DocumentDownloadDTO>(`${baseUrl}/companies/${companyId}/mentorships/${mentorshipId}/mentor-agreement`),
    },
    finalReport: {
      download: (companyId: number, mentorshipId: UUID) =>
        api.get<DocumentDownloadDTO>(`${baseUrl}/companies/${companyId}/mentorships/${mentorshipId}/final-report-file`),
      upload: (companyId: number, mentorshipId: UUID, payload: { filename: string }) =>
        api.post<UploadDocumentResponse>(
          `${baseUrl}/companies/${companyId}/mentorships/${mentorshipId}/final-report-file`,
          payload,
        ),
      update: (companyId: CompanyId, mentorshipId: UUID, finalReport: UpdateMenteeReport) =>
        api.patch(`${baseUrl}/companies/${companyId}/mentorships/${mentorshipId}/final-report`, finalReport),
    },
    mentorFinalReport: {
      update: (companyId: CompanyId, mentorshipId: UUID, finalReport: UpdateMentorReport) =>
        api.patch(`${baseUrl}/companies/${companyId}/mentorships/${mentorshipId}/mentor/final-report`, finalReport),
    },
    scope: {
      update: (companyId: CompanyId, mentorshipId: UUID, finalReport: UpdateMentorshipScope) =>
        api.patch<{ scope: string }>(
          `${baseUrl}/companies/${companyId}/mentorships/${mentorshipId}/scope`,
          finalReport,
        ),
      updateAsMentor: (companyId: CompanyId, mentorshipId: UUID, finalReport: UpdateMentorshipScope) =>
        api.patch<{ scope: string }>(
          `${baseUrl}/companies/${companyId}/mentorships/${mentorshipId}/mentor/scope`,
          finalReport,
        ),
    },
  },
};
