import React from 'react';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import useKeyboard from 'hooks/useKeyboard';
import ActionBarButton from 'ui/views/dialogs/CenteredModalDialog/ActionBarButton';

interface ActionBarProps {
  onNext: () => void;
  onPrevious: () => void;
  hasNext: boolean;
  hasPrevious: boolean;
  shouldDisableKeyboardNavigation?: () => boolean;
  label?: string;
}

export default function NextPreviousBar(props: ActionBarProps) {
  const { onNext, onPrevious, hasNext, hasPrevious, label, shouldDisableKeyboardNavigation } = props;
  useKeyboard(
    {
      ArrowRight: () => {
        hasNext && onNext();
      },
      ArrowLeft: () => {
        hasPrevious && onPrevious();
      },
    },
    shouldDisableKeyboardNavigation,
    [hasNext, hasPrevious, shouldDisableKeyboardNavigation],
  );

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <ActionBarButton disabled={!hasPrevious} onClick={onPrevious} style={{ textAlign: 'left' }}>
        <ChevronLeftIcon /> Previous
        <span>
          {label ? ' ' : ''}
          {label}
        </span>
      </ActionBarButton>
      <ActionBarButton disabled={!hasNext} onClick={onNext} style={{ textAlign: 'right' }}>
        Next
        <span>
          {label ? ' ' : ''}
          {label}
        </span>
        <ChevronRightIcon />
      </ActionBarButton>
    </div>
  );
}
