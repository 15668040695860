import styled from '@emotion/styled';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import BookmarkActiveIcon from 'ui/elements/icons/BookmarkActiveIcon';
import BookmarkIcon from 'ui/elements/icons/BookmarkIcon';
import { pluralize } from 'util/stringUtils';
import { Shortlist } from 'types/user';

const StyledShortlistName = styled.span`
  color: ${bluePlanetTheme.bluePlanetPalette.blue.main};
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export function ShortlistButtonText({ shortlists, hideText }: { shortlists: Shortlist[]; hideText?: boolean }) {
  return shortlists.length ? (
    <>
      <BookmarkActiveIcon className="u-half-spacing-right" color="blue" fillColor="blue" />
      <div className="u-flex u-ellipsis u-flex--gap-quarter">
        Saved in <StyledShortlistName>{shortlists[0].name}</StyledShortlistName>
        {shortlists.length > 1 && ` and ${shortlists.length - 1} ${pluralize(shortlists.length - 1, 'other list')}`}
      </div>
    </>
  ) : (
    <>
      <BookmarkIcon className="u-half-spacing-right" />
      {!hideText && <span>Add to list</span>}
    </>
  );
}
