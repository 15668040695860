import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function InviteUserIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M1 18c0-2.3 3-2.3 4.5-3.8.8-.7-1.5-.7-1.5-4.4C4 7.3 5 6 7 6s3 1.3 3 3.8c0 3.7-2.3 3.7-1.5 4.4C10 15.8 13 15.8 13 18M22 11h-8M18 7v8" />
    </SvgIcon>
  );
}
