import { darken } from '@mui/material';
import MuiSvgIcon from '@mui/material/SvgIcon';
import React, { useMemo } from 'react';
import { bluePlanetTheme } from 'ui/theme';

export interface IconProps {
  color?: IconColor;
  fillColor?: IconColor;
  fontSize?: 'inherit' | 'medium' | 'large' | 'small';
  transform?: string;
  strokeWidth?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
  viewBox?: string;
  children?: JSX.Element | JSX.Element[];
}

export type IconColor =
  | 'blue'
  | 'dark-grey'
  | 'green-medium'
  | 'green'
  | 'grey'
  | 'indigo'
  | 'inherit'
  | 'orange'
  | 'orange-medium'
  | 'purple'
  | 'red'
  | 'red-medium'
  | 'white';

export function getColor(color?: IconColor) {
  if (!color) {
    return 'inherit';
  }
  if (color === 'blue') {
    return bluePlanetTheme.bluePlanetPalette.blue.main;
  } else if (color === 'red') {
    return bluePlanetTheme.bluePlanetPalette.red.main;
  } else if (color === 'red-medium') {
    return bluePlanetTheme.bluePlanetPalette.red.medium;
  } else if (color === 'indigo') {
    return bluePlanetTheme.bluePlanetPalette.indigo.main;
  } else if (color === 'grey') {
    return bluePlanetTheme.bluePlanetPalette.grey.main;
  } else if (color === 'dark-grey') {
    return bluePlanetTheme.bluePlanetPalette.grey.dark;
  } else if (color === 'green') {
    return bluePlanetTheme.bluePlanetPalette.green.main;
  } else if (color === 'green-medium') {
    return bluePlanetTheme.bluePlanetPalette.green.medium;
  } else if (color === 'purple') {
    return bluePlanetTheme.bluePlanetPalette.purple.main;
  } else if (color === 'orange') {
    return bluePlanetTheme.bluePlanetPalette.orange.main;
  } else if (color === 'orange-medium') {
    return bluePlanetTheme.bluePlanetPalette.orange.medium;
  } else if (color === 'white') {
    return bluePlanetTheme.bluePlanetPalette.white;
  } else {
    return darken(bluePlanetTheme.bluePlanetPalette.grey.medium, 0.2);
  }
}

export default function SvgIcon(props: IconProps) {
  const sx = useMemo(
    () => ({
      fill: 'none',
      stroke: 'currentColor',
      transition: bluePlanetTheme.transitions.create('stroke', {
        duration: bluePlanetTheme.transitions.duration.shorter,
      }),
      verticalAlign: 'middle',
      color: getColor(props.color),
    }),
    [props.color],
  );

  return (
    <MuiSvgIcon
      sx={sx}
      className={props.className}
      fontSize={props.fontSize}
      style={props.style}
      onClick={props.onClick}
      viewBox={props.viewBox}
    >
      <g
        fillRule="evenodd"
        fill={props.fillColor ? getColor(props.fillColor) : 'none'}
        strokeLinecap="round"
        strokeWidth={props.strokeWidth ?? 1.5}
        transform={props.transform}
      >
        {props.children}
      </g>
    </MuiSvgIcon>
  );
}
