import { createGenericPaginatedReducer, GenericPaginatedStore } from '../common/genericPaginatedResource';
import { bindActionCreators, Dispatch } from 'redux';
import conversationApi, { Conversation, UserConversation } from 'apis/ContentAPI/conversationApi';
import { PaginationOptions } from 'types/api';
import { getCompanyProfiles } from 'actions/companyProfiles';
import { getUserProfiles } from 'actions/userProfiles';
import { RootStore } from 'globalstate/rootStore';
import { notEmpty } from 'util/arrayUtils';
import { addItem, filterInList } from 'util/paginationUtils';
import { conversationActions as conversationListActions } from 'globalstate/user/conversationList';
import { TAction } from 'types';
import flatten from 'ramda/src/flatten';
import uniq from 'ramda/src/uniq';
import { invalidate } from 'hooks/useSWR';
import { conversationMeKey } from 'apis/CompanyAPI/users/useConversationMe';

type Resource = Conversation;
export type UserConversationsStore = GenericPaginatedStore<Conversation>;
export type UserConversationRequestStore = GenericPaginatedStore<UserConversation>;

const conversationsResourceName = 'user inbox messages';
const conversationRequestsResourceName = 'user inbox requests';

const { reducer: conversationsReducer, actions: conversationActions } = createGenericPaginatedReducer<Resource, UUID>(
  conversationsResourceName,
  t => t.id,
);
const { reducer: conversationRequestReducer, actions: conversationRequestActions } = createGenericPaginatedReducer<
  UserConversation,
  UUID
>(conversationRequestsResourceName, t => t.id);

export { conversationActions, conversationsReducer, conversationRequestActions, conversationRequestReducer };

export function listUserConversations(fetchType: 'initial' | 'page', paginationOptions?: PaginationOptions) {
  return (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({ getCompanyProfiles, getUserProfiles }, dispatch);
    if (fetchType === 'initial') {
      dispatch(conversationActions.fetching());
    }
    conversationApi.user
      .listConversations(paginationOptions)
      .then(result => {
        if (fetchType === 'initial') {
          dispatch(conversationActions.fetched(result));
        } else {
          dispatch(conversationActions.fetchedPage(result));
        }
        boundActions.getCompanyProfiles(
          result.values
            .map(v => (v.type === 'user-company' ? v.companyConversation.companyId : undefined))
            .filter(notEmpty),
        );

        const userConversationsWith = flatten(
          result.values
            .map(v =>
              v.type === 'user-user'
                ? v.userConversation.activeParticipants.concat(v.userConversation.inactiveParticipants)
                : undefined,
            )
            .filter(notEmpty),
        );
        const latestMessagesUsers = result.values.map(v => v.latestReply.creatorCwUserId);

        boundActions.getUserProfiles(uniq(userConversationsWith.concat(latestMessagesUsers)));
      })
      .catch(() => {
        dispatch(conversationActions.fetchedFailed());
      });
  };
}

export function listUserConversationRequests(fetchType: 'initial' | 'page', paginationOptions?: PaginationOptions) {
  return (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({ getUserProfiles }, dispatch);
    if (fetchType === 'initial') {
      dispatch(conversationRequestActions.fetching());
    }
    conversationApi.user
      .listConversationRequests(paginationOptions)
      .then(result => {
        if (fetchType === 'initial') {
          dispatch(conversationRequestActions.fetched(result));
        } else {
          dispatch(conversationRequestActions.fetchedPage(result));
        }

        const userConversationsWith = flatten(
          result.values
            .map(v =>
              v.type === 'user-user'
                ? v.userConversation.activeParticipants.concat(v.userConversation.inactiveParticipants)
                : undefined,
            )
            .filter(notEmpty),
        );
        const latestMessagesUsers = result.values.map(v => v.latestReply.creatorCwUserId);

        boundActions.getUserProfiles(uniq(userConversationsWith.concat(latestMessagesUsers)));
      })
      .catch(() => {
        dispatch(conversationRequestActions.fetchedFailed());
      });
  };
}

export function userConversationAdded(conversation: Conversation) {
  return (dispatch: Dispatch, getState: () => RootStore) => {
    const state = getState().user.conversations;
    if (state.state === 'fetched') {
      dispatch(conversationActions.fetched(addItem(state.resource, conversation, 'before')));
    }
  };
}

export function userConversationRequestRemoved(conversationId: UUID) {
  return (dispatch: Dispatch, getState: () => RootStore) => {
    const state = getState().user.conversationRequests;
    if (state.state === 'fetched') {
      dispatch(
        conversationRequestActions.fetched(
          filterInList(state.resource, (c: UserConversation) => c.id !== conversationId),
        ),
      );
    }
  };
}

export function markConversationAsRead(conversation: Conversation): TAction {
  return dispatch => {
    invalidate(conversationMeKey);
    dispatch(conversationListActions.updated(conversation.id, prev => ({ ...prev, hasUnreadMessages: false })));
  };
}
