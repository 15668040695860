import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function HornIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M15 4.4 8.2 7.6c-.5.3-1 .3-1.6.2-.4 0-.6-.1-.7-.2-1.9-.2-3 1.3-3 3v.9c0 1.6 1.1 3 3 2.9l.7-.2c.5-.1 1 0 1.6.2l6.6 3.2c1.6.7 2.3 1 3.2.8.8-.3 1.1-.9 1.7-2.1 1.6-3.4 1.6-7.2 0-10.6-.6-1.2-.9-1.8-1.7-2.1-.9-.3-1.6 0-3.2.8Z" />
      <path d="M13 17v.5c0 1.3 0 2-.2 2.3-.3.5-.9.7-1.4.7-.5 0-1-.4-2-1.2l-1.2-.9C7.2 17.7 7 17.2 7 16v-1.5M7.5 14V8" />
    </SvgIcon>
  );
}
