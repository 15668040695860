import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import React from 'react';
import useLazyResource from 'util/resource/useLazyResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import { invalidateStartsWith } from 'hooks/useSWR';
import useNotify from 'hooks/useNotify';
import { OrganizationInviteDTO } from 'types/organization';

interface WithdrawInvitationDialogProps {
  invite: OrganizationInviteDTO;
  onClose: () => void;
}

export default function WithdrawInvitationDialog(props: WithdrawInvitationDialogProps) {
  const notify = useNotify();

  const [retractInvite, isLoading] = useLazyResource(
    () => OrganizationAPI.retractInvite(props.invite.organizationId, props.invite.inviteCode),
    {
      onSuccess: () => {
        invalidateStartsWith([OrganizationAPI.listInvitesUrl(props.invite.organizationId)]);
        props.onClose();
        notify('success', `Invitation removed`);
      },
      onFailure: () => {
        notify('error', `Something unexpected happened when trying to withdraw invitation.`);
      },
    },
  );

  return (
    <Dialog open={true} onClose={props.onClose} backdropColor="light" maxWidth="sm">
      <MobileMargin>
        <Title style={{ paddingBottom: 0 }} onClose={props.onClose}>
          Withdraw invitation
        </Title>
        <Content style={{ paddingTop: 0, paddingBottom: 0 }}>
          <div className={'u-flex u-flex--column u-half-spacing-top'}>
            <span>Are you sure you would like to withdraw the invitation to {props.invite.email}?</span>
          </div>
        </Content>
        <DialogActions>
          <Button kind="primary" isLoading={isLoading} onClick={retractInvite}>
            Withdraw
          </Button>
          <Button kind="tertiary" onClick={props.onClose}>
            Cancel
          </Button>
        </DialogActions>
      </MobileMargin>
    </Dialog>
  );
}
