import SvgIcon, { IconProps } from '../SvgIcon';
import React from 'react';

export default function PngIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3.5 13.77v-.8c0-2.97 0-4.45.47-5.64a7.24 7.24 0 0 1 4.37-4.12c1.26-.44 2.83-.44 5.98-.44 1.8 0 2.7 0 3.42.25a4.14 4.14 0 0 1 2.5 2.36c.26.67.26 1.52.26 3.22v5.17" />
      <path d="M3.5 12.77c0-1.84 1.5-3.33 3.33-3.33.67 0 1.45.12 2.1-.06.58-.15 1.03-.6 1.18-1.18.17-.64.06-1.43.06-2.1 0-1.84 1.49-3.33 3.33-3.33" />
      <path d="M3.5 22.34v-2.4m0 0v-2.4c0-.88.08-1.2.99-1.2h1.48c.82 0 1.49.8 1.49 1.8s-.67 1.8-1.49 1.8H3.5Z" />
      <path d="M10.52 22.58v-6.24l2.96 6.24v-6.24M20.5 16.68a2.81 2.81 0 0 0-1.43-.13c-1.57.37-2.13 1.49-2.13 2.9 0 1.98.49 3.03 3.56 2.86v-2.17h-1.36" />
    </SvgIcon>
  );
}
