import { Fade, Popper } from '@mui/material';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import EditIcon from 'ui/elements/icons/EditIcon';
import { communityUrls, companyUrls, companyOverviewSections } from 'urls';
import styles from '../styles.scss';
import Logo from 'ui/domain/Logo';
import { ICompany } from 'types/company';

export function CompanyOrCommunityLogo({
  companyOrCommunity,
  canEditCompany,
  routeContext,
}: {
  companyOrCommunity: ICompany;
  canEditCompany: boolean;
  routeContext: 'company' | 'community';
}) {
  const location = useLocation();
  const currentPath = location.pathname;
  const [open, setOpen] = useState(false);

  const overviewHrefs = companyOrCommunity
    ? routeContext === 'community'
      ? [communityUrls.overview(companyOrCommunity.slug)]
      : companyOverviewSections.map(section => companyUrls.overview(companyOrCommunity.slug, section))
    : [];
  const editLogoUrl =
    companyOrCommunity &&
    (routeContext === 'community'
      ? communityUrls.settings.information(companyOrCommunity.slug, 'general-information')
      : companyUrls.settings.settings(companyOrCommunity.slug, 'images'));
  const companyLogoWrapperRef = useRef(null);

  const showEditLink = () => {
    setOpen(true);
  };

  const hideEditLink = () => {
    setOpen(false);
  };

  return (
    <>
      {companyOrCommunity && (
        <>
          <Link
            className={classnames('u-flex u-flex-center', styles.link, {
              [styles.selected]: overviewHrefs.filter(href => currentPath.includes(href)).length > 0,
            })}
            to={overviewHrefs[0]}
            ref={companyLogoWrapperRef}
            {...(canEditCompany
              ? {
                  onMouseEnter: showEditLink,
                  onMouseLeave: hideEditLink,
                  'aria-describedby': 'edit-company-logo-link',
                }
              : {})}
          >
            <div>
              <div className={styles.companyLogoWrapper}>
                <Logo url={companyOrCommunity.logoURL} type={routeContext} size={59} />
              </div>
              <p>Overview</p>
            </div>
          </Link>
          {canEditCompany && !companyOrCommunity.logoURL && (
            <Popper
              id="edit-company-logo-link"
              placement="right-end"
              open={open}
              anchorEl={companyLogoWrapperRef.current}
              className="z-layer-4"
              keepMounted
              modifiers={[
                { name: 'inner', enabled: true, options: { order: 100 } },
                { name: 'offset', enabled: true, options: { offset: [-30, -35] } },
              ]}
              transition
              style={{ overflow: 'hidden' }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Link
                    to={editLogoUrl ?? ''}
                    className={classnames(styles.overlayButton)}
                    data-track-edit-company-logo-from-navbar-desktop
                    onMouseEnter={showEditLink}
                    onMouseLeave={hideEditLink}
                  >
                    <EditIcon color="blue" />
                  </Link>
                </Fade>
              )}
            </Popper>
          )}
        </>
      )}
    </>
  );
}
