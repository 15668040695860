import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function BellIcon(props: IconProps) {
  return (
    <SvgIcon viewBox="0 0 23 23" {...props}>
      <path d="M2.5 14.8c-.2 1.4.8 2.3 2 2.8a21.3 21.3 0 0 0 15 0c1.2-.5 2.2-1.4 2-2.8-.2-.9-.8-1.6-1.3-2.3a5 5 0 0 1-.7-3 7.5 7.5 0 0 0-15 0c0 1 0 2-.7 3-.5.7-1.1 1.4-1.3 2.3Z" />
      <path d="M8 19c.5 1.7 2 3 4 3s3.5-1.3 4-3" />
    </SvgIcon>
  );
}
