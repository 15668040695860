import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function TrendingUpIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m3 17 6-6 4 4 7.4-7.4M20.4 7.6 21 7" />
      <path d="M21 10V7h-3" />
    </SvgIcon>
  );
}
