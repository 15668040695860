import React from 'react';
import WarningIcon from 'ui/elements/icons/WarningIcon';
import InfoCard from '../cards/InfoCard';
import Button from 'ui/elements/buttons/Button';

export default function ErrorCard() {
  return (
    <InfoCard title="Oops" icon={<WarningIcon fontSize="large" strokeWidth={1} />}>
      We were unable to load this part 😳. The error has been reported and we will be working hard to fix it. <br />
      You might want to try to refresh the page, or try again at a later time. Sorry for the inconvenience.
      <div className="u-content-spacing-top">
        <Button kind="secondary" onClick={() => window.location.reload()}>
          Refresh page
        </Button>
      </div>
    </InfoCard>
  );
}
