import useIO from 'util/resource/useLazyResource';
import useNotify from 'hooks/useNotify';
import { ExecutiveSummaryDTO, ICompany } from 'types/company';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { useCompanyLastUpdated } from '../profile/LastUpdated';
import { invalidate } from 'hooks/useSWR';
import { getExecutiveSummaryKey } from 'apis/CompanyAPI/companies/useExecutiveSummary';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { companyDashboardKey } from 'pages/Dashboard/useDashboardData';
import { companyOnboardingKey } from 'apis/OnboardingAPI';

export default function useSaveExecutiveSummary(company: ICompany, props: { name?: string; onComplete: () => void }) {
  const name = props.name ?? 'Executive summary';
  const { setLastUpdated } = useCompanyLastUpdated(company.id);
  const notify = useNotify();
  return useIO((payload: ExecutiveSummaryDTO) => companiesApi.executiveSummary.patch(company.slug, payload), {
    onSuccess: () => {
      invalidate(getExecutiveSummaryKey(company.id));
      invalidate(userDashboardKey);
      invalidate(companyDashboardKey(company.id));
      invalidate(companyOnboardingKey(company.slug));
      notify('success', `${name} saved`);
      props.onComplete && props.onComplete();
      setLastUpdated(new Date());
    },
    onFailure: message => notify('error', message || `Unable to save ${name.toLowerCase()}, please try again later.`),
  });
}
