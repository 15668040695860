import { Investor } from 'apis/CompanyAPI/insights/types';
import React from 'react';
import { AnonymousUserDTO, getAnonymousUserTitle, getInvestorUserTitle } from 'types/user';
import UserIcon from 'ui/elements/icons/UserIcon';
import Card from 'ui/views/cards/Card';
import StatsCard from 'ui/views/cards/StatsCard';

interface Props {
  value: number;
  users: AnonymousUserDTO[] | Investor[];

  title: string;
  tooltip: string;
  openUserDialog?: (cwUserId: UUID) => void;
}

export default function EngagementStats({ value, users, title, tooltip, openUserDialog }: Props) {
  return (
    <div>
      <StatsCard className="u-quarter-spacing-bottom" title={title} value={value} tooltip={tooltip} />
      <Card elevation={1} style={{ height: '100%;' }}>
        <p className="text-small text-weight-medium">Last 5 {title.toLocaleLowerCase()} by</p>
        {users.length ? (
          users.map((user, i) => {
            return (
              <div className="u-flex u-flex-align-center u-half-spacing-top text-font-text text-tiny" key={i}>
                <UserIcon fontSize="small" className="u-half-spacing-right" />
                {'personalia' in user ? (
                  <span>
                    <button
                      style={{ display: 'inline' }}
                      onClick={() => openUserDialog && openUserDialog(user.personalia.cwUserId)}
                    >
                      <b>{user.personalia.name}</b>
                    </button>
                    {getInvestorUserTitle(user)}
                  </span>
                ) : (
                  <>{getAnonymousUserTitle(user)}</>
                )}
              </div>
            );
          })
        ) : (
          <p className="text-tiny text-italic u-half-spacing-top">No {title.toLocaleLowerCase()} in period</p>
        )}
      </Card>
    </div>
  );
}
