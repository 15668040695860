import { useTheme } from '@mui/material/styles';
import { formatAsKpi } from 'domain/kpis/utils';
import React from 'react';
import { Area, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import { NameType } from 'recharts/types/component/DefaultTooltipContent';
import Card from 'ui/views/cards/Card';
import { asShortDate, asUnixMillis } from 'util/dateUtils';

export interface LineSeriesPoint {
  x: number;
  y: number;
}

export interface ChartData {
  data: LineSeriesPoint[];
}

interface Props {
  name: string;
  main: ChartData;
  unit?: string;
}

export default function AreaChart(props: Props) {
  const main = props.main.data.length > 0 ? props.main : { data: [{ x: asUnixMillis(new Date()), y: 0 }] };

  const theme = useTheme();

  return (
    <ResponsiveContainer height={200}>
      <ComposedChart
        data={main.data}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid vertical={false} />
        <Tooltip content={<ToolTip unit={props.unit} />} />
        <Area
          type="monotone"
          dataKey="y"
          name={props.name}
          stroke={theme.bluePlanetPalette.blue.main}
          fill={theme.bluePlanetPalette.blue.veryLight}
          fillOpacity={0.4}
          strokeWidth={4}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function ToolTip(props: TooltipProps<number, NameType> & { unit?: string }) {
  const { active, payload, unit } = props;
  if (active && payload && payload.length) {
    return (
      <Card elevation={2}>
        {payload.every(entry => entry.value === undefined) ? (
          <p>No data</p>
        ) : (
          payload.map(p => (
            <p key={p.dataKey}>
              <strong>{formatAsKpi(p.value ?? 0)}</strong> {unit}
            </p>
          ))
        )}
        <p className="text-metadata">{asShortDate(payload[0]?.payload?.x)}</p>
      </Card>
    );
  }

  return null;
}
