import { UserProfile } from 'types/user';
import { Message } from './Message/types';
import groupWith from 'ramda/src/groupWith';
import addMinutes from 'date-fns/addMinutes';
import isBefore from 'date-fns/isBefore';

export function getConversationName(conversationWith: UserProfile[]) {
  const names = conversationWith.map(u => u.name);
  if (names.length < 1) return 'Someone';
  if (names.length === 1) return names[0];
  return `${names.slice(0, names.length - 1).join(', ')} and ${names[names.length - 1]}`;
}

export function messageListToBlocks(messages: Message[]) {
  // Turns a list of messages into message blocks wherever they were sent by the same user within a given timeframe
  return groupWith(
    (a: Message, b: Message) =>
      a.creatorCwUserId === b.creatorCwUserId &&
      isBefore(new Date(b.createdAt), addMinutes(new Date(a.createdAt), 5)) &&
      a.type === b.type,
    messages,
  );
}
