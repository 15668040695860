import { Organization, OrganizationUser } from 'types/organization';
import React from 'react';
import SelectDialog from 'pages/Organization/Settings/ConnectionSettings/SelectDialog';
import useNotify from 'hooks/useNotify';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';

interface DisplayAsDialogProps {
  initialUser: OrganizationUser;
  organization: Organization;
  organizationUsers: OrganizationUser[];
  onClose: () => void;
}

export default function DisplayAsDialog(props: DisplayAsDialogProps) {
  const notify = useNotify();
  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(props.organization, {
    onSuccess: () => {
      notify('success', 'User updated');
      props.onClose();
    },
  });

  return (
    <SelectDialog
      isLoading={isUpdatingOrganization}
      onClose={props.onClose}
      onSubmit={selectedOptions => {
        const displayAs = [];
        if (selectedOptions.includes('Contact person')) {
          displayAs.push('public_contact_person');
        }
        if (selectedOptions.includes('Team member')) {
          displayAs.push('public_team_member');
        }

        const updatedUser = {
          id: props.initialUser.user.cwUserId,
          isAdmin: props.initialUser.isAdmin,
          displayAs: displayAs,
        };

        updateOrganization({
          users: props.organizationUsers.map(user => {
            if (user.user.cwUserId === updatedUser.id) {
              return updatedUser;
            } else {
              return {
                id: user.user.cwUserId,
                isAdmin: user.isAdmin,
                displayAs: user.displayAs,
              };
            }
          }),
        });
      }}
      selectionOptions={[
        {
          name: 'Contact person',
          selected: props.initialUser.displayAs.includes('public_contact_person'),
        },
        {
          name: 'Team member',
          selected: props.initialUser.displayAs.includes('public_team_member'),
        },
      ]}
      selectionType={'multi-select'}
      title={`Add display for ${props.initialUser.user.name}${props.initialUser.user.email ? '\n' + '(' + props.initialUser.user.email + ')' : ''}`}
    />
  );
}
