import { useEffect, useLayoutEffect, useRef } from 'react';

// This beautiful hook will execute a callback at a given time, unless the tab is not visible
// When the tab becomes visible again, the callback will be executed immediately (or at it's original schedule, if it is in the future)
// When executeAt is changed, the old schedule is replaced with the new one (or just canceled if executeAt is undefined)
export function useDelayedExecution(callback: () => void, executeAt: Date | undefined) {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    if (!executeAt) {
      return;
    }

    const delay = executeAt.getTime() - Date.now();
    let timeoutId: NodeJS.Timeout;

    // Stop the scheduled execution when the tab is not visible and resume when it becomes visible again
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const delay = executeAt.getTime() - Date.now();

        timeoutId = setTimeout(() => savedCallback.current(), delay);
      } else {
        clearTimeout(timeoutId);
      }
    };

    if (document.visibilityState === 'visible') {
      timeoutId = setTimeout(() => savedCallback.current(), delay);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(timeoutId);
    };
  }, [executeAt]);
}
