import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function SearchIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M17.5 17.5 22 22" />
      <path d="M20 11a9 9 0 1 0-18 0 9 9 0 0 0 18 0Z" />
    </SvgIcon>
  );
}
