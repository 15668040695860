import React, { useState } from 'react';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import { useSelector } from 'react-redux';
import { RootStore } from 'globalstate/rootStore';
import { listUserConversationRequests, listUserConversations } from 'globalstate/user/conversationList';
import useSharedPaginatedResource from 'hooks/useSharedPaginatedResource';
import LoadableResources from 'util/resource/Resources';
import { Conversation } from 'apis/ContentAPI/conversationTypes';
import NewConversationButton from '../NewConversationButton';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import Tab from 'ui/modules/tabs/Tab';
import Tabs from 'ui/modules/tabs/Tabs';
import MessageList from './MessageList';

interface Props {
  setActiveChat: (conversation: Conversation) => void;
  dialogHandler: DialogHandlerProps;
}

export default function UserInboxContent(props: Props) {
  const { setActiveChat } = props;
  const { resource: userProfileResource } = useSelfUserProfile();
  const [activeTab, setActiveTab] = useState<'messages' | 'requests'>('messages');

  const state = useSelector((state: RootStore) => ({
    companyProfiles: state.companyProfiles.items,
    userProfiles: state.userProfiles.items,
  }));

  const [latestConversations, , fetchConversationsPage] = useSharedPaginatedResource(
    state => state.user.conversations,
    (isInitialFetch, pagOpts) => {
      if (!props.dialogHandler.isOpen) {
        return () => {};
      }
      return listUserConversations(isInitialFetch, pagOpts || { limit: 30 });
    },
    [props.dialogHandler.isOpen],
  );

  const [latestConversationRequests, , fetchRequestsPage] = useSharedPaginatedResource(
    state => state.user.conversationRequests,
    (isInitialFetch, pagOpts) => {
      if (!props.dialogHandler.isOpen) {
        return () => {};
      }
      return listUserConversationRequests(isInitialFetch, pagOpts || { limit: 30 });
    },
    [props.dialogHandler.isOpen],
  );

  return (
    <div className="u-content-padding">
      <div className="u-flex-align-center u-flex-space-between">
        <h3 className="text-h3">Inbox</h3>
        <NewConversationButton />
      </div>
      <LoadableResources resources={[latestConversations, latestConversationRequests, userProfileResource]}>
        {([conversations, requests, userProfile]) => (
          <>
            <div>
              <Tabs className="u-content-spacing-y" activeTab={activeTab}>
                <Tab
                  onClick={() => setActiveTab('messages')}
                  value="messages"
                  label="Messages"
                  hasActivityIndicator={conversations.values.some(c => c.hasUnreadMessages)}
                />
                <Tab
                  onClick={() => setActiveTab('requests')}
                  value="requests"
                  label="Requests"
                  hasActivityIndicator={requests.values.some(r => r.hasUnreadMessages)}
                />
              </Tabs>
            </div>
            {activeTab === 'messages' && (
              <MessageList
                companyProfiles={state.companyProfiles}
                userProfiles={state.userProfiles}
                conversations={conversations}
                fetchConversations={fetchConversationsPage}
                cwUserId={userProfile.cwUserId}
                setActiveChat={setActiveChat}
                type="messages"
              />
            )}
            {activeTab === 'requests' && (
              <MessageList
                companyProfiles={state.companyProfiles}
                userProfiles={state.userProfiles}
                conversations={requests}
                fetchConversations={fetchRequestsPage}
                cwUserId={userProfile.cwUserId}
                setActiveChat={setActiveChat}
                type="requests"
              />
            )}
          </>
        )}
      </LoadableResources>
    </div>
  );
}
