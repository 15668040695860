import { GoalState } from 'types/goals';

const validGoalStateTransitions: Record<GoalState, GoalState[]> = {
  draft: ['active'],
  active: ['archived', 'completed'],
  archived: ['active'],
  completed: [],
};

export const isValidGoalStateTransition = (current: GoalState, next: GoalState): boolean =>
  validGoalStateTransitions[current].includes(next);
