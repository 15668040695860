import Hidden from '@mui/material/Hidden';
import { NavItem } from 'domain/shared/Navigation/types';
import React, { useContext, useEffect, useState } from 'react';
import { Auth0User } from 'types';
import CWLogo from 'ui/elements/icons/CrowdWorksLogo';
import LogoutIcon from 'ui/elements/icons/LogoutIcon';
import { backofficeUrls, userUrls } from 'urls';
import Desktop from './Desktop';
import Mobile from './Mobile';
import styles from './styles.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { getAdminRights } from 'auth/util';
import AdminIcon from 'ui/elements/icons/AdminIcon';
import CollectionIcon from 'ui/elements/icons/BookmarkActiveIcon';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import DiscoverIcon from 'ui/elements/icons/DiscoverIcon';
import NetworkIcon from 'ui/elements/icons/NetworkIcon';

const logo = <CWLogo />;
const logOutIcon = <LogoutIcon className={styles.icon} />;

interface NavigationItems {
  navLinks: NavItem[];
  navLogo?: JSX.Element;
  navLogoMobile?: (onClick: (url: string) => void) => JSX.Element;
}

export const NavigationContext = React.createContext<
  NavigationItems & {
    setNavigationItems: (value: NavigationItems) => void;
    isOpen: boolean;
    closeNavigation: () => void;
    toggleNavigation: () => void;
    mobileHeader?: string;
    setMobileHeader: (value?: string) => void;
  }
>({
  navLinks: [],
  setNavigationItems: () => {},
  isOpen: false,
  closeNavigation: () => {},
  toggleNavigation: () => {},
  setMobileHeader: () => {},
});

export function useNavigationContext() {
  return useContext(NavigationContext);
}

export function useContextualNavigation(values: NavigationItems, deps: any[]) {
  const { setNavigationItems } = useNavigationContext();

  useEffect(() => {
    setNavigationItems(values);
    return () => {
      setNavigationItems({ navLinks: [], navLogo: undefined, navLogoMobile: undefined });
    };
  }, deps);
}

export function useMobileHeader(value: string) {
  const { setMobileHeader } = useNavigationContext();

  useEffect(() => {
    setMobileHeader(value);
    return () => {
      setMobileHeader(undefined);
    };
  }, [value]);
}

export function NavigationContextProvider({ children }: { children: React.ReactNode | React.ReactNode[] }) {
  const [navigationState, setNavigationState] = useState<NavigationItems>({
    navLinks: [],
  });

  const [isOpen, setIsOpen] = useState(false);
  const closeNavigation = () => setIsOpen(false);
  const toggleNavigation = () => setIsOpen(!isOpen);
  const [mobileHeader, setMobileHeader] = useState<string | undefined>(undefined);

  return (
    <NavigationContext.Provider
      value={{
        ...navigationState,
        setNavigationItems: setNavigationState,
        isOpen,
        closeNavigation,
        toggleNavigation,
        mobileHeader,
        setMobileHeader,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
}

export default function Navigation() {
  const { user: auth0User }: { user?: Auth0User } = useAuth0();
  const { resource: userProfileResource } = useSelfUserProfile();

  const hasRequiredAdminRight = (link: NavItem) =>
    !link.superAdminOnly || getAdminRights(auth0User).includes('backoffice');
  const isVisible = (link: NavItem) => link.isVisible !== false && hasRequiredAdminRight(link);

  const userPreview =
    userProfileResource.state === 'fetched'
      ? {
          name: userProfileResource.resource.name,
          imageUrl: userProfileResource.resource.imageUrl,
        }
      : {};

  const globalLinks: NavItem[] = [
    {
      href: userUrls.collections.list,
      label: 'Collections',
      identifier: 'collections',
      icon: <CollectionIcon className={styles.icon} />,
    },
    {
      href: userUrls.dashboard.network.overview(),
      label: 'Network',
      identifier: 'network',
      icon: <NetworkIcon className={styles.icon} />,
    },
    {
      href: userUrls.discover,
      identifier: 'explore',
      label: 'Discover',
      icon: <DiscoverIcon className={styles.icon} />,
    },
    {
      href: backofficeUrls.view,
      identifier: 'backoffice',
      label: 'Backoffice',
      superAdminOnly: true,
      icon: <AdminIcon className={styles.icon} />,
    },
  ].filter(isVisible);

  const { navLinks, navLogo, navLogoMobile, isOpen, toggleNavigation, closeNavigation, mobileHeader } =
    useNavigationContext();

  return (
    <>
      <Hidden mdDown>
        <Desktop
          inContextLogo={navLogo}
          userPreview={userPreview}
          inContextLinks={navLinks.filter(isVisible)}
          globalLinks={globalLinks}
          icons={{ logo, logOutIcon }}
        />
      </Hidden>
      <Hidden mdUp>
        <Mobile
          userPreview={userPreview}
          inContextLinks={navLinks.filter(isVisible)}
          inContextLogo={navLogoMobile}
          globalLinks={globalLinks}
          icons={{ logo, logOutIcon }}
          isOpen={isOpen}
          closeMenu={closeNavigation}
          toggleMenu={toggleNavigation}
          mobileHeader={mobileHeader}
        />
      </Hidden>
    </>
  );
}
