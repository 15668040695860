import classNames from 'classnames';
import React, { useEffect } from 'react';
import { CSSProperties, useRef, useState } from 'react';
import LinkButton from '../buttons/LinkButton';
import styles from './styles.scss';

export default function ClampedText({
  numLines,
  children,
  style,
  className,
}: {
  numLines: 3 | 5;
  children: string;
  style?: CSSProperties;
  className?: string;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);
  const [isTextClamped, setIsTextClamped] = useState(
    contentRef.current ? contentRef.current.scrollHeight > contentRef.current.clientHeight : false,
  );

  // when the component first renders, the contentRef.current is null.  To ensure that isTextClamped is set correctly
  // we need to update it after the contentRef has been set.
  useEffect(() => {
    if (contentRef.current) {
      setIsTextClamped(contentRef.current.scrollHeight > contentRef.current.clientHeight);
    }
  }, [contentRef.current]);

  return (
    <div style={style} className={className}>
      <div
        ref={contentRef}
        className={classNames({
          [styles.clamped5]: !isExpanded && numLines === 5,
          [styles.clamped3]: !isExpanded && numLines === 3,
        })}
      >
        {children}
      </div>

      {isTextClamped && !isExpanded && (
        <div>
          <LinkButton
            size="small"
            onClick={() => {
              setIsExpanded(true);
            }}
          >
            Read more
          </LinkButton>
        </div>
      )}
    </div>
  );
}
