import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function UsersIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M1 18c0-2.3 3-2.3 4.5-3.8.8-.7-1.5-.7-1.5-4.4C4 7.3 5 6 7 6s3 1.3 3 3.8c0 3.7-2.3 3.7-1.5 4.4C10 15.8 13 15.8 13 18m-.2-2.3 1.2-.5c.6-.2 1-.5 1.5-1 .8-.7-1.5-.7-1.5-4.4C14 7.3 15 6 17 6s3 1.3 3 3.8c0 3.7-2.3 3.7-1.5 4.4C20 15.8 23 15.8 23 18" />
      <path d="m12 16 .8-.3" />
    </SvgIcon>
  );
}
