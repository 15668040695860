import Resource from 'util/resource/Resource';
import SeeMore from 'ui/modules/Pagination/SeeMore';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import useTabState from 'hooks/useTabState';
import React from 'react';
import { Link } from 'react-router-dom';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { Goal, GoalFilter, GoalState } from 'types/goals';
import AddAction from 'ui/elements/actions/AddAction';
import Tab from 'ui/modules/tabs/Tab';
import Tabs from 'ui/modules/tabs/Tabs';
import InfoCard from 'ui/views/cards/InfoCard';
import { companyUrls } from 'urls';
import { PAGINATION_SMALL_PAGESIZE } from 'util/constants';
import { addPage } from 'util/paginationUtils';
import GoalList from './All/List';
import GoalsAPI from 'apis/GoalsAPI';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';

const getFilter = (params: URLSearchParams): GoalState => {
  const filter = params.get('tab') || 'active';
  if (['draft', 'active', 'completed', 'archived'].includes(filter)) {
    return filter as GoalState;
  }
  return 'active';
};

const getFilterForState = (state: GoalState): GoalFilter => {
  switch (state) {
    case 'draft':
      return { state, sortBy: 'createdAt', sortOrder: 'desc' };
    case 'active':
      return { state, sortBy: 'deadline', sortOrder: 'asc' };
    case 'archived':
      return { state, sortBy: 'archivedAt', sortOrder: 'desc' };
    case 'completed':
      return { state, sortBy: 'completedAt', sortOrder: 'desc' };
  }
};

export default function GoalsList({ company, viewAs }: { company: ICompany; viewAs: CompanyViewAs }) {
  const params = new URLSearchParams(location.search);
  const [stateFilter, setStateFilter] = useTabState<GoalState>(getFilter(params));
  const isCompanyAdmin = viewAs === 'Admin';

  const [goalsResource, setGoalsResource] = useResourceLegacy(
    () => GoalsAPI.goal(company.id).list(getFilterForState(stateFilter), { limit: PAGINATION_SMALL_PAGESIZE }),
    [company.id, stateFilter],
  );

  const refresh = async () => {
    const result = await GoalsAPI.goal(company.id).list(getFilterForState(stateFilter), {
      limit: PAGINATION_SMALL_PAGESIZE,
    });
    setGoalsResource(() => result);
  };

  const seeMore = async (existing: PaginatedResult<Goal>, paginationOptions: PaginationOptions) => {
    const result = await GoalsAPI.goal(company.id).list(getFilterForState(stateFilter), paginationOptions);
    setGoalsResource(() => addPage(existing, result));
  };

  const urls = companyUrls;

  return (
    <>
      {isCompanyAdmin ? (
        <div className="u-flex u-flex-end">
          <Link to={urls.goals.new(company.slug)}>
            <AddAction>New goal</AddAction>
          </Link>
        </div>
      ) : null}
      <Tabs className="u-half-spacing-bottom" activeTab={stateFilter} onChange={setStateFilter}>
        <Tab label="Active" value="active" />
        <Tab label="Completed" value="completed" />
        {isCompanyAdmin && <Tab label="Archived" value="archived" />}
        {isCompanyAdmin && <Tab label="Draft" value="draft" />}
      </Tabs>
      <Resource resource={goalsResource}>
        {result => {
          const goals = result.values;
          return goals.length > 0 ? (
            <>
              <GoalList company={company} goals={goals} refresh={refresh} viewAs={viewAs} />
              <SeeMore
                className="u-content-spacing-top"
                limit={PAGINATION_SMALL_PAGESIZE}
                resource={result}
                loadResource={opt => seeMore(result, opt)}
                showRemaining
              />
            </>
          ) : (
            <InfoCard>There are currently no {stateFilter} goals.</InfoCard>
          );
        }}
      </Resource>
    </>
  );
}
