import Dialog, { Title, Content, DialogActions } from 'ui/views/dialogs/Dialog';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { useCookieConsent } from 'CookieConsent';
import React from 'react';
import Button from 'ui/elements/buttons/Button';

export default function PleaseEnableCookiesDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { show: showCookieConsent } = useCookieConsent();
  return (
    <Dialog open={open} onClose={() => onClose}>
      <Title>You need to accept cookies to chat with support</Title>
      <Content>
        Our chat service is provided by a third party, Intercom. To use this service, you need to accept cookies.
      </Content>
      <DialogActions>
        <ButtonList>
          <Button
            kind="primary"
            onClick={() => {
              showCookieConsent();
              onClose();
            }}
          >
            Adjust cookie settings
          </Button>
          <Button kind="tertiary" onClick={onClose}>
            Close
          </Button>
        </ButtonList>
      </DialogActions>
    </Dialog>
  );
}
