import { IResource } from 'util/resource';
import { useSelector } from 'react-redux';
import { RootStore } from 'globalstate/rootStore';

type SelectSharedResource<T> = [IResource<T>];

export function useResourceSelector<T>(selectResource: (store: RootStore) => IResource<T>): SelectSharedResource<T> {
  const resource = useSelector(selectResource);

  return [resource];
}
