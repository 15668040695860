import PageTitle from 'ui/views/layouts/BaseLayout/PageTitle';
import React from 'react';
import MaxWidth from 'ui/views/containers/MaxWidth';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { contentSpacing, halfSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import { useMobileHeader } from 'domain/shared/Navigation';
import ScrollableBody from './ScrollableBody';
import styles from './styles.scss';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  heading?: React.ReactNode;
  marginTop?: 'default' | 'none';
  navigationStack?: { title: string; href?: string }[];
  children: React.ReactNode;
}

export const defaultBleedAmounts = {
  xs: halfSpacing,
  sm: contentSpacing,
  md: contentSpacing,
  lg: sectionSpacing,
};

export const Content = styled.div(
  ({ marginTop = 'default' }: { marginTop?: 'default' | 'none' }) => `
    margin-right: ${contentSpacing};
    margin-left: ${contentSpacing};
    margin-top: ${marginTop === 'none' ? 0 : contentSpacing};
    
  ${bluePlanetTheme.breakpoints.down('sm')} {
    margin-top: ${marginTop === 'none' ? 0 : halfSpacing};
    margin-right: 0;
    margin-left: 0;
  }

  ${bluePlanetTheme.breakpoints.up('lg')} {
    margin-right: ${sectionSpacing};
    margin-left: ${sectionSpacing};
    margin-top: ${marginTop === 'none' ? 0 : sectionSpacing};
  }
`,
);

export const MobileMargin = styled.div(`
  ${bluePlanetTheme.breakpoints.down('sm')} {
    margin-right: ${halfSpacing};
    margin-left: ${halfSpacing};
  }
`);

export const Heading = styled.div`
  background-color: ${bluePlanetTheme.bluePlanetPalette.white};
  padding: ${contentSpacing};

  ${bluePlanetTheme.breakpoints.down('sm')} {
    padding-right: ${halfSpacing};
    padding-left: ${halfSpacing};
  }

  ${bluePlanetTheme.breakpoints.up('md')} {
    margin-bottom: ${contentSpacing};
  }

  ${bluePlanetTheme.breakpoints.up('lg')} {
    padding: ${sectionSpacing};
    margin-bottom: ${sectionSpacing};
  }
`;

export default function BaseLayout(props: Props) {
  const { title } = props;
  useMobileHeader(title);
  return (
    <>
      <PageTitle title={title} />
      <ScrollableBody accountForMobileHeader>
        {props.heading && (
          <Heading>
            <MaxWidth style={{ margin: '0 auto' }} width="xl" className="u-flex u-flex-space-between">
              {props.heading}
            </MaxWidth>
          </Heading>
        )}

        {props.navigationStack && (
          <div className={styles.bannerHeader}>
            <MaxWidth className={styles.innerBannerHeader} width="xl">
              <div className={styles.breadcrumb}>
                {props.navigationStack.map((entry, index) => {
                  const isLastItem = index === (props.navigationStack?.length ?? 0) - 1;
                  return (
                    <span key={index} style={{ opacity: isLastItem ? 1 : 0.6 }}>
                      {entry.href ? <Link to={entry.href}>{entry.title}</Link> : entry.title}
                      {!isLastItem && <span className="u-quarter-spacing-left u-quarter-spacing-right">/</span>}
                    </span>
                  );
                })}
              </div>
            </MaxWidth>
          </div>
        )}
        <Content marginTop={props.marginTop}>
          <MaxWidth width="xl" style={{ margin: '0 auto' }}>
            {props.children}
          </MaxWidth>
        </Content>
      </ScrollableBody>
    </>
  );
}
