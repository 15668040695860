import styled from '@emotion/styled';
import React from 'react';
import { halfSpacing } from 'ui/theme/themeConstants';

interface Props {
  className?: string;
  children: React.ReactNode;
  columnCount?: number;
  gridGap?: 'section' | 'content';
  style?: React.CSSProperties;
}

const StyledDictionary = styled.dl(
  (props: { columnCount: number }) => `
  gap: ${halfSpacing};
  display: grid;
  grid-template-columns: repeat(${props.columnCount}, 125px minmax(min-content, 100%));
`,
);

export default function Dictionary({ columnCount = 1, children, ...rest }: Props) {
  return (
    <StyledDictionary columnCount={columnCount} {...rest}>
      {children}
    </StyledDictionary>
  );
}
