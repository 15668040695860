import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { ICompany } from 'types/company';
import { bluePlanetTheme } from 'ui/theme';
import { quarterSpacing } from 'ui/theme/themeConstants';
import { companyUrls } from 'urls';

const BreadcrumbLink = styled(Link)`
  margin-right: ${quarterSpacing};
  transition: opacity 0.1s ease;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

const BreadcrumbWrapper = styled.div`
  display: none;
  justify-self: flex-start;
  margin-left: 0;
  color: ${bluePlanetTheme.bluePlanetPalette.white};
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  align-items: center;
  ${bluePlanetTheme.breakpoints.up('md')} {
    display: flex;
  }
`;

function SubBreadcrumb(props: { path?: string; title: string }) {
  return props.path ? (
    <>
      <span className="u-quarter-spacing-left u-quarter-spacing-right">/</span>
      <BreadcrumbLink to={props.path}>{props.title}</BreadcrumbLink>
    </>
  ) : (
    <>
      <span className="u-quarter-spacing-left u-quarter-spacing-right">/</span>
      <span>{props.title}</span>
    </>
  );
}

interface Props {
  breadcrumbs?: { title: string; path: string }[];
  path?: string;
  title: string;
  company: ICompany;
}

export default function Breadcrumb({ company, breadcrumbs, title }: Props) {
  return (
    <BreadcrumbWrapper>
      <BreadcrumbLink to={companyUrls.overview(company.slug, 'profile')}>{company.name}</BreadcrumbLink>
      {breadcrumbs &&
        breadcrumbs.map((breadcrumb, i) => (
          <SubBreadcrumb key={`breadcrumb=${i}`} path={breadcrumb.path} title={breadcrumb.title} />
        ))}
      <SubBreadcrumb title={title} />
    </BreadcrumbWrapper>
  );
}
