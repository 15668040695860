import React from 'react';
import Button from 'ui/elements/buttons/Button';
import useLazyResource from 'util/resource/useLazyResource';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import { FeedbackContext } from 'types/user';
import i18n from 'util/i18n';
import useNotify from 'hooks/useNotify';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import { useCloseableMessage } from 'apis/CompanyAPI/users/useUiState';

export default function ContactMeButton({
  tag,
  feedback,
  companyId,
  buttonText,
}: {
  tag: string;
  feedback: FeedbackContext;
  companyId?: number;
  buttonText?: string;
}) {
  const { isClosed: hasSent, closeMessage: setHasSent, isLoading } = useCloseableMessage(tag);

  const notify = useNotify();

  const [sendFeedback, isSendingFeedback] = useLazyResource(
    (feedbackContext: FeedbackContext) =>
      usersApi.feedback({
        feedback: undefined,
        anonymous: false,
        feedbackContext: feedbackContext,
        companyId,
      }),
    {
      onSuccess: () => {
        setHasSent();
      },
      onFailure: () => {
        notify('error', i18n('en').errors.send('feedback'));
      },
    },
  );

  return (
    !isLoading && (
      <div className="text-nowrap u-fade-in--quick">
        {hasSent ? (
          <p className="u-flex u-flex--gap-quarter u-flex-align-center">
            <IconAvatar color="success" size={{ width: '20px', height: '20px' }}>
              <CheckmarkIcon />
            </IconAvatar>
            We will contact you soon.
          </p>
        ) : (
          <Button kind="primary" color="blue" isLoading={isSendingFeedback} onClick={() => sendFeedback(feedback)}>
            {buttonText || 'Contact me'}
          </Button>
        )}
      </div>
    )
  );
}
