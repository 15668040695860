import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
const quotes = [
  `Companies with lots of connections are seen as trustworthy investments. Its a credibility boost that other investors notice. Don't forget to invite your shareholders!`,
  `Even though you are not actively seeking funding today, remember to post traction signals and keep your shareholders and followers in the loop! In a years time, when you need to raise money - they will be ready for your funding round.`,
  `Your shareholders want to see your company succeed, and can be your greatest ambassadors! By inviting them to your company, you're giving them an active role in your journey.`,
  `Share regular updates and keep your shareholders and followers in the loop on your company's growth. Frequent traction signals like posts on KPI updates, new hires, wins and challenges will help you build trust over time.`,
  `Engaged shareholders and followers will share your company profile with their own networks, helping you build momentum for you company.`,
];

const QuoteContainer = styled.div`
  max-width: 600px;
  text-align: center;
  opacity: 0;
  animation: fadeInOut 12s linear infinite;

  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0;
    }
    10%,
    90% {
      opacity: 1;
    }
  }
`;

export default function Quotes() {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuoteIndex(prevIndex => (prevIndex + 1) % quotes.length);
    }, 12000);
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return <QuoteContainer className="u-half-spacing-top">{quotes[currentQuoteIndex]}</QuoteContainer>;
}
