import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ChartIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21 21H10c-3.3 0-5 0-6-1s-1-2.7-1-6V3" />
      <path d="M8 17c3.5 0 11-1.5 10.7-10.6M16.5 8l1.9-1.9c.2-.1.5-.2.7 0l1.9 2" />
    </SvgIcon>
  );
}
