import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ClipIcon(props: IconProps) {
  return (
    <>
      <SvgIcon {...props}>
        <path d="m8 13.8 7.2-7.2a2.3 2.3 0 0 1 3.1 3.1l-9.9 9.8a3.7 3.7 0 1 1-5.3-5.3l9.8-9.7a5.2 5.2 0 1 1 7.5 7.5l-7.2 7" />
      </SvgIcon>
    </>
  );
}
