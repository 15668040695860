import { useAuth0 } from '@auth0/auth0-react';
import { createRedirectUri } from 'auth/util';
import classnames from 'classnames';
import { OrangeBadge } from 'ui/elements/OrangeBadge';
import useDialogHandler from 'hooks/useDialogHandler';
import { NavItem } from 'domain/shared/Navigation/types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Avatar from 'ui/elements/avatars/Avatar';
import CrowdWorksLogo from 'ui/elements/icons/CrowdWorksLogo';
import DashboardIcon from 'ui/elements/icons/DashboardIcon';
import Tooltip from 'ui/elements/Tooltip';
import { userUrls } from 'urls';
import styles from '../styles.scss';
import CompanySelector from './CompanySelector';
import HelpIcon from 'ui/elements/icons/HelpIcon';
import { isHomeTab } from 'pages/Dashboard/Dashboard';

interface Props {
  inContextLinks: NavItem[];
  globalLinks: NavItem[];
  inContextLogo?: JSX.Element;
  icons: {
    logo: React.ReactNode;
    logOutIcon: React.ReactNode;
  };
  userPreview: {
    name?: string;
    imageUrl?: string;
  };
}

export default function Desktop({ inContextLinks, globalLinks, inContextLogo, userPreview, icons }: Props) {
  const { logout } = useAuth0();
  const location = useLocation();
  const currentPath = location.pathname;
  const dialogHandler = useDialogHandler();

  return (
    <>
      <CompanySelector dialogHandler={dialogHandler} />
      <nav className={styles.navigation}>
        <nav className={styles.inner}>
          <button
            className={styles.logoContainer}
            onClick={() => (dialogHandler.isOpen ? dialogHandler.close() : dialogHandler.open())}
          >
            <span
              className={classnames(styles.logoWrapper, dialogHandler.isOpen && styles.openNavbarIcon)}
              onClick={() => (dialogHandler.isOpen ? dialogHandler.close() : dialogHandler.open())}
            >
              <CrowdWorksLogo
                strokeWidth={1}
                style={{ height: 55, width: 55 }}
                className={classnames(styles.cwLogo, {
                  [styles.selected]: dialogHandler.isOpen,
                })}
                onClick={() => dialogHandler.close()}
              />
            </span>
          </button>
          <div className={styles.links} data-intercom-target="nav-bar">
            <Link
              onClick={dialogHandler.close}
              to={userUrls.dashboard.home()}
              data-intercom-target="nav-dashboard-button"
              className={classnames('u-flex u-flex-center', styles.link, {
                [styles.selected]: isHomeTab(currentPath),
              })}
            >
              <DashboardIcon className={styles.icon} />
              Home
            </Link>
            <div className={inContextLogo || inContextLinks?.length > 0 ? styles.inContextLinks : ''}>
              {inContextLogo}
              {inContextLinks.map(({ href, label, icon, indicator, identifier, hideLabelOnDesktop }, index) => {
                const content =
                  indicator && indicator > 0 ? <OrangeBadge badgeContent={indicator}>{icon}</OrangeBadge> : icon;

                const tip = (key: string, children: Children) =>
                  hideLabelOnDesktop ? (
                    <Tooltip key={key} title={`${label}`} placement="right">
                      {children}
                    </Tooltip>
                  ) : (
                    <span key={key}>{children}</span>
                  );

                return tip(
                  `desktop-context-link-${index}`,
                  <Link
                    data-intercom-target={`nav-${identifier}`}
                    to={href}
                    className={classnames(styles.link, {
                      [styles.selected]: currentPath.startsWith(href),
                      [styles.small]: inContextLinks.length > 7,
                    })}
                  >
                    <div>{content}</div>
                    <p>{hideLabelOnDesktop ? '' : label}</p>
                  </Link>,
                );
              })}
            </div>
            {globalLinks && (
              <div>
                {globalLinks.map(({ href, label, icon, indicator, identifier }, index) => {
                  const content =
                    indicator && indicator > 0 ? <OrangeBadge badgeContent={indicator}>{icon}</OrangeBadge> : icon;
                  return (
                    <Link
                      data-intercom-target={`nav-${identifier}`}
                      key={`desktop-global-link-${index}`}
                      to={href}
                      className={classnames(styles.link, {
                        [styles.selected]: href === currentPath,
                        [styles.small]: inContextLinks.length > 7,
                      })}
                    >
                      <div>{content}</div>
                      <p>{label}</p>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
          <div>
            <Link to="/help" className={classnames(styles.reverseLink, currentPath === '/help' && styles.selected)}>
              <HelpIcon />
              <p>Help</p>
            </Link>
            <Link
              data-intercom-target="user-profile-box"
              to="/profile"
              className={classnames(styles.reverseLink, currentPath === '/profile' && styles.selected)}
            >
              <Avatar imageUrl={userPreview.imageUrl} className={styles.avatar} />
              <p className={styles.userName}>{userPreview.name}</p>
            </Link>
            <a
              className={styles.reverseLink}
              onClick={e => {
                e.preventDefault();
                logout({
                  returnTo: createRedirectUri('/'),
                });
              }}
            >
              <div>{icons.logOutIcon}</div>
              <p>Log out</p>
            </a>
          </div>
        </nav>
      </nav>
    </>
  );
}
