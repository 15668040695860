import React, { useState } from 'react';
import { UserProfile } from 'types/user';
import { AuthorImage, asUserAuthor } from 'domain/shared/author';
import styles from './styles.scss';
import { Message } from 'apis/ContentAPI/conversationApi';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Button from 'ui/elements/buttons/Button';
import { userRole } from 'ui/domain/Users/userUtils';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import useDialogHandler from 'hooks/useDialogHandler';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import Resource from 'util/resource/Resource';
import { ConversationInsights } from 'domain/users/UserProfile/Insights';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import Card from 'ui/views/cards/Card';

interface Props {
  onAccept: () => Promise<void>;
  onDecline: () => Promise<void>;
}

interface MessagerRequestProps extends Props {
  message: Message;
  author?: UserProfile;
  onAuthorClick: (authorId: string) => void;
}

interface RequestDeclineDialogProps {
  dialogHandler: DialogHandlerProps;
  onClick: () => Promise<void>;
}

function RequestDeclineDialog(props: RequestDeclineDialogProps) {
  const [isLoadingDecline, setLoadingDecline] = useState(false);

  async function handleDecline() {
    setLoadingDecline(true);
    try {
      await props.onClick();
    } finally {
      setLoadingDecline(false);
    }
  }

  return (
    <Dialog open={props.dialogHandler.isOpen} onClose={() => props.dialogHandler.close()} backdropColor="medium">
      <>
        <Title
          onClose={() => props.dialogHandler.close()}
          subheading="Are you sure that you want to decline this request?"
        >
          Decline message request
        </Title>
        <Content className="u-half-spacing-bottom">
          If you decline this message request it will no longer be available. You will still be able to contact this
          user if you choose to do so.
        </Content>
        <DialogActions>
          <Button isLoading={isLoadingDecline} onClick={handleDecline} kind="primary">
            Decline
          </Button>
          <Button onClick={() => props.dialogHandler.close()} kind="tertiary">
            Cancel
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}

export function MessageRequestStrip(props: Props) {
  const { onAccept, onDecline } = props;
  const [isLoadingAccept, setLoadingAccept] = useState(false);
  const declineDialogHandler = useDialogHandler();

  async function handleAccept() {
    setLoadingAccept(true);
    try {
      await onAccept();
    } finally {
      setLoadingAccept(false);
    }
  }

  return (
    <div className={styles.requestStrip}>
      <div className="u-flex u-flex-align-center u-flex--gap-half">
        <IconAvatar className={styles.requestStripIcon} size={{ width: '40px', height: '40px' }}>
          <ChatBubblesIcon color="blue" fontSize="small" />
        </IconAvatar>
        <div className={styles.requestCardText}>Accept to join chat</div>
      </div>
      <ButtonList>
        <Button kind="primary" color="white" onClick={handleAccept} isLoading={isLoadingAccept}>
          Accept
        </Button>
        <Button kind="tertiary" color="white" onClick={() => declineDialogHandler.open()} disabled={isLoadingAccept}>
          Decline
        </Button>
      </ButtonList>
      {declineDialogHandler.isOpen && <RequestDeclineDialog dialogHandler={declineDialogHandler} onClick={onDecline} />}
    </div>
  );
}

export default function MessageRequest(props: MessagerRequestProps) {
  const { message, author, onAccept, onDecline, onAuthorClick } = props;
  const [isLoadingAccept, setLoadingAccept] = useState(false);
  const userAuthor = asUserAuthor(author);
  const declineDialogHandler = useDialogHandler();

  const [detailedUserResource] = useResourceLegacy(
    () => usersApi.userProfiles.getDetailed(userAuthor.cwUserId),
    [props.author],
  );

  async function handleAccept() {
    setLoadingAccept(true);
    try {
      await onAccept();
    } finally {
      setLoadingAccept(false);
    }
  }

  return (
    <Card color="light-grey" padding="double" className={styles.requestCard}>
      <div className={styles.requestCardHeader}>
        <span>
          <AuthorImage
            onClick={author?.cwUserId ? () => onAuthorClick(author?.cwUserId) : undefined}
            author={userAuthor}
            size={60}
          />
        </span>
        <div className="u-flex-center u-flex--column u-flex--align-items-start u-half-spacing-left">
          <button onClick={author?.cwUserId ? () => onAuthorClick(author?.cwUserId) : undefined} title={author?.name}>
            <span className="text-weight-medium">{userAuthor.name}</span>
          </button>
          <span className="text-small">{author && userRole(author)}</span>
        </div>
      </div>
      <Resource resource={detailedUserResource}>
        {detailedUser => <ConversationInsights user={detailedUser} />}
      </Resource>
      <div className={styles.requestCardText}>{message.content}</div>
      <ButtonList>
        <Button kind="primary" onClick={handleAccept} isLoading={isLoadingAccept}>
          Accept
        </Button>
        <Button kind="secondary" onClick={() => declineDialogHandler.open()} disabled={isLoadingAccept}>
          Decline
        </Button>
      </ButtonList>
      {declineDialogHandler.isOpen && <RequestDeclineDialog dialogHandler={declineDialogHandler} onClick={onDecline} />}
    </Card>
  );
}
