import React from 'react';
import Button from 'ui/elements/buttons/Button';
import { CompanyProfile } from 'types/company';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import BrandingForm from 'domain/shared/Branding/BrandingForm';

interface Props {
  company: CompanyProfile;
  onClose: () => void;
  onEdit: () => void;
  isOpen: boolean;
}

export default function BrandingDialog({ isOpen, company, onClose, onEdit }: Props) {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" mobileLayout="fullscreen">
      <Title onClose={onClose}>Company Branding</Title>
      <Content>
        <BrandingForm
          companyId={company.id}
          companySlug={company.slug}
          logoURL={company.logoURL}
          imageURL={company.imageURL}
          onImageUploaded={onEdit}
          onLogoUploaded={onEdit}
        />
      </Content>
      <DialogActions>
        <Button kind="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
