import React, { JSXElementConstructor, ReactElement } from 'react';
import { ResponsiveContainer } from 'recharts';
import Card from 'ui/views/cards/Card';

type Props = {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
};

export default function StatsChartContainer(props: Props) {
  return (
    <Card
      elevation={1}
      padding="none"
      className="u-section-padding-top u-section-padding-bottom u-content-padding-right"
    >
      <ResponsiveContainer width="100%" height={300}>
        {props.children}
      </ResponsiveContainer>
    </Card>
  );
}
