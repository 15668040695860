import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ChatIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M14.2 20.9a8.4 8.4 0 0 0 7.8-8v-2.4a8.4 8.4 0 0 0-7.8-8H9.8a8.4 8.4 0 0 0-7.8 8V13c.1 1.5.8 3 1.6 4.2.5.8.2 1.9-.3 2.8-.4.7-.5 1-.4 1.2.2.3.5.3 1.1.3 1.2 0 2-.3 2.7-.8l.7-.4c.2 0 .4 0 1 .3l1.4.3h4.4Z" />
    </SvgIcon>
  );
}
