import {
  DialogContent,
  DialogTitle,
  IconButton,
  DialogContentProps,
  DialogActions as MuiDialogActions,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import { DialogTitleProps } from '@mui/material/DialogTitle';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import React, { createContext, useContext } from 'react';
import styles from './styles.scss';
import bluePlanetTheme, { BackdropColor, getBackdropColor } from 'ui/theme/themeBluePlanet';
import { contentSpacing, halfSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import cx from 'classnames';
import MobileDialog, {
  DialogTitle as MobileDialogTitle,
  DialogContent as MobileDialogContent,
  DialogActions as MobileDialogActions,
} from '../MobileDialog';
import DrawerBottom from 'ui/elements/DrawerBottom';
import ButtonList from 'ui/elements/buttons/ButtonList';

interface DialogContextProps {
  mobileLayout?: 'dialog' | 'drawer' | 'fullscreen';
  onClose?: () => void;
}

export const DialogContext = createContext<DialogContextProps | null>(null);

interface Props {
  onClose?: () => void;
  open: DialogProps['open'];
  maxWidth?: DialogProps['maxWidth'];
  fullScreen?: DialogProps['fullScreen'];
  backdropColor?: BackdropColor;
  backdropBlur?: 'none' | 'some';
  backgroundColor?: string;
  scroll?: DialogProps['scroll'];
  verticalAlign?: 'none' | 'top';
  mobileLayout?: 'dialog' | 'drawer' | 'fullscreen';
  className?: string;
  sx?: DialogProps['sx'];
  children: React.ReactNode;
}

type TitleProps = DialogTitleProps & {
  subheading?: React.ReactNode;
  onClose?: () => void;
  closeIconIndent?: 'single' | 'double';
  style?: React.CSSProperties;
  paddingY?: PaddingSizes;
  paddingX?: PaddingSizes;
  backgroundColor?: string;
};

export function Title({
  subheading,
  onClose,
  closeIconIndent,
  style,
  paddingX,
  paddingY,
  className,
  sx,
  backgroundColor,
  children,
}: TitleProps) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const context = useContext(DialogContext);

  if (context?.mobileLayout === 'fullscreen' && isMobile) {
    if (!onClose) {
      throw Error('Dialog.Title: onClose is required when mobileLayoutMode is fullscreen.');
    }
    return <MobileDialogTitle onClose={onClose}>{children}</MobileDialogTitle>;
  }

  const shouldUseDrawer = context?.mobileLayout === 'drawer' && isMobile;

  const closeIcon =
    onClose && !shouldUseDrawer ? (
      <IconButton
        color="inherit"
        onClick={onClose}
        aria-label="Close"
        style={{
          marginTop: isMobile ? 0 : 3,
          marginRight: closeIconIndent === 'double' ? -10 : -5,
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : undefined;

  return (
    <DialogTitle
      style={style}
      sx={{
        ...sx,
        ...{
          paddingY: paddingY === 'none' ? 0 : paddingY === 'half' ? sectionSpacing : undefined,
          paddingX: paddingX === 'none' ? 0 : paddingX === 'half' ? halfSpacing : undefined,
          backgroundColor: backgroundColor === 'grey-light' ? bluePlanetTheme.bluePlanetPalette.grey.light : undefined,
        },
      }}
      className={cx(className, {
        'u-content-padding-y': paddingY === 'half',
      })}
    >
      <div className="u-flex-space-between u-flex-align-center">
        <div className="u-flex u-flex-align-center u-flex-1">{children}</div>
        {closeIcon}
      </div>
      {subheading && <div className={styles.subheading}>{subheading}</div>}
    </DialogTitle>
  );
}
type PaddingSizes = 'default' | 'half' | 'none';

interface DialogActionProps {
  className?: string;
  align?: 'left' | 'center' | 'space-between';
  padding?: PaddingSizes;
  children?: React.ReactNode;
}

export function DialogActions({ className, align, padding, children }: DialogActionProps) {
  const justifyContent = align === 'center' ? 'center' : align === 'space-between' ? 'space-between' : 'start';
  const context = useContext(DialogContext);
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  if (context?.mobileLayout === 'fullscreen' && isMobile) {
    return <MobileDialogActions>{children}</MobileDialogActions>;
  }
  if (context?.mobileLayout === 'drawer' && isMobile) {
    return <ButtonList className="u-content-padding">{children}</ButtonList>;
  }
  return (
    <MuiDialogActions
      className={cx(className, {
        'u-content-padding-y': padding === 'half',
      })}
      style={{ justifyContent }}
    >
      <>{children}</>
    </MuiDialogActions>
  );
}

type ContentProps = DialogContentProps & {
  overflowY?: 'visible' | 'auto';
  paddingY?: 'none' | 'top' | 'bottom' | 'both';
  paddingX?: 'double' | 'default' | 'half' | 'none';
};

export const Content = (
  props: ContentProps & {
    rootRef?: DialogProps['ref'];
  },
) => {
  const { overflowY, paddingY, paddingX, rootRef, sx, ...rest } = props;
  const theme = useTheme();
  const context = useContext(DialogContext);
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  if (context?.mobileLayout === 'fullscreen' && isMobile) {
    return <MobileDialogContent paddingX={paddingX || 'half'} ref={props.ref} {...rest} />;
  }
  if (context?.mobileLayout === 'drawer' && isMobile) {
    return <div className="u-content-padding-x">{props.children}</div>;
  }

  return (
    <DialogContent
      sx={{
        ...sx,
        wordBreak: 'break-word',
        overflowY: overflowY ?? undefined,
        paddingTop: paddingY === 'top' || paddingY === 'both' ? sectionSpacing : 0,
        paddingBottom: paddingY === 'bottom' || paddingY === 'both' ? sectionSpacing : 0,
        paddingLeft: paddingX === 'double' ? sectionSpacing : paddingX === 'default' ? contentSpacing : sectionSpacing,
        paddingRight: paddingX === 'double' ? sectionSpacing : paddingX === 'default' ? contentSpacing : sectionSpacing,
        [theme.breakpoints.down('lg')]: {
          paddingTop: paddingY === 'top' || paddingY === 'both' ? contentSpacing : 0,
          paddingBottom: paddingY === 'bottom' || paddingY === 'both' ? contentSpacing : 0,
          paddingLeft:
            paddingX === 'double' ? sectionSpacing : paddingX === 'default' ? contentSpacing : sectionSpacing,
          paddingRight:
            paddingX === 'double' ? sectionSpacing : paddingX === 'default' ? contentSpacing : sectionSpacing,
        },
      }}
      ref={rootRef}
      {...rest}
    />
  );
};

export default function Dialog(props: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const yMargin = halfSpacing;

  if (props.mobileLayout === 'fullscreen' && isMobile) {
    if (!props.onClose) {
      throw Error('Dialog.Title: onClose is required when mobileLayoutMode is fullscreen.');
    }
    return (
      <DialogContext.Provider value={{ mobileLayout: props.mobileLayout, onClose: props.onClose }}>
        <MobileDialog {...props} />
      </DialogContext.Provider>
    );
  }

  if (props.mobileLayout === 'drawer' && isMobile) {
    if (!props.onClose) {
      throw Error('Dialog.Title: onClose is required when mobileLayoutMode is drawer.');
    }
    return (
      <DialogContext.Provider value={{ mobileLayout: props.mobileLayout, onClose: props.onClose }}>
        <DrawerBottom isOpen={props.open} onClose={props.onClose}>
          <div className="u-flex u-flex-grow u-flex-space-between u-flex--column">{props.children}</div>
        </DrawerBottom>
      </DialogContext.Provider>
    );
  }

  return (
    <DialogContext.Provider
      value={{
        mobileLayout: props.mobileLayout,
        onClose: props.onClose,
      }}
    >
      <MuiDialog
        maxWidth={props.maxWidth}
        sx={
          props.verticalAlign === 'top'
            ? {
                '& .MuiDialog-container:after': {
                  display: 'none',
                },
              }
            : undefined
        }
        PaperProps={{
          style: {
            marginTop: props.verticalAlign === 'top' && !isMobile ? '15vh' : props.fullScreen ? '0' : yMargin,
            marginBottom: props.fullScreen ? 0 : yMargin,
            borderRadius: isMobile && props.fullScreen ? '0' : '8px',
            backgroundColor: props.backgroundColor,
            maxWidth: props.fullScreen ? 'calc(100%)' : undefined,
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: getBackdropColor(props.backdropColor),
            backdropFilter: props.backdropBlur === 'some' ? 'blur(5px)' : 'none',
          },
        }}
        open={props.open}
        onClose={props.onClose}
        fullWidth
        fullScreen={props.fullScreen}
        scroll={props.scroll || 'body'}
      >
        {props.children}
      </MuiDialog>
    </DialogContext.Provider>
  );
}
