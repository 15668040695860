import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function FilterIcon(props: IconProps) {
  const styles = { ...props.style, transform: 'rotate(180deg)' };
  return (
    <SvgIcon {...props} style={styles}>
      <path d="M4 6h16M7 12h10M9.5 18h5" />
    </SvgIcon>
  );
}
