import React from 'react';
import { Organization, OrganizationUser } from 'types/organization';
import useNotify from 'hooks/useNotify';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';
import SelectDialog from 'pages/Organization/Settings/ConnectionSettings/SelectDialog';

interface PermissionDialogProps {
  organization: Organization;
  initialUser: OrganizationUser;
  organizationUsers: OrganizationUser[];
  onClose: () => void;
}

export default function PermissionDialog(props: PermissionDialogProps) {
  const notify = useNotify();
  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(props.organization, {
    onSuccess: () => {
      notify('success', 'User updated');
      props.onClose();
    },
  });

  return (
    <SelectDialog
      isLoading={isUpdatingOrganization}
      onClose={props.onClose}
      onSubmit={selectedOptions => {
        const updatedUser = {
          id: props.initialUser.user.cwUserId,
          isAdmin: selectedOptions.includes('Admin'),
          displayAs: props.initialUser.displayAs,
        };

        const updatedUsers = props.organizationUsers.map(user => {
          if (user.user.cwUserId === updatedUser.id) {
            return updatedUser;
          } else {
            return {
              id: user.user.cwUserId,
              isAdmin: user.isAdmin,
              displayAs: user.displayAs,
            };
          }
        });

        if (!updatedUsers.some(x => x.isAdmin)) {
          notify('error', 'At least one user must be admin of the organization');
          return;
        }

        updateOrganization({
          users: updatedUsers,
        });
      }}
      selectionOptions={[
        {
          name: 'Admin',
          selected: props.initialUser.isAdmin,
        },
        {
          name: 'None',
          selected: !props.initialUser.isAdmin,
        },
      ]}
      selectionType={'single-select'}
      title={`Add permission for ${props.initialUser.user.name}${props.initialUser.user.email ? '\n' + '(' + props.initialUser.user.email + ')' : ''}`}
    />
  );
}
