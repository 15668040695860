import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import useNotify from 'hooks/useNotify';

import { getPublicCompanyUrl } from 'pages/CompanySettings/CompanySharing';
import React, { useState } from 'react';
import { CompanyProfile, Role } from 'types/company';
import CopyButton from 'ui/elements/CopyButton';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import FormRow from 'ui/elements/form/FormRow';
import Label from 'ui/elements/form/Label';
import TextField from 'ui/elements/form/TextField';
import bluePlanetTheme from 'ui/theme/themeBluePlanet';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import i18n from 'util/i18n';
import useLazyResource from 'util/resource/useLazyResource';
import { emailIsValid, emailRegex } from 'util/stringUtils';

function EmailInput({
  autoFocus,
  onBlur,
}: {
  autoFocus: boolean;
  onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}) {
  const [emailInput, setEmailInput] = useState('');
  const [hasBlurred, setHasBlurred] = useState(false);

  const emailError = `invalid email. Please make sure the address is valid, e.g. ${i18n('en').placeholders.email}`;

  return (
    <TextField
      autoFocus={autoFocus}
      error={hasBlurred && emailInput && !emailRegex.test(emailInput) ? true : false}
      helperText={hasBlurred && emailInput && !emailRegex.test(emailInput) ? emailError : ''}
      onBlur={e => {
        setHasBlurred(true);
        onBlur(e);
      }}
      value={emailInput}
      onChange={event => {
        setHasBlurred(false);
        setEmailInput(event.target.value);
      }}
    />
  );
}

export default function ShareAndConnectForm({
  onComplete,
  onBack,
  onClose,
  companyProfile,
}: {
  onComplete: () => void;
  onBack: () => void;
  onClose: () => void;
  companyProfile: CompanyProfile;
}) {
  const notify = useNotify();

  const { setLastUpdated } = useCompanyLastUpdated(companyProfile.id);
  const [multiline, setMultiline] = useState(false);
  const [emails, setEmails] = useState<string[]>(['', '', '']);

  const onUserEmailPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const list = e.clipboardData
      .getData('text')
      .replace(/[\r\n;\s]+/g, ',')
      .split(',')
      .filter(pastedEmail => !emails.find(existingEmail => existingEmail === pastedEmail) && emailIsValid(pastedEmail));
    setEmails([...emails, ...list]);
    e.preventDefault();
  };

  const [onInviteUsers, isInvitingUsers] = useLazyResource(
    () => {
      const validEmails: { email: string; role: Role }[] = emails
        .map(email => email.trim().replace(/,$/, ''))
        .filter(email => email && emailRegex.test(email))
        .map(email => ({
          role: 'prospective_investor',
          email: email,
        }));
      if (validEmails.length > 500) {
        notify('error', 'We only support inviting 500 users at a time');
        return Promise.resolve();
      } else if (validEmails.length > 0) {
        return companiesApi.userInvites.invite(companyProfile.id, validEmails);
      } else {
        return Promise.resolve();
      }
    },
    {
      onSuccess: () => {
        onComplete();
        setLastUpdated(new Date());
      },
      onFailure: () => notify('error', 'Could not invite users'),
    },
  );

  return (
    <>
      <Content>
        <Label>Invite company connections by email</Label>
        <div>
          {multiline ? (
            <FormRow>
              <TextField
                multiline
                minRows={6}
                maxRows={12}
                autoFocus
                onPaste={onUserEmailPaste}
                helperText="Add email addresses one by one, or paste in a list of addresses seperated by comma or line break"
                value={emails.join('\n')}
                onChange={e => setEmails(e.target.value.split('\n'))}
              />
            </FormRow>
          ) : (
            <>
              {emails.map((email, i) => (
                <FormRow key={i}>
                  <EmailInput
                    onBlur={event => setEmails(emails.map((e, j) => (i === j ? event.target.value : e)))}
                    autoFocus={i === 0}
                  />
                </FormRow>
              ))}
              <Button
                className="u-half-spacing-top"
                kind="tertiary"
                onClick={() => {
                  if (emails.length < 5) {
                    setEmails([...emails, '']);
                  } else setMultiline(true);
                }}
              >
                Add another connection
              </Button>
            </>
          )}
          <FormRow>
            <Label>Or invite connections by link</Label>
            <div className="u-flex">
              <TextField
                inputProps={{ readOnly: true, backgroundColor: bluePlanetTheme.bluePlanetPalette.grey.light }}
                value={getPublicCompanyUrl(companyProfile.slug)}
                className="u-half-spacing-right"
              />
              <CopyButton
                copyString={getPublicCompanyUrl(companyProfile.slug)}
                displayString="Copy"
                color="grey"
                kind="secondary"
              />
            </div>
          </FormRow>
        </div>
        <div className="u-content-spacing-top text-metadata-regular">
          Connections get access to your company profile and future updates. You can easily change permissions to
          investor and board member access for confidential information at a later stage. If your connections are
          already on the platform they will be notified directly. Others will recieve an invitation to join your
          company.
        </div>
      </Content>
      <DialogActions align="space-between">
        <ButtonList className="u-section-spacing-top">
          <Button isLoading={isInvitingUsers} onClick={() => onInviteUsers('')} kind="primary">
            Next
          </Button>
          <Button onClick={onBack} kind="primary" color="greyLight">
            Back
          </Button>
        </ButtonList>
        <Button onClick={onClose} kind="tertiary" color="grey">
          Pick it up later
        </Button>
      </DialogActions>
    </>
  );
}
