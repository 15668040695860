import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function GlitterIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M14.607 11.069c0-.194-.17-.35-.379-.35-3.428 0-6.215-3.054-6.215-6.81 0-.194-.17-.35-.379-.35s-.378.156-.378.35c0 3.756-2.79 6.81-6.215 6.81-.21 0-.379.156-.379.35 0 .193.17.35.379.35 3.425 0 6.215 3.054 6.215 6.81 0 .193.169.35.378.35.21 0 .379-.157.379-.35 0-3.756 2.787-6.81 6.215-6.81.21 0 .379-.157.379-.35zM13.224 4.712c1.244 0 2.255 1.127 2.255 2.51 0 .194.17.35.379.35s.378-.156.378-.35c0-1.383 1.012-2.51 2.255-2.51.21 0 .379-.156.379-.35 0-.194-.17-.35-.379-.35-1.243 0-2.255-1.127-2.255-2.51 0-.194-.169-.35-.378-.35-.21 0-.379.156-.379.35 0 1.383-1.011 2.51-2.255 2.51-.21 0-.378.156-.378.35 0 .194.169.35.378.35zM23.017 17.652c-2.074 0-3.761-1.859-3.761-4.143 0-.193-.17-.35-.379-.35s-.378.156-.378.35c0 2.284-1.688 4.143-3.761 4.143-.21 0-.379.159-.379.35 0 .194.17.35.379.35 2.073 0 3.761 1.86 3.761 4.146 0 .193.169.35.378.35.21 0 .379-.157.379-.35 0-2.286 1.687-4.146 3.761-4.146.21 0 .378-.156.378-.35 0-.191-.169-.35-.378-.35z" />
    </SvgIcon>
  );
}
