import React, { ReactNode } from 'react';
import { quarterSpacing } from 'ui/theme/themeConstants';
import MuiAvatarGroup from '@mui/material/AvatarGroup';

interface Props {
  children: ReactNode;
  size: number;
  max: number;
  className?: string;
}

export default function AvatarGroup({ children, size, max, className }: Props) {
  return (
    <MuiAvatarGroup
      className={className}
      style={{ marginRight: quarterSpacing, display: 'inline-flex', alignItems: 'center' }}
      max={max}
      sx={{
        '& .MuiAvatar-root': {
          width: size,
          height: size,
        },
      }}
    >
      {children}
    </MuiAvatarGroup>
  );
}
