import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function WriteIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m16.2 5 1.4-1.4a2 2 0 0 1 2.8 2.8L19 7.8M16.2 5 11 10.2c-1 1-1.6 1.6-2 2.2A19 19 0 0 0 8 16c1.4-.3 3-.7 3.6-1a11 11 0 0 0 2.2-2L19 7.8M16.2 5 19 7.8" />
      <path d="M21 12c0 4.2 0 6.4-1.3 7.7C18.4 21 16.2 21 12 21s-6.4 0-7.7-1.3C3 18.4 3 16.2 3 12s0-6.4 1.3-7.7C5.6 3 7.8 3 12 3" />
    </SvgIcon>
  );
}
