import DocumentAPI from 'apis/DocumentAPI';
import useFileUpload from 'domain/shared/Files/Upload/useFileUpload';
import { uploadFile } from 'domain/shared/Files/actions';
import { FormikProps } from 'formik';
import usePrompt from 'hooks/usePrompt';
import { DocumentList } from 'types/documents';
import { EventFormValues } from './EventDialog';

type Props = {
  formik: FormikProps<EventFormValues>;
  attachments?: DocumentList;
};

export function useEventAttachments({ formik, attachments }: Props) {
  const onAttachmentUploaded = (files: { documentId: string; name: string }[]) => {
    const newDocumentIds = files.map(f => f.documentId);
    formik.setFieldValue('attachments', [...formik.values.attachments, ...newDocumentIds]);
  };

  const onAttachmentRemoved = (documentId: UUID): Promise<void> => {
    DocumentAPI.userFiles.deleteFile(documentId);
    return Promise.resolve(
      formik.setFieldValue(
        'attachments',
        formik.values.attachments.filter(doc => doc !== documentId),
      ),
    ).then(() => {});
  };

  usePrompt(formik.dirty, formik.isSubmitting);
  const [newAttachments, onUploadFile, removeFile] = useFileUpload(file => uploadFile('events', file), {
    onSuccess: onAttachmentUploaded,
    deleteFile: onAttachmentRemoved,
  });

  const deleteAttachments = (attachmentIdsToKeep: string[], allAttachmentIds: string[]) => {
    const attachmentsToDelete = allAttachmentIds.filter(att => !attachmentIdsToKeep.includes(att));
    attachmentsToDelete?.forEach(documentId => {
      DocumentAPI.userFiles.deleteFile(documentId);
    });
  };

  const onSubmit = () => {
    deleteAttachments(formik.values.attachments, attachments?.documents.map(d => d.id) ?? []);
    formik.handleSubmit();
  };

  return { newAttachments, onAttachmentRemoved, onUploadFile, removeFile, onSubmit };
}
