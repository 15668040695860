import classnames from 'classnames';
import React from 'react';

import styles from './styles.scss';

interface IndicatorProps {
  value: number;
  className?: string;
  color?: 'blue' | 'grey';
  shape?: 'circle' | 'square';
}

export default function Indicator(props: IndicatorProps) {
  const className = classnames(styles.indicator, props.className, {
    [styles.indicatorBlue]: props.color === 'blue',
    [styles.indicatorGrey]: props.color === 'grey',
    [styles.square]: props.shape === 'square',
  });
  return <span className={className}>{props.value}</span>;
}
