import { CompanyPendingInCommunity } from 'apis/DashboardAPI';
import ApplicationOnYourAgenda from 'domain/communities/applications/ApplicationOnYourAgenda';
import featureToggle from 'featureToggle';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import { communityUrls, companyUrls } from 'urls';
import { calculateCompletedPercentage } from 'domain/onboarding/utils';
import { Link } from 'react-router-dom';
import Loading from 'ui/elements/Loading';
import DashboardMessage from './DashboardMessage';
import Logo from 'ui/domain/Logo';
import { useCompanyOnboarding } from 'apis/OnboardingAPI';

interface Props {
  message: CompanyPendingInCommunity;
}

export default function PendingCompanyMessage({ message }: Props) {
  const { community, company, communityHasApplication } = message;
  const { checklistSteps } = useCompanyOnboarding(message.company.slug);

  const completeness = checklistSteps ? calculateCompletedPercentage(checklistSteps) : undefined;
  const hasCompletedProfile = completeness !== undefined ? completeness >= 80 : undefined;

  const isDnbCommunity = featureToggle.communityIsConnectedToDnb(community.id);

  return communityHasApplication ? (
    <ApplicationOnYourAgenda message={message}></ApplicationOnYourAgenda>
  ) : (
    <>
      <DashboardMessage
        key={message.type}
        color={{
          background: isDnbCommunity ? '#285358' : bluePlanetTheme.bluePlanetPalette.white,
          text: isDnbCommunity
            ? bluePlanetTheme.bluePlanetPalette.white
            : bluePlanetTheme.bluePlanetPalette.indigo.dark,
        }}
        topElement={<Logo size={70} type="community" url={message.community.logoURL}></Logo>}
      >
        <h4 className="text-font-text text-medium">You have been invited to {community.name}</h4>
        {hasCompletedProfile === undefined ? (
          <div className="u-flex-center u-section-spacing-y">
            <Loading size={20}></Loading>
          </div>
        ) : (
          <p>
            {message.company.name} is pending access to {community.name}.{' '}
            {!hasCompletedProfile
              ? 'Please complete your company profile to be able to showcase yourself to potential investors and other companies.'
              : 'Great job completing the company setup! Please wait for the community admin to review your company profile, you will be notified as soon as the company gets approved.'}
          </p>
        )}
        <div style={{ marginTop: 'auto' }}>
          {!hasCompletedProfile ? (
            <Link
              className={isDnbCommunity ? 'text-link-light' : 'text-link'}
              to={companyUrls.overview(company.slug, 'profile')}
            >
              Complete now
            </Link>
          ) : (
            <Link
              className={isDnbCommunity ? 'text-link-light' : 'text-link'}
              to={communityUrls.overview(community.slug)}
            >
              Go to community
            </Link>
          )}
        </div>
      </DashboardMessage>
    </>
  );
}
