import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function EllipsisIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path strokeWidth="2.5" d="M6 12h0m6 0h0m6 0h0" />
    </SvgIcon>
  );
}
