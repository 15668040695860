import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { useFormik } from 'formik';
import useNotify from 'hooks/useNotify';
import React from 'react';
import { PitchDTO } from 'types/company/company';
import Button from 'ui/elements/buttons/Button';
import CurrencyInput from 'ui/elements/form/numbers/CurrencyInput';
import Dialog, { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import map, { DEFAULT_CURRENCY } from 'util/currency';
import useLazyResource from 'util/resource/useLazyResource';
import * as Yup from 'yup';
import { useCompanyLastUpdated } from '../../LastUpdated';

interface Props {
  company: PitchDTO;
  focusableField: 'revenues' | 'valuation';
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
}

interface FormInput {
  revenuesValue?: number;
  revenuesCurrency: string;
  valuationValue?: number;
  valuationCurrency: string;
}

export default function RevenueAndValuationDialog({ company, onComplete, isOpen, onClose, focusableField }: Props) {
  const notify = useNotify();

  const { setLastUpdated } = useCompanyLastUpdated(company.id);
  const [onSave, isSaving] = useLazyResource(
    (payload: FormInput) =>
      companiesApi.update(company.id, {
        revenues: payload.revenuesValue,
        revenuesCurrency: payload.revenuesCurrency,
        valuation: payload.valuationValue,
        valuationCurrency: payload.valuationCurrency,
      }),

    {
      onSuccess: () => {
        setLastUpdated(new Date());
        onComplete();
        onClose();
      },
      onFailure: () => notify('error', `Something unexpected happened when trying to update company information.`),
    },
  );

  const formik = useFormik({
    initialValues: {
      revenuesValue: company.revenues.value,
      revenuesCurrency: company.revenues.currency ?? DEFAULT_CURRENCY,
      valuationValue: company.valuation?.value,
      valuationCurrency: company.valuation?.currency ?? DEFAULT_CURRENCY,
    },
    onSubmit: values => {
      onSave(values);
    },
    validationSchema: Yup.object().shape({
      revenuesValue: Yup.number()
        .notRequired()
        .nullable()
        .lessThan(1000000000000000, 'Revenues must be less than 1 000 000 000 000 000'),
      revenuesCurrency: Yup.string().notRequired().oneOf(Object.keys(map)),
      valuationValue: Yup.number()
        .notRequired()
        .nullable()
        .lessThan(1000000000000000, 'Valuation must be less than 1 000 000 000 000 000'),
      valuationCurrency: Yup.string().notRequired().oneOf(Object.keys(map)),
    }),
  });

  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="md">
      <form onSubmit={formik.handleSubmit}>
        <Content paddingY="top">
          <CurrencyInput
            autoFocus={focusableField === 'revenues'}
            className="u-content-spacing-bottom"
            name="revenuesValue"
            value={formik.values.revenuesValue}
            error={formik.errors.revenuesValue}
            onChange={v => formik.setFieldValue('revenuesValue', v)}
            label="Revenues (last year)"
            placeholder="1 000 000"
            currency={formik.values.revenuesCurrency}
            nullable={true}
            onCurrencyChange={v => formik.setFieldValue('revenuesCurrency', v)}
          />
          <CurrencyInput
            autoFocus={focusableField === 'valuation'}
            name="valuationValue"
            onChange={v => formik.setFieldValue('valuationValue', v)}
            value={formik.values.valuationValue}
            error={formik.errors.valuationValue}
            label="Valuation"
            placeholder="10 000 000"
            currency={formik.values.valuationCurrency}
            onCurrencyChange={v => formik.setFieldValue('valuationCurrency', v)}
            nullable={true}
            helperText="Valuation is only visible to company admins, board members and shareholders"
          />
        </Content>
        <DialogActions>
          <Button kind="primary" type="submit" isLoading={isSaving}>
            Update
          </Button>
          <Button kind="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
