import React, { useEffect, useRef } from 'react';
type Timeout = ReturnType<typeof setTimeout>;

interface Props {
  text?: React.ReactNode;
  setText: (text: React.ReactNode | undefined) => void;
  children: JSX.Element;
}

export default function TooltipTrigger({ text, setText: onShow, children }: Props) {
  const timeoutRef = useRef<Timeout | undefined>(undefined);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    onShow(text);
  };

  const handleTouchStart = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      onShow(text);
    }, 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    onShow(undefined);
  };

  return text ? (
    <span
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleMouseLeave}
    >
      {children}
    </span>
  ) : (
    children
  );
}
