import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function FolderIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M7 7.61h9.75c2.1 0 3.16 0 3.92.51.32.22.6.5.82.83.51.75.51 1.8.51 3.91 0 3.52 0 5.27-.84 6.53a5 5 0 0 1-1.38 1.38c-1.26.84-3.02.84-6.53.84H12c-4.71 0-7.07 0-8.54-1.46C2 18.69 2 16.33 2 11.61V8.56c0-1.82 0-2.73.38-3.4a3 3 0 0 1 1.16-1.17c.68-.38 1.59-.38 3.4-.38 1.17 0 1.75 0 2.26.2 1.16.43 1.64 1.49 2.17 2.54L12 7.6" />
    </SvgIcon>
  );
}
