import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import { communityAPIUrls } from './communitiesApi';
import { MyCommunity } from 'types/company/community';

export const communitiesKey = communityAPIUrls.list();

export const useCommunities = () => {
  const swrResult = useSWR<{ values: MyCommunity[] }>(communitiesKey);
  return useSWRResource(swrResult);
};
