import React from 'react';
import Chip from 'ui/elements/Chip';
import GlitterIcon from 'ui/elements/icons/GlitterIcon';

export default function AIChip({ className, onClick }: { className?: string; onClick?: () => void }) {
  return (
    <div>
      <Chip
        className={className}
        onClick={onClick}
        textTransform="unset"
        color="purple-light"
        label={
          <>
            <GlitterIcon style={{ fontSize: '0.875rem' }} fontSize="small" className="u-quarter-spacing-right" />
            This section is AI generated. Please review and edit section if needed.
          </>
        }
      />
    </div>
  );
}
