import { InlineWidget } from 'react-calendly';
import React, { useState } from 'react';
import { getOrUndefined } from 'util/resource';
import Dialog, { Title } from 'ui/views/dialogs/Dialog';
import useJourneyLogger from 'hooks/useJourneyLogger';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';

export default function Calendly({
  calendlyUrl,
  children,
}: {
  children: (onOpen: () => void) => void;
  calendlyUrl: string;
}) {
  const [log] = useJourneyLogger();
  const { resource: userProfileResource } = useSelfUserProfile();
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => {
    log(`opened calendly`);
    setIsOpen(true);
  };

  return (
    <>
      {children(onOpen)}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg">
        <Title onClose={() => setIsOpen(false)} style={{ paddingBottom: 0 }}></Title>
        <InlineWidget
          styles={{ height: '860px' }}
          key="calendly"
          url={`https://calendly.com/${calendlyUrl}`}
          prefill={{
            name: getOrUndefined(userProfileResource)?.name,
            email: getOrUndefined(userProfileResource)?.email,
          }}
        />
      </Dialog>
    </>
  );
}
