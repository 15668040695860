import React, { useState } from 'react';
import { getOrUndefined, map } from 'util/resource';
import { getUserProfiles } from 'actions/userProfiles';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addItem } from 'util/paginationUtils';
import MessageField from 'domain/conversations/Message/MessageField';
import LoadableResource from 'util/resource/Resource';
import CompanyLogo from 'ui/elements/CompanyLogo';
import usePaginatedResourceLegacy from 'util/resource/usePaginatedResourceLegacy';
import { ICompany } from 'types/company';
import { useResourceSelector } from 'util/resource/useResourceSelector';
import Messages from './Message/Messages';
import conversationApi, { Message, PostConversationMessage } from 'apis/ContentAPI/conversationApi';
import { fetchConversationAsUser } from './actions';
import { RootStore } from 'globalstate/rootStore';
import { conversationActions as conversationListActions } from 'globalstate/user/conversationList';
import { markConversationAsRead } from 'globalstate/user/conversationList';
import ConversationHeader from './Message/ConversationHeader';
import cx from 'classnames';
import useRoleTrackingKeys from 'hooks/useRoleTrackingKeys';
import ConversationSettingsButton from './Message/ConversationSettingsButton';
import sortBy from 'ramda/src/sortBy';
import reverse from 'ramda/src/reverse';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { reloadCompanyInbox } from 'pages/Inbox/useCompanyInbox';
import useCompanyRole from 'hooks/useCompanyRole';
import InfoIcon from 'ui/elements/icons/InfoIcon';
import { getAdminRights } from 'auth/util';
import { Auth0User } from 'types';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  company: ICompany;
  initialMessage?: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function CompanyConversationDialogAsUser({ company, initialMessage, isOpen, onClose }: Props) {
  const { user: auth0User }: { user?: Auth0User } = useAuth0();
  const dispatch = useDispatch();
  const roleTrackingKeys = useRoleTrackingKeys(company.slug);
  const boundActions = bindActionCreators(
    {
      getUserProfiles,
      markConversationAsRead,
    },
    dispatch,
  );
  const { resource: userProfileResource } = useSelfUserProfile();
  const [conversations] = useResourceSelector(state => state.user.conversations);
  const conversation = getOrUndefined(conversations)?.values.find(
    c => c.type === 'user-company' && c.companyConversation.companyId === company.id,
  );

  const [messagesResource, setMessages, , seeMore] = usePaginatedResourceLegacy(
    paginationOptions =>
      fetchConversationAsUser(boundActions.getUserProfiles, company.id, paginationOptions || { limit: 10 }),
    [company.id],
    result => {
      if (result.page === 1) {
        conversation && boundActions.markConversationAsRead(conversation);
        reloadCompanyInbox(company.id);
      }
    },
  );
  const [showSidebar, setShowSidebar] = useState(false);

  const title = <span className="u-wrap-text">{company.name}</span>;
  const logo = <CompanyLogo company={company} size={40} />;
  const onReply = async (message: PostConversationMessage) => {
    const result = await conversationApi.user.single.reply(company.id, message);

    dispatch(
      conversationListActions.updated(company.id, prev => ({
        ...prev,
        latestReply: result,
      })),
    );
    return result;
  };

  const userProfiles = useSelector((state: RootStore) => state.userProfiles.items);
  const withProfile = getOrUndefined(userProfileResource);

  const { canEditCompany } = useCompanyRole(company.slug);

  const canContactCompany = !canEditCompany || getAdminRights(auth0User).includes('backoffice');

  const messages = map(messagesResource, r => ({
    ...r,
    values: sortBy(
      a => new Date(a.createdAt),
      reverse(r.values).map(c => ({
        ...c,
        align: withProfile?.cwUserId === c.creatorCwUserId ? ('right' as const) : ('left' as const),
      })),
    ),
  }));

  const addMessage = (message: Message) => setMessages(prevState => addItem(prevState, message));

  return (
    <LoadableResource resource={userProfileResource}>
      {userProfile => (
        <Messages
          addMessage={addMessage}
          user={userProfile}
          userProfiles={userProfiles}
          onClose={onClose}
          showSidebar={showSidebar}
          setShowSidebar={(v: boolean) => setShowSidebar(v)}
          header={
            <ConversationHeader
              title={title}
              logo={logo}
              suffix={
                <ConversationSettingsButton
                  onClick={() => setShowSidebar(!showSidebar)}
                  text={showSidebar ? 'Close Settings' : 'Show Settings'}
                /> //header
              }
            />
          }
          conversation={messages}
          seeMore={seeMore}
        >
          {canContactCompany ? (
            <MessageField
              initialMessage={initialMessage}
              placeholder="Write a message"
              handleSubmit={onReply}
              onSuccess={addMessage}
              submitClassName={cx(
                'data-track-conversation-send-message',
                'data-track-conversation-send-message-as-company-user',
                roleTrackingKeys,
              )}
              padding="none"
              autoFocus
            />
          ) : (
            <p className="u-align-center u-content-padding">
              <InfoIcon className="u-half-spacing-right"></InfoIcon>You&apos;re attempting to message a company you are
              an admin of.
            </p>
          )}
        </Messages>
      )}
    </LoadableResource>
  );
}
