import React from 'react';
import { UserProfile } from 'types/user';

export function userRole(user: UserProfile) {
  return user.position ? (
    <>
      {user.position}
      {user.employer ? (
        <>
          {' '}
          @ <span>{user.employer}</span>
        </>
      ) : null}
    </>
  ) : null;
}
