import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { useCompanyProfile } from 'apis/CompanyAPI/companies/useCompanyProfile';
import { usePitch } from 'apis/CompanyAPI/companies/usePitch';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import useNotify from 'hooks/useNotify';
import {
  CloseFundingRoundDTO,
  FullFundingRound,
  NewFundingRoundDTO,
  UpdateArchivedFundingRoundDTO,
  UpdateFundingRoundDTO,
} from 'types/company';
import { DEFAULT_CURRENCY } from 'util/currency';
import useLazyResource from 'util/resource/useLazyResource';

export function useNewFundingRound(
  companyId: number,
  companySlug: string,
  { onSuccess, onFailure }: { onSuccess?: () => void; onFailure?: () => void },
) {
  const notify = useNotify();
  const { setLastUpdated } = useCompanyLastUpdated(companyId);

  const { mutate: refreshCompany } = useCompanyProfile(companySlug);
  const { mutate: refreshPitch } = usePitch(companyId);

  return useLazyResource(
    (fundingRound: NewFundingRoundDTO) =>
      companiesApi.fundingRound.create(companyId, fundingRound).then(() => {
        if (fundingRound.fundingStage) {
          companiesApi.fundingStage.update(companyId, fundingRound.fundingStage);
        }
      }),
    {
      onSuccess: () => {
        notify('success', 'Funding round started');
        setLastUpdated(new Date());
        refreshCompany();
        refreshPitch();
        onSuccess && onSuccess();
      },
      onFailure: () => {
        () => notify('error', 'Failed to start funding round');
        onFailure && onFailure();
      },
    },
  );
}

export function useUpdateFundingRound(
  companyId: number,
  companySlug: string,
  {
    onSuccess,
    onFailure,
  }: { onSuccess?: (result?: FullFundingRound) => void; onFailure?: (result?: FullFundingRound) => void },
) {
  const notify = useNotify();

  const { mutate: refreshCompany } = useCompanyProfile(companySlug);
  const { mutate: refreshPitch } = usePitch(companyId);

  return useLazyResource(
    (fundingRound: UpdateFundingRoundDTO) =>
      companiesApi.fundingRound.update(companyId, fundingRound).then(fundingRoundResponse => {
        if (fundingRound.fundingStage) {
          companiesApi.fundingStage.update(companyId, fundingRound.fundingStage);
        }
        return fundingRoundResponse;
      }),
    {
      onSuccess: fundingRoundResponse => {
        notify('success', 'Funding round updated');
        refreshCompany();
        refreshPitch();
        onSuccess && onSuccess(fundingRoundResponse);
      },
      onFailure: () => {
        () => notify('error', 'Failed to update funding round');
        onFailure && onFailure();
      },
    },
  );
}

export function useUpdateArchivedRound(
  companyId: number,
  fundingRoundId: UUID,
  {
    onSuccess,
    onFailure,
  }: { onSuccess?: (result?: FullFundingRound) => void; onFailure?: (result?: FullFundingRound) => void },
) {
  const notify = useNotify();

  return useLazyResource(
    ({
      fundingRound,
      shouldUpdateValuation,
    }: {
      fundingRound: UpdateArchivedFundingRoundDTO;
      shouldUpdateValuation: boolean;
    }) =>
      companiesApi.fundingRound.updateArchivedRound(companyId, fundingRoundId, fundingRound).then(() => {
        if (shouldUpdateValuation && fundingRound.postMoneyValuation) {
          companiesApi.update(companyId, {
            valuation: fundingRound.postMoneyValuation,
            valuationCurrency: fundingRound.postMoneyValuationCurrency || DEFAULT_CURRENCY,
          });
        }
      }),
    {
      onSuccess: () => {
        notify('success', 'Funding round updated');
        onSuccess && onSuccess();
      },
      onFailure: () => {
        () => notify('error', 'Failed to update funding round');
        onFailure && onFailure();
      },
    },
  );
}

export function useCloseFundingRound(
  companyId: number,
  companySlug: string,
  { onSuccess, onFailure }: { onSuccess?: () => void; onFailure?: () => void },
) {
  const notify = useNotify();
  const { setLastUpdated } = useCompanyLastUpdated(companyId);

  const { mutate: refreshCompany } = useCompanyProfile(companySlug);
  const { mutate: refreshPitch } = usePitch(companyId);

  return useLazyResource(
    ({
      fundingRound,
      shouldUpdateValuation,
    }: {
      fundingRound: CloseFundingRoundDTO;
      shouldUpdateValuation?: boolean;
    }) =>
      companiesApi.fundingRound.close(companyId, fundingRound).then(() => {
        if (fundingRound.fundingStage) {
          companiesApi.fundingStage.update(companyId, fundingRound.fundingStage);
        }
        if (shouldUpdateValuation && fundingRound.postMoneyValuation) {
          companiesApi.update(companyId, {
            valuation: fundingRound.postMoneyValuation,
            valuationCurrency: fundingRound.postMoneyValuationCurrency || DEFAULT_CURRENCY,
          });
        }
      }),
    {
      onSuccess: () => {
        notify('success', 'Funding round closed');
        setLastUpdated(new Date());
        refreshCompany();
        refreshPitch();
        onSuccess && onSuccess();
      },
      onFailure: () => {
        notify('error', 'Failed to close funding round');
        onFailure && onFailure();
      },
    },
  );
}
