import useSWR from 'hooks/useSWR';
import { AllStages } from 'types';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from '../companies/companiesApi';

export const stagesKey = companiesAPIUrls.stages.listAll();

export const useStages = () => {
  const swrResult = useSWR<AllStages>(stagesKey);
  return useSWRResource(swrResult);
};
