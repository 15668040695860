import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CompanyPage, { Main } from 'ui/views/layouts/CompanyPage';

import SectionHeading from 'ui/elements/SectionHeading';
import ProfileVisits from './ProfileVisits';
import LoadableResources from 'util/resource/Resources';
import useResource from 'util/resource/useResource';

import DateRangePresetsPicker, { getInitialDateRangeFromSearchParams } from 'ui/elements/DateRangePresetsPicker';
import { STATS_DATE_RANGES, STATS_MAX_DAYS_RANGE } from 'domain/Stats/StatsDateRanges';
import FeedbackCard from 'pages/Community/CommunityManagement/companies/FeedbackCard';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import { companyUrls, userUrls } from 'urls';
import AddAction from 'ui/elements/actions/AddAction';
import { CompanyReachDTO, ICompany, RangeDTO } from 'types/company';
import CommunityGroupsFeedbackDialog from './CommunityGroupsFeedbackDialog';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import EngagementStats from './EngagementCard';
import { contentSpacing } from 'ui/theme/themeConstants';
import {
  ProfileEngagement,
  ProfileEngagementLastFiveUsers,
  ProfileInsights,
  ProfileInsightsBreakdown,
  PitchDeckStats,
} from 'apis/CompanyAPI/insights/types';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import PitchDeckViews from './PitchDeckViews';
import PitchDeckInsights from './PitchDeckInsights';
import LinkButton from 'ui/elements/buttons/LinkButton';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';

const StatsHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: ${bluePlanetTheme.spacing(2)};
  align-items: flex-start;
  margin-bottom: ${contentSpacing};

  ${bluePlanetTheme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
  ${bluePlanetTheme.breakpoints.down('sm')} {
    margin-left: ${contentSpacing};
    margin-right: ${contentSpacing};
  }
`;

export const getReachText = (reach: RangeDTO) => {
  return reach.upperLimit ? `${reach.lowerLimit}-${reach.upperLimit}` : `at least ${reach.lowerLimit}`;
};

const ActionLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${bluePlanetTheme.spacing(2)};
  margin-top: auto;

  ${bluePlanetTheme.breakpoints.down('md')} {
    grid-template-columns: 2fr 2fr;
  }
`;

const EngagementGrid = styled.div`
  display: grid;
  gap: ${contentSpacing};
  grid-template-columns: 1fr 1fr 1fr 1fr;
  ${bluePlanetTheme.breakpoints.down('lg')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${bluePlanetTheme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
  }
`;

export default function Insights({ company }: { company: ICompany }) {
  const location = useLocation();

  const [userDialogId, setUserDialogId] = useState<UUID>();

  const [range, setRange] = useState(getInitialDateRangeFromSearchParams(location.search, STATS_MAX_DAYS_RANGE));

  const [isPitchDeckViewsDialogOpen, setPitchDeckViewsDialogOpen] = useState(false);

  const { resource: reachResource } = useResource<CompanyReachDTO>(companiesAPIUrls.insights.reach(company.id));

  const { resource: profileVisits } = useResource<ProfileInsights>(
    companiesAPIUrls.insights.profileVisits(company.id, range.startDate, range.endDate),
    { keepPreviousData: true },
  );

  const { resource: profileVisitsBreakdown } = useResource<ProfileInsightsBreakdown>(
    companiesAPIUrls.insights.profileVisitsBreakdown(company.id, range.startDate, range.endDate),
    { keepPreviousData: true },
  );

  const { resource: profileEngagement } = useResource<ProfileEngagement>(
    companiesAPIUrls.insights.profileEngagement(company.id, range.startDate, range.endDate),
    { keepPreviousData: true },
  );

  const { resource: profileEngagementLastFiveUsers } = useResource<ProfileEngagementLastFiveUsers>(
    companiesAPIUrls.insights.profileEngagementLastFiveUsers(company.id, range.startDate, range.endDate),
    { keepPreviousData: true },
  );

  const { resource: pitchDeckViews } = useResource<PitchDeckStats>(
    companiesAPIUrls.insights.pitchDeckViews(company.id, range.startDate, range.endDate),
    { keepPreviousData: true },
  );

  const [isFeedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  return (
    <CompanyPage company={company} title="Insights" showCompanyNameBelowHeaderOnMobile>
      <Main>
        <LoadableResources
          resources={[
            profileVisits,
            profileEngagement,
            profileVisitsBreakdown,
            profileEngagementLastFiveUsers,
            pitchDeckViews,
            reachResource,
          ]}
        >
          {([
            profileVisits,
            profileEngagement,
            profileVisitsBreakdown,
            profileEngagementLastFiveUsers,
            pitchDeckViews,
            reach,
          ]) => {
            return (
              <>
                <StatsHeader>
                  <div>
                    <h4 className="text-h2 u-half-spacing-bottom">Insights</h4>
                    <p className="text-medium text-weight-small u-content-spacing-bottom">
                      Here is what&apos;s happening with your profile recently.{' '}
                      {reach.potentialReach ? (
                        <>
                          You have a potential reach of{' '}
                          <span className="text-weight-medium">{getReachText(reach.potentialReach)} people,</span> and{' '}
                          <span className="text-weight-medium">
                            {reach.connectionCount} are people connected to your company.
                          </span>
                        </>
                      ) : (
                        <>
                          Your potential reach is{' '}
                          <span className="text-weight-medium">
                            {reach.connectionCount} people, and they are following your company.
                          </span>
                        </>
                      )}
                      <br />
                      <br />
                      To gain even more traction, build your investor network through updates or join a community to
                      gain exposure.
                    </p>
                    <ActionLinks className="u-half-spacing-bottom">
                      <Link to={companyUrls.settings.inviteUser(company.slug)}>
                        <AddAction>Invite your network</AddAction>
                      </Link>
                      <Link to={companyUrls.kpi.manage(company.slug)}>
                        <AddAction>Update your KPIs</AddAction>
                      </Link>
                      <Link to={companyUrls.overview(company.slug, 'updates', { isPostingUpdate: true })}>
                        <AddAction>Post update</AddAction>
                      </Link>
                      <Link to={userUrls.discover}>
                        <AddAction>Join investor community</AddAction>
                      </Link>
                      <CommunityGroupsFeedbackDialog
                        isOpen={isFeedbackDialogOpen}
                        onClose={() => setFeedbackDialogOpen(false)}
                      />
                    </ActionLinks>
                  </div>
                  <FeedbackCard
                    feedbackContext="CompanyStatistics"
                    message="We are working on powerful features for you to leverage your company pitch to get in touch with relevant investors. Please get in touch to tell about your experience or if you have questions."
                  />
                  <div>
                    <DateRangePresetsPicker
                      presets={STATS_DATE_RANGES}
                      maxDaysRange={STATS_MAX_DAYS_RANGE}
                      initialRange={range}
                      onChange={newRange => {
                        setRange(newRange);
                      }}
                    />
                  </div>
                </StatsHeader>
                <SectionHeading heading="Profile visits" addMobileMargin />
                <ProfileVisits profileVisits={profileVisits} profileVisitsBreakdown={profileVisitsBreakdown} />
                <div className="u-flex u-flex--align-center u-flex--wrap u-section-spacing-top u-half-spacing-bottom">
                  <SectionHeading
                    margin="none"
                    heading="Pitch deck views"
                    className="text-nowrap u-content-spacing-right"
                    addMobileMargin
                  />
                  <div className="u-flex u-flex--align-items-center u-flex--wrap">
                    <span className="u-content-spacing-x u-quarter-spacing-y">
                      Showing aggregated data from the latest uploaded pitch deck
                    </span>
                    <LinkButton
                      onClick={() => {
                        setPitchDeckViewsDialogOpen(true);
                      }}
                      className="u-content-spacing-x"
                    >
                      See all pitch deck insights &gt;
                    </LinkButton>
                  </div>
                </div>
                <PitchDeckViews pitchDeckViews={pitchDeckViews} />
                <SectionHeading heading="Engagement" className="u-section-spacing-top" addMobileMargin />
                <EngagementGrid>
                  <EngagementStats
                    value={profileEngagement.collectionListings.value}
                    users={profileEngagementLastFiveUsers.collectionListings}
                    title="Collection listings"
                    tooltip="Unique users who have added your company to one of their collections in this period"
                    openUserDialog={setUserDialogId}
                  />
                  <EngagementStats
                    value={profileEngagement.newFollowers.value}
                    users={profileEngagementLastFiveUsers.newFollowers}
                    title="Follows"
                    tooltip="The number of people who started following your company in this period"
                    openUserDialog={setUserDialogId}
                  />
                  <EngagementStats
                    value={profileEngagement.pitchDownloads.value}
                    users={profileEngagementLastFiveUsers.pitchDownloads}
                    title="Pitch downloads"
                    tooltip="The number of people who downloaded your pitch deck in this period"
                    openUserDialog={setUserDialogId}
                  />
                  <EngagementStats
                    value={profileEngagement.postEngagement.value}
                    users={profileEngagementLastFiveUsers.postEngagement}
                    title="Post engagement"
                    tooltip="The number of people who liked or commented on your posts in this period"
                    openUserDialog={setUserDialogId}
                  />
                </EngagementGrid>

                <CenteredModalDialog
                  open={isPitchDeckViewsDialogOpen}
                  onClose={() => setPitchDeckViewsDialogOpen(false)}
                  overflowY="scroll" // always show scrollbar to prevent window from jumping
                  width="wide"
                >
                  <PitchDeckInsights company={company} dateRange={range} />
                </CenteredModalDialog>
              </>
            );
          }}
        </LoadableResources>
      </Main>
      {userDialogId && <UserProfileDialog cwUserId={userDialogId} onClose={() => setUserDialogId(undefined)} />}
    </CompanyPage>
  );
}
