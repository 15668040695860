export function resize(
  url: string | undefined,
  {
    width,
    height,
    quality,
    fit,
    backgroundColor,
  }: {
    width?: number | 'auto';
    height?: number;
    quality?: number;
    fit?: 'fit' | 'fill';
    backgroundColor?: 'transparent' | 'white';
  },
) {
  const h = height ? `h_${height},` : '';
  // w_auto only works on chromium based browsers. If we don't send with, it will return the original image.
  // :300  tells Cloudinary how big the “jumps” between alternate resources should be.
  // 4000 means that max width is 4000px, which is a fallback for when w_auto doesn't work.
  const w = typeof width === 'number' ? `w_${width},` : 'w_auto:300:4000/';
  const q = `q_${quality || '95'},`;

  if (url && url.includes('cloudinary')) {
    return url
      .split('upload/')
      .join(`upload/f_auto/${w + h + q}c_${fit ?? 'fit'},b_${backgroundColor ?? 'transparent'}/`);
  }
  return url || '';
}
