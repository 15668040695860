import React, { ReactNode, useState } from 'react';
import ImageCard from 'ui/views/cards/ImageCard';
import { bluePlanetTheme } from 'ui/theme';
import { DashboardMessage } from 'apis/DashboardAPI';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import { communityUrls, companyUrls, userUrls } from 'urls';
import Dialog, { Title, Content, DialogActions } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import ClickableCard from 'ui/views/cards/ClickableCard';
import Logo from 'ui/domain/Logo';
import CardStack from 'ui/views/cards/CardStack';

interface Props {
  message: DashboardMessage;
}

function MentorCard({ children }: { children: ReactNode }) {
  return (
    <ImageCard
      backgroundColor={bluePlanetTheme.bluePlanetPalette.blue.main}
      color={bluePlanetTheme.bluePlanetPalette.white}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>{children}</div>
    </ImageCard>
  );
}

export default function MentorCtaCard({ message }: Props) {
  const [showMentorships, setShowMentorships] = useState(false);

  switch (message.type) {
    case 'mentor-with-no-mentees':
      return (
        <MentorCard>
          <p className="u-content-spacing-bottom">
            Feel free to explore and follow the companies you like. Make sure that you have given thorough information
            about your experience and skills. This will help the program manager connect you with a suitable company.
          </p>
          <div style={{ marginTop: 'auto' }}>
            <LinkAsButton url={userUrls.profile.edit} kind="primary" color="white">
              Update profile
            </LinkAsButton>
          </div>
        </MentorCard>
      );
    case 'mentor-with-one-mentee':
      return (
        <MentorCard>
          <p className="u-content-spacing-bottom">You are currently mentoring {message.company.name}</p>
          <div style={{ marginTop: 'auto' }}>
            <LinkAsButton url={companyUrls.overview(message.company.slug, 'profile')} kind="primary" color="white">
              Show mentorship
            </LinkAsButton>
          </div>
        </MentorCard>
      );
    case 'mentor-with-several-mentees':
      return (
        <MentorCard>
          <p>You are currently mentoring {message.count} companies. </p>
          <div style={{ marginTop: 'auto' }}>
            <Button
              className="u-content-spacing-top"
              kind="primary"
              color="white"
              onClick={() => setShowMentorships(true)}
            >
              View mentorships
            </Button>
            {message.communitySlug && message.companies.length === 0 && (
              <LinkAsButton
                className="u-content-spacing-top u-half-spacing-left"
                url={communityUrls.overview(message.communitySlug)}
                kind="secondary"
                color="white"
              >
                Go to community overview
              </LinkAsButton>
            )}
          </div>

          <Dialog open={showMentorships} onClose={() => setShowMentorships(false)}>
            <Title onClose={() => setShowMentorships(false)}>Mentorships</Title>
            <Content>
              <CardStack>
                {message.companies.map(company => (
                  <ClickableCard
                    title={company.name}
                    href={companyUrls.overview(company.slug, 'profile')}
                    key={`company=${company.id}`}
                    avatar={<Logo size={40} url={company.logoURL} type="company" />}
                  />
                ))}
              </CardStack>
            </Content>
            <DialogActions>
              <Button kind="primary">Close</Button>
            </DialogActions>
          </Dialog>
        </MentorCard>
      );
    default:
      return null;
  }
}
