import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function ListIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M9.08 5H19.9" />
      <path strokeWidth="2" d="M4.3 5h0M4.3 12h0M4.3 19h0" />
      <path d="M9.08 12H19.9M9.08 19H19.9" />
    </SvgIcon>
  );
}
