import React, { useState, useRef } from 'react';
import { CompanyProfile, UpdateCompanyDTO } from 'types/company/company';
import Accordion from 'ui/modules/Accordion';
import { toggleItemInList } from 'util/arrayUtils';
import ScrollNavigation from 'ui/modules/ScrollNavigation';
import uniq from 'ramda/src/uniq';
import { companyProfileByIdKey, companyProfileKey } from 'apis/CompanyAPI/companies/useCompanyProfile';
import InfoCard from 'ui/views/cards/InfoCard';
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import config from 'config';
import Box from 'ui/views/containers/Box';
import Switch from 'ui/elements/form/choice/Switch';
import useNotify from 'hooks/useNotify';
import DownloadIcon from 'ui/elements/icons/DownloadIcon';
import { EmptyCompanyCard } from 'domain/companies/CompanyCard/EmptyCompanyCard';
import Button from 'ui/elements/buttons/Button';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { invalidate } from 'hooks/useSWR';
import useLazyResource from 'util/resource/useLazyResource';
import { useTheme } from '@mui/system';
import useDialogHandler from 'hooks/useDialogHandler';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import PublicProfile from 'pages/Company/Public/PublicProfile';
import LinkButton from 'ui/elements/buttons/LinkButton';
import TextField from 'ui/elements/form/TextField';
import CopyButton from 'ui/elements/CopyButton';
import Logo from 'ui/domain/Logo';
import { css, Global } from '@emotion/react';
import { createPortal } from 'react-dom';
import { bluePlanetTheme } from 'ui/theme';
import Label from 'ui/elements/form/Label';
import { spacingUnits } from 'ui/theme/themeBluePlanet';
import { EMBED_IFRAME_WIDTH, getIframeCode } from './utils';

interface Props {
  company: CompanyProfile;
}

export type CompanySharingSection = 'qr-code' | 'profile-widget' | 'copy-url' | 'qr-badge' | undefined;

export const getPublicCompanyUrl = (slug: string) => {
  if (!location) {
    return '';
  }
  return `${location.origin}/companies/${slug}`;
};

export default function CompanySharing({ company }: Props) {
  const notify = useNotify();

  const [expandedSections, setExpandedSections] = useState<CompanySharingSection[]>([
    'qr-code',
    'profile-widget',
    'copy-url',
    'qr-badge',
  ]);
  const qrCanvasRef = useRef<HTMLDivElement>(null);

  const downloadQrCode = (companyName: string) => {
    if (!qrCanvasRef.current) {
      return;
    }

    const canvas = qrCanvasRef.current.children[0] as HTMLCanvasElement;
    if (!canvas) {
      return;
    }

    const pngFile = canvas.toDataURL('image/png');

    const downloadLink = document.createElement('a');
    downloadLink.download = `${companyName} - Qr Code`;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  const [updateCompany, isUpdatingCompany] = useLazyResource(
    (payload: UpdateCompanyDTO) => companiesApi.update(company.id, payload),
    {
      onSuccess: () => {
        const updatedProfile = { ...company, hasPublicPitch: !company.hasPublicPitch };

        notify('success', 'Company profile visibility updated');
        invalidate(companyProfileKey(company.slug), updatedProfile);
        invalidate(companyProfileByIdKey(company.id), updatedProfile);
      },
      onFailure: () => {
        notify('error', 'Failed to make company profile public');
      },
    },
  );

  const togglePublicPitch = async (value: boolean) => {
    await updateCompany({ hasPublicPitch: value });
  };

  const theme = useTheme();
  const { isOpen, open: openDialog, close } = useDialogHandler(false);

  // Don't instantly remove toggle, but hide it next visit of enabled
  const [showProfileVisibilityToggle] = useState(!company.hasPublicPitch);

  return (
    <>
      <Global
        styles={css`
          .printable {
            display: none;
          }
          @media print {
            @page {
              size: auto;
              margin: 0mm;
            }
            body {
              background-color: #fff !important;
            }
            #app {
              display: none;
            }
            .printable {
              display: revert;
            }
          }
        `}
      />
      {showProfileVisibilityToggle && (
        <InfoCard color="blue">
          <Box sx={{ [theme.breakpoints.down('md')]: { flexDirection: 'column' } }} className="u-flex-align-center">
            {/* Integers between [0,1] material ui converts it to a percentage */}
            <Box
              sx={{
                width: { xs: 1, md: 2 / 3 },
                [theme.breakpoints.down('sm')]: { marginTop: 2 },
              }}
            >
              <p>
                Using these sharing tools requires that your profile is public. Your public profile shows limited
                company information and lets visitors sign up to your company as followers or contact you for investment
                information.
              </p>
              <p className="u-half-spacing-top">
                See an{' '}
                <LinkButton onClick={openDialog} style={{ display: 'inline-flex' }}>
                  example profile
                </LinkButton>{' '}
                or read more about public profiles{' '}
                <a
                  href="https://help.crowdworks.it/en/articles/6337303-what-information-is-visible-on-a-public-profile-for-visitors"
                  target="_blank"
                  rel="noreferrer"
                  className="text-link"
                >
                  here
                </a>
                .
              </p>
            </Box>
            <Box className="u-margin-left-auto" sx={{ [theme.breakpoints.down('md')]: { marginTop: 2 } }}>
              <Switch
                label="Enable public profile"
                onChange={() => {
                  togglePublicPitch(!company.hasPublicPitch);
                }}
                value={company.hasPublicPitch}
                disabled={isUpdatingCompany}
                className="u-margin-right-0"
              />
            </Box>
          </Box>
        </InfoCard>
      )}

      <div className="u-content-spacing-top">
        <ScrollNavigation<CompanySharingSection>
          onMenuItemClick={(id: CompanySharingSection) => setExpandedSections(uniq([...expandedSections, id]))}
          sections={[
            {
              key: 'qr-code',
              name: 'Company profile QR Code',
              element: (
                <Accordion
                  title="Company profile QR Code"
                  isExpanded={expandedSections.includes('qr-code')}
                  showPill
                  border="none"
                  onChange={() => setExpandedSections(toggleItemInList(expandedSections, 'qr-code'))}
                >
                  <p className="text-metadata">
                    This is your QR code - you can share this anywhere you like. If it is scanned the visitor will be
                    taken to your public profile and can from there sign up to be a follower of your company or connect
                    with you through messages.
                  </p>
                  <Box
                    sx={{
                      display: 'flex',
                      [theme.breakpoints.down('md')]: { flexDirection: 'column-reverse' },
                      marginTop: 2,
                    }}
                  >
                    {/* The sx prop borderRadius multiplies with
                          theme.shape.borderRadius, which is 4px.
                          Padding and margin: multiplies with theme.spacing which is 10px */}
                    <Box
                      sx={{
                        borderRadius: 3,
                        backgroundColor: theme => theme.bluePlanetPalette.grey.light,
                        padding: { md: 6 },
                        [theme.breakpoints.down('md')]: { padding: 2 },
                        maxWidth: 420,
                      }}
                      ref={qrCanvasRef}
                    >
                      <QRCodeCanvas
                        size={300}
                        value={`${config.PORTAL_URL}/companies/${company.slug}`}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginLeft: { md: 2 },
                        [theme.breakpoints.down('md')]: { marginBottom: 2, marginLeft: 0 },
                      }}
                    >
                      <h5 className="text-subheading-2">Download options</h5>
                      <div className="u-flex u-flex--column">
                        <button
                          onClick={() => {
                            downloadQrCode(company.name);
                          }}
                          className="u-content-spacing-top text-body text-weight-medium u-color-grey u-align-left"
                        >
                          <DownloadIcon color="grey" className="u-content-spacing-right" />
                          Download as .PNG
                        </button>
                      </div>
                    </Box>
                  </Box>
                </Accordion>
              ),
            },
            {
              key: 'qr-badge',
              name: 'QR Badge',
              element: (
                <Accordion
                  title="QR Badge"
                  isExpanded={expandedSections.includes('qr-badge')}
                  showPill
                  border="none"
                  onChange={() => setExpandedSections(toggleItemInList(expandedSections, 'qr-badge'))}
                >
                  <Box sx={{ width: { sm: 3 / 4 } }}>
                    <p className="text-metadata">
                      Print this badge and wear it at conferences and meetups - people you meet can easily connect with
                      you by scanning the code on the badge to open your profile.
                    </p>
                  </Box>
                  <Box
                    sx={{
                      marginTop: 2,
                      display: 'flex',
                      [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
                      flexDirection: { sm: 'row' },
                    }}
                  >
                    <Box
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          marginBottom: 2,
                          marginRight: 0,
                        },
                        paddingY: { xs: 2, sm: 3 },
                        paddingX: { xs: 3, sm: 8 },
                        marginRight: { sm: 2 },
                        marginBottom: { sm: 0 },
                        backgroundColor: theme => theme.bluePlanetPalette.grey.light,
                        borderRadius: 2,
                        width: 1,
                        maxWidth: 420,
                      }}
                    >
                      <QrPrintBadgePreview company={company} />
                    </Box>
                    <div>
                      <Button onClick={() => window.print()} kind="secondary" color="grey">
                        Print QR badge
                      </Button>
                    </div>
                    {createPortal(
                      <div className="printable">
                        <div
                          style={{
                            display: 'table',
                            height: '100%',
                            padding: '40mm',
                            width: '100%',
                          }}
                        >
                          <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                            <div style={{ margin: 'auto' }}>
                              <div className="u-flex-center" style={{ marginBottom: -50 }}>
                                <Logo url={company.logoURL} size={90} type="company" />
                              </div>
                              <Box
                                sx={{
                                  borderRadius: 2,
                                  boxShadow: 1,
                                  backgroundColor: theme => theme.bluePlanetPalette.white,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                  paddingTop: 5,
                                  paddingBottom: 3,
                                  paddingX: 3,
                                }}
                              >
                                <h2
                                  style={{ fontSize: '32pt' }}
                                  className="text-x-large text-weight-bold text-font-text u-half-spacing-top"
                                >
                                  {company.name}
                                </h2>
                                <div className="u-content-spacing-top" style={{ width: '80%' }}>
                                  <QRCodeCanvas
                                    value={getPublicCompanyUrl(company.slug)}
                                    size={1000}
                                    style={{ maxWidth: '100%', height: 'auto' }}
                                  />
                                </div>
                                <p
                                  style={{ fontSize: '20pt' }}
                                  className="text-body text-weight-medium u-content-spacing-top"
                                >
                                  Scan to connect with us
                                </p>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>,
                      document.body,
                    )}
                  </Box>
                </Accordion>
              ),
            },
            {
              key: 'copy-url',
              name: 'Copy URL or Embed profile as investor page',
              element: (
                <Accordion
                  title="Copy URL or Embed profile as investor page"
                  isExpanded={expandedSections.includes('copy-url')}
                  showPill
                  border="none"
                  onChange={() => setExpandedSections(toggleItemInList(expandedSections, 'copy-url'))}
                >
                  <Box sx={{ width: { sm: 3 / 4 } }}>
                    <p className="text-metadata">
                      This is your company profile link. It is public and you can share it with anyone you like,
                      investors, leads and business partners. Tip: ask your CTO to redirect e.g.{' '}
                      <strong className="text-weight-bold">invest.yourcompany.com</strong> to this link for quick
                      access.
                    </p>
                  </Box>
                  <Box
                    sx={{
                      marginTop: 2,
                      display: 'flex',
                      flexDirection: { sm: 'row' },
                      alignItems: 'center',
                      [theme.breakpoints.down('sm')]: { flexDirection: 'column', alignItems: 'start' },
                    }}
                  >
                    <Box sx={{ width: 1, maxWidth: 420, marginRight: 2 }}>
                      <TextField
                        inputProps={{
                          readOnly: true,
                          backgroundColor: bluePlanetTheme.bluePlanetPalette.grey.light,
                        }}
                        value={getPublicCompanyUrl(company.slug)}
                      />
                    </Box>
                    <Box sx={{ marginTop: { sm: 0 }, [theme.breakpoints.down('sm')]: { marginTop: 1 } }}>
                      <CopyButton
                        copyString={getPublicCompanyUrl(company.slug)}
                        displayString="Copy"
                        color="grey"
                        kind="secondary"
                      />
                    </Box>
                  </Box>
                </Accordion>
              ),
            },
            {
              key: 'profile-widget',
              name: 'Profile widget',
              element: (
                <Accordion
                  title="Profile widget"
                  isExpanded={expandedSections.includes('profile-widget')}
                  showPill
                  border="none"
                  onChange={() => setExpandedSections(toggleItemInList(expandedSections, 'profile-widget'))}
                >
                  <Box sx={{ width: { sm: 3 / 4 } }}>
                    <p className="text-metadata">
                      Use the profile widget to encourage your community to follow you on CrowdWorks. They{"'"}re easy
                      to embed on your homepage or footer, and subscribers can keep track of your progress and jump
                      straight to your profile on CrowdWorks.
                    </p>
                  </Box>
                  {/* This needs to stay in sync with the embed styles and markup in server/embed.html,
                  server/detailed-embed.html and server/empty-embed.html */}
                  <div className="u-content-spacing-top">
                    <h5 className="text-subheading u-half-spacing-bottom">Minimal widget</h5>
                    <EmbedProfilePreview companyProfile={company} />
                  </div>
                  <div className="u-content-spacing-top">
                    <h5 className="text-subheading u-half-spacing-bottom">Detailed widget</h5>
                    <EmbedDetailedProfilePreview companyProfile={company} />
                  </div>
                </Accordion>
              ),
            },
          ]}
        />
      </div>
      <CenteredModalDialog open={isOpen} onClose={close} modalId="publicProfileDialog" disableEnforceFocus width="wide">
        <PublicProfile company={company} disabled />
      </CenteredModalDialog>
    </>
  );
}

function EmbedProfilePreview({ companyProfile }: { companyProfile: CompanyProfile }) {
  const theme = useTheme();
  const { hasPublicPitch } = companyProfile;

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
          display: 'flex',
          alignItems: 'start',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ width: 1, maxWidth: EMBED_IFRAME_WIDTH + 60, marginRight: 2, marginBottom: 1 }}>
          <TextField
            inputProps={{
              readOnly: true,
              backgroundColor: bluePlanetTheme.bluePlanetPalette.grey.light,
            }}
            multiline
            value={
              hasPublicPitch
                ? getIframeCode(companyProfile.slug)
                : 'You must set your company profile to public in order to share your profile widget'
            }
          />
        </Box>
        <div className="u-content-spacing-bottom">
          <CopyButton
            copyString={getIframeCode(companyProfile.slug)}
            displayString="Copy embed code"
            color="grey"
            kind="secondary"
            disabled={!hasPublicPitch}
          />
        </div>
      </Box>
      <Box
        sx={{
          borderRadius: 2,
          backgroundColor: theme => theme.bluePlanetPalette.grey.light,
          [theme.breakpoints.down('sm')]: {
            paddingX: 2,
            paddingBottom: 2,
            paddingTop: 3,
            marginRight: 0,
          },
          paddingX: { sm: 3 },
          paddingBottom: { sm: 3 },
          paddingTop: { sm: 4 },
          marginRight: { sm: 2 },
          maxWidth: EMBED_IFRAME_WIDTH + 60, // paddingX 3 is 3 * 10 for each side = 60
          position: 'relative',
        }}
      >
        <Label
          style={{ position: 'absolute', top: spacingUnits.half, left: spacingUnits.half, margin: 0 }}
          className="u-color-grey"
        >
          Preview
        </Label>
        {hasPublicPitch ? (
          <div className="u-fullWidth" dangerouslySetInnerHTML={{ __html: getIframeCode(companyProfile.slug) }} />
        ) : (
          <EmptyCompanyCard type="embed" />
        )}
      </Box>
    </>
  );
}

function EmbedDetailedProfilePreview({ companyProfile }: { companyProfile: CompanyProfile }) {
  const theme = useTheme();
  const { hasPublicPitch } = companyProfile;

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
          display: 'flex',
          alignItems: 'start',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ width: 1, maxWidth: EMBED_IFRAME_WIDTH + 60, marginRight: 2, marginBottom: 1 }}>
          <TextField
            inputProps={{
              readOnly: true,
              backgroundColor: bluePlanetTheme.bluePlanetPalette.grey.light,
            }}
            multiline
            value={
              hasPublicPitch
                ? getIframeCode(companyProfile.slug, 'detailed')
                : 'You must set your company profile to public in order to share your profile widget'
            }
          />
        </Box>
        <div className="u-content-spacing-bottom">
          <CopyButton
            copyString={getIframeCode(companyProfile.slug, 'detailed')}
            displayString="Copy embed code"
            color="grey"
            kind="secondary"
            disabled={!hasPublicPitch}
          />
        </div>
      </Box>
      <Box
        sx={{
          borderRadius: 2,
          backgroundColor: theme => theme.bluePlanetPalette.grey.light,
          [theme.breakpoints.down('sm')]: {
            paddingX: 2,
            paddingBottom: 2,
            paddingTop: 3,
            marginRight: 0,
          },
          paddingX: { sm: 3 },
          paddingBottom: { sm: 3 },
          paddingTop: { sm: 4 },
          marginRight: { sm: 2 },
          maxWidth: EMBED_IFRAME_WIDTH + 60, // paddingX 3 is 3 * 10 for each side = 60
          position: 'relative',
        }}
      >
        <Label
          style={{ position: 'absolute', top: spacingUnits.half, left: spacingUnits.half, margin: 0 }}
          className="u-color-grey"
        >
          Preview
        </Label>
        {hasPublicPitch ? (
          <div
            className="u-fullWidth"
            dangerouslySetInnerHTML={{ __html: getIframeCode(companyProfile.slug, 'detailed') }}
          />
        ) : (
          <EmptyCompanyCard type="embed" />
        )}
      </Box>
    </>
  );
}

function QrPrintBadgePreview({ company }: { company: CompanyProfile }) {
  return (
    <>
      <div className="u-flex-center" style={{ marginBottom: -50 }}>
        <Logo url={company.logoURL} size={90} type="company" />
      </div>
      <Box
        sx={{
          borderRadius: 2,
          boxShadow: 1,
          bgcolor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          paddingTop: 5,
          paddingBottom: 3,
          paddingX: 3,
        }}
      >
        <h2 className="text-x-large text-weight-bold text-font-text u-half-spacing-top">{company.name}</h2>
        <div className="u-content-spacing-top">
          <QRCodeSVG value={getPublicCompanyUrl(company.slug)} />
        </div>
        <p className="text-body text-weight-medium u-content-spacing-top">Scan to connect with us</p>
      </Box>
    </>
  );
}
