import { useSWRResource } from 'util/resource/useSWRResource';
import useSWR from 'hooks/useSWR';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import { FundingRound } from 'types/company';

export const fundingRoundKey = (companySlug: string) => companiesAPIUrls.fundingRound.getActive(companySlug);

export default function useFundingRound(companySlug: string) {
  const swrResult = useSWR<FundingRound>(fundingRoundKey(companySlug));

  return useSWRResource(swrResult);
}
