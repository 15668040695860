import React, { createRef, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { useDebouncedCallback } from 'use-debounce';
import cx from 'classnames';

import TextField from 'ui/elements/form/TextField';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import SearchIcon from 'ui/elements/icons/SearchIcon';

import styles from './styles.scss';

interface Props {
  setValue: (value: string) => void;
  value: string;
  onChange?: () => void;
  placeholder?: string;
  alwaysOpen?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  prefix?: React.ReactNode;
}

export default function TextFilter(props: Props) {
  const inputRef = createRef<HTMLInputElement>();
  const [searchInput, setSearchInput] = React.useState(props.value);
  const debounced = useDebouncedCallback(() => {
    props.setValue(searchInput);
    props.onChange && props.onChange();
  }, 300);

  const onSearchChange = (val: string) => {
    setSearchInput(val);
    debounced();
  };

  const [isFilterActive, setIsFilterActive] = useState<boolean>(props.alwaysOpen || !!props.value);

  useEffect(() => {
    setSearchInput(props.value);
  }, [props.value]);

  return (
    <span className="u-flex u-flex-align-center" style={props.fullWidth ? { width: '100%' } : undefined}>
      <span
        style={props.style}
        className={cx(styles.textFilter, {
          [styles.textFilterOpen]: isFilterActive && !props.fullWidth,
          [styles.textFilterFullWidth]: isFilterActive,
        })}
      >
        <TextField
          prefix={props.prefix}
          autoFocus={props.autoFocus}
          placeholder={props.placeholder}
          inputRef={inputRef}
          value={searchInput}
          suffix={
            props.value || !props.alwaysOpen ? (
              <IconButton
                onClick={() => {
                  props.setValue('');
                  if (!props.alwaysOpen) {
                    setIsFilterActive(false);
                  }
                  props.onChange && props.onChange();
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : null
          }
          onChange={e => onSearchChange(e.target.value)}
          onKeyUp={props.onKeyUp}
        />
      </span>
      {!isFilterActive ? (
        <IconButton
          onClick={() => {
            setIsFilterActive(true);
            inputRef?.current?.focus();
          }}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      ) : null}
    </span>
  );
}
