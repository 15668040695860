import { Box, FormHelperText } from '@mui/material';
import React, { useState } from 'react';
import { UserProfile, UserProfileWithAccess } from 'types/user';
import * as Yup from 'yup';
import { companiesApi, HighlightedSection, HighlightedSectionList } from 'apis/CompanyAPI/companies/companiesApi';
import Card from 'ui/views/cards/Card';
import Dialog, { Title, Content, DialogActions } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { ICompany } from 'types/company';
import Avatar from 'ui/elements/avatars/Avatar';
import useLazyResource from 'util/resource/useLazyResource';
import ChipList from 'ui/elements/Chip/ChipList';
import AutocompleteResource from 'ui/elements/form/AutocompleteResource';
import CardStack from 'ui/views/cards/CardStack';
import IconButton from 'ui/elements/icons/IconButton';
import RemoveIcon from 'ui/elements/icons/RemoveIcon';
import { useFormik } from 'formik';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import Tooltip from 'ui/elements/Tooltip';
import { useCompanyLastUpdated } from '../LastUpdated';
import { get } from 'apis/ApiBase';
import { PaginatedResult } from 'types';
import { Link } from 'react-router-dom';
import Action from 'ui/elements/actions/Action';
import { companyUrls } from 'urls';
import InviteUserIcon from 'ui/elements/icons/InviteUserIcon';

export type EditSectionMode = { type: 'new' } | { type: 'edit'; section: HighlightedSection };

function PredefinedSectionButton({ name, onChange }: { name: string; onChange: (name: string) => void }) {
  return (
    <Button kind="primary" onClick={() => onChange(name)} color="blueLight" shape="rounded">
      {name}
    </Button>
  );
}

export default function HighlightedUsersDialog({
  onClose,
  onSuccess,
  company,
  editMode,
}: {
  onClose: () => void;
  onSuccess: (response: HighlightedSectionList) => void;
  company: ICompany;
  editMode: EditSectionMode;
}) {
  const { setLastUpdated } = useCompanyLastUpdated(company.id);
  const [setKeyUsers, isSaving] = useLazyResource(
    (values: { sectionName: string; users: UserProfile[] }) =>
      editMode.type === 'edit'
        ? companiesApi.profile.highlightedUsers.patch(
            company.slug,
            editMode.section.id,
            values.sectionName,
            values.users.map(user => user.cwUserId),
          )
        : companiesApi.profile.highlightedUsers.post(
            company.slug,
            values.sectionName,
            values.users.map(user => user.cwUserId),
          ),
    {
      onSuccess: result => {
        onSuccess(result);
        setLastUpdated(new Date());
        onClose();
      },
    },
  );

  const [removeSection, isRemoving] = useLazyResource(
    () =>
      editMode.type === 'edit'
        ? companiesApi.profile.highlightedUsers.delete(company.slug, editMode.section.id)
        : Promise.resolve({ sections: [] }),
    {
      onSuccess: result => {
        onSuccess(result);
        onClose();
      },
    },
  );

  const formikProps = useFormik({
    validateOnChange: false,
    initialValues: {
      sectionName: editMode.type === 'edit' ? editMode.section.sectionName : '',
      users: editMode.type === 'edit' ? editMode.section.users : [],
    },
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      sectionName: Yup.string()
        .trim()
        .required('Please provide a name for the section')
        .max(100, 'Name cannot be longer than 100 characters'),
      users: Yup.array().min(1, 'Please select at least 1 user'),
    }),
    onSubmit: values => setKeyUsers(values),
  });

  const [showSectionNameField, setShowSectionNameField] = useState(!!formikProps.values.sectionName);
  const setSection = (name: string) => {
    setShowSectionNameField(true);
    formikProps.setFieldValue('sectionName', name, !!formikProps.errors.sectionName);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" scroll="paper">
      <Title onClose={onClose}>
        {editMode.type === 'edit' ? `Edit section: ${editMode.section.sectionName}` : 'Add new section'}
      </Title>
      <Content overflowY="auto">
        <p className="u-content-spacing-bottom">Showcase the key stakeholders in your company.</p>
        {!formikProps.values.sectionName && (
          <div className="u-content-spacing-bottom">
            <ChipList>
              <PredefinedSectionButton name="Core team" onChange={setSection} />
              <PredefinedSectionButton name="Key shareholders" onChange={setSection} />
              <PredefinedSectionButton name="Advisory board" onChange={setSection} />
              <PredefinedSectionButton name="Board members" onChange={setSection} />
              <PredefinedSectionButton name="Founders" onChange={setSection} />
              <Button kind="tertiary" color="indigo" shape="rounded" onClick={() => setShowSectionNameField(true)}>
                Add other
              </Button>
            </ChipList>
            {/* Show validation error if section name is missing and input field isn't visible yet */}
            {formikProps.errors.sectionName && formikProps.touched.sectionName && !showSectionNameField && (
              <FormHelperText error>Please select a category</FormHelperText>
            )}
          </div>
        )}
        {showSectionNameField && (
          <div className="u-half-spacing-bottom">
            <FormikTextField
              autoFocus={showSectionNameField}
              formikProps={formikProps}
              name="sectionName"
              maxRows={1}
              label="Section name"
            />
          </div>
        )}
        <div className="u-half-spacing-bottom">
          <Link to={companyUrls.settings.inviteUser(company.slug, 'prospective_investor')} className="text-link">
            <Action icon={<InviteUserIcon />}>Invite team members</Action>
          </Link>
        </div>
        <AutocompleteResource<UserProfile>
          name="users"
          label="Add users"
          placeholder="Search for user"
          error={!!formikProps.errors.users && formikProps.touched.users !== undefined}
          helperText={
            typeof formikProps.errors.users === 'string' && formikProps.touched.users !== undefined
              ? formikProps.errors.users
              : undefined
          }
          minCharactersTreshold={0}
          onBlur={formikProps.handleBlur}
          onSelect={user => {
            if (!formikProps.values.users.find(selected => selected.id === user.id)) {
              (async () => {
                await formikProps.setFieldValue('users', [...formikProps.values.users, user], true);
                // for some reason, formik is unable to validate the users array correctly. We have to
                // manually remove the validation error after the value is updated
                await formikProps.setFieldError('users', undefined);
              })();
            }
          }}
          renderOption={(props, option: UserProfile) => (
            <Box component="li" {...props} key={option.id}>
              <Avatar className="u-half-spacing-right" userName={option.name} imageUrl={option.imageUrl} />{' '}
              {option.name}
              <span className="u-half-spacing-left u-color-grey text-weight-regular">
                {option.employer} {option.position}
              </span>
            </Box>
          )}
          onQueryChange={query =>
            get<PaginatedResult<UserProfileWithAccess>>(
              companiesApi.users.list(company.id, { page: 1, limit: 15 }, { name: query }),
            ).then(r =>
              r.values.filter(user => formikProps.values.users.every(selectedUser => selectedUser.id !== user.id)),
            )
          }
        />
        <CardStack>
          {formikProps.values.users.map(user => (
            <Card key={user.id} className="u-flex-space-between">
              <div className="u-flex-align-center">
                <Avatar userName={user.name} imageUrl={user.imageUrl} className="u-content-spacing-right" />
                <span className="u-ellipsis">{user.name}</span>
              </div>
              <IconButton
                color="grey"
                onClick={() =>
                  formikProps.setFieldValue(
                    'users',
                    formikProps.values.users.filter(u => u.id !== user.id),
                    true,
                  )
                }
              >
                <RemoveIcon />
              </IconButton>
            </Card>
          ))}
        </CardStack>
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button kind="primary" onClick={formikProps.submitForm} isLoading={isSaving} disabled={isRemoving}>
            Save
          </Button>
          <Button onClick={onClose} kind="tertiary" disabled={isRemoving}>
            Cancel
          </Button>
        </ButtonList>
        {editMode.type === 'edit' && (
          <Tooltip title="Remove" placement="top">
            <span>
              <IconButton disabled={isRemoving} color="red" onClick={removeSection}>
                <TrashIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </DialogActions>
    </Dialog>
  );
}
