import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserProfile } from 'actions/userProfiles';
import { getCompanyProfile } from 'actions/companyProfiles';
import { CompanyUpdate } from 'types/content';
import { useEffect } from 'react';
import { companyUpdateAPI } from './UpdateAPI';

export function useCompanyUpdate(companyId: number, updateId: UUID) {
  const api = companyUpdateAPI(companyId);
  const swrResult = useSWR<CompanyUpdate>(api.get(updateId));

  const dispatch = useDispatch();
  const boundActions = bindActionCreators(
    {
      getUserProfile,
      getCompanyProfile,
    },
    dispatch,
  );

  useEffect(() => {
    if (swrResult.data !== undefined) {
      boundActions.getUserProfile(swrResult.data.update.creatorCwUserId);
      boundActions.getCompanyProfile(swrResult.data.companyId);
    }
  }, [updateId, swrResult.data !== undefined]);

  return useSWRResource(swrResult);
}
