import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ExternalLinkIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M11.1 3.4c-3.7 0-5.6 0-6.8 1.3C3 6 3 8 3 12.4c0 4.2 0 6.3 1.3 7.6 1.3 1.4 3.5 1.4 7.7 1.4s6.4 0 7.7-1.4c1.2-1.2 1.3-3.1 1.3-6.7M20.6 3.8 11 13.4m9.6-9.6c-.5-.4-3.9-.4-4.6-.4m4.6.4c.5.5.4 3.9.4 4.6" />
    </SvgIcon>
  );
}
