import CircularProgress from '@mui/material/CircularProgress';
import { notify } from 'actions/snackbar';
import { getUserProfiles } from 'actions/userProfiles';
import React from 'react';
import { connect } from 'react-redux';
import { PaginatedResult } from 'types';
import { RootStore } from 'globalstate/rootStore';
import { PaginationOptions } from 'types/api';
import { ICwUserId, UserProfile } from 'types/user';
import Button from 'ui/elements/buttons/Button';
import Dialog, { Title, Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { addPage } from 'util/paginationUtils';
import UserList from './UserList';

interface Props {
  userProfiles: UserProfile[];
}
interface State<T> {
  resource?: PaginatedResult<T>;
}

interface DispatchProps {
  notify: typeof notify;
  getUserProfiles: typeof getUserProfiles;
}

interface OwnProps<T> {
  isOpen: boolean;
  closeDialog: () => void;
  fetchResource: (paginationOptions?: PaginationOptions) => Promise<PaginatedResult<T>>;
  title: string;
  emptyMessage?: string;
}

class UserListDialog<T extends ICwUserId> extends React.Component<Props & DispatchProps & OwnProps<T>, State<T>> {
  constructor(props: Props & DispatchProps & OwnProps<T>) {
    super(props);
    this.state = {
      resource: undefined,
    };
  }

  async componentDidUpdate(oldProps: Props & DispatchProps & OwnProps<T>) {
    if (!oldProps.isOpen && this.props.isOpen) {
      try {
        const resource = await this.props.fetchResource({ limit: 20 });
        await this.props.getUserProfiles(resource.values.map(value => value.cwUserId));
        this.setState({
          resource,
        });
      } catch (e) {
        this.props.closeDialog();
        this.props.notify('error', 'Could not load resource. Please try again later.');
      }
    }
    if (oldProps.isOpen && !this.props.isOpen) {
      this.setState({
        resource: undefined,
      });
    }
  }

  seeMore = async (paginationOptions: PaginationOptions) => {
    const resource = await this.props.fetchResource(paginationOptions);
    await this.props.getUserProfiles(resource.values.map(value => value.cwUserId));
    this.setState(prevState => ({
      resource: prevState.resource ? addPage(prevState.resource, resource, (value: T) => value.cwUserId) : resource,
    }));
    return resource;
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const closeHandler: React.ReactEventHandler<{}> = e => {
      e.stopPropagation();
      closeDialog();
    };

    return (
      <Dialog open={isOpen} onClose={closeDialog} maxWidth="xs">
        <Title onClose={closeDialog}>{this.props.title}</Title>
        <Content>
          {this.state.resource ? (
            <UserList
              emptyMessage={this.props.emptyMessage}
              resource={this.state.resource}
              userProfiles={this.props.userProfiles}
              seeMore={this.seeMore}
            />
          ) : (
            <div className="u-flex-center">
              <CircularProgress />
            </div>
          )}
        </Content>
        <DialogActions>
          <Button kind="secondary" onClick={closeHandler}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: RootStore) => ({
  userProfiles: state.userProfiles.items,
});

export default connect(mapStateToProps, {
  getUserProfiles,
  notify,
})(UserListDialog);
