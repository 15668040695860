import config from 'config';
import { List, PaginationOptions } from 'types/api';
import { FollowerRequestStatusFilter } from 'types/company/companyAPI';
import { ReceivedFollowerRequestDTO } from 'types/follower';
import ApiBase from '../ApiBase';
import { withPagination } from 'apis/utils';

const baseUrl = config.COMPANIES_API_URL;

const getFollowUrl = (companyId: number) => `${baseUrl}/companies/${companyId}/followers`;

const api = ApiBase();

export default {
  user: {
    requestFollow: (companyId: number, message: string, context: { path: string; communitySlug?: string }) =>
      api.post(`${getFollowUrl(companyId)}/requests/prospective-investor`, { message, context }),
    unfollow: (companyId: number) => api.deleteRequest(`${getFollowUrl(companyId)}/requests/prospective-investor`),
    listUserRequestsForMyCompanies: (userId?: number) =>
      api.get<List<ReceivedFollowerRequestDTO>>(
        `${baseUrl}/users/follower-requests/received${userId && `?userId=${userId}`}`,
      ),
  },
  company: {
    list: (
      companyId: number,
      paginationOptions: PaginationOptions,
      filters: { status?: FollowerRequestStatusFilter },
    ) =>
      withPagination(`${baseUrl}/companies/${companyId}/followers/pending`, paginationOptions, {
        status: filters.status && filters.status !== 'all' ? filters.status : undefined,
      }),

    accept: (companyId: number, userId: number) =>
      api.post(`${baseUrl}/companies/${companyId}/followers/pending/${userId}/accept`, {}),
    reject: (companyId: number, userId: number) =>
      api.deleteRequest(`${baseUrl}/companies/${companyId}/followers/pending/${userId}`),
  },
};
