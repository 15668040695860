import React from 'react';
import SvgIcon, { IconProps, getColor } from './SvgIcon';

export default function LinkedInIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        stroke={getColor(props.color)}
        d="M4.5 9.5H4c-.94 0-1.41 0-1.7.3-.3.29-.3.76-.3 1.7V20c0 .94 0 1.41.3 1.7.29.3.76.3 1.7.3h.5c.94 0 1.41 0 1.7-.3.3-.29.3-.76.3-1.7v-8.5c0-.94 0-1.41-.3-1.7-.29-.3-.76-.3-1.7-.3ZM6.5 4.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
      />
      <path
        fill="none"
        stroke={getColor(props.color)}
        d="M12.33 9.5h-.83c-.94 0-1.41 0-1.7.3-.3.29-.3.76-.3 1.7V20c0 .94 0 1.41.3 1.7.29.3.76.3 1.7.3h.5c.94 0 1.41 0 1.7-.3.3-.29.3-.76.3-1.7v-3.5c0-1.66.53-3 2.09-3 .78 0 1.41.67 1.41 1.5v4.5c0 .94 0 1.41.3 1.7.29.3.76.3 1.7.3h.5c.94 0 1.41 0 1.7-.3.3-.29.3-.76.3-1.7V14c0-2.48-2.36-4.5-4.7-4.5-1.34 0-2.52.65-3.3 1.67 0-.63 0-.94-.14-1.18a1 1 0 0 0-.35-.35c-.24-.14-.55-.14-1.18-.14Z"
      />
    </SvgIcon>
  );
}
