import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import DocumentAPI from 'apis/DocumentAPI';
import { uploadFileToGCP } from 'domain/shared/Files/actions';

export function uploadPitchDocument(companyId: number, documentType: string) {
  return (document: File) =>
    uploadFileToGCP(
      document,
      () =>
        companiesApi.profile.pitchDocuments.post(companyId, {
          filename: document.name,
          documentType,
        }),
      (documentId: UUID) => DocumentAPI.deleteDocument(companyId, documentId),
    );
}
