import uniqBy from 'ramda/src/uniqBy';
import { ICompany } from 'types/company/company';

export enum ActionTypes {
  FETCH_SINGLE = 'companyProfiles/FETCH_SINGLE',
  FETCH_LIST = 'companyProfiles/FETCH_LIST',
}

type companyProfilesFetched = {
  type: ActionTypes.FETCH_LIST;
  payload: { values: ICompany[] };
};

type companyProfileFetched = {
  type: ActionTypes.FETCH_SINGLE;
  payload: ICompany;
};

export const fetchCompanyProfiles = (payload: { values: ICompany[] }): companyProfilesFetched => ({
  payload,
  type: ActionTypes.FETCH_LIST,
});

export const fetchCompanyProfile = (payload: ICompany): companyProfileFetched => ({
  payload,
  type: ActionTypes.FETCH_SINGLE,
});

export type actions = companyProfilesFetched | companyProfileFetched;

type State = {
  items: ICompany[];
};

const initialState: State = {
  items: [],
};

export default (state = initialState, action: actions): State => {
  switch (action.type) {
    case ActionTypes.FETCH_LIST:
      return {
        ...state,
        items: uniqBy(company => company.id, [...state.items, ...action.payload.values]),
      };
    case ActionTypes.FETCH_SINGLE:
      return {
        ...state,
        items: uniqBy(user => user.id, [...state.items, action.payload]),
      };
    default:
      return state;
  }
};
