import SvgIcon, { IconProps } from '../SvgIcon';
import React from 'react';

export default function XlsIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3.5 13v-.8c0-3 0-4.5.5-5.6.7-2 2.3-3.4 4.3-4.2 1.3-.4 2.9-.4 6-.4 1.8 0 2.7 0 3.4.3 1.2.4 2.1 1.2 2.5 2.3.3.7.3 1.5.3 3.2V13" />
      <path d="M3.5 12c0-1.8 1.5-3.3 3.3-3.3H9c.6-.2 1-.7 1.2-1.3.2-.6 0-1.4 0-2 0-2 1.6-3.4 3.4-3.4" />
      <path d="M16.5 20.3c0 .8.1 1.1 1 1.1H19c.8 0 1.5-.7 1.5-1.6 0-1-.7-1.7-1.5-1.7l-1.5-.3M18.7 15.4h-1.2c-.6 0-1 .6-1 1.2 0 .7.4 1.2 1 1.2M11.5 15.4v4.8c0 .9.1 1.2 1 1.2H14" />
      <path d="m4.2 21.4 4-6M4.2 15.4l4 6" />
    </SvgIcon>
  );
}
