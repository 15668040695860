export const quarterSpacing = '0.3125rem'; // 5px when base font size is 16px
export const halfSpacing = '0.625rem'; // 10px when base font size is 16px
export const contentSpacing = '1.25rem'; // 20px when base font size is 16px
export const sectionSpacing = '2.5rem'; // 40px when base font size is 16px
export const mobileNavbarHeight = '60px';
export const transtionEase = 'cubic-bezier(0.4, 0, 0.2, 1)';
export const transitionHover = 'cubic-bezier(0.25, 0.8, 0.25, 1)';
export const zLayer1 = 10;
export const zLayer2 = 20;
export const screenSizeXs = '360px';
export const screenSizeSm = '600px';
export const screenSizeMd = '960px';
export const screenSizeLg = '1280px';
export const screenSizeXl = '1920px';
