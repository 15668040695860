import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { emptyPaginatedResult } from 'util/paginationUtils';
import { getOrElse, getOrUndefined } from 'util/resource';

export function useAccess() {
  const companiesResource = useCompanies();
  const communitiesResource = useCommunities();
  const companies = getOrElse(companiesResource.resource, emptyPaginatedResult()).values;
  const communities = getOrUndefined(communitiesResource.resource)?.values ?? [];
  return {
    hasAccess: (companyId: number) => [...companies, ...communities].some(item => item.id === companyId),
  };
}
