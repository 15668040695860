import React from 'react';

import { currentYear } from 'util/dateUtils';
import CrowdWorksLogo from 'ui/elements/icons/CrowdWorksLogo';
import BackgroundShape from 'ui/illustrations/crowdworks_background_shape.svg';

import styles from './styles.scss';
import Button from 'ui/elements/buttons/Button';
import { bluePlanetTheme } from 'ui/theme';
import { userUrls } from 'urls';
import { useLoginWithRedirect } from 'auth/useLoginWithRedirect';

// This footer has the same design as the on on our webpage and should be kept in sync.
// https://github.com/crowdworksit/marketing-v2/tree/master/src/sections/Footer

export default function Footer({ topMargin = 'regular' }: { topMargin?: 'regular' | 'none' }) {
  const { loginWithRedirect } = useLoginWithRedirect();
  return (
    <footer className={styles.container} style={{ marginTop: topMargin === 'regular' ? 200 : 0 }}>
      <div className={styles.sizingContainer}>
        <div className={styles.image1}>
          <img src={BackgroundShape} alt="footer background" />
        </div>
        <div className={styles.image2}>
          <img src={BackgroundShape} alt="footer background" />
        </div>
        <div className={styles.image3}>
          <img src={BackgroundShape} alt="footer background" />
        </div>
        <div className="u-flex u-flex-center">
          <div className={styles.footerMessage}>
            <div>Are you investing in unlisted companies?</div>
            <div>
              Browse company pitches, investors and network communities to find your next
              <span style={{ color: bluePlanetTheme.bluePlanetPalette.blue.main }}> success story.</span>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button kind="primary" onClick={() => loginWithRedirect('signup')}>
            Sign up
          </Button>
          <a href="https://crowdworks.it">Learn more</a>
        </div>
        <div className={styles.footer}>
          <div className={styles.left}>
            <div className={styles.logo}>
              <CrowdWorksLogo style={{ width: '40px', height: '40px' }} />
              <span className=" u-half-spacing-left text-font-text text-large text-weight-medium">CrowdWorks</span>
            </div>
            <div className={styles.address}>
              <div className={styles.addressBlock}>
                <div>Møllergata 6,</div>
                <div>0179 Oslo, Norway</div>
              </div>
              <div className={styles.addressBlock}>
                <div>43 W 23rd St</div>
                <div>New York, NY 10010</div>
              </div>
              <div className={styles.addressBlock}>
                <div>
                  <a href="mailto: hello@crowdworks.it">E-mail us</a> or follow us
                </div>
                <div>
                  on{' '}
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/crowdworks.it/">
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <ul>
              <div className={styles.appsTitle}>Find our apps:</div>
              <li>
                <a href="https://apps.apple.com/app/crowdworks/id1475645202">Apple App Store</a>
              </li>
              <li>
                <a href="https://play.google.com/store/apps/details?id=it.crowdworks.androidApp">Google Play</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://crowdworks.it">Companies</a>
              </li>
              <li>
                <a href="https://crowdworks.it/communities">Accelerators</a>
              </li>
              <li>
                <a href="https://crowdworks.it/investors">Investors</a>
              </li>
              <li>
                <a href="https://crowdworks.it/about">About us</a>
              </li>
              <li>
                <a href="https://crowdworks.it/careers">Careers</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href={userUrls.legal.privacyPolicy()}>Privacy policy</a>
              </li>
              <li>
                <a href={userUrls.legal.termsOfUse()}>Terms of use</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.copyright}>© {currentYear()} — All rights reserved</div>
      </div>
    </footer>
  );
}
