import React from 'react';
import { IResource } from 'util/resource';
import Loading from 'ui/elements/Loading';
import LoadingBar from 'ui/elements/Loading/LoadingBar';
import ErrorBoundary from 'util/components/ErrorBoundary';
import ErrorCard from 'ui/views/errors/ErrorCard';

interface LProps<T> {
  children: (t: T) => React.ReactNode;
  resource: IResource<T>;
  renderError?: 'Nothing' | (() => JSX.Element);
  renderLoading?: 'Nothing' | 'Bar' | (() => JSX.Element);
}

function InnerComponent<T>(props: LProps<T>) {
  switch (props.resource.state) {
    case 'idle':
      return null;
    case 'fetching':
      if (props.renderLoading === 'Nothing' || props.renderLoading === 'Bar') {
        return null;
      }
      return typeof props.renderLoading === 'function' ? (
        props.renderLoading()
      ) : (
        <div className="u-align-center u-content-spacing">
          <Loading />
        </div>
      );
    case 'fetched':
      return <>{props.children(props.resource.resource)}</>;
    case 'error':
      if (props.renderError) {
        return props.renderError === 'Nothing' ? null : props.renderError();
      }
      return <ErrorCard />;
  }
}

export default function Resource<T>(props: LProps<T>) {
  return (
    <ErrorBoundary fallback={<ErrorCard />}>
      {/* The LoadingBar needs to stay rendered after loading to fade out correctly */}
      {props.renderLoading === 'Bar' && (
        <LoadingBar isComplete={props.resource.state === 'fetched' || props.resource.state === 'error'} />
      )}
      <InnerComponent {...props} />
    </ErrorBoundary>
  );
}
