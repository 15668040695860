import React from 'react';
import HeadTags from 'ui/views/layouts/BaseLayout/PageTitle';
import ScrollableBody from 'ui/views/layouts/BaseLayout/ScrollableBody';
import NotFound from './NotFound';

export default function NotFoundPage() {
  return (
    <>
      <HeadTags title="404 Not found" />
      <ScrollableBody accountForMobileHeader>
        <NotFound />
      </ScrollableBody>
    </>
  );
}
