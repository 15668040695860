import { useEffect } from 'react';
import pluginConfig from './config';

import 'vanilla-cookieconsent';
import 'styles/global/overrides/cookieconsent.scss';

// Whenever we add new cookies, we need to update the cookie list in the config.
// Cookies are not enabled until the user accepts them.
// This works by changing scripts in the index file to text/plain. So the scripts won't run until the user accepts cookies,
// this plugin executes the script blocks.
// the data-cookiecategory attribute on the script tags needs to match the category value in the config file.

export default function CookieConsent() {
  useEffect(() => {
    if (!document.getElementById('cc--main')) {
      window.CC = window.initCookieConsent();
      window.CC.run(pluginConfig);
    }
  }, []);

  return null;
}

export function useCookieConsent() {
  const show = () => {
    document.getElementById('cc--main')?.remove();
    delete_cookie('cc_cookie', '/', window.location.hostname);
    window.CC = window.initCookieConsent();
    window.CC.run(pluginConfig);
  };

  return { show };
}

function delete_cookie(name: string, path: string, domain: string) {
  if (get_cookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
}

function get_cookie(name: string) {
  return document.cookie.split(';').some(c => {
    return c.trim().startsWith(name + '=');
  });
}
