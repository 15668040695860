import styled from '@emotion/styled';
import cx from 'classnames';
import React from 'react';
import { contentSpacing, sectionSpacing } from 'ui/theme/themeConstants';

import styles from './styles.scss';

interface Props {
  className?: string;
  align?: 'left' | 'center';
  columnCount?: number;
  columnWidth?: number;
  gridGap?: 'section' | 'content';
  children: { key: string; value: React.ReactNode }[];
  style?: React.CSSProperties;
}
const StyledHorizontalDictionary = styled.dl(
  (props: { columnCount: number; columnWidth?: number; gridGap: Props['gridGap'] }) => `
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      min(
        100%,
        max(
          ${props.columnWidth ?? 100}px${props.columnCount ? ', ' + 100 / (props.columnCount + 1) + '%' : ''}
        )
      ),
      1fr
    )
  );
  grid-gap: ${props.gridGap === 'section' ? sectionSpacing : contentSpacing};
`,
);

export default function HorizontalDictionary(props: Props) {
  return (
    <StyledHorizontalDictionary
      columnCount={props.columnCount || props.children.length}
      columnWidth={props.columnWidth}
      gridGap={props.gridGap}
      style={props.style}
      className={cx(props.className, { 'text-align-center': props.align === 'center' })}
    >
      {props.children.map(child => (
        <div key={child.key}>
          <dt className="text-subheading-2">{child.key}</dt>
          <dd className={styles.horizontalValue}>{child.value}</dd>
        </div>
      ))}
    </StyledHorizontalDictionary>
  );
}
