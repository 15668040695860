import React from 'react';
import SvgIcon from './SvgIcon';

interface Props {
  className?: string;
}

export default function AdminIcon(props: Props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 2C9 2 7 4 4.7 4.8c-.9.3-1.4.4-1.6.6-.2.2-.2.5-.3 1.2-1.2 6.5 1.4 12.6 7.6 15 .7.3 1 .4 1.6.4.6 0 1-.1 1.6-.4 6.2-2.4 8.8-8.5 7.6-15 0-.7-.1-1-.3-1.2-.2-.2-.7-.3-1.6-.6C17 4 15 2 12 2Z" />
      <path d="M12 2v20m9-10H3" />
    </SvgIcon>
  );
}
