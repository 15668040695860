import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CheckmarkIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m6 12.7 3.8 3.7 8.1-8.8" />
    </SvgIcon>
  );
}
