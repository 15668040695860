import Error from 'ui/elements/icons/ErrorIcon';
import React, { useState } from 'react';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import Button from 'ui/elements/buttons/Button';
import InfoCard from 'ui/views/cards/InfoCard';
import { LoadingStates } from 'util/resource';
import { hasMorePages } from 'util/paginationUtils';

interface Props<T> {
  resource: PaginatedResult<T>;
  loadResource: (paginationOptions: PaginationOptions) => Promise<any> | void;
  limit?: number;
  text?: string;
  className?: string;
  showRemaining?: boolean;
  render?: (fetch: () => Promise<void>, remaining: number, isLoading?: boolean) => React.ReactElement;
}

export default function SeeMore<T>(props: Props<T>) {
  const [state, setState] = useState<LoadingStates>('idle');

  const seeMore = async () => {
    if (props.resource.page < props.resource.total / props.resource.pageSize) {
      setState('fetching');
      const nextPage = props.resource.page + 1;
      try {
        await props.loadResource({ limit: props.limit || 20, page: nextPage });
        setState('fetched');
      } catch (e) {
        setState('error');
      }
    }
  };

  const canSeeMore = () => {
    if (props.resource.page === 0 && props.resource.total === 1) {
      // page = 0 when an initial load has occoured
      return false;
    }
    return hasMorePages(props.resource);
  };

  const text = props.text || 'See more';
  // page === 0 is a hack for resources with 1 item, to make see more load the first page again
  const actualPageForCalculatingRemaining = props.resource.page === 0 ? 1 : props.resource.page;
  const remaining = props.resource.total - actualPageForCalculatingRemaining * props.resource.pageSize;
  const postfix = props.showRemaining ? `(${remaining})` : '';

  if (!canSeeMore()) {
    return null;
  }

  switch (state) {
    case 'fetched':
    case 'fetching':
    case 'idle':
      return props.render ? (
        props.render(seeMore, remaining, state === 'fetching')
      ) : (
        <div className={props.className}>
          <Button onClick={seeMore} kind="secondary" isLoading={state === 'fetching'}>
            <span>
              {text}
              {postfix}
            </span>
          </Button>
        </div>
      );
    case 'error':
      return (
        <InfoCard title="Oops" icon={<Error fontSize="inherit" />}>
          We were unable to load more items 😳. You might want to try to refresh the page, or try to load it at a later
          time. We are sorry for the inconvenience.
          <div className="u-content-spacing-top">
            <Button kind="secondary" onClick={() => seeMore()}>
              Try again
            </Button>
          </div>
        </InfoCard>
      );
  }
}
