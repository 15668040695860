import React, { createContext, useContext, useState } from 'react';

const KeyboardNavigationContext = createContext<
  | {
      navigationContext: string | null;
      setNavigationContext: (context: string | null) => void;
    }
  | undefined
>(undefined);

export const useKeyboardNavigation = () => {
  const context = useContext(KeyboardNavigationContext);
  if (!context) {
    throw new Error('useKeyboardNavigation must be used within a KeyboardNavigationProvider');
  }
  return context;
};

export const KeyboardNavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeContext, setActiveContext] = useState<string | null>(null);
  return (
    <KeyboardNavigationContext.Provider
      value={{ navigationContext: activeContext, setNavigationContext: setActiveContext }}
    >
      {children}
    </KeyboardNavigationContext.Provider>
  );
};
