import IconButton from '@mui/material/IconButton';
import cx from 'classnames';
import React from 'react';
import NotificationEntry from 'domain/notify/NotificationEntry';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import HamburgerIcon from 'ui/elements/icons/HamburgerIcon';
import UserInbox from 'domain/conversations/UserInbox';
import styles from './styles.scss';
import { useNavigationContext } from 'domain/shared/Navigation';
import { bluePlanetTheme } from 'ui/theme';
import { quarterSpacing } from 'ui/theme/themeConstants';

interface Props {
  title: string;
}

export default function MobileHeader(props: Props) {
  const { isOpen, toggleNavigation } = useNavigationContext();

  return (
    <div className={styles.header}>
      <IconButton onClick={toggleNavigation}>
        {isOpen ? <CloseIcon className={styles.menu} /> : <HamburgerIcon className={styles.menu} />}
      </IconButton>
      <div className={cx(styles.titleWrapper, { ['u-align-center']: true })}>
        <div className="u-flex u-flex--column">
          <h1 className={styles.mobileTitle}>{props.title}</h1>
        </div>
      </div>
      <UserInbox
        style={{ color: bluePlanetTheme.bluePlanetPalette.white, padding: quarterSpacing }}
        className="u-half-spacing-right"
      />
      <NotificationEntry
        style={{ color: bluePlanetTheme.bluePlanetPalette.white, padding: quarterSpacing }}
        className="u-half-spacing-right"
      />
    </div>
  );
}
