import { backofficeApiUrls } from 'apis/BackofficeAPI';
import useSWR from 'hooks/useSWR';
import { BackofficeCompanyDetailsDTO } from 'types/backoffice';
import { CompanyId } from 'urls';
import { useSWRResource } from 'util/resource/useSWRResource';

export const backofficeCompanyKey = (companyId: CompanyId) => backofficeApiUrls.companies.get(companyId);

export const useBackofficeCompany = (companyId: CompanyId) => {
  const swrResult = useSWR<BackofficeCompanyDetailsDTO>(backofficeCompanyKey(companyId));

  return useSWRResource(swrResult);
};
