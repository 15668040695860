import cx from 'classnames';
import React from 'react';
import InfoIcon from 'ui/elements/icons/InfoIcon';
import Card from 'ui/views/cards/Card';
import classes from './infocard.scss';

interface Props {
  className?: string;
  title?: string;
  icon?: React.ReactNode;
  color?: 'red' | 'transparent' | 'blue' | 'green';
  children: React.ReactNode | React.ReactNode[];
}

export default function InfoCard(props: Props) {
  return (
    <Card
      className={cx(classes.infoBox, props.className, 'text-body', {
        [classes.red]: props.color === 'red',
        [classes.transparent]: props.color === 'transparent',
        [classes.blue]: props.color === 'blue',
        [classes.green]: props.color === 'green',
      })}
    >
      <div
        className={cx(classes.icon, {
          ['u-color-red']: props.color === 'red',
        })}
      >
        {props.icon ? props.icon : <InfoIcon />}
      </div>
      <div className="text-body">
        {props.title && (
          <div
            className={cx('u-flex-space-between u-flex-align-center', {
              'u-half-spacing-bottom': !!props.title,
            })}
          >
            <h3 className={cx('text-h4', classes.infoBoxHeader)}>{props.title}</h3>
          </div>
        )}
        {props.children}
      </div>
    </Card>
  );
}
