import { useAiOnboardingSessionStorage } from 'domain/onboarding/company/Wizard/AIOnboarding';
import React, { useState } from 'react';
import { ExecutiveSummaryDTO, ICompany } from 'types/company';
import SectionHeading from 'ui/elements/SectionHeading';
import EditAction from 'ui/elements/actions/EditAction';
import CtaButton from 'ui/elements/buttons/CtaButton';
import Card from 'ui/views/cards/Card';
import AIChip from '../AiChip';
import ProblemSolutionDialog from './ProblemSolutionDialog';

interface Props {
  company: ICompany;
  executiveSummary: ExecutiveSummaryDTO;
  canEditCompany: boolean;
  onSave?: () => void;
}

export default function ProblemSolutionSection({ company, executiveSummary, canEditCompany, onSave }: Props) {
  const [isProblemSolutionDialogOpen, setIsProblemSolutionDialogOpen] = useState(false);

  // Make sure which field triggered opening of the problem-solution dialog, so that we can focus the marker in the right input field.
  const [focusableProblemSolutionField, setFocusableProblemSolutionField] = useState<'problem' | 'solution'>('problem');
  const [isAiOnboarding] = useAiOnboardingSessionStorage();

  const executiveSummaryIsEmpty = !executiveSummary.problem && !executiveSummary.solution;
  if (!canEditCompany && executiveSummaryIsEmpty) {
    return null;
  }

  const onEdit = (field: 'problem' | 'solution') => {
    setIsProblemSolutionDialogOpen(true);
    setFocusableProblemSolutionField(field);
  };

  return (
    <div>
      <SectionHeading
        heading={
          <>
            The challenge
            {isAiOnboarding && <AIChip className="u-half-spacing-left" onClick={() => onEdit('problem')} />}
          </>
        }
        addMobileMargin
      >
        {canEditCompany && (
          <button onClick={() => onEdit('problem')} aria-label="Edit section">
            <EditAction tooltip="Edit section" />
          </button>
        )}
      </SectionHeading>
      <Card className="u-flex--gap-1">
        {(executiveSummary.problem || canEditCompany) && <h3 className="text-subheading-2">Problem</h3>}
        <div>
          {!executiveSummary.problem && canEditCompany ? (
            <CtaButton className="u-half-spacing-y" height={100} onClick={() => onEdit('problem')}>
              Which problem does your company solve?
            </CtaButton>
          ) : (
            <p className="u-half-spacing-y text-body">{executiveSummary.problem}</p>
          )}
        </div>
        {(executiveSummary.solution || canEditCompany) && <h3 className="text-subheading-2">Solution</h3>}
        {!executiveSummary.solution && canEditCompany ? (
          <CtaButton className="u-half-spacing-y" height={100} onClick={() => onEdit('solution')}>
            Which solutions do you provide?
          </CtaButton>
        ) : (
          <p className="u-half-spacing-y text-body">{executiveSummary.solution}</p>
        )}
      </Card>

      {isProblemSolutionDialogOpen && (
        <ProblemSolutionDialog
          company={company}
          executiveSummary={executiveSummary}
          focusableField={focusableProblemSolutionField}
          onClose={() => setIsProblemSolutionDialogOpen(false)}
          onSuccess={onSave}
        />
      )}
    </div>
  );
}
