import React from 'react';
import LinkedInIcon from 'ui/elements/icons/LinkedInIcon';
import cx from 'classnames';

interface Props {
  className?: string;
  linkedInUrl: string;
  linkIsDisabled?: boolean;
}

export default function LinkedIn({ className, linkedInUrl, linkIsDisabled = false }: Props) {
  const cxs = cx(className, 'u-flex-align-center text-link-small text-link-dark');
  return linkIsDisabled ? (
    <span className={cxs}>
      <LinkedInIcon fontSize="small" className="u-quarter-spacing-right" style={{ verticalAlign: 'middle' }} />
      <span>LinkedIn</span>
    </span>
  ) : (
    <a href={`https://www.linkedin.com/in/${linkedInUrl}`} rel="noopener noreferrer" target="_blank" className={cxs}>
      <LinkedInIcon fontSize="small" className="u-quarter-spacing-right" style={{ verticalAlign: 'middle' }} />
      <span>LinkedIn</span>
    </a>
  );
}
