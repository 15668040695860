import React, { forwardRef } from 'react';
import MuiBox from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  className?: string;
}

const Box = forwardRef<HTMLDivElement, Props>(function Box({ children, sx, className }, ref) {
  return (
    <MuiBox sx={sx} className={className} ref={ref}>
      {children}
    </MuiBox>
  );
});

export default Box;
