import clamp from 'ramda/src/clamp';

import { inflateSnackbarAction, SnackbarActionType } from 'globalstate/reducers/snackbarReducer';

import { TAction } from 'types';
import { SnackbarVariant, SnackbarAction } from 'types/snackbar';

export const inflateSnackbar = (message: string): TAction => {
  return async dispatch => {
    const timestamp = new Date().toString();
    // Display duration for snackbar is:
    // min 4 seconds, max 10 seconds otherwise 0.1 second per character in the message
    const duration = clamp(4000, 10000, 100 * message.length);

    dispatch(inflateSnackbarAction(timestamp, message, duration));
  };
};

const inflateSnackbarError = (message: string, action?: SnackbarAction): TAction => {
  return async dispatch => {
    const timestamp = new Date().toString();

    dispatch(inflateSnackbarAction(timestamp, message, undefined, 'error', action));
  };
};

const inflateSnackbarSuccess = (message: string, action?: SnackbarAction): TAction => {
  return async dispatch => {
    const timestamp = new Date().toString();
    // Display duration for snackbar is:
    // min 4 seconds, max 10 seconds otherwise 0.1 second per character in the message
    const duration = clamp(4000, 10000, 100 * message.length);

    dispatch(inflateSnackbarAction(timestamp, message, duration, 'success', action));
  };
};

export const notify = (type: SnackbarVariant, message: string, action?: SnackbarAction) => {
  switch (type) {
    case 'error':
      return inflateSnackbarError(message, action);
    case 'success':
      return inflateSnackbarSuccess(message, action);
  }
};

export const closeSnackbar = (): TAction => {
  return async dispatch => {
    dispatch({
      type: SnackbarActionType.CloseSnackbar,
    });
  };
};
