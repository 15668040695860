import React from 'react';
import Dropzone from 'react-dropzone';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import UploadIcon from 'ui/elements/icons/UploadIcon';
import { DOCUMENT_UPLOAD_LIMIT } from 'util/constants';

const Uploader = styled.div`
  width: 100%;
  max-width: 500px;
  font-weight: 500;
  font-size: 0.8125rem;
  text-align: center;
  padding: 5rem 3rem;
  border: 2px dashed ${bluePlanetTheme.bluePlanetPalette.indigo.main};
  &:hover {
    background-color: ${bluePlanetTheme.bluePlanetPalette.offWhite};
    cursor: pointer;
  }
`;

export default function UploadPitchDeck({
  onUseManualInput,
  onFileSelected,
}: {
  onFileSelected: (file: File) => void;
  onUseManualInput: () => void;
}) {
  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    onFileSelected(file);
  };

  return (
    <>
      <Content>
        <div className="u-content-spacing-top">
          <div className="u-flex u-flex-center">
            <Dropzone
              accept={{
                'application/*': ['application/pdf'],
              }}
              maxSize={DOCUMENT_UPLOAD_LIMIT.bytes}
              onDrop={onDrop}
              useFsAccessApi={false} // this is a hack to chrome to upload the correct file types. See https://github.com/react-dropzone/react-dropzone/issues/1191
            >
              {state => (
                <Uploader {...state.getRootProps()}>
                  <input {...state.getInputProps()} />
                  <UploadIcon fontSize="large" color="blue" />
                  <h4 className="text-h4 u-content-spacing-top">Drag and drop, or upload your deck in PDF format.</h4>
                </Uploader>
              )}
            </Dropzone>
          </div>
          <p className="u-content-spacing-top text-small u-section-spacing-bottom">
            Disclaimer: Data is sent to ChatGPT for crunching. If you dont want to use this AI powered profile helper to
            match with investors,{' '}
            <a className="text-link-small" onClick={onUseManualInput}>
              click here to get started manually.
            </a>
          </p>
        </div>
      </Content>
      <DialogActions align="space-between">
        <Button kind="tertiary" onClick={onUseManualInput}>
          Back to manual input
        </Button>
      </DialogActions>
    </>
  );
}
