import styled from '@emotion/styled';
import React from 'react';

const Wrapper = styled.div`
  & > span {
    position: relative;
    line-height: 2;
    bottom: 10px;
  }
`;

export default function TextAvatarGroup({ className, children }: { className?: string; children: React.ReactNode }) {
  return <Wrapper className={className}>{children}</Wrapper>;
}
