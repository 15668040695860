import uniq from 'ramda/src/uniq';

import { fetchCompanyProfile, fetchCompanyProfiles } from 'globalstate/reducers/companyProfiles';

import { TAction } from 'types';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';

export const getCompanyProfile = (companyId: number): TAction => {
  return (dispatch, getState) => {
    const loaded = getState().companyProfiles.items.some(u => u.id === companyId);
    if (loaded) {
      return;
    }
    companiesApi.companyProfile.get(companyId).then(result => {
      dispatch(fetchCompanyProfile(result));
    });
  };
};

export const getCompanyProfiles = (companyIds: number[]): TAction => {
  return (dispatch, getState) => {
    const uniqueIds = uniq(companyIds);
    const notLoaded = uniqueIds.filter(id => !getState().companyProfiles.items.find(u => u.id === id));

    if (notLoaded.length !== 0) {
      companiesApi.companyProfile.listCompaniesByIds(notLoaded).then(result => {
        dispatch(fetchCompanyProfiles(result));
      });
    }
  };
};
