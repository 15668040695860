import { FormikProps } from 'formik';
import React from 'react';
import Button from 'ui/elements/buttons/Button';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { CompanyUpdateFormValues } from 'domain/Updates/CompanyUpdate/UpdateForm/types';
import { PostUpdateTo } from 'types/content';

export default function UpdateSettings(props: {
  onClose: () => void;
  setFieldValue: FormikProps<CompanyUpdateFormValues>['setFieldValue'];
  values: FormikProps<CompanyUpdateFormValues>['values'];
  setPostUpdateTo: (postUpdateTo: PostUpdateTo) => void;
}) {
  const isOwnersInformation = props.values.category === 'owners information';

  return (
    <>
      <Content>
        <div>
          <Checkbox
            color="primary"
            helperText={`Mark update as critical. This will notify all your shareholders, including
        those who has unsubscribed from general updates. The update will only be available for shareholders and board members.`}
            checked={isOwnersInformation}
            label="Critical information"
            onChange={() => {
              props.setFieldValue('category', isOwnersInformation ? 'news' : 'owners information');
              props.setPostUpdateTo({ type: 'company', visibility: 'Private' });
            }}
          />
        </div>
      </Content>
      <DialogActions>
        <Button kind="primary" onClick={props.onClose}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
}
