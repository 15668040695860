import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function EllipsisVertical(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path strokeWidth="2.5" d="M12 7h0m0 5h0m0 5h0" />
    </SvgIcon>
  );
}
