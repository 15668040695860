import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

export type CompanyFeature = 'boardroom' | 'goals' | 'documents' | 'cap-table';

export function useCompanyFeatureToggles(companySlug: string, canSeeFeatures: boolean) {
  const swrResult = useSWR<CompanyFeature[]>(canSeeFeatures ? companiesAPIUrls.features.list(companySlug) : undefined);

  return useSWRResource(swrResult);
}
