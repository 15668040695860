import { useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import Bar from 'react-top-loading-bar';

interface Props {
  isComplete: boolean;
}

export default function LoadingBar({ isComplete }: Props) {
  const theme = useTheme();

  // Increment the loading bar after 50ms + 50ms more for each time, to 60 then by 5 percent each time until 95
  const [{ n, progress }, setProgress] = React.useState({ n: 1, progress: 0 });
  useEffect(() => {
    if (progress <= 95) {
      setTimeout(() => {
        setProgress({ n: n + 1, progress: 60 + n * 5 });
      }, 50 * n);
    }
  }, [progress]);

  return (
    <Bar
      height={3}
      color={theme.bluePlanetPalette.blue.main}
      shadow={false}
      loaderSpeed={300}
      progress={isComplete ? 100 : progress}
      waitingTime={300}
    />
  );
}
