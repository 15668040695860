import React from 'react';
import { Industry, Stage } from 'types';
import IndustryPicker from './IndustryPicker';
import StagePicker from './StagePicker';

interface Props {
  companyId: number;
  industries: Industry[];
  productStage?: Stage;
  onIndustryChange: (newState: Industry[]) => void;
  onStageChange: (newState: Stage) => void;
  autoFocusField?: IndustryAndStageField;
}

export type IndustryAndStageField = 'product-stage' | 'industry';

export default function CompanyIndustryAndStagePicker({
  industries,
  productStage,
  onIndustryChange,
  onStageChange,
  autoFocusField,
}: Props) {
  return (
    <>
      <div className="u-content-spacing-bottom">
        <IndustryPicker
          label="What industries are you involved in?"
          autoFocus={autoFocusField === 'industry'}
          selectedIndustries={industries}
          setIndustries={onIndustryChange}
        />
      </div>
      <StagePicker
        label="Current stage"
        autoFocus={autoFocusField === 'product-stage'}
        productStage={productStage}
        onChange={onStageChange}
        industries={industries}
      />
    </>
  );
}
