import { Location } from 'apis/MapboxAPI';

const isNotEmpty = (it?: string) => it != null && it != '';

export function formatLocation(location: Location) {
  const cityAndRegion = [location.place, location.region].filter(isNotEmpty);

  // If the place (city) and region have the same name, we only want to show it once.
  const uniqueCityAndRegion = [...new Set(cityAndRegion)].join(', ');

  if (location.country) {
    return `${uniqueCityAndRegion}, ${location.country}`;
  }

  return uniqueCityAndRegion;
}

export function formatShortLocation(location?: Location) {
  return location ? [location.place, location.country].filter(isNotEmpty).join(', ') : '';
}
