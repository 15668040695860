import { Organization } from 'types/organization';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import React, { useState } from 'react';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import { invalidateStartsWith } from 'hooks/useSWR';
import { EmailInput } from 'domain/shared/EmailInput';

interface AddConnectionDialogProps {
  organization: Organization;
  onClose: () => void;
}

export default function AddConnectionDialog(props: AddConnectionDialogProps) {
  const [validEmails, setValidEmails] = useState<string[]>([]);

  const notify = useNotify();

  const [inviteUsers, isLoading] = useLazyResource(
    (emails: string[]) => OrganizationAPI.inviteUsers(props.organization.id, emails),
    {
      onSuccess: () => {
        invalidateStartsWith([OrganizationAPI.listInvitesUrl(props.organization.id)]);
        props.onClose();
        notify('success', `Invitations sent`);
      },
      onFailure: () => {
        notify('error', `Something unexpected happened when trying to invite users.`);
      },
    },
  );

  return (
    <Dialog open={true} onClose={props.onClose} backdropColor="light" maxWidth="sm">
      <MobileMargin>
        <Title style={{ paddingBottom: 0 }} onClose={props.onClose}>
          Add connection to your organization
        </Title>
        <Content>
          <div className="u-flex u-half-spacing-top" style={{ alignItems: 'flex-start' }}>
            <div className="u-flex u-flex-grow u-flex--column">
              <EmailInput validEmails={validEmails} setValidEmails={setValidEmails} />
            </div>
          </div>
        </Content>
        <DialogActions>
          <Button kind="primary" isLoading={isLoading} onClick={() => inviteUsers(validEmails)}>
            Invite
          </Button>
          <Button kind="tertiary" onClick={props.onClose}>
            Cancel
          </Button>
        </DialogActions>
      </MobileMargin>
    </Dialog>
  );
}
