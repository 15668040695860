import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function BulbIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M5.1 14A8 8 0 0 1 12 2a8 8 0 0 1 6.9 12" />
      <path d="M14 10c-.6.6-1.3 1-2 1s-1.4-.4-2-1" />
      <path d="M7.4 17.1c-.1-.3-.2-.4-.1-.5 0-.3.1-.5.3-.6H16.4c.2.1.3.3.3.6v.5l-.5 1a2 2 0 0 1-1.2.8l-1 .1H9a2 2 0 0 1-1.2-1c-.2-.1-.2-.4-.4-.9Z" />
      <path d="m15 19-.1.6c-.2.8-.2 1.1-.4 1.4a2 2 0 0 1-1.1.9L12 22l-1.4-.1a2 2 0 0 1-1.1-1c-.2-.2-.2-.5-.4-1.3L9 19" />
      <path d="M12 15.5V11" />
    </SvgIcon>
  );
}
