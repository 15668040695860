import React, { FocusEventHandler, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import TextField, { Props as TextFieldProps } from '../TextField';

const decimalRegex = /^-?[0-9\b]+(\.[0-9]{0,2})?$/;

interface Props {
  name?: string;
  autoFocus?: boolean;
  error?: boolean;
  placeholder?: string;
  helperText?: React.ReactNode;
  getInputRef?: (ref1: HTMLInputElement) => void;
  value?: string | number | null;
  prefix?: string;
  suffix?: React.ReactNode;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler;
  nullable?: boolean;
  ariaLabelledBy?: string;
  style?: React.CSSProperties;
  className?: string;
}

export default function DecimalInput(props: Props) {
  const inputElement = useMemo(
    () =>
      function CustomDecimalInput(p: TextFieldProps) {
        return <TextField {...p} prefix={props.prefix} suffix={props.suffix} />;
      },
    [props.prefix],
  );

  // Formatting doesn't work for strings, so convert it if possible (but not for null or empty)
  const valueAsNumber = typeof props.value === 'number' ? props.value : Number(props.value);
  const value = props.value && !isNaN(valueAsNumber) ? valueAsNumber : props.value;

  return (
    <NumericFormat
      aria-labelledby={props.ariaLabelledBy}
      name={props.name}
      customInput={inputElement}
      autoFocus={props.autoFocus}
      getInputRef={props.getInputRef}
      value={value}
      thousandSeparator={' '}
      decimalSeparator={'.'}
      decimalScale={2}
      onFocusCapture={(e: any) => (typeof e.target.select === 'undefined' ? null : e.target.select())} // type broken with react 18 upgrade
      placeholder={props.placeholder}
      onKeyUp={props.onKeyUp}
      helperText={props.helperText}
      className={props.className}
      error={props.error}
      onChange={(e: any) => {
        // type broken with react 18 upgrade
        const newValue = e.target.value.replace(/\s/g, ''); // remove whitespace
        if (e.target.value === '' || decimalRegex.test(newValue)) {
          props.onChange(newValue);
        }
      }}
      onValueChange={(values, _) => {
        if (props.nullable && values.value === '') {
          props.onChange('');
        }
      }}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      autoComplete="not-active" // arbitrary string input will disable autoComplete, but using "off" will not.
      style={props.style}
    />
  );
}
