import useSWR, { invalidate } from 'hooks/useSWR';
import { PaginatedResult } from 'types/api';
import { DocumentDTO, FolderDTO, UnreadDocumentDTO } from 'types/documents';
import { useSWRResource } from 'util/resource/useSWRResource';
import { documentsUrls } from '.';
import { getUserProfiles } from 'actions/userProfiles';
import { IResource } from 'util/resource';
import { useEffect } from 'react';

export const documentCenterDocumentsKey = (companyId: number, folderId: string) =>
  documentsUrls.documentCenter.listDocuments(companyId, folderId);
export const documentCenterFoldersKey = (companyId: number) => documentsUrls.documentCenter.listFolders(companyId);
export const unreadDocumentsKey = (companyId: number) => documentsUrls.documentCenter.listUnread(companyId);

export const useDocumentCenterFolders = (companyId: number) => {
  const swrResult = useSWR<{ folders: FolderDTO[] }>(documentCenterFoldersKey(companyId));

  return useSWRResource(swrResult);
};

export const useUnreadDocuments = (companyId?: number) => {
  const swrResult = useSWR<PaginatedResult<UnreadDocumentDTO>>(
    companyId != null ? unreadDocumentsKey(companyId) : undefined,
  );

  return useSWRResource(swrResult);
};

export const refetchUnreadDocuments = (companyId: number) => {
  invalidate(unreadDocumentsKey(companyId));
};

export function useDocumentCenterDocuments(
  companyId: number,
  folderId: string | undefined,
  _getUserProfiles: typeof getUserProfiles,
): { resource: IResource<{ documents: DocumentDTO[] }> } {
  const swrResult = useSWR<{ documents: DocumentDTO[] }>(
    folderId ? documentCenterDocumentsKey(companyId, folderId) : undefined,
  );
  const { resource: foldersResource, mutate } = useDocumentCenterFolders(companyId);

  const swrResource = useSWRResource(swrResult);
  useEffect(() => {
    if (swrResource.resource.state === 'fetched') {
      _getUserProfiles(swrResource.resource.resource.documents.map(doc => doc.createdByCwUserId));
      refetchUnreadDocuments(companyId);
      if (foldersResource.state === 'fetched') {
        // Update the unread count on the folder
        mutate({
          folders: foldersResource.resource.folders.map(folder =>
            folder.id === folderId ? { ...folder, personalization: { numUnreadDocuments: 0 } } : folder,
          ),
        });
      }
    }
  }, [swrResource.resource.state]);

  if (!folderId) {
    return { resource: { state: 'fetched', resource: { documents: [] }, isValidating: swrResult.isValidating } };
  }

  return swrResource;
}
