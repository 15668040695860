import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';
import { IconButton } from '@mui/material';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { useCloseableMessage } from 'apis/CompanyAPI/users/useUiState';

interface Props {
  dismissKey?: string;
  className?: string;
  children: Children;
  icon?: JSX.Element;
  confirmButton?: JSX.Element;
  dismissButton?: JSX.Element;
  title?: string;
  color?: 'yellow' | 'blue' | 'blue-light' | 'indigo' | 'white';
  layout?: 'row' | 'column';
  dismissOnConfirm?: boolean;
}

export default function InfoMessage(props: Props) {
  const {
    isClosed: isMessageDismissed,
    closeMessage: handleDismiss,
    isLoading,
  } = useCloseableMessage(`message-is-read-${props.dismissKey}`);

  return !isLoading && !isMessageDismissed ? (
    <div
      className={cx('u-fade-in--quick', props.className, styles.infoMessage, {
        [styles.infoMessageWhite]: props.color === 'white',
        [styles.infoMessageYellow]: props.color === 'yellow',
        [styles.infoMessageIndigo]: props.color === 'indigo',
        [styles.infoMessageBlueLight]: props.color === 'blue-light',
      })}
    >
      {props.icon && (
        <div
          className={cx(styles.iconWrapper, {
            [styles.iconWrapperYellow]: props.color === 'yellow',
            [styles.iconWrapperWhite]: props.color === 'white',
            [styles.iconWrapperBlueLight]: props.color === 'blue-light',
            [styles.columnLayout]: props.layout === 'column',
          })}
        >
          {props.icon && props.icon}
        </div>
      )}
      <div
        className={cx('u-flex-space-between u-flex-align-center', {
          [styles.columnLayout]: props.layout === 'column',
        })}
      >
        <div style={{ flex: 1 }} className={props.confirmButton ? 'u-half-spacing-right' : ''}>
          <div className={styles.title}>{props.title}</div>
          <div>{props.children}</div>
        </div>
        <ButtonList>
          <div onClick={props.dismissOnConfirm ? handleDismiss : undefined}>{props.confirmButton}</div>
          {props.dismissKey &&
            (props.dismissButton ? (
              React.cloneElement(props.dismissButton, { onClick: handleDismiss })
            ) : (
              <IconButton color="inherit" onClick={handleDismiss} className="u-quarter-spacing-left" aria-label="Close">
                <CloseIcon />
              </IconButton>
            ))}
        </ButtonList>
      </div>
    </div>
  ) : null;
}
