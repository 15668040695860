import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function WarningIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M5.3 9.7c2.4-4.3 3.6-6.4 5.3-7 1-.3 1.9-.3 2.8 0 1.7.6 2.9 2.7 5.3 7s3.6 6.4 3.2 8.1c-.2 1-.7 1.9-1.4 2.5-1.3 1.2-3.7 1.2-8.5 1.2s-7.2 0-8.5-1.2A4.7 4.7 0 0 1 2 17.8c-.4-1.7.8-3.8 3.2-8.1Z" />
      <path strokeWidth="2" d="M12 16h0" />
      <path d="M12 13V9" />
    </SvgIcon>
  );
}
