import React, { useState } from 'react';
import useNotify from 'hooks/useNotify';
import ButtonList from 'ui/elements/buttons/ButtonList';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import Button from 'ui/elements/buttons/Button';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { Organization, Service } from 'types/organization';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';

export default function OrganizationServicesForm({
  services,
  organization,
  onComplete,
  onClose,
  onBack,
}: {
  services: Service[];
  organization: Organization;
  onComplete: () => void;
  onClose: () => void;
  onBack: () => void;
}) {
  const notify = useNotify();

  const [selectedServices, setSelectedServices] = useState<Service[]>(
    organization.services.length > 0 ? organization.services : [],
  );

  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(organization, {
    onSuccess: () => {
      onComplete();
    },
    onFailure() {
      notify('error', 'Failed to update organization services');
    },
  });

  return (
    <>
      <Content>
        <SelectCloud
          options={services}
          values={selectedServices}
          onSelect={s => setSelectedServices([...selectedServices, s])}
          onRemove={s => setSelectedServices(selectedServices.filter(serv => serv !== s))}
        />
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button
            isLoading={isUpdatingOrganization}
            onClick={() =>
              updateOrganization({
                services: selectedServices.map(s => s.id),
              })
            }
            kind="primary"
          >
            Next
          </Button>
          <Button onClick={onBack} kind="primary" color="greyLight">
            Back
          </Button>
        </ButtonList>
        <Button onClick={onClose} kind="tertiary" color="grey">
          Pick it up later
        </Button>
      </DialogActions>
    </>
  );
}
