import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function AddIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 8v8m4-4H8" />
      <path d="M22 12a10 10 0 1 0-20 0 10 10 0 0 0 20 0Z" />
    </SvgIcon>
  );
}
