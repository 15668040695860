import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function TrashIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m19.5 5.5-.6 10c-.2 2.6-.3 3.9-.9 4.8a4 4 0 0 1-1.2 1.1c-1 .6-2.2.6-4.8.6-2.6 0-3.9 0-4.8-.6A4 4 0 0 1 6 20.3c-.7-1-.7-2.2-.9-4.8l-.6-10M3 5.5h18m-5 0-.6-1.4c-.5-1-.7-1.4-1.1-1.7l-.3-.2c-.4-.2-1-.2-2-.2s-1.6 0-2 .2a2 2 0 0 0-.3.2c-.4.3-.6.8-1 1.8L8 5.5" />
    </SvgIcon>
  );
}
