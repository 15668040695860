import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function TurnRightIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3.715 19.387C3.715 13.098 9.06 9 15.653 9H19" />
      <path d="m15 4 5 5-5 5h0" />
    </SvgIcon>
  );
}
