import React, { useEffect, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import { SelfUserProfile, PatchUserPayload } from 'types/user';
import { Industry } from 'types';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { saveUser } from 'actions/userProfiles';
import { useIndustries } from 'apis/CompanyAPI/labels/useIndustries';
import Resource from 'util/resource/Resource';
import { notEmpty } from 'util/arrayUtils';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import ButtonList from 'ui/elements/buttons/ButtonList';
import MaxWidth from 'ui/views/containers/MaxWidth';
import IndustryPicker from 'domain/labels/industries/IndustryPicker';
import { USER_WIZARD_CONTENT_MIN_HEIGHT } from '../UserOnboardingWizard';

interface OwnProps {
  onComplete: () => void;
  closeWizard?: () => void;
  onStart?: () => void;
  onBack?: () => void;
  userProfile: SelfUserProfile;
}

const predefinedIndustryNames = [
  'software',
  'ai',
  'fintech',
  'renewable energy',
  'education',
  'health',
  'b2b',
  'b2c',
  'saas',
  'technology',
  'sustainability',
  'consumer',
];

export default function InvestorPreferenceForm({ onStart, onBack, closeWizard, onComplete, userProfile }: OwnProps) {
  const notify = useNotify();

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  const { resource: allIndustries } = useIndustries();
  const [selectedIndustries, setSelectedIndustries] = useState<Industry[]>(userProfile.industryInvestmentPreferences);

  const [updateUser, isSaving] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onSuccess: () => {
      onComplete();
    },
    onFailure: () => notify('error', `Unable to save changes`),
  });

  return (
    <Resource resource={allIndustries}>
      {allIndustries => {
        const predefinedIndustries = predefinedIndustryNames
          .map(ind => allIndustries.parents.find(industry => industry.name.toLowerCase() === ind))
          .filter(notEmpty);
        return (
          <>
            <Content style={{ minHeight: USER_WIZARD_CONTENT_MIN_HEIGHT }}>
              <SelectCloud
                options={predefinedIndustries}
                values={selectedIndustries}
                onSelect={industry => setSelectedIndustries([...selectedIndustries, industry])}
                onRemove={industry => setSelectedIndustries(selectedIndustries.filter(ind => ind.id !== industry.id))}
                customInputComponent={
                  <MaxWidth width="xs">
                    <IndustryPicker
                      className="u-half-spacing-top"
                      autoFocus
                      selectedIndustries={selectedIndustries}
                      setIndustries={industries => setSelectedIndustries(industries)}
                      hideSelectedValues
                    />
                  </MaxWidth>
                }
              />
            </Content>
            <DialogActions align="space-between">
              <ButtonList>
                <Button
                  isLoading={isSaving}
                  onClick={() => updateUser({ industryInvestmentPreferences: selectedIndustries.map(it => it.id) })}
                  kind="primary"
                >
                  {closeWizard ? 'Next' : 'Update'}
                </Button>
                {onBack && (
                  <Button kind="primary" onClick={onBack} color="greyLight">
                    Back
                  </Button>
                )}
              </ButtonList>
              {closeWizard && (
                <Button onClick={closeWizard} kind="tertiary" color="grey">
                  Pick it up later
                </Button>
              )}
            </DialogActions>
          </>
        );
      }}
    </Resource>
  );
}
