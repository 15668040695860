import { CompanyViewAs } from 'domain/companies/roleUtils';
import React, { ReactNode, useState } from 'react';
import { PitchDTO } from 'types/company';
import SectionHeading from 'ui/elements/SectionHeading';
import EditAction from 'ui/elements/actions/EditAction';
import CtaButton from 'ui/elements/buttons/CtaButton';
import HorizontalDictionary from 'ui/elements/dictionary/HorizontalDictionary';
import Card from 'ui/views/cards/Card';
import { formatCurrency } from 'util/currency';
import RevenueAndValuationDialog from './RevenueAndValuationDialog';

interface Props {
  company: PitchDTO;
  reloadCompany: () => void;
  viewAs: CompanyViewAs;
}

export default function RevenueAndValuationSection({ company, reloadCompany, viewAs }: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const canViewValuation = viewAs !== 'Follower' && viewAs !== 'Visitor';
  const isAdminForCompany = viewAs === 'Admin';

  // Make sure which field triggered opening of the problem-solution dialog, so that we can focus the marker in the right input field.
  const [focusableField, setFocusableField] = useState<'revenues' | 'valuation'>('revenues');

  if (!isAdminForCompany && !company.revenues.value && !company.valuation?.value) {
    return null;
  }

  const itemsToShow = [
    {
      key: 'Revenues',
      value:
        !company.revenues.value && isAdminForCompany ? (
          <CtaButton
            onClick={() => {
              setIsDialogOpen(true);
              setFocusableField('revenues');
            }}
          >
            Add revenues
          </CtaButton>
        ) : (
          <span className="u-flex-align-center text-body">{formatCurrency(false, company.revenues)}</span>
        ),
    },
    canViewValuation
      ? {
          key: 'Valuation',
          value:
            !company.valuation?.value && isAdminForCompany ? (
              <CtaButton
                onClick={() => {
                  setIsDialogOpen(true);
                  setFocusableField('valuation');
                }}
              >
                Add valuation
              </CtaButton>
            ) : (
              <span className="u-flex-align-center text-body">{formatCurrency(false, company.valuation)}</span>
            ),
        }
      : undefined,
  ].filter(it => it !== undefined) as { key: string; value: ReactNode }[];

  return (
    <div>
      <SectionHeading heading={canViewValuation ? 'Revenues and valuation' : 'Revenues'} addMobileMargin>
        {isAdminForCompany && (
          <button
            onClick={() => {
              setIsDialogOpen(true);
              setFocusableField('revenues');
            }}
            aria-label="Edit section"
          >
            <EditAction tooltip="Edit section" />
          </button>
        )}
      </SectionHeading>
      <Card>
        <HorizontalDictionary columnCount={2}>{itemsToShow}</HorizontalDictionary>
      </Card>
      <RevenueAndValuationDialog
        company={company}
        isOpen={isDialogOpen}
        focusableField={focusableField}
        onClose={() => setIsDialogOpen(false)}
        onComplete={() => reloadCompany()}
      />
    </div>
  );
}
