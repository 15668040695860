import { Radio as MuiRadio, RadioProps } from '@mui/material';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';

type RadioColor = 'blue' | 'red' | 'orange' | 'green' | 'purple' | 'yellow' | 'indigo';

interface Props {
  color?: RadioColor;
  name?: RadioProps['name'];
  disabled?: RadioProps['disabled'];
  checked?: boolean;
  icon?: RadioProps['icon'];
  checkedIcon?: RadioProps['checkedIcon'];
  onClick?: RadioProps['onClick'];
  onChange?: RadioProps['onChange'];

  onBlur?: RadioProps['onBlur'];
  value?: RadioProps['value'];
  classes?: RadioProps['classes'];
  size?: RadioProps['size'];
}

function getColors(color: Props['color']) {
  switch (color) {
    case 'red':
      return {
        color: bluePlanetTheme.bluePlanetPalette.grey.main,
        '&.Mui-checked': {
          color: bluePlanetTheme.bluePlanetPalette.red.main,
        },
      };
    case 'orange':
      return {
        color: bluePlanetTheme.bluePlanetPalette.grey.main,
        '&.Mui-checked': {
          color: bluePlanetTheme.bluePlanetPalette.orange.main,
        },
      };
    case 'yellow':
      return {
        color: bluePlanetTheme.bluePlanetPalette.grey.main,
        '&.Mui-checked': {
          color: bluePlanetTheme.bluePlanetPalette.yellow.main,
        },
      };
    case 'green':
      return {
        color: bluePlanetTheme.bluePlanetPalette.grey.main,
        '&.Mui-checked': {
          color: bluePlanetTheme.bluePlanetPalette.green.main,
        },
      };
    case 'purple':
      return {
        color: bluePlanetTheme.bluePlanetPalette.grey.main,
        '&.Mui-checked': {
          color: bluePlanetTheme.bluePlanetPalette.purple.main,
        },
      };
    case 'indigo':
      return {
        color: bluePlanetTheme.bluePlanetPalette.indigo.main,
        '&.Mui-checked': {
          color: bluePlanetTheme.bluePlanetPalette.indigo.main,
        },
      };
    default:
      return {
        color: bluePlanetTheme.bluePlanetPalette.grey.main,
        '&.Mui-checked': {
          color: bluePlanetTheme.bluePlanetPalette.blue.main,
        },
      };
  }
}

export default function Radio(props: Props) {
  const { color, ...radioProps } = props;

  return <MuiRadio sx={{ ...getColors(color) }} {...radioProps} />;
}
