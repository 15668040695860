import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function CompassIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M22 12a10 10 0 1 1-20 0 10 10 0 0 1 20 0Z" />
      <path d="m7.4 17 2.8-6.5 6.4-3.4-2.8 6.4L7.4 17Z" />
    </SvgIcon>
  );
}
