import { List } from 'types/api';
import { UserProfile } from 'types/user';

import uniqBy from 'ramda/src/uniqBy';

export enum ActionTypes {
  FETCH_SINGLE = 'userProfiles/FETCH_SINGLE',
  FETCH_LIST = 'userProfiles/FETCH_LIST',
}

type userProfilesFetched = {
  type: ActionTypes.FETCH_LIST;
  payload: List<UserProfile>;
};

type userProfileFetched = {
  type: ActionTypes.FETCH_SINGLE;
  payload: UserProfile;
};

export const fetchUserProfiles = (payload: List<UserProfile>): userProfilesFetched => ({
  payload,
  type: ActionTypes.FETCH_LIST,
});

export const fetchUserProfile = (payload: UserProfile): userProfileFetched => ({
  payload,
  type: ActionTypes.FETCH_SINGLE,
});

export type actions = userProfilesFetched | userProfileFetched;

type State = {
  items: UserProfile[];
};

const initialState: State = {
  items: [],
};

export default (state = initialState, action: actions): State => {
  switch (action.type) {
    case ActionTypes.FETCH_LIST:
      return {
        ...state,
        items: uniqBy(user => user.id, [...state.items, ...action.payload.values]),
      };
    case ActionTypes.FETCH_SINGLE:
      return {
        ...state,
        items: uniqBy(user => user.id, [...state.items, action.payload]),
      };
    default:
      return state;
  }
};
