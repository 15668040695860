import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function QrCodeIcon(props: IconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M9 2c-2.8 0-4.4.3-5.6 1.4C2.4 4.6 2.1 6.2 2 9m13-7c2.8 0 4.4.3 5.6 1.4 1 1.2 1.3 2.8 1.4 5.6m-7 13c2.8 0 4.4-.3 5.6-1.4 1-1.2 1.3-2.8 1.4-5.6M9 22c-2.8 0-4.4-.3-5.6-1.4-1-1.2-1.3-2.8-1.4-5.6" />
      <path d="M7.4 7c-.3.2-.3.7-.3 1.6 0 1 0 1.4.3 1.7m0-3.4c.3-.3.8-.3 1.7-.3 1 0 1.4 0 1.7.3m-3.4 3.4c.3.3.8.3 1.7.3 1 0 1.4 0 1.7-.3m0 0c.3-.3.3-.7.3-1.7s0-1.4-.3-1.7M16.6 17c.3-.2.3-.7.3-1.6 0-1 0-1.4-.3-1.7m0 3.4c-.3.3-.8.3-1.7.3-1 0-1.4 0-1.7-.3m3.4-3.4c-.3-.3-.8-.3-1.7-.3-1 0-1.4 0-1.7.3m0 0c-.3.3-.3.7-.3 1.7s0 1.4.3 1.7" />
      <path d="M16.9 7.6v2M7.1 16.4v-2M14.3 7.6v2M9.7 16.4v-2" />
    </SvgIcon>
  );
}
