import { useTheme, lighten } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import styles from '../styles.scss';

interface ListItemProps {
  to: string;
  icon: React.ReactElement<any>;
  primary: string;
  secondary?: string;
  onClick?: () => void;
  seen: boolean;
}

export function NotificationListItem(props: ListItemProps) {
  const { icon, primary, secondary, seen, onClick, to } = props;

  const renderLink = React.useMemo(() => {
    const comp = React.forwardRef<any, Omit<LinkProps, 'to' | 'innerRef'>>((linkProps, ref) => (
      <Link to={to} ref={ref} {...linkProps} />
    ));
    comp.displayName = 'NotificationListItemLink';
    return comp;
  }, [to]);

  const theme = useTheme();

  return (
    <ListItem
      component={renderLink}
      onClick={onClick}
      sx={{
        backgroundColor: theme.bluePlanetPalette.white,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '&:hover': {
          backgroundColor: lighten(theme.bluePlanetPalette.grey.light, 0.4),
        },
        borderBottom: `2px solid ${theme.bluePlanetPalette.grey.medium}`,
        height: '88px',
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      }}
    >
      {icon && (
        <div className={!seen ? styles.unreadMarker : undefined}>
          <ListItemIcon
            className="u-half-spacing-right"
            sx={{
              minWidth: '0',
              color: theme.palette.primary.main,
              backgroundColor: theme.bluePlanetPalette.grey.medium,
              padding: theme.spacing(1),
              borderRadius: '100%',
            }}
          >
            {icon}
          </ListItemIcon>
        </div>
      )}
      <ListItemText
        className={styles.listItemText}
        secondaryTypographyProps={{ fontWeight: '400', marginTop: theme.spacing(0.3) }}
        primary={primary}
        secondary={secondary}
        sx={{}}
      />
      <ChevronRightIcon strokeWidth={2} />
    </ListItem>
  );
}
