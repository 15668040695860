import { combineReducers, Reducer } from 'redux';
import {
  UserConversationRequestStore,
  UserConversationsStore,
  conversationRequestReducer,
  conversationsReducer,
} from './conversationList';

export interface UserStore {
  conversations: UserConversationsStore;
  conversationRequests: UserConversationRequestStore;
}

export const user: Reducer<UserStore> = combineReducers({
  conversations: conversationsReducer,
  conversationRequests: conversationRequestReducer,
});
