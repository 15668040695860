import React, { useState } from 'react';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import i18n from 'util/i18n';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { FeedbackContext } from 'types/user';
import ConfirmationDialog from 'ui/modules/dialogs/ConfirmationDialog';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export default function CommunityGroupsFeedbackDialog({ onClose, isOpen }: Props) {
  const notify = useNotify();

  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const close = () => {
    onClose();
  };

  const [sendFeedback] = useLazyResource(
    (feedbackContext: FeedbackContext) =>
      usersApi.feedback({
        feedback: undefined,
        anonymous: false,
        feedbackContext: feedbackContext,
      }),
    {
      onSuccess: (_, feedbackContext: FeedbackContext) => {
        if (feedbackContext == 'CommunityGroupFeedbackYes') {
          setConfirmationDialogOpen(true);
        } else {
          notify('success', 'Feedback received!');
        }
        close();
      },
      onFailure: () => notify('error', i18n('en').errors.send('feedback')),
    },
  );

  return (
    <>
      <Dialog open={isOpen} onClose={close} backdropColor="light">
        <Title onClose={close} fontSize="30px">
          Coming soon!
        </Title>
        <Content>
          <p className="text-weight-regular text-medium">
            We are soon launching community groups for founders to join and meet new potential investors and other
            founders. The communities are curated by trusted industry partners and have selected industries, regions and
            stages as focus. For you as a founder, this means new opportunities through a new and larger investor
            audience. Stay tuned!
          </p>
          <h5 className="text-h4 u-content-spacing-top">Do you want to be notified when it is available?</h5>
        </Content>
        <DialogActions>
          <Button
            onClick={() => {
              sendFeedback('CommunityGroupFeedbackYes');
            }}
            kind="primary"
          >
            Yes, please let me know
          </Button>

          <Button
            onClick={() => {
              sendFeedback('CommunityGroupFeedbackNo');
            }}
            kind="secondary"
          >
            No, thank you
          </Button>
          <Button onClick={close} kind="tertiary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Awesome!"
      >
        Thank you for registering your interest, we will let you know as soon as we launch the community groups!
      </ConfirmationDialog>
    </>
  );
}
