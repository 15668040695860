import React from 'react';

import Card from 'ui/views/cards/Card';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { spacingUnits } from 'ui/theme/themeBluePlanet';
import { useLoginWithRedirect } from 'auth/useLoginWithRedirect';
import Button from 'ui/elements/buttons/Button';

const Body = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  margin-top: 6rem;
  @media screen and (max-width: 1040px) {
    max-width: 920px;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  ${bluePlanetTheme.breakpoints.down('lg')} {
    margin: 4rem auto 0 auto;
  }

  ${bluePlanetTheme.breakpoints.down('md')} {
    margin: 3rem ${spacingUnits.half} 0 ${spacingUnits.half};
  }

  ${bluePlanetTheme.breakpoints.down('sm')} {
    width: auto;
    margin: 2rem ${spacingUnits.half} 0 ${spacingUnits.half};
  }

  @media (max-width: 360px) {
    margin: 1rem 0 ${spacingUnits.half};
  }
`;

interface Props {
  heading: string;
  body: JSX.Element;
  action?: JSX.Element;
}

export default function PrivatePage({ heading, body, action }: Props) {
  const { loginWithRedirect } = useLoginWithRedirect();

  return (
    <Body>
      <Card style={{ textAlign: 'center' }} className="u-section-spacing-bottom">
        <div className="u-content-spacing-y"></div>
        <h1 className="text-h2">{heading}</h1>
        <p className="text-body text-large u-section-spacing-top">{body}</p>
        {action ?? (
          <Button
            className="u-section-spacing-y"
            size="large"
            kind="primary"
            onClick={() => loginWithRedirect('login')}
          >
            Log in
          </Button>
        )}
      </Card>
    </Body>
  );
}
