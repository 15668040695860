import config from 'config';
import { notEmpty } from 'util/arrayUtils';
import { log } from 'util/logging';

function readChunks(reader: ReadableStreamDefaultReader<Uint8Array>) {
  return {
    async *[Symbol.asyncIterator]() {
      let readResult = await reader.read();
      while (!readResult.done) {
        yield readResult.value;
        readResult = await reader.read();
      }
    },
  };
}

export type OpenAiTextCompletion = { id: string; choices: { text: string }[] };

const decoder = new TextDecoder();

export const parse = (text: string): OpenAiTextCompletion | undefined => {
  try {
    if (text === '') return undefined;
    return JSON.parse(text);
  } catch (e) {
    log(`Failed to parse text to json from completion endpoint. value: ${text}`, 'error');
    return undefined;
  }
};

export interface CompletionInput {
  value: string;
  type:
    | 'Shorter'
    | 'Longer'
    | 'SimplerLanguage'
    | 'MoreEngaging'
    | 'Professional'
    | 'Casual'
    | 'Confident'
    | 'Playful'
    | 'Straightforward'
    | 'Friendly';
  maxLength?: number;
}

export const getTextCompletion = async (
  accessToken: string | undefined,
  companyId: number | undefined,
  organizationId: UUID | undefined,
  input: CompletionInput,
  onChunck: (a: OpenAiTextCompletion[]) => void,
  onComplete: (result: OpenAiTextCompletion[]) => void,
  onError: () => void,
) => {
  const result = [] as OpenAiTextCompletion[];

  await fetch(`${config.COMPANIES_API_URL}/completions/stream`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
    },

    body: JSON.stringify({ ...input, companyId, organizationId }),
  })
    .then(async response => {
      if (!response.body) return null;
      if (response.status !== 200) {
        throw new Error(`${response.status}`);
      }

      const reader = response.body.getReader();

      for await (const chunk of readChunks(reader)) {
        try {
          const text = decoder.decode(chunk).split('\n');
          const data = text.map(parse).filter(notEmpty);
          result.push(...data);
          onChunck(data);
        } catch (e) {
          log(`Failed to read stream chunck, got error: ${e}`, 'error');
        }
      }

      return new ReadableStream({
        start(controller) {
          function pump(): any {
            return reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              controller.enqueue(value);
              return pump();
            });
          }
          return pump();
        },
      });
    })
    .then(() => {
      onComplete(result);
    })
    .catch(e => {
      log(`Failed to read completion stream, got error: ${e}`, 'error');
      onError();
    });
};
