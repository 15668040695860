import useDialogHandler from 'hooks/useDialogHandler';
import React, { CSSProperties } from 'react';
import Button, { ButtonProps } from 'ui/elements/buttons/Button';
import styles from './styles.scss';
import UnfollowDialog from './UnfollowDialog';
import cx from 'classnames';
import { ICompany } from 'types/company';
import useFollowCompany from 'domain/companies/Follow/useFollowCompany';
import PlusBoxIcon from 'ui/elements/icons/PlusBoxIcon';
import CheckmarkBoxIcon from 'ui/elements/icons/CheckmarkBoxIcon';
import TimerIcon from 'ui/elements/icons/TimerIcon';

interface Props {
  mode?: 'compact' | 'regular';
  company: ICompany;
  kind?: ButtonProps['kind'];
  size?: ButtonProps['size'];
  followingText?: Children;
  pendingText?: Children;
  trackingKey: string;
  style?: CSSProperties;
  color?: 'blue' | 'indigo';
}

export default function FollowButton({
  company,
  kind = 'primary',
  size,
  style,
  followingText,
  pendingText,
  trackingKey,
  mode,
  color = 'blue',
}: Props) {
  const {
    onFollow,
    onUnfollow,
    isUnfollowing,
    isCompanyRolesFetched,
    isFollowingCompany,
    isPendingFollowerRequest,
    isSendingFollowRequest,
    hasOtherRoleThanFollower,
    pendingStateIsDisabled,
    setPendingStateIsDisabled,
  } = useFollowCompany(company);

  const unfollowDialogHandler = useDialogHandler();

  if (!isCompanyRolesFetched) {
    return null;
  }

  if (hasOtherRoleThanFollower) {
    return null;
  } else if (isFollowingCompany) {
    return (
      <>
        <Button
          className={cx(styles.unfollowButton, { [styles.largeButton]: size === 'large' })}
          size={size}
          kind={kind}
          onClick={unfollowDialogHandler.open}
          style={style}
          color="grey"
        >
          {followingText ? (
            followingText
          ) : (
            <>
              <CheckmarkBoxIcon fontSize="small" className="u-half-spacing-right" /> Following
            </>
          )}
        </Button>
        <UnfollowDialog
          dialogHandler={unfollowDialogHandler}
          onUnfollow={onUnfollow}
          isUnfollowing={isUnfollowing}
          companyName={company.name}
          requestIsPending={isPendingFollowerRequest}
        />
      </>
    );
  } else if (isPendingFollowerRequest) {
    return (
      <>
        <Button
          kind={kind}
          color="grey"
          size={size}
          onClick={unfollowDialogHandler.open}
          disabled={pendingStateIsDisabled}
          // We don't want unfollow button to appear immediately after following
          onMouseLeave={() => setPendingStateIsDisabled(false)}
          className={cx({
            [styles.pendingButton]: !pendingStateIsDisabled,
            [styles.largeButton]: size === 'large',
          })}
          style={style}
        >
          {pendingText && !pendingStateIsDisabled ? (
            pendingText
          ) : (
            <>
              <TimerIcon fontSize="small" className="u-half-spacing-right" />
              {mode === 'compact' ? 'Pending' : 'Waiting for approval'}
            </>
          )}
        </Button>
        <UnfollowDialog
          dialogHandler={unfollowDialogHandler}
          onUnfollow={onUnfollow}
          isUnfollowing={isUnfollowing}
          companyName={company.name}
          requestIsPending={isPendingFollowerRequest}
        />
      </>
    );
  } else {
    return (
      <>
        <Button
          className={cx(`data-track-${trackingKey}`, {
            [styles.largeButton]: size === 'large',
          })}
          size={size}
          kind={kind || 'primary'}
          onClick={onFollow}
          isLoading={isSendingFollowRequest}
          style={style}
          color={color}
        >
          <PlusBoxIcon fontSize="small" className="u-half-spacing-right" />
          Follow
        </Button>
        <UnfollowDialog
          dialogHandler={unfollowDialogHandler}
          onUnfollow={onUnfollow}
          isUnfollowing={isUnfollowing}
          companyName={company.name}
          requestIsPending={isPendingFollowerRequest}
        />
      </>
    );
  }
}
