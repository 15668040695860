import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function SendIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21 3C19 .8 2.5 6.6 2.5 8.7c0 2.3 6.4 3 8.2 3.6 1 .3 1.3.6 1.6 1.7 1 5 1.6 7.5 2.9 7.6 2 0 8-16.2 5.8-18.4Z" />
      <path d="M11.5 12.5 15 9" />
    </SvgIcon>
  );
}
