import { PopoverProps } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';

interface Props<T = string> {
  onSelect: (value?: T) => void;
  values: { label: string; value: T; href?: string }[];
  anchorEl: PopoverProps['anchorEl'];
  open: boolean;
  setOpen: (value: boolean) => void;
  selectedValue?: T;
}

export default function DropDown<T extends string>(props: Props<T>) {
  return (
    <Menu open={props.open} anchorEl={props.anchorEl} onClose={() => props.setOpen(false)}>
      {props.values.map(item => (
        <MenuItem
          selected={item.value === props.selectedValue}
          key={item.value}
          value={item.value}
          onClick={() => {
            props.setOpen(false);
            props.onSelect(item.value);
          }}
          className="text-weight-medium"
        >
          {item.href ? (
            <a href={item.href} target="_blank" rel="noreferrer" style={{ width: '100%', height: '100%' }}>
              {item.label}
            </a>
          ) : (
            item.label
          )}
        </MenuItem>
      ))}
    </Menu>
  );
}
