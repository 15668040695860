import InputAdornment from '@mui/material/InputAdornment';
import MaterialTextField, { TextFieldProps as MaterialTextFieldProps } from '@mui/material/TextField';
import React, { CSSProperties } from 'react';

export interface TextFieldProps {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  suffixPosition?: 'start-middle' | 'start-bottom';
  hideSuffixWhenEmpty?: boolean;
  maxLength?: number;
}

export type Props = TextFieldProps & Omit<MaterialTextFieldProps, 'prefix'>;

const TextField = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      value,
      variant, // needs to be destructured although it is not used, as it should not be part of ...textFieldProps
      inputProps,
      type,
      suffix,
      prefix,
      name,
      label,
      error,
      hideSuffixWhenEmpty,
      maxLength,
      onKeyPress,
      onChange,
      autoComplete = 'not-active', // arbitrary string input will disable autoComplete, but using "off" will not.
      suffixPosition: suffixPosition,
      sx,
      ...textFieldProps
    },
    ref,
  ) => {
    // extract non-standard textfield props from props, since ...textFieldProps is passed
    // into native text field
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      if (!onChange) return;

      if (type === 'number') {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
          onChange(e);
        }
      } else if (type === 'decimal') {
        const re = /^[0-9\b]*(\.[0-9]{0,4})?$/;

        if (e.target.value === '' || re.test(e.target.value)) {
          onChange(e);
        }
      } else {
        onChange(e);
      }
    }

    const actualSX =
      suffixPosition === 'start-bottom'
        ? {
            ...sx,
            '.MuiInputBase-root': {
              paddingBottom: '54px', // buttom height + spacing
              position: 'relative',
            },
          }
        : sx;

    const suffixStyle: CSSProperties | undefined =
      suffixPosition === 'start-bottom' ? { position: 'absolute', bottom: '1.875rem', left: 0 } : undefined;

    return (
      <MaterialTextField
        {...textFieldProps}
        sx={actualSX}
        ref={ref}
        value={value}
        name={name}
        label={label}
        variant="standard"
        id={`${name}-input`}
        fullWidth
        onChange={handleChange}
        type={type === 'number' || type === 'decimal' ? 'text' : type}
        error={!!error}
        autoComplete={autoComplete}
        inputProps={{
          maxLength,
        }}
        InputProps={{
          style: inputProps,
          onKeyPress,
          disableUnderline: true,
          ...(prefix
            ? {
                startAdornment: (
                  <InputAdornment position="start" disableTypography style={{ zIndex: 0 }}>
                    {prefix}
                  </InputAdornment>
                ),
              }
            : {}),
          ...(suffix && (!hideSuffixWhenEmpty || value)
            ? {
                endAdornment: (
                  <InputAdornment style={suffixStyle} position="end" disableTypography>
                    {suffix}
                  </InputAdornment>
                ),
              }
            : {}),
        }}
        InputLabelProps={
          label
            ? {
                shrink: true,
                disableAnimation: true,
                style: { position: 'relative', transform: 'none' },
              }
            : undefined
        }
      />
    );
  },
);

TextField.displayName = 'TextField';
export default TextField;
