import ChevronLeftRounded from 'ui/elements/icons/ChevronLeftIcon';
import React from 'react';
import { Link } from 'react-router-dom';
import EmptyIllustration from 'ui/illustrations/empty.svg';
import { Hidden } from '@mui/material';

export default function NotFound() {
  return (
    <div className="u-align-center u-section-padding-y">
      <h1 className="text-h1 u-content-spacing-bottom">404</h1>
      <h2 className="text-h2 u-content-spacing-bottom">Did you lose your way from your crowd?</h2>
      <Hidden smDown>
        <h3 className="text-h3 u-content-spacing-bottom ">
          Sometimes you just have to take a step back to go forward.
        </h3>
      </Hidden>
      <p className="u-section-spacing-y u-flex-center">
        <Link to="/" className="text-link" style={{ display: 'flex', alignItems: 'center' }}>
          <ChevronLeftRounded className="u-half-spacing-right" />
          Back home
        </Link>
      </p>
      <Hidden smDown>
        <div style={{ maxWidth: '26rem', margin: '5rem auto' }}>
          <img src={EmptyIllustration} />
        </div>
      </Hidden>
    </div>
  );
}
