import { getUserProfiles } from 'actions/userProfiles';
import roomUpdateAPI from './RoomUpdateAPI';
import { UpdatesOptions } from 'actions/content/updates';
import { notify } from 'actions/snackbar';
import { CreateRoomUpdateDTO, RoomUpdateDTO } from './types';

export const listUpdates = async (roomId: string, options: UpdatesOptions, getProfiles: typeof getUserProfiles) => {
  const resp = await roomUpdateAPI(roomId).list(options.paginationOptions);
  getProfiles(resp.values.map(u => u.update.creatorCwUserId));
  return resp;
};

export const likeUpdate = async (roomId: string, updateId: string, hasLiked: boolean) => {
  if (hasLiked) {
    await roomUpdateAPI(roomId).likes.unLike(updateId);
  } else {
    await roomUpdateAPI(roomId).likes.like(updateId);
  }
};

export const createUpdate =
  (_notify: typeof notify, roomId: string, onComplete?: (content: RoomUpdateDTO) => void) =>
  async (dto: CreateRoomUpdateDTO) => {
    try {
      const content = await roomUpdateAPI(roomId).create(dto);
      if (onComplete) {
        onComplete(content);
      }
      _notify('success', 'Update has been posted');
    } catch (error) {
      _notify('error', 'Something went wrong when creating update');
    }
  };

export const editUpdate = async (
  _notify: typeof notify,
  roomId: string,
  onComplete: (contentId: string, content: RoomUpdateDTO) => void,
  contentId: string,
  dto: CreateRoomUpdateDTO,
) => {
  try {
    const result = await roomUpdateAPI(roomId).editUpdate(contentId, dto);
    onComplete(contentId, result);
    _notify('success', 'Post updated!');
    return result;
  } catch (error) {
    _notify('error', 'Something went wrong when trying to update post');
    return null;
  }
};

export const deleteUpdate = async (
  _notify: typeof notify,
  roomId: string,
  contentId: string,
  onComplete: () => void,
) => {
  try {
    const result = await roomUpdateAPI(roomId).delete(contentId);
    onComplete();
    _notify('success', 'Post deleted!');
    return result;
  } catch (error) {
    _notify('error', 'Something went wrong when trying to delete post');
    return null;
  }
};
