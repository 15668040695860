import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CrowdWorksLogo(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M6.5 16.4a2.3 2.3 0 1 1-4.7 0 2.3 2.3 0 0 1 4.7 0ZM14.3 16.4a2.3 2.3 0 1 1-4.6 0 2.3 2.3 0 0 1 4.6 0ZM22.2 16.4a2.3 2.3 0 1 1-4.7 0 2.3 2.3 0 0 1 4.7 0Z"
        clipRule="evenodd"
      />
      <path d="M5.6 12a2.3 2.3 0 0 1 4.7 0M13.5 12a2.3 2.3 0 1 1 4.7 0M9.6 7.6a2.3 2.3 0 0 1 4.7 0" />
    </SvgIcon>
  );
}
