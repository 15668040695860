import { IResource } from 'util/resource';
import { HttpErrorType } from 'pages/common/error/helpers';
import { addPage, updateInList } from 'util/paginationUtils';
import { PaginatedResult } from 'types';

export type GenericPaginatedStore<T> = IResource<PaginatedResult<T>>;

export type FetchingAction = {
  type: string;
};

export type FetchedAction<T> = {
  type: string;
  payload: T;
};

export type FetchedPageAction<T> = {
  type: string;
  payload: T;
};

export type RemovedAction<T> = {
  type: string;
  payload: {
    id: any;
    selector: (item: T) => any;
  };
};

export type UpdatedAction<T> = {
  type: string;
  payload: {
    id: any;
    transform: (prev: T) => T;
  };
};

export type FetchFailedAction = {
  type: string;
  errorType?: HttpErrorType;
};

// We are unable to correctly type usages of these actions
// as they are generated runtime.
// if you rename any of these, reducers that rely on them
// will break silently.

const createActionTypes = (name: string) => ({
  fetching: `fetching ${name}`,
  fetched: `${name} fetched`,
  fetchedPage: `${name} fetched page`,
  updateItem: `${name} item updated`,
  fetchFailed: `fetching ${name} failed`,
});

export const createActions = <T>(name: string) => {
  const types = createActionTypes(name);
  return {
    fetching: (): FetchingAction => ({
      type: types.fetching,
    }),
    fetched: (t: PaginatedResult<T>): FetchedAction<PaginatedResult<T>> => ({
      type: types.fetched,
      payload: t,
    }),
    fetchedPage: (t: PaginatedResult<T>): FetchedPageAction<PaginatedResult<T>> => ({
      type: types.fetchedPage,
      payload: t,
    }),
    updated: (id: any, transform: (prev: T) => T): UpdatedAction<T> => ({
      type: types.updateItem,
      payload: { id, transform },
    }),
    fetchedFailed: (): FetchFailedAction => ({
      type: types.fetchFailed,
    }),
  };
};

export type Actions<T> =
  | FetchingAction
  | FetchedAction<T>
  | FetchFailedAction
  | FetchedPageAction<T>
  | UpdatedAction<T>;

export function createGenericPaginatedReducer<T, V>(name: string, uniqueFunc: (t: T) => V) {
  const initialState: GenericPaginatedStore<T> = {
    state: 'idle',
  };

  const actionTypes = createActionTypes(name);

  const reducer = (
    state: IResource<PaginatedResult<T>> = initialState,
    action: Actions<PaginatedResult<T>>,
  ): GenericPaginatedStore<T> => {
    switch (action.type) {
      case actionTypes.fetching:
        return { state: 'fetching' };
      case actionTypes.fetched:
        return {
          state: 'fetched',
          resource: (action as FetchedAction<PaginatedResult<T>>).payload,
          isValidating: false,
        };
      case actionTypes.fetchedPage:
        if (state.state === 'fetched') {
          return {
            state: 'fetched',
            resource: addPage(state.resource, (action as FetchedAction<PaginatedResult<T>>).payload, uniqueFunc),
            isValidating: false,
          };
        }
        return state;
      case actionTypes.updateItem:
        if (state.state === 'fetched') {
          const v = action as UpdatedAction<T>;
          return {
            state: 'fetched',
            resource: updateInList(state.resource, (i: T) => v.payload.id === uniqueFunc(i), v.payload.transform),
            isValidating: false,
          };
        }
        return state;
      case actionTypes.fetchFailed:
        return {
          state: 'error',
          errorType: 'Error',
        };
      default:
        return state;
    }
  };

  return {
    reducer,
    actions: createActions<T>(name),
  };
}
