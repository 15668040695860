import React, { ReactNode, useState } from 'react';
import { AllIndustries, Industry } from 'types';
import Label from 'ui/elements/form/Label';
import MultiSelect from 'ui/elements/form/Select/MultiSelect';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import uniqBy from 'ramda/src/uniqBy';
import { useIndustries } from 'apis/CompanyAPI/labels/useIndustries';
import Fuse from 'fuse.js';
import Resource from 'util/resource/Resource';
import { useDebouncedCallback } from 'use-debounce';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';

interface Props {
  label?: string;
  selectedIndustries: Industry[];
  setIndustries: (industries: Industry[]) => void;
  error?: string | ReactNode;
  autoFocus?: boolean;
  hideSelectedValues?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${bluePlanetTheme.spacing(1)};

  > * {
    width: calc(50% - ${bluePlanetTheme.spacing(1)});
    flex-grow: 1;
  }

  ${bluePlanetTheme.breakpoints.down('sm')} {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
`;

export function getIndustryFuse(industries: Industry[]) {
  return new Fuse(industries, {
    keys: [
      { name: 'name', weight: 2 },
      { name: 'tags', weight: 1 },
    ],
    threshold: 0.3,
  });
}

function Picker({
  label,
  selectedIndustries,
  setIndustries,
  error,
  autoFocus,
  industries: allIndustries,
  hideSelectedValues,
  style,
  className,
}: Props & {
  industries: AllIndustries;
}) {
  const [input, setInput] = useState('');

  const [options, setOptions] = useState<Industry[]>(allIndustries.parents);
  const fuse = getIndustryFuse([...allIndustries.parents, ...allIndustries.children]);

  const onAdd = (industries: Industry[], selectedIndustry?: Industry) => {
    companiesApi.industries.search(input, options.length, selectedIndustry?.name);
    setIndustries(uniqBy(it => it.id, [...selectedIndustries, ...industries]));
  };
  const onRemove = (industry: Industry) => setIndustries(selectedIndustries.filter(v => v !== industry));

  const delayLogSearchResult = useDebouncedCallback(() => {
    if (options.length === 0 && input) {
      // log search query to backend
      companiesApi.industries.search(input, 0);
    }
  }, 2500);

  const onInputChange = (value: string) => {
    setInput(value);
    // If the input is empty, we want to show all the parent industries
    if (!value) {
      setOptions(allIndustries.parents);
      return;
    }

    const result = fuse.search(value, { limit: 10 });
    setOptions(result.map(r => r.item));
    if (result.length === 0) {
      // log search query to backend
      delayLogSearchResult();
    }
  };

  const renderValue = !hideSelectedValues ? IndustryChip : () => <></>;

  return (
    <div style={style} className={className}>
      {label && <Label>{label}</Label>}
      <SelectWrapper>
        <MultiSelect
          name="parentIndustries"
          placeholder="Select industries"
          onChange={onAdd}
          onRemove={onRemove}
          getOptionLabel={industry => industry?.name || ''}
          onInputChange={onInputChange}
          options={options}
          error={error}
          helperText={error?.toString()}
          autoFocus={autoFocus}
          values={selectedIndustries}
          renderValue={renderValue}
          disableFilter
        />
      </SelectWrapper>
    </div>
  );
}

export default function IndustryPicker(props: Props) {
  const { resource: allIndustriesResource } = useIndustries();

  return (
    <Resource resource={allIndustriesResource}>
      {allIndustries => <Picker industries={allIndustries} {...props} />}
    </Resource>
  );
}
