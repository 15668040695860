import React from 'react';
import { useTheme, useMediaQuery, PopoverProps, Fade, Typography, lighten } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { toggleItemInList } from 'util/arrayUtils';
import Radio from '../form/choice/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from 'ui/elements/buttons/Button';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import Checkbox from '../form/choice/Checkbox';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';

interface Value {
  label: string;
  value: string;
}

interface Props {
  onSelect: (values: string[]) => void;
  values: Value[];
  value: string[];
  anchorEl: PopoverProps['anchorEl'];
  open: boolean;
  setOpen: (value: boolean) => void;
}

const PersistentScrollbar = styled.div`
  --scrollbar-background: ${bluePlanetTheme.bluePlanetPalette.grey.medium};
  --scrollbar-foreground: ${lighten(bluePlanetTheme.bluePlanetPalette.grey.main, 0.35)};

  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    /* Foreground */
    background: var(--scrollbar-foreground);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${bluePlanetTheme.bluePlanetPalette.grey.main};
    cursor: pointer;
  }

  /* Background */
  ::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
  }
`;

function MobileMultiSelect(props: Props & { setOpen: (value: boolean) => void }) {
  return (
    <Dialog scroll="paper" fullScreen open>
      <Title onClose={() => props.setOpen(false)}>Industries</Title>
      <Content sx={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
        {props.values.map(item => (
          <FormControlLabel
            key={item.label}
            onClick={e => {
              e.preventDefault();
              props.onSelect(toggleItemInList(props.value, item.value));
            }}
            control={<Radio size="small" color="blue" checked={props.value.includes(item.value)} />}
            label={<div>{item.label}</div>}
          />
        ))}
      </Content>
      <DialogActions>
        <Button kind="primary" onClick={() => props.setOpen(false)}>
          View results
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function DropDownMultiSelect(props: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile && props.open ? (
    <MobileMultiSelect {...props} setOpen={props.setOpen} />
  ) : (
    <Menu
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={() => props.setOpen(false)}
      TransitionComponent={Fade}
      slotProps={{ paper: { style: { display: 'flex', flexDirection: 'column' } } }}
    >
      <PersistentScrollbar
        style={{
          marginBottom: 'auto',
          maxHeight: 500,
          overflowY: 'scroll',
        }}
      >
        {props.values.map(item => (
          <MenuItem
            key={item.label}
            onClick={e => {
              e.preventDefault();
              props.onSelect(toggleItemInList(props.value, item.value));
            }}
            sx={{ pt: 0, pb: 0 }}
          >
            <Typography noWrap>
              <FormControlLabel
                sx={{
                  width: '100%',
                  marginRight: 0,
                }}
                key={item.value}
                control={
                  <Checkbox
                    checked={props.value.includes(item.value)}
                    onChange={() => {
                      props.onSelect(toggleItemInList(props.value, item.value));
                    }}
                  />
                }
                label={item.label}
              />
            </Typography>
          </MenuItem>
        ))}
      </PersistentScrollbar>
      <MenuItem
        onClick={() => props.setOpen(false)}
        style={{ borderTop: `1px solid ${bluePlanetTheme.bluePlanetPalette.grey.medium}` }}
      >
        <Button kind="primary" onClick={() => props.setOpen(false)} style={{ width: '100%' }}>
          View results
        </Button>
      </MenuItem>
    </Menu>
  );
}
