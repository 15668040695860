import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function DiscoverIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M20.5 5.3v9.1c0 3.8 0 5.7-1.2 6.9-1.1 1.1-3 1.1-6.8 1.1h-1c-3.8 0-5.7 0-6.8-1.1-1.2-1.2-1.2-3-1.2-6.9V4.5" />
      <path d="M20.5 5.2h-4.4m0 0-10.8.1c-1.4 0-1.8-.7-1.8-1.5S3.9 2.2 5.3 2l10.8-.8v4ZM14.3 15.3l2 2m-1-4.5a3.5 3.5 0 1 0-7 0 3.5 3.5 0 0 0 7 0Z" />
    </SvgIcon>
  );
}
