import { FormControl } from '@mui/material';
import React from 'react';
import { Involvement } from 'types/user';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import styles from './styles.scss';

interface Props {
  involvement: Involvement;
  onChange?: (e: React.ChangeEvent<{}>) => void;
}

export const involvementLabels = {
  provideGeneralFeedback: `I'm happy to provide general feedback`,
  openForBoardMemberPosition: `I'm open for Board Membership`,
  openForAdvisoryBoardPosition: `I'm open for Advisory Board Position`,
  openForMentorPosition: `I'm open for mentor assignments`,
  openForSweatEquityProjects: `I'm open to "sweat equity" projects`,
};

export default function UserInvolvementForm(props: Props) {
  const { involvement, onChange } = props;
  return (
    <FormControl className={styles.container}>
      <Checkbox
        color="primary"
        name="involvement.provideGeneralFeedback"
        checked={involvement.provideGeneralFeedback}
        onChange={e => onChange && onChange(e)}
        label={involvementLabels.provideGeneralFeedback}
      />
      <Checkbox
        color="primary"
        name="involvement.openForBoardMemberPosition"
        checked={involvement.openForBoardMemberPosition}
        onChange={e => onChange && onChange(e)}
        label={involvementLabels.openForBoardMemberPosition}
      />
      <Checkbox
        color="primary"
        name="involvement.openForAdvisoryBoardPosition"
        checked={involvement.openForAdvisoryBoardPosition}
        onChange={e => onChange && onChange(e)}
        label={involvementLabels.openForAdvisoryBoardPosition}
      />
      <Checkbox
        color="primary"
        name="involvement.openForMentorPosition"
        checked={involvement.openForMentorPosition}
        onChange={e => onChange && onChange(e)}
        label={involvementLabels.openForMentorPosition}
      />
      <Checkbox
        color="primary"
        name="involvement.openForSweatEquityProjects"
        checked={involvement.openForSweatEquityProjects}
        onChange={e => onChange && onChange(e)}
        label={involvementLabels.openForSweatEquityProjects}
      />
    </FormControl>
  );
}
