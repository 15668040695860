import ApiBase from 'apis';
import config from 'config';
import { Room, RoomWithUsers } from 'domain/rooms/types';
import { List } from 'types/api';
import { DocumentDownloadDTO, UploadDocumentResponse } from 'types/documents';

const api = ApiBase();
const baseUrl = config.COMPANIES_API_URL;

export const roomAPIUrls = {
  listForCompany: (companySlug: string) => `${baseUrl}/companies/${companySlug}/rooms`,
  searchUsers: (nameFilter: string, roomId: UUID) => ` ${baseUrl}/rooms/${roomId}/search?name=${nameFilter}`,
  documents: {
    list: (roomId: UUID) => `${baseUrl}/rooms/${roomId}/documents`,
  },
};

export default {
  get: (roomId: UUID) => api.get<RoomWithUsers>(`${baseUrl}/rooms/${roomId}`),
  list: (roomIds: UUID[]) => api.post<List<Room>>(`${baseUrl}/rooms/list`, { ids: roomIds }),
  patch: (roomId: string, users: UUID[], admins: UUID[]) =>
    api.patch<RoomWithUsers>(`${baseUrl}/rooms/${roomId}/users`, {
      users,
      admins,
    }),
  documents: {
    delete: (roomId: UUID, documentId: UUID) => api.deleteRequest(`${baseUrl}/rooms/${roomId}/documents/${documentId}`),
    post: (roomId: UUID, payload: { filename: string }) =>
      api.post<UploadDocumentResponse>(`${baseUrl}/rooms/${roomId}/documents`, payload),
    download: (roomId: UUID, documentId: string) =>
      api.get<DocumentDownloadDTO>(`${baseUrl}/rooms/${roomId}/documents/${documentId}`),
  },
};
