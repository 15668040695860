import BoardRoomIcon from 'ui/elements/icons/BoardIcon';
import React from 'react';
import { Scope, ICompany } from 'types/company';
import SettingsIcon from 'ui/elements/icons/SettingsIcon';
import { companyUrls } from 'urls';
import i18n from 'util/i18n';
import { hasScope } from 'util/scopeUtils';
import { NavItem } from './types';
import UserIcon from 'ui/elements/icons/UserIcon';
import { IResource, getOrElse } from 'util/resource';
import { MentorMentorship } from 'apis/MentorAPI/types';
import featureToggle from 'featureToggle';
import { Room } from 'domain/rooms/types';
import UsersIcon from 'ui/elements/icons/UsersIcon';
import EnvelopeIcon from 'ui/elements/icons/EnvelopeIcon';
import InsightsIcon from 'ui/elements/icons/InsightsIcon';
import { CompanyFeature } from 'apis/CompanyAPI/companies/useCompanyFeatureToggles';
import QrCodeIcon from 'ui/elements/icons/QrCodeIcon';
import { List } from 'types/api';

export default (
  company: ICompany,
  scopes: Scope[],
  unreadBoardroomDocuments: number,
  unreadMessagesAsCompany: number,
  mentorMentorships: IResource<List<MentorMentorship>>,
  rooms: Room[],
  companyFeatures: CompanyFeature[],
  isMdUp: boolean,
): NavItem[] => {
  const canEdit = hasScope({ action: 'Write', resource: 'Company' }, scopes);

  const canViewBoardroom =
    companyFeatures.includes('boardroom') && (hasScope({ action: 'Read', resource: 'Boardroom' }, scopes) || canEdit);
  const canViewCompanyInbox = hasScope({ action: 'Read', resource: 'Company.Inbox' }, scopes);

  const mentorMentorshipz = getOrElse(mentorMentorships, { values: [] }).values.map(mentorship => {
    const isInnovasjonNorge = featureToggle.isMentorMasterNetwork(mentorship.mentorMasterNetworkId);
    return {
      href: isInnovasjonNorge
        ? companyUrls.innovasjonNorge.mentor.overview(company.slug, mentorship.mentorMasterNetworkId)
        : companyUrls.overview(company.slug, 'updates'),
      label: isInnovasjonNorge
        ? `Mentorship: ${mentorship.programName.split(' ')[0]} ${mentorship.programName.split(' ')[1]}`
        : 'Mentorship',
      identifier: 'mentorpage',
      icon: <UserIcon />,
      isVisible: true,
    };
  });

  const roomNavItems = rooms.map(room => ({
    href: companyUrls.rooms.view(company.slug, room.id),
    label: room.name,
    identifier: room.name,
    icon: <UsersIcon />,
  }));

  const tabs: NavItem[] = [
    ...mentorMentorshipz,
    ...roomNavItems,
    {
      href: companyUrls.inbox.list(company.slug),
      label: i18n('en').contactCompany.titleAdmins,
      identifier: 'inbox',
      indicator: unreadMessagesAsCompany,
      icon: <EnvelopeIcon fontSize="small" />,
      isVisible: canViewCompanyInbox,
    },
    {
      href: companyUrls.boardroom.overview(company.slug),
      label: 'Boardroom',
      identifier: 'boardroom',
      isVisible: canViewBoardroom,
      indicator: unreadBoardroomDocuments,
      icon: <BoardRoomIcon fontSize="small" />,
    },
    {
      href: companyUrls.insights(company.slug),
      label: 'Insights',
      identifier: 'insights',
      isVisible: canEdit,
      icon: <InsightsIcon fontSize="small" />,
    },
    {
      href: companyUrls.settings.settings(company.slug),
      label: 'Settings',
      identifier: 'settings',
      isVisible: canEdit,
      icon: <SettingsIcon fontSize="small" />,
    },
    {
      href: companyUrls.settings.share(company.slug, 'qr-code'),
      label: 'Share company QR',
      identifier: 'show-qr',
      isVisible: canEdit && !isMdUp,
      icon: <QrCodeIcon fontSize="small" />,
    },
  ];

  return tabs.filter(t => t.isVisible == null || t.isVisible);
};
