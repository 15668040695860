import React, { Fragment } from 'react';
import styles from './styles.scss';

interface Props {
  steps: { name: string; icon: JSX.Element }[];
}

export default function WizardPreview(props: Props) {
  return (
    <div className={styles.upcomingSteps}>
      {props.steps.map((step, i) => {
        return (
          <Fragment key={step.name}>
            <div className={styles.upcomingStep}>
              <div className={styles.upcomingStepIcon}>{step.icon}</div>
              <p className="text-weight-medium text-small u-half-spacing-top u-quarter-spacing-bottom text-font-text">
                STEP {i + 1}
              </p>
              <p className="text-small text-font-text u-align-center">{step.name}</p>
            </div>
            {i + 1 !== props.steps.length && (
              <div className={styles.upcomingSection}>
                <div />
                <div />
                <div />
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
