import Menu from '@mui/material/Menu';
import cx from 'classnames';
import React from 'react';
import Radio from 'ui/elements/form/choice/Radio';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import ChevronUpIcon from 'ui/elements/icons/ChevronUpIcon';
import styles from './styles.scss';

interface Props {
  className?: string;
  color?: 'grey' | 'white';
  borderColor?: 'grey' | 'none';
  hasError?: boolean;
  onBlur?: () => void;
  children: JSX.Element | (JSX.Element | string)[];
  items: ButtonDropdownItem[];
}

export type ButtonDropdownItem = {
  onClick: () => void;
  icon?: JSX.Element;
  text: string;
  checked: boolean;
  disabled?: boolean;
  rightIcon?: 'radio' | JSX.Element;
};

export default function ButtonDropdown(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClose() {
    setAnchorEl(null);
    props.onBlur && props.onBlur();
  }

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  const open = Boolean(anchorEl);

  return (
    <div className={props.className}>
      <button
        className={cx(styles.button, styles.primary, styles.medium, {
          [styles.hasError]: props.hasError && !open,
          [styles.grey]: props.color === 'grey' || !props.color,
          [styles.borderGrey]: props.borderColor === 'grey',
          [styles.white]: props.color === 'white',
        })}
        onClick={handleClick}
      >
        <span className={styles.text}>
          {props.children}{' '}
          {open ? (
            <ChevronUpIcon className="u-content-spacing-left" />
          ) : (
            <ChevronDownIcon className="u-content-spacing-left" />
          )}
        </span>
      </button>
      <Menu
        MenuListProps={{ style: { padding: 0 } }}
        PaperProps={{ elevation: 2 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {props.items.map((item, i) => (
          <button
            className={cx(styles.button, styles.primary, styles.medium, styles.menuItem, {
              [styles.selected]: !item.rightIcon && item.checked,
              [styles.grey]: props.color === 'grey' || !props.color,
              [styles.white]: props.color === 'white',
            })}
            disabled={item.disabled}
            key={i}
            onClick={() => {
              !item.disabled && item.onClick();
              handleClose();
            }}
          >
            <span
              className="u-flex-align-center"
              data-intercom-target={'audience-picker-' + item.text.toLowerCase().split(' ').join('-')}
            >
              {item.icon && <span className="u-half-spacing-right">{item.icon}</span>}
              {item.text}
            </span>
            <span className="u-half-spacing-left">
              {item.rightIcon === 'radio' ? (
                <Radio color="indigo" checked={item.checked} size="small" disabled={item.disabled} />
              ) : (
                item.rightIcon
              )}
            </span>
          </button>
        ))}
      </Menu>
    </div>
  );
}
