import IconButton from '@mui/material/IconButton';
import isEmpty from 'ramda/src/isEmpty';
import React, { useRef, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import FilterIcon from 'ui/elements/icons/FilterIcon';
import { LabelFilter } from 'pages/Community/CommunityMembers/Companies/useCompanyFilters';
import DropDownMultiSelect from 'ui/elements/DropDown/DropDownMultiSelect';

export default function DropDownMultiSelectHeader({ filter }: { filter: LabelFilter }) {
  const [open, setOpen] = useState(false);
  const chip = useRef(null);
  return (
    <span ref={chip}>
      <IconButton className="u-quarter-spacing-left" onClick={() => setOpen(true)}>
        <FilterIcon />
      </IconButton>
      <DropDownMultiSelect
        values={filter.options.map(option => ({ value: option.id.toString(), label: option.name }))}
        value={filter.currentValue.map(v => v.id.toString())}
        onSelect={value => filter.setValue(value)}
        anchorEl={chip.current}
        setOpen={setOpen}
        open={open}
      />
      {
        <Button
          kind="tertiary"
          size="small"
          onClick={() => filter.setValue([])}
          style={!isEmpty(filter.currentValue) ? {} : { visibility: 'hidden', pointerEvents: 'none' }} // Prevent table columns from 'jumping' before the next result is loaded
        >
          <CloseIcon fontSize="inherit" className="u-quarter-spacing-right" />
          Clear
        </Button>
      }
    </span>
  );
}
