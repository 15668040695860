import { asUserAuthor } from 'domain/shared/author';
import React, { useState } from 'react';
import { UserProfile } from 'types/user';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import LoadableResource from 'util/resource/Resource';

import UpdateForm from './RoomUpdateForm';
import { editUpdate } from '../actions';
import { RoomUpdateDTO } from '../types';
import roomUpdateAPI from '../RoomUpdateAPI';
import useNotify from 'hooks/useNotify';

interface OwnProps {
  roomId: string;
  setActive: (v: boolean) => void;
  user?: UserProfile;
  onComplete: (contentId: string, content: RoomUpdateDTO) => void;
  update: RoomUpdateDTO;
}

export default function EditUpdateDialog(props: OwnProps) {
  const { user } = props;
  const [isSaving, setSaving] = useState(false);
  const author = asUserAuthor(user);

  const [attachmentsResource] = useResourceLegacy(
    () =>
      props.update.update.attachments.length > 0
        ? roomUpdateAPI(props.roomId).attachments.list(props.update.update.id)
        : Promise.resolve({ documents: [] }),
    [props.update.update.id, props.roomId],
  );

  const notify = useNotify();

  return (
    <LoadableResource resource={attachmentsResource}>
      {attachments => (
        <UpdateForm
          dialogTitle="Edit update"
          roomId={props.roomId}
          titleDraft={props.update.update.title}
          contentDraft={props.update.update.content}
          bannerImage={props.update.update.bannerImage}
          attachments={attachments}
          author={author}
          onSave={content => {
            setSaving(true);
            editUpdate(
              notify,
              props.roomId,
              (contentId, content) => {
                props.onComplete(contentId, content);
                setSaving(false);
                props.setActive(false);
              },
              props.update.update.id,
              content,
            );
          }}
          saveButtonText="Save"
          isSaving={isSaving}
          onClose={() => props.setActive(false)}
          contentId={props.update.update.id}
        />
      )}
    </LoadableResource>
  );
}
