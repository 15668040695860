import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function EnvelopeIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m2 6 7 4c2.5 1.4 3.5 1.4 6 0l7-4" />
      <path d="M2 13.5c0 3 .1 4.6 1.2 5.7 1.2 1.1 2.8 1.2 5.9 1.3h5.8c3.1-.1 4.7-.2 5.9-1.3 1-1.1 1.1-2.7 1.2-5.7v-3c0-3-.1-4.6-1.2-5.7-1.2-1.1-2.8-1.2-5.9-1.3H9.1c-3.1.1-4.7.2-5.9 1.3-1 1.1-1.1 2.7-1.2 5.7v3Z" />
    </SvgIcon>
  );
}
