import useSWR, { invalidate } from 'hooks/useSWR';
import { PaginatedResult } from 'types/api';
import { DocumentDTO, FolderDTO, UnreadDocumentDTO } from 'types/documents';
import { useSWRResource } from 'util/resource/useSWRResource';
import { documentsUrls } from '.';
import { IResource } from 'util/resource';
import { useEffect } from 'react';
import { getUserProfiles } from 'actions/userProfiles';

export const boardroomFoldersKey = (companyId: number) => documentsUrls.boardroom.listFolders(companyId);
export const unreadBoardroomDocumentsKey = (companyId: number) => documentsUrls.boardroom.listUnread(companyId);
export const boardroomDocumentsKey = (companyId: number, folderId: string) =>
  documentsUrls.boardroom.listDocuments(companyId, folderId);

export function useBoardroomFolders(companyId: number) {
  const swrResult = useSWR<{ folders: FolderDTO[] }>(boardroomFoldersKey(companyId));

  return useSWRResource(swrResult);
}

export function useBoardroomDocuments(
  companyId: number,
  folderId: string,
  _getUserProfiles: typeof getUserProfiles,
): { resource: IResource<{ documents: DocumentDTO[] }> } {
  const { resource, mutate } = useBoardroomFolders(companyId);

  const swrResult = useSWR<{ documents: DocumentDTO[] }>(
    folderId ? boardroomDocumentsKey(companyId, folderId) : undefined,
  );

  const swrResource = useSWRResource(swrResult);
  useEffect(() => {
    if (swrResource.resource.state === 'fetched') {
      _getUserProfiles(swrResource.resource.resource.documents.map(doc => doc.createdByCwUserId));
      refetchUnreadBoardroomDocuments(companyId);

      if (resource.state === 'fetched') {
        // Update the unread count on the folder
        mutate({
          folders: resource.resource.folders.map(folder =>
            folder.id === folderId ? { ...folder, personalization: { numUnreadDocuments: 0 } } : folder,
          ),
        });
      }
    }
  }, [swrResource.resource.state]);

  if (!folderId) {
    return { resource: { state: 'fetched', resource: { documents: [] }, isValidating: swrResult.isValidating } };
  }

  return swrResource;
}

export const useUnreadBoardroomDocuments = (companyId: number | undefined, canReadBoardRoomDocuments: boolean) => {
  const swrResult = useSWR<PaginatedResult<UnreadDocumentDTO>>(
    companyId !== undefined && canReadBoardRoomDocuments ? unreadBoardroomDocumentsKey(companyId) : undefined,
  );

  return useSWRResource(swrResult);
};

export const refetchUnreadBoardroomDocuments = (companyId: number) => {
  invalidate(unreadBoardroomDocumentsKey(companyId));
};
