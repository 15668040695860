import useSWR from 'hooks/useSWR';
import { List } from 'types/api';
import { SentFollowerRequestDTO } from 'types/follower';
import { useSWRResource } from 'util/resource/useSWRResource';
import { userAPIUrls } from './usersApi';

export const useSentFollowerRequests = () => {
  const swrResult = useSWR<List<SentFollowerRequestDTO>>(userAPIUrls.followerRequests.listSent());

  return useSWRResource(swrResult);
};
