import FormHelperText from '@mui/material/FormHelperText';
import React, { CSSProperties, ReactNode } from 'react';
import Chip from 'ui/elements/Chip';
import { Autocomplete as MaterialAutocomplete, TextField } from '@mui/material';
import { FormikErrors } from 'formik';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';

interface Props<T> {
  id?: string;
  name?: string;
  placeholder?: string;
  options: T[];
  values: T[];
  onChange: (value: T[], selectedValue?: T) => void;
  onInputChange?: (newInput: string) => void;
  onRemove?: (value: T) => void;
  autoComplete?: string;
  noOptionsText?: string;
  getOptionLabel: (option: T) => string;
  helperText?: string;
  error?: ReactNode | string | string[] | FormikErrors<T[]>;
  style?: CSSProperties;
  isClearable?: boolean;
  className?: string;
  isDisabled?: boolean;
  touched?: boolean;
  autoFocus?: boolean;
  renderValue?: (props: { label: string; className?: string; onDelete?: () => void }) => JSX.Element;
  disableFilter?: boolean;
}

export default function MultiSelect<T>({
  values,
  onChange,
  onInputChange,
  onRemove,
  name,
  id,
  placeholder,
  options,
  getOptionLabel,
  noOptionsText,
  error,
  helperText,
  autoComplete = 'not-active',
  style,
  className,
  isClearable = false,
  isDisabled = false,
  touched,
  autoFocus,
  renderValue,
  disableFilter,
}: Props<T>) {
  function removeValue(value: T) {
    onRemove ? onRemove(value) : onChange(values.filter((v: T) => v !== value));
  }

  const hasError = !!error && !!touched;

  const RenderValue = renderValue ? renderValue : () => <></>;

  return (
    <>
      <MaterialAutocomplete
        multiple={true}
        value={values || []}
        id={id}
        style={style}
        disabled={isDisabled}
        filterOptions={disableFilter ? x => x : undefined} // This will show all the options provided without filtering.
        popupIcon={<ChevronDownIcon fontSize="small"></ChevronDownIcon>}
        renderInput={params => (
          <TextField
            variant="standard"
            {...params}
            name={name}
            autoFocus={autoFocus}
            placeholder={values.length === 0 ? placeholder : undefined}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
              style: { position: 'relative', transform: 'none' },
            }}
            error={!!error}
            className={className}
            sx={{
              '.MuiInput-root .MuiInput-input': {
                padding: 0,
              },
            }}
            autoComplete={autoComplete}
            helperText={helperText}
          />
        )}
        noOptionsText={noOptionsText}
        onInputChange={(event, newInput) => (onInputChange ? onInputChange(newInput) : undefined)}
        getOptionLabel={getOptionLabel}
        onChange={(_, values, __, details) => {
          values && onChange(values, details?.option);
        }}
        options={options.filter(option => !values.some(value => getOptionLabel(option) == getOptionLabel(value)))}
        disableClearable={!isClearable}
        renderTags={(value, getTagProps) =>
          value.map((option, index) =>
            renderValue ? (
              <RenderValue
                {...getTagProps({ index })}
                key={getOptionLabel(option)}
                label={getOptionLabel(option)}
                onDelete={() => removeValue(option)}
              />
            ) : (
              <Chip
                {...getTagProps({ index })}
                key={getOptionLabel(option)}
                color="blue"
                label={getOptionLabel(option)}
                onDelete={() => removeValue(option)}
              />
            ),
          )
        }
      />
      {hasError && (
        <FormHelperText error>
          {
            // https://github.com/jaredpalmer/formik/issues/3683
            error as ReactNode
          }
        </FormHelperText>
      )}
    </>
  );
}
