import React, { CSSProperties } from 'react';

import AddIcon from 'ui/elements/icons/AddIcon';

import Action from './Action';
interface Props {
  className?: string;
  children: React.ReactNode;
  style?: CSSProperties;
}

export default function AddAction(props: Props) {
  return <Action {...props} icon={<AddIcon fontSize="small" />}></Action>;
}
