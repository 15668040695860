import cx from 'classnames';
import React from 'react';
import Pill from 'ui/elements/Pill';
import styles from './styles.scss';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';

interface Props {
  heading: string | JSX.Element;
  id?: string;
  className?: string;
  margin?: 'large' | 'normal' | 'none';
  addMobileMargin?: boolean;
  actionsAlignment?: 'left' | 'right';
  children?: React.ReactNode;
  'data-intercom-target'?: string;
}

export default function SectionHeading({
  heading,
  addMobileMargin,
  id,
  className,
  margin,
  actionsAlignment,
  children,
  ...rest
}: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  return (
    <h2
      className={cx(styles.wrapper, className, {
        'u-half-spacing-bottom': !margin || margin === 'normal',
        'u-content-spacing-bottom': margin === 'large',
        'u-content-spacing-x': addMobileMargin && isMobile,
      })}
      {...rest}
    >
      <div className={styles.heading} style={actionsAlignment === 'left' ? { flexGrow: 'unset' } : undefined}>
        <span className="u-flex">
          <Pill />
        </span>
        <span id={id} className="text-weight-medium text-medium u-flex-align-center">
          {heading}
        </span>
      </div>
      {children && <div className={styles.actions}>{children}</div>}
    </h2>
  );
}
