import useFollowCompany from 'domain/companies/Follow/useFollowCompany';
import React from 'react';
import { ICompany } from 'types/company';
import Loading from 'ui/elements/Loading';
import Card from 'ui/views/cards/Card';

export default function RestrictedSection({ company, section }: { company: ICompany; section: string }) {
  const { onFollow, isPendingFollowerRequest, isSendingFollowRequest } = useFollowCompany(company);
  return (
    <Card>
      <p>
        <span className="u-quarter-spacing-right text-weight-medium">{section} are visible for connections only.</span>
        Let the company know you have an interest in their{' '}
        {section.substring(0, 1).toLowerCase() + section.substring(1)} by sending a{' '}
        {isSendingFollowRequest ? (
          <Loading style={{ display: 'inline-block' }} className="u-content-spacing-x" size={12}></Loading>
        ) : isPendingFollowerRequest ? (
          <>
            request to follow.<i className="u-quarter-spacing-left">Your request to follow is pending</i>
          </>
        ) : (
          <button onClick={onFollow} style={{ lineHeight: 1 }} className="text-link">
            request to follow
          </button>
        )}
      </p>
    </Card>
  );
}
