import CompanyAPI from 'apis/CompanyAPI';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import useNotify from 'hooks/useNotify';
import React, { useState } from 'react';
import { AllStages, FundingStage, Stage } from 'types';
import { CompanyProfile } from 'types/company';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Label from 'ui/elements/form/Label';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';

export default function StagesForm({
  stages,
  fundingStages,
  companyProfile,
  onComplete,
  onClose,
  onBack,
}: {
  stages: AllStages;
  fundingStages: FundingStage[];
  companyProfile: CompanyProfile;
  onComplete: (stage?: Stage, fundingStage?: FundingStage) => void;
  onClose: () => void;
  onBack: () => void;
}) {
  const notify = useNotify();
  const { setLastUpdated } = useCompanyLastUpdated(companyProfile.id);
  const parentIndustries = companyProfile
    ? companyProfile.industries.values.filter(ind =>
        stages.industryDependent.some(c => c.parents.some(p => p.id === ind.id)),
      )
    : [];
  const children = stages.industryDependent.filter(stage =>
    stage.parents.some(parentIndustry => parentIndustries.some(ind => ind.id === parentIndustry.id)),
  );
  const allStages = [...stages.values, ...children];

  const [selectedStage, setSelectedStage] = useState(
    companyProfile.stages.values.length > 0 ? companyProfile.stages.values[0] : undefined,
  );
  const [selectedFundingStage, setSelectedFundingStage] = useState(companyProfile.fundingStage);

  const [save, isSaving] = useLazyResource(
    () =>
      Promise.all([
        selectedFundingStage && companiesApi.fundingStage.update(companyProfile.id, selectedFundingStage?.id),
        CompanyAPI.stages.update(companyProfile.id, selectedStage ? [selectedStage] : []),
      ]),
    {
      onSuccess: () => {
        onComplete(selectedStage, selectedFundingStage);
        setLastUpdated(new Date());
      },
      onFailure: () => notify('error', 'Failed to update stage'),
    },
  );

  return (
    <>
      <Content>
        <Label>Product stage</Label>
        <SelectCloud
          options={allStages}
          values={selectedStage ? [selectedStage] : []}
          onSelect={stage => setSelectedStage(stage)}
          onRemove={() => setSelectedStage(undefined)}
        />
        <Label className="u-content-spacing-top">Funding stage</Label>
        <SelectCloud
          options={fundingStages}
          values={selectedFundingStage ? [selectedFundingStage] : []}
          onSelect={fundingStage => setSelectedFundingStage(fundingStage)}
          onRemove={() => setSelectedFundingStage(undefined)}
        />
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button
            isLoading={isSaving}
            onClick={() => {
              save({});
            }}
            kind="primary"
          >
            Next
          </Button>
          <Button onClick={onBack} kind="primary" color="greyLight">
            Back
          </Button>
        </ButtonList>
        <Button onClick={onClose} kind="tertiary" color="grey">
          Pick it up later
        </Button>
      </DialogActions>
    </>
  );
}
