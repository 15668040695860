const sansSerifFallbacks = [
  '"HelveticaNeue-Light"',
  '"Helvetica Neue Light"',
  '"Helvetica Neue"',
  'Helvetica',
  'Arial',
  '"Lucida Grande"',
  'sans-serif',
];

export default {
  display: ['"Red Hat Display"', ...sansSerifFallbacks].join(','),
  text: ['"Red Hat Text"', ...sansSerifFallbacks].join(','),
};
