import React, { useEffect, useState } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { bluePlanetTheme } from 'ui/theme';
import Pill from 'ui/elements/Pill';
import styles from './accordion.scss';
import Button from 'ui/elements/buttons/Button';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import cx from 'classnames';
import { useTheme } from '@mui/material';

interface Props {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
  isActivated: boolean;
  isExpanded?: boolean;
  showPill?: boolean;
  iconColor?: 'grey' | 'indigo';
  onActivate: () => void;
  onSave: () => void;
  onExpand?: (isExpanded: boolean) => void;
  saving: boolean;
  isSuccess?: boolean;
  setSuccess?: (isSuccess: boolean) => void;
  isDirty: boolean;
}

export default function SettingsAccordion(props: Props) {
  const [isExpanded, setExpanded] = useState(props.isExpanded ?? false);
  const [hasBeenActivated, setHasBeenActivated] = useState(false);
  const theme = useTheme();

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
    props.onExpand && props.onExpand(!isExpanded);
  };

  useEffect(() => {
    setExpanded(props.isExpanded ?? isExpanded);
  }, [props.isExpanded]);

  useEffect(() => {
    if (props.isActivated) {
      setHasBeenActivated(true);
    }
  }, [props.isActivated]);

  const accordionSummaryStyle = props.isActivated
    ? {
        height: theme.spacing(6),
      }
    : {
        height: 'unset',
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: theme.spacing(2),
        },
      };

  return (
    <div className={props.className}>
      <MuiAccordion
        elevation={0}
        expanded={isExpanded}
        TransitionProps={{ unmountOnExit: true }}
        onChange={props.isActivated ? toggleExpanded : undefined}
      >
        <AccordionSummary
          className={'u-flex u-flex-align-center text-body'}
          sx={{
            ...accordionSummaryStyle,
            '.MuiAccordionSummary-expandIconWrapper': !props.isExpanded
              ? {
                  transform: 'none',
                  transition: '0s',
                }
              : undefined,
            '.MuiAccordionSummary-content': {
              margin: 0,
              height: 'unset',
            },
          }}
          style={!props.isActivated ? { cursor: 'default' } : undefined}
          expandIcon={
            props.isActivated ? (
              <ChevronDownIcon
                style={{
                  color:
                    props.iconColor === 'indigo'
                      ? bluePlanetTheme.bluePlanetPalette.indigo.medium
                      : bluePlanetTheme.bluePlanetPalette.grey.main,
                }}
                strokeWidth={2}
              />
            ) : (
              <Button className="u-ellipsis" kind="secondary" color="grey" type="button" onClick={props.onActivate}>
                Activate
              </Button>
            )
          }
        >
          <div className={styles.settingsSummary}>
            <div className="u-flex u-flex--column" style={{ width: '100%' }}>
              <div className="u-flex-align-center">
                {props.showPill && <Pill />} {props.title}
              </div>
              {!props.isActivated && <span className={styles.description}>{props.description}</span>}
            </div>
            {!props.isActivated && hasBeenActivated && (props.isDirty || props.isSuccess) && (
              <Button
                kind="primary"
                onClick={props.onSave}
                isLoading={props.saving}
                isSuccess={props.isSuccess}
                setSuccess={props.setSuccess}
                disabled={props.saving || props.isSuccess}
                className="u-ellipsis"
              >
                Save
              </Button>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className={cx(styles.noBorder, 'u-quarter-padding-y')}>{props.children}</AccordionDetails>
      </MuiAccordion>
    </div>
  );
}
