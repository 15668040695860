import React from 'react';
import SvgIcon, { IconProps, getColor } from './SvgIcon';

export default function PinIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        stroke={getColor(props.color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m3 21 5-5M13.3 18.9C9.5 18 6 14.5 5 10.7c-.1-.6-.2-.8 0-1.3s.5-.7 1-1c1-.6 2.2-.8 3.4-.7 1.7.1 2.5.2 3 0 .4-.3.7-.8 1.2-1.8l.8-1.3c.4-.9.7-1.3 1.3-1.5.5-.2.9 0 1.5.2 1.6.5 2.9 1.8 3.4 3.4.3.6.4 1 .2 1.5-.2.6-.6.9-1.5 1.3l-1.3.8c-1 .6-1.6.8-1.8 1.3-.2.4-.1 1.2 0 2.9.1 1.2 0 2.4-.7 3.5-.3.4-.5.7-1 .9-.5.2-.7.1-1.3 0Z"
      />
    </SvgIcon>
  );
}
