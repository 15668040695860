import compareDesc from 'date-fns/compareDesc';
import numeral from 'numeral';

import { Kpi, KpiValue, Trend } from 'domain/kpis/types';
import sort from 'ramda/src/sort';

export const formatAsKpi = (value: number) => numeral(value).format('0,0.[00]');

export const sortKpiValuesDesc = (values: KpiValue[]) =>
  sort((a, b) => compareDesc(new Date(a.timestamp), new Date(b.timestamp)), values);

const numberOrParseString = (n: number | string) => {
  if (typeof n === 'number') {
    return n;
  }
  return parseFloat(n);
};

export function getChangeInPercent(values: KpiValue[]): number {
  const len = values.length;
  if (len < 2) {
    return 0;
  }

  const sortedValues = sortKpiValuesDesc(values);

  const newestValue = numberOrParseString(sortedValues[0].value);
  const secondNewestValue = numberOrParseString(sortedValues[1].value);
  return parseFloat(formatAsKpi(((newestValue - secondNewestValue) / secondNewestValue) * 100));
}

export const getTrend = (kpi: Kpi): Trend => {
  const twoLatestValues = sortKpiValuesDesc(kpi.values)
    .slice(0, 2)
    .map(v => v.value);

  if (twoLatestValues.length < 2 || twoLatestValues[1] === twoLatestValues[0]) return 'neutral';

  if (kpi.details.trendIndicator === 'up') return twoLatestValues[0] > twoLatestValues[1] ? 'positive' : 'negative';

  return twoLatestValues[1] > twoLatestValues[0] ? 'positive' : 'negative';
};
