import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ChevronRightIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m9 6 6 6-6 6" />
    </SvgIcon>
  );
}
