import KpiAPI from 'apis/KpiAPI';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useNotify from 'hooks/useNotify';
import React, { useEffect } from 'react';
import Button from 'ui/elements/buttons/Button';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import MaxWidth from 'ui/views/containers/MaxWidth';
import { Content } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import useResource from 'util/resource/useResource';
import { getOrElse } from 'util/resource';
import { Kpi as KPI } from 'domain/kpis/types';

interface Kpi {
  id?: string;
  name: string;
  unitOfMeasure?: string;
}

const predefinedKpis: Kpi[] = [
  { name: 'Revenue', unitOfMeasure: undefined },
  { name: 'Runway months', unitOfMeasure: 'Months' },
  { name: 'Cash balance', unitOfMeasure: undefined },
  { name: 'Customers', unitOfMeasure: undefined },
  { name: 'MRR', unitOfMeasure: undefined },
  { name: 'Gross margin', unitOfMeasure: '%' },
  { name: 'Users', unitOfMeasure: undefined },
  { name: 'Monthly active users', unitOfMeasure: undefined },
  { name: 'Units sold', unitOfMeasure: undefined },
];

export default function KpisForm({
  onStart,
  onKpiUploaded,
  companyId,
}: {
  onStart?: () => void;
  onKpiUploaded: () => void;
  companyId: number;
}) {
  useEffect(() => {
    onStart && onStart();
  }, [onStart]);
  const notify = useNotify();
  const { setLastUpdated } = useCompanyLastUpdated(companyId);

  const { resource, mutate } = useResource<KPI[]>(KpiAPI.kpi.list(companyId));
  const existingKPIs = getOrElse(resource, []).filter(kpi => !kpi.details.archivedAt);

  const [addKpi, isAddingKpi] = useLazyResource((payload: Kpi) => KpiAPI.kpi.new(companyId, payload), {
    onSuccess: newKpi => {
      onKpiUploaded();
      mutate([...existingKPIs, { details: newKpi, values: [] }]);
    },
    onFailure: () => notify('error', `Something unexpected happened when trying to add the KPI.`),
  });

  const [archiveKpi, isArchiving] = useLazyResource((kpiId: string) => KpiAPI.kpi.archive(companyId, kpiId), {
    onSuccess: (_, kpiId) => {
      mutate(existingKPIs.filter(it => it.details.id !== kpiId));
      setLastUpdated(new Date());
    },
    onFailure: () => notify('error', `Something unexpected happened when trying to add the KPI.`),
  });

  const formik = useFormik({
    initialValues: {
      kpiName: '',
    },
    onSubmit: values => {
      if (!existingKPIs.some(it => it.details.name === values.kpiName)) {
        addKpi({ name: values.kpiName });
      }
      formik.resetForm();
    },
    validationSchema: Yup.object().shape({
      kpiName: Yup.string().trim().required('You need to add a short descriptive name of the KPI'),
    }),
  });

  return (
    <Content>
      <SelectCloud
        options={predefinedKpis}
        values={existingKPIs.map(it => ({
          id: it.details.id,
          name: it.details.name,
          unitOfMeasure: it.details.unitOfMeasure,
        }))}
        onSelect={addKpi}
        isSelecting={isAddingKpi}
        onRemove={kpi => {
          kpi.id && archiveKpi(kpi.id);
        }}
        isRemoving={isArchiving}
        customInputComponent={
          <MaxWidth width="xs">
            <div className="u-flex u-flex--align-items-start u-half-spacing-top">
              <FormikTextField name="kpiName" className="u-quarter-spacing-right" formikProps={formik} autoFocus />
              <Button
                kind="secondary"
                onClick={() => {
                  formik.submitForm();
                }}
                isLoading={isAddingKpi}
              >
                Add
              </Button>
            </div>
          </MaxWidth>
        }
      />
    </Content>
  );
}
