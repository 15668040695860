import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function DownloadIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 14.5v-10m0 10c.7 0 2-2 2.5-2.5M12 14.5c-.7 0-2-2-2.5-2.5M20 16.5c0 2.5-.5 3-3 3H7c-2.5 0-3-.5-3-3" />
    </SvgIcon>
  );
}
