import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';
import { ButtonColors } from './Button';

interface Props {
  href?: string;
  onClick?: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  color?: ButtonColors;
  kind: 'primary' | 'secondary' | 'tertiary';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  target?: string;
  rel?: string;
}

export default function AnchorButton(props: Props) {
  const color = props.color ?? 'blue';
  const size = props.size ?? 'medium';

  return (
    <a
      href={props.href}
      onClick={props.onClick}
      target={props.target}
      rel={props.rel}
      className={cx(props.className, styles.button, styles[color], styles[props.kind], styles[size])}
    >
      {props.children}
    </a>
  );
}
