import { getUserProfiles } from 'actions/userProfiles';
import { PaginationOptions } from 'types/api';
import conversationApi from 'apis/ContentAPI/conversationApi';
import { downloadBlob } from 'domain/shared/Files/actions';
import { track } from 'util/analytics';

export const getMeAsCompany = (companyId: number, cwUserId: string) => conversationApi.company.me(companyId, cwUserId);

export const fetchConversationAsUser = async (
  getUserProfilesA: typeof getUserProfiles,
  companyId: number,
  paginationOptions: PaginationOptions,
) => {
  const result = await conversationApi.user.single.get(companyId, paginationOptions);
  const users = result.values.map(u => u.creatorCwUserId);
  getUserProfilesA(users);
  return result;
};

export const fetchConversationAsCompany = async (
  getUserProfilesA: typeof getUserProfiles,
  companyId: number,
  cwUserId: string,
  paginationOptions: PaginationOptions,
) => {
  const result = await conversationApi.company.get(companyId, cwUserId, paginationOptions);
  const users = [...result.values.map(u => u.creatorCwUserId), cwUserId];
  getUserProfilesA(users);
  return result;
};

export const fetchConversationByUsers = async (ids: UUID[], getUserProfilesA?: typeof getUserProfiles) => {
  if (ids.length > 0) {
    const conversation = await conversationApi.user.getByUsers(ids);
    if (getUserProfilesA) {
      getUserProfilesA(
        conversation.userConversation.activeParticipants.concat(conversation.userConversation.inactiveParticipants),
      );
    }

    return conversation;
  } else {
    return Promise.reject();
  }
};

export const listMessages = async (conversationId: UUID, paginationOptions: PaginationOptions) => {
  return await conversationApi.user.listMessages(conversationId, paginationOptions);
};

export const listAttachments = async (conversationId: UUID) => {
  return await conversationApi.listAttachments(conversationId);
};

export async function downloadAttachment(fileId: string) {
  const response = await conversationApi.downloadAttachment(fileId);
  await downloadBlob(response);
}

interface TrackConversationOpened {
  withCompanyId: number;
  key: string;
  path: string;
  communitySlug?: string;
}

export async function trackConversationOpened(context: TrackConversationOpened) {
  try {
    await conversationApi.trackConversationOpened({
      key: context.key,
      withCompanyId: context.withCompanyId,
      path: context.path,
      openedFromCommunitySlug: context.communitySlug,
    });
    track(`conversation-opened--${context.key}`);
  } catch (e) {}
}
