import Loading from 'ui/elements/Loading';
import React from 'react';
import { Fetched, IResource } from 'util/resource';
import LoadingBar from 'ui/elements/Loading/LoadingBar';
import ErrorCard from 'ui/views/errors/ErrorCard';
import { ErrorBoundary } from '@sentry/react';

type ResourceOf<T extends Fetched<any>> = T['resource'];

type FetchSuccess<T> = T extends Fetched<any> ? T : never;

interface Props<R extends ReadonlyArray<IResource<any>>> {
  children: (resources: { [K in keyof R]: ResourceOf<FetchSuccess<R[K]>> }) => React.ReactNode;
  resources: R;
  renderError?: 'Nothing' | (() => JSX.Element);
  renderLoading?: 'Nothing' | 'Bar' | (() => JSX.Element);
}

function InnerComponent<R extends ReadonlyArray<IResource<any>>>(props: Props<R>) {
  const resources = props.resources;
  if (resources.every(r => r.state === 'idle')) {
    return null;
  } else if (resources.some(r => r.state === 'fetching')) {
    if (props.renderLoading === 'Nothing' || props.renderLoading === 'Bar') {
      return null;
    }
    return typeof props.renderLoading === 'function' ? (
      props.renderLoading()
    ) : (
      <div className="u-align-center u-content-spacing">
        <Loading />
      </div>
    );
  } else if (resources.every(r => r.state === 'fetched')) {
    const fetchedResources = resources.map(r => (r as Fetched<any>).resource as ResourceOf<FetchSuccess<R[number]>>);
    return <>{props.children(fetchedResources as { [K in keyof R]: ResourceOf<FetchSuccess<R[K]>> })}</>;
  } else if (resources.some(r => r.state === 'error')) {
    if (props.renderError) {
      return props.renderError === 'Nothing' ? null : props.renderError();
    }
    return <ErrorCard />;
  } else {
    return null;
  }
}

export default function Resources<const R extends ReadonlyArray<IResource<any>>>(props: Props<R>) {
  return (
    <ErrorBoundary fallback={<ErrorCard />}>
      {/* The LoadingBar needs to stay rendered after loading to fade out correctly */}
      {props.renderLoading === 'Bar' && (
        <LoadingBar isComplete={props.resources.every(r => r.state === 'fetched' || r.state === 'error')} />
      )}
      <InnerComponent {...props} />
    </ErrorBoundary>
  );
}
