import styled from '@emotion/styled';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';

const P = styled.p`
  color: ${bluePlanetTheme.bluePlanetPalette.grey.main};
`;

export default function InlineTooltip({ title, children }: { title: string; children?: React.ReactNode }) {
  return (
    <>
      <P className="text-tiny text-uppercase text-weight-medium">{title}</P>
      <P className="text-medium text-weight-regular">{children}</P>
    </>
  );
}
