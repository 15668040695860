import { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import Button from 'ui/elements/buttons/Button';
import Dialog, { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import i18n from 'util/i18n';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  title: string;
  confirmButton?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  dismissLabel?: string;
  maxWidth?: DialogProps['maxWidth'];
}

export default function ConfirmDialog(props: Props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={props.maxWidth || 'xs'}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <Content>{props.children}</Content>
      <DialogActions>
        {props.confirmButton && props.confirmButton}
        <Button
          kind={props.confirmButton ? 'tertiary' : 'secondary'}
          onClick={e => {
            e.stopPropagation();
            props.onClose();
          }}
        >
          {props.dismissLabel || i18n('en').common.noCancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
