import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function QuestionIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z" />
      <path d="M10 9a2 2 0 1 1 3.7 1C13 11 12 12 12 13v.5" />
      <path strokeWidth="2" d="M12 17h0" />
    </SvgIcon>
  );
}
