import { useEffect, useState } from 'react';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { IResource } from 'util/resource';
import { addPage } from 'util/paginationUtils';
import { fromStatusCode } from 'pages/common/error/helpers';

export default function usePaginatedResourceLegacy<T, U>(
  fetchResource: (paginationOptions?: PaginationOptions) => Promise<PaginatedResult<T>>,
  deps: any[],
  onLoad?: (resource: PaginatedResult<T>) => void,
  uniqueSelector?: (t: T) => U,
) {
  const [state, setState] = useState<IResource<PaginatedResult<T>>>({ state: 'idle' });

  const reload = async () => {
    setState({ state: 'fetching' });
    try {
      const resource = await fetchResource();
      setState({ resource, state: 'fetched', isValidating: false });
      onLoad && onLoad(resource);
    } catch (e) {
      setState({ state: 'error', errorType: fromStatusCode(e?.status) });
    }
  };

  useEffect(() => {
    reload();
  }, deps);

  const onSeeMore = async (paginationOptions: PaginationOptions) => {
    if (state.state === 'fetched') {
      const values = await fetchResource(paginationOptions);
      setState({
        state: 'fetched',
        resource: addPage(state.resource, values, uniqueSelector),
        isValidating: false,
      });
    }
  };

  const setLoadedResource = (func: (prevState: PaginatedResult<T>) => PaginatedResult<T>) => {
    if (state.state === 'fetched') {
      setState({
        state: 'fetched',
        resource: func(state.resource),
        isValidating: state.isValidating,
      });
    }
  };

  type ReturnType = [typeof state, typeof setLoadedResource, typeof setState, typeof onSeeMore, typeof reload];
  const returnable: ReturnType = [state, setLoadedResource, setState, onSeeMore, reload];
  return returnable;
}
