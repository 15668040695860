import LoadableResource from 'util/resource/Resource';
import React from 'react';
import presentationIllustration from 'ui/illustrations/presentation.svg';
import Card from 'ui/views/cards/Card';
import ManagePitchDocuments from './ManagePitchDocumentsDialog';
import SmallAccordion from 'ui/modules/Accordion/SmallAccordion';
import PitchDocumentTile from 'domain/companies/Documents/Document/PitchDocumentTile';
import { DocumentList } from 'types/documents';
import { KeyedMutator } from 'swr';
import { IResource } from 'util/resource';
import { useCompanyLastUpdated } from '../LastUpdated';
import { ICompany, VideoListDTO } from 'types/company';
import LinkButton from 'ui/elements/buttons/LinkButton';
import { splitFilenameExtension } from 'util/stringUtils';
import PitchDeckPDFContainer from 'domain/companies/Documents/Document/PitchDeck/PitchDeckPDFContainer';
import { CompanyViewAs } from 'domain/companies/roleUtils';

interface Props {
  className?: string;
  company: ICompany;
  userCanUploadDocuments: boolean;
  documents: IResource<DocumentList>;
  setDocuments: KeyedMutator<DocumentList>;
  videos: VideoListDTO;
  onSaveVideos: (videos: VideoListDTO) => void;
  isEditDialogOpen: boolean;
  setIsEditDialogOpen: (isOpen: boolean) => void;
  shouldAutofocusVideos?: boolean;
  viewAs: CompanyViewAs;
}

const maxVisibleFileCount = 2;
export default function PitchDocuments({
  documents: documentsResource,
  setDocuments,
  company,
  userCanUploadDocuments,
  className,
  videos,
  onSaveVideos,
  isEditDialogOpen,
  setIsEditDialogOpen,
  shouldAutofocusVideos,
  viewAs,
}: Props) {
  const { setLastUpdated } = useCompanyLastUpdated(company.id);

  return (
    <LoadableResource resource={documentsResource} renderError={() => <></>}>
      {documents => {
        const pitchDocuments = documents.documents
          .filter(doc => doc.documentType === 'pitch_deck' && splitFilenameExtension(doc.name).extension !== 'pdf')
          .concat(documents.documents.filter(doc => doc.documentType !== 'pitch_deck'));

        const pitchPDF = documents.documents.find(
          doc => doc.documentType === 'pitch_deck' && splitFilenameExtension(doc.name).extension === 'pdf',
        );

        const showEmptyPlaceholder = documents.documents.length === 0 && userCanUploadDocuments;

        return documents.documents.length > 0 || userCanUploadDocuments ? (
          <>
            <Card
              className={className}
              onClick={showEmptyPlaceholder ? () => setIsEditDialogOpen(true) : undefined}
              type={showEmptyPlaceholder ? 'dashed' : 'default'}
              style={{ position: 'relative' }}
            >
              {userCanUploadDocuments && documents.documents.length < 1 && (
                <>
                  <div className="u-flex u-flex--center u-flex-align-center">
                    <img src={presentationIllustration} width="72" />
                    <div className="u-flex u-flex--column u-section-spacing-left">
                      <p className="text-body">
                        Add a pitch deck, company presentation or any other documentation you want to share with
                        visitors of your company profile. The documents will be visible in all communities you are part
                        of.
                      </p>
                      <LinkButton onClick={() => setIsEditDialogOpen(true)}>Select files</LinkButton>
                    </div>
                  </div>
                </>
              )}
              {pitchPDF && (
                <PitchDeckPDFContainer
                  document={pitchPDF}
                  company={company}
                  viewAs={viewAs}
                  pitchDeckTitle={videos.values.length > 0 ? 'Pitch Deck' : undefined}
                />
              )}
              {pitchDocuments.length > 0 && (
                <>
                  {pitchPDF && (
                    <div className="text-large text-weight-medium u-section-spacing-top u-content-spacing-bottom">
                      Additional documents
                    </div>
                  )}
                  <div className="u-flex u-flex--column">
                    {pitchDocuments.slice(0, maxVisibleFileCount).map(doc => (
                      <PitchDocumentTile
                        key={doc.id}
                        companyId={company.id}
                        document={doc}
                        isPitchDeck={doc.documentType === 'pitch_deck'}
                      />
                    ))}
                    {pitchDocuments.length > maxVisibleFileCount && (
                      <SmallAccordion viewMoreText="Show more" viewLessText="Show less" align="bottom">
                        <div className="u-flex u-flex--column">
                          {pitchDocuments.slice(maxVisibleFileCount).map(doc => (
                            <PitchDocumentTile
                              key={doc.id}
                              companyId={company.id}
                              document={doc}
                              isPitchDeck={doc.documentType === 'pitch_deck'}
                            />
                          ))}
                        </div>
                      </SmallAccordion>
                    )}
                  </div>
                </>
              )}
            </Card>
            {userCanUploadDocuments && isEditDialogOpen && (
              <ManagePitchDocuments
                onClose={() => setIsEditDialogOpen(false)}
                company={company}
                documents={documents}
                onDocumentUploaded={() => {
                  setDocuments();
                  setLastUpdated(new Date());
                }}
                onDocumentDeleted={documentId =>
                  setDocuments({ documents: documents.documents.filter(doc => doc.id !== documentId) })
                }
                videos={videos}
                onSaveVideos={onSaveVideos}
                shouldAutofocusVideos={shouldAutofocusVideos}
              />
            )}
          </>
        ) : null;
      }}
    </LoadableResource>
  );
}
