import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import { CompanyListItem } from 'types/company';
import { companiesCommunitiesByRole } from 'domain/companies/companyUtils';
import useLocalStorage from 'hooks/useLocalStorage';
import { MyCommunity, TinyCommunity } from 'types/company/community';
import { OrganizationListItemDTO } from 'types/organization';
import ManageCommunity from 'pages/Dashboard/manage/ManageCommunity';
import ManageCompany from 'pages/Dashboard/manage/ManageCompany';
import ManageOrganization from 'pages/Dashboard/manage/ManageOrganization';
import CompanySelector from 'pages/Dashboard/sections/CompanySelector';

export const NUMBER_OF_DAYS_IN_INSIGHTS_PERIOD = 30;

const dashboardContextKey = 'dashboard-context';

const isCompanyListItem = (item: any): item is CompanyListItem => 'roles' in item && 'lastUpdated' in item;
const isTinyCommunity = (item: any): item is TinyCommunity => 'communityType' in item;
const isOrganizationListItemDTO = (item: any): item is OrganizationListItemDTO =>
  'isAdmin' in item && 'displayAs' in item;

export function Manage({
  companies,
  communities,
  organizations,
}: {
  companies: CompanyListItem[];
  communities: MyCommunity[];
  organizations: OrganizationListItemDTO[];
}) {
  const byRoles = companiesCommunitiesByRole(companies, communities);
  const managements = [
    ...byRoles.companiesIManage,
    ...byRoles.communitiesIManage.map(c => c.details),
    ...organizations.filter(o => o.isAdmin),
  ];

  const [selected, setSelected] = useLocalStorage<
    CompanyListItem | TinyCommunity | OrganizationListItemDTO | undefined
  >(dashboardContextKey, undefined);
  const selectedEntity = managements.some(it => it.id === selected?.id) ? selected : managements[0];

  return (
    <>
      {managements.length > 1 && selectedEntity && (
        <div className="u-half-spacing-bottom">
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: bluePlanetTheme.spacing(1) }}>
            <CompanySelector value={selectedEntity} onSelect={value => setSelected(value)} values={managements} />
          </div>
        </div>
      )}
      {isTinyCommunity(selectedEntity) && <ManageCommunity community={selectedEntity} />}
      {isCompanyListItem(selectedEntity) && <ManageCompany company={selectedEntity} />}
      {isOrganizationListItemDTO(selectedEntity) && <ManageOrganization organization={selectedEntity} />}
    </>
  );
}
