import { communitiesApi, communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import useNotify from 'hooks/useNotify';
import { invalidate } from 'hooks/useSWR';
import React from 'react';
import { useHistory } from 'react-router';
import { TinyCommunity } from 'types/company/community';
import { EventDTO } from 'ui/domain/Event/types';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import { communityUrls } from 'urls';
import useLazyResource from 'util/resource/useLazyResource';

interface Props {
  community: TinyCommunity;
  event: EventDTO;
  handleClose: () => void;
}

export default function DeleteEvent({ community, event, handleClose }: Props) {
  const history = useHistory();
  const notify = useNotify();

  const [deleteEvent, isDeletingEvent] = useLazyResource(() => communitiesApi.events.delete(community.id, event.id), {
    onSuccess: _ => {
      notify('success', `Event ${event.title} deleted`);
      history.push(communityUrls.overview(community.slug, 'events'));
      invalidate(communityAPIUrls.events.list(community.slug));
    },
    onFailure: error => notify('error', error || 'Could not delete event'),
  });

  return (
    <Dialog open onClose={handleClose}>
      <Title onClose={handleClose}>Delete event</Title>
      <Content>
        Are you sure you want to delete <b>{event.title}</b>?
      </Content>
      <DialogActions>
        <ButtonList>
          <Button isLoading={isDeletingEvent} kind="primary" color="red" onClick={() => deleteEvent(event)}>
            Delete event
          </Button>
          <Button kind="tertiary" onClick={handleClose}>
            Cancel
          </Button>
        </ButtonList>
      </DialogActions>
    </Dialog>
  );
}
