import { useAuth0 } from '@auth0/auth0-react';
import { getAdminRights } from 'auth/util';
import { useRouteMatch } from 'react-router';
import { Auth0User } from 'types';
import { backofficeUrls } from 'urls';
import useLocalStorage from './useLocalStorage';

export type BackofficeFeatures = 'ExtendedProfiles';

export default function useBackoffice() {
  const match = useRouteMatch();
  const { user }: { user?: Auth0User } = useAuth0();
  const adminRights = getAdminRights(user);
  const canAccessBackoffice = adminRights.includes('backoffice');

  const [showBackofficeInfoOnProfilesSetting, setShowBackofficeInfoOnProfilesSetting] = useLocalStorage(
    `backoffice-extended-profiles`,
    false,
  );

  const isBackofficeUrl = match.path.startsWith(backofficeUrls.view);

  return {
    canAccessBackoffice,
    adminRights,
    showBackofficeInfoOnProfiles: canAccessBackoffice && (showBackofficeInfoOnProfilesSetting || isBackofficeUrl),
    showBackofficeInfoOnProfilesSetting,
    setShowBackofficeInfoOnProfilesSetting,
  };
}
