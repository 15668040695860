import React from 'react';
import styled from '@emotion/styled';
import { UserProfile } from 'types/user';
import { bluePlanetTheme } from 'ui/theme';
import { fontSizeTiny } from 'ui/theme/themeBluePlanet';
import { halfSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import InfoIcon from 'ui/elements/icons/InfoIcon';

const IntroText = styled.span`
  display: flex;
  flex-direction: column;
  gap: ${halfSpacing};
  align-items: center;
  margin-top: ${sectionSpacing};
  margin-bottom: 5rem;
  color: ${bluePlanetTheme.bluePlanetPalette.grey.main};
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  font-size: ${fontSizeTiny};
`;

interface Props {
  members: UserProfile[];
  notConnectedMembers: UserProfile[];
}

export default function UserConversationIntroduction({ members, notConnectedMembers }: Props) {
  if (notConnectedMembers.length === 1) {
    return (
      <div className="u-flex u-flex-center">
        <IntroText>
          <InfoIcon />
          You and {notConnectedMembers[0].name} are not part of each others&apos; networks. You can send{' '}
          {notConnectedMembers[0].name} a message, and when your request to chat has been accepted, you can continue to
          contact each other directly.
        </IntroText>
      </div>
    );
  }
  if (notConnectedMembers.length > 0) {
    return (
      <div className="u-flex u-flex-center">
        <IntroText>
          <InfoIcon />
          The following people are not part of your network: {notConnectedMembers.map(m => m.name).join(', ')}. They
          will be able to respond to this conversation once they have accepted your message request.
        </IntroText>
      </div>
    );
  }
  return (
    <div className="u-flex u-flex-center">
      <IntroText>
        It&apos;s the beginning of your conversation{' '}
        {members.length > 0 ? `with ${members.map(m => m.name).join(', ')}` : ''}
      </IntroText>
    </div>
  );
}
