import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';

interface Props {
  readOnly?: boolean;
  children: React.ReactNode;
}

export default function ImpactGoalContainer({ readOnly, children }: Props) {
  return <div className={cx(styles.impactGoalContainer, { [styles.readOnly]: readOnly })}>{children}</div>;
}
