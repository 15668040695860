import config from 'config';
import ApiBase from 'apis/ApiBase';

const baseUrl = config.ANTIFRAUD_API_URL;
const api = ApiBase();

export default {
  report: {
    conversation: (conversationId: UUID, report: Report) =>
      api.post(`${baseUrl}/conversations/${conversationId}/report`, report),
    company: (companyId: number, report: Report) => api.post(`${baseUrl}/companies/${companyId}/report`, report),
    user: (cwUserId: string, report: Report) => api.post(`${baseUrl}/users/${cwUserId}/report`, report),
  },
  intelligence: {
    messages: () =>
      api.get<{ cwUserId: UUID; groups: { count: number; message: string }[] }[]>(`${baseUrl}/intelligence/messages`),
    conversation: (conversationId: UUID) =>
      api.get<{
        participants: ({ user: UUID } | { company: number })[];
        messages: { postedBy: UUID; message: string; createdAt: DateString }[];
      }>(`${baseUrl}/intelligence/conversations/${conversationId}`),
    conversationId: (companyId: string, cwUserId: UUID) =>
      api.get<{
        conversationId: UUID;
      }>(`${baseUrl}/intelligence/conversation-id/companies/${companyId}/users/${cwUserId}`),
  },
};

export interface Report {
  reason: string;
  elaboration?: string;
}
