import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function BookmarkActiveIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M4 18V9.7c0-3.6 0-5.4 1.2-6.6C6.3 2 8.2 2 12 2s5.7 0 6.8 1.1C20 4.3 20 6.1 20 9.7V18c0 2.3 0 3.4-.8 3.9-1.5.8-4.3-2-5.6-2.7-.8-.5-1.2-.7-1.6-.7-.4 0-.8.2-1.6.7-1.3.8-4.1 3.5-5.6 2.7-.8-.5-.8-1.6-.8-4Z" />
    </SvgIcon>
  );
}
