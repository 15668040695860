import React from 'react';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import Dialog, { DialogActions, Title } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import { invalidate } from 'hooks/useSWR';
import { organizationKey } from 'apis/OrganizationAPI/organizations/useOrganizations';

interface JoinOrganizationDialogProps {
  onClose: () => void;
  organizationId: string;
  inviteCode: string;
}

export default function JoinOrganizationDialog({ onClose, organizationId, inviteCode }: JoinOrganizationDialogProps) {
  const notify = useNotify();

  const [acceptInvite, isAcceptingInvite] = useLazyResource(
    () => OrganizationAPI.acceptInvite(organizationId, inviteCode),
    {
      onSuccess: () => {
        invalidate(organizationKey);
        notify('success', 'Accepted');
        onClose();
      },
      onFailure: () => {
        notify('error', 'Invite not found');
        onClose();
      },
    },
  );

  const [rejectInvite, isRejectingInvite] = useLazyResource(
    () => OrganizationAPI.retractInvite(organizationId, inviteCode),
    {
      onSuccess: () => {
        notify('success', 'Rejected');
        onClose();
      },
      onFailure: () => {
        notify('error', 'Invite not found');
        onClose();
      },
    },
  );

  return (
    <Dialog open={true} onClose={onClose} backdropColor="light" maxWidth="sm">
      <MobileMargin>
        <Title style={{ paddingBottom: 0 }} onClose={onClose}>
          You have been invited to join this organization
        </Title>
        <DialogActions>
          <Button kind="primary" onClick={acceptInvite} isLoading={isAcceptingInvite}>
            Accept
          </Button>
          <Button kind="secondary" color={'red'} onClick={rejectInvite} isLoading={isRejectingInvite}>
            Reject
          </Button>
        </DialogActions>
      </MobileMargin>
    </Dialog>
  );
}
